import React, { Component } from 'react'

class UserRefundPolicy extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            // <div className="reg-summary-outer">
            //     <div className="container">
            //         <div className="row">
            //             <div className="col-lg-6 col-md-6">
            //                 <div className="reg-summary">
            //                     <h2>Finish your listing to start earning</h2>
            //                     <p>You can always edit your listing after you publish it.</p>
            //                     <ul>
            //                         <li className="completed">
            //                             <i class="fas fa-check"></i>
            //                             <p>Property and guests</p>
            //                         </li>
            //                         <li className="active">
            //                             <i class="fas fa-check"></i>
            //                             <p>Location</p>
            //                             <a href="#" className="btn btn-primary">Continue</a>
            //                         </li>
            //                         <li className="pending">
            //                             <i class="fas fa-check"></i>
            //                             <p>Amenities</p>
            //                         </li>
            //                         <li className="pending">
            //                             <i class="fas fa-check"></i>
            //                             <p>Photos</p>
            //                         </li>
            //                         <li className="pending">
            //                             <i class="fas fa-check"></i>
            //                             <p>Description and title</p>
            //                         </li>
            //                         <li className="pending">
            //                             <i class="fas fa-check"></i>
            //                             <p>Booking setting</p>
            //                         </li>
            //                         <li className="pending">
            //                             <i class="fas fa-check"></i>
            //                             <p>Calendar and availability</p>
            //                         </li>
            //                         <li className="pending">
            //                             <i class="fas fa-check"></i>
            //                             <p>Pricing</p>
            //                         </li>
            //                         <li className="pending">
            //                             <i class="fas fa-check"></i>
            //                             <p>Review</p>
            //                         </li>
            //                     </ul>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </div>

            // <div className="add-place-wrap">
            //     <div className="container">
            //         <div className="add-place-content">
            //             <div className="apc-heading">
            //                 <h2>Quality for a host?</h2>
            //             </div>
            //             <div className="row">
            //                 <div className="col-lg-5 col-md-5">
            //                     <div className="apc-inner-content">
            //                         <div className="form-group">
            //                             <label>Address</label>
            //                             <input className="form-control" placeholder="Pod Address"></input>
            //                         </div>
            //                         <h4>Mandatory Amenities</h4>
            //                         <div className="checkbox-list-tag">
            //                             <div className="row">
            //                                 <div className="col-lg-6 col-md-6">
            //                                     <div class="filter-check">
            //                                         <label>
            //                                             <input type="checkbox" checked="checked" disabled></input>
            //                                             <span>
            //                                                 Washroom
            //                                             </span>
            //                                         </label>
            //                                     </div>
            //                                 </div>
            //                                 <div className="col-lg-6 col-md-6">
            //                                     <div class="filter-check">
            //                                         <label>
            //                                             <input type="checkbox" checked="checked" disabled></input>
            //                                             <span>
            //                                                 Wifi
            //                                             </span>
            //                                         </label>
            //                                     </div>
            //                                 </div>
            //                             </div>
            //                             <div className="row">
            //                                 <div className="col-lg-6 col-md-6">
            //                                     <div class="filter-check">
            //                                         <label>
            //                                             <input type="checkbox" checked="checked" disabled></input>
            //                                             <span>
            //                                                 Internet Connection
            //                                             </span>
            //                                         </label>
            //                                     </div>
            //                                 </div>
            //                                 <div className="col-lg-6 col-md-6">
            //                                     <div class="filter-check">
            //                                         <label>
            //                                             <input type="checkbox" checked="checked" disabled></input>
            //                                             <span>
            //                                                 Available 2+ sockets
            //                                             </span>
            //                                         </label>
            //                                     </div>
            //                                 </div>
            //                             </div>
            //                         </div>
            //                         <div class="covid-info mt-20">
            //                             <p>
            //                                 Due to COVID-19, there are lockdowns in place across the UK. Travel is not permitted other than in limited circumstances.<a href="javascript:;">Learn more</a>
            //                             </p>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //             <div className="apc-footer">
            //                 <a href="javascript:;" onClick={() => this.setState({ Step: this.state.Step + 1, dynamicProgress: this.state.dynamicProgress + 14.2857123 })} className="btn btn-primary">Next</a>
            //             </div>
            //         </div>
            //     </div>
            // </div>

            <div className="container">
                <div className="privacy-text">
                    <h2>The Work Pod User Refund Policy</h2>
                    <p><b>Effective as of: 14th April, 2021</b></p>
                    <p>
                        These terms and conditions govern The Work Pod’s policy for User refunds (“User Refund Policy”) and the obligations of the Host associated with the User Refund Policy. The User Refund Policy applies in addition to The Work Pod’s Terms of Service (“The Work Pod Terms”). The User Refund Policy is available to Users who book and pay for a workspace through the Work Pod Platform and suffer a Pod Issue (as defined below). The User’s rights under this User Refund Policy will supersede the Host’s cancellation policy.
                    </p>
                    <p>
                        All capitalised terms shall have the meaning set forth in the Work Pod Terms or Payments Terms unless otherwise defined in this User Refund Policy. By using the Work Pod Platform as a Host or User, you are indicating that you have read and that you understand and agree to be bound by this User Refund Policy along with all other relevant terms on the website.
                    </p>
                    <h3>1.	Pod Issue</h3>
                    <p>
                        A “Pod Issue” means any one of the following:
                    </p>
                    <p>
                        <b>(a)</b> the Host of the Workspace (i) cancels a booking shortly before the scheduled start of the booking, or (ii) fails to provide the User with the reasonable ability to access the Workspace (e.g. does not provide the keys and/or a security code).
                    </p>
                    <p>
                        <b>(b)</b> the Listing’s description or depiction of the Workspace is materially inaccurate with respect to:
                    </p>
                    <ul>
                        <li>
                        	the size of the Workspace (e.g., number of guests able to be accommodated),
                        </li>
                        <li>
                        	necessities such as internet connection, advertised amenities or features represented in the Listing are not provided or do not function, such as printers, photocopiers, scanners, coffee machines and electrical, heating or air conditioning systems, or
                        </li>
                        <li>
                        	the physical location of the Workspace (proximity).
                        </li>
                    </ul>
                    <p>
                        <b>(c)</b> at the start of the User’s booking, the Workspace: (i) is not generally clean and sanitary; (ii) contains safety or health hazards that would be reasonably expected to adversely affect the User’s stay at the Workspace in The Work Pod’s judgment, or (iii) has vermin or other pests.
                    </p>
                    <h3>2.	The User Refund Policy</h3>
                    <p>
                        If you are a User and suffer a Pod Issue, you are covered by this policy as follows:
                    </p>
                    <p>
                        If you report a Pod Issue after check-in, we agree, at our discretion, to reimburse you the amount paid by you through the Work Pod Platform (“Total Fees”). The Work Pod shall decide whether an issue reported by a User qualifies as a Pod Issue. The Work Pod’s decisions under the User Refund Policy are final and binding on Users and Hosts but do not affect other contractual or statutory rights you may have. Any right that you may have to initiate legal action remains unaffected.
                    </p>
                    <h3>3.	Conditions for making a Claim</h3>
                    <p>
                        To submit a valid claim for a Pod Issue and receive the benefits with respect to your booking, you are required to meet each of the following conditions:
                    </p>
                    <p>
                        <b>(a)</b> you must be the User that booked the Workspace;
                    </p>
                    <p>
                        <b>(b)</b> you must report the Pod Issue to us in writing or via telephone within 24 hours of discovering the existence of the Pod Issue, and you must provide us with information (including photographs, videos, or other written or tangible evidence) about the Workspace and the circumstances of the Pod Issue;
                    </p>
                    <p>
                        <b>(c)</b> you must respond to any requests by us for additional information or cooperation on the Pod Issue within the time specified by The Work Pod;
                    </p>
                    <p>
                        <b>(d)</b> you must not have directly or indirectly caused the Pod Issue (through your action, omission or negligence);
                    </p>
                    <p>
                        <b>(e)</b> unless otherwise specified by The Work Pod or The Work Pod advises you that the Pod Issue cannot be remediated, you must use reasonable efforts to try to remedy the circumstances of the Pod Issue with the Host; and
                    </p>
                    <p>
                        <b>(f)</b> in order to receive a reimbursement of Total Fees, you must agree to vacate the Workspace. If you choose to stay in the Workspace, you may still qualify for a partial refund at The Work Pod’s discretion as described in this policy (regardless of whether you reported the Pod Issue up to 24 hours after check-in).
                    </p>
                    <h3>4.	Minimum Quality Standards, Host Responsibilities and Reimbursement to User</h3>
                    <p>
                        <b>4.1</b> If you are a Host, you are responsible for ensuring that the Workspaces you list on the Work Pod Platform are accessible, adequately and accurately described in the Listing description, safe and clean, and do not present a User with Pod Issues, as specified in these terms. During a User’s stay at a workspace, Hosts should be available, or make a third-party available, in order to try, in good faith, to resolve any Pod Issues or other User issues.
                    </p>
                    <p>
                        <b>4.2</b> If you are a Host, and if (i) The Work Pod determines that a User has suffered a Pod Issue related to a workspace listed by you and (ii) The Work Pod either reimburses that User (up to their Total Fees) or provides an alternative Workspace to the User, you agree to reimburse The Work Pod up to the amount paid by The Work Pod within 30 days of The Work Pod’s request. You authorise The Work Pod Payments to collect any amounts owed to The Work Pod by reducing your Payout or as otherwise permitted pursuant to the Payments Terms.
                    </p>
                    <p>
                        <b>4.3</b> If you dispute the Pod Issue, you may notify us in writing or via telephone and provide us with information (including photographs or other evidence) disputing the claims regarding the Pod Issue. In order to dispute a Pod Issue, you must use reasonable and good faith efforts to try to remedy any Pod Issue with the User unless The Work Pod advises you that the Pod Issue cannot be remediated or the User has vacated the Workspace.
                    </p>
                    <h3>5. General Provisions</h3>
                    <p>
                        <b>5.1 No Assignment/No Insurance.</b> This User Refund Policy is not intended to constitute an offer to insure, does not constitute insurance or an insurance contract, does not take the place of insurance obtained or obtainable by the User, and the User has not paid any premium in respect of the User Refund Policy. The benefits provided under this User Refund Policy are not assignable or transferable by you.
                    </p>
                    <p>
                        <b>5.2 Modification or Termination.</b> The Work Pod reserves the right to modify or terminate this User Refund Policy, at any time, in its sole discretion. If The Work Pod modifies this User Refund Policy, we will post the modification on the Work Pod Platform or provide you with notice of the modification and The Work Pod will continue to process all claims for Pod Issues made prior to the effective date of the modification according to then applicable policy.
                    </p>
                    <p>
                        <b>5.3 Entire Agreement.</b> This User Refund Policy constitutes the entire and exclusive understanding and agreement between The Work Pod and you regarding the User Refund Policy and supersedes and replaces any and all prior oral or written understandings or agreements between The Work Pod and you regarding the User Refund Policy.
                    </p>
                    <h3>6. Contacting The Work Pod</h3>
                    <p>
                        If you have any questions about the User Refund Policy, please email us.
                    </p>
                </div>
            </div>

        )
    }
}

export default UserRefundPolicy;