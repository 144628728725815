import React, { Component } from 'react'

class RequestToBook extends Component {
    constructor(props){
        super(props);
        this.state = {
            
        }
    }

    render(){
        return (
            <div className="comming-soon">
                <p>Coming soon...</p>
            </div>
        )
    }
}

export default RequestToBook
