import Homepage from "../src/components/Homepage/Homepage.js";
import AboutUs from "../src/components/AboutUs/AboutUs.js";
import ContactUs from "../src/components/ContactUs/ContactUs.js";
import PaymentTermsOfService from "../src/components/PaymentTermsOfService/PaymentTermsOfService.js";
import PrivacyPolicy from "../src/components/PrivacyPolicy/PrivacyPolicy.js";
import NonDiscriminationPolicy from "../src/components/NonDiscriminationPolicy/NonDiscriminationPolicy.js";
import FreeCancellation from "../src/components/FreeCancellation/FreeCancellation.js";
import TermsOfService from "../src/components/TermsOfService/TermsOfService.js";
import WorkPodFeatures from "../src/components/WorkPodFeatures/WorkPodFeatures.js";
import HowDoesItWork from "../src/components/HowDoesItWork/HowDoesItWork.js";
import Places from "../src/components/Places/Places.js";
import BlogPress from "../src/components/BlogPress/BlogPress.js";
import BlogDetail from "../src/components/BlogDetail/BlogDetail.js";
import FAQ from "../src/components/FAQ/FAQ.js";
import RequestToBook from "../src/components/RequestToBook/RequestToBook.js";
import Listing from "../src/components/Listing/Listing.js";
import Dashboard from "../src/components/Dashboard/Dashboard.js";
import CouponCodes from "../src/components/CouponCodes/CouponCodes.js";
import PodListing from "../src/components/Dashboard/PodListing.js";
import Bookings from "../src/components/Dashboard/Bookings.js";
import ResetPassword from "../src/components/ResetPassword/ResetPassword.js";
import verification from "../src/components/Verification/verification.js";
import Detailpage from "../src/components/Detailpage/Detailpage.js";
import Profile from "../src/components/Profile/Profile.js";
import PrepaidAccount from "../src/components/PrepaidAccount/PrepaidAccount.js";
import Registration from "../src/components/BecomeHost/Registration.js";
import UserRefundPolicy from "../src/components/UserRefundPolicy/UserRefundPolicy.js";
import ViewPropertyByAdmin from "./components/BecomeHost/ViewPropertyByAdmin.js";
import ViewEditPropertyByAdmin from "./components/BecomeHost/ViewEditPropertyByAdmin.js";
import Checkout from "../src/components/Payment/Checkout.js";
import WishList from "../src/components/WishList/WishList.js";
import ReviewListing from "../src/components/Review/ReviewListing";
import Review from "../src/components/Review/Review.js";
import FeedbackListing from "components/Review/FeedbackListing.js";
import HostListing from "components/HostList/HostListing.js";
import BookingCancellation from "components/Dashboard/BookingCancellation.js";
import ManageBlog from "../src/components/ManageBlog/ManageBlog.js";

var dashRoutes = [
    {
      path: "/homepage",
      name: "Homepage",
      component: Homepage,
      invisible : true,
      layout: "/admin",
      isAccessRequired: false
    },
    {
      path: "/requesttobook",
      name: "RequestToBook",
      component: RequestToBook,
      invisible : true,
      layout: "/admin",
      isAccessRequired: false
    },
    {
      path: "/blogpress",
      name: "BlogPress",
      component: BlogPress,
      invisible : true,
      layout: "/admin",
      isAccessRequired: false
    },
    {
      path: "/blogdetail",
      name: "BlogDetail",
      component: BlogDetail,
      invisible : true,
      layout: "/admin",
      isAccessRequired: false
    },
    {
      path: "/contactus",
      name: "ContactUs",
      component: ContactUs,
      invisible : true,
      layout: "/admin",
      isAccessRequired: false
    },
    {
      path: "/workpodfeatures",
      name: "WorkPodFeatures",
      component: WorkPodFeatures,
      invisible : true,
      layout: "/admin",
      isAccessRequired: false
    },
    {
      path: "/faq",
      name: "FAQ",
      component: FAQ,
      invisible : true,
      layout: "/admin",
      isAccessRequired: false
    },
    {
      path: "/howdoesitwork",
      name: "HowDoesItWork",
      component: HowDoesItWork,
      invisible : true,
      layout: "/admin",
      isAccessRequired: false
    },
    {
      path: "/aboutus",
      name: "AboutUs",
      component: AboutUs,
      invisible : true,
      layout: "/admin",
      isAccessRequired: false
    },
    {
      path: "/profile",
      name: "Profile",
      component: Profile,
      invisible : true,
      layout: "/admin",
      isAccessRequired: true
    },
    {
      path: "/prepaidaccount",
      name: "PrepaidAccount",
      component: PrepaidAccount,
      invisible : true,
      layout: "/admin",
      isAccessRequired: true
    },
    {
      path: "/paymenttermsofservice",
      name: "PaymentTermsOfService",
      component: PaymentTermsOfService,
      invisible : true,
      layout: "/admin",
      isAccessRequired: false
    },
    {
      path: "/privacypolicy",
      name: "PrivacyPolicy",
      component: PrivacyPolicy,
      invisible : true,
      layout: "/admin",
      isAccessRequired: false
    },
    {
      path: "/nondiscriminationpolicy",
      name: "NonDiscriminationPolicy",
      component: NonDiscriminationPolicy,
      invisible : true,
      layout: "/admin",
      isAccessRequired: false
    },
    {
      path: "/freecancellation",
      name: "FreeCancellation",
      component: FreeCancellation,
      invisible : true,
      layout: "/admin",
      isAccessRequired: false
    },
    {
      path: "/termsofservice",
      name: "TermsOfService",
      component: TermsOfService,
      invisible : true,
      layout: "/admin",
      isAccessRequired: false
    },
    {
      path: "/places",
      name: "Places",
      component: Places,
      invisible : true,
      layout: "/admin",
      isAccessRequired: false
    },
    {
      path: "/listing",
      name: "Listing",
      component: Listing,
      invisible : true,
      layout: "/admin",
      isAccessRequired: false
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
      invisible : true,
      layout: "/admin",
      isAccessRequired: true
    },
    {
      path: "/podlisting",
      name: "PodListing",
      component: PodListing,
      invisible : true,
      layout: "/admin",
      isAccessRequired: true
    },
    {
      path: "/couponcodes",
      name: "CouponCodes",
      component: CouponCodes,
      invisible : true,
      layout: "/admin",
      isAccessRequired: true
    },
    {
      path: "/bookings",
      name: "Bookings",
      component: Bookings,
      invisible : true,
      layout: "/admin",
      isAccessRequired: true
    },
    {
      path: "/bookingcancellation",
      name: "Bookings",
      component: BookingCancellation,
      invisible : true,
      layout: "/admin",
      isAccessRequired: true
    },
    {
      path: "/detailpage",
      name: "Detailpage",
      component: Detailpage,
      invisible : true,
      layout: "/admin",
      isAccessRequired: false
    },
    {
      path: "/ResetPassword",
      name: "ResetPassword",
      component: ResetPassword,
      invisible : true,
      layout: "/admin",
      isAccessRequired: false
    },
    {
      path: "/registration",
      name: "Registration",
      component: Registration,
      invisible : true,
      layout: "/admin",
      isAccessRequired: true
    },
    {
      path: "/userrefund",
      name: "UserRefundPolicy",
      component: UserRefundPolicy,
      invisible : true,
      layout: "/admin",
      isAccessRequired: false
    },
    {
      path: "/viewPodRegistered",
      name: "ViewPropertyByAdmin",
      component: ViewPropertyByAdmin,
      invisible : true,
      layout: "/admin",
      isAccessRequired: true
    },
    {
      path: "/viewProperty",
      name: "ViewEditPropertyByAdmin",
      component: ViewEditPropertyByAdmin,
      invisible : true,
      layout: "/admin",
      isAccessRequired: true
    },
    {
      path: "/checkout",
      name: "Checkout",
      component: Checkout,
      invisible : true,
      layout: "/admin",
      isAccessRequired: false
    },
    {
      path: "/wishlists",
      name: "WishList",
      component: WishList,
      invisible : true,
      layout: "/admin",
      isAccessRequired: true
    },
    {
      path: "/verification",
      name: "verification",
      component: verification,
      invisible : true,
      layout: "/admin",
      isAccessRequired: false
    },
    {
      path: "/reviewlisting",
      name: "reviewlisting",
      component: ReviewListing,
      invisible : true,
      layout: "/admin",
      isAccessRequired: true
    },
    {
      path: "/review",
      name: "review",
      component: Review,
      invisible : true,
      layout: "/admin",
      isAccessRequired: false
    },
    {
      path: "/feedbacklisting",
      name: "feedbacklisting",
      component: FeedbackListing,
      invisible : true,
      layout: "/admin",
      isAccessRequired: true
    },
    {
      path: "/hostlisting",
      name: "hostlisting",
      component: HostListing,
      invisible : true,
      layout: "/admin",
      isAccessRequired: true
    },
    {
      path: "/manageblogs",
      name: "ManageBlog",
      component: ManageBlog,
      invisible : true,
      layout: "/admin",
      isAccessRequired: true
    },
]
export default dashRoutes;