import React, { Component } from 'react'

class NonDiscriminationPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div className="container">
                <div className="privacy-text">
                    <ol className="breadcrumb">
                        <li>
                            <a
                                href="javascript:;"
                                onClick={() =>
                                    this.props.history.push("/admin/homepage")
                                }
                            >
                                <span>Home</span>
                            </a>
                        </li>
                        <li className="active">
                            <span>Non Discrimination Policy</span>
                        </li>
                    </ol>

                    <h2>Non Discrimination Policy</h2>
                    <p>
                        Last Updated: 26th March, 2021
                    </p>
                    <p>
                        The Work Pod welcomes people from every sociocultural background, and commits to ensuring everyone involved in our services feels safe and respected. This commitment rests on two foundational principles that apply both to The Work Pod’s hosts and workspace users: <b>inclusion and equality</b>. Our shared commitment to these principles enables all members of our community to feel welcome on the Work Pod platform no matter who they are, where they come from, how they worship, or whom they love. The Work Pod recognises that some jurisdictions permit, or require, distinctions among individuals based on factors such as national origin, gender, marital status or sexual orientation, and it does not require hosts to violate local laws or take actions that may subject them to legal liability. The Work Pod will provide additional guidance and adjust this non-discrimination policy to reflect such permissions and requirements in the jurisdictions where they exist.
                    </p>
                    <p>
                        We are all committed to doing everything we can to help eliminate all forms of unlawful bias, discrimination, and intolerance from our platform. We want to promote a culture within the Work Pod community—hosts, users and people just considering whether to use our platform—that goes above and beyond mere compliance. To that end, all of us; The Work Pod employees, hosts and users alike; agree to read and act in accordance with the following policy to strengthen our community and realise our mission of ensuring that everyone can belong, and feels welcome, anywhere.
                    </p>
                    <p>
                        Joining the Work Pod, as a host or user, means becoming part of a community of inclusion. Bias, prejudice, xenophobia, and hatred have no place on our platform or in our community. Hosts are required to follow all applicable laws that prohibit discrimination based on such factors as race, religion, national origin, and others listed below.
                    </p>
                    <h3>Specific Guidance for Hosts</h3>
                    <p>
                        Some jurisdictions may have additional legal requirements that expand or limit the civil right protections of the user community. Hosts are required to comply with such legal requirements, and should generally familiarise themselves with all applicable federal, state, and local laws that apply. Hosts should contact The Work Pod customer service if they have any questions about their obligations to comply with this Non-discrimination Policy. Guided by these principles, our EU, U.S. and Canadian host community will follow these rules when considering potential users and hosting users:
                    </p>
                    <p>
                        <b>Race, Colour, Ethnicity, National Origin, Religion, Sexual Orientation, Gender Identity, or Marital Status</b>
                    </p>
                    <ul>
                        <li>
                            The Work Pod hosts <b>may not:</b>
                            <ul>
                                <li>
                                    Decline a booking based on race, colour, ethnicity, national origin, religion, sexual orientation, gender identity, or marital status.
                                </li>
                                <li>
                                    Impose any different terms or conditions based on race, colour, ethnicity, national origin, religion, sexual orientation, gender identity, or marital status.
                                </li>
                                <li>
                                    Post any listing or make any statement that discourages or indicates a preference for or against any user on account of race, colour, ethnicity, national origin, religion, sexual orientation, gender identity, or marital status.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <h3>Gender Identity</h3>
                    <p>
                        The Work Pod does not assign a gender identity to our users. We consider the gender of individuals to be what they identify and/or designate on their user profiles, and we expect our The Work Pod community to do the same. This includes respecting the pronouns (he/him, she/her, they/them, etc.) any users within the community identify themselves with.
                    </p>
                    <h3>Disability</h3>
                    <ul>
                        <li>
                            The Work Pod hosts may not:
                            <ul>
                                <li>
                                    Decline a user based on any actual or perceived disability.
                                </li>
                                <li>
                                    Impose any different terms or conditions based on the fact that the user has a disability.
                                </li>
                                <li>
                                    Substitute their own judgment about whether a unit meets the needs of a user with a disability for that of the prospective user.
                                </li>
                                <li>
                                    Inquire about the existence or severity of a user’s disability, or the means used to accommodate any disability. If, however, a potential user raises his or her disability, a host may, and should, discuss with the potential user whether the listing meets the potential user’s needs.
                                </li>
                                <li>
                                    Prohibit or limit the use of mobility devices.
                                </li>
                                <li>
                                    Charge more in fees for users with disabilities, including pet fees when the user has an assistance animal (such as a service animal) because of the disability.
                                </li>
                                <li>
                                    Post any listing or make any statement that discourages or indicates a preference for or against any user on account of the fact that the user has a disability.
                                </li>
                                <li>
                                    Refuse to communicate with users through accessible means that are available, such as relay operators (for people with hearing impairments) or e-mail (for people with vision impairments using screen readers).
                                </li>
                                <li>
                                    Refuse to provide reasonable accommodations, including flexibility when users with disabilities request modest changes in your workspace rules, such as bringing a service animal that is necessary because of the disability, using an available parking space near the unit, or Type 1 diabetics bringing food into the workspace. When a user requests such an accommodation, the host and the user should engage in a dialogue to explore mutually agreeable ways to ensure the unit meets the user’s needs.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            The Work Pod hosts may:
                            <ul>
                                <li>Provide factually accurate information about the unit’s accessibility features (or lack of them), allowing for users with disabilities to assess for themselves whether the unit is appropriate to their individual needs.</li>
                                <li>Actively engage with users who have disclosed disabilities and/or have questions regarding the accessibility of a listing, and to consider reasonable requests. </li>
                            </ul>
                        </li>
                    </ul>
                    <h3>Other factors to consider</h3>
                    <p>
                        <b>When users are turned down.</b> Hosts should keep in mind that no one likes to be turned down. While a host may articulate lawful and legitimate reasons for turning down a potential user, it may cause that member of our community to feel unwelcome or excluded. Hosts should make every effort to be welcoming to users of all backgrounds. Hosts who demonstrate a pattern of rejecting users from a protected class (even while articulating legitimate reasons) undermine the strength of our community by making potential users feel unwelcome, and The Work Pod may suspend hosts who have demonstrated such a pattern from the Work Pod platform.
                    </p>
                    <h3>Specific Guidance for Hosts outside the European Union, United States and Canada</h3>
                    <p>
                        Outside of the United States, the European Union, and Canada, some countries or communities may allow or even require people to make accommodation distinctions based on, for example, marital status, national origin, gender or sexual orientation, in violation of our general non-discrimination philosophy. In these cases, we do not require hosts to violate local laws, nor to accept users that could expose the hosts to a real and demonstrable risk of arrest, or physical harm to their persons or property. Hosts who live in such areas should set out any such restriction on their ability to host particular users in their listing, so that prospective users are aware of the issue and The Work Pod can confirm the necessity for such an action. In communicating any such restrictions, we expect hosts to use clear, factual, non-derogatory terms. Slurs and insults have no place on our platform or in our community.
                    </p>
                    <h3>What happens when a host does not comply with our policies in this area?</h3>
                    <p>
                        If a particular listing contains language contrary to this non-discrimination policy, the host will be asked to remove the language and affirm his or her understanding and intent to comply with this policy and its underlying principles. The Work Pod may also, in its discretion, take steps up to and including suspending the host from the Work Pod platform.
                    </p>
                    <p>
                        If the host improperly rejects users on the basis of protected class, or uses language demonstrating that his or her actions were motivated by factors prohibited by this policy, The Work Pod will take steps to enforce this policy, up to and including suspending the host from the platform.
                    </p>
                    <p>
                        As the Work Pod community grows, we will continue to ensure that The Work Pod’s policies and practices align with our most important goal: to ensure that users and hosts feel welcome and respected in all of their interactions using the Work Pod platform. The public, our community, and we ourselves, expect no less than this.
                    </p>
                </div>
            </div>
        )
    }
}

export default NonDiscriminationPolicy
