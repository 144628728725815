import React, { Component } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar } from 'react-date-range';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import api from "../../../src/utils/apiClient";
// import { Dropdown } from 'primereact/dropdown';
import { ProgressBar } from "primereact/progressbar";
// import { Calendar } from 'primereact/calendar';
// import { MultiSelect } from 'primereact/multiselect';
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { Button } from "react-bootstrap";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { CommonConfig } from "utils/constant";
import { InputSwitch } from 'primereact/inputswitch';
import moment from "moment";
import cogoToast from "cogo-toast";
import Modal from 'react-bootstrap/Modal';

const initialState = {
    dt: null,
    podList: [],
    personType: "",
    toggleSwitch: [],
    status: "Under Review",

    couponList: [],
    key: 'AddCoupons',

    CouponCode: '',
    CouponCodeErr: false,
    CouponCodehelperText: '',

    DiscountPercentage: '',
    DiscountPercentageErr: false,
    DiscountPercentagehelperText: '',

    ExpiryDate: '',
    ExpiryDateErr: false,
    ExpiryDatehelperText: '',

    PersonID: CommonConfig.loginData().PersonID,
    showDelete: false,
    deleteItems: [],
    showExpiry: false,
    expiryItems: {}
};

class PodListing extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    handleChange = (value, rowData) => {
        let toggleSwitchNew = this.state.toggleSwitch;
        toggleSwitchNew[rowData.index] = value

        // console.log("value", value)

        this.setState({
            toggleSwitch: toggleSwitchNew
        })

        let data = {
            PropertyID: rowData.PropertyID,
            PersonType: CommonConfig.loginData().PersonType,
            Status: value ? "Active" : "Disabled",
            HostEmail: this.state.podList[0].HostEmailId,
            AdminEmail: this.state.podList[0].AdminEmailID,
            FirstName: rowData.FirstName,
            PropertyName: rowData.PropertyName,
            Reason: ''
        }

        // console.log("data", data);
        try {
            api
                .post("property/updatePropertyStatus", data)
                .then((res) => {
                    if (res.success) {
                        this.handleStatus(this.state.Status);
                        cogoToast.success(`${rowData.PropertyName} has been moved to ${data.Status.toLowerCase()} state`)
                    } else {
                    }
                })
                .catch((err) => {
                    // // console.log(err)
                });
        } catch (err) { }
    }

    componentDidMount() {
        this.getCouponCodeList();
        this.setState({
            personType: CommonConfig.loginData().PersonType,
        });
    }

    getCouponCodeList = () => {
        let data = {
            PersonID: CommonConfig.loginData().PersonID,
            PersonType: CommonConfig.loginData().PersonType,
        };
        try {
            api
                .post("utility/getCouponCode", data)
                .then((res) => {
                    if (res.success) {
                        this.setState({ couponList: res.data });
                    } else {
                    }
                })
                .catch((err) => {
                    // // console.log(err)
                });
        } catch (err) { }
    };

    filterDate = (value, filter) => {
        if (
            filter === undefined ||
            filter === null ||
            (typeof filter === "string" && filter.trim() === "")
        ) {
            return true;
        }

        if (value === undefined || value === null) {
            return false;
        }

        return value === this.formatDate(filter);
    };

    formatDate = (date) => {
        let month = date.getMonth() + 1;
        let day = date.getDate();

        if (month < 10) {
            month = "0" + month;
        }

        if (day < 10) {
            day = "0" + day;
        }

        return date.getFullYear() + "-" + month + "-" + day;
    };

    onRepresentativesChange = (e) => {
        this.state.dt.current.filter(e.value, "representative.name", "in");
        this.setState({ selectedRepresentative: e.value });
    };

    onDateChange = (e) => {
        this.state.dt.current.filter(e.value, "date", "custom");
        this.setState({ selectedDate: e.value });
    };

    onStatusChange = (e) => {
        this.state.dt.current.filter(e.value, "status", "equals");
        this.setState({ selectedStatus: e.value });
    };

    CouponCodeTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Coupon Code</span>
                {rowData.CouponCode}
            </React.Fragment>
        );
    };

    DiscountPercentageTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Discount Percentage</span>
                {parseFloat(rowData.DiscountPercentage).toFixed(2)}
            </React.Fragment>
        );
    };

    ExpiryTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Expiry</span>
                {moment(rowData.EndDate).format('DD MMM YYYY')}
            </React.Fragment>
        );
    };

    StatusTemplate = (rowData) => {
        return (<React.Fragment>
            <span className="p-column-title">Status</span>
            {rowData.Status}
        </React.Fragment>)
    };

    ActionTemplate = (rowData) => {
        return (<React.Fragment>
            <div className="tbl-actions">
                <InputSwitch checked={rowData.Status === "Active" ? true : false} onChange={(e) => {
                    if (Date.parse(rowData.EndDate) < Date.parse(new Date())) {
                        this.setState({
                            expiryItems: rowData,
                            showExpiry: true
                        })
                    } else {
                        this.update(e, rowData, "Inactive")
                    }
                }} />
                <a href="javascript:;" className="view-btn"><i className="pi pi-trash" onClick={(e) => this.setState({
                    deleteItems: rowData,
                    showDelete: true
                })}></i></a>
            </div>
        </React.Fragment>
        );
    };

    reasonTemplate = (rowData) => {
        const { personType } = this.state;
        return (
            <React.Fragment>
                <span className="p-column-title">Reason</span>
                {rowData.Reason}
            </React.Fragment>
        );
    };

    WashRoomsTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">WashRooms</span>
                {rowData.BathroomCount}
            </React.Fragment>
        );
    };

    LocationTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">PropertyAddress</span>
                {rowData.PropertyAddress}
            </React.Fragment>
        );
    };

    LastModifiedTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">UpdatedOn</span>
                {moment(rowData.UpdatedOn).format("DD-MM-YYYY HH:mm A")}
            </React.Fragment>
        );
    };

    countryBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Country</span>
                <img
                    alt="flag"
                    src="showcase/demo/images/flag_placeholder.png"
                    onError={(e) =>
                    (e.target.src =
                        "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
                    }
                    className={`flag flag-${rowData.country.code}`}
                    width={30}
                />
                <span className="image-text">{rowData.country.name}</span>
            </React.Fragment>
        );
    };

    representativeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Representative</span>
                <img
                    alt={rowData.representative.name}
                    src={`showcase/demo/images/avatar/${rowData.representative.image}`}
                    onError={(e) =>
                    (e.target.src =
                        "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
                    }
                    width={32}
                    style={{ verticalAlign: "middle" }}
                />
                <span className="image-text">{rowData.representative.name}</span>
            </React.Fragment>
        );
    };

    dateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Date</span>
                <span>{rowData.date}</span>
            </React.Fragment>
        );
    };

    statusBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Status</span>
                <span className={`customer-badge status-${rowData.status}`}>
                    {rowData.status}
                </span>
            </React.Fragment>
        );
    };

    activityBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Activity</span>
                <ProgressBar value={rowData.activity} showValue={false} />
            </React.Fragment>
        );
    };

    representativesItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <img
                    alt={option.name}
                    src={`showcase/demo/images/avatar/${option.image}`}
                    onError={(e) =>
                    (e.target.src =
                        "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
                    }
                    width={32}
                    style={{ verticalAlign: "middle" }}
                />
                <span className="image-text">{option.name}</span>
            </div>
        );
    };

    statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    };

    header = () => {
        return (
            <div className="table-header">
                List of Pods
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search"
                        onInput={(e) => this.setState({ globalFilter: e.target.value })}
                        placeholder="Global Search"
                    />
                </span>
            </div>
        );
    };

    handleView = (propertyID) => {
        this.props.history.push({
            pathname: "/admin/viewPodRegistered",
            state: { PropertyID: propertyID, UserID: 1 },
        });
    };

    handleStatus = (status) => {
        let data = {
            PersonID: CommonConfig.loginData().PersonID,
            PersonType: CommonConfig.loginData().PersonType,
            Status: status
        }
        //   if(status=='Active') this.setState({toggleSwitch : this.state.toggleSwitch.map(x=>true)}) 
        //   else this.setState({toggleSwitch : this.state.toggleSwitch.map(x=>false)}) 
        this.setState({
            Status: status,
        })
        try {
            api
                .post("property/listpropertybystatus", data)
                .then((res) => {
                    if (res.success) {
                        var i = 0;
                        res.data.map(OBJ => {
                            OBJ.Index = i;
                            i++;
                        });
                        this.setState({ podList: res.data });
                        this.setState({
                            toggleSwitch: status == "Active" ? res.data.map(x => true) : res.data.map(x => false)
                        })
                    } else {
                    }
                })
                .catch((err) => {
                    // // console.log(err)
                });
        } catch (err) { }
    }

    validate = (event) => {
        event.preventDefault();
        const { CouponCode, DiscountPercentage, ExpiryDate } = this.state;
        let IsValid = true;

        if (CommonConfig.isEmpty(CouponCode)) {
            IsValid = false;
            this.setState({ CouponCodeErr: true, CouponCodehelperText: 'Please enter coupon code.' })
        }
        else {
            this.setState({ CouponCodeErr: false, CouponCodehelperText: '' })
        }
        if (CommonConfig.isEmpty(DiscountPercentage)) {
            IsValid = false;
            this.setState({ DiscountPercentageErr: true, DiscountPercentagehelperText: 'Please enter discount percentage.' })
        }
        else {
            this.setState({ DiscountPercentageErr: false, DiscountPercentagehelperText: '' })
        }
        if (CommonConfig.isEmpty(ExpiryDate)) {
            IsValid = false;
            this.setState({ ExpiryDateErr: true, ExpiryDatehelperText: 'Please enter expiry date.' })
        }
        else {
            this.setState({ ExpiryDateErr: false, ExpiryDatehelperText: '' })
        }
        return IsValid;
    }

    update = (event, rowData, type, submitStatus) => {
        event.preventDefault();
        let data = {};
        if (type === "Inactive") {
            data = {
                CouponID: rowData.CouponID,
                CouponStatus: event.target.value == true || submitStatus ? "Active" : "Inactive",
                ExpiryDate: !CommonConfig.isObjectEmpty(this.state.expiryItems) ? moment(this.state.ExpiryDate).format('YYYY-MM-DD HH:mm:ss').toString() : moment(rowData.EndDate).format('YYYY-MM-DD HH:mm:ss').toString(),
                UpdatedBy: this.state.PersonID,
                Type: "CouponInactive"
            }
        } else {
            data = {
                CouponID: rowData.CouponID,
                UpdatedBy: this.state.PersonID,
                Type: "Inactive"
            }
        }
        
        if (!CommonConfig.isObjectEmpty(this.state.expiryItems)) {
            if (CommonConfig.isEmpty(this.state.ExpiryDate)) {
                this.setState({ ExpiryDateErr: true, ExpiryDatehelperText: 'Please enter expiry date.' })
            }
            else {
                try {
                    api.post("utility/addUpdateInactiveDeleteCoupon", data).then(res => {
                        if (res.success) {
                            if (type === "Inactive") {
                                cogoToast.success(`Coupon code ${rowData.CouponCode} is now ${event.target.value == true ? "activated" : "deactivated"}.`)
                            } else {
                                cogoToast.success(`Coupon code ${rowData.CouponCode} deleted successsfully.`)
                            }
                            this.setState(initialState);
                            this.setState({ key: "ViewCoupons" })
                            this.getCouponCodeList();
                        }
                        else {
                        }
                    }).catch(err => {
                        // console.log(err)
                    })
                } catch (err) {
                }
            }
        } else {
            try {
                api.post("utility/addUpdateInactiveDeleteCoupon", data).then(res => {
                    if (res.success) {
                        if (type === "Inactive") {
                            cogoToast.success(`Coupon code ${rowData.CouponCode} is now ${event.target.value == true ? "activated" : "deactivated"}.`)
                        } else {
                            cogoToast.success(`Coupon code ${rowData.CouponCode} deleted successsfully.`)
                        }
                        this.setState(initialState);
                        this.setState({ key: "ViewCoupons" })
                        this.getCouponCodeList();
                    }
                    else {
                    }
                }).catch(err => {
                    // console.log(err)
                })
            } catch (err) {
            }
        }
    }

    submit = (event) => {
        if (this.validate(event)) {
            let data = {
                CouponCode: this.state.CouponCode,
                DiscountPercentage: this.state.DiscountPercentage,
                ExpiryDate: moment(this.state.ExpiryDate).format('YYYY-MM-DD HH:mm:ss').toString(),
                CreatedBy: CommonConfig.loginData().PersonID,
                Type: "Insert"
            }
            try {
                api.post("utility/addUpdateInactiveDeleteCoupon", data).then(res => {
                    if (res.success) {
                        window.scrollTo(0, 0)
                        this.setState(initialState);
                        this.getCouponCodeList();
                        cogoToast.success('Coupon code added successfully.')
                    }
                    else {
                    }
                }).catch(err => {
                    // console.log(err)
                })
            } catch (err) {
            }
        }
    }

    handleChangeCoupon = (e, type) => {
        if (type == "CouponCode") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ CouponCode: e.target.value, CouponCodeErr: true, CouponCodehelperText: 'Please enter coupon code.' })
            } else {
                this.setState({ CouponCode: e.target.value, CouponCodeErr: false, CouponCodehelperText: '' })
            }
        }
        if (type == "DiscountPercentage") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ DiscountPercentage: e.target.value, DiscountPercentageErr: true, DiscountPercentagehelperText: 'Please enter discount percentage.' })
            } else {
                this.setState({ DiscountPercentage: e.target.value, DiscountPercentageErr: false, DiscountPercentagehelperText: '' })
            }
        }
        if (type == "ExpiryDate") {
            if (CommonConfig.isEmpty(e)) {
                this.setState({ ExpiryDate: e, ExpiryDateErr: true, ExpiryDatehelperText: 'Please enter expiry date.' })
            } else {
                if(!this.state.showExpiry){
                    this.datepick.click();
                }
                else this.modalDatepick.click();
                this.setState({ ExpiryDate: e, ExpiryDateErr: false, ExpiryDatehelperText: '' })
            }
        }
    }

    render() {
        const { dt, podList, header, globalFilter, personType, couponList } = this.state;
        return (
            <div className="datatable-filter-demo">
                <div className="host-dashboard-outer">
                    <div className="container">
                        <div className="host-page-heading">
                            <h2>Coupon Code</h2>
                        </div>
                        <Tabs
                            id="controlled-tab-coupon-example"
                            className="requests-tab-nav"
                            defaultActiveKey="AddCoupons"
                            activeKey={this.state.key}
                            onSelect={(k) => this.setState({ key: k, ExpiryDate: '', ExpiryDateErr: false, ExpiryDatehelperText: '' })}
                        >
                            <Tab eventKey="AddCoupons" title="Add Coupon">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Coupon Code</label>
                                            <input type="text" className={this.state.CouponCodeErr ? "form-control is-invalid" : "form-control"} placeholder="E.g. WINTER20" value={this.state.CouponCode} onChange={(e) => this.handleChangeCoupon(e, "CouponCode")} />
                                            <div className="invalid-feedback" >{this.state.CouponCodehelperText}</div>
                                        </div>
                                        <div className="form-group">
                                            <label>Discount (%)</label>
                                            <input type="number" className={this.state.DiscountPercentageErr ? "form-control is-invalid" : "form-control"} placeholder="E.g. 10%" value={this.state.DiscountPercentage} onChange={(e) => this.handleChangeCoupon(e, "DiscountPercentage")} />
                                            <div className="invalid-feedback" >{this.state.DiscountPercentagehelperText}</div>
                                        </div>
                                        <div className="form-group">
                                            <label>Expiration Date</label>
                                            <OverlayTrigger
                                                trigger="click"
                                                placement="bottom-start"
                                                rootClose={true}
                                                overlay={
                                                    <Popover>
                                                        <Calendar onChange={item => this.handleChangeCoupon(item, "ExpiryDate")}
                                                            date={this.state.ExpiryDate} minDate={new Date()} />
                                                    </Popover>
                                                }
                                            >
                                                <input 
                                                ref = {datepick => this.datepick = datepick}
                                                type="text" className={this.state.ExpiryDateErr ? "form-control is-invalid" : "form-control"} placeholder="E.g. 11 Mar 2020" value={this.state.ExpiryDate ? moment(this.state.ExpiryDate).format('DD MMM YYYY') : ''} readOnly />
                                            </OverlayTrigger>
                                            <div className="invalid-feedback" >{this.state.ExpiryDatehelperText}</div>
                                        </div>
                                        <div className="apc-footer">
                                            <button onClick={() => this.setState(initialState)} className="btn"><i className="fas fa-times"></i> Reset</button>
                                            <button className="btn btn-primary" onClick={(event) => this.submit(event)}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="ViewCoupons" title="View Coupons">
                                <DataTable
                                    ref={dt}
                                    value={couponList}
                                    paginator
                                    rows={10}
                                    header={header}
                                    className="p-datatable-customers"
                                    globalFilter={globalFilter}
                                    emptyMessage="No coupon codes found."
                                >
                                    <Column
                                        field="CouponCode"
                                        header="Coupon Code"
                                        body={this.CouponCodeTemplate}
                                        filter
                                        filterPlaceholder="Search by Coupon Code"
                                    />
                                    <Column
                                        field="DiscountPercentage"
                                        header="Discount (%)"
                                        sortable
                                        body={this.DiscountPercentageTemplate}
                                    />
                                    <Column
                                        field="ExpiryDate"
                                        header="Expires On"
                                        body={this.ExpiryTemplate}
                                        sortable
                                    />
                                    <Column
                                        field="Status"
                                        filterField="status"
                                        header={"Status"}
                                        body={this.StatusTemplate}
                                    />
                                    <Column
                                        header="Action"
                                        body={this.ActionTemplate}
                                    />
                                    <Column
                                        field="UpdatedOn"
                                        header="Last Modified"
                                        body={this.LastModifiedTemplate}
                                    // sortable
                                    />
                                </DataTable>
                                <Modal show={this.state.showDelete} onHide={() => this.setState({
                                    deleteItems: [],
                                    showDelete: false
                                })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Delete Coupon</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>Are you sure you want to delete coupon?</Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={() => this.setState({
                                            deleteItems: [],
                                            showDelete: false
                                        })}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" onClick={(e) => this.update(e, this.state.deleteItems, "Delete")}>
                                            Delete
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                <Modal show={this.state.showExpiry} onHide={() => this.setState({
                                    expiryItems: [],
                                    showExpiry: false,
                                    ExpiryDate: '', ExpiryDateErr: false, ExpiryDatehelperText: ''
                                })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Expiration Date</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="form-group">
                                            <label>Expiration Date</label>
                                            <OverlayTrigger
                                                trigger="click"
                                                placement="bottom-start"
                                                rootClose={true}
                                                overlay={
                                                    <Popover>
                                                        <Calendar onChange={item => this.handleChangeCoupon(item, "ExpiryDate")}
                                                            date={this.state.ExpiryDate} minDate={new Date()} />
                                                    </Popover>
                                                }
                                            >
                                                <input 
                                                ref = {modalDatepick => this.modalDatepick = modalDatepick}
                                                type="text" className={this.state.ExpiryDateErr ? "form-control is-invalid" : "form-control"} 
                                                placeholder="E.g. 11 Mar 2020" 
                                                value={this.state.ExpiryDate ? moment(this.state.ExpiryDate).format('DD MMM YYYY') : ''} readOnly
                                                />
                                            </OverlayTrigger>
                                            <div className="invalid-feedback" >{this.state.ExpiryDatehelperText}</div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={() => this.setState({
                                            expiryItems: [],
                                            showExpiry: false,
                                            ExpiryDate: '', ExpiryDateErr: false, ExpiryDatehelperText: ''
                                        })}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" onClick={(e) => this.update(e, this.state.expiryItems, "Inactive",true)}>
                                            Submit
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }
}

export default PodListing;
