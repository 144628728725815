import React, { Component } from "react";
import api from "../../../src/utils/apiClient";
import { CommonConfig } from "../../utils/constant";
import StickyBox from "react-sticky-box";
import moment from "moment";
import Form from "react-bootstrap/Form";
import { apiBase } from "../../../src/utils/config";
import Col from "react-bootstrap/Col";
import cogoToast from "cogo-toast";
import Header from "../Header/Header.js";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import tick from "../../assets/img/booking-confirm.svg";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import logo from "../../assets/img/logo-white.svg";
import { withNamespaces } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import SlideToggle from "react-slide-toggle";
import parse from "html-react-parser";
import Loader from "../../../src/utils/general";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

class BookingCancellation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      reasonList: [],
      reason: "",
      reasonErr: false,
      customizedReason: "",
      customizedReasonErr: false,
      customizedReasonHelperText: "",
      BookingID: this.props.location.state
        ? this.props.location.state.BookingID
        : "",
      isChecked: false,
      CancellationDetails: {},
      IsYesSelected: false,
      IsNoSelected: false
    };
  }

  componentDidMount = () => {
    this.getReasonsList();
    this.getBookingCancellationDetails();
  };

  getBookingCancellationDetails = () => {
    let data = {
      BookingID: this.state.BookingID,
    };
    try {
      api
        .post("property/getBookingCancellationDetails", data)
        .then((res) => {
          if (res.success) {
            res.data.map(OBJ => {
              OBJ.DayType === 'Multi Day' ? OBJ.isChecked = false : OBJ.isChecked = true;
              OBJ.Slots = OBJ.Slots.split(",");
              OBJ.Slots = OBJ.Slots.map(obj => {
                obj = {
                  Time: obj,
                  isChecked: false,
                };
                return obj;
              })
              OBJ.Slots.length === 1 && res.data.length === 1 ? OBJ.Slots[0].isChecked = true : OBJ.Slots[0].isChecked = false;
            })
            this.setState({
              bookingDetails: res.data,
            });
            // console.log("bookingDetails..", this.state.bookingDetails)
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  };

  getReasonsList = () => {
    let data = {
      StringMapType: "BOOKINGCANCELLATION",
    };
    try {
      api
        .post("utility/getStringMap", data)
        .then((res) => {
          if (res.success) {
            this.setState({
              reasonList: res.data.map((x) => ({
                label: x.StringMapName,
                value: x.StringMapName,
              })),
            });
            // console.log("reason...", this.state.reasonList);
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  };

  handleChange = (e, type, idx, i) => {
    
    if (type === "SELECTREASON") {
      if (e.value == "Others") {
        this.setState({
          reason: e,
          reasonErr: false,
        });
      } else {
        this.setState({
          reason: e,
          customizedReason: "",
          customizedReasonErr: false,
          customizedReasonHelperText: "",
        });
      }
    } else if ((type === "CUSTOMREASON")) {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          customizedReason: e.target.value,
          customizedReasonErr: true,
          customizedReasonHelperText: "Please enter reason",
        });
      } else {
        this.setState({
          customizedReason: e.target.value,
          customizedReasonErr: false,
          customizedReasonHelperText: "",
        });
      }
    }
    if (type === "SELECTDATE") {
      let bookingDetailsNew = this.state.bookingDetails;
      bookingDetailsNew[idx].isChecked = e.target.checked
      bookingDetailsNew[idx].Slots.map(x => {
        x.isChecked = e.target.checked
      })
      this.setState({
        bookingDetails: bookingDetailsNew
      })
    }
    if (type === "SELECTSLOT") {

      let bookingDetailsNew = this.state.bookingDetails;
      bookingDetailsNew[idx].Slots[i].isChecked = e.target.checked
      bookingDetailsNew[idx].isChecked = bookingDetailsNew[idx].Slots.filter(x => x.isChecked).length === bookingDetailsNew[idx].Slots.length ? true : false
      this.setState({
        bookingDetails: bookingDetailsNew
      })
    }
    if (type === "SELECTALL") {
      let bookingDetailsNew = this.state.bookingDetails;
      bookingDetailsNew.map((item) => {
        item.isChecked = e.target.checked;
        item.Slots.map(x => {
          x.isChecked = e.target.checked
        })
      })
      this.setState({
        bookingDetails: bookingDetailsNew
      })
      // console.log("e....", bookingDetailsNew);
    }
  };

  getBookingCancellationRefundDetails = () => {
    let slotList = this.state.bookingDetails.map(x => x.SlotList.split(",").filter((y, i) => x.Slots[i].isChecked
    )).filter(z => z.length > 0).toString();
    let data = {
      PersonID: CommonConfig.loginData().PersonID,
      BookingID: this.state.BookingID,
      //   Slots: this.state.bookingDetails.filter(x => x.isChecked === true).map(x => x.SlotList).toString(),
      Slots: slotList,
      Reason: this.state.reason.value === 'Others' ? this.state.customizedReason : this.state.reason.value,
      Status: "Cancelled",
      BookingCancellationID: ''
    };
    try {

      api
        .post("property/bookingCancellation", data)
        .then((res) => {
          if (res.success) {
            // console.log("data...", data)
            this.setState({ CancellationDetails: res.data })
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  }

  completeCancellation = () => {
    let data = {
      PersonID: CommonConfig.loginData().PersonID,
      BookingID: this.state.BookingID,
      Slots: this.state.bookingDetails.map(x => x.SlotList.split(",").filter((y, i) => x.Slots[i].isChecked
      )).filter(z => z.length > 0).toString(),
      Reason: this.state.reason.value === 'Others' ? this.state.customizedReason : this.state.reason.value,
      Status: "Cancelled",
      BookingCancellationID: this.state.CancellationDetails.BookingCancellationID
    };
    try {

      api
        .post("property/bookingCancellation", data)
        .then((res) => {
          if (res.success) {
            this.props.history.push('/admin/bookings');
            cogoToast.success("Booking cancellation request made successfully.")
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  }

  handleNext = () => {
    if (this.validate()) {
      if (this.state.step === 1) {
        this.getBookingCancellationRefundDetails();
      }
      this.setState({
        step: this.state.step + 1,
      });
    }
  };

  validate = () => {
    let isValid = true;
    const { step, reason, customizedReason } = this.state;
    if (step === 0) {
      if (CommonConfig.isEmpty(reason)) {
        isValid = false;
        this.setState({
          reasonErr: true,
          customizedReasonHelperText: "Please enter reason",
        });
        cogoToast.error("Please select reason");
      } else if (reason.value === "Others") {
        if (CommonConfig.isEmpty(customizedReason)) {
          isValid = false;
          this.setState({
            customizedReasonErr: true,
            customizedReasonHelperText: "Please enter reason",
          });
        }
      }
    }
    if (step === 1) {
      let bookingDetailsNew = this.state.bookingDetails.map(x => x.Slots.filter(x => x.isChecked)).filter(y => y.length > 0);
      if (bookingDetailsNew.length === 0) {
        isValid = false
        cogoToast.error('Please select the slot(s)')
      }
    }
    return isValid;
  };

  handlePrev = () => {
    this.setState({
      step: this.state.step - 1,
    })
  }

  cancel = () => {
    this.props.history.push('/admin/bookings');
  }

  render() {
    const { loading, step } = this.state;
    const { ...rest } = this.props;

    let dayType = this.state.bookingDetails ? this.state.bookingDetails[0].DayType : null

    return loading ? (
      <Loader />
    ) : (
      <div className="checkout-outer">
        <div className="container">
          <div className="wizard-step">
            <ul>
              <li>
                <a
                  href="javascript:;"
                  className={step === 0 ? "active" : "completed"}
                >
                  <span>1</span>
                  <p>Select Reason</p>
                </a>
              </li>
              <li>
                <a
                  href="javascript:;"
                  className={
                    step === 1
                      ? "active"
                      : step === 2
                        ? "completed"
                        : "pending"
                  }
                >
                  <span>2</span>
                  <p>Select Slots</p>
                </a>
              </li>
              <li>
                <a
                  href="javascript:;"
                  className={step == 2 ? "active" : step === 3 ? "completed" : "pending"}
                >
                  <span>3</span>
                  <p>Refund</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="container">



          {step == 0 ? (
            <div className="row align-items-center justify-content-center">
              <div className="col-md-5 col-lg-5">
                <div className="radio-button">
                  <div className="apc-heading">
                    <h3>Are you sure you want to cancel?</h3>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline1" name="customRadioInline1" class="custom-control-input" checked={this.state.IsYesSelected} onChange={(e) => this.setState({ IsYesSelected: true })}></input>
                    <label class="custom-control-label" for="customRadioInline1">Yes</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline2" name="customRadioInline1" class="custom-control-input" onChange={(e) => this.props.history.goBack(-1)}></input>
                    <label class="custom-control-label" for="customRadioInline2">No</label>
                  </div>
                </div>
                {this.state.IsYesSelected ?
                  <>
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="apc-heading">
                          <h3>Why do you need to cancel?</h3>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="apc-heading">
                          <h5>Please select a reason</h5>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <Select
                            className={[
                              // this.state.reasonErr
                              //   ? "form-control mb-10 is-invalid"
                              //   :
                              "mb-10"
                              //  ,
                            ]}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            placeholder={"Select reason"}
                            value={this.state.reason}
                            options={this.state.reasonList}
                            onChange={(e) => {
                              this.handleChange(e, "SELECTREASON");
                            }}
                          />
                          {this.state.reason &&
                            this.state.reason.value == "Others" ? (
                            <input
                              type="text"
                              className={[
                                this.state.reason.value == "Others" &&
                                  this.state.customizedReasonErr
                                  ? "form-control mb-10 is-invalid"
                                  : "form-control mb-10",
                              ]}
                              maxLength={100}
                              placeholder="Enter the reason"
                              value={this.state.customizedReason}
                              onChange={(e) => this.handleChange(e, "CUSTOMREASON")}
                            />
                          ) : null}

                          <div className="invalid-feedback d-block">
                            {this.state.customizedReasonHelperText}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <button
                          type="submit"
                          onClick={() => this.handleNext()}
                          className="btn btn-primary float-right"
                        >
                          Continue
                  </button>
                      </div>
                    </div>
                  </> : null}
              </div>
            </div>
          ) : step === 1 ? (
            <div>
              {/* {dayType === 'Multi Day' ?
                <div className="row">
                  <div className="form-check form-check-inline mb-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="feature-later"
                      checked={this.state.checked}
                      onChange={(e) => this.handleChange(e, "SELECTALL")}
                    ></input>
                    <label className="form-check-label" for="feature-later">
                      Select All
                      </label>
                  </div>
                </div> : null} */}

              <div className="specify-slot">
                <div className="apc-heading">
                  {this.state.bookingDetails && this.state.bookingDetails[0].Slots.length > 1 ? <h3>You seem to have multiple slot booked, please indicate which slot to cancel.</h3> : null}
                </div>
                <div className="cancel-slot-table">
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="thead-light ">
                        <tr>
                          <th>
                            {dayType === 'Multi Day' ?
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="customse;ectCheck1" checked={this.state.checked}
                                  onChange={(e) => this.handleChange(e, "SELECTALL")}></input>
                                <label class="custom-control-label" for="customse;ectCheck1"></label>
                              </div>
                              : null
                            }
                          </th>
                          <th>Date</th>
                          <th>Timeslot</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.bookingDetails && this.state.bookingDetails.map((item, idx) => {
                          return (
                            <tr>
                              <td>
                                {dayType === 'Multi Day' ?
                                  <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id={"customCheck2" + idx} checked={item.isChecked} onChange={e => this.handleChange(e, "SELECTDATE", idx)}></input>
                                    <label class="custom-control-label" for={"customCheck2" + idx}></label>
                                  </div>
                                  : null
                                }
                              </td>
                              <td>{item.SlotDate}</td>
                              <td className="d-flex align-items-center">{item.Slots.map((content, i) => {
                                return (
                                  this.state.bookingDetails.length === 1 && item.Slots.length === 1 ?
                                    <div className="custom-control custom-checkbox">
                                      <label for={"customcheck" + i}>{content.Time} </label>
                                    </div>
                                    :
                                    <div className="custom-control custom-checkbox">
                                      <input type="checkbox" className="custom-control-input" checked={content.isChecked} onChange={(e) => this.handleChange(e, "SELECTSLOT", idx, i)} id={"customcheck" + i} />
                                      <label class="custom-control-label" for={"customcheck" + i}>{content.Time} </label>
                                      &nbsp;
                                    </div>
                                )
                              })}</td>
                            </tr>

                          );
                        })}
                        {/* <tr>
                            <td>
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="customCheck2"></input>
                                <label class="custom-control-label" for="customCheck2"></label>
                              </div>
                            </td>
                            <td>Tuesday 15 June, 2021</td>
                            <td>09:00 - 13:00 13:00 - 17:00</td>
                          </tr>
                          <tr>
                            <td>
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="customCheck3"></input>
                                <label class="custom-control-label" for="customCheck3"></label>
                              </div>
                            </td>
                            <td>Tuesday 15 June, 2021</td>
                            <td>09:00 - 13:00 13:00 - 17:00</td>
                          </tr>
                          <tr>
                            <td>
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="customCheck4"></input>
                                <label class="custom-control-label" for="customCheck4"></label>
                              </div>
                            </td>
                            <td>Tuesday 15 June, 2021</td>
                            <td>09:00 - 13:00 13:00 - 17:00</td>
                          </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>

              {/* <div className="row">
                <div className="col-md-4">
                  {" "}
                  <h6>Date</h6>{" "}
                </div>
                <div className="col-md-4">
                  <h6>Time Slots</h6>
                </div>
              </div> */}


              {/* {this.state.bookingDetails.map((item, idx) => {
                return (
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-check form-check-inline mb-2">
                        {dayType === 'Multi Day' ?
                          <input
                            checked={item.isChecked}
                            type="checkbox"
                            className="form-check-input"
                            onChange={e => this.handleChange(e, "SELECTDATE", idx)}
                          ></input> : null}
                        <label className="form-check-label">
                          {" "}
                          {item.SlotDate}{" "}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-8">
                      {item.Slots.split(",").map(slot => {
                        return <span>{slot}  </span>
                      })}
                    </div>
                  </div>
                );
              })} */}

              <div className="rb-complete d-flex align-items-center justify-content-end">
                <button className="btn btn-secondary mr-2" onClick={() => this.handlePrev()}>Back</button>
                <button className="btn btn-success" onClick={() => this.handleNext()}>Continue</button>
              </div>
              {/* <button className="btn btn-primary" onClick={() => this.handleNext()}>Proceed Cancellation</button> */}

            </div>
          ) : (

            <div className="row align-items-center justify-content-center">
              <div className="col-lg-5 col-md-5">
                <div className="refund-box">
                  <div className="rb-inner">
                    <p>Amount Paid while Booking</p>
                    <span>{this.state.CancellationDetails.AmountDueAfterDiscount}</span>
                  </div>
                  <div className="rb-inner">
                    <p>Amount Refund</p>
                    <span>{this.state.CancellationDetails.RefundAmount}</span>
                  </div>
                </div>
                <div className="rb-complete d-flex align-items-center justify-content-end">
                  <button className="btn btn-secondary mr-2" onClick={() => this.cancel()}>Cancel</button>
                  <button className="btn btn-success" onClick={() => this.completeCancellation()}>Complete</button>
                </div>
              </div>
            </div>




          )}
        </div>

        {this.state.showLoginModal ? (
          <Header
            showLoginModal={this.state.showLoginModal}
            hideLoginModal={() => this.setState({ showLoginModal: false })}
            {...rest}
          />
        ) : (
          false
        )}
      </div>
    );
  }
}

export default withNamespaces()(BookingCancellation);