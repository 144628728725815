import React, { Component } from "react";
import { apiBase } from "../../../src/utils/config";
import avatar from "../../assets/img/avatar.jpg";
import api from "../../../src/utils/apiClient";
import listingimage from "../../assets/img/listing-image.jpg";
import wifiIcon from "../../assets/img/wifi.svg";
import Col from "react-bootstrap/Col";
import { CommonConfig } from "../../utils/constant";
import Form from "react-bootstrap/Form";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import StarRatings from 'react-star-ratings';
// import {
//     geocodeByAddress,
//     getLatLng,
// } from 'react-places-autocomplete';
import parse from "html-react-parser";
import Slider from "react-slick";
import { Calendar } from "react-date-range";
import moment from "moment";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Alert from "react-bootstrap/Alert";
import Popover from "react-bootstrap/Popover";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import cogoToast from "cogo-toast";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import StickyBox from "react-sticky-box";
import Header from "../Header/Header.js";
import tick from "../../assets/img/booking-confirm.svg";


const animatedComponents = makeAnimated();

class Detailpage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDateRange: this.props.location.state
        ? this.props.location.state.selectedDateRange ? this.props.location.state.selectedDateRange
          : [
            {
              startDate: new Date(),
              endDate: new Date(),
              key: "selection",
            },
          ] : [],
      // similarPodList: this.props.location.state && this.props.location.state.similarPodList ? this.props.location.state.similarPodList : [],
      day:
        this.props.location.state && this.props.location.state.day
          ? this.props.location.state.day
          : "Single Day",
      ArriveTime:
        this.props.location.state && this.props.location.state.ArriveTime
          ? this.props.location.state.ArriveTime
          : [],
      DepartTime:
        this.props.location.state && this.props.location.state.DepartTime
          ? this.props.location.state.DepartTime
          : [],
      Time:
        this.props.location.state && this.props.location.state.Time
          ? this.props.location.state.Time
          : [],
      singleDate:
        this.props.location.state && this.props.location.state.singleDate
          ? this.props.location.state.singleDate
          : "",
      Users:
        this.props.location.state && this.props.location.state.Users
          ? this.props.location.state.Users
          : 1,
      latitude:
        this.props.location.state && this.props.location.state.latitude
          ? this.props.location.state.latitude
          : "",
      longitude:
        this.props.location.state && this.props.location.state.longitude
          ? this.props.location.state.longitude
          : "",
      dayTypeList: [],
      timeSlotList: [],
      noOfUsersList: [],
      photoIndex: 0,
      isOpen: false,
      Price: "",
      nearByTransportList: [],
      standardAmenitiesList: [],
      propertyDetail: {
        HostRating : [],
      },
      TotalBookingDays: [],
      PriceData: [],
      IsSameSlotSelected: false,
      IsFullDaySelected: false,
      IsSlotSelected: false,
      IsArriveFullDaySelected: false,
      IsArriveSlotSelected: false,
      IsDepartFullDaySelected: false,
      IsDepartSlotSelected: false,
      IsMobileCheckOutActive: false,

      showContactHost: false,
      Name: "",
      NameErr: false,
      NamehelperText: "",

      EmailAddress: "",
      EmailAddressErr: false,
      EmailAddresshelperText: "",

      Message: "",
      MessageErr: false,
      MessagehelperText: "",

      showFavModal: false,
      FavPropertyID: "",
      WishListName: "",
      WishListNameErr: false,
      WishListNamehelperText: "",
      items: [],
      showLoginModal: false,
      UsersCount: "",
      disabledDates: [],
      tempTimeSlotList: [],
      filteredSlotArray: [],
      filteredSlotArrayDivided: [],
      isDateSelected: false,
      bookingHistoryDetail: [],
      index: 0
    };
  }

  componentDidMount() {
    
    if (this.props.location.state && !CommonConfig.isEmpty(this.props.location.state.BookingID)) {
      this.getBookingHistoryDetail(this.props.location.state.BookingID);
    }
    this.getTimeSlotList();
    this.getPodDetails();
    this.getDayTypeList();
    this.getStandardAmenitiesList();
  }

  getBookingHistoryDetail = (BookingID) => {
    let data = {
      BookingID: BookingID,
    };
    try {
      api
        .post("property/getBookingHistoryDetail", data)
        .then((res) => {
          if (res.success) {
            this.setState({ bookingHistoryDetail: res.data });
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  }

  detailspage = (id) => {
    window.location.reload();
    this.props.history.push({
      pathname: "/admin/detailpage",
      state: {
        PropertyID: id,
      },
    });
    // // console.log("id...", content);
  };

  getSimilarListing = (PropertyID) => {
    let data = {
      PropertyID: PropertyID,
    };
    try {
      api
        .post("property/showSimilarListing", data)
        .then((res) => {
          if (res.success) {
            this.setState({ similarPodList: res.data });
          } else {
          }
        })

        .catch((error) => {
          // // console.log("error", error);
        });
    } catch (err) {
      // // console.log("error", err);
    }
  };

  initMap(response) {
    const google = window.google;
    var geocoder = new google.maps.Geocoder();
    var latlng = new google.maps.LatLng(response.Latitude, response.Longitude);
    try {
      fetch("https://app.cors.bridged.cc/https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=" +
          response.Latitude +
          "," +
          response.Longitude +
          "&radius=1500&type=bus_station&key=AIzaSyAww6TxPSzQbg74ljJahxvuLHmRfICHbf4", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json"
    }
})
.then((response) => {
  
    return response.json();
})
.then((result) => {
    // console.log(result.data.translations[0].value); // Will output "Étiquette du bouton"
});
      // api
      //   .post(
      //     "https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=" +
      //     response.Latitude +
      //     "," +
      //     response.Longitude +
      //     "&radius=1500&type=bus_station&key=AIzaSyAww6TxPSzQbg74ljJahxvuLHmRfICHbf4"
      //   )
      //   .then((res) => {
      //     // console.log("res", res);
      //     if (res) {
      //       this.setState({
      //         nearByTransportList: res.results.map((x) => {
      //           return x.name;
      //         }),
      //       });
      //     } else {
      //     }
      //   })
      //   .catch((err) => {
      //     // // console.log(err)
      //   });
    } catch (err) { }
  }

  validate = (event, type) => {
    event.preventDefault();
    const {
      UsersCount,
      ArriveTime,
      Users,
      Time,
      singleDate,
      selectedDateRange,
      Name,
      EmailAddress,
      Message,
    } = this.state;
    let IsValid = true;

    if (type === "CheckOut") {
      // if(this.state.filteredSlotArray.filter(x=>!x.Time.length > 0).length > 0){
      //     IsValid = false;
      //     var slotArray = this.state.filteredSlotArray.filter(x=>!x.Time.length > 0)

      //     slotArray.map((x,idx)=>{
      //         slotArray[idx]['TimeErr'] = true;
      //         slotArray[idx]['TimehelperText'] = 'Please select time.';
      //     })
      //     this.setState({ filteredSlotArray: slotArray })
      // }else{
      //     var slotArray = this.state.filteredSlotArray.filter(x=>x.Time.length > 0)

      //     slotArray.map((x,idx)=>{
      //         slotArray[idx]['TimeErr'] = false;
      //         slotArray[idx]['TimehelperText'] = '';
      //     })
      //     this.setState({ filteredSlotArray: slotArray })
      // }
      if (!this.state.filteredSlotArray.length > 0) {
        IsValid = false;
        cogoToast.error("Please select time slots.");
        // this.setState({ TimeErr: true, TimehelperText: 'Please select time.' })
      } else if (
        this.state.filteredSlotArray.filter((x) => !x.Time.length > 0).length >
        0
      ) {
        IsValid = false;
        cogoToast.error("Please select time slots.");
      } else {
        // this.setState({ TimeErr: false, TimehelperText: '' })
      }
      if (this.state.day === "Single Day") {
        if (CommonConfig.isEmpty(singleDate)) {
          IsValid = false;
          this.setState({
            DateErr: true,
            DatehelperText: "Please select date.",
          });
        } else {
          this.setState({ DateErr: false, DatehelperText: "" });
        }
      } else {
        if (selectedDateRange[0].startDate === selectedDateRange[0].endDate) {
          IsValid = false;
          this.setState({
            DateErr: true,
            DatehelperText: "Please select date.",
          });
        } else {
          this.setState({ DateErr: false, DatehelperText: "" });
        }
        // if (CommonConfig.isEmpty(ArriveTime)) {
        //     IsValid = false;
        //     this.setState({ ArriveTimeErr: true, ArriveTimehelperText: 'Please select arrive time.' })
        // }
        // else {
        //     this.setState({ ArriveTimeErr: false, ArriveTimehelperText: '' })
        // }
      }

      if (this.state.Users === "Multi") {
        if (CommonConfig.isEmpty(UsersCount)) {
          IsValid = false;
          this.setState({
            UsersErr: true,
            UsershelperText: "Please enter users count.",
          });
        } else if (
          Number(UsersCount) > Number(this.state.propertyDetail.GuestCount)
        ) {
          this.setState({
            UsersErr: true,
            UsershelperText:
              "Users Count specified exceed the maximum allowed users for this pod.",
          });
        } else {
          this.setState({ UsersErr: false, UsershelperText: "" });
        }
      } else {
        if (CommonConfig.isEmpty(Users)) {
          IsValid = false;
          this.setState({
            UsersErr: true,
            UsershelperText: "Please select users.",
          });
        } else {
          this.setState({ UsersErr: false, UsershelperText: "" });
        }
      }
    } else {
      if (CommonConfig.isEmpty(Name)) {
        IsValid = false;
        this.setState({ NameErr: true, NamehelperText: "Name is required." });
      } else {
        this.setState({ NameErr: false, NamehelperText: "" });
      }
      if (CommonConfig.isEmpty(EmailAddress)) {
        IsValid = false;
        this.setState({
          EmailAddressErr: true,
          EmailAddresshelperText: "Email address is required.",
        });
      } else if (!EmailAddress.match(CommonConfig.RegExp.email)) {
        IsValid = false;
        this.setState({
          EmailAddressErr: true,
          EmailAddresshelperText: "Please enter valid email.",
        });
      } else {
        this.setState({ EmailAddressErr: false, EmailAddresshelperText: "" });
      }
      if (CommonConfig.isEmpty(Message)) {
        IsValid = false;
        this.setState({
          MessageErr: true,
          MessagehelperText: "Message is required.",
        });
      } else if(Message.length < 20)  {
        IsValid = false;
        this.setState({
          MessageErr: true,
          MessagehelperText: "Minimum 20 characters required.",
        });
      }
      else {
        this.setState({ MessageErr: false, MessagehelperText: "" });
      }
    }
    return IsValid;
  };

  bookpod = (event) => {
    event.preventDefault();
    // if (localStorage.getItem('loginData')) {
    if (this.validate(event, "CheckOut")) {
      let data;
      if (this.state.day == "Single Day") {
        data = {
          EntityID: this.props.location.state.PropertyID,
          AdultCount:
            this.state.Users === "Multi"
              ? this.state.UsersCount
              : this.state.Users,
          day: this.state.day,
          IsSameSlotSelected: this.state.IsSameSlotSelected,
          CheckInDate: this.state.singleDate,
          CheckOutDate: this.state.singleDate,
          ArriveTime: this.state.Time,
          BookedBy: CommonConfig.loginData().PersonID,
          TotalAmount: this.state.Price,
          propertyDetail: this.state.propertyDetail,
          IsArriveFullDaySelected: this.state.IsArriveFullDaySelected,
          IsDepartFullDaySelected: this.state.IsDepartFullDaySelected,
          filteredSlotArray: this.state.filteredSlotArray,
        };
      } else {
        data = {
          EntityID: this.props.location.state.PropertyID,
          AdultCount:
            this.state.Users === "Multi"
              ? this.state.UsersCount
              : this.state.Users,
          day: this.state.day,
          IsSameSlotSelected: this.state.IsSameSlotSelected,
          CheckInDate: this.state.selectedDateRange[0].startDate,
          CheckOutDate: this.state.selectedDateRange[0].endDate,
          ArriveTime: this.state.ArriveTime,
          DepartTime: this.state.DepartTime,
          BookedBy: CommonConfig.loginData().PersonID,
          TotalAmount: this.state.Price,
          propertyDetail: this.state.propertyDetail,
          IsArriveFullDaySelected: this.state.IsArriveFullDaySelected,
          IsDepartFullDaySelected: this.state.IsDepartFullDaySelected,
          filteredSlotArray: this.state.filteredSlotArray,
        };
      }
      this.props.history.push({
        pathname: "/admin/checkout",
        state: { data },
      });
    }

    // }
    // else {
    // }
  };

  getStandardAmenitiesList = () => {
    let data = {
      FeatureType: "STANDARDAMENITIES",
    };
    try {
      api
        .post("property/getAmenitiesList", data)
        .then((res) => {
          if (res.success) {
            this.setState({ standardAmenitiesList: res.data });
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  };

  getDayTypeList = () => {
    let data = {
      StringMapType: "DAYTYPE",
    };
    try {
      api
        .post("utility/getStringMap", data)
        .then((res) => {
          if (res.success) {
            this.setState({ dayTypeList: res.data });
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  };

  getTimeSlotList = (propertyDetail) => {
    let data = {
      StringMapType: "TIMESLOT",
    };
    try {
      api
        .post("utility/getStringMap", data)
        .then((res) => {
          if (res.success) {
            // var TimeSlot = propertyDetail.TimeSlot.split(',');
            // res.data = res.data.filter(x => TimeSlot.includes(x.StringMapName))
            this.setState({
              timeSlotList: res.data,
              tempTimeSlotList: res.data,
            });
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  };

  getFilteredTimeSlot = (Date, PropertyID) => {
    let data = {
      Date: Date,
      PropertyID: PropertyID,
      Day: this.state.day,
    };
    try {
      api
        .post("property/getFilteredTimeSlot", data)
        .then((res) => {
          if (res.success) {
            // var response = []
            // res.data[0].map(x=>{ response.push(x.StringMapKey)})
            var TimeSlot = this.state.tempTimeSlotList;
            // TimeSlot = TimeSlot.filter(x => res.data[0].map(i => { return i.StringMapKey }).includes(x.StringMapKey))
            // // console.log("Timeslot",TimeSlot)
            this.setState({
              timeSlotList: TimeSlot,
              filteredSlotArray:
                res.data[0].length > 0
                  ? res.data[0].map((item) => ({
                    ...item,
                    Time: [],
                  }))
                  : [],
            });
            // console.log("timeslot...", this.state.timeSlotList);
            this.splitArray();
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  };

  getNoOfUsersList = (propertyDetail) => {
    let data = {
      StringMapType: "NOOFUSERS",
    };
    try {
      api
        .post("utility/getStringMap", data)
        .then((res) => {
          if (res.success) {
            var GuestCount = propertyDetail.GuestCount;
            if (GuestCount > 3) {
              this.setState({ noOfUsersList: res.data });
            } else {
              var Index = res.data.findIndex(
                (x) => x.StringMapName === GuestCount
              );
              res.data = res.data
                .filter((x, idx) => idx <= Index)
                .map((x) => {
                  return x;
                });
              this.setState({ noOfUsersList: res.data });
            }
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  };

  getPodDetails = () => {
    
    var url_string = window.location.href; //window.location.href
    var url = new URL(url_string);
    var id = url.searchParams.get("id");
    let PropertyID = this.props.location.state
      ? this.props.location.state.PropertyID
      : id
        ? id
        : "";
    if (PropertyID == "") {
      this.props.history.push({
        pathname: "/admin/homepage",
        state: {},
      });
    } else {
      let data;
      if (this.state.day == "Single Day") {
        data = {
          PropertyID: PropertyID,
          singleDate:
            this.props.location.state && this.props.location.state.singleDate
              ? moment(this.props.location.state.singleDate)
                .format("YYYY-MM-DD HH:mm:ss")
                .toString()
              : "",
          UserID: CommonConfig.loginData().PersonID,
        };
      } else {
        data = {
          PropertyID: PropertyID,
          ArriveDate: this.props.location.state
            ? moment(this.props.location.state.selectedDateRange[0].startDate)
              .format("YYYY-MM-DD HH:mm:ss")
              .toString()
            : "",
          DepartDate: this.props.location.state
            ? moment(this.props.location.state.selectedDateRange[0].endDate)
              .format("YYYY-MM-DD HH:mm:ss")
              .toString()
            : "",
          UserID: CommonConfig.loginData().PersonID,
        };
      }
      try {
        api
          .post("property/viewProperty", data)
          .then((res) => {
            
            if (res.success) {
              this.setState({
                propertyDetail: res.data,
                Price: res.data.Price,
                latitude: res.data.Latitude,
                longitude: res.data.Longitude,
                disabledDates: res.data.disabledDates ? res.data.disabledDates.split(",").map((x) => {
                  return new Date(x);
                }):[],
              });
              // geocodeByAddress(res.data.PropertyAddress)
              //     .then(results => getLatLng(results[0]))
              //     .then(latLng => {
              //         this.setState({ latitude: latLng.lat, longitude: latLng.lng })
              //         // // // console.log('Success', latLng)
              //     })
              //     .catch(error => {/*console.error('Error', error)*/ });
              this.initMap(res.data);
              this.getSimilarListing(PropertyID);
              this.getNoOfUsersList(res.data);
              if (this.state.singleDate) {
                this.getFilteredTimeSlot(
                  moment(this.state.singleDate).format("YYYY-MM-DD").toString(),
                  res.data.PropertyID
                );
              }
              if (
                moment(this.state.selectedDateRange[0].startDate).format(
                  "DD-MM-YYYY"
                ) !==
                moment(this.state.selectedDateRange[0].endDate).format(
                  "DD-MM-YYYY"
                )
              ) {
                this.getFilteredTimeSlot(
                  `` +
                  moment(this.state.selectedDateRange[0].startDate)
                    .format("YYYY-MM-DD")
                    .toString() +
                  ` , ` +
                  moment(this.state.selectedDateRange[0].endDate)
                    .format("YYYY-MM-DD")
                    .toString() +
                  ``,
                  this.state.propertyDetail.PropertyID
                );
              }
            } else {
            }
          })
          .catch((err) => {
            // // console.log(err)
          });
      } catch (err) { }
    }
  };

  distanceToMouse = (pt, mp) => {
    if (pt && mp) {
      // return distance between the marker and mouse pointer
      return Math.sqrt(
        (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
      );
    }
  };

  handleClickOpenCalendar = () => {
    this.setState({
      isShowCalendar: true,
    });
  };

  handleChange = (e, type) => {
    if (type === "date") {
      this.setState({
        selectedDateRange: e,
        TotalBookingDays: CommonConfig.getDatesBetweenDates(
          e[0].startDate,
          e[0].endDate
        ),
        DateErr: false,
        DatehelperText: "",
      });
      if (
        moment(e[0].startDate).format("DD-MM-YYYY") !==
        moment(e[0].endDate).format("DD-MM-YYYY")
      ) {
        this.datepick.click();
        this.getFilteredTimeSlot(
          `` +
          moment(e[0].startDate).format("YYYY-MM-DD").toString() +
          ` , ` +
          moment(e[0].endDate).format("YYYY-MM-DD").toString() +
          ``,
          this.state.propertyDetail.PropertyID
        );
        this.setState({
          isDateSelected: true
        })
      } else {
      }
    } else if (type === "singleDate") {
      if (CommonConfig.isEmpty(e)) {
        this.setState({
          singleDate: e,
          DateErr: true,
          DatehelperText: "Please select date.",
        });
      } else {
        this.datepick.click();
        this.setState({ singleDate: e, DateErr: false, DatehelperText: "", isDateSelected: true });
        this.getFilteredTimeSlot(
          moment(e).format("YYYY-MM-DD").toString(),
          this.state.propertyDetail.PropertyID
        );
      }
    } else if (type === "day") {
      this.setState({ day: e });
    } else if (type === "ArriveTime") {
      // var Time = e;
      // if (Time.length > 0) {
      //     var TimeSlotPrice = this.state.propertyDetail.TimeSlotPrice.split(",")
      //     var TimeSlot = this.state.propertyDetail.TimeSlot.split(",")
      //     var CheckINData = []
      //     var PriceData = []
      //     Time.map((x) => {
      //         var Index = TimeSlot.findIndex(item => item === x.label)
      //         this.state.TotalBookingDays.map(i => {
      //             PriceData.push({ "Pdata": TimeSlotPrice[Index] })
      //         })
      //         CheckINData.push({
      //             CheckInDate: this.state.selectedDateRange[0].startDate,
      //             TimeSlot: x.label,
      //             TimeSlotPrice: Number(TimeSlotPrice[Index]),
      //             BookingFlag: 'CheckIN'
      //         })
      //     })
      //     this.state.propertyDetail["CheckINData"] = CheckINData
      //     this.setState({ Price: PriceData.reduce((a, v) => a = a + parseFloat(v.Pdata), 0), PriceData: PriceData });
      // } else {
      //     this.state.propertyDetail["CheckINData"] = []
      //     this.setState({ Price: this.state.propertyDetail })
      // }
      // this.setState({ propertyDetail: this.state.propertyDetail })
      if (
        e.length > 0 &&
        e[0].label === "Full Day(09:00 - 17:00)"
      ) {
        this.setState({
          IsArriveFullDaySelected: true,
          IsArriveSlotSelected: false,
        });
      } else if (
        e.length > 0 &&
        e[0].label !== "Full Day(09:00 - 17:00)"
      ) {
        this.setState({
          IsArriveFullDaySelected: false,
          IsArriveSlotSelected: true,
        });
      } else {
        this.setState({
          IsArriveFullDaySelected: false,
          IsArriveSlotSelected: false,
        });
      }
      this.setState({
        ArriveTime: e,
        ArriveTimeErr: false,
        ArriveTimehelperText: "",
      });
    } else if (type === "Time") {
      // var Time = e;
      // if (Time.length > 0) {
      //     var TimeSlotPrice = this.state.propertyDetail.TimeSlotPrice.split(",")
      //     var TimeSlot = this.state.propertyDetail.TimeSlot.split(",")
      //     var sum = 0
      //     var Price = Time.map((x) => {
      //         var Index = TimeSlot.findIndex(item => item === x.label)
      //         sum = sum + Number(TimeSlotPrice[Index])
      //         this.setState({ Price: sum })
      //         // return sum;
      //     })
      //     // this.setState({ Price: Price })
      // } else {
      //     this.setState({ Price: this.state.propertyDetail.Price })
      // }
      if (
        e.length > 0 &&
        e[0].label === "Full Day(09:00 - 17:00)"
      ) {
        this.setState({ IsFullDaySelected: true, IsSlotSelected: false });
      } else if (
        e.length > 0 &&
        e[0].label !== "Full Day(09:00 - 17:00)"
      ) {
        this.setState({ IsFullDaySelected: false, IsSlotSelected: true });
      } else {
        this.setState({ IsFullDaySelected: false, IsSlotSelected: false });
      }
      this.setState({ Time: e, TimeErr: false, TimehelperText: "" });
    } else if (type === "DepartTime") {
      // var Time = e;
      // if (Time.length > 0) {
      //     var TimeSlotPrice = this.state.propertyDetail.TimeSlotPrice.split(",")
      //     var TimeSlot = this.state.propertyDetail.TimeSlot.split(",")
      //     var CheckoutData = []
      //     var sum = 0
      //     var PriceData = [...this.state.PriceData]
      //     Time.map((x) => {
      //         var Index = TimeSlot.findIndex(item => item === x.label)
      //         PriceData.push({ "Pdata": TimeSlotPrice[Index] })
      //         CheckoutData.push({
      //             CheckInDate: this.state.selectedDateRange[0].endDate,
      //             TimeSlot: x.label,
      //             TimeSlotPrice: Number(TimeSlotPrice[Index]),
      //             BookingFlag: 'CheckOUT'
      //         })
      //     })
      //     this.state.propertyDetail["CheckoutData"] = CheckoutData
      //     this.setState({ Price: PriceData.reduce((a, v) => a = a + parseFloat(v.Pdata), 0) });
      // } else {
      //     this.state.propertyDetail["CheckoutData"] = []
      //     this.setState({ Price: this.state.PriceData.reduce((a, v) => a = a + parseFloat(v.Pdata), 0) });
      // }
      // this.setState({ propertyDetail: this.state.propertyDetail })
      if (
        e.length > 0 &&
        e[0].label === "Full Day(09:00 - 17:00)"
      ) {
        this.setState({
          IsDepartFullDaySelected: true,
          IsDepartSlotSelected: false,
        });
      } else if (
        e.length > 0 &&
        e[0].label !== "Full Day(09:00 - 17:00)"
      ) {
        this.setState({
          IsDepartFullDaySelected: false,
          IsDepartSlotSelected: true,
        });
      } else {
        this.setState({
          IsDepartFullDaySelected: false,
          IsDepartSlotSelected: false,
        });
      }
      this.setState({ DepartTime: e });
    } else if (type == "UsersCount") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          UsersCount: e.target.value,
          UsersErr: true,
          UsershelperText: "Please select users.",
        });
      } else if (
        Number(e.target.value) > Number(this.state.propertyDetail.GuestCount)
      ) {
        this.setState({
          UsersCount: Number(e.target.value),
          UsersErr: true,
          UsershelperText:
            "Users Count specified exceed the maximum allowed users for this pod.",
        });
      } else {
        this.setState({
          UsersCount: Number(e.target.value),
          UsersErr: false,
          UsershelperText: "",
        });
      }
    } else if (type === "Users") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          Users: e.target.value,
          UsersErr: true,
          UsershelperText: "Please enter user count.",
        });
      } else {
        this.setState({
          Users: e.target.value,
          UsersErr: false,
          UsershelperText: "",
        });
      }
    } else if (type === "IsSameSlot") {
      this.setState({ IsSameSlotSelected: e.target.checked, DepartTime: [] });
    } else if (type === "Name") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          Name: e.target.value,
          NameErr: true,
          NamehelperText: "Name is required.",
        });
      } else {
        this.setState({
          Name: e.target.value,
          NameErr: false,
          NamehelperText: "",
        });
      }
    } else if (type === "EmailAddress") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          EmailAddress: e.target.value,
          EmailAddressErr: true,
          EmailAddresshelperText: "Email address is required.",
        });
      } else if (!e.target.value.match(CommonConfig.RegExp.email)) {
        this.setState({
          EmailAddress: e.target.value,
          EmailAddressErr: true,
          EmailAddresshelperText: "Please enter valid email.",
        });
      } else {
        this.setState({
          EmailAddress: e.target.value,
          EmailAddressErr: false,
          EmailAddresshelperText: "",
        });
      }
    } else if (type === "Message") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          Message: e.target.value,
          MessageErr: true,
          MessagehelperText: "Message is required.",
        });
      } else if(e.target.value.length < 20) {
        this.setState({
          Message: e.target.value,
          MessageErr: true,
          MessagehelperText: "Minimum 20 characters required.",
        })
      }
      else {
        this.setState({
          Message: e.target.value,
          MessageErr: false,
          MessagehelperText: "",
        });
      }
    } else if (type === "WishListName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          WishListName: e.target.value,
          WishListNameErr: true,
          WishListNamehelperText: "Please enter name",
        });
      } else {
        this.setState({
          WishListName: e.target.value,
          WishListNameErr: false,
          WishListNamehelperText: "",
        });
      }
    }
  };

  submitContactToHost = (e) => {
    e.preventDefault();
    if (this.validate(e, "ContactHost")) {
      let data = {
        Name: this.state.Name,
        Email: this.state.EmailAddress,
        Message: this.state.Message,
        PropertyID: this.state.propertyDetail.PropertyID,
        PersonID: CommonConfig.loginData().PersonID
          ? CommonConfig.loginData().PersonID
          : "",
        PersonType: "Host",
      };
      try {
        api
          .post("property/contactUs", data)
          .then((res) => {
            if (res.success) {
              cogoToast.success(
                "Request sent to host. We will respond to your message soon!"
              );
              this.setState({
                showContactHost: false,
                Name: "",
                NameErr: false,
                NamehelperText: "",

                EmailAddress: "",
                EmailAddressErr: false,
                EmailAddresshelperText: "",

                Message: "",
                MessageErr: false,
                MessagehelperText: "",
              });
            } else {
              cogoToast.error("Request failed. Please try again!");
              this.setState({
                showContactHost: false,
                Name: "",
                NameErr: false,
                NamehelperText: "",

                EmailAddress: "",
                EmailAddressErr: false,
                EmailAddresshelperText: "",

                Message: "",
                MessageErr: false,
                MessagehelperText: "",
              });
            }
          })
          .catch((err) => {
            // // console.log(err)
          });
      } catch (err) { }
    }
  };

  handleFavModal = (e, item, type) => {
    if (CommonConfig.loginData().PersonID) {
      this.setState({ items: item });
      if (type === "Favorite") {
        this.setState({ showFavModal: true });
      } else {
        this.favUnFavPod(e, "UnFavorite", item);
      }
    } else {
      // this.props.location.state["showLoginModal" ] = true
      // this.props.history.push('/admin/listing');
      this.setState({ showLoginModal: true });
      // // // console.log("list",this.props)
    }
  };

  favUnFavPod = (event, type, items) => {
    event.preventDefault();
    if (type == "Favorite") {
      const { WishListName } = this.state;
      if (CommonConfig.isEmpty(WishListName)) {
        this.setState({
          WishListNameErr: true,
          WishListNamehelperText: "Please enter name",
        });
      } else {
        let data = {
          WishListName: WishListName,
          EntityID: items.PropertyID,
          UserID: CommonConfig.loginData().PersonID,
        };
        try {
          api
            .post("user/favunfav", data)
            .then((res) => {
              if (res.success) {
                this.setState({
                  showFavModal: false,
                  items: [],
                  WishListName: "",
                  WishListNameErr: false,
                  WishListNamehelperText: "",
                });
                cogoToast.success(`Saved to ` + WishListName + `.`);
                this.getPodDetails();
              } else {
                this.setState({
                  WishListNameErr: true,
                  WishListNamehelperText: "Please enter valid name.",
                });
                cogoToast.error(`Maximum name length 50 character is allowed`);
              }
            })

            .catch((error) => {
              // // console.log("error", error);
            });
        } catch (err) {
          // // console.log("error", err);
        }
      }
    } else {
      let data = {
        WishListName: items.WishListName,
        EntityID: items.PropertyID,
        UserID: CommonConfig.loginData().PersonID,
      };
      try {
        api
          .post("user/favunfav", data)
          .then((res) => {
            if (res.success) {
              cogoToast.success(
                `Removed from favourite ` + items.WishListName + `.`
              );
              this.setState({
                showFavModal: false,
                items: [],
                WishListName: "",
                WishListNameErr: false,
                WishListNamehelperText: "",
              });
              this.getPodDetails();
            } else {
              // this.setState({ WishListNameErr: true, WishListNamehelperText: 'Please enter valid name.' })
              // cogoToast.error(`Maximum name length 50 character is allowed`)
            }
          })

          .catch((error) => {
            // // console.log("error", error);
          });
      } catch (err) {
        // // console.log("error", err);
      }
    }
  };

  // validateCheckOut = (event) => {
  //     event.preventDefault();
  //     const { FirstName, LastName, Email } = this.state;
  //     let IsValid = true;

  //     if (CommonConfig.isEmpty(singleDate) || this.state.selectedDateRange[0].startDate == this.state.selectedDateRange[0].endDate) {
  //         IsValid = false;
  //         this.setState({ DateErr: true, DatehelperText: 'Please select date.' })
  //     }
  //     else {
  //         this.setState({ DateErr: false, DatehelperText: '' })
  //     }
  //     if (CommonConfig.isEmpty(Slot)) {
  //         IsValid = false;
  //         this.setState({ SlotErr: true, SlothelperText: 'Please enter last name.' })
  //     }
  //     else {
  //         this.setState({ SlotErr: false, SlothelperText: '' })
  //     }
  //     return IsValid;
  // }

  // ReviewRating = (count) => {
  //     var count = parseFloat(count).toFixed(1)
  //     var maincount = parseInt(count)
  //     var other = 5 - count;
  //     for (let i = 0;i<maincount.length;i++ ) {
  //         return (
  //             <>
  //                 <span className="fa fa-star"></span>
  //                 {/* <span className="fa fa-star"></span>
  //                 <span className="fa fa-star"></span> */}
  //                 {/* <span className="fa fa-star-o"></span>
  //                 <span className="fa fa-star-o"></span> */}
  //             </>
  //         )
  //     }
  // }

  splitArray = () => {
    let splittedArr = this.state.filteredSlotArray;
    let Arr = [];
    let init = 0;
    let final = 7;
    let count = 0;
    let tempArr = splittedArr;
    // if (splittedArr.length > 7) {
    while (count < splittedArr.length) {
      Arr.push(splittedArr.slice(init, final));
      init = final;
      final = splittedArr.length > final + 7 ? final + 7 : splittedArr.length;
      count = count + 7;
    }
    tempArr = Arr;
    // }
    this.setState({
      filteredSlotArrayDivided: tempArr,
    },  () => {
      this.state.filteredSlotArrayDivided.map((item, i) => {
        if(i!==0) { 
          document.getElementById(i).style.display = "none" 
        };
      })
    });
  };

  onChange1 = (e, idx, i) => {
    
    let { filteredSlotArrayDivided } = this.state;
    var slotArray = filteredSlotArrayDivided[i];
    if (e.length > 0 && e[0].label === "Full Day(09:00 - 17:00)") {
      slotArray[idx]["IsFullDaySelected"] = 1;
      slotArray[idx]["IsSlotSelected"] = 0;
      // this.setState({ IsFullDaySelected: true, IsSlotSelected: false })
    } else if (
      e.length > 0 &&
      e[0].label !== "Full Day(09:00 - 17:00)"
    ) {
      slotArray[idx]["IsFullDaySelected"] = 0;
      slotArray[idx]["IsSlotSelected"] = 1;
      // this.setState({ IsFullDaySelected: false, IsSlotSelected: true })
    } else {
      slotArray[idx]["IsFullDaySelected"] = 0;
      slotArray[idx]["IsSlotSelected"] = 0;
      // this.setState({ IsFullDaySelected: false, IsSlotSelected: false })
    }
    slotArray[idx]["Time"] = e;
    let tempfilteredSlotArrayDivided = filteredSlotArrayDivided;
    tempfilteredSlotArrayDivided[i] = slotArray;
    this.setState({
      filteredSlotArrayDivided: tempfilteredSlotArrayDivided,
    });
  };

  onChange2 = (e, idx, i) => {
    
    let { filteredSlotArrayDivided } = this.state;
    var slotArray = filteredSlotArrayDivided[i];
    if (e === null) {
      slotArray[idx]["IsFullDaySelected"] = 0;
      slotArray[idx]["IsSlotSelected"] = 0;
      slotArray[idx]["Time"] = e;
      this.setState({
        filteredSlotArray: slotArray,
      });
      // this.setState({ Time: e, IsFullDaySelected: false, IsSlotSelected: false })
    } else {
      slotArray[idx]["Time"] = e;
      let tempfilteredSlotArrayDivided = filteredSlotArrayDivided;
      tempfilteredSlotArrayDivided[i] = slotArray;
      this.setState({
        filteredSlotArrayDivided: tempfilteredSlotArrayDivided,
      });
    }
  };

  onChange3 = (e, idx, i) => {
    
    let { filteredSlotArrayDivided } = this.state;
    var slotArray = filteredSlotArrayDivided[i];
    if (e.length > 0 && e[0].label === "Full Day(09:00 - 17:00)") {
      slotArray[idx]["IsFullDaySelected"] = 1;
      slotArray[idx]["IsSlotSelected"] = 0;
      // this.setState({ IsFullDaySelected: true, IsSlotSelected: false })
    } else if (
      e.length > 0 &&
      e[0].label !== "Full Day(09:00 - 17:00)"
    ) {
      slotArray[idx]["IsFullDaySelected"] = 0;
      slotArray[idx]["IsSlotSelected"] = 1;
      // this.setState({ IsFullDaySelected: false, IsSlotSelected: true })
    } else {
      slotArray[idx]["IsFullDaySelected"] = 0;
      slotArray[idx]["IsSlotSelected"] = 0;
      // this.setState({ IsFullDaySelected: false, IsSlotSelected: false })
    }
    slotArray[idx]["Time"] = e;
    let tempfilteredSlotArrayDivided = filteredSlotArrayDivided;
    tempfilteredSlotArrayDivided[i] = slotArray;
    this.setState({
      filteredSlotArrayDivided: tempfilteredSlotArrayDivided,
    });
    document.getElementsByClassName("slot-slide-"+this.state.index)
    
  };

  handleSlider = (type) => {
    const {index, filteredSlotArrayDivided} = this.state
    if(type === 'next'){
      if(index !== filteredSlotArrayDivided.length - 1) {
        this.setState({
          index: index + 1
        }, () => {
          document.getElementById(this.state.index).style.display = "block"
          document.getElementById(this.state.index - 1).style.display = "none"
        })
      }
    } else {
      if(index!==0){
        this.setState({
          index: index - 1
        }, () => {
          document.getElementById(this.state.index).style.display = "block"
          document.getElementById(this.state.index + 1).style.display = "none"
        })
      }
    }
  }

  render() {
    const {
      showFavModal,
      dayTypeList,
      timeSlotList,
      noOfUsersList,
      photoIndex,
      isOpen,
      nearByTransportList,
      filteredSlotArray,
      tempTimeSlotList,
      filteredSlotArrayDivided,
      bookingHistoryDetail
    } = this.state;
    const timeSlotListArray = timeSlotList.map((content) => {
      return { value: content.StringMapID, label: content.StringMapName };
    });

    // const tempTimeSlotListArray = tempTimeSlotList.map(content => {
    //     return { value: content.StringMapID, label: content.StringMapName }
    // })
    // let propertyDetail = this.state.propertyDetail;
    // let hostRating = propertyDetail.HostRating;
    // // console.log("filteredSlotArrayDivided..",propertyDetail ? hostRating.length > 0  ? hostRating[0].HostRating:null :null);
    const { ...rest } = this.props;

    const settingsFeaturedPods = {
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      centerMode: true,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            centerMode: false,
            variableWidth: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            centerMode: false,
            variableWidth: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            centerMode: false,
            variableWidth: false,
          },
        },
      ],
    };

    const settingsSliderMultiDay = {
      dots: false,
      arrows: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplaySpeed: 4000,
      autoplay: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ],
    };

    // // console.log("propertydetail..",this.state.propertyDetail)
    return (
      <div>
        <section className="pr-slider-outer">
          <div className="pr-slider-wrap">
            <Slider {...settingsFeaturedPods}>
              {this.state.propertyDetail &&
                this.state.propertyDetail.PropertyMedia
                ? this.state.propertyDetail.PropertyMedia.filter(item => item.MediaType.split("/")[0] === 'image').map((content) => {
                  return (
                    <div className="pr-slide">
                      <a
                        href="javascript:;"
                        onClick={() => this.setState({ isOpen: true })}
                      >
                        <img
                          src={apiBase + content.MediaURL}
                          alt=""
                          height="500"
                          width="750"
                        />
                        {/* <video controls height="500" width="750">
                                 <source
                                   src={apiBase + content.MediaURL}
                                   type={content.MediaType}
                                 />
                               </video> */}
                      </a>
                    </div>
                  );
                })
                : null}
            </Slider>
          </div>
        </section>
        <section className="section property-details-outer bg-light-gray">
          <div className="container">
            <div className="property-details-inner">
              <div className="row mobile-column-reverce">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">
                  <div className="property-details-content-area">
                    <div className="title-section">
                      <div className="block block-top-title">
                        <div className="block-body">
                          <ol className="breadcrumb">
                            <li>
                              <a
                                href="javascript:;"
                                onClick={() =>
                                  this.props.history.push("/admin/homepage")
                                }
                              >
                                <span>Home</span>
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:;"
                                onClick={() =>
                                  this.props.history.push({
                                    pathname: "/admin/listing",
                                    state: {
                                      selectedDateRange: this.state
                                        .selectedDateRange,
                                      day: this.state.day,
                                      ArriveTime: this.state.ArriveTime,
                                      DepartTime: this.state.DepartTime,
                                      Time: this.state.Time,
                                      singleDate: this.state.singleDate,
                                      Users: this.state.Users,
                                      latitude: this.state.latitude,
                                      longitude: this.state.longitude,
                                    },
                                  })
                                }
                              >
                                {" "}
                                <span>Pods</span>
                              </a>
                            </li>
                            <li className="active">
                              {this.state.propertyDetail &&
                                this.state.propertyDetail.PropertyName
                                ? this.state.propertyDetail.PropertyName
                                : null}
                            </li>
                          </ol>
                          <h1 className="listing-title">
                            {this.state.propertyDetail &&
                              this.state.propertyDetail.PropertyName
                              ? this.state.propertyDetail.PropertyName
                              : null}
                          </h1>
                          <address>
                            <i
                              className="fa fa-map-marker"
                              aria-hidden="true"
                            ></i>{" "}
                            {this.state.propertyDetail &&
                              this.state.propertyDetail.PropertyAddress
                              ? this.state.propertyDetail.PropertyAddress
                              : null}
                          </address>
                          <div className="avatar">
                            <img
                              src={avatar}
                              className="img-circle media-object"
                              alt=""
                            />
                          </div>
                          <div className="list-rev">
                            <div className="list-inline rating hidden-xs">
                              <span className="fa fa-star"></span>
                              <span className="ml-1">
                                {this.state.propertyDetail &&
                                  this.state.propertyDetail.Rate
                                  ? this.state.propertyDetail.Rate
                                  : "No reviews"}
                              </span>
                              {/* <span className="fa fa-star"></span>
                                                            <span className="fa fa-star"></span>
                                                            <span className="fa fa-star-o"></span>
                                                            <span className="fa fa-star-o"></span> */}
                              <span className="star-text-right">
                                {this.state.propertyDetail &&
                                  this.state.propertyDetail.ReviewRatingType
                                  ? this.state.propertyDetail.ReviewRatingType
                                  : ""}
                              </span>
                            </div>
                            <a href="#reviews-section">Jump to reviews</a>
                          </div>
                        </div>
                        {/* <!-- block-body --> */}
                      </div>
                      {/* <!-- block --> */}
                    </div>

                    <div id="about-section" className="about-section">
                      {this.state.propertyDetail &&
                        this.state.propertyDetail.Amenities &&
                        this.state.propertyDetail.Amenities.length > 0
                        ? this.state.propertyDetail.Amenities.map(
                          (content, i) => {
                            return i % 4 === 0
                              ? this.state.propertyDetail.Amenities.slice(
                                i,
                                i + 4
                              )
                              : null;
                          }
                        )
                          .filter((content) => content != null)
                          .map((item, idx) => {
                            return (
                              <div className="block block-bordered">
                                {item.map((x) => (
                                  <div
                                    className="block-col block-col-25"
                                    key={idx}
                                  >
                                    <div className="block-icon">
                                      {parse(x.FeatureIconURL)}
                                    </div>
                                    <div>{x.FeatureName}</div>
                                    {/* <div><strong>Available </strong></div> */}
                                  </div>
                                ))}
                              </div>
                            );
                          })
                        : null}

                      <div className="block">
                        <div className="block-body">
                          <h2>About this listing</h2>
                          <p style={{ textAlign: "justify" }}>
                            {this.state.propertyDetail &&
                              this.state.propertyDetail.PropertyDescription
                              ? this.state.propertyDetail.PropertyDescription
                              : null}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="stm-outer">
                      <h2>Standard Amenities</h2>
                      <div className="standard-amenities about-section">
                        {this.state.standardAmenitiesList.length > 0
                          ? this.state.standardAmenitiesList
                            .map((content, i) => {
                              return i % 4 === 0
                                ? this.state.standardAmenitiesList.slice(
                                  i,
                                  i + 4
                                )
                                : null;
                            })
                            .filter((content) => content != null)
                            .map((item, idx) => {
                              return (
                                <div className="block block-bordered">
                                  {item.map((x) => (
                                    <div
                                      className="block-col block-col-25"
                                      key={idx}
                                    >
                                      <div className="block-icon">
                                        {parse(x.FeatureIconURL)}
                                      </div>
                                      <div>{x.FeatureName}</div>
                                      {/* <div><strong>Available </strong></div> */}
                                    </div>
                                  ))}
                                </div>
                              );
                            })
                          : null}

                        {/* <div className="block block-bordered">
                                                    
                                                    <div className="block-col block-col-25">
                                                        <div className="block-icon">
                                                            <img src={wifiIcon}></img>
                                                        </div>
                                                        <div>Wifi</div>
                                                    </div>
                                                    <div className="block-col block-col-25">
                                                        <div className="block-icon">
                                                            <img src={wifiIcon}></img>
                                                        </div>
                                                        <div>Wifi</div>
                                                    </div>
                                                    <div className="block-col block-col-25">
                                                        <div className="block-icon">
                                                            <img src={wifiIcon}></img>
                                                        </div>
                                                        <div>Wifi</div>
                                                    </div>
                                                    <div className="block-col block-col-25">
                                                        <div className="block-icon">
                                                            <img src={wifiIcon}></img>
                                                        </div>
                                                        <div>Wifi</div>
                                                    </div>
                                                </div>
                                             */}
                      </div>
                    </div>
                    <div id="details-section" className="details-section">
                      <div className="block">
                        <div className="block-section">
                          <div className="block-body">
                            <div className="block-left">
                              <h3 className="title">Details</h3>
                            </div>
                            <div className="block-right">
                              <ul className="detail-list detail-list-2-cols">
                                <li>
                                  <i
                                    className="fa fa-angle-right"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  ID:{" "}
                                  <strong>
                                    {this.state.propertyDetail &&
                                      this.state.propertyDetail
                                        .PropertySchemeNumber
                                      ? this.state.propertyDetail
                                        .PropertySchemeNumber
                                      : null}
                                  </strong>
                                </li>
                                
                                
                                <li>
                                  <i
                                    className="fa fa-angle-right"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Washrooms:{" "}
                                  <strong>
                                    {this.state.propertyDetail &&
                                      this.state.propertyDetail.BathroomCount
                                      ? this.state.propertyDetail.BathroomCount
                                      : null}
                                  </strong>
                                </li>
                                <li>
                                  <i
                                    className="fa fa-angle-right"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Users:{" "}
                                  <strong>
                                    {this.state.propertyDetail &&
                                      this.state.propertyDetail.GuestCount
                                      ? this.state.propertyDetail.GuestCount
                                      : null}
                                  </strong>
                                </li>
                                <li>
                                  <i
                                    className="fa fa-angle-right"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Starting from{" "}
                                  <strong>
                                    {this.state.propertyDetail.CurrencySymbol ? parse(this.state.propertyDetail.CurrencySymbol) : ""} {this.state.Price}
                                  </strong>
                                </li>
                                {/* <li>
                                                                    <i className="fa fa-angle-right" aria-hidden="true"></i>Working Days: <strong>{this.state.propertyDetail && this.state.propertyDetail.WorkingDays ? this.state.propertyDetail.WorkingDays.map(x=>{return x.WorkingDays}):null}  </strong>
                                                                </li> */}
                              </ul>
                              <ul>
                              <li>
                                  <i
                                    className="mr-2 fa fa-angle-right"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Time Slot:{" "}
                                  <strong>
                                    {this.state.propertyDetail &&
                                      this.state.propertyDetail.TimeSlot
                                      ? this.state.propertyDetail.TimeSlot
                                      : "No Slots available"}
                                  </strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="mt-3 mb-3"><h5>Images</h5></div>
                    <div className="gallery-section">
                      {this.state.propertyDetail && this.state.propertyDetail.PropertyMedia
                        ? this.state.propertyDetail.PropertyMedia.filter(item => item.MediaType.split("/")[0] === 'image').map(
                            (content) => {
                              return (
                                  <img
                                  height="180.5px" width="180.5px"
                                    src={apiBase + content.MediaURL}
                                    type={content.MediaType}
                                  />
                                )}) : null}
                    </div>
                    
                    {this.state.propertyDetail.PropertyMedia ? this.state.propertyDetail.PropertyMedia.filter(item => item.MediaType.split("/")[0] === 'video').length > 0 ?
                    <div>
                    <div className="mt-3 mb-3"><h5>Videos</h5></div>
                   <div className="gallery-section">
                      {this.state.propertyDetail.PropertyMedia ? this.state.propertyDetail.PropertyMedia.filter(item => item.MediaType.split("/")[0] === 'video').map(
                                  (content) => {
                                    return (
                                  <video
                                    controls
                                    width="100%"
                                  >
                                    <source
                                      src={apiBase + content.MediaURL}
                                      type={content.MediaType}
                                    />
                                  </video>
                                )}
                              ): null}
                    </div>
                   </div> : null : null}
                    <div className="map-section place-map">
                      {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16715.269734336198!2d-0.4793139776456319!3d51.481291863286884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd613a737c8bc6bdc!2sHyatt%20Place%20London%20Heathrow%20Airport!5e0!3m2!1sen!2sin!4v1613555766215!5m2!1sen!2sin" frameborder="0" style={{ border: '0' }} allowfullscreen="" aria-hidden="false" tabIndex="0"></iframe> */}
                      <div
                        style={{ height: "400px", width: "100%" }}
                        id="detail-map"
                      >
                        <Map
                          google={this.props.google}
                          gestureHandling="greedy"
                          loading="lazy"
                          center={{
                            lat: this.state.latitude,
                            lng: this.state.longitude,
                          }}
                          zoom={14}
                        >
                          <Marker
                            onClick={this.onMarkerClick}
                            name={
                              this.state.propertyDetail &&
                                this.state.propertyDetail.PropertyName
                                ? this.state.propertyDetail.PropertyName
                                : null
                            }
                            position={{
                              lat: this.state.latitude,
                              lng: this.state.longitude,
                            }}
                            draggable={false}
                          />
                        </Map>
                      </div>
                    </div>
                    <div id="nearby-section" className="nearby-section">
                      <div className="block">
                        <div className="block-section">
                          <div className="block-body">
                            <div className="block-left">
                              <h3 className="title">Nearby</h3>
                            </div>
                            <div className="block-right">
                              <div className="what-nearby">
                                <dl>
                                  <dt>
                                    <i className="fa fa-picture-o"></i>{" "}
                                    Transportation
                                  </dt>
                                  <dd>
                                    {nearByTransportList
                                      .slice(0, 5)
                                      .map((x) => {
                                        return (
                                          <div className="what-nearby-left">
                                            {x}
                                            {/* <span className="time-review"> (0.04 mi) </span> */}
                                          </div>
                                        );
                                      })}
                                    {/* <div className="what-nearby-right">
                                                                            <div className="rating-wrap">
                                                                                <div className="rating-container">
                                                                                    <div className="rating">
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span><span className="label label-success">Excellent</span>                                                            <span className="time-review">4 reviews</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                  </dd>
                                  {/* <dd>
                                                                        <div className="what-nearby-left">
                                                                            Time &amp; Again Galleries <span className="time-review"> (0.04 mi) </span>
                                                                        </div>
                                                                        <div className="what-nearby-right">
                                                                            <div className="rating-wrap">
                                                                                <div className="rating-container">
                                                                                    <div className="rating">
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star-half-o"></span>
                                                                                        <span className="fa fa-star-o"></span><span className="label label-success">Good</span>                                                            <span className="time-review">3 reviews</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </dd>
                                                                    <dd>
                                                                        <div className="what-nearby-left">
                                                                            A La Mode Shoppe <span className="time-review"> (0.05 mi) </span>
                                                                        </div>
                                                                        <div className="what-nearby-right">
                                                                            <div className="rating-wrap">
                                                                                <div className="rating-container">
                                                                                    <div className="rating">
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star-half-o"></span><span className="label label-success">Excellent</span>                                                            <span className="time-review">93 reviews</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </dd>
                                                                 */}
                                </dl>
                                {/* <dl>
                                                                    <dt><i className="fa fa-shopping-basket"></i> Food</dt>
                                                                    <dd>
                                                                        <div className="what-nearby-left">
                                                                            First Avenue Vintner <span className="time-review"> (0.01 mi) </span>
                                                                        </div>
                                                                        <div className="what-nearby-right">
                                                                            <div className="rating-wrap">
                                                                                <div className="rating-container">
                                                                                    <div className="rating">
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star-half-o"></span>
                                                                                        <span className="fa fa-star-o"></span><span className="label label-success">Good</span>                                                            <span className="time-review">17 reviews</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </dd>
                                                                    <dd>
                                                                        <div className="what-nearby-left">
                                                                            Simchick Meats <span className="time-review"> (0.01 mi) </span>
                                                                        </div>
                                                                        <div className="what-nearby-right">
                                                                            <div className="rating-wrap">
                                                                                <div className="rating-container">
                                                                                    <div className="rating">
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star-o"></span>
                                                                                        <span className="fa fa-star-o"></span><span className="label label-success">Average</span>                                                            <span className="time-review">38 reviews</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </dd>
                                                                    <dd>
                                                                        <div className="what-nearby-left">
                                                                            Sabatino Truffles <span className="time-review"> (0.02 mi) </span>
                                                                        </div>
                                                                        <div className="what-nearby-right">
                                                                            <div className="rating-wrap">
                                                                                <div className="rating-container">
                                                                                    <div className="rating">
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span><span className="label label-success">Excellent</span>                  <span className="time-review">2 reviews</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </dd>
                                                                </dl>
                                                                <dl>
                                                                    <dt><i className="fa fa-medkit"></i> Health &amp; Medical</dt>
                                                                    <dd>
                                                                        <div className="what-nearby-left">
                                                                            Stefan Kantrowitz M.D. <span className="time-review"> (0.02 mi) </span>
                                                                        </div>
                                                                        <div className="what-nearby-right">
                                                                            <div className="rating-wrap">
                                                                                <div className="rating-container">
                                                                                    <div className="rating">
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span><span className="label label-success">Excellent</span>                                                            <span className="time-review">1 reviews</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </dd>
                                                                    <dd>
                                                                        <div className="what-nearby-left">
                                                                            George Vincent Lombardi, MD <span className="time-review"> (0.02 mi) </span>
                                                                        </div>
                                                                        <div className="what-nearby-right">
                                                                            <div className="rating-wrap">
                                                                                <div className="rating-container">
                                                                                    <div className="rating">
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star-half-o"></span>
                                                                                        <span className="fa fa-star-o"></span><span className="label label-success">Good</span>                  <span className="time-review">2 reviews</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </dd>
                                                                    <dd>
                                                                        <div className="what-nearby-left">
                                                                            HealthSource Pharmacy <span className="time-review"> (0.03 mi) </span>
                                                                        </div>
                                                                        <div className="what-nearby-right">
                                                                            <div className="rating-wrap">
                                                                                <div className="rating-container">
                                                                                    <div className="rating">
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star-o"></span><span className="label label-success">Good</span>                                                            <span className="time-review">9 reviews</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </dd>
                                                                </dl>
                                                                <dl> */}
                                {/* <dt><i className="fa fa-glass"></i> Nightlife</dt>
                                                                    <dd>
                                                                        <div className="what-nearby-left">
                                                                            The Greats Of Craft <span className="time-review"> (0.02 mi) </span>
                                                                        </div>
                                                                        <div className="what-nearby-right">
                                                                            <div className="rating-wrap">
                                                                                <div className="rating-container">
                                                                                    <div className="rating">
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span><span className="label label-success">Excellent</span>                  <span className="time-review">59 reviews</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </dd>
                                                                    <dd>
                                                                        <div className="what-nearby-left">
                                                                            Parnell's Pub &amp; Restaurant <span className="time-review"> (0.07 mi) </span>
                                                                        </div>
                                                                        <div className="what-nearby-right">
                                                                            <div className="rating-wrap">
                                                                                <div className="rating-container">
                                                                                    <div className="rating">
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star-half-o"></span>
                                                                                        <span className="fa fa-star-o"></span><span className="label label-success">Good</span>                  <span className="time-review">94 reviews</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </dd>
                                                                    <dd>
                                                                        <div className="what-nearby-left">
                                                                            Neary's <span className="time-review"> (0.14 mi) </span>
                                                                        </div>
                                                                        <div className="what-nearby-right">
                                                                            <div className="rating-wrap">
                                                                                <div className="rating-container">
                                                                                    <div className="rating">
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star"></span>
                                                                                        <span className="fa fa-star-o"></span><span className="label label-success">Good</span>                                                            <span className="time-review">85 reviews</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </dd>
                                                                 */}
                                {/* </dl> */}
                              </div>
                              {/* <div className="nearby-logo">Powered by <i className="fa fa-yelp" aria-hidden="true"></i> <strong>Yelp</strong></div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="rules-section" className="rules-section">
                      <div className="block">
                        <div className="block-section">
                          <div className="block-body">
                            <div className="block-left">
                              <h3 className="title">Terms &amp; rules</h3>
                            </div>
                            <div className="block-right">
                              <ul className="rules_list detail-list">
                                {this.state.propertyDetail &&
                                  this.state.propertyDetail.PropertyRules
                                  ? this.state.propertyDetail.PropertyRules.filter(
                                    (item) => item.FeatureID !== ""
                                  ).length === 0
                                    ? "No terms and rules mentioned by host"
                                    : this.state.propertyDetail.PropertyRules.filter(
                                      (item) => item.FeatureID !== ""
                                    ).map((content,indx) => {
                                      return <li className={this.state.propertyDetail.PropertyRules.filter(
                                        (item) => item.FeatureID !== ""
                                      ).length - 1 == indx ? "" :"mb-0"}>{content.Rule}</li>;
                                    })
                                  : "No terms and rules mentioned by host"}
                              </ul>
                              <ul className="detail-list">
                                <li className="mb-0">
                                  <strong>Additional rules information</strong>
                                </li>
                                {this.state.propertyDetail &&
                                  this.state.propertyDetail.PropertyRules ? (
                                  this.state.propertyDetail.PropertyRules.filter(
                                    (item) => item.FeatureID == ""
                                  ).length > 0 ? (
                                    this.state.propertyDetail.PropertyRules.filter(
                                      (item) => item.FeatureID == ""
                                    ).map((content,indx) => {
                                      return <li className={this.state.propertyDetail.PropertyRules.filter(
                                        (item) => item.FeatureID == ""
                                      ).length - 1 == indx ? "" :"mb-0"}>{content.Rule}</li>;
                                    })
                                  ) : (
                                    <li>No additional rules specified</li>
                                  )
                                ) : null}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="host-section" className="host-section">
                      <div className="block">
                        <div className="block-head">
                          <div className="media">
                            <div className="media-left">
                              <img
                                src={avatar}
                                className="img-circle media-object"
                                alt=""
                              />
                            </div>
                            <div className="media-body">
                              <h2 className="title">
                                Hosted by{" "}
                                <span>
                                  {this.state.propertyDetail
                                    ? this.state.propertyDetail.FirstName +
                                    " " +
                                    this.state.propertyDetail.LastName
                                    : null}
                                </span>
                              </h2>
                              <ul className="list-inline profile-host-info">
                                <li>
                                  <address>
                                    <i
                                      className="fa fa-map-marker"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    {this.state.propertyDetail
                                      ? this.state.propertyDetail.PropertyAddress
                                      : null}
                                  </address>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="block-body">
                          <div className="row">
                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                              <dl>
                                <dt>Profile Status</dt>
                                {this.state.propertyDetail &&
                                  this.state.propertyDetail.HostVerificationStatus == "Verified" ? (
                                  <dd className="text-success">
                                    <i className="fa fa-check-circle-o"></i>&nbsp;
                                    {this.state.propertyDetail.HostVerificationStatus}
                                  </dd>
                                ) : (
                                  <dd className="text-danger">
                                    <i className="fa fa-times-circle-o"></i> {this.state.propertyDetail.HostVerificationStatus}
                                  </dd>
                                )}
                              </dl>
                            </div>
                            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                              <dl>
                                <dt>Host rating</dt>
                                <dd>
                                  <div className="rating">

                                  <StarRatings
                                    rating={this.state.propertyDetail.HostRating.length > 0 ? this.state.propertyDetail.HostRating[0].HostRating : 0}
                                    starRatedColor="blue"
                                    numberOfStars={5}
                                    starDimension="15px"
                                    starSpacing="2px"
                                    />
                                    {/* <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star"></span>
                                    <span className="fa fa-star-o"></span> */}
                                    
                                    <span className="star-text-right">
                                      {this.state.propertyDetail.HostRating.length > 0 ? this.state.propertyDetail.HostRating[0].HostRatingType : null}
                                    </span>
                                  </div>
                                </dd>
                              </dl>
                            </div>
                          </div>
                          <div className="host-section-buttons">
                            {/* <a href="javascript:;" className="btn btn-primary mr-2">Contact the host</a> */}
                            {/* <a href="javascript:;" className="btn btn-primary">View Profile</a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="reviews-section" className="reviews-section">
                      <div className="sort-wrap clearfix">
                        <div className="pull-left">
                          <h2 className="title">
                            {this.state.propertyDetail &&
                              this.state.propertyDetail.Review &&
                              this.state.propertyDetail.Review.length
                              ? this.state.propertyDetail.Review.length
                              : 0}{" "}
                            Review
                          </h2>
                        </div>
                      </div>

                      <div className="review-cat">

                        {this.state.propertyDetail &&
                          this.state.propertyDetail.OverallReview && this.state.propertyDetail.OverallReview.length ?
                          this.state.propertyDetail.OverallReview.map((x, i) => {
                            return i % 2 === 0 ? this.state.propertyDetail.OverallReview.slice(i, i + 2) : null;
                          })
                            .filter((x) => x != null)
                            .map((item, indx) => {
                              // let classN = (Number(idx)%2) === 0 ?  "row" : ''; 
                              return (
                                <div className='row' key={indx}>
                                  {item.map((review, idx) => (
                                    <div className="col-lg-6 col-md-6" key={idx}>
                                      <div className="review-cat-opt">
                                        <p>{review.EntityType}</p>
                                        <div className="review-prg">
                                          <span className="rp-stripe">
                                            <span
                                              style={{ width: (review.Rate * 20) + "%" }}
                                              className="rstripe-progress"
                                            ></span>
                                          </span>
                                          <p>{parseFloat(review.Rate).toFixed(1)}</p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )

                            }) : "No reviews found"}

                      </div>
                      {/* <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="review-cat-opt">
                              <p>Cleanliness</p>
                              <div className="review-prg">
                                <span className="rp-stripe">
                                  <span
                                    style={{ width: "80%" }}
                                    className="rstripe-progress"
                                  ></span>
                                </span>
                                <p>4.6</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="review-cat-opt">
                              <p>Accuracy</p>
                              <div className="review-prg">
                                <span className="rp-stripe">
                                  <span
                                    style={{ width: "80%" }}
                                    className="rstripe-progress"
                                  ></span>
                                </span>
                                <p>4.6</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="review-cat-opt">
                              <p>Communication</p>
                              <div className="review-prg">
                                <span className="rp-stripe">
                                  <span
                                    style={{ width: "80%" }}
                                    className="rstripe-progress"
                                  ></span>
                                </span>
                                <p>4.6</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="review-cat-opt">
                              <p>Location</p>
                              <div className="review-prg">
                                <span className="rp-stripe">
                                  <span
                                    style={{ width: "80%" }}
                                    className="rstripe-progress"
                                  ></span>
                                </span>
                                <p>4.6</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row"> */}
                      {/* <div className="col-lg-6 col-md-6">
                            <div className="review-cat-opt">
                              <p>Check-in</p>
                              <div className="review-prg">
                                <span className="rp-stripe">
                                  <span
                                    style={{ width: "80%" }}
                                    className="rstripe-progress"
                                  ></span>
                                </span>
                                <p>4.6</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="review-cat-opt">
                              <p>Value</p>
                              <div className="review-prg">
                                <span className="rp-stripe">
                                  <span
                                    style={{ width: "80%" }}
                                    className="rstripe-progress"
                                  ></span>
                                </span>
                                <p>4.6</p>
                              </div>
                            </div>
                          </div>
                        </div> */}


                      <div className="alert alert-info" role="alert">
                        <i
                          className="fa fa-check-circle-o"
                          aria-hidden="true"
                        ></i>{" "}
                        Verified Reviews - All reviews are from verified guests.
                      </div>
                      <ul id="homey_reviews" className="list-unstyled">
                        {this.state.propertyDetail &&
                          this.state.propertyDetail.Review &&
                          this.state.propertyDetail.Review.length > 0
                          ? this.state.propertyDetail.Review.map((x, idx) => {
                            return (
                              <li id="review-808" className="review-block">
                                <div className="media">
                                  <div className="media-left">
                                    <a className="media-object">
                                      <img
                                        src={avatar}
                                        className="img-circle"
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                  <div className="media-body media-middle" key={idx}>
                                    <div className="msg-user-info">
                                      <div className="msg-user-left">
                                        <div>
                                          <strong>{x.FirstName + " " + x.LastName}</strong>
                                          <span className="rating">
                                            <span className="fa fa-star"></span>{" "}
                                            {parseFloat(x.Rate).toFixed(1)}
                                            <span className="label label-success">
                                              {x.ReviewRatingType}
                                            </span>
                                          </span>
                                        </div>
                                        <div style={{ marginTop: "6px" }}>
                                          {x.Description}
                                        </div>
                                        <div className="message-date">
                                          <i className="fa fa-calendar"></i>{" "}
                                          {new Date(x.CreatedOn).toDateString()}{" "}
                                          <i className="fa fa-clock-o"></i>{" "}
                                          {moment(x.CreatedOn).format("HH:mm A")}
                                        </div>
                                      </div>
                                    </div></div>
                                </div>

                              </li>
                            );
                          })
                          : null}
                      </ul>

                      <div className="similar-listing">
                        <h2 className="title">Similar listing</h2>
                        {this.state.similarPodList && this.state.similarPodList.length > 0 ? <div className="listing-main-wrap">
                          <Swiper
                            pagination={{
                              clickable: true,
                              dynamicBullets: true,
                            }}
                          // autoplay={{
                          //   "delay": 2500,
                          //   "disableOnInteraction": false
                          // }}
                          >
                            {this.state.similarPodList.map((content) => {
                              return (
                                <SwiperSlide>
                                  <div className="listing-media">
                                    <div className="lm-slider">
                                      <Swiper
                                        navigation
                                        pagination={{
                                          clickable: true,
                                          dynamicBullets: true,
                                        }}
                                      >
                                        {content.PropertyMedia.filter(item => item.MediaType.split("/")[0] === 'image').map(
                                          (media) => {
                                            return (
                                              <SwiperSlide>
                                                <img
                                                  src={
                                                    apiBase +
                                                    media.MediaURL
                                                  }
                                                  alt=""
                                                  height="237px"
                                                  width="300px"

                                                />
                                                {/* //   <video
                                                          //     controls
                                                          //     height="200px"
                                                          //     width="300px"
                                                          //   >
                                                          //     <source
                                                          //       src={
                                                          //         apiBase +
                                                          //         media.MediaURL
                                                          //       }
                                                          //       type={
                                                          //         media.MediaType
                                                          //       }
                                                          //     />
                                                          //   </video>
                                                          // ) */}
                                              </SwiperSlide>
                                            );
                                          }
                                        )}
                                      </Swiper>
                                    </div>
                                  </div>
                                  <div className="listing-body">
                                    <div className="listing-item-title">
                                      <a href="javascript:;" onClick={() => this.detailspage(content.PropertyID)}>
                                        {content.PropertyName}
                                      </a>
                                      <span className="listing-item-address">
                                        {content.PropertyAddress}
                                      </span>
                                    </div>
                                    <div className="fpb-amenities listing-item-amenities">
                                      <ul>
                                        {content.Features.map(
                                          (feature) => {
                                            return (
                                              <li>
                                                {feature.FeatureName}
                                              </li>
                                            );
                                          }
                                        )}
                                        {/* <li><i className="fas fa-wifi"></i> Wifi</li>
                                                            <li><i className="fas fa-charging-station"></i> Socket</li>
                                                            <li><i className="fas fa-user-friends"></i> Guests</li> */}
                                      </ul>
                                    </div>
                                    <div class="fpb-slots">
                                      <p>Available Slots</p>
                                      <div class="slots-inner">
                                        {content.Slot && content.Slot.split(",").length > 0 ? content.Slot.split(",").map(x =>
                                          <a
                                            href="javascript:;"
                                            class="slot-text"
                                          >
                                            {x}
                                          </a>) : null}
                                      </div>
                                    </div>
                                    <div className="listing-item-footer">
                                      <div class="listing-price">
                                        <span class="fpb-price">
                                          <small>Starting from</small>
                                          <sup>{parse(content.CurrencySymbol)}</sup>
                                          {content.Price}
                                          <sub></sub>
                                        </span>
                                      </div>
                                      {/* <div className="listing-item-host">
                                                            <img src={avatar} alt="" />
                                                            <span>Hosted By<br />{content.Name}</span>
                                                        </div> */}
                                      {/* <div className="listing-item-rating">
                                                            <div className="fpb-star">
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star-half"></i>
                                                                <span>Excellent</span>
                                                            </div>
                                                        </div> */}
                                    </div>
                                  </div>

                                  {/* <img
                                        src={apiBase + content.MediaURL}
                                        alt=""
                                        height="200px"
                                        width="310px" */}
                                  {/* /> */}
                                </SwiperSlide>
                              );
                            })}
                          </Swiper>
                        </div> : "No pods found"}
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div>
                </div> */}
                {this.props.location.state && !CommonConfig.isEmpty(this.props.location.state.BookingID) ?
                  
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <StickyBox offsetTop={110} offsetBottom={20}>
                      <div className="ck-book-box-wrap">
                        <div className="ckb-box">
                          <img src={tick} alt=""></img>
                          <h2>Your booking is confirmed</h2>
                          <span>Booking number: {bookingHistoryDetail.length > 0 && bookingHistoryDetail[0].BookingNumber ? bookingHistoryDetail[0].BookingNumber : ''}</span>
                        </div>
                        <div className="offer-outer">
                          <a
                            href="javascript:;"
                          >
                            <h3>Discount / Promotion Code</h3>
                          </a>
                          {bookingHistoryDetail.length > 0 && bookingHistoryDetail[0].DiscountType == "Student" ?
                            <div className="dis-applied">
                              <p>
                                {bookingHistoryDetail[0].DiscountPercent}% Student Discount Applied</p>
                            </div>
                            : null}

                          {bookingHistoryDetail.length > 0 && bookingHistoryDetail[0].DiscountType == "Coupon" ?
                            <div className="dis-applied">
                              <p>
                                {bookingHistoryDetail[0].CouponCode} is Applied</p>
                            </div>
                            :
                            null
                          }
                        </div>

                        <div className="ck-price">
                          <h5 className="mb-2 w-600">Booking Summary</h5>
                          <div className="slot-prices">
                            <table>
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Time Slot</th>
                                  {/* <th className="right">Price</th>
                                  <th className="right">Users</th> */}
                                  <th className="right">Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {bookingHistoryDetail.length > 0 ? bookingHistoryDetail.map(x => {
                                  return (
                                    <tr>
                                      <td>{x.SlotDate ? moment(x.SlotDate).format('ddd ,DD MMM YYYY') : ''}</td>
                                      <td>{x.SlotTime}</td>
                                      {/* <td className="right">{x.CurrencySymbol ? parse(x.CurrencySymbol) : ""} {x.SlotPrice}</td>
                                      <td className="right">{x.AdultCount}</td> */}
                                      <td className="right">{x.CurrencySymbol ? parse(x.CurrencySymbol) : ""} {x.FinalSlotPrice}</td>
                                    </tr>
                                  )
                                }) : "No record found"}
                              </tbody>
                              <tfoot>
                                {bookingHistoryDetail.length > 0 && bookingHistoryDetail[0].DiscountType == "Student" ?
                                  <tr>
                                    <th colSpan="2">Student Discount {bookingHistoryDetail[0].DiscountPercent}% applied</th>
                                    <th className="right"> - {bookingHistoryDetail[0].CurrencySymbol ? parse(bookingHistoryDetail[0].CurrencySymbol) : ""}{bookingHistoryDetail[0].DiscountAmount}</th>
                                  </tr>
                                  : null}
                                {bookingHistoryDetail.length > 0 && bookingHistoryDetail[0].DiscountType == "Coupon" ?
                                  <tr>
                                    <th colSpan="2">Promo code discount {bookingHistoryDetail[0].DiscountPercent}% applied</th>
                                    <th className="right"> - {bookingHistoryDetail[0].CurrencySymbol ? parse(bookingHistoryDetail[0].CurrencySymbol) : ""}{bookingHistoryDetail[0].DiscountAmount}</th>
                                  </tr>
                                  : null}

                                <tr>
                                  <th colSpan="2">Total</th>
                                  <th className="right">{bookingHistoryDetail.length > 0 && bookingHistoryDetail[0].CurrencySymbol ? parse(bookingHistoryDetail[0].CurrencySymbol) : ""} {bookingHistoryDetail.length > 0 && bookingHistoryDetail[0].PaidAmount ? bookingHistoryDetail[0].PaidAmount : 0}</th>
                                </tr>
                              </tfoot>

                            </table>
                          </div>
                        </div>
                      </div>
                    </StickyBox>
                  </div>
                :
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <StickyBox offsetTop={110} offsetBottom={20}>
                      <div className="sidebar-sticky">
                        <div className="sidebar-booking">
                          <div className="sb-price">
                            <span className="fpb-price">
                              Starting from <sup>{this.state.propertyDetail.CurrencySymbol ? parse(this.state.propertyDetail.CurrencySymbol) : ""}</sup>
                              {this.state.Price}
                              <sub></sub>
                            </span>
                          </div>
                          <div className="sb-form">
                            {this.state.day === "Single Day" ? (
                              <div>
                                <div className="row">
                                  <div className="col-lg-12 col-md-12">
                                    <div className="common-input with-icon">
                                      <OverlayTrigger
                                        trigger="click"
                                        placement="bottom-end"
                                        rootClose={true}
                                        overlay={
                                          <Popover>
                                            <div className="date-selection">
                                              <div className="date-select-box">
                                                {dayTypeList.map((x) => {
                                                  return (
                                                    <button
                                                      className={
                                                        this.state.day ===
                                                          x.StringMapName
                                                          ? "active"
                                                          : null
                                                      }
                                                      onClick={() =>
                                                        this.handleChange(
                                                          x.StringMapName,
                                                          "day"
                                                        )
                                                      }
                                                    >
                                                      {x.StringMapName}
                                                    </button>
                                                  );
                                                })}
                                              </div>
                                            </div>
                                            <Calendar
                                              onChange={(item) =>
                                                this.handleChange(
                                                  item,
                                                  "singleDate"
                                                )
                                              }
                                              disabledDates={
                                                this.state.disabledDates
                                              }
                                              date={this.state.singleDate}
                                              minDate={new Date()}
                                              maxDate={CommonConfig.addDaysToDates(
                                                new Date(),
                                                30
                                              )}
                                            />
                                          </Popover>
                                        }
                                      >
                                        <input
                                          type="text"
                                          ref={(datepick) =>
                                            (this.datepick = datepick)
                                          }
                                          className={
                                            this.state.DateErr
                                              ? "form-control is-invalid"
                                              : "form-control"
                                          }
                                          placeholder="Date"
                                          readOnly
                                          onClick={() =>
                                            this.handleClickOpenCalendar()
                                          }
                                          value={
                                            this.state.singleDate
                                              ? moment(
                                                this.state.singleDate
                                              ).format("DD/MM/YYYY")
                                              : ""
                                          }
                                        />
                                      </OverlayTrigger>
                                      <i
                                        className="far fa-calendar-alt"
                                        aria-hidden="true"
                                      ></i>
                                      <Form.Control.Feedback type="invalid">
                                        {this.state.DatehelperText}
                                      </Form.Control.Feedback>
                                    </div>
                                  </div>
                                </div>
                                {/* {this.state.singleDate ? */}
                                <div className="row">
                                  <div className="col-lg-12 col-md-12">
                                    {/* <div className="common-input mb-10">
                                                                        {!this.state.IsFullDaySelected && !this.state.IsSlotSelected ?
                                                                            <Select
                                                                                className={this.state.TimeErr ? "is-invalid" : ""}
                                                                                closeMenuOnSelect={true}
                                                                                components={animatedComponents}
                                                                                placeholder={'Time Slot'}
                                                                                value={this.state.Time}
                                                                                isMulti
                                                                                onChange={(e) => this.handleChange(e, "Time")}
                                                                                options={timeSlotListArray}
                                                                            />
                                                                            :
                                                                            this.state.IsFullDaySelected && !this.state.IsSlotSelected ?
                                                                                <Select
                                                                                    className={this.state.TimeErr ? "is-invalid" : ""}
                                                                                    closeMenuOnSelect={true}
                                                                                    components={animatedComponents}
                                                                                    placeholder={'Time Slot'}
                                                                                    value={this.state.Time}
                                                                                    isClearable={true}
                                                                                    isMulti={false}
                                                                                    onChange={(e) => {
                                                                                        if (e === null) {
                                                                                            this.setState({ Time: e, IsFullDaySelected: false, IsSlotSelected: false })
                                                                                        } else {
                                                                                            this.setState({ Time: e })
                                                                                        }
                                                                                    }}
                                                                                    options={timeSlotListArray.filter(x => x.value == "Full Day(09:00 - 17:00)")}
                                                                                />
                                                                                :
                                                                                <Select
                                                                                    className={this.state.TimeErr ? "is-invalid" : ""}
                                                                                    closeMenuOnSelect={false}
                                                                                    components={animatedComponents}
                                                                                    placeholder={'Time Slot'}
                                                                                    value={this.state.Time}
                                                                                    isMulti
                                                                                    onChange={(e) => this.handleChange(e, "Time")}
                                                                                    options={timeSlotListArray.filter(x => x.value !== "Full Day(09:00 - 17:00)")}
                                                                                />
                                                                        }
                                                                        <div className="invalid-feedback d-block">{this.state.TimehelperText}</div>
                                                                    </div>
                                                                 */}
                                    {this.state.filteredSlotArray.length > 0 ? (
                                      filteredSlotArray.map((x, idx) => {
                                        return (
                                          <div className="common-input mb-10">
                                            {!x.IsFullDaySelected &&
                                              !x.IsSlotSelected ? (
                                              <Select
                                                className={
                                                  x.TimeErr ? "is-invalid" : ""
                                                }
                                                closeMenuOnSelect={true}
                                                components={animatedComponents}
                                                placeholder={"Time Slot"}
                                                value={x.Time}
                                                isMulti
                                                onChange={(e) => {
                                                  var slotArray = filteredSlotArray;
                                                  if (
                                                    e.length > 0 &&
                                                    e[0].label ===
                                                    "Full Day(09:00 - 17:00)"
                                                  ) {
                                                    slotArray[idx][
                                                      "IsFullDaySelected"
                                                    ] = 1;
                                                    slotArray[idx][
                                                      "IsSlotSelected"
                                                    ] = 0;
                                                    // this.setState({ IsFullDaySelected: true, IsSlotSelected: false })
                                                  } else if (
                                                    e.length > 0 &&
                                                    e[0].label !==
                                                    "Full Day(09:00 - 17:00)"
                                                  ) {
                                                    slotArray[idx][
                                                      "IsFullDaySelected"
                                                    ] = 0;
                                                    slotArray[idx][
                                                      "IsSlotSelected"
                                                    ] = 1;
                                                    // this.setState({ IsFullDaySelected: false, IsSlotSelected: true })
                                                  } else {
                                                    slotArray[idx][
                                                      "IsFullDaySelected"
                                                    ] = 0;
                                                    slotArray[idx][
                                                      "IsSlotSelected"
                                                    ] = 0;
                                                    // this.setState({ IsFullDaySelected: false, IsSlotSelected: false })
                                                  }
                                                  slotArray[idx]["Time"] = e;
                                                  this.setState({
                                                    filteredSlotArray: slotArray,
                                                  });
                                                  // this.handleChange(e, "Time")
                                                }}
                                                options={timeSlotListArray.filter(
                                                  (id) =>
                                                    x.StringMapKey.split(",")
                                                      .map((t) => {
                                                        return t;
                                                      })
                                                      .includes(id.label)
                                                )}
                                              />
                                            ) : x.IsFullDaySelected &&
                                              !x.IsSlotSelected ? (
                                              <Select
                                                className={
                                                  x.TimeErr ? "is-invalid" : ""
                                                }
                                                closeMenuOnSelect={true}
                                                components={animatedComponents}
                                                placeholder={"Time Slot"}
                                                value={x.Time}
                                                isClearable={true}
                                                isMulti={false}
                                                onChange={(e) => {
                                                  var slotArray = filteredSlotArray;
                                                  if (e === null) {
                                                    slotArray[idx][
                                                      "IsFullDaySelected"
                                                    ] = 0;
                                                    slotArray[idx][
                                                      "IsSlotSelected"
                                                    ] = 0;
                                                    slotArray[idx]["Time"] = e;
                                                    this.setState({
                                                      filteredSlotArray: slotArray,
                                                    });
                                                    // this.setState({ Time: e, IsFullDaySelected: false, IsSlotSelected: false })
                                                  } else {
                                                    slotArray[idx]["Time"] = e;
                                                    this.setState({
                                                      filteredSlotArray: slotArray,
                                                    });
                                                  }
                                                }}
                                                options={timeSlotListArray.filter(
                                                  (i) =>
                                                    i.label ==
                                                    "Full Day(09:00 - 17:00)"
                                                )}
                                              />
                                            ) : (
                                              <Select
                                                className={
                                                  x.TimeErr ? "is-invalid" : ""
                                                }
                                                closeMenuOnSelect={true}
                                                components={animatedComponents}
                                                placeholder={"Time Slot"}
                                                value={x.Time}
                                                isMulti
                                                onChange={(e) => {
                                                  var slotArray = filteredSlotArray;
                                                  if (
                                                    e.length > 0 &&
                                                    e[0].label ===
                                                    "Full Day(09:00 - 17:00)"
                                                  ) {
                                                    slotArray[idx][
                                                      "IsFullDaySelected"
                                                    ] = 1;
                                                    slotArray[idx][
                                                      "IsSlotSelected"
                                                    ] = 0;
                                                    // this.setState({ IsFullDaySelected: true, IsSlotSelected: false })
                                                  } else if (
                                                    e.length > 0 &&
                                                    e[0].label !==
                                                    "Full Day(09:00 - 17:00)"
                                                  ) {
                                                    slotArray[idx][
                                                      "IsFullDaySelected"
                                                    ] = 0;
                                                    slotArray[idx][
                                                      "IsSlotSelected"
                                                    ] = 1;
                                                    // this.setState({ IsFullDaySelected: false, IsSlotSelected: true })
                                                  } else {
                                                    slotArray[idx][
                                                      "IsFullDaySelected"
                                                    ] = 0;
                                                    slotArray[idx][
                                                      "IsSlotSelected"
                                                    ] = 0;
                                                    // this.setState({ IsFullDaySelected: false, IsSlotSelected: false })
                                                  }
                                                  slotArray[idx]["Time"] = e;
                                                  this.setState({
                                                    filteredSlotArray: slotArray,
                                                  });
                                                  // this.handleChange(e, "Time")
                                                }}
                                                options={timeSlotListArray.filter(
                                                  (i) =>
                                                    x.StringMapKey.split(",")
                                                      .map((t) => {
                                                        return t;
                                                      })
                                                      .includes(i.label) &&
                                                    i.label !==
                                                    "Full Day(09:00 - 17:00)"
                                                )}
                                              />
                                            )}
                                            <div className="invalid-feedback d-block">
                                              {x.TimehelperText}
                                            </div>
                                          </div>
                                        );
                                      })
                                    ) : this.state.isDateSelected ? (
                                      <Alert variant={"info"}>
                                        <p>
                                          Unfortunately slots are full . Please
                                          try using different dates.
                                      </p>
                                      </Alert>
                                    ) : this.state.isDateSelected || this.state.disabledDates.length === 31 ? (
                                      <Alert variant={"info"}>

                                        <p>
                                          Please Select a Date
                                    </p>
                                      </Alert>) : null}
                                  </div>
                                </div>
                                {/* // : null} */}
                              </div>
                            ) : (
                              <div>
                                <div className="row">
                                  <div className="col-lg-12 col-md-12">
                                    <div className="common-input with-icon">
                                      <OverlayTrigger
                                        trigger="click"
                                        placement="bottom-end"
                                        rootClose={true}
                                        overlay={
                                          <Popover>
                                            <div className="date-selection">
                                              <div className="date-select-box">
                                                {dayTypeList.map((x) => {
                                                  return (
                                                    <button
                                                      className={
                                                        this.state.day ===
                                                          x.StringMapName
                                                          ? "active"
                                                          : null
                                                      }
                                                      onClick={() =>
                                                        this.handleChange(
                                                          x.StringMapName,
                                                          "day"
                                                        )
                                                      }
                                                    >
                                                      {x.StringMapName}
                                                    </button>
                                                  );
                                                })}
                                              </div>
                                            </div>
                                            <DateRangePicker
                                              onChange={(item) =>
                                                this.handleChange(
                                                  [item.selection],
                                                  "date"
                                                )
                                              }
                                              showSelectionPreview={true}
                                              showMonthAndYearPickers={false}
                                              showDateDisplay={false}
                                              moveRangeOnFirstSelection={false}
                                              months={2}
                                              disabledDates={
                                                this.state.disabledDates
                                              }
                                              ranges={
                                                this.state.selectedDateRange
                                              }
                                              minDate={new Date()}
                                              direction="horizontal"
                                              maxDate={CommonConfig.addDaysToDates(
                                                new Date(),
                                                30
                                              )}
                                            />
                                          </Popover>
                                        }
                                      >
                                        <input
                                          ref={(datepick) =>
                                            (this.datepick = datepick)
                                          }
                                          className={
                                            this.state.DateErr
                                              ? "form-control is-invalid"
                                              : "form-control"
                                          }
                                          type="text"
                                          placeholder="Date"
                                          readOnly
                                          onClick={() =>
                                            this.handleClickOpenCalendar()
                                          }
                                          value={
                                            this.state.selectedDateRange.length >
                                              0 &&
                                              Date.parse(
                                                this.state.selectedDateRange[0]
                                                  .startDate
                                              ) !=
                                              Date.parse(
                                                this.state.selectedDateRange[0]
                                                  .endDate
                                              )
                                              ? moment(
                                                this.state.selectedDateRange[0]
                                                  .startDate
                                              ).format("DD/MM/YYYY") +
                                              "-" +
                                              moment(
                                                this.state.selectedDateRange[0]
                                                  .endDate
                                              ).format("DD/MM/YYYY")
                                              : ""
                                          }
                                        />
                                      </OverlayTrigger>
                                      <i
                                        className="far fa-calendar-alt"
                                        aria-hidden="true"
                                      ></i>
                                      <Form.Control.Feedback type="invalid">
                                        {this.state.DatehelperText}
                                      </Form.Control.Feedback>
                                    </div>
                                  </div>
                                </div>
                                {/* <Slider
                                  {...settingsSliderMultiDay}
                                  className="slider-multiday"
                                > */}
                                  {/* {filteredSlotArray.length > 7 && */}
                                  <div className="custom-slot-slider">
                                  
                                  {filteredSlotArrayDivided && filteredSlotArrayDivided.length > 1 ? <><a className="slot-nav next" style={this.state.index === filteredSlotArrayDivided.length-1 ? {display :  "none"} :  null} onClick = {() => this.handleSlider('next')}><i className="fas fa-angle-right"></i></a>
                                  <a className="slot-nav prev" style={this.state.index === 0 ? {display :  "none"} :  null} onClick = {() => this.handleSlider('prev')}><i className="fas fa-angle-left"></i></a></> :  null}
                                  {/* <div className="slot-slide slot-slide-1"> */}
                                  {filteredSlotArrayDivided && filteredSlotArrayDivided.length > 0 ? (
                                    filteredSlotArrayDivided.map((item, i) => {
                                      // return <SwiperSlide>
                                      //   {
                                      return (
                                        <div className={"slot-slide slot-slide-" + i} id={i}>
                                          {
                                            item &&
                                            item.map((x, idx) => {
                                              return (
                                                <div className="row">
                                                  <div className="col-lg-3 col-md-3 d-flex align-items-center">
                                                    <div className="slot-label text-nowrap">
                                                      <p>
                                                        {moment(x.Dates).format(
                                                          "DD-MM-YYYY"
                                                        )}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div className="col-lg-9 col-md-9 d-flex align-items-center">
                                                    <div className="slot-select">
                                                       {!x.IsFullDaySelected &&
                                                        !x.IsSlotSelected ? (
                                                        // <select 
                                                        // className={x.TimeErr ? "is-invalid" : ""}
                                                        // placeholder={"Time Slot"}  
                                                        // value={x.Time.label}
                                                        // onChange={(e) => {
                                                        //   var slotArray =
                                                        //     filteredSlotArrayDivided[i];
                                                        //   if (
                                                        //     e.length > 0 &&
                                                        //     e[0].label ===
                                                        //     "Full Day(09:00 - 17:00)"
                                                        //   ) {
                                                        //     slotArray[idx][
                                                        //       "IsFullDaySelected"
                                                        //     ] = 1;
                                                        //     slotArray[idx][
                                                        //       "IsSlotSelected"
                                                        //     ] = 0;
                                                        //   } else if (
                                                        //     e.length > 0 &&
                                                        //     e[0].label !==
                                                        //     "Full Day(09:00 - 17:00)"
                                                        //   ) {
                                                        //     slotArray[idx][
                                                        //       "IsFullDaySelected"
                                                        //     ] = 0;
                                                        //     slotArray[idx][
                                                        //       "IsSlotSelected"
                                                        //     ] = 1;
                                                        //   } else {
                                                        //     slotArray[idx][
                                                        //       "IsFullDaySelected"
                                                        //     ] = 0;
                                                        //     slotArray[idx][
                                                        //       "IsSlotSelected"
                                                        //     ] = 0;
                                                        //   }
                                                        //   slotArray[idx][
                                                        //     "Time"
                                                        //   ] = e;
                                                        //   let tempfilteredSlotArrayDivided = filteredSlotArrayDivided;
                                                        //   tempfilteredSlotArrayDivided[
                                                        //     i
                                                        //   ] = slotArray;
                                                        //   this.setState({
                                                        //     filteredSlotArrayDivided: tempfilteredSlotArrayDivided,
                                                        //   });
                                                        // }}
                                                        // >
                                                        //  {timeSlotListArray.filter(id =>
                                                        //     x.StringMapKey.split(",").map((t) => {
                                                        //       return t;
                                                        //     }).includes(id.label)
                                                        //   ).map(x => {
                                                        //     return <option value ={x.label}>{x.label}</option>
                                                        //   }
                                                        //   )}
                                                        // </select> 
                                                         <Select
                                                        menuPlacement = {"bottom"}
                                                          className={
                                                            x.TimeErr
                                                              ? "is-invalid"
                                                              : ""
                                                          }
                                                          closeMenuOnSelect={
                                                            true
                                                          }
                                                          components={
                                                            animatedComponents
                                                          }
                                                          placeholder={
                                                            "Time Slot"
                                                          }
                                                          value={x.Time}
                                                          isMulti
                                                          onChange={(e) => {
                                                            var slotArray =
                                                              filteredSlotArrayDivided[
                                                              i
                                                              ];
                                                            if (
                                                              e.length > 0 &&
                                                              e[0].label ===
                                                              "Full Day(09:00 - 17:00)"
                                                            ) {
                                                              slotArray[idx][
                                                                "IsFullDaySelected"
                                                              ] = 1;
                                                              slotArray[idx][
                                                                "IsSlotSelected"
                                                              ] = 0;
                                                              // this.setState({ IsFullDaySelected: true, IsSlotSelected: false })
                                                            } else if (
                                                              e.length > 0 &&
                                                              e[0].label !==
                                                              "Full Day(09:00 - 17:00)"
                                                            ) {
                                                              slotArray[idx][
                                                                "IsFullDaySelected"
                                                              ] = 0;
                                                              slotArray[idx][
                                                                "IsSlotSelected"
                                                              ] = 1;
                                                              // this.setState({ IsFullDaySelected: false, IsSlotSelected: true })
                                                            } else {
                                                              slotArray[idx][
                                                                "IsFullDaySelected"
                                                              ] = 0;
                                                              slotArray[idx][
                                                                "IsSlotSelected"
                                                              ] = 0;
                                                              // this.setState({ IsFullDaySelected: false, IsSlotSelected: false })
                                                            }
                                                            slotArray[idx][
                                                              "Time"
                                                            ] = e;
                                                            let tempfilteredSlotArrayDivided = filteredSlotArrayDivided;
                                                            tempfilteredSlotArrayDivided[
                                                              i
                                                            ] = slotArray;
                                                            this.setState({
                                                              filteredSlotArrayDivided: tempfilteredSlotArrayDivided,
                                                            });
                                                            // this.handleChange(e, "Time")
                                                          }}
                                                          options={timeSlotListArray.filter(
                                                            (id) =>
                                                              x.StringMapKey.split(
                                                                ","
                                                              )
                                                                .map((t) => {
                                                                  return t;
                                                                })
                                                                .includes(
                                                                  id.label
                                                                )
                                                          )}
                                                        />
                                                      ) : x.IsFullDaySelected &&
                                                        !x.IsSlotSelected ? (
                                                          // <select 
                                                          // className={x.TimeErr ? "is-invalid" : ""}
                                                          // placeholder={"Time Slot"}  
                                                          // value={x.Time.label}
                                                          // onChange={(e) => {
                                                          //   var slotArray =
                                                          //     filteredSlotArrayDivided[
                                                          //     i
                                                          //     ];
                                                          //   if (e === null) {
                                                          //     slotArray[idx][
                                                          //       "IsFullDaySelected"
                                                          //     ] = 0;
                                                          //     slotArray[idx][
                                                          //       "IsSlotSelected"
                                                          //     ] = 0;
                                                          //     slotArray[idx][
                                                          //       "Time"
                                                          //     ] = e;
                                                          //     this.setState({
                                                          //       filteredSlotArray: slotArray,
                                                          //     });
                                                              
                                                          //   } else {
                                                          //     slotArray[idx][
                                                          //       "Time"
                                                          //     ] = e;
                                                          //     let tempfilteredSlotArrayDivided = filteredSlotArrayDivided;
                                                          //     tempfilteredSlotArrayDivided[
                                                          //       i
                                                          //     ] = slotArray;
                                                          //     this.setState({
                                                          //       filteredSlotArrayDivided: tempfilteredSlotArrayDivided,
                                                          //     });
                                                          //   }
                                                          // }} >
                                                          // {timeSlotListArray.filter(
                                                          //   (id) =>
                                                          //     id.label ==
                                                          //     "Full Day(09:00 - 17:00)"
                                                          //   ).map(x => {
                                                          //   return <option value ={x.label}>{x.label}</option>
                                                          // })}
                                                          // </select>
                                                        <Select
                                                          menuPlacement = {"bottom"}
                                                          className={
                                                            x.TimeErr
                                                              ? "is-invalid"
                                                              : ""
                                                          }
                                                          closeMenuOnSelect={
                                                            true
                                                          }
                                                          components={
                                                            animatedComponents
                                                          }
                                                          placeholder={
                                                            "Time Slot"
                                                          }
                                                          value={x.Time}
                                                          isClearable={true}
                                                          isMulti={false}
                                                          onChange={(e) => {
                                                            var slotArray =
                                                              filteredSlotArrayDivided[
                                                              i
                                                              ];
                                                            if (e === null) {
                                                              slotArray[idx][
                                                                "IsFullDaySelected"
                                                              ] = 0;
                                                              slotArray[idx][
                                                                "IsSlotSelected"
                                                              ] = 0;
                                                              slotArray[idx][
                                                                "Time"
                                                              ] = e;
                                                              this.setState({
                                                                filteredSlotArray: slotArray,
                                                              });
                                                              // this.setState({ Time: e, IsFullDaySelected: false, IsSlotSelected: false })
                                                            } else {
                                                              slotArray[idx][
                                                                "Time"
                                                              ] = e;
                                                              let tempfilteredSlotArrayDivided = filteredSlotArrayDivided;
                                                              tempfilteredSlotArrayDivided[
                                                                i
                                                              ] = slotArray;
                                                              this.setState({
                                                                filteredSlotArrayDivided: tempfilteredSlotArrayDivided,
                                                              });
                                                            }
                                                          }}
                                                          options={timeSlotListArray.filter(
                                                            (id) =>
                                                              id.label ==
                                                              "Full Day(09:00 - 17:00)"
                                                          )}
                                                        />
                                                      ) : (
                                                        // <select 
                                                        //   className={x.TimeErr ? "is-invalid" : ""}
                                                        //   placeholder={"Time Slot"}  
                                                        //   value={x.Time.label}
                                                        //   onChange={(e) => {
                                                        //     var slotArray =
                                                        //       filteredSlotArrayDivided[
                                                        //       i
                                                        //       ];
                                                        //     if (
                                                        //       e.length > 0 &&
                                                        //       e[0].label ===
                                                        //       "Full Day(09:00 - 17:00)"
                                                        //     ) {
                                                        //       slotArray[idx][
                                                        //         "IsFullDaySelected"
                                                        //       ] = 1;
                                                        //       slotArray[idx][
                                                        //         "IsSlotSelected"
                                                        //       ] = 0;
                                                        //     } else if (
                                                        //       e.length > 0 &&
                                                        //       e[0].label !==
                                                        //       "Full Day(09:00 - 17:00)"
                                                        //     ) {
                                                        //       slotArray[idx][
                                                        //         "IsFullDaySelected"
                                                        //       ] = 0;
                                                        //       slotArray[idx][
                                                        //         "IsSlotSelected"
                                                        //       ] = 1;
                                                        //     } else {
                                                        //       slotArray[idx][
                                                        //         "IsFullDaySelected"
                                                        //       ] = 0;
                                                        //       slotArray[idx][
                                                        //         "IsSlotSelected"
                                                        //       ] = 0;
                                                        //     }
                                                        //     slotArray[idx][
                                                        //       "Time"
                                                        //     ] = e;
                                                        //     let tempfilteredSlotArrayDivided = filteredSlotArrayDivided;
                                                        //     tempfilteredSlotArrayDivided[
                                                        //       i
                                                        //     ] = slotArray;
                                                        //     this.setState({
                                                        //       filteredSlotArrayDivided: tempfilteredSlotArrayDivided,
                                                        //     });
                                                        //   }}>
                                                        //     {timeSlotListArray.filter(
                                                        //     (id) => x.StringMapKey.split(",").map((t) => {
                                                        //           return t;
                                                        //         }).includes(id.label) &&
                                                        //       id.label !=="Full Day(09:00 - 17:00)"
                                                        //      ).map(x => {
                                                        //       return <option value ={x.label}>{x.label}</option>}
                                                        //     )}
                                                        //   </select>
                                                        <Select
                                                        menuPlacement = {"bottom"}
                                                          className={
                                                            x.TimeErr
                                                              ? "is-invalid"
                                                              : ""
                                                          }
                                                          closeMenuOnSelect={
                                                            true
                                                          }
                                                          components={
                                                            animatedComponents
                                                          }
                                                          placeholder={
                                                            "Time Slot"
                                                          }
                                                          value={x.Time}
                                                          isMulti
                                                          onChange={(e) => {
                                                            var slotArray =
                                                              filteredSlotArrayDivided[
                                                              i
                                                              ];
                                                            if (
                                                              e.length > 0 &&
                                                              e[0].label ===
                                                              "Full Day(09:00 - 17:00)"
                                                            ) {
                                                              slotArray[idx][
                                                                "IsFullDaySelected"
                                                              ] = 1;
                                                              slotArray[idx][
                                                                "IsSlotSelected"
                                                              ] = 0;
                                                              // this.setState({ IsFullDaySelected: true, IsSlotSelected: false })
                                                            } else if (
                                                              e.length > 0 &&
                                                              e[0].label !==
                                                              "Full Day(09:00 - 17:00)"
                                                            ) {
                                                              slotArray[idx][
                                                                "IsFullDaySelected"
                                                              ] = 0;
                                                              slotArray[idx][
                                                                "IsSlotSelected"
                                                              ] = 1;
                                                              // this.setState({ IsFullDaySelected: false, IsSlotSelected: true })
                                                            } else {
                                                              slotArray[idx][
                                                                "IsFullDaySelected"
                                                              ] = 0;
                                                              slotArray[idx][
                                                                "IsSlotSelected"
                                                              ] = 0;
                                                              // this.setState({ IsFullDaySelected: false, IsSlotSelected: false })
                                                            }
                                                            slotArray[idx][
                                                              "Time"
                                                            ] = e;
                                                            let tempfilteredSlotArrayDivided = filteredSlotArrayDivided;
                                                            tempfilteredSlotArrayDivided[
                                                              i
                                                            ] = slotArray;
                                                            this.setState({
                                                              filteredSlotArrayDivided: tempfilteredSlotArrayDivided,
                                                            });
                                                            // this.handleChange(e, "Time")
                                                          }}
                                                          options={timeSlotListArray.filter(
                                                            (id) =>
                                                              x.StringMapKey.split(
                                                                ","
                                                              )
                                                                .map((t) => {
                                                                  return t;
                                                                })
                                                                .includes(
                                                                  id.label
                                                                ) &&
                                                              id.label !==
                                                              "Full Day(09:00 - 17:00)"
                                                          )}
                                                        />
                                                      )}
                                                      <div className="invalid-feedback d-block">
                                                        {x.TimehelperText}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            })
                                            // }
                                            // );
                                            // </SwiperSlide>
                                          }
                                        </div>
                                      );
                                    })
                                  ) :
                                    this.state.isDateSelected ?
                                      (
                                        <Alert variant={"info"}>
                                          <p>
                                            Unfortunately slots are full . Please try
                                            using different dates.
                                          </p>
                                        </Alert>
                                      ) :
                                      this.state.isDateSelected || this.state.disabledDates.length === 31 ?
                                        (
                                          <Alert variant={"info"}>
                                            <p>
                                              Unfortunately slots are full. Please try
                                              using different dates.
                                            </p>
                                          </Alert>
                                        ) : (<Alert variant={"info"}>
                                          <p>
                                            Please select the dates.
                                          </p>
                                        </Alert>)
                                  }

                                  </div>
                                {/* </Slider> */}
                                {/* <div className="custom-slot-slider">

                                  </div>
                                {/* </Slider> */}
                                {/* <div className="custom-slot-slider">

                                  <a className="slot-nav next"><i className="fas fa-angle-right"></i></a>
                                  <a className="slot-nav prev"><i className="fas fa-angle-left"></i></a>
                                  <div className="slot-slide slot-slide-1">
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="slot-slide slot-slide-2" style={{display: "none"}}>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="slot-slide slot-slide-3" style={{display: "none"}}>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="slot-slide slot-slide-4" style={{display: "none"}}>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-label">
                                          <p>15-06-2021</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                        <div className="slot-select">
                                          <select>
                                            <option>Time Slot</option>
                                            <option>Time Slot 1</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              */}
                              </div>
                            )}
                            {this.state.Users !== "Multi" ? (
                              <div className="common-input with-icon">
                                <select
                                  className={[
                                    this.state.UsersErr
                                      ? "form-control is-invalid"
                                      : "form-control",
                                  ]}
                                  value={this.state.Users}
                                  onChange={(e) => this.handleChange(e, "Users")}
                                >
                                  {noOfUsersList.map((x) => {
                                    return (
                                      <option value={x.StringMapName}>
                                        {x.StringMapName}
                                      </option>
                                    );
                                  })}
                                </select>
                                <i className="fas fa-user" aria-hidden="true"></i>
                                <Form.Control.Feedback type="invalid">
                                  {this.state.UsershelperText}
                                </Form.Control.Feedback>
                              </div>
                            ) : (
                              <div className="common-input with-icon">
                                <select
                                  className="form-control"
                                  value={this.state.Users}
                                  onChange={(e) => this.handleChange(e, "Users")}
                                >
                                  {noOfUsersList.map((x) => {
                                    return (
                                      <option value={x.StringMapName}>
                                        {x.StringMapName}
                                      </option>
                                    );
                                  })}
                                </select>
                                <input
                                  type="number"
                                  placeholder="Enter users count"
                                  className={[
                                    this.state.UsersErr
                                      ? "form-control is-invalid"
                                      : "form-control",
                                  ]}
                                  value={this.state.UsersCount}
                                  onChange={(e) =>
                                    this.handleChange(e, "UsersCount")
                                  }
                                />
                                <i className="fas fa-user" aria-hidden="true"></i>
                                <Form.Control.Feedback type="invalid">
                                  {this.state.UsershelperText}
                                </Form.Control.Feedback>
                              </div>
                            )}
                            {/* <select className={this.state.UsersErr ? "form-control is-invalid" : "form-control"} onChange={(e) => this.handleChange(e, "Users")} value={this.state.Users}>
                                                        <option selected disabled hidden value="1">1</option>
                                                        {noOfUsersList.map((x, idx) => {
                                                            return (
                                                                <option value={x.StringMapName} selected={idx===0?true:false} key={idx}>{x.StringMapName}</option>
                                                            )
                                                        })}
                                                    </select> */}

                            <div className="sb-form-submit">
                              <button onClick={(e) => this.bookpod(e)}>
                                Check Out
                            </button>
                            </div>
                          </div>
                        </div>
                        <div className="sidebar-booking-footer">
                          <button
                            className={
                              this.state.propertyDetail.UserPropertyFavoriteID ==
                                null
                                ? ""
                                : "active"
                            }
                            onClick={(e) =>
                              this.handleFavModal(
                                e,
                                this.state.propertyDetail,
                                this.state.propertyDetail
                                  .UserPropertyFavoriteID == null
                                  ? "Favorite"
                                  : "UnFavorite"
                              )
                            }
                          >
                            <i className="fas fa-heart"></i>{" "}
                            {this.state.propertyDetail.UserPropertyFavoriteID ==
                              null
                              ? "Add to Favorite"
                              : "Added to Favorite"}
                          </button>
                          <button
                            className="contact-host"
                            onClick={() =>
                              this.setState({ showContactHost: true })
                            }
                          >
                            Contact the host
                        </button>
                        </div>
                      </div>
                    </StickyBox>
                  </div>
                    
              }
              </div>
            </div>
            <div
              className={
                this.state.IsMobileCheckOutActive
                  ? "mobile-checkout active"
                  : "mobile-checkout"
              }
            >
              <div className="mc-head">
                <p>Total: {this.state.propertyDetail.CurrencySymbol ? parse(this.state.propertyDetail.CurrencySymbol) : ""}{this.state.Price}</p>
                <a
                  href="javascript:;"
                  onClick={() =>
                    this.setState({
                      IsMobileCheckOutActive: !this.state
                        .IsMobileCheckOutActive,
                    })
                  }
                >
                  <i
                    className={
                      this.state.IsMobileCheckOutActive
                        ? "fa fa-angle-down"
                        : "fa fa-angle-up"
                    }
                  ></i>
                </a>
              </div>
              <div className="mc-content">
                {/* {this.state.day === "Single Day" ? (
                  <div>
                    <div className="common-input with-icon">
                      <OverlayTrigger
                        trigger="click"
                        placement="top"
                        rootClose={true}
                        overlay={
                          <Popover>
                            <div className="date-selection">
                              <div className="date-select-box">
                                {dayTypeList.map((x) => {
                                  return (
                                    <button
                                      className={
                                        this.state.day === x.StringMapName
                                          ? "active"
                                          : null
                                      }
                                      onClick={() =>
                                        this.handleChange(
                                          x.StringMapName,
                                          "day"
                                        )
                                      }
                                    >
                                      {x.StringMapName}
                                    </button>
                                  );
                                })}
                              </div>
                            </div>
                            <Calendar
                              onChange={(item) =>
                                this.handleChange(item, "singleDate")
                              }
                              date={this.state.singleDate}
                              minDate={new Date()}
                            />
                          </Popover>
                        }
                      >
                        <input
                          type="text"
                          className={
                            this.state.DateErr
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          placeholder="Date"
                          readOnly
                          onClick={() => this.handleClickOpenCalendar()}
                          value={
                            this.state.singleDate
                              ? moment(this.state.singleDate).format(
                                  "DD/MM/YYYY"
                                )
                              : ""
                          }
                        />
                      </OverlayTrigger>
                      <i className="far fa-calendar-alt" aria-hidden="true"></i>
                      <Form.Control.Feedback type="invalid">
                        {this.state.DatehelperText}
                      </Form.Control.Feedback>
                    </div>

                    <div className="common-input mb-10">
                      {!this.state.IsFullDaySelected &&
                      !this.state.IsSlotSelected ? (
                        <Select
                          className={this.state.TimeErr ? "is-invalid" : ""}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          placeholder={"Time Slot"}
                          value={this.state.Time}
                          isMulti
                          onChange={(e) => this.handleChange(e, "Time")}
                          options={timeSlotListArray}
                        />
                      ) : this.state.IsFullDaySelected &&
                        !this.state.IsSlotSelected ? (
                        <Select
                          className={this.state.TimeErr ? "is-invalid" : ""}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          placeholder={"Time Slot"}
                          value={this.state.Time}
                          isClearable={true}
                          isMulti={false}
                          onChange={(e) => {
                            if (e === null) {
                              this.setState({
                                Time: e,
                                IsFullDaySelected: false,
                                IsSlotSelected: false,
                              });
                            } else {
                              this.setState({ Time: e });
                            }
                          }}
                          options={timeSlotListArray.filter(
                            (x) =>
                              x.label == "Full Day(09:00 - 17:00)"
                          )}
                        />
                      ) : (
                        <Select
                          className={this.state.TimeErr ? "is-invalid" : ""}
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          placeholder={"Time Slot"}
                          value={this.state.Time}
                          isMulti
                          onChange={(e) => this.handleChange(e, "Time")}
                          options={timeSlotListArray.filter(
                            (x) =>
                              x.label !== "Full Day(09:00 - 17:00)"
                          )}
                        />
                      )}
                      <div className="invalid-feedback d-block">
                        {this.state.TimehelperText}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="common-input with-icon">
                          <OverlayTrigger
                            trigger="click"
                            placement="top"
                            rootClose={true}
                            overlay={
                              <Popover>
                                <div className="date-selection">
                                  <div className="date-select-box">
                                    {dayTypeList.map((x) => {
                                      return (
                                        <button
                                          className={
                                            this.state.day === x.StringMapName
                                              ? "active"
                                              : null
                                          }
                                          onClick={() =>
                                            this.handleChange(
                                              x.StringMapName,
                                              "day"
                                            )
                                          }
                                        >
                                          {x.StringMapName}
                                        </button>
                                      );
                                    })}
                                  </div>
                                </div>
                                <DateRangePicker
                                  onChange={(item) =>
                                    this.handleChange([item.selection], "date")
                                  }
                                  showSelectionPreview={true}
                                  showMonthAndYearPickers={false}
                                  showDateDisplay={false}
                                  moveRangeOnFirstSelection={false}
                                  months={2}
                                  ranges={this.state.selectedDateRange}
                                  minDate={new Date()}
                                  direction="horizontal"
                                />
                              </Popover>
                            }
                          >
                            <input
                              className={
                                this.state.DateErr
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              type="text"
                              placeholder="Date"
                              readOnly
                              onClick={() => this.handleClickOpenCalendar()}
                              value={
                                this.state.selectedDateRange.length > 0 &&
                                Date.parse(
                                  this.state.selectedDateRange[0].startDate
                                ) !=
                                  Date.parse(
                                    this.state.selectedDateRange[0].endDate
                                  )
                                  ? moment(
                                      this.state.selectedDateRange[0].startDate
                                    ).format("DD/MM/YYYY") +
                                    "-" +
                                    moment(
                                      this.state.selectedDateRange[0].endDate
                                    ).format("DD/MM/YYYY")
                                  : ""
                              }
                            />
                          </OverlayTrigger>
                          <i
                            className="far fa-calendar-alt"
                            aria-hidden="true"
                          ></i>
                          <Form.Control.Feedback type="invalid">
                            {this.state.DatehelperText}
                          </Form.Control.Feedback>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="common-input with-icon">
                          {!this.state.IsArriveFullDaySelected &&
                          !this.state.IsArriveSlotSelected ? (
                            <Select
                              className={
                                this.state.ArriveTimeErr ? "is-invalid" : ""
                              }
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              placeholder={"Arrival Time"}
                              value={this.state.ArriveTime}
                              isMulti
                              onChange={(e) =>
                                this.handleChange(e, "ArriveTime")
                              }
                              options={timeSlotListArray}
                            />
                          ) : this.state.IsArriveFullDaySelected &&
                            !this.state.IsArriveSlotSelected ? (
                            <Select
                              className={
                                this.state.ArriveTimeErr ? "is-invalid" : ""
                              }
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              placeholder={"Arrival Time"}
                              value={this.state.ArriveTime}
                              isMulti={false}
                              onChange={(e) => {
                                if (e === null) {
                                  this.setState({
                                    ArriveTime: e,
                                    IsArriveFullDaySelected: false,
                                    IsSlotSelected: false,
                                  });
                                } else {
                                  this.setState({ ArriveTime: e });
                                }
                              }}
                              options={timeSlotListArray.filter(
                                (x) =>
                                  x.label ==
                                  "Full Day(09:00 - 17:00)"
                              )}
                            />
                          ) : (
                            <Select
                              className={
                                this.state.ArriveTimeErr ? "is-invalid" : ""
                              }
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              placeholder={"Arrival Time"}
                              value={this.state.ArriveTime}
                              isMulti
                              onChange={(e) =>
                                this.handleChange(e, "ArriveTime")
                              }
                              options={timeSlotListArray.filter(
                                (x) =>
                                  x.label !==
                                  "Full Day(09:00 - 17:00)"
                              )}
                            />
                          )}
                          <div className="invalid-feedback d-block">
                            {this.state.ArriveTimehelperText}
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.ArriveTime.length > 0 &&
                    !this.state.IsArriveFullDaySelected ? (
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <label>
                            <input
                              type="checkbox"
                              checked={
                                this.state.IsSameSlotSelected ? true : false
                              }
                              onChange={(e) =>
                                this.handleChange(e, "IsSameSlot")
                              }
                            />{" "}
                            Do you want select same slot for all days ?
                          </label>
                        </div>
                      </div>
                    ) : null}
                    {this.state.IsSameSlotSelected ? null : (
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <div className="common-input with-icon">
                            {!this.state.IsDepartFullDaySelected &&
                            !this.state.IsDepartSlotSelected ? (
                              <Select
                                className={
                                  this.state.DepartTimeErr ? "is-invalid" : ""
                                }
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                placeholder={"Departure Time"}
                                value={this.state.DepartTime}
                                isMulti
                                onChange={(e) =>
                                  this.handleChange(e, "DepartTime")
                                }
                                options={timeSlotListArray}
                              />
                            ) : this.state.IsDepartFullDaySelected &&
                              !this.state.IsDepartSlotSelected ? (
                              <Select
                                className={
                                  this.state.DepartTimeErr ? "is-invalid" : ""
                                }
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                placeholder={"Departure Time"}
                                value={this.state.DepartTime}
                                isMulti={false}
                                onChange={(e) => {
                                  if (e === null) {
                                    this.setState({
                                      DepartTime: e,
                                      IsDepartFullDaySelected: false,
                                      IsSlotSelected: false,
                                    });
                                  } else {
                                    this.setState({ DepartTime: e });
                                  }
                                }}
                                options={timeSlotListArray.filter(
                                  (x) =>
                                    x.label ==
                                    "Full Day(09:00 - 17:00)"
                                )}
                              />
                            ) : (
                              <Select
                                className={
                                  this.state.DepartTimeErr ? "is-invalid" : ""
                                }
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                placeholder={"Departure Time"}
                                value={this.state.DepartTime}
                                isMulti
                                onChange={(e) =>
                                  this.handleChange(e, "DepartTime")
                                }
                                options={timeSlotListArray.filter(
                                  (x) =>
                                    x.label !==
                                    "Full Day(09:00 - 17:00)"
                                )}
                              />
                            )}
                            <div className="invalid-feedback d-block">
                              {this.state.DepartTimehelperText}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )} */}

                {this.state.day === "Single Day" ? (
                  <div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="common-input with-icon">
                          <OverlayTrigger
                            trigger="click"
                            placement="top"
                            rootClose={true}
                            overlay={
                              <Popover>
                                <div className="date-selection">
                                  <div className="date-select-box">
                                    {dayTypeList.map((x) => {
                                      return (
                                        <button
                                          className={
                                            this.state.day ===
                                              x.StringMapName
                                              ? "active"
                                              : null
                                          }
                                          onClick={() =>
                                            this.handleChange(
                                              x.StringMapName,
                                              "day"
                                            )
                                          }
                                        >
                                          {x.StringMapName}
                                        </button>
                                      );
                                    })}
                                  </div>
                                </div>
                                <Calendar
                                  onChange={(item) =>
                                    this.handleChange(
                                      item,
                                      "singleDate"
                                    )
                                  }
                                  disabledDates={
                                    this.state.disabledDates
                                  }
                                  date={this.state.singleDate}
                                  minDate={new Date()}
                                  maxDate={CommonConfig.addDaysToDates(
                                    new Date(),
                                    30
                                  )}
                                />
                              </Popover>
                            }
                          >
                            <input
                              type="text"
                              ref={(datepick) =>
                                (this.datepick = datepick)
                              }
                              className={
                                this.state.DateErr
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              placeholder="Date"
                              readOnly
                              onClick={() =>
                                this.handleClickOpenCalendar()
                              }
                              value={
                                this.state.singleDate
                                  ? moment(
                                    this.state.singleDate
                                  ).format("DD/MM/YYYY")
                                  : ""
                              }
                            />
                          </OverlayTrigger>
                          <i
                            className="far fa-calendar-alt"
                            aria-hidden="true"
                          ></i>
                          <Form.Control.Feedback type="invalid">
                            {this.state.DatehelperText}
                          </Form.Control.Feedback>
                        </div>
                      </div>
                    </div>
                    {/* {this.state.singleDate ? */}
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        {/* <div className="common-input mb-10">
                                                                        {!this.state.IsFullDaySelected && !this.state.IsSlotSelected ?
                                                                            <Select
                                                                                className={this.state.TimeErr ? "is-invalid" : ""}
                                                                                closeMenuOnSelect={true}
                                                                                components={animatedComponents}
                                                                                placeholder={'Time Slot'}
                                                                                value={this.state.Time}
                                                                                isMulti
                                                                                onChange={(e) => this.handleChange(e, "Time")}
                                                                                options={timeSlotListArray}
                                                                            />
                                                                            :
                                                                            this.state.IsFullDaySelected && !this.state.IsSlotSelected ?
                                                                                <Select
                                                                                    className={this.state.TimeErr ? "is-invalid" : ""}
                                                                                    closeMenuOnSelect={true}
                                                                                    components={animatedComponents}
                                                                                    placeholder={'Time Slot'}
                                                                                    value={this.state.Time}
                                                                                    isClearable={true}
                                                                                    isMulti={false}
                                                                                    onChange={(e) => {
                                                                                        if (e === null) {
                                                                                            this.setState({ Time: e, IsFullDaySelected: false, IsSlotSelected: false })
                                                                                        } else {
                                                                                            this.setState({ Time: e })
                                                                                        }
                                                                                    }}
                                                                                    options={timeSlotListArray.filter(x => x.value == "Full Day(09:00 - 17:00)")}
                                                                                />
                                                                                :
                                                                                <Select
                                                                                    className={this.state.TimeErr ? "is-invalid" : ""}
                                                                                    closeMenuOnSelect={false}
                                                                                    components={animatedComponents}
                                                                                    placeholder={'Time Slot'}
                                                                                    value={this.state.Time}
                                                                                    isMulti
                                                                                    onChange={(e) => this.handleChange(e, "Time")}
                                                                                    options={timeSlotListArray.filter(x => x.value !== "Full Day(09:00 - 17:00)")}
                                                                                />
                                                                        }
                                                                        <div className="invalid-feedback d-block">{this.state.TimehelperText}</div>
                                                                    </div>
                                                                 */}
                        {this.state.filteredSlotArray.length > 0 ? (
                          filteredSlotArray.map((x, idx) => {
                            return (
                              <div className="common-input mb-10">
                                {!x.IsFullDaySelected &&
                                  !x.IsSlotSelected ? (
                                  <Select
                                    className={
                                      x.TimeErr ? "is-invalid" : ""
                                    }
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    placeholder={"Time Slot"}
                                    value={x.Time}
                                    isMulti
                                    onChange={(e) => {
                                      var slotArray = filteredSlotArray;
                                      if (
                                        e.length > 0 &&
                                        e[0].label ===
                                        "Full Day(09:00 - 17:00)"
                                      ) {
                                        slotArray[idx][
                                          "IsFullDaySelected"
                                        ] = 1;
                                        slotArray[idx][
                                          "IsSlotSelected"
                                        ] = 0;
                                        // this.setState({ IsFullDaySelected: true, IsSlotSelected: false })
                                      } else if (
                                        e.length > 0 &&
                                        e[0].label !==
                                        "Full Day(09:00 - 17:00)"
                                      ) {
                                        slotArray[idx][
                                          "IsFullDaySelected"
                                        ] = 0;
                                        slotArray[idx][
                                          "IsSlotSelected"
                                        ] = 1;
                                        // this.setState({ IsFullDaySelected: false, IsSlotSelected: true })
                                      } else {
                                        slotArray[idx][
                                          "IsFullDaySelected"
                                        ] = 0;
                                        slotArray[idx][
                                          "IsSlotSelected"
                                        ] = 0;
                                        // this.setState({ IsFullDaySelected: false, IsSlotSelected: false })
                                      }
                                      slotArray[idx]["Time"] = e;
                                      this.setState({
                                        filteredSlotArray: slotArray,
                                      });
                                      // this.handleChange(e, "Time")
                                    }}
                                    options={timeSlotListArray.filter(
                                      (id) =>
                                        x.StringMapKey.split(",")
                                          .map((t) => {
                                            return t;
                                          })
                                          .includes(id.label)
                                    )}
                                  />
                                ) : x.IsFullDaySelected &&
                                  !x.IsSlotSelected ? (
                                  <Select
                                    className={
                                      x.TimeErr ? "is-invalid" : ""
                                    }
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    placeholder={"Time Slot"}
                                    value={x.Time}
                                    isClearable={true}
                                    isMulti={false}
                                    onChange={(e) => {
                                      var slotArray = filteredSlotArray;
                                      if (e === null) {
                                        slotArray[idx][
                                          "IsFullDaySelected"
                                        ] = 0;
                                        slotArray[idx][
                                          "IsSlotSelected"
                                        ] = 0;
                                        slotArray[idx]["Time"] = e;
                                        this.setState({
                                          filteredSlotArray: slotArray,
                                        });
                                        // this.setState({ Time: e, IsFullDaySelected: false, IsSlotSelected: false })
                                      } else {
                                        slotArray[idx]["Time"] = e;
                                        this.setState({
                                          filteredSlotArray: slotArray,
                                        });
                                      }
                                    }}
                                    options={timeSlotListArray.filter(
                                      (i) =>
                                        i.label ==
                                        "Full Day(09:00 - 17:00)"
                                    )}
                                  />
                                ) : (
                                  <Select
                                    className={
                                      x.TimeErr ? "is-invalid" : ""
                                    }
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    placeholder={"Time Slot"}
                                    value={x.Time}
                                    isMulti
                                    onChange={(e) => {
                                      var slotArray = filteredSlotArray;
                                      if (
                                        e.length > 0 &&
                                        e[0].label ===
                                        "Full Day(09:00 - 17:00)"
                                      ) {
                                        slotArray[idx][
                                          "IsFullDaySelected"
                                        ] = 1;
                                        slotArray[idx][
                                          "IsSlotSelected"
                                        ] = 0;
                                        // this.setState({ IsFullDaySelected: true, IsSlotSelected: false })
                                      } else if (
                                        e.length > 0 &&
                                        e[0].label !==
                                        "Full Day(09:00 - 17:00)"
                                      ) {
                                        slotArray[idx][
                                          "IsFullDaySelected"
                                        ] = 0;
                                        slotArray[idx][
                                          "IsSlotSelected"
                                        ] = 1;
                                        // this.setState({ IsFullDaySelected: false, IsSlotSelected: true })
                                      } else {
                                        slotArray[idx][
                                          "IsFullDaySelected"
                                        ] = 0;
                                        slotArray[idx][
                                          "IsSlotSelected"
                                        ] = 0;
                                        // this.setState({ IsFullDaySelected: false, IsSlotSelected: false })
                                      }
                                      slotArray[idx]["Time"] = e;
                                      this.setState({
                                        filteredSlotArray: slotArray,
                                      });
                                      // this.handleChange(e, "Time")
                                    }}
                                    options={timeSlotListArray.filter(
                                      (i) =>
                                        x.StringMapKey.split(",")
                                          .map((t) => {
                                            return t;
                                          })
                                          .includes(i.label) &&
                                        i.label !==
                                        "Full Day(09:00 - 17:00)"
                                    )}
                                  />
                                )}
                                <div className="invalid-feedback d-block">
                                  {x.TimehelperText}
                                </div>
                              </div>
                            );
                          })
                        ) : this.state.isDateSelected ? (
                          <Alert variant={"info"}>
                            <p>
                              Unfortunately slots are full . Please
                              try using different dates.
                                      </p>
                          </Alert>
                        ) : <Alert variant={"info"}>
                          <p>
                            Please Select a Date
                                  </p>
                        </Alert>}
                      </div>
                    </div>
                    {/* // : null} */}
                  </div>
                ) : (
                  <div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="common-input with-icon">
                          <OverlayTrigger
                            trigger="click"
                            placement="top"
                            rootClose={true}
                            overlay={
                              <Popover>
                                <div className="date-selection">
                                  <div className="date-select-box">
                                    {dayTypeList.map((x) => {
                                      return (
                                        <button
                                          className={
                                            this.state.day ===
                                              x.StringMapName
                                              ? "active"
                                              : null
                                          }
                                          onClick={() =>
                                            this.handleChange(
                                              x.StringMapName,
                                              "day"
                                            )
                                          }
                                        >
                                          {x.StringMapName}
                                        </button>
                                      );
                                    })}
                                  </div>
                                </div>
                                <DateRangePicker
                                  onChange={(item) =>
                                    this.handleChange(
                                      [item.selection],
                                      "date"
                                    )
                                  }
                                  showSelectionPreview={true}
                                  showMonthAndYearPickers={false}
                                  showDateDisplay={false}
                                  moveRangeOnFirstSelection={false}
                                  months={2}
                                  disabledDates={
                                    this.state.disabledDates
                                  }
                                  ranges={
                                    this.state.selectedDateRange
                                  }
                                  minDate={new Date()}
                                  direction="horizontal"
                                  maxDate={CommonConfig.addDaysToDates(
                                    new Date(),
                                    30
                                  )}
                                />
                              </Popover>
                            }
                          >
                            <input
                              ref={(datepick) =>
                                (this.datepick = datepick)
                              }
                              className={
                                this.state.DateErr
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              type="text"
                              placeholder="Date"
                              readOnly
                              onClick={() =>
                                this.handleClickOpenCalendar()
                              }
                              value={
                                this.state.selectedDateRange.length >
                                  0 &&
                                  Date.parse(
                                    this.state.selectedDateRange[0]
                                      .startDate
                                  ) !=
                                  Date.parse(
                                    this.state.selectedDateRange[0]
                                      .endDate
                                  )
                                  ? moment(
                                    this.state.selectedDateRange[0]
                                      .startDate
                                  ).format("DD/MM/YYYY") +
                                  "-" +
                                  moment(
                                    this.state.selectedDateRange[0]
                                      .endDate
                                  ).format("DD/MM/YYYY")
                                  : ""
                              }
                            />
                          </OverlayTrigger>
                          <i
                            className="far fa-calendar-alt"
                            aria-hidden="true"
                          ></i>
                          <Form.Control.Feedback type="invalid">
                            {this.state.DatehelperText}
                          </Form.Control.Feedback>
                        </div>
                      </div>
                    </div>
                    <Slider
                      {...settingsSliderMultiDay}
                      className="slider-multiday"
                    >
                      {/* {filteredSlotArray.length > 7 && */}
                      {filteredSlotArrayDivided && filteredSlotArrayDivided.length > 0 ? (
                        filteredSlotArrayDivided.map((item, i) => {
                          // return <SwiperSlide>
                          //   {
                          return (
                            <div>
                              {
                                item &&
                                item.map((x, idx) => {
                                  return (
                                    <div className="row">
                                      <div className="col-lg-3 col-md-3">
                                        <div className="common-input mb-10">
                                          <label>
                                            {moment(x.Dates).format(
                                              "DD-MM-YYYY"
                                            )}
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-lg-9 col-md-9">
                                        <div className="common-input mb-10">
                                          {!x.IsFullDaySelected &&
                                            !x.IsSlotSelected ? (
                                            <Select
                                              className={
                                                x.TimeErr
                                                  ? "is-invalid"
                                                  : ""
                                              }
                                              closeMenuOnSelect={
                                                true
                                              }
                                              components={
                                                animatedComponents
                                              }
                                              placeholder={
                                                "Time Slot"
                                              }
                                              value={x.Time}
                                              isMulti
                                              onChange={(e) => {
                                                var slotArray =
                                                  filteredSlotArrayDivided[
                                                  i
                                                  ];
                                                if (
                                                  e.length > 0 &&
                                                  e[0].label ===
                                                  "Full Day(09:00 - 17:00)"
                                                ) {
                                                  slotArray[idx][
                                                    "IsFullDaySelected"
                                                  ] = 1;
                                                  slotArray[idx][
                                                    "IsSlotSelected"
                                                  ] = 0;
                                                  // this.setState({ IsFullDaySelected: true, IsSlotSelected: false })
                                                } else if (
                                                  e.length > 0 &&
                                                  e[0].label !==
                                                  "Full Day(09:00 - 17:00)"
                                                ) {
                                                  slotArray[idx][
                                                    "IsFullDaySelected"
                                                  ] = 0;
                                                  slotArray[idx][
                                                    "IsSlotSelected"
                                                  ] = 1;
                                                  // this.setState({ IsFullDaySelected: false, IsSlotSelected: true })
                                                } else {
                                                  slotArray[idx][
                                                    "IsFullDaySelected"
                                                  ] = 0;
                                                  slotArray[idx][
                                                    "IsSlotSelected"
                                                  ] = 0;
                                                  // this.setState({ IsFullDaySelected: false, IsSlotSelected: false })
                                                }
                                                slotArray[idx][
                                                  "Time"
                                                ] = e;
                                                let tempfilteredSlotArrayDivided = filteredSlotArrayDivided;
                                                tempfilteredSlotArrayDivided[
                                                  i
                                                ] = slotArray;
                                                this.setState({
                                                  filteredSlotArrayDivided: tempfilteredSlotArrayDivided,
                                                });
                                                // this.handleChange(e, "Time")
                                              }}
                                              options={timeSlotListArray.filter(
                                                (id) =>
                                                  x.StringMapKey.split(
                                                    ","
                                                  )
                                                    .map((t) => {
                                                      return t;
                                                    })
                                                    .includes(
                                                      id.label
                                                    )
                                              )}
                                            />
                                          ) : x.IsFullDaySelected &&
                                            !x.IsSlotSelected ? (
                                            <Select
                                              className={
                                                x.TimeErr
                                                  ? "is-invalid"
                                                  : ""
                                              }
                                              closeMenuOnSelect={
                                                true
                                              }
                                              components={
                                                animatedComponents
                                              }
                                              placeholder={
                                                "Time Slot"
                                              }
                                              value={x.Time}
                                              isClearable={true}
                                              isMulti={false}
                                              onChange={(e) => {
                                                var slotArray =
                                                  filteredSlotArrayDivided[
                                                  i
                                                  ];
                                                if (e === null) {
                                                  slotArray[idx][
                                                    "IsFullDaySelected"
                                                  ] = 0;
                                                  slotArray[idx][
                                                    "IsSlotSelected"
                                                  ] = 0;
                                                  slotArray[idx][
                                                    "Time"
                                                  ] = e;
                                                  this.setState({
                                                    filteredSlotArray: slotArray,
                                                  });
                                                  // this.setState({ Time: e, IsFullDaySelected: false, IsSlotSelected: false })
                                                } else {
                                                  slotArray[idx][
                                                    "Time"
                                                  ] = e;
                                                  let tempfilteredSlotArrayDivided = filteredSlotArrayDivided;
                                                  tempfilteredSlotArrayDivided[
                                                    i
                                                  ] = slotArray;
                                                  this.setState({
                                                    filteredSlotArrayDivided: tempfilteredSlotArrayDivided,
                                                  });
                                                }
                                              }}
                                              options={timeSlotListArray.filter(
                                                (id) =>
                                                  id.label ==
                                                  "Full Day(09:00 - 17:00)"
                                              )}
                                            />
                                          ) : (
                                            <Select
                                              className={
                                                x.TimeErr
                                                  ? "is-invalid"
                                                  : ""
                                              }
                                              closeMenuOnSelect={
                                                true
                                              }
                                              components={
                                                animatedComponents
                                              }
                                              placeholder={
                                                "Time Slot"
                                              }
                                              value={x.Time}
                                              isMulti
                                              onChange={(e) => {
                                                var slotArray =
                                                  filteredSlotArrayDivided[
                                                  i
                                                  ];
                                                if (
                                                  e.length > 0 &&
                                                  e[0].label ===
                                                  "Full Day(09:00 - 17:00)"
                                                ) {
                                                  slotArray[idx][
                                                    "IsFullDaySelected"
                                                  ] = 1;
                                                  slotArray[idx][
                                                    "IsSlotSelected"
                                                  ] = 0;
                                                  // this.setState({ IsFullDaySelected: true, IsSlotSelected: false })
                                                } else if (
                                                  e.length > 0 &&
                                                  e[0].label !==
                                                  "Full Day(09:00 - 17:00)"
                                                ) {
                                                  slotArray[idx][
                                                    "IsFullDaySelected"
                                                  ] = 0;
                                                  slotArray[idx][
                                                    "IsSlotSelected"
                                                  ] = 1;
                                                  // this.setState({ IsFullDaySelected: false, IsSlotSelected: true })
                                                } else {
                                                  slotArray[idx][
                                                    "IsFullDaySelected"
                                                  ] = 0;
                                                  slotArray[idx][
                                                    "IsSlotSelected"
                                                  ] = 0;
                                                  // this.setState({ IsFullDaySelected: false, IsSlotSelected: false })
                                                }
                                                slotArray[idx][
                                                  "Time"
                                                ] = e;
                                                let tempfilteredSlotArrayDivided = filteredSlotArrayDivided;
                                                tempfilteredSlotArrayDivided[
                                                  i
                                                ] = slotArray;
                                                this.setState({
                                                  filteredSlotArrayDivided: tempfilteredSlotArrayDivided,
                                                });
                                                // this.handleChange(e, "Time")
                                              }}
                                              options={timeSlotListArray.filter(
                                                (id) =>
                                                  x.StringMapKey.split(
                                                    ","
                                                  )
                                                    .map((t) => {
                                                      return t;
                                                    })
                                                    .includes(
                                                      id.label
                                                    ) &&
                                                  id.label !==
                                                  "Full Day(09:00 - 17:00)"
                                              )}
                                            />
                                          )}
                                          <div className="invalid-feedback d-block">
                                            {x.TimehelperText}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                                // }
                                // );
                                // </SwiperSlide>
                              }
                            </div>
                          );
                        })
                      ) :
                        this.state.isDateSelected ?
                          (
                            <Alert variant={"info"}>
                              <p>
                                Unfortunately slots are full . Please try
                                using different dates.
                                    </p>
                            </Alert>
                          ) : (<Alert variant={"info"}>
                            <p>
                              Please select the dates.
                                </p>
                          </Alert>)}
                    </Slider>
                  </div>
                )}
                {this.state.Users !== "Multi" ? (
                  <div className="common-input with-icon">
                    <select
                      className={[
                        this.state.UsersErr
                          ? "form-control is-invalid"
                          : "form-control",
                      ]}
                      value={this.state.Users}
                      onChange={(e) => this.handleChange(e, "Users")}
                    >
                      {noOfUsersList.map((x) => {
                        return (
                          <option value={x.StringMapName}>
                            {x.StringMapName}
                          </option>
                        );
                      })}
                    </select>
                    <i className="fas fa-user" aria-hidden="true"></i>
                    <Form.Control.Feedback type="invalid">
                      {this.state.UsershelperText}
                    </Form.Control.Feedback>
                  </div>
                ) : (
                  <div className="common-input with-icon">
                    <select
                      className="form-control"
                      value={this.state.Users}
                      onChange={(e) => this.handleChange(e, "Users")}
                    >
                      {noOfUsersList.map((x) => {
                        return (
                          <option value={x.StringMapName}>
                            {x.StringMapName}
                          </option>
                        );
                      })}
                    </select>
                    <input
                      type="number"
                      placeholder="Enter users count"
                      className={[
                        this.state.UsersErr
                          ? "form-control is-invalid"
                          : "form-control",
                      ]}
                      value={this.state.UsersCount}
                      onChange={(e) => this.handleChange(e, "UsersCount")}
                    />
                    <i className="fas fa-user" aria-hidden="true"></i>
                    <Form.Control.Feedback type="invalid">
                      {this.state.UsershelperText}
                    </Form.Control.Feedback>
                  </div>
                )}
                <div className="sb-form-submit">
                  <button onClick={(e) => this.bookpod(e)}>Check Out</button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Modal
          show={this.state.showContactHost}
          onHide={() =>
            this.setState({
              showContactHost: false,
              Name: "",
              NameErr: false,
              NamehelperText: "",
              EmailAddress: "",
              EmailAddressErr: false,
              EmailAddresshelperText: "",
              Message: "",
              MessageErr: false,
              MessagehelperText: "",
            })
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Contact the Host</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="contact-text">
              The Work Pod’s customer services are happy to help if you have any
              website feedback, testimonials or complaints about the
              booking/payment process. Please allow us some time to get back to
              you.
            </p>
            <div className="form-group">
              <label>Name *</label>
              <input
                type="text"
                className={
                  this.state.NameErr
                    ? "form-control is-invalid"
                    : "form-control"
                }
                placeholder="E.g. Clara Smith"
                value={this.state.Name}
                onChange={(e) => this.handleChange(e, "Name")}
              />
              <div className="invalid-feedback">
                {this.state.NamehelperText}
              </div>
            </div>
            <div className="form-group">
              <label>Email address *</label>
              <input
                type="text"
                className={
                  this.state.EmailAddressErr
                    ? "form-control is-invalid"
                    : "form-control"
                }
                placeholder="E.g. abac@gmail.com"
                value={this.state.EmailAddress}
                onChange={(e) => this.handleChange(e, "EmailAddress")}
              />
              <div className="invalid-feedback">
                {this.state.EmailAddresshelperText}
              </div>
            </div>
            <div className="form-group">
              <label>Message *</label>
              <textarea
                placeholder="Your message"
                className={
                  this.state.MessageErr
                    ? "form-control is-invalid"
                    : "form-control"
                }
                value={this.state.Message}
                onChange={(e) => this.handleChange(e, "Message")}
              ></textarea>
              <div className="invalid-feedback">
                {this.state.MessagehelperText}
              </div>
            </div>
            <div className="pr-text">
              <p>
                The information you provided is only used to respond to your
                request. The data controller is The WorkPod who acts in
                compliance with applicable laws. You can read our entire{" "}
                <a href="/admin/privacypolicy" target="_blank">
                  Privacy Policy
                </a>
                .
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() =>
                this.setState({
                  showContactHost: false,
                  Name: "",
                  NameErr: false,
                  NamehelperText: "",
                  EmailAddress: "",
                  EmailAddressErr: false,
                  EmailAddresshelperText: "",
                  Message: "",
                  MessageErr: false,
                  MessagehelperText: "",
                })
              }
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={(e) => this.submitContactToHost(e)}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        {isOpen && (
          <Lightbox
            mainSrc={
              apiBase +
              this.state.propertyDetail.PropertyMedia.filter(item => item.MediaType.split("/")[0] === 'image')[photoIndex]["MediaURL"]
            }
            nextSrc={
              this.state.propertyDetail.PropertyMedia.filter(item => item.MediaType.split("/")[0] === 'image')[
              (photoIndex + 1) %
              this.state.propertyDetail.PropertyMedia.filter(item => item.MediaType.split("/")[0] === 'image').length
              ]
            }
            prevSrc={
              this.state.propertyDetail.PropertyMedia.filter(item => item.MediaType.split("/")[0] === 'image')[
              (photoIndex +
                this.state.propertyDetail.PropertyMedia.filter(item => item.MediaType.split("/")[0] === 'image').length -
                1) %
              this.state.propertyDetail.PropertyMedia.filter(item => item.MediaType.split("/")[0] === 'image').length
              ]
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex +
                    this.state.propertyDetail.PropertyMedia.filter(item => item.MediaType.split("/")[0] === 'image').length -
                    1) %
                  this.state.propertyDetail.PropertyMedia.filter(item => item.MediaType.split("/")[0] === 'image').length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex + 1) %
                  this.state.propertyDetail.PropertyMedia.filter(item => item.MediaType.split("/")[0] === 'image').length,
              })
            }
          />
        )}
        <Modal
          show={showFavModal}
          onHide={() =>
            this.setState({
              showFavModal: false,
              items: [],
            })
          }
          centered
        >
          <div className="custom-modal-login-inner">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Name this wishlist</h4>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={() =>
                    this.setState({
                      showFavModal: false,
                      item: [],
                    })
                  }
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="modal-login-form register">
                  <Form
                    noValidate
                    onSubmit={(e) =>
                      this.favUnFavPod(e, "Favorite", this.state.items)
                    }
                  >
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="name"
                        className="mb-15"
                      >
                        <Form.Control
                          required
                          type="text"
                          className={
                            this.state.WishListNameErr ? "is-invalid" : null
                          }
                          placeholder="Name"
                          value={this.state.WishListName}
                          autoFocus
                          onChange={(e) => this.handleChange(e, "WishListName")}
                        />
                        <Form.Control.Feedback type="invalid">
                          {this.state.WishListNamehelperText}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Button
                      type="submit"
                      className="homey_login_button btn btn-primary btn-full-width"
                    >
                      Create
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {this.state.showLoginModal ? (
          <Header
            showLoginModal={this.state.showLoginModal}
            hideLoginModal={() => this.setState({ showLoginModal: false })}
            {...rest}
          />
        ) : (
          false
        )}
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAww6TxPSzQbg74ljJahxvuLHmRfICHbf4",
})(Detailpage);