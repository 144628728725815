import React, { Component } from 'react'
import image1 from "../../assets/img/workspace.svg";
import image2 from "../../assets/img/find-pod.svg";
import image3 from "../../assets/img/confirm.svg";
import image4 from "../../assets/img/enjoy-pod.svg";

class FreeCancellation extends Component {
    constructor(props){
        super(props);
        this.state = {
            
        }
    }

    render(){
        return (
            <div className="how-we-work-outer">
                <div className="container-fluid">
                    <div className="how-work-box">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="hw-content">
                                    <h2>Your dedicated workspace for a few hours.</h2>
                                    <p>
                                        Work in peace, with all you need at hand, and no distractions to bother you – achieve productivity at its peak.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="hw-img">
                                    <img src={image1} alt=""></img>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 className="hw-head">How does it work?</h2>
                    <div className="how-work-box">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="hw-img">
                                    <img src={image2} alt=""></img>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="hw-content">
                                    <h2>Find the pod for you.</h2>
                                    <p>
                                        A pod which caters to your accessibility needs? A meeting pod for 5 people? A pod with a PC and photocopier? Don’t worry - we got you covered. Find the perfect pod for you.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="how-work-box">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="hw-content">
                                    <h2>Book</h2>
                                    <p>
                                        Familiarise yourself with the host, the cancellation options and reviews.Whenever you’re ready, click the button!
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="hw-img">
                                    <img src={image3} alt=""></img>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="how-work-box">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="hw-img">
                                    <img src={image4} alt=""></img>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="hw-content">
                                    <h2>Enjoy the respite, in your pod.</h2>
                                    <p>
                                        With the amenities you chose at your fingertips, bunker down for some solid work, so that later, you can relax in the knowledge that your jobs are done!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FreeCancellation
