import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import HttpApi from 'i18next-http-backend';
import { apiBase } from './config';
let fileArr = ["Header","Footer","Homepage","PODRegistration","ViewEditPod","AboutUs","Profile","ManageBooking","Checkout"]

i18n
  .use(HttpApi)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    // backend: options,
    // lng: "en",

    // keySeparator: false, // we do not use keys in form messages.welcome

    // interpolation: {
    //   escapeValue: false // react already safes from xss
    // }

    lng: "GB",
    fallbackLng: 'GB',
    returnNull: false,
    backend: {
      loadPath: `${apiBase}/locales/{{lng}}/{{ns}}.json`,
    //   customHeaders: {
    //     'X-Api-Key': appTextApiKey,
    //     // ...
    //   },
    },
    debug: false,
    ns: fileArr,
    defaultNS: 'Homepage'
  });

export default i18n;