import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import api from "../../../src/utils/apiClient";
import { ProgressBar } from "primereact/progressbar";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { Button, ButtonGroup } from "react-bootstrap";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { CommonConfig } from "utils/constant";
import { InputSwitch } from 'primereact/inputswitch';
import moment from "moment";
import cogoToast from "cogo-toast";
import parse from "html-react-parser";

class PodListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dt: null,
      podList: [],
      personType: "",
      toggleSwitch: [],
      Status: "Under Review"
    };
  }

  handleChange = (value, rowData) => {
    let toggleSwitchNew = this.state.toggleSwitch;
    toggleSwitchNew[rowData.index] = value


    this.setState({
      toggleSwitch: toggleSwitchNew
    })

    // console.log("rowdata...", rowData)
    let data = {
      PropertyID: rowData.PropertyID,
      PersonType: CommonConfig.loginData().PersonType,
      Status: value ? "Active" : "Disabled",
      Reason: '',
      Type: value ? ["Admin Activated Pod", "Host Activated Pod"] : ["Admin Disabled Pod", "Host Disabled Pod"]
    }

    try {
      api
        .post("property/updatePropertyStatus", data)
        .then((res) => {
          if (res.success) {
            let details = {
              PropertyID: rowData.PropertyID,
              PersonID: rowData.CreatedBy,
              Status: value ? "activated" : "disabled",
              PropertyName: rowData.PropertyName
            }
            this.SetNotificationAdmin(details);
            this.SetNotificationHost(details);
            this.handleStatus(this.state.Status);
            cogoToast.success(`${rowData.PropertyName} has been moved to ${data.Status.toLowerCase()} state`)
          } else {
          }
        })
        .catch((err) => {
        });
    } catch (err) { }
  }

  componentDidMount() {
    this.getMyPodList();
    this.setState({
      personType: CommonConfig.loginData().PersonType,
    });
  }

  getMyPodList = () => {
    let data = {
      PersonID: CommonConfig.loginData().PersonID,
      PersonType: CommonConfig.loginData().PersonType,
      Status: this.state.Status
    };
    try {
      api
        .post("property/listpropertybystatus", data)
        .then((res) => {
          if (res.success) {
            this.setState({ podList: res.data });
            // console.log("PodList", res.data)
          } else {
          }
        })
        .catch((err) => {
        });
    } catch (err) { }
  };

  filterDate = (value, filter) => {
    if (
      filter === undefined ||
      filter === null ||
      (typeof filter === "string" && filter.trim() === "")
    ) {
      return true;
    }

    if (value === undefined || value === null) {
      return false;
    }

    return value === this.formatDate(filter);
  };

  formatDate = (date) => {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = "0" + month;
    }

    if (day < 10) {
      day = "0" + day;
    }

    return date.getFullYear() + "-" + month + "-" + day;
  };

  onRepresentativesChange = (e) => {
    this.state.dt.current.filter(e.value, "representative.name", "in");
    this.setState({ selectedRepresentative: e.value });
  };

  onDateChange = (e) => {
    this.state.dt.current.filter(e.value, "date", "custom");
    this.setState({ selectedDate: e.value });
  };

  onStatusChange = (e) => {
    this.state.dt.current.filter(e.value, "status", "equals");
    this.setState({ selectedStatus: e.value });
  };

  PropertyNameTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Name</span>
        {rowData.PropertyName}
      </React.Fragment>
    );
  };

  TodoTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Name</span>
        {/* {rowData.PropertyName} */}
      </React.Fragment>
    );
  };

  UsersTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Users</span>
        {rowData.GuestCount}
      </React.Fragment>
    );
  };

  statusTemplate = (rowData) => {
    const { personType } = this.state;
    let classname = rowData.PropertyStatus === "Under Review" ? "text-primary" : rowData.PropertyStatus === "Active" ? "text-success" : rowData.PropertyStatus === "Declined" ? "text-secondary" : "text-danger"
    return personType !== "Admin" ? (
      <React.Fragment>
        <span className="p-column-title">Status</span>
        <span style={{ fontWeight: "bold" }} className={classname} >{rowData.PropertyStatus}</span>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div className="tbl-actions">
          <a href="javascript:;" className="view-btn">
            <i className="pi pi-eye" onClick={() => this.handleView(rowData.PropertyID)}></i>
          </a>
          {this.state.Status === 'Active' || this.state.Status === 'Disabled' ?
            <InputSwitch checked={this.state.toggleSwitch[rowData.Index]} onChange={(e) => this.handleChange(e.value, rowData)} />
            : null
          }
          {rowData.PaymentStatus == 'Pending' ?
            <div>
              <button className="view-btn" onClick={() => this.handlePay(rowData.TransactionId)}>Pay</button>
            </div>
            : null
          }
        </div>
      </React.Fragment>
    );
  };

  paymentStatusTemplate = (rowData) => {
    const { personType } = this.state;
    return (
      <React.Fragment>
        <span className="p-column-title">Payment Status</span>
        {rowData.PaymentStatus}
      </React.Fragment>
    )
  };

  amountPaidTemplate = (rowData) => {
    const { personType } = this.state;
    return personType !== "Admin" ? (
      <React.Fragment>
        <span className="p-column-title">Amount Received</span>
        {rowData.AmountReceived}
      </React.Fragment>
    ) : (
      <React.Fragment>
        <span className="p-column-title">Amount Transferred</span>
        {rowData.AmountReceived}
      </React.Fragment>
    );
  };

  amountPendingTemplate = (rowData) => {
    
    const { personType } = this.state;
    return (
      <React.Fragment>
        <span className="p-column-title">Amount Pending</span>
        {rowData.AmountPending}
      </React.Fragment>
    )
  };

  reasonTemplate = (rowData) => {
    const { personType } = this.state;
    return (
      <React.Fragment>
        <span className="p-column-title">Reason</span>
        {rowData.Reason}
      </React.Fragment>
    );
  };

  WashRoomsTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">WashRooms</span>
        {rowData.BathroomCount}
      </React.Fragment>
    );
  };

  LocationTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">PropertyAddress</span>
        {rowData.FullAddress}
      </React.Fragment>
    );
  };

  FlagTemplate = (rowData) => {
    // // console.log("..........",rowData.Flag)
    return (
      <React.Fragment>
        <span className="p-column-title">Flag</span>
        <span className="flag-img">{parse(rowData.Flag)}</span>
      </React.Fragment>
    );
  };

  LastModifiedTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">CreatedOn</span>
        {moment(rowData.CreatedOn).format("DD-MM-YYYY HH:mm A")}
      </React.Fragment>
    );
  };

  countryBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Country</span>
        <img
          alt="flag"
          src="showcase/demo/images/flag_placeholder.png"
          onError={(e) =>
          (e.target.src =
            "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
          }
          className={`flag flag-${rowData.country.code}`}
          width={30}
        />
        <span className="image-text">{rowData.country.name}</span>
      </React.Fragment>
    );
  };

  representativeBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Representative</span>
        <img
          alt={rowData.representative.name}
          src={`showcase/demo/images/avatar/${rowData.representative.image}`}
          onError={(e) =>
          (e.target.src =
            "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
          }
          width={32}
          style={{ verticalAlign: "middle" }}
        />
        <span className="image-text">{rowData.representative.name}</span>
      </React.Fragment>
    );
  };

  dateBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Date</span>
        <span>{rowData.date}</span>
      </React.Fragment>
    );
  };

  statusBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Status</span>
        <span className={`customer-badge status-${rowData.status}`}>
          {rowData.status}
        </span>
      </React.Fragment>
    );
  };

  activityBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Activity</span>
        <ProgressBar value={rowData.activity} showValue={false} />
      </React.Fragment>
    );
  };

  representativesItemTemplate = (option) => {
    return (
      <div className="p-multiselect-representative-option">
        <img
          alt={option.name}
          src={`showcase/demo/images/avatar/${option.image}`}
          onError={(e) =>
          (e.target.src =
            "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
          }
          width={32}
          style={{ verticalAlign: "middle" }}
        />
        <span className="image-text">{option.name}</span>
      </div>
    );
  };

  statusItemTemplate = (option) => {
    return <span className={`customer-badge status-${option}`}>{option}</span>;
  };

  header = () => {
    return (
      <div className="table-header">
        List of Pods
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder="Global Search"
          />
        </span>
      </div>
    );
  };

  handleView = (propertyID) => {
    this.props.history.push({
      pathname: "/admin/viewProperty",
      state: { PropertyID: propertyID, UserID: 1 },
    });
  };

  handlePay = (id) => {
    
    let data = {
      PersonID: CommonConfig.loginData().PersonID,
      TransactionId: id
    }
    try {
      api
        .post("request/podPaymentTransferByAdmin", data)
        .then((res) => {
          if (res.success) {
            cogoToast.success('Payment Transferred successfully.')
            this.getMyPodList();
          } else {
          }
        })
        .catch((err) => {
        });
    } catch (err) { }
  }

  handleStatus = (status) => {
    let data = {
      PersonID: CommonConfig.loginData().PersonID,
      PersonType: CommonConfig.loginData().PersonType,
      Status: status
    }
    this.setState({
      Status: status,
    })
    try {
      api
        .post("property/listpropertybystatus", data)
        .then((res) => {
          if (res.success) {
            var i = 0;
            res.data.map(OBJ => {
              OBJ.Index = i;
              i++;
            });
            this.setState({ podList: res.data });
            this.setState({
              toggleSwitch: status == "Active" ? res.data.map(x => true) : res.data.map(x => false)
            })
          } else {
          }
        })
        .catch((err) => {
        });
    } catch (err) { }
  }

  SetNotificationAdmin = (details) => {
    try {
      let Reason = !CommonConfig.isEmpty(details.Reason) ? "due to  " + details.Reason : "";
      let NotificationData = {
        NotificationCategory: details.Status === "activated" ? "Pod Activation": details.Status === "deactivated" ? "Pod Deactivation" : "POD Declined",
        NotificationType: 'Notification',
        EntityType: 'Property',
        EntityId: details.PropertyID,
        UserFrom: 'System',
        UserTo: 'Admin',
        GroupTo: null,
        NotificationText: `A property named ${details.PropertyName} has been ${details.Status} ${Reason} by you`,
        IsImportant: 1
      }
      // console.log("NotificationData", NotificationData)
      
      api.post("user/AddNotification", NotificationData).then(res => {
        if (res.success) {
        }
        else {
        }
      }).catch(err => {
        // console.log(err)
      })
    }
    catch (err) {
    }
  }

  SetNotificationHost = (details) => {
    try {
      let Reason = !CommonConfig.isEmpty(details.Reason) ? "due to " + details.Reason : "";
      let NotificationData = {
        NotificationCategory: details.Status === "activated" ? "Pod Activation": details.Status === "deactivated" ? "Pod Deactivation" : "POD Declined",
        NotificationType: 'Notification',
        EntityType: 'Property',
        EntityId: details.PropertyID,
        UserFrom: 'System',
        UserTo: details.PersonID,
        GroupTo: null,
        NotificationText: `Your pod named ${details.PropertyName} has been ${details.Status} ${Reason} by Admin`,
        IsImportant: 1
      }
      api.post("user/AddNotification", NotificationData).then(res => {
        if (res.success) {
        }
        else {
        }
      }).catch(err => {
        // console.log(err)
      })
    }
    catch (err) {
    }
  }

  render() {
    const { dt, podList, header, globalFilter, personType } = this.state;
    return (
      <div className="datatable-filter-demo">
        <div className="table-filter">
          <ButtonGroup aria-label="Basic example">
            <Button variant="outline-primary" className={this.state.Status === "Under Review" ? "active" : null} onClick={(e) => { this.handleStatus("Under Review") }}>Under Review</Button>
            <Button variant="outline-success" className={this.state.Status === "Active" ? "active" : null} onClick={() => { this.handleStatus("Active") }}>Active</Button>
            <Button variant="outline-secondary" className={this.state.Status === "Declined" ? "active" : null} onClick={() => { this.handleStatus("Declined") }}>Declined</Button>
            <Button variant="outline-danger" className={this.state.Status === "Disabled" ? "active" : null} onClick={() => { this.handleStatus("Disabled") }}>Disabled</Button>
          </ButtonGroup>
        </div>
        <div className="podlisting-table">
          <DataTable
            ref={dt}
            value={podList}
            paginator
            rows={10}
            header={header}
            className="p-datatable-customers"
            globalFilter={globalFilter}
            emptyMessage="No listed pods found."
          >
            <Column
              field="PropertyName"
              header="Listing"
              body={this.PropertyTemplateTemplate}
              filter
              filterPlaceholder="Search by Pod name"
            />
            {/* <Column field="todo" filterField="todo" header="Status" body={this.TodoTemplate} /> */}
            <Column
              field="status"
              filterField="status"
              header={personType === 'Admin' ? "Action" : "Status"}
              body={this.statusTemplate}
            />
            <Column
              // field="AmountReceived"
              // filterField="status"
              header={personType === 'Admin' ? "Amount Transferred" : "Amount Received"}
              body={this.amountPaidTemplate}
            />
            <Column
              // field="AmountPending"
              // filterField="status"
              header={"Amount Pending"}
              body={this.amountPendingTemplate}
            />
            <Column
              // field="PaymentStatus"
              // filterField="status"
              header={"Payment Status"}
              body={this.paymentStatusTemplate}
            />
            {/* <Column field="representative.name" header="Instant Book" body={this.InstantBookTemplate} sortable /> */}
            {this.state.Status === "Disabled" || this.state.Status === "Declined" ?
              <Column
                field="reason"
                filterField="reason"
                header="Reason"
                body={this.reasonTemplate}
              />
              :
              null
            }
            <Column
              field="GuestCount"
              header="Users"
              body={this.UsersTemplate}
              sortable={true}

              filterMatchMode="gte"
            />
            <Column
              field="BathroomCount"
              header="WashRooms"
              body={this.WashRoomsTemplate}
              sortable
              filterMatchMode="gte"
            />
            <Column
              field="PropertyAddress"
              header="Location"
              body={this.LocationTemplate}
              sortable
              filterMatchMode="contains"
            />
            {personType === 'Admin'?
            <Column
              field="Flag"
              header="Country"
              sortable
              body={this.FlagTemplate}
              filterMatchMode="contains"
            />:null}
            <Column
              field="CreatedOn"
              header="Last Modified"
              body={this.LastModifiedTemplate}
              sortable
            />
          </DataTable>
        </div>
      </div>
    );
  }
}

export default PodListing;
