import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import parse from "html-react-parser";
import api from "../../../src/utils/apiClient";
import { CommonConfig } from "utils/constant";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import cogoToast from "cogo-toast";
import { apiBase } from "../../../src/utils/config";
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PersonID: CommonConfig.loginData().PersonID,
      PersonType: CommonConfig.loginData().PersonType,
      upcomingBooking: [],
      historyBooking: [],
      inprogressBooking: [],
      amenitiesList: [],
      bookingDate: [],
      displayDialog: false,
      FeedbackOptions: [],
      checked: [],
      steps: 0,
      isBug: false,
      disabled: true,
      isValid: false,
      Reason: [],
      ReasonErr: false,
      ReasonHelperText: "",
      NotificationList: [],
      PrepaidAccountList: [],
      BookingList: []
    };
  }

  componentDidMount() {
    this.getFeedBackOptions();
    this.getDashboardDetails();
  }

  getDashboardDetails = () => {
    let data = {
      PersonID: this.state.PersonID,
    };
    try {
      api
        .post("/DashBoardDetails", data)
        .then((res) => {
          if (res.success) {
            this.setState({
              BookingList: res.data.BookingList,
              NotificationList: res.data.NotificationList,
              PrepaidAccountList: res.data.PrepaidAccountList,
            });
          }
        })
        .catch((err) => { });
    } catch (err) { }
  };

  getBookingsForHost = () => {
    
    let data = {
      PersonId: this.state.PersonID,
      PersonType: this.state.PersonType,
      PaymentMode: 'ALL',
      ListingStatus: 'Upcoming',
    };
    try {
      api
        .post("DashBoardDetails/getRequestByHostId", data)
        .then((res) => {
          if (res.success) {
            this.setState({
              BookingList: res.data,
            });
          } else {
          }
        })
        .catch((err) => { });
    } catch (err) { }
  };

  getFeedBackOptions = () => {
    let data = {
      StringMapType: "FEEDBACKOPTIONS",
    };
    try {
      api
        .post("utility/getStringMap", data)
        .then((res) => {
          if (res.success) {
            res.data.map((OBJ) => {
              OBJ.IsChecked = false;
            });
            this.setState({ FeedbackOptions: res.data });
          } else {
          }
        })
        .catch((err) => { });
    } catch (err) { }
  };

  setSteps = (value) => {
    this.setState({
      steps: value,
    });
  };

  handleChange = (e, idx, type) => {
    if (type === "feedback") {
      let feedbackOption = this.state.FeedbackOptions;
      feedbackOption.map((x) => {
        x.IsChecked = false;
      });
      feedbackOption[idx]["IsChecked"] = e.target.checked;
      this.setState({
        FeedbackOptions: feedbackOption,
        disabled: false,
      });
    } else if (type === "textarea") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          feedback: e.target.value,
          feedbackErr: true,
          feedbackhelperText: "Please enter the description.",
          isValid: false,
        });
      } else if (e.target.value.length < 20) {
        this.setState({
          feedback: e.target.value,
          feedbackErr: true,
          feedbackhelperText: "Minimum 20 characters required",
          isValid: false,
        });
      } else {
        this.setState({
          feedback: e.target.value,
          feedbackErr: false,
          feedbackhelperText: "",
          isValid: true,
        });
      }
    } else if (type === "Reason") {
      let ReasonNew = this.state.upcomingBooking;
      ReasonNew[idx][type] = e.target.value;
      if (CommonConfig.isEmpty(e.target.value)) {
        ReasonNew[idx]["ReasonErr"] = true;
        ReasonNew[idx]["ReasonHelperText"] = "Please enter the Reason";
      } else {
        ReasonNew[idx]["ReasonErr"] = false;
        ReasonNew[idx]["ReasonHelperText"] = "";
      }
      this.setState({
        Reason: ReasonNew,
      });
    } else if (type === "bug") {
      this.setState({
        isBug: !this.state.isBug,
      });
    }
  };

  handleFeedback = (e) => {
    this.setState({
      displayDialog: true,
    });
    e.preventDefault();
  };

  hideModal = () => {
    let feedbackOption = this.state.FeedbackOptions;
    feedbackOption.map((x) => {
      x.IsChecked = false;
    });
    this.setState({
      displayDialog: false,
      feedback: "",
      isBug: false,
      steps: 0,
      disabled: true,
      feedbackhelperText: "",
      feedbackErr: false,
    });
  };

  modal = () => {
    if (this.state.steps === 0)
      return (
        <>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Give us Feedback
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <h4>Centered Modal</h4> */}
            <div className="feedback-box">
              <p>
                Please let us know what your feedback is about. We review all
                feedback but are unable to respond individually
              </p>
              {this.state.FeedbackOptions.map((x, idx) => {
                return (
                  <div className="custom-control custom-radio">
                    <label for={x.StringMapName}>
                      <RadioButton
                        inputId="rb1"
                        name="Feedback"
                        value={x.StringMapName}
                        onChange={(e) => this.handleChange(e, idx, "feedback")}
                        checked={x.IsChecked}
                      />
                      {x.StringMapName}
                    </label>
                  </div>
                );
              })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="feedback-footer">
              <p>
                Need to connect with our support team? Visit the Help Centre or
                contact us.
              </p>
              <Button
                onClick={() => this.setSteps(1)}
                disabled={this.state.disabled}
              >
                Continue
              </Button>
            </div>
          </Modal.Footer>
        </>
      );
    else if (this.state.steps === 1) {
      return (
        <>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Tell us about it
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <strong>
                Share your experience with us. What’s working well? What
                could’ve gone better?
              </strong>
            </p>
            <div className="p-col-12">
              <InputTextarea
                className={[
                  this.state.feedbackErr
                    ? "form-control mb-10 is-invalid"
                    : "form-control mb-10",
                ]}
                rows={5}
                cols={20}
                maxLength={200}
                value={this.state.feedback}
                onChange={(e) => this.handleChange(e, 0, "textarea")}
              />
              <div className="invalid-feedback">
                {this.state.feedbackhelperText}
              </div>
            </div>
            <div className="p-col-12">
              <Checkbox
                onChange={(e) => this.handleChange(e, 0, "bug")}
                checked={this.state.isBug}
              ></Checkbox>
              <label htmlFor="cb1" className="p-checkbox-label">
                I’m reporting a bug
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.setSteps(0)}>Back</Button>
            <Button onClick={() => this.submit()}>Submit</Button>
          </Modal.Footer>
        </>
      );
    }
  };

  submit = () => {
    if (this.state.isValid) {
      const Name = this.state.FeedbackOptions.filter(
        (x) => x.IsChecked === true
      );
      const data = {
        Topic: Name[0].StringMapName,
        IsBug: this.state.isBug ? 1 : 0,
        Description: this.state.feedback,
        PersonID: this.state.PersonID,
      };
      try {
        api
          .post("DashBoardDetails/addFeedback", data)
          .then((res) => {
            if (res.success) {
              cogoToast.success("Feedback submitted");
              this.hideModal();
              // this.props.history.push('/admin/podlisting')
            } else {
            }
          })

          .catch((err) => { });
      } catch (err) { }
    } else {
      let message = CommonConfig.isEmpty(this.state.feedbackhelperText)
        ? "Please fill the details"
        : this.state.feedbackhelperText;
      cogoToast.error(message);
    }
  };

  validate(status, reason) {
    let IsValid = true;
    if (CommonConfig.isEmpty(reason) && status === "Cancel") {
      IsValid = false;
    }
    return IsValid;
  }

  detailspage = (id, bookingID) => {
    this.props.history.push({
      pathname: "/admin/detailpage",
      state: {
        PropertyID: id,
        BookingID: bookingID
      }
    })
  }

  updateNotification(notes, type) {
    let data = {
      NotificationId: notes.DashboardNotificationId,
      Type: type,
      IsRead: notes.IsRead.data[0] == 1 ? false : true,
      CurrentUserId: CommonConfig.loginData().PersonID
    }
    api.post('user/updateNotification', data).then(res => {
      if (res.success) {
        this.getDashboardDetails();
      } else {
      }
    }).catch(err => {
    })
  }

  handleClick = (type) => {
    if (type === "prepaid") {
      this.props.history.push("/admin/prepaidaccount")
    }
    if (type === "bookings") {
      this.props.history.push("/admin/bookings")
    }
  }

  render() {
    const { NotificationList, PrepaidAccountList, BookingList } = this.state;
    const { ...rest } = this.props;
    return (
      <div>
        <div className="host-dashboard-outer">
          <div className="container">
            <div className="host-page-heading host-page-heading d-flex align-itemd-center justify-content-between">
              <h2>Dashboard</h2>
              <h6 className="mt-2">
                <a href="javascript:;" onClick={(e) => this.handleFeedback(e)}>
                  Give us Feedback
                </a>
              </h6>
            </div>
            <div className="row">
              <div className="col-lg-8 col-md-8">
                <div className="dashboard-box">
                  <div className="dashboard-box-header">
                    <h5>My Bookings</h5>
                    <a className="btn btn-primary" onClick={() => this.handleClick("bookings")}>View All</a>
                  </div>
                  <div className="dashboard-box-content">
                    {BookingList.length ?
                      BookingList.map(x => {
                        return (


                          <div className="db-booking">
                            <div className="dbb-row">
                              <div className="dbb-img">
                                <Swiper
                                  navigation={false}
                                  pagination={{ clickable: true, dynamicBullets: true }}
                                >
                                  {x.PropertyMedia
                                    ? x.PropertyMedia.filter(item => item.MediaType.split("/")[0] === 'image').map(
                                      (content) => {
                                        return (
                                          <SwiperSlide>
                                            <img
                                              // height="180.5px" width="180.5px"
                                              src={apiBase + content.MediaURL}
                                              type={content.MediaType}
                                            />
                                          </SwiperSlide>
                                        )
                                      }) : null}
                                </Swiper>
                                {/* <img src="https://picsum.photos/100/65" alt=""></img> */}
                              </div>
                              <div className="dbb-content">
                                <h5>{x.PropertyName}</h5>
                                <div className="dbb-status">
                                  <p><i class="fas fa-university" aria-hidden="true"></i> : {x.PaymentMode}</p>
                                  <p>{x.CurrencySymbol ? parse(x.CurrencySymbol) : ''} {x.AmountPaid}</p>
                                </div>
                              </div>
                              <div className="dbb-btn">
                                <button className="btn btn-primary" onClick={() => this.detailspage(x.PropertyId, x.BookingId)}>View Detail</button>
                              </div>
                            </div>

                            {/* <div className="dbb-row">
                            <div className="dbb-img">
                              <img src="https://picsum.photos/100/65" alt=""></img>
                            </div>
                            <div className="dbb-content">
                              <h5>Maximum value, maximum amenities</h5>
                              <div className="dbb-status">
                                <p><i class="fas fa-university" aria-hidden="true"></i> : RazorPay</p>
                                <p>₹ 1100</p>
                              </div>
                            </div>
                            <div className="dbb-btn">
                              <button className="btn btn-primary">View Detail</button>
                            </div>
                          </div>
                          <div className="dbb-row">
                            <div className="dbb-img">
                              <img src="https://picsum.photos/100/65" alt=""></img>
                            </div>
                            <div className="dbb-content">
                              <h5>Maximum value, maximum amenities</h5>
                              <div className="dbb-status">
                                <p><i class="fas fa-university" aria-hidden="true"></i> : RazorPay</p>
                                <p>₹ 1100</p>
                              </div>
                            </div>
                            <div className="dbb-btn">
                              <button className="btn btn-primary">View Detail</button>
                            </div>
                          </div>
                          <div className="dbb-row">
                            <div className="dbb-img">
                              <img src="https://picsum.photos/100/65" alt=""></img>
                            </div>
                            <div className="dbb-content">
                              <h5>Maximum value, maximum amenities</h5>
                              <div className="dbb-status">
                                <p><i class="fas fa-university" aria-hidden="true"></i> : RazorPay</p>
                                <p>₹ 1100</p>
                              </div>
                            </div>
                            <div className="dbb-btn">
                              <button className="btn btn-primary">View Detail</button>
                            </div>
                          </div>
                          <div className="dbb-row">
                            <div className="dbb-img">
                              <img src="https://picsum.photos/100/65" alt=""></img>
                            </div>
                            <div className="dbb-content">
                              <h5>Maximum value, maximum amenities</h5>
                              <div className="dbb-status">
                                <p><i class="fas fa-university" aria-hidden="true"></i> : RazorPay</p>
                                <p>₹ 1100</p>
                              </div>
                            </div>
                            <div className="dbb-btn">
                              <button className="btn btn-primary">View Detail</button>
                            </div>
                          </div>
                         */}
                          </div>

                        )
                      })
                      :
                      <div className="create-prepaid-acc">
                        <p>No recent bookings</p>
                      </div>
                    }
                  </div>

                </div>
                <div className="dashboard-box">
                  <div className="dashboard-box-header">
                    <h5>Manage Prepaid Account</h5>
                    <a className="btn btn-primary" onClick={() => this.handleClick("prepaid")}>
                      {PrepaidAccountList.length > 0 ? "View Details" :
                        "Setup Prepaid Account"}
                    </a>
                  </div>
                  <div className="dashboard-box-content">
                    <div className="row">
                      {PrepaidAccountList.length > 0 ? PrepaidAccountList.map(item => {
                        return <div className="col-lg-6 col-md-6">
                          <div class="pre-dashboard-info">
                            <span>{item.PrepaidAccountName}</span>
                            <div class="pre-account-balence">
                              <h3>Balance: {parse(item.CurrencySymbol)} {item.Balance}</h3>
                              <a href="javascript:;" class="btn btn-primary" onClick={() => this.handleClick("prepaid")}>
                                Top Up
                            </a>
                            </div>
                            <a href="javascript:;">
                              <p>{item.PersonID.split(",").length} Members are configured</p>
                            </a>
                            <div class="payment-history" onClick={() => this.handleClick("prepaid")}>
                              <a href="javascript:;" class="btn history-btn">
                                Payment History
                            </a>
                            </div>
                          </div>
                        </div>

                      }) :
                        <div className="create-prepaid-acc">
                          <p>It appears that you do not have any Prepaid Account setup</p>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="dashboard-box">
                  <div className="dashboard-box-header">
                    <h5>Notifications</h5>
                    {/* <a className="btn btn-primary" onClick = {() => this.handleClick("notification")}>View All</a> */}
                  </div>
                  <div className="dashboard-box-content">
                    <div class="wnd-content">
                      <ul>
                        {NotificationList && NotificationList.length > 0 ?
                          NotificationList.map((not) => {
                            return (
                              <li
                                className={
                                  not.IsRead.data[0] === 0 ? "wnd-unread" : ""
                                }
                              >
                                <div className="wnd-inner">
                                  {not.NotificationCategory ===
                                    "POD Deactivation" ||
                                    not.NotificationCategory ===
                                    "POD Declined" ? (
                                    <span className="icn-warning">
                                      <i className="fa fa-close"></i>
                                    </span>
                                  ) : (
                                    <span>
                                      <i className="fas fa-check"></i>
                                    </span>
                                  )}
                                  <div class="wnd-text">
                                    <p>{not.NotificationCategory}</p>
                                    <small>{not.NotificationText}</small>
                                  </div>
                                </div>
                                <div class="wnd-inner-options">
                                  {not.IsRead.data[0] === 0 ? (
                                    <a
                                      href="javascript:;"
                                      onClick={() =>
                                        this.updateNotification(not, "Update")
                                      }
                                      className="wnd-unread-icon"
                                      title="Mark as Unread"
                                    >
                                      <i className="fa fa-envelope"></i>
                                    </a>
                                  ) : (
                                    <a
                                      href="javascript:;"
                                      onClick={() =>
                                        this.updateNotification(not, "Update")
                                      }
                                      className="wnd-read-icon"
                                      title="Mark as Read"
                                    >
                                      <i className="fa fa-envelope-open"></i>
                                    </a>
                                  )}
                                  <a
                                    href="javascript:;"
                                    onClick={() =>
                                      this.updateNotification(not, "Delete")
                                    }
                                    className="wnd-delete-icon"
                                    title="Delete"
                                  >
                                    <i
                                      class="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </div>
                              </li>
                            );
                          }) :
                          <div className="create-prepaid-acc">
                            <p>No records available</p>
                          </div>
                        }
                      </ul>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <Modal
            show={this.state.displayDialog}
            onHide={() => this.hideModal()}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            {this.modal()}
          </Modal>
        </div>
      </div>
    );
  }
}

export default Dashboard;
