import React, { Component } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Button, Modal, Badge } from "react-bootstrap";
import parse from "html-react-parser";
import api from "../../../src/utils/apiClient";
import { CommonConfig } from "utils/constant";
import listingimage from "../../assets/img/listing-image.jpg";
import moment from "moment";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { apiBase } from "../../../src/utils/config";
import cogoToast from "cogo-toast";
import { Form } from "react-bootstrap";
import { withNamespaces } from 'react-i18next';
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import Loader from '../../../src/utils/general';
// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

class Booking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PersonID: CommonConfig.loginData().PersonID,
      PersonType: CommonConfig.loginData().PersonType,
      upcomingBooking: [],
      historyBooking: [],
      inprogressBooking: [],
      amenitiesList: [],
      bookingDate: [],
      displayDialog: false,
      FeedbackOptions: [],
      checked: [],
      steps: 0,
      isBug: false,
      disabled: true,
      isValid: false,
      Reason: [],
      ReasonErr: false,
      ReasonHelperText: "",
      innerkey: "ALL",
      key: "UpComing",
      // PaymentMode: 'ALL',
      // ListingStatus: 'UpComing',
      cancelBookings: [],
      loading: false
    };
  }

  componentDidMount() {
    this.getBookingsForHost();
    this.getFeedBackOptions();
  }

  getFeedBackOptions = () => {
    let data = {
      StringMapType: "FEEDBACKOPTIONS",
    };
    try {
      api
        .post("utility/getStringMap", data)
        .then((res) => {
          if (res.success) {
            res.data.map((OBJ) => {
              OBJ.IsChecked = false;
            });
            this.setState({ FeedbackOptions: res.data });
          } else {
          }
        })
        .catch((err) => { });
    } catch (err) { }
  };

  getMyBookings = () => {
    let data = {
      PersonId: this.state.PersonID,
    };
    try {
      api
        .post("/DashBoardDetails", data)
        .then((res) => {
          if (res.success) {
            res.data[2].map((OBJ) => {
              OBJ.IsCancel = false;
              OBJ.Reason = "";
              OBJ.ReasonErr = false;
              OBJ.ReasonHelperText = "";
            });
            this.setState({
              upcomingBooking: res.data[2],
              historyBooking: res.data[0],
              inprogressBooking: res.data[1],
              amenitiesList: res.data[3],
            });

            // console.log("sttaattee1", this.state);
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  };

  formatDates = (inDate, outDate) => {
    if (
      moment(inDate).format("DD/MM/YYYY") ===
      moment(outDate).format("DD/MM/YYYY")
    ) {
      return moment(inDate).format("ddd DD MMMM,YYYY");
    } else {
      return (
        moment(inDate).format("ddd DD MMMM") +
        " to " +
        moment(outDate).format("ddd DD MMMM,YYYY")
      );
    }
  };

  getBookingsForHost = (PaymentMode, ListingStatus) => {
    
    let data = {
      PersonId: this.state.PersonID,
      PersonType: this.state.PersonType,
      PaymentMode: CommonConfig.isEmpty(PaymentMode)
        ? this.state.innerkey
        : PaymentMode,
      ListingStatus: CommonConfig.isEmpty(ListingStatus)
        ? this.state.key
        : ListingStatus,
    };
    try {
      api
        .post("DashBoardDetails/getRequestByHostId", data)
        .then((res) => {
          if (res.success) {
            res.data.map((OBJ) => {
              OBJ.IsCancel = false;
              OBJ.Reason = "";
              OBJ.ReasonErr = false;
              OBJ.ReasonHelperText = "";
            });
            this.setState({
              historyBooking: res.data,
            });
          } else {
          }
        })
        .catch((err) => { });
    } catch (err) { }
  };

  getCancelledBookingsList = () => {
    let data = {
    };
    try {
      api
        .post("property/getCancelledBooking", data)
        .then((res) => {
          if (res.success) {
            this.setState({
              cancelBookings: res.data,
            });
          } else {
          }
        })
        .catch((err) => { });
    } catch (err) { }
  };

  setSteps = (value) => {
    this.setState({
      steps: value,
    });
  };

  handleChange = (e, idx, type) => {
    if (type === "feedback") {
      let feedbackOption = this.state.FeedbackOptions;
      feedbackOption.map((x) => {
        x.IsChecked = false;
      });
      feedbackOption[idx]["IsChecked"] = e.target.checked;
      this.setState({
        FeedbackOptions: feedbackOption,
        disabled: false,
      });
    } else if (type === "textarea") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          feedback: e.target.value,
          feedbackErr: true,
          feedbackhelperText: "Please enter the description.",
        });
      } else {
        this.setState({
          feedback: e.target.value,
          feedbackErr: false,
          feedbackhelperText: "",
          isValid: true,
        });
      }
    } else if (type === "Reason") {
      let ReasonNew = this.state.historyBooking;
      ReasonNew[idx][type] = e.target.value;
      if (CommonConfig.isEmpty(e.target.value)) {
        ReasonNew[idx]["ReasonErr"] = true;
        ReasonNew[idx]["ReasonHelperText"] = "Please enter the Reason";
      } else {
        ReasonNew[idx]["ReasonErr"] = false;
        ReasonNew[idx]["ReasonHelperText"] = "";
      }
      this.setState({
        upcomingBooking: ReasonNew,
      });
    } else if (type === "InProgressReason") {
      let ReasonNew = this.state.inprogressBooking;
      ReasonNew[idx]["Reason"] = e.target.value;
      if (CommonConfig.isEmpty(e.target.value)) {
        ReasonNew[idx]["ReasonErr"] = true;
        ReasonNew[idx]["ReasonHelperText"] = "Please enter the Reason";
      } else {
        ReasonNew[idx]["ReasonErr"] = false;
        ReasonNew[idx]["ReasonHelperText"] = "";
      }
      this.setState({
        inprogressBooking: ReasonNew,
      });
    } else if (type === "bug") {
      this.setState({
        isBug: !this.state.isBug,
      });
    }
  };

  handleFeedback = (e) => {
    this.setState({
      displayDialog: true,
    });
    e.preventDefault();
  };

  hideModal = () => {
    let feedbackOption = this.state.FeedbackOptions;
    feedbackOption.map((x) => {
      x.IsChecked = false;
    });
    this.setState({
      displayDialog: false,
      steps: 0,
      isBug: false,
      feedback: "",
      FeedbackOptions: feedbackOption,
    });
  };

  modal = () => {
    if (this.state.steps === 0)
      return (
        <>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Give us Feedback
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <h4>Centered Modal</h4> */}
            <p>
              <strong>
                Please let us know what your feedback is about. We review all
                feedback but are unable to respond individually
              </strong>
            </p>

            {this.state.FeedbackOptions.map((x, idx) => {
              return (
                <div className="custom-control custom-radio">
                  <RadioButton
                    inputId="rb1"
                    name="Feedback"
                    value={x.StringMapName}
                    onChange={(e) => this.handleChange(e, idx, "feedback")}
                    checked={x.IsChecked}
                  />
                  <label for={x.StringMapName}>{x.StringMapName}</label>
                </div>
              );
            })}
          </Modal.Body>
          <Modal.Footer>
            Need to connect with our support team? Visit the Help Centre or
            contact us.
            <Button
              onClick={() => this.setSteps(1)}
              disabled={this.state.disabled}
            >
              Continue
            </Button>
          </Modal.Footer>
        </>
      );
    else if (this.state.steps === 1) {
      return (
        <>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Tell us about it
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <strong>
                Share your experience with us. What’s working well? What
                could’ve gone better?
              </strong>
            </p>
            <div className="p-col-12">
              <InputTextarea
                className={[
                  this.state.feedbackErr
                    ? "form-control mb-10 is-invalid"
                    : "form-control mb-10",
                ]}
                rows={5}
                cols={20}
                maxLength={200}
                value={this.state.feedback}
                onChange={(e) => this.handleChange(e, 0, "textarea")}
              />
            </div>
            <div className="p-col-12">
              <Checkbox
                onChange={(e) => this.handleChange(e, 0, "bug")}
                checked={this.state.isBug}
              ></Checkbox>
              <label htmlFor="cb1" className="p-checkbox-label">
                I’m reporting a bug
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.setSteps(0)}>Back</Button>
            <Button onClick={() => this.submit()}>Submit</Button>
          </Modal.Footer>
        </>
      );
    }
  };

  submit = () => {
    if (this.state.isValid) {
      const Name = this.state.FeedbackOptions.filter(
        (x) => x.IsChecked === true
      );
      const data = {
        Topic: Name[0].StringMapName,
        IsBug: this.state.isBug ? 1 : 0,
        Description: this.state.feedback,
        PersonID: this.state.PersonID,
      };
      try {
        api
          .post("DashBoardDetails/addFeedback", data)
          .then((res) => {
            if (res.success) {
              cogoToast.success("Feedback submitted");
              this.hideModal();
              // this.props.history.push('/admin/podlisting')
            } else {
            }
          })
          .catch((err) => { });
      } catch (err) { }
    } else {
      cogoToast.error("Something went wrong!!");
    }
  };

  validate(status, reason) {
    // console.log("status", status);
    let IsValid = true;
    if (CommonConfig.isEmpty(reason) && status === "Cancelled") {
      IsValid = false;
    }
    return IsValid;
  }

  reviewProperty = (UUID) => {
    this.props.history.push(`/admin/review/${UUID}`)
  }

  handleBookingStatus = (status, bookingID, reason) => {
    if (this.validate(status, reason)) {
      let data = {
        BookingID: bookingID,
        Status: status,
        Reason: reason,
      };

      try {
        api
          .post("DashBoardDetails/updateBookingStatus", data)
          .then((res) => {
            if (res.success) {
              cogoToast.success("Pod status changed to " + status);
              // if (this.state.PersonType === "User") this.getMyBookings();
              this.getBookingsForHost();
              // this.props.history.push('/admin/bookings')
            } else {
            }
          })
          .catch((err) => { });
      } catch (err) { }
    } else {
      cogoToast.error("Please Enter Reason");
    }
  };

  changeCancelStatus = (idx, bookingID) => {
    // let upcomingBooking = this.state.historyBooking;
    // upcomingBooking[idx]["IsCancel"] = true;
    // this.setState({
    //   historyBooking: upcomingBooking,
    // });
    
    this.props.history.push({
      pathname: "/admin/bookingcancellation",
      state: { BookingID: bookingID },
    });

  };

  changeCancelStatusInProgress = (idx) => {
    let inprogressBooking = this.state.inprogressBooking;
    inprogressBooking[idx]["IsCancel"] = true;
    this.setState({
      inprogressBooking: inprogressBooking,
    });
  };

  detailspage = (id, bookingID) => {
    this.props.history.push({
      pathname: "/admin/detailpage",
      state: {
        PropertyID: id,
        BookingID: bookingID
      }
    })
  }

  UpdateRefundStatus = (BookingCancellationID, Status) => {
    this.setState({ loading: true })
    var data = {
      BookingCancellationID: BookingCancellationID,
      RefundStatus: Status,
      PersonID: CommonConfig.loginData().PersonID
    }
    try {
      api
        .post("property/UpdateBookingCancellationRequest", data)
        .then((res) => {
          if (res.success) {
            cogoToast.success(`Booking cancellation request has been ${Status}`);
            this.setState({ loading: false })
            this.getCancelledBookingsList();
            // this.hideModal();
            // this.props.history.push('/admin/podlisting')
          } else {
          }
        })
        .catch((err) => { });
    } catch (err) { }
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        {this.state.PersonType === "Host" ||
          this.state.PersonType === "Admin" ? (
          <div className="host-dashboard-outer">
            <div className="container">
              <div className="host-page-heading">
                <h2>Bookings</h2>
              </div>
              <div className="row">
                <div className="col-lg-10 col-md-10">
                  <Tabs
                    id="controlled-tab-example"
                    className="requests-tab-nav"
                    defaultActiveKey="UpComing"
                    activeKey={this.state.key}
                    onSelect={(k) => {
                      this.setState({ key: k });
                      if (k === "Cancelled") {
                        this.getCancelledBookingsList();
                      } else {
                        this.getBookingsForHost(this.state.innerkey, k);
                      }
                    }}
                  >
                    <Tab eventKey="UpComing" title="Upcoming">
                      <Tabs
                        id="controlled-tab-example-inner"
                        className="requests-tab-nav"
                        defaultActiveKey="ALL"
                        activeKey={this.state.innerkey}
                        onSelect={(k) => {
                          this.setState({ innerkey: k });
                          this.getBookingsForHost(k);
                        }}
                      >
                        <Tab eventKey="Prepaid" title="Prepaid"></Tab>
                        <Tab eventKey={t("ManageBooking:PaymentMethod")} title={t("ManageBooking:PaymentMethod")}></Tab>
                        <Tab eventKey="ALL" title="ALL"></Tab>
                      </Tabs>
                      <div className="requests">
                        {this.state.historyBooking.length > 0 ? this.state.historyBooking.map((item, idx) => {
                          return (
                            <div className="rq-box">
                              <div className="rq-box-head">
                                <h4>{item.PropertyName}</h4>

                                <p>
                                  <span>{item.AdultCount} guests</span>
                                  <span>Price: {item.CurrencySymbol ? parse(item.CurrencySymbol) : ''}{item.AmountPaid}</span>
                                  {/* <span>{item.StringMapName}</span> */}
                                  <span>
                                    {/* {this.state.historyBookingDate[idx]} */}
                                  </span>
                                </p>
                              </div>
                              <div className="rq-box-message">
                                {item.PropertyDescription}
                              </div>
                              <span>
                                Booked by{" "}
                                <h6 style={{ display: "inline" }}>
                                  {item.BookedByPersonName}
                                </h6>
                              </span>
                              {/* <div className="rq-box-footer"> */}
                              <div className="mt-3 user-pay-status">
                                      <p>
                                        <i class="fas fa-university"></i> :{" "}
                                        {item.PaymentMode}
                                      </p>
                                      <p>{item.CurrencySymbol ? parse(item.CurrencySymbol) : ''}
                                        &nbsp;{item.AmountPaid}</p>
                                      <p><Badge pill variant="success" style={{ fontSize: "12px" }}>{item.PaymentStatus}</Badge>{' '}</p>
                                      <div className="manage-booking-buttons ml-auto">
                                  {item.BookingStatus !== "Cancelled" ?
                                    <a
                                      href="javascript:;"
                                      className="btn btn-outline-danger d-flex align-items-center mt-0"
                                      onClick={() =>
                                        this.changeCancelStatus(idx, item.BookingId)
                                      }
                                    >
                                      Cancel
                                      &nbsp;&nbsp;<i className="fa fa-times ml-auto"></i>
                                    </a> : null}
                                </div>
                              
                                    {/* </div> */}
                                {/* <div className="payment-tags-outer">
                                  <span className="payment-tags">
                                    Payment Mode&nbsp;: <b>&nbsp;{item.PaymentMode}</b>
                                  </span>
                                  <span className="payment-tags">
                                    Payment Status&nbsp;: <b>&nbsp;{item.PaymentStatus}</b>
                                  </span>
                                  <span className="payment-tags">
                                    Booking Status&nbsp;: <b>&nbsp;{item.BookingStatus}</b>
                                  </span>
                                </div> */}
                                </div>
                            </div>
                          );
                        }) :
                          <span>No records available</span>
                        }
                      </div>

                      {/* <div className="requests">
                        {this.state.upcomingBooking.map((item, idx) => {
                          return (
                            <div className="rq-box">
                              <div className="rq-box-head">
                                <h4>{item.PropertyName}</h4>
                                <p>
                                  <span>{item.GuestCount} guests</span>
                                  <span>{item.StringMapName}</span>
                                  <span>
                                    {this.state.upcomingBookingDate[idx]}
                                  </span>
                                </p>
                              </div>
                              <div className="rq-box-message">
                                {item.PropertyDescription}
                              </div>
                              <span>
                                Booked by{" "}
                                <h6 style={{ display: "inline" }}>
                                  {item.CreatedBy}
                                </h6>
                              </span>
                              <span className="float-right">
                                Status: {item.BookingStatus}
                              </span>
                              <div className="rq-box-footer">
                                {!item.IsCancel ? (
                                  <>
                                    <a
                                      style={
                                        item.BookingStatus === "Cancelled"
                                          ? { pointerEvents: "none" }
                                          : null
                                      }
                                      href="javascript:;"
                                      className="btn btn-outline-primary"
                                      onClick={() =>
                                        this.changeCancelStatus(idx)
                                      }
                                    >
                                      Cancel
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    <Form>
                                      <Form.Row>
                                        <Form.Control
                                          value={item.Reason}
                                          onChange={(e) =>
                                            this.handleChange(e, idx, "Reason")
                                          }
                                          placeholder="Reason to cancel this booking"
                                          as="textarea"
                                          rows={5}
                                          className={
                                            item.ReasonErr
                                              ? "form-control mb-10 is-invalid"
                                              : "form-control mb-10"
                                          }
                                        />
                                        <span className="text-danger">
                                          {item.ReasonErr
                                            ? item.ReasonHelperText
                                            : null}
                                        </span>
                                      </Form.Row>
                                    </Form>

                                    <a
                                      href="javascript:;"
                                      className="btn btn-outline-primary"
                                      onClick={() =>
                                        this.handleBookingStatus(
                                          "Cancelled",
                                          item.BookingID,
                                          item.Reason
                                        )
                                      }
                                    >
                                      Cancel
                                    </a>
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div> */}
                    </Tab>
                    <Tab eventKey="History" title="History">
                      <Tabs
                        id="controlled-tab-example-inner"
                        className="requests-tab-nav"
                        defaultActiveKey="ALL"
                        activeKey={this.state.innerkey}
                        onSelect={(k) => {
                          this.setState({ innerkey: k });
                          this.getBookingsForHost(k);
                        }}
                      >
                        <Tab eventKey="Prepaid" title="Prepaid"></Tab>
                        <Tab eventKey={t("ManageBooking:PaymentMethod")} title={t("ManageBooking:PaymentMethod")}></Tab>
                        <Tab eventKey="ALL" title="ALL"></Tab>
                      </Tabs>
                      <div className="requests">
                        {this.state.historyBooking.length > 0 ? this.state.historyBooking.map((item, idx) => {
                          return (
                            <div className="rq-box">
                              <div className="rq-box-head">
                                <h4>{item.PropertyName}</h4>

                                <p>
                                  <span>{item.AdultCount} guests</span>
                                  <span>Price: {item.CurrencySymbol ? parse(item.CurrencySymbol) : ''}{item.AmountPaid}</span>
                                  {/* <span>{item.StringMapName}</span> */}
                                  <span>
                                    {/* {this.state.historyBookingDate[idx]} */}
                                  </span>
                                </p>
                              </div>
                              <div className="rq-box-message">
                                {item.PropertyDescription}
                              </div>
                              <span>
                                Booked by{" "}
                                <h6 style={{ display: "inline" }}>
                                  {item.BookedByPersonName}
                                </h6>
                              </span>
                              {/* <div className="rq-box-footer">
                                <div className="payment-tags-outer">
                                  <span className="payment-tags">
                                    Payment Mode&nbsp;: <b>&nbsp;{item.PaymentMode}</b>
                                  </span>
                                  <span className="payment-tags">
                                    Payment Status&nbsp;: <b>&nbsp;{item.PaymentStatus}</b>
                                  </span>
                                  <span className="payment-tags">
                                    Booking Status&nbsp;: <b>&nbsp;{item.BookingStatus}</b>
                                  </span>
                                </div>
                              </div> */}
                              <div className="mt-3 user-pay-status">
                                      <p>
                                        <i class="fas fa-university"></i> :{" "}
                                        {item.PaymentMode}
                                      </p>
                                      <p>{item.CurrencySymbol ? parse(item.CurrencySymbol) : ''}
                                        &nbsp;{item.AmountPaid}</p>
                                      <p><Badge pill variant="success" style={{ fontSize: "12px" }}>{item.PaymentStatus}</Badge>{' '}</p>
                                      </div>
                            </div>
                          );
                        }) :
                          <span>No records available</span>
                        }
                      </div>

                      {/* <div className="requests">
                        {this.state.upcomingBooking.map((item, idx) => {
                          return (
                            <div className="rq-box">
                              <div className="rq-box-head">
                                <h4>{item.PropertyName}</h4>
                                <p>
                                  <span>{item.GuestCount} guests</span>
                                  <span>{item.StringMapName}</span>
                                  <span>
                                    {this.state.upcomingBookingDate[idx]}
                                  </span>
                                </p>
                              </div>
                              <div className="rq-box-message">
                                {item.PropertyDescription}
                              </div>
                              <span>
                                Booked by{" "}
                                <h6 style={{ display: "inline" }}>
                                  {item.CreatedBy}
                                </h6>
                              </span>
                              <span className="float-right">
                                Status: {item.BookingStatus}
                              </span>
                              <div className="rq-box-footer">
                                {!item.IsCancel ? (
                                  <>
                                    <a
                                      style={
                                        item.BookingStatus === "Cancelled"
                                          ? { pointerEvents: "none" }
                                          : null
                                      }
                                      href="javascript:;"
                                      className="btn btn-outline-primary"
                                      onClick={() =>
                                        this.changeCancelStatus(idx)
                                      }
                                    >
                                      Cancel
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    <Form>
                                      <Form.Row>
                                        <Form.Control
                                          value={item.Reason}
                                          onChange={(e) =>
                                            this.handleChange(e, idx, "Reason")
                                          }
                                          placeholder="Reason to cancel this booking"
                                          as="textarea"
                                          rows={5}
                                          className={
                                            item.ReasonErr
                                              ? "form-control mb-10 is-invalid"
                                              : "form-control mb-10"
                                          }
                                        />
                                        <span className="text-danger">
                                          {item.ReasonErr
                                            ? item.ReasonHelperText
                                            : null}
                                        </span>
                                      </Form.Row>
                                    </Form>

                                    <a
                                      href="javascript:;"
                                      className="btn btn-outline-primary"
                                      onClick={() =>
                                        this.handleBookingStatus(
                                          "Cancelled",
                                          item.BookingID,
                                          item.Reason
                                        )
                                      }
                                    >
                                      Cancel
                                    </a>
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div> */}
                    </Tab>
                    <Tab eventKey="In Progress" title="In Progress">
                      <Tabs
                        id="controlled-tab-example-inner"
                        className="requests-tab-nav"
                        defaultActiveKey="ALL"
                        activeKey={this.state.innerkey}
                        onSelect={(k) => {
                          this.setState({ innerkey: k });
                          this.getBookingsForHost(k);
                        }}
                      >
                        <Tab eventKey="Prepaid" title="Prepaid"></Tab>
                        <Tab eventKey={t("ManageBooking:PaymentMethod")} title={t("ManageBooking:PaymentMethod")}></Tab>
                        <Tab eventKey="ALL" title="ALL"></Tab>
                      </Tabs>
                      <div className="requests">
                        {this.state.historyBooking.length > 0 ? this.state.historyBooking.map((item, idx) => {
                          return (
                            <div className="rq-box">
                              <div className="rq-box-head">
                                <h4>{item.PropertyName}</h4>

                                <p>
                                  <span>{item.AdultCount} guests</span>
                                  <span>Price: {item.CurrencySymbol ? parse(item.CurrencySymbol) : ''}{item.AmountPaid}</span>
                                  {/* <span>{item.StringMapName}</span> */}
                                  <span>
                                    {/* {this.state.historyBookingDate[idx]} */}
                                  </span>
                                </p>
                              </div>
                              <div className="rq-box-message">
                                {item.PropertyDescription}
                              </div>
                              <span>
                                Booked by{" "}
                                <h6 style={{ display: "inline" }}>
                                  {item.BookedByPersonName}
                                </h6>
                              </span>
                              {/* <div className="rq-box-footer">
                                <div className="payment-tags-outer">
                                  <span className="payment-tags">
                                    Payment Mode&nbsp;: <b>&nbsp;{item.PaymentMode}</b>
                                  </span>
                                  <span className="payment-tags">
                                    Payment Status&nbsp;: <b>&nbsp;{item.PaymentStatus}</b>
                                  </span>
                                  <span className="payment-tags">
                                    Booking Status&nbsp;: <b>&nbsp;{item.BookingStatus}</b>
                                  </span>
                                </div>
                                <div className="manage-booking-buttons">
                                  {item.BookingStatus !== "Checked Out" ?
                                    (
                                      <>
                                        {item.BookingStatus === "Checked In" ? (
                                          <a
                                            style={
                                              item.BookingStatus === "Cancelled"
                                                ? { pointerEvents: "none" }
                                                : null
                                            }
                                            href="javascript:;"
                                            className="btn btn-outline-primary"
                                            onClick={() =>
                                              this.handleBookingStatus(
                                                "Checked Out",
                                                item.BookingId,
                                                item.Reason
                                              )
                                            }
                                          >
                                            Check Out
                                          </a>
                                        ) : (
                                          <>
                                            {item.BookingStatus !== "Cancelled" ?
                                              <>
                                                <a
                                                  href="javascript:;"
                                                  className="btn btn-outline-primary"
                                                  onClick={() =>
                                                    this.handleBookingStatus(
                                                      "Checked In",
                                                      item.BookingId,
                                                      item.Reason
                                                    )
                                                  }
                                                >
                                                  Check In
                                          </a>
                                                <a
                                                  href="javascript:;"
                                                  className="btn btn-outline-primary"
                                                  onClick={() =>
                                                    this.handleBookingStatus(
                                                      "Not Sure",
                                                      item.BookingId,
                                                      item.Reason
                                                    )
                                                  }
                                                >
                                                  Not Sure
                                          </a>
                                                <a
                                                  href="javascript:;"
                                                  className="btn btn-outline-primary"
                                                  onClick={() =>
                                                    this.changeCancelStatus(idx, item.BookingId)
                                                  }
                                                >
                                                  Cancel
                                         </a>
                                              </>
                                              : null
                                            }

                                          </>
                                        )}
                                      </>
                                    ) : null}
                                </div>
                              </div> */}
                              <div className="mt-3 user-pay-status">
                                      <p>
                                        <i class="fas fa-university"></i> :{" "}
                                        {item.PaymentMode}
                                      </p>
                                      <p>{item.CurrencySymbol ? parse(item.CurrencySymbol) : ''}
                                        &nbsp;{item.AmountPaid}</p>
                                      <p><Badge pill variant="success" style={{ fontSize: "12px" }}>{item.PaymentStatus}</Badge>{' '}</p>
                                      </div>
                                      <div className="manage-booking-buttons">
                                  {item.BookingStatus !== "Checked Out" ?
                                    (
                                      <>
                                        {item.BookingStatus === "Checked In" ? (
                                          <a
                                            style={
                                              item.BookingStatus === "Cancelled"
                                                ? { pointerEvents: "none" }
                                                : null
                                            }
                                            href="javascript:;"
                                            className="btn btn-outline-primary ml-2"
                                            onClick={() =>
                                              this.handleBookingStatus(
                                                "Checked Out",
                                                item.BookingId,
                                                item.Reason
                                              )
                                            }
                                          >
                                            Check Out
                                          </a>
                                        ) : (
                                          <>
                                            {item.BookingStatus !== "Cancelled" ?
                                              <>
                                                <a
                                                  href="javascript:;"
                                                  className="btn btn-outline-primary ml-2"
                                                  onClick={() =>
                                                    this.handleBookingStatus(
                                                      "Checked In",
                                                      item.BookingId,
                                                      item.Reason
                                                    )
                                                  }
                                                >
                                                  Check In
                                          </a>
                                                <a
                                                  href="javascript:;"
                                                  className="btn btn-outline-primary ml-2"
                                                  onClick={() =>
                                                    this.handleBookingStatus(
                                                      "Not Sure",
                                                      item.BookingId,
                                                      item.Reason
                                                    )
                                                  }
                                                >
                                                  Not Sure
                                          </a>
                                                <a
                                                  href="javascript:;"
                                                  className="btn btn-outline-primary ml-2"
                                                  onClick={() =>
                                                    this.changeCancelStatus(idx, item.BookingId)
                                                  }
                                                >
                                                  Cancel
                                         </a>
                                              </>
                                              : null
                                            }

                                          </>
                                        )}
                                      </>
                                    ) : null}
                              
                                      </div>
                            </div>
                          );
                        }) :
                          <span>No records available</span>
                        }
                      </div>

                      {/* <div className="requests">
                        {this.state.upcomingBooking.map((item, idx) => {
                          return (
                            <div className="rq-box">
                              <div className="rq-box-head">
                                <h4>{item.PropertyName}</h4>
                                <p>
                                  <span>{item.GuestCount} guests</span>
                                  <span>{item.StringMapName}</span>
                                  <span>
                                    {this.state.upcomingBookingDate[idx]}
                                  </span>
                                </p>
                              </div>
                              <div className="rq-box-message">
                                {item.PropertyDescription}
                              </div>
                              <span>
                                Booked by{" "}
                                <h6 style={{ display: "inline" }}>
                                  {item.CreatedBy}
                                </h6>
                              </span>
                              <span className="float-right">
                                Status: {item.BookingStatus}
                              </span>
                              <div className="rq-box-footer">
                                {!item.IsCancel ? (
                                  <>
                                    <a
                                      style={
                                        item.BookingStatus === "Cancelled"
                                          ? { pointerEvents: "none" }
                                          : null
                                      }
                                      href="javascript:;"
                                      className="btn btn-outline-primary"
                                      onClick={() =>
                                        this.changeCancelStatus(idx)
                                      }
                                    >
                                      Cancel
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    <Form>
                                      <Form.Row>
                                        <Form.Control
                                          value={item.Reason}
                                          onChange={(e) =>
                                            this.handleChange(e, idx, "Reason")
                                          }
                                          placeholder="Reason to cancel this booking"
                                          as="textarea"
                                          rows={5}
                                          className={
                                            item.ReasonErr
                                              ? "form-control mb-10 is-invalid"
                                              : "form-control mb-10"
                                          }
                                        />
                                        <span className="text-danger">
                                          {item.ReasonErr
                                            ? item.ReasonHelperText
                                            : null}
                                        </span>
                                      </Form.Row>
                                    </Form>

                                    <a
                                      href="javascript:;"
                                      className="btn btn-outline-primary"
                                      onClick={() =>
                                        this.handleBookingStatus(
                                          "Cancelled",
                                          item.BookingID,
                                          item.Reason
                                        )
                                      }
                                    >
                                      Cancel
                                    </a>
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div> */}
                    </Tab>
                    {this.state.PersonType === "Admin" ?
                      <Tab eventKey="Cancelled" title="Cancelled Bookings">
                        {this.state.loading ? (<Loader />) :
                          <div className="requests">
                            {this.state.cancelBookings.length > 0 ? this.state.cancelBookings.map((item, idx) => {
                              return (
                                <div className="rq-box">
                                  <div className="rq-box-head">
                                    <h4>{item.PropertyName}</h4>
                                    <p>
                                      <span>{item.AdultCount} guests</span>
                                      <span>Price: {item.CurrencySymbol ? parse(item.CurrencySymbol) : ''} {item.AmountPaid}</span>
                                      <span>Booking Number : {item.BookingNumber}</span>
                                    </p>
                                  </div>
                                  <div className="rq-box-message">
                                    {item.Reason}
                                  </div>
                                  <span>
                                    Booked by{" "}
                                    <h6 style={{ display: "inline" }}>
                                      {item.BookedByPersonName}
                                    </h6>
                                  </span>
                                  
                                  <div className="rq-box-footer">
                                    <div className="payment-tags-outer text-nowrap">
                                      <span className="payment-tags">
                                        Amount Charged&nbsp;: {item.CurrencySymbol ? parse(item.CurrencySymbol) : ''}<b>&nbsp;{item.PenaltyAmount}</b>
                                      </span>
                                      <span className="payment-tags">
                                        Refund Amount&nbsp;: {item.CurrencySymbol ? parse(item.CurrencySymbol) : ''}<b>&nbsp;{item.RefundAmount}</b>
                                      </span>
                                      <span className="payment-tags">
                                        Refund Status&nbsp;: <b>&nbsp;{item.RefundStatus}</b>
                                      </span>
                                      {item.RefundStatus === "Pending" ?
                                        <div className="manage-cancel-buttons">
                                          <a
                                            href="javascript:;"
                                            className="btn btn-success"
                                            onClick={() => this.UpdateRefundStatus(item.BookingCancellationID, 'Approved')}
                                          >
                                            Approve
                                      <i className="fa fa-check ml-2"></i>
                                          </a>
                                          <a
                                            href="javascript:;"
                                            className="btn btn-outline-danger"
                                            onClick={() => this.UpdateRefundStatus(item.BookingCancellationID, 'Rejected')}
                                          >
                                            Reject
                                      <i className="fa fa-times ml-2"></i>
                                          </a>
                                        </div>
                                        : null
                                      }
                                    </div>
                                  </div>
                                  {/* </div> */}
                                </div>
                              );
                            }) :
                              <p>No records available</p>
                            }
                          </div>
                        }
                      </Tab>
                      : null
                    }
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="host-dashboard-outer">
            <div className="container">
              <div className="host-page-heading">
                <h2>My Bookings</h2>
              </div>
              <div className="row">
                <div className="col-lg-9 col-md-9">
                  <Tabs
                    id="controlled-tab-example"
                    className="requests-tab-nav"
                    defaultActiveKey="UpComing"
                    activeKey={this.state.key}
                    onSelect={(k) => {
                      this.setState({ key: k });
                      this.getBookingsForHost(this.state.innerkey, k);
                    }}
                  >
                    <Tab eventKey="UpComing" title="Upcoming">
                      <Tabs
                        id="controlled-tab-example-inner"
                        className="requests-tab-nav"
                        defaultActiveKey="ALL"
                        activeKey={this.state.innerkey}
                        onSelect={(k) => {
                          this.setState({ innerkey: k });
                          this.getBookingsForHost(k, this.state.key);
                        }}
                      >
                        <Tab
                          eventKey="Prepaid Account"
                          title="Prepaid Account"
                        ></Tab>
                        <Tab eventKey={t("ManageBooking:PaymentMethod")} title={t("ManageBooking:PaymentMethod")}></Tab>
                        <Tab eventKey="ALL" title="ALL"></Tab>
                      </Tabs>
                      {this.state.historyBooking.length > 0 ? (
                        <div className="user-booked-listing">
                          {this.state.historyBooking.map((x, idx) => {
                            return (
                              <div
                                className="listing-main-wrap booking-list"
                                key={idx}
                                id={idx}
                              >
                                <div className="listing-media">
                                  <div className="lm-slider">
                                    <Swiper
                                      navigation={false}
                                      pagination={{
                                        clickable: true,
                                        dynamicBullets: true,
                                        
                                      }}
                                    >
                                      {x.PropertyMedia.filter(item => item.MediaType.split("/")[0] === 'image').map((content) => {
                                        return (
                                          <SwiperSlide>
                                            <img src={apiBase + content.MediaURL} alt="" height="200px"
                                              width="310px" />
                                            {/* <video controls height="200px" width="310px"><source src={apiBase + content.MediaURL} type={content.MediaType} /></video>: null} */}
                                          </SwiperSlide>
                                        );
                                      })}
                                    </Swiper>
                                  </div>
                                  <p className="student-discount-text">
                                    {x.BookingStatus}
                                  </p>
                                </div>
                                <div className="listing-body" >
                                  <a
                                    href="javascript:;"
                                    className="item-favorite"
                                  // className={
                                  //   x.UserPropertyFavoriteID == null
                                  //     ? "item-favorite"
                                  //     : "item-favorite active"
                                  // }
                                  // onClick={(e) =>
                                  //   this.handleFavModal(
                                  //     e,
                                  //     x,
                                  //     x.UserPropertyFavoriteID == null
                                  //       ? "Favorite"
                                  //       : "UnFavorite"
                                  //   )
                                  // }
                                  >
                                    {/* <i className="fas fa-heart"></i> */}
                                  </a>

                                  <div
                                    onClick={() => this.detailspage(x.PropertyId, x.BookingId)}
                                    className="listing-item-title"
                                  // onClick={() => this.detailspage(x.PropertyID)}
                                  >
                                    <a href="javascript:;">{x.PropertyName}</a>
                                    {/* <span className="listing-item-address">
                                {x.PropertyAddress}
                              </span> */}
                                  </div>
                                  <div
                                    className="fpb-amenities listing-item-amenities"
                                  // onClick={() => this.detailspage(x.PropertyID)}
                                  >
                                    <ul>
                                      {x.Features.map((content) => {
                                        return (
                                          <li>
                                            {/* {content.FeatureIconURL ? parse(content.FeatureIconURL) : ''} */}
                                            {content.FeatureName}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                  {/* {x.BookingSlots
                                    ? x.BookingSlots.map((i) => {
                                      return (
                                        <div className="mt-3">
                                          <p className="ml-1">
                                            {moment(i.SlotDate).format(
                                              "ddd, DD MMM YYYY"
                                            )}{" "}
                                              - Booked Slots
                                            </p>
                                          <div className="slots-inner mt-2">
                                            {i.SlotTime.split(",").map(
                                              (content, indx) => {
                                                return (
                                                  <a
                                                    href="javascript:;"
                                                    className="slot-text"
                                                    key={indx}
                                                  >
                                                    {content}
                                                  </a>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      );
                                    })
                                    : null} */}
                                  <div className="mt-3 user-pay-status">
                                      <p>
                                        <i class="fas fa-university"></i> :{" "}
                                        {x.PaymentMode}
                                      </p>
                                      {/* <p>
                                        {x.CurrencySymbol ? parse(x.CurrencySymbol) : ''} :{" "}
                                        {x.PaymentStatus}
                                      </p> */}
                                      <p>{x.CurrencySymbol ? parse(x.CurrencySymbol) : ''}
                                        &nbsp;{x.AmountPaid}</p>
                                      <p><Badge pill variant="success" style={{ fontSize: "12px" }}>{x.PaymentStatus}</Badge>{' '}</p>
                                      {/* <sub></sub> */}
                                      {/* <span> */}

                                      {/* </span> */}
                                      {x.BookingStatus !== "Cancelled" ? (
                                      <div className="manage-cancel-buttons ml-auto">
                                        <a
                                          href="javascript:;"
                                          className="btn btn-outline-danger d-flex align-items-center"
                                          onClick={() =>
                                            this.changeCancelStatus(idx, x.BookingId)
                                          }
                                        >
                                          Cancel
                                  <i className="fa fa-times ml-2"></i>
                                        </a>
                                      </div>
                                    ) : null}
                                    </div>
                                  
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <h4>No record found</h4>
                      )}
                    </Tab>
                    <Tab eventKey="History" title="History">
                      <Tabs
                        id="controlled-tab-example-inner"
                        className="requests-tab-nav"
                        defaultActiveKey="ALL"
                        activeKey={this.state.innerkey}
                        onSelect={(k) => {
                          this.setState({ innerkey: k });
                          this.getBookingsForHost(k, this.state.key);
                        }}
                      >
                        <Tab
                          eventKey="Prepaid Account"
                          title="Prepaid Account"
                        ></Tab>
                        <Tab eventKey={t("ManageBooking:PaymentMethod")} title={t("ManageBooking:PaymentMethod")}></Tab>
                        <Tab eventKey="ALL" title="ALL"></Tab>
                      </Tabs>
                      {this.state.historyBooking.length > 0 ? (
                        <div className="user-booked-listing">
                          {this.state.historyBooking.map((x, idx) => {
                            return (
                              <div
                                className="listing-main-wrap"
                                key={idx}
                                id={idx}
                              >
                                <div className="listing-media">
                                  <div className="lm-slider">
                                    <Swiper
                                      navigation
                                      pagination={{
                                        clickable: true,
                                        dynamicBullets: true,
                                      }}
                                    >
                                      {x.PropertyMedia.filter(item => item.MediaType.split("/")[0] === 'image').map((content) => {
                                        return (
                                          <SwiperSlide>
                                            <img src={apiBase + content.MediaURL} alt="" height="200px"
                                              width="310px" />
                                            {/* <video controls height="200px" width="310px"><source src={apiBase + content.MediaURL} type={content.MediaType} /></video>: null} */}

                                          </SwiperSlide>
                                        );
                                      })}
                                    </Swiper>
                                  </div>
                                  <p className="student-discount-text">
                                    {x.BookingStatus}
                                  </p>
                                </div>
                                <div className="listing-body" onClick={() => this.detailspage(x.PropertyId, x.BookingId)}>
                                  <a
                                    href="javascript:;"
                                    className="item-favorite"
                                  // className={
                                  //   x.UserPropertyFavoriteID == null
                                  //     ? "item-favorite"
                                  //     : "item-favorite active"
                                  // }
                                  // onClick={(e) =>
                                  //   this.handleFavModal(
                                  //     e,
                                  //     x,
                                  //     x.UserPropertyFavoriteID == null
                                  //       ? "Favorite"
                                  //       : "UnFavorite"
                                  //   )
                                  // }
                                  >
                                    {/* <i className="fas fa-heart"></i> */}
                                  </a>

                                  <div
                                    className="listing-item-title"
                                  // onClick={() => this.detailspage(x.PropertyID)}
                                  >
                                    <a href="javascript:;">{x.PropertyName}</a>
                                    {/* <span className="listing-item-address">
                                {x.PropertyAddress}
                              </span> */}
                                  </div>
                                  <div
                                    className="fpb-amenities listing-item-amenities"
                                  // onClick={() => this.detailspage(x.PropertyID)}
                                  >
                                    <ul>
                                      {x.Features.map((content) => {
                                        return (
                                          <li>
                                            {/* {content.FeatureIconURL ? parse(content.FeatureIconURL) : ''} */}
                                            {content.FeatureName}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                  {/* {x.BookingSlots
                                    ? x.BookingSlots.map((i) => {
                                      return (
                                        <div className="mt-3">
                                          <p className="ml-1">
                                            {moment(i.SlotDate).format(
                                              "ddd, DD MMM YYYY"
                                            )}{" "}
                                              - Booked Slots
                                            </p>
                                          <div className="slots-inner mt-2">
                                            {i.SlotTime.split(",").map(
                                              (content, indx) => {
                                                return (
                                                  <a
                                                    href="javascript:;"
                                                    className="slot-text"
                                                    key={indx}
                                                  >
                                                    {content}
                                                  </a>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      );
                                    })
                                    : null} */}
                                  <div></div>
                                  <div
                                    className="listing-item-footer"
                                  // onClick={() => this.detailspage(x.PropertyID)}
                                  >
                                    <div className="user-pay-status">
                                      <p>
                                        <i class="fas fa-university"></i> :{" "}
                                        {x.PaymentMode}
                                      </p>

                                      {/* <p>
                                        {x.CurrencySymbol ? parse(x.CurrencySymbol) : ''} :{" "}
                                        {x.PaymentStatus}
                                      </p>
                                     */}
                                      <p>{x.CurrencySymbol ? parse(x.CurrencySymbol) : ''}
                                        &nbsp;{x.AmountPaid}</p>
                                      <p><Badge pill variant="success" style={{ fontSize: "12px" }}>{x.PaymentStatus}</Badge>{' '}</p>
                                    </div>
                                    {/* <div className="listing-item-rating">
                                                                <div className="fpb-star">
                                                                    <i className="fas fa-star"></i>
                                                                    {" " + x.Rate + " "}
                                                                    <span>{x.ReviewRatingType}</span>
                                                                </div>
                                                            </div> */}

                                    {/* <div className="listing-price">
                                      <span className="fpb-price">
                                        <sup>{x.CurrencySymbol ? parse(x.CurrencySymbol) : ''}</sup>
                                        {x.AmountPaid}
                                        <sub></sub>
                                      </span>
                                      {x.BookingStatus !== "Cancelled" ?
                                        <span>
                                          <a
                                            href="javascript:;"
                                            className="btn btn-outline-primary"
                                            onClick={() =>
                                              this.reviewProperty(x.VerifyUUID)
                                            }
                                          >
                                            review
                                        </a>
                                        </span>
                                        : null}
                                    </div> */}
                                    {x.BookingStatus !== "Cancelled" ?
                                      <div className="manage-cancel-buttons">

                                        <a
                                          href="javascript:;"
                                          className="review-btn btn btn-outline-success d-flex align-items-center"
                                          onClick={() =>
                                            this.reviewProperty(x.VerifyUUID)
                                          }
                                        >
                                          <i className="fa fa-star mr-2"></i>
                                          Review

                                        </a>
                                      </div>
                                      : null}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <h4>No record found</h4>
                      )}
                    </Tab>
                    <Tab eventKey="In Progress" title="In Progress">
                      <Tabs
                        id="controlled-tab-example-inner"
                        className="requests-tab-nav"
                        defaultActiveKey="ALL"
                        activeKey={this.state.innerkey}
                        onSelect={(k) => {
                          this.setState({ innerkey: k });
                          this.getBookingsForHost(k, this.state.key);
                        }}
                      >
                        <Tab
                          eventKey="Prepaid Account"
                          title="Prepaid Account"
                        ></Tab>
                        <Tab eventKey={t("ManageBooking:PaymentMethod")} title={t("ManageBooking:PaymentMethod")}></Tab>
                        <Tab eventKey="ALL" title="ALL"></Tab>
                      </Tabs>
                      {this.state.historyBooking.length > 0 ? (
                        <div className="user-booked-listing">
                          {this.state.historyBooking.map((x, idx) => {
                            return (
                              <div
                                className="listing-main-wrap"
                                key={idx}
                                id={idx}
                              >
                                <div className="listing-media">
                                  <div className="lm-slider">
                                    <Swiper
                                      navigation
                                      pagination={{
                                        clickable: true,
                                        dynamicBullets: true,
                                      }}
                                    >
                                      {x.PropertyMedia.filter(item => item.MediaType.split("/")[0] === 'image').map((content) => {
                                        return (
                                          <SwiperSlide>
                                            <img src={apiBase + content.MediaURL} alt="" height="200px"
                                              width="310px" />
                                            {/* <video controls height="200px" width="310px"><source src={apiBase + content.MediaURL} type={content.MediaType} /></video>: null} */}
                                          </SwiperSlide>
                                        );
                                      })}
                                    </Swiper>
                                  </div>
                                  <p className="student-discount-text">
                                    {x.BookingStatus}
                                  </p>
                                </div>
                                <div className="listing-body" onClick={() => this.detailspage(x.PropertyId, x.BookingId)}>
                                  <a
                                    href="javascript:;"
                                    className="item-favorite"
                                  // className={
                                  //   x.UserPropertyFavoriteID == null
                                  //     ? "item-favorite"
                                  //     : "item-favorite active"
                                  // }
                                  // onClick={(e) =>
                                  //   this.handleFavModal(
                                  //     e,
                                  //     x,
                                  //     x.UserPropertyFavoriteID == null
                                  //       ? "Favorite"
                                  //       : "UnFavorite"
                                  //   )
                                  // }
                                  >
                                    {/* <i className="fas fa-heart"></i> */}
                                  </a>

                                  <div
                                    className="listing-item-title"
                                  // onClick={() => this.detailspage(x.PropertyID)}
                                  >
                                    <a href="javascript:;">{x.PropertyName}</a>
                                    {/* <span className="listing-item-address">
                                {x.PropertyAddress}
                              </span> */}
                                  </div>
                                  <div
                                    className="fpb-amenities listing-item-amenities"
                                  // onClick={() => this.detailspage(x.PropertyID)}
                                  >
                                    <ul>
                                      {x.Features.map((content) => {
                                        return (
                                          <li>
                                            {/* {content.FeatureIconURL ? parse(content.FeatureIconURL) : ''} */}
                                            {content.FeatureName}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                  {/* {x.BookingSlots
                                    ? x.BookingSlots.map((i) => {
                                      return (
                                        <div className="mt-3">
                                          <p className="ml-1">
                                            {moment(i.SlotDate).format(
                                              "ddd, DD MMM YYYY"
                                            )}{" "}
                                              - Booked Slots
                                            </p>
                                          <div className="slots-inner mt-2">
                                            {i.SlotTime.split(",").map(
                                              (content, indx) => {
                                                return (
                                                  <a
                                                    href="javascript:;"
                                                    className="slot-text"
                                                    key={indx}
                                                  >
                                                    {content}
                                                  </a>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      );
                                    })
                                    : null} */}
                                  <div></div>
                                  <div
                                    className="listing-item-footer"
                                  // onClick={() => this.detailspage(x.PropertyID)}
                                  >
                                    <div className="user-pay-status">
                                      <p>
                                        <i class="fas fa-university"></i> :{" "}
                                        {x.PaymentMode}
                                      </p>
                                      <p>
                                        {x.CurrencySymbol ? parse(x.CurrencySymbol) : ''} :{" "}
                                        {x.PaymentStatus}
                                      </p>
                                    </div>
                                    {/* <div className="listing-item-rating">
                                                                <div className="fpb-star">
                                                                    <i className="fas fa-star"></i>
                                                                    {" " + x.Rate + " "}
                                                                    <span>{x.ReviewRatingType}</span>
                                                                </div>
                                                            </div> */}

                                    <div className="listing-price">
                                      <span className="fpb-price">
                                        <sup>{x.CurrencySymbol ? parse(x.CurrencySymbol) : ''}</sup>
                                        {x.AmountPaid}
                                        <sub></sub>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <h4>No record found</h4>
                      )}
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        )}
        <div>
          <Modal
            show={this.state.displayDialog}
            onHide={() => this.hideModal()}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            {this.modal()}
          </Modal>
        </div>
      </div>
    );
  }
}

export default withNamespaces()(Booking);
