import React, { Component } from "react";
import { CommonConfig } from "utils/constant";
import api from "../../../src/utils/apiClient";
import { Form, Col } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { parsePhoneNumber,  getCountryCallingCode, formatPhoneNumber, formatPhoneNumberIntl , isValidPhoneNumber } from "react-phone-number-input";
import cogoToast from 'cogo-toast';
import { withNamespaces } from 'react-i18next';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PersonID: CommonConfig.loginData().PersonID,
      edit: [],
      FirstName:"",
      DateOfBirth: "",
      LastName:'',
      Email:'',
      DOB:'',
      Gender:'',
      PhoneNum:'',
      NewPassword: '',
      ConfirmPassword: '',
      OldPassword: '',
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
    };
  }

  componentDidMount() {
    this.getProfileInfo();
  }

  getProfileInfo = () => {
    const {t} = this.props
    let data = {
      PersonID: this.state.PersonID,
    };
    api
      .post("user/getProfileInfo", data)
      .then((res) => {
        // // // console.log('res',res)
        if (res.success) {
          // console.log("object..", res);
          for (const [key, value] of Object.entries(res.data[0])) {
            this.setState({
            //   [key]: CommonConfig.isEmpty(value) ? "Not Specified" : value,
              [key]: value,
            });
          }
            this.setState({
              NewPassword: '',
              ConfirmPassword: '',
              OldPassword: '',
              FirstNameErr: '',
              LastNameErr: '',
            })
          const Phone = "+" + this.state.CountryCode + this.state.PhoneNum;
          //   const phoneNumber = parsePhoneNumber('+'+this.state.CountryCode+this.state.PhoneNum).country
          if (parsePhoneNumber(Phone)) {
            this.setState({ Country: parsePhoneNumber(Phone).country });
          } else {
            this.setState({
              Country: t('Profile:Country'),
              CountryCode: t('Profile:ContactCode'),
            });
          }
          this.setState({
            PhoneNum:
            !CommonConfig.isEmpty(this.state.PhoneNum)
                ? this.state.CountryCode + this.state.PhoneNum
                : this.state.PhoneNum,
          });
        } else {
          // // // console.log("SomeThing went wrong in get notification list.");
        }
      })
      .catch((err) => {
        // // // console.log("get notification err", err);
      });
  };

  edit = (idx) => {
    // console.log("inside...");
    let editNew = this.state.edit;
    editNew[idx] = true;
    this.setState({
      edit: editNew,
    });
    // console.log("edit", this.state.edit);
  };

  cancel = (idx) => {
    // console.log("inside...");
    let editNew = this.state.edit;
    editNew[idx] = false;
    this.setState({
      edit: editNew,
    });
    // console.log("edit", this.state.edit);
    this.getProfileInfo();
  };

  compareOldPassword = async () => {
    let formValid = false;
    let data = {
      PersonID: this.state.PersonID,
      Password: this.state.OldPassword
    }
    await api.post('user/comparePassword', data).then(res => {
      // // // console.log('res',res)
      if (res.success) {
        formValid =  res.data.isValid;
      }
    }).catch(err => {
      // // console.log("get notification err", err);
    })
    // console.log("formValid...",formValid)
    return await formValid;
  }

  save = (idx) => {
    const {FirstName, LastName, Email, DOB, Gender, PhoneNum, PersonID} = this.state;
    // // console.log("object.....","+" +PhoneNum)
    // let countrycode= !CommonConfig.isEmpty(PhoneNum)?getCountryCallingCode((parsePhoneNumber("+" + PhoneNum).hasCountry))?getCountryCallingCode((parsePhoneNumber("+" + PhoneNum).country)):"":""
    this.validate(idx).then((res) => {
      
      // console.log("objdfsfs.",res);
        if(res){

          let countrycode= !CommonConfig.isEmpty(PhoneNum)?(parsePhoneNumber("+" + PhoneNum).country)? getCountryCallingCode((parsePhoneNumber("+" + PhoneNum).country)):"":""

        // console.log("object...",PhoneNum);
        let data = {
            FirstName : FirstName,
            LastName : LastName,
            Email : Email,
            Gender : Gender!=null?Gender:'',
            CountryCode : countrycode,
            DOB : DOB!=null?DOB:'',
            PhoneNumber : PhoneNum?PhoneNum.substr(countrycode.length):'',
            PersonID : PersonID,
            Type: 'Update',
            Password: this.state.NewPassword
        }
        // console.log("data...",data)
        api.post('user/updateProfile', data).then(res => {
          if (res.success) {
            if(res.data.length > 0){
              cogoToast.error(res.data[0][0].message);
            } 
            else {
              window.location.reload();
              cogoToast.success("Data updated successfully");
            } 
          }else {
              cogoToast.error("Something went wrong");
            }
            
          }).catch(err => {
            // // console.log("get notification err", err);
          })
        }
        else{
          // cogoToast.error("Plese")
        }
      })
    
    
    
  }

  handleChange = (e, type) => {

    if (type == "NewPassword") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ NewPassword: e.target.value, NewPasswordErr: true, NewPasswordhelperText: 'Please enter new password.' })
      } else {
        this.setState({ NewPassword: e.target.value, NewPasswordErr: false, NewPasswordhelperText: '' })
      }
    }
    if (type == "OldPassword") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ OldPassword: e.target.value, OldPasswordErr: true, OldPasswordhelperText: 'Please enter your password.' })
      } else {
        this.setState({ OldPassword: e.target.value, OldPasswordErr: false, OldPasswordhelperText: '' })
      }
    }
    if (type == "ConfirmPassword") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ ConfirmPassword: e.target.value, ConfirmPasswordErr: true, ConfirmPasswordhelperText: 'Please enter confirm password.' })
      } else if (e.target.value !== this.state.NewPassword) {
        this.setState({ ConfirmPassword: e.target.value, ConfirmPasswordErr: true, ConfirmPasswordhelperText: 'New Password and confirm password must be same.' })
      } else {
        this.setState({ ConfirmPassword: e.target.value, ConfirmPasswordErr: false, ConfirmPasswordhelperText: '' })
      }
    }

    if (type === "Phone") {
    //   if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          PhoneNum: e.target.value,
        //   PhoneErr: true,
        //   PhonehelperText: "Please enter Phone number",
        });
    //   } else {
    //     this.setState({
    //         PhoneNum: e.target.value,
    //         PhoneErr: false,
    //         PhonehelperText: "",
    //     });
    //   }
    }
    if (type === "PhoneNumber") {
        if (CommonConfig.isEmpty(e)) {
          this.setState({ PhoneNum: e, PhoneNumberErr: true, PhoneNumberhelperText: 'Please enter phone number.' })
        } else {
          this.setState({ PhoneNum: e, PhoneNumberErr: false, PhoneNumberhelperText: '' })
        }
      }
    if (type === "FirstName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          FirstName: e.target.value,
          FirstNameErr: true,
          FirstNamehelperText: "Please enter first name.",
        });
      } else {
        this.setState({
          FirstName: e.target.value,
          FirstNameErr: false,
          FirstNamehelperText: "",
        });
      }
    }
    if (type === "LastName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          LastName: e.target.value,
          LastNameErr: true,
          LastNamehelperText: "Please enter last name.",
        });
      } else {
        this.setState({
          LastName: e.target.value,
          LastNameErr: false,
          LastNamehelperText: "",
        });
      }
    }
    if (type === "Email") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          Email: e.target.value,
          EmailErr: true,
          EmailhelperText: "Please enter email.",
        });
      } else if (!e.target.value.match(CommonConfig.RegExp.email)) {
        this.setState({
          Email: e.target.value,
          EmailErr: true,
          EmailhelperText: "Please enter valid email.",
        });
      } else {
        this.setState({
          Email: e.target.value,
          EmailErr: false,
          EmailhelperText: "",
        });
      }
    }

    if (type === "DOB") {
      this.setState({
        DOB: e.target.value,
      });
    }
    if (type === "Gender") {
      this.setState({
        Gender: e.target.value,
      });
    }
  };

  handleTogglePassword = (type) => {
    if (type === "OldPassword") {
      this.setState(state => ({ showOldPassword: !state.showOldPassword }));
    } else if (type === "NewPassword") {
      this.setState(state => ({ showNewPassword: !state.showNewPassword }));
    }else {
      this.setState(state => ({ showConfirmPassword: !state.showConfirmPassword }));
    }
  };

   validate = async (idx) => {
    let isValid = true;
    const {FirstName, LastName, Email, DOB, Gender, PhoneNum, EmailErr, OldPassword, NewPassword, ConfirmPassword, OldPasswordErr, NewPasswordErr, ConfirmPasswordErr} = this.state;
      if(idx === 0) {
        if(CommonConfig.isEmpty(FirstName) || CommonConfig.isEmpty(LastName)){
            isValid = false;
            cogoToast.error("Firstname or Lastname cannot be empty");
        }
      }
      if(idx === 1) {
        if(CommonConfig.isEmpty(DOB)){
            isValid = false;
            cogoToast.error("Please enter Date of Birth");
        }
      }
      if(idx === 2) {
        if(CommonConfig.isEmpty(Gender)){
            isValid = false;
            cogoToast.error("Please enter Gender");
        }
      }
      if(idx === 3) {
        if(CommonConfig.isEmpty(Email) || EmailErr===true){
            isValid = false;
            cogoToast.error("Please enter Email");
        }
      }
      if(idx === 4) {
          // console.log("Phone",PhoneNum)
        if(CommonConfig.isEmpty(PhoneNum) || !formatPhoneNumberIntl("+" + PhoneNum) || !parsePhoneNumber("+" + PhoneNum).country || !isValidPhoneNumber("+" + PhoneNum)){
            isValid = false;
            cogoToast.error("Please enter valid phone no.");
        }
      }
      if(idx === 5) {
        if(CommonConfig.isEmpty(OldPassword) || CommonConfig.isEmpty(NewPassword) || CommonConfig.isEmpty(ConfirmPassword)){
            isValid = false;
            cogoToast.error("Please enter the password");
        }
        else{
          isValid = await this.compareOldPassword()
          // console.log("isVali.d..........   ",isValid);
          if(!isValid){
            cogoToast.error("Entered password is incorrect");
          }
        }
        if(OldPasswordErr || ConfirmPasswordErr || NewPasswordErr){
          isValid = false;
            cogoToast.error("Please enter valid password");
        }
    }
      return isValid;
  }

  render() {
    const {
      FirstName,
      LastName,
      DateOfBirth,
      Gender,
      Email,
      PhoneNum,
      Country,
      CountryCode,
      Password,
      NewPassword,
      ConfirmPassword,
      OldPassword,
      DOB,
      edit,
    } = this.state;
    return (
      <div className="profile-outer">
        <div className="container">
          <div className="profile-inner">
            <h3>Personal Info</h3>
            <div className="row">
              <div className="col-lg-8 col-md-8">
                <div className="added-info">
                  <div className="ai-left">
                    <h6>Legal name</h6>
                    {!edit[0] ? (
                      <span>
                        {FirstName} {LastName}
                      </span>
                    ) : (
                      <Form>
                        <Form.Row>
                          <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>FirstName</Form.Label>
                            <Form.Control
                              type="text"
                              value={FirstName}
                              className={
                                this.state.FirstNameErr ? "is-invalid" : null
                              }
                              placeholder="Enter FirstName"
                              onChange={(e) =>
                                this.handleChange(e, "FirstName")
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {this.state.FirstNamehelperText}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>LastName</Form.Label>
                            <Form.Control
                              type="text"
                              value={LastName}
                              className={
                                this.state.LastNameErr ? "is-invalid" : null
                              }
                              placeholder="Enter LastName"
                              onChange={(e) => this.handleChange(e, "LastName")}
                            />
                            <Form.Control.Feedback type="invalid">
                              {this.state.LastNamehelperText}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                      </Form>
                    )}
                  </div>
                  <div className="edti-info">
                    {!edit[0] ? (
                      <a href="javascript:;" onClick={() => this.edit(0)}>
                        Edit
                      </a>
                    ) : (
                      <><a href="javascript:;" onClick={() => this.save(0)}>
                        Save
                      </a><br />
                      <a href="javascript:;" onClick={() => this.cancel(0)}>
                        Cancel
                      </a></>
                    )}
                  </div>
                </div>
                <div className="added-info">
                  <div className="ai-left">
                    <h6>Date of Birth</h6>
                    {!edit[1] ? (
                      <span>{CommonConfig.isEmpty(DateOfBirth)?"Not Specified":DateOfBirth}</span>
                    ) : (
                      <Form>
                        <Form.Row>
                          <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Control
                              type="date"
                              value={DOB}
                              placeholder="Enter Date of Birth"
                              onChange={(e) => this.handleChange(e, "DOB")}
                            />
                          </Form.Group>
                        </Form.Row>
                      </Form>
                    )}
                  </div>
                  <div className="edti-info">
                  <div className="edti-info">
                    {!edit[1] ? (
                      <a href="javascript:;" onClick={() => this.edit(1)}>
                        Edit
                      </a>
                    ) : (
                      <><a href="javascript:;" onClick={() => this.save(1)}>
                        Save
                      </a><br />
                      <a href="javascript:;" onClick={() => this.cancel(1)}>
                        Cancel
                      </a></>
                    )}
                  </div>
                
                  </div>
                </div>
                <div className="added-info">
                  <div className="ai-left">
                    <h6>Gender</h6>
                    {!edit[2] ? (
                      <span>{CommonConfig.isEmpty(Gender)?"Not Specified":Gender}</span>
                    ) : (
                      <Form>
                        <Form.Row>
                          {" "}
                          <Form.Group as={Col} controlId="formGridState">
                            <Form.Control
                              as="select"
                              onChange={(e) => this.handleChange(e, "Gender")}
                              defaultValue="Choose..."
                            >
                              <option disabled>Choose...</option>
                              <option selected={Gender==="Male"?true:false}>Male</option>
                              <option selected={Gender==="Female"?true:false}>Female</option>
                            </Form.Control>
                          </Form.Group>
                        </Form.Row>
                      </Form>
                    )}
                  </div>
                  <div className="edti-info">
                    {!edit[2] ? (
                      <a href="javascript:;" onClick={() => this.edit(2)}>
                        Edit
                      </a>
                    ) : (
                      <><a href="javascript:;" onClick={() => this.save(2)}>
                        Save
                      </a><br />
                      <a href="javascript:;" onClick={() => this.cancel(2)}>
                        Cancel
                      </a></>
                    )}
                  </div>
                
                </div>
                <div className="added-info">
                  <div className="ai-left">
                    <h6>Email</h6>
                    {!edit[3] ? (
                      <span>{CommonConfig.isEmpty(Email)?"Not Specified":Email}</span>
                    ) : (
                      <Form>
                        <Form.Row>
                          <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Control
                              type="email"
                              value={Email}
                              className={
                                this.state.EmailErr ? "is-invalid" : null
                              }
                              onChange={(e) => this.handleChange(e, "Email")}
                              placeholder="Enter email"
                            />
                            <Form.Control.Feedback type="invalid">
                              {this.state.EmailhelperText}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                      </Form>
                    )}
                  </div>
                  <div className="edti-info">
                    {!edit[3] ? (
                      <a href="javascript:;" onClick={() => this.edit(3)}>
                        Edit
                      </a>
                    ) : (
                      <><a href="javascript:;" onClick={() => this.save(3)}>
                        Save
                      </a><br />
                      <a href="javascript:;" onClick={() => this.cancel(3)}>
                        Cancel
                      </a></>
                    )}
                  </div>
                
                </div>
                <div className="added-info">
                  <div className="ai-left">
                    <h6>Phone Number</h6>
                    {!edit[4] ? (
                      <span>
                        {CommonConfig.isEmpty(PhoneNum)
                          ? "Not Specified"
                          : formatPhoneNumberIntl("+" + PhoneNum)}
                      </span>
                    ) : (
                        <>
                      <PhoneInput
                        country={Country.toLowerCase()}
                        value={PhoneNum}
                        onChange={(e) => this.handleChange(e,"PhoneNumber")}
                      />
                      <div className="invalid-feedback d-block">{this.state.PhoneNumberhelperText}</div></>
                    )}
                  </div>
                  <div className="edti-info">
                    {!edit[4] ? (
                      <a href="javascript:;" onClick={() => this.edit(4)}>
                        Edit
                      </a>
                    ) : (
                      <><a href="javascript:;" onClick={() => this.save(4)}>
                        Save
                      </a><br />
                      <a href="javascript:;" onClick={() => this.cancel(4)}>
                        Cancel
                      </a></>
                    )}
                  </div>
                
                </div>
                     
                <div className="added-info">
                  <div className="ai-left">
                    <h6>Password</h6>
                    {!edit[5] ? (
                      <span>
                        **********
                      </span>
                    ) : (
                        <>
                        <Form>
                          <Form.Row>
                                  <Form.Group as={Col} md="12" controlId="password" className="mb-3">
                                    <Form.Control
                                      required
                                      type={this.state.showOldPassword ? "text" : "password"}
                                      className={this.state.OldPasswordErr ? "is-invalid" : null}
                                      value={OldPassword}
                                      placeholder="Password" onChange={(e) => this.handleChange(e, "OldPassword")}
                                    />
                                    <a href="javascript:;">
                                      {!this.state.showOldPassword ?
                                        <i className="fas fa-eye-slash" onClick={() => this.handleTogglePassword("OldPassword")}></i>
                                        :
                                        <i className="fas fa-eye" onClick={() => this.handleTogglePassword("OldPassword")}></i>

                                      }
                                    </a>
                                    <Form.Control.Feedback type="invalid">{this.state.OldPasswordhelperText}</Form.Control.Feedback>
                                  </Form.Group>
                          </Form.Row>
                          <Form.Row>
                                  <Form.Group as={Col} md="12" controlId="password" className="mb-3">
                                    <Form.Control
                                      required
                                      type={this.state.showNewPassword ? "text" : "password"}
                                      className={this.state.NewPasswordErr ? "is-invalid" : null}
                                      value={NewPassword}
                                      placeholder="New Password" onChange={(e) => this.handleChange(e, "NewPassword")}
                                    />
                                    <a href="javascript:;">
                                      {!this.state.showNewPassword ?
                                        <i className="fas fa-eye-slash" onClick={() => this.handleTogglePassword("NewPassword")}></i>
                                        :
                                        <i className="fas fa-eye" onClick={() => this.handleTogglePassword("NewPassword")}></i>

                                      }
                                    </a>
                                    <Form.Control.Feedback type="invalid">{this.state.NewPasswordhelperText}</Form.Control.Feedback>
                                  </Form.Group>
                                </Form.Row>
                          
                          
                          <Form.Row>
                                  <Form.Group as={Col} md="12" controlId="confirmpassword" className="mb-3">
                                    <Form.Control
                                      required
                                      type={this.state.showConfirmPassword ? "text" : "password"}
                                      className={this.state.ConfirmPasswordErr ? "is-invalid" : null}
                                      value={ConfirmPassword}
                                      placeholder="Confirm Password" onChange={(e) => this.handleChange(e, "ConfirmPassword")}
                                    />
                                    <a href="javascript:;">
                                      {!this.state.showConfirmPassword ?
                                        <i className="fas fa-eye-slash" onClick={() => this.handleTogglePassword("ConfirmPassword")}></i>
                                        :
                                        <i className="fas fa-eye" onClick={() => this.handleTogglePassword("ConfirmPassword")}></i>

                                      }
                                    </a>
                                    <Form.Control.Feedback type="invalid">{this.state.ConfirmPasswordhelperText}</Form.Control.Feedback>
                                  </Form.Group>
                                </Form.Row>
                        
                        </Form>
                     </>
                    )}
                  </div>
                  <div className="edti-info">
                    {!edit[5] ? (
                      <a href="javascript:;" onClick={() => this.edit(5)}>
                        Edit
                      </a>
                    ) : (
                      <><a href="javascript:;" onClick={() => this.save(5)}>
                        Save
                      </a><br />
                      <a href="javascript:;" onClick={() => this.cancel(5)}>
                        Cancel
                      </a></>
                    )}
                  </div>
                
                </div>
              
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="edit-info">
                  <div className="edit-info-box">
                    <i class="fas fa-lock"></i>
                    <h4>Which details can be edited?</h4>
                    <p>
                      Details The WorkPod uses to verify your identity can't be
                      changed. Contact info and some personal details can be
                      edited, but we may ask you to verify your identity the
                      next time you book or create a listing.
                    </p>
                  </div>
                  <div className="edit-info-box">
                    <i class="fas fa-id-card"></i>
                    <h4>What info is shared with others?</h4>
                    <p>
                      The WorkPod only releases contact information for hosts
                      and guests after a reservation is confirmed.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withNamespaces()(Profile);
