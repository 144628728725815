import React, { Component } from "react";
import SlideToggle from "react-slide-toggle";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import cogoToast from "cogo-toast";
import api from "../../../src/utils/apiClient";
import { CommonConfig } from "utils/constant";
import { apiBase } from "../../../src/utils/config";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Popover from "react-bootstrap/Popover";
import StickyBox from "react-sticky-box";
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import Header from "../Header/Header.js";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import GoogleMapReact from "google-map-react";
import { Calendar } from "react-date-range";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import parse from "html-react-parser";
import listingimage from "../../assets/img/listing-image.jpg";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { withNamespaces } from 'react-i18next';
// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
const animatedComponents = makeAnimated();

class Listing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placeName:
        this.props.location.state && this.props.location.state.placeName
          ? this.props.location.state.placeName
          : "",
      latitude: CommonConfig.isEmpty(this.props.location.state.latitude) ? 51.51 : this.props.location.state.latitude,
      // this.props.location.state && this.props.location.state.latitude
      //   ? this.props.location.state.latitude
      //   : 51.50745774435763,
      longitude: CommonConfig.isEmpty(this.props.location.state.longitude) ? -0.13 : this.props.location.state.longitude,
      // this.props.location.state && this.props.location.state.longitude
      //   ? this.props.location.state.longitude
      //   : -0.11642864912109863,
      selectedDateRange:
        this.props.location.state && this.props.location.state.selectedDateRange
          ? this.props.location.state.selectedDateRange
          : [
            {
              startDate: new Date(),
              endDate: new Date(),
              key: "selection",
            },
          ],
      day:
        this.props.location.state && this.props.location.state.day
          ? this.props.location.state.day
          : "Single Day",
      ArriveTime: [],
      DepartTime: [],
      Time: [],
      singleDate:
        this.props.location.state && this.props.location.state.singleDate
          ? this.props.location.state.singleDate
          : "",
      Users:
        this.props.location.state && this.props.location.state.Users
          ? this.props.location.state.Users
          : "",
      Pods: "",
      Rooms: "",
      PodType: "",
      Min: "",
      MinUser:
        this.props.location.state && this.props.location.state.MinUser
          ? this.props.location.state.MinUser
          : "",
      Max: "",
      isShowCalendar: false,
      podList: [],
      podListMap: [],
      openAdvanceFilter: false,
      dayTypeList: [],
      amenitiesList: [],
      timeSlotList: [],
      noOfUsersList: [],
      toggleEvent: 0,
      toggleAdvanceEvent: 0,
      IsGridViewSelected: false,
      sortBy: "Low",
      zoom: 5,
      Amenities: [],
      tempPlaceName:
        this.props.location.state && this.props.location.state.placeName
          ? this.props.location.state.placeName
          : "",
      showFavModal: false,
      FavPropertyID: "",
      WishListName: "",
      WishListNameErr: false,
      WishListNamehelperText: "",
      items: [],
      showLoginModal: false,
      IsStudentDiscount: CommonConfig.isEmpty(this.props.location.state.IsStudentDiscount) ? 0 : this.props.location.state.IsStudentDiscount,
      IsLateNightPodSelected: CommonConfig.isEmpty(this.props.location.state.IsLateNightPodSelected) ? 0 : this.props.location.state.IsLateNightPodSelected,
      IsLongTermPodSelected: CommonConfig.isEmpty(this.props.location.state.IsLongTermPodSelected) ? 0 : this.props.location.state.IsLongTermPodSelected,
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      mapLoaded: false
    };
  }

  componentDidMount() {
    if (window.innerWidth <= 767) {
      this.setState({ IsGridViewSelected: true });
    }

    this.getSearchListing();
    this.getDayTypeList();
    this.getTimeSlotList();
    this.getAmenitiesList();
    this.getNoOfUsersList();
  }

  detailspage = (id) => {
    this.props.history.push({
      pathname: "/admin/detailpage",
      state: {
        PropertyID: id,
        selectedDateRange: this.state.selectedDateRange,
        day: this.state.day,
        ArriveTime: this.state.ArriveTime,
        DepartTime: this.state.DepartTime,
        Time: this.state.Time,
        singleDate: this.state.singleDate,
        Users: this.state.Users,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        CountryName: this.state.CountryName
      },
    });
  };

  getDayTypeList = () => {
    let data = {
      StringMapType: "DAYTYPE",
    };
    try {
      api
        .post("utility/getStringMap", data)
        .then((res) => {
          if (res.success) {
            this.setState({ dayTypeList: res.data });
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  };

  getAmenitiesList = () => {
    let data = {
      FeatureType: "AMENITIES",
    };
    try {
      api
        .post("property/getAmenitiesList", data)
        .then((res) => {
          if (res.success) {
            this.setState({ amenitiesList: res.data });
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  };

  getTimeSlotList = () => {
    let data = {
      StringMapType: "TIMESLOT",
    };
    try {
      api
        .post("utility/getStringMap", data)
        .then((res) => {
          if (res.success) {
            this.setState({ timeSlotList: res.data });
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  };

  getNoOfUsersList = () => {
    let data = {
      StringMapType: "NOOFUSERS",
    };
    try {
      api
        .post("utility/getStringMap", data)
        .then((res) => {
          if (res.success) {
            this.setState({ noOfUsersList: res.data });
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  };

  getSearchListing = (sortBy, place) => {
    let place_name = this.state.placeName
      ? this.state.placeName
      : this.props.location.state
        ? this.props.location.state.placeName
        : "";
    let IsStudentDiscount = this.state.IsStudentDiscount
    // ? this.state.IsStudentDiscount
    // : this.props.location.state
    // ? this.props.location.state.IsStudentDiscount
    // : 0;
    let IsLateNightPodSelected = this.state.IsLateNightPodSelected
    let IsLongTermPodSelected = this.state.IsLongTermPodSelected
    // ? this.state.IsLateNightPodSelected
    // : this.props.location.state
    // ? this.props.location.state.IsLateNightPodSelected
    // : 0;
    // 
    this.props.history.push({
      state: this.state,
    });
    // if (place_name == "" || IsStudentDiscount == 0 || IsLateNightPodSelected == 0) {
    //   this.props.history.push({
    //     pathname: "/admin/homepage",
    //     state: {},
    //   });
    // } else {
    let data;
    if (this.state.day === "Single Day") {
      data = {
        placeName: place
          ? place
          : this.state.placeName
            ? this.state.placeName
            : this.props.location.state
              ? this.props.location.state.placeName
              : "",
        day: this.state.day,
        Time: this.state.Time,
        singleDate: this.state.singleDate
          ? moment(this.state.singleDate)
            .format("YYYY-MM-DD HH:mm:ss")
            .toString()
          : "",
        Users: this.state.Users,
        Pods: this.state.Pods,
        Rooms: this.state.Rooms,
        PodType: this.state.PodType,
        Min: this.state.Min,
        MinUser: this.state.MinUser,
        Max: this.state.Max,
        sortBy: sortBy ? sortBy : this.state.sortBy,
        Amenities: this.state.Amenities,
        UserID: CommonConfig.loginData().PersonID,
        IsStudentDiscount: Number(IsStudentDiscount),
        IsLateNightPodSelected: Number(IsLateNightPodSelected),
        IsLongTermPodSelected: Number(IsLongTermPodSelected),
        // CountryName : this.state.CountryName
      };
    } else {
      data = {
        placeName: place
          ? place
          : this.state.placeName
            ? this.state.placeName
            : this.props.location.state
              ? this.props.location.state.placeName
              : "",
        day: this.state.day,
        ArriveDate: moment(this.state.selectedDateRange[0].startDate).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        DepartDate: moment(this.state.selectedDateRange[0].endDate).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        ArriveTime: this.state.ArriveTime,
        DepartTime: this.state.DepartTime,
        Users: this.state.Users,
        Pods: this.state.Pods,
        Rooms: this.state.Rooms,
        PodType: this.state.PodType,
        MinUser: this.state.MinUser,
        Min: this.state.Min,
        Max: this.state.Max,
        sortBy: sortBy ? sortBy : this.state.sortBy,
        Amenities: this.state.Amenities,
        UserID: CommonConfig.loginData().PersonID,
        IsStudentDiscount: Number(IsStudentDiscount),
        IsLateNightPodSelected: Number(IsLateNightPodSelected),
        IsLongTermPodSelected: Number(IsLongTermPodSelected),
        // CountryName : this.state.CountryName
      };
    }
    try {
      api
        .post("property/searchproperty", data)
        .then((res) => {
          if (res.success) {
            if (res.data.length > 0) {
              const google = window.google;
              var geocoder = new google.maps.Geocoder();
              var latlng = new google.maps.LatLng(Number(res.data[0]['Latitude']), Number(res.data[0]['Longitude']));
              geocoder.geocode({ 'latLng': latlng }, (results, status) => {
                if (status == google.maps.GeocoderStatus.OK) {
                  //formatted address
                  for (var i = 0; i < results[0].address_components.length; i++) {
                    for (var b = 0; b < results[0].address_components[i].types.length; b++) {

                      //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
                      if (results[0].address_components[i].types[b] == "country") {
                        //this is the object you are looking for
                        // console.log("results[0].address_components[i].long_name", results[0].address_components[i].long_name)
                        geocodeByAddress(results[0].address_components[i].long_name)
                          .then(results => getLatLng(results[0]))
                          .then(latLng => {
                            this.setState({ latitude: latLng.lat, longitude: latLng.lng })
                          })
                          .catch(error => { console.error('Error', error) });
                        break;
                      }
                    }
                  }
                  // return output;
                } else {
                  // alert("Geocoder failed due to: " + status);
                }
              })
            }
            this.setState({ podList: res.data, podListMap: res.data });
          } else {
          }
        })

        .catch((error) => {
          // // console.log("error", error);
        });
    } catch (err) {
      // // console.log("error", err);
    }
    // }
  };

  handleClickOpenCalendar = () => {
    this.setState({
      isShowCalendar: true,
    });
  };

  handleChange = (e, type) => {
    if (type === "date") {
      if (moment(e[0].startDate).format('DD-MM-YYYY') !== moment(e[0].endDate).format('DD-MM-YYYY')) {
        this.datepickrange.click()
      }
      this.setState({
        selectedDateRange: e,
      });
    } else if (type === "singleDate") {
      this.setState({ singleDate: e });
      this.datepick.click()
    } else if (type === "day") {
      this.setState({ day: e });
    } else if (type === "ArriveTime") {
      this.setState({ ArriveTime: e });
    } else if (type === "Time") {
      this.setState({ Time: e });
    } else if (type === "DepartTime") {
      this.setState({ DepartTime: e });
    } else if (type === "Users") {
      this.setState({ Users: e.target.value });
    } else if (type === "Pods") {
      this.setState({ Pods: e.target.value });
    } else if (type === "Rooms") {
      this.setState({ Rooms: e.target.value });
    } else if (type === "PodType") {
      this.setState({ PodType: e.target.value });
    } else if (type === "Min") {
      this.setState({ Min: e.target.value });
    } else if (type === "Max") {
      this.setState({ Max: e.target.value });
    } else if (type === "PriceDefined0to10") {
      if (e.target.checked) {
        this.setState({ Min: 0, Max: 10 });
      } else {
        this.setState({ Min: "", Max: "" });
      }
    } else if (type === "PriceDefined10to20") {
      if (e.target.checked) {
        this.setState({ Min: 10, Max: 20 });
      } else {
        this.setState({ Min: "", Max: "" });
      }
    } else if (type === "PriceDefined20to30") {
      if (e.target.checked) {
        this.setState({ Min: 20, Max: 30 });
      } else {
        this.setState({ Min: "", Max: "" });
      }
    } else if (type === "SortBy") {
      if (e.target.value == "Nearby") {
        this.setState({ sortBy: e.target.value });
        navigator.geolocation.getCurrentPosition((position) => {
          // // // console.log("checkMyLocation", position);
          // newLat = parseFloat(position.coords.latitude).toFixed(4);
          // newLng = parseFloat(position.coords.longitude).toFixed(4);
          this.setState({
            latitude: parseFloat(position.coords.latitude).toFixed(4),
            longitude: parseFloat(position.coords.longitude).toFixed(4),
          });
          this.codeLatLng(
            parseFloat(position.coords.latitude).toFixed(4),
            parseFloat(position.coords.longitude).toFixed(4)
          );
        });
      } else {
        this.setState({ sortBy: e.target.value });
        // this.handleSelect(this.state.tempPlaceName)
        this.getSearchListing(e.target.value, "");
      }
    } else if (type === "Amenities") {
      if (this.state.Amenities.includes(e.FeatureID)) {
        var array = [...this.state.Amenities];
        var index = array.indexOf(e.FeatureID);
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ Amenities: array });
        }
      } else {
        this.setState({ Amenities: [...this.state.Amenities, e.FeatureID] });
      }
    } else if (type === "WishListName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          WishListName: e.target.value,
          WishListNameErr: true,
          WishListNamehelperText: "Please enter name",
        });
      } else {
        this.setState({
          WishListName: e.target.value,
          WishListNameErr: false,
          WishListNamehelperText: "",
        });
      }
    } else {
      this.setState({
        placeName: e.target.value,
        tempPlaceName: e.target.value,
      });
    }
    if (type === "Name") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ Name: e.target.value, NameErr: true, NamehelperText: 'Name is required.' })
      }
      else {
        this.setState({ Name: e.target.value, NameErr: false, NamehelperText: '' })
      }
    } else if (type === "EmailAddress") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ EmailAddress: e.target.value, EmailAddressErr: true, EmailAddresshelperText: 'Email address is required.' })
      } else if (!e.target.value.match(CommonConfig.RegExp.email)) {
        this.setState({ EmailAddress: e.target.value, EmailAddressErr: true, EmailAddresshelperText: 'Please enter valid email.' })
      } else {
        this.setState({ EmailAddress: e.target.value, EmailAddressErr: false, EmailAddresshelperText: '' })
      }
    } else if (type === "Message") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ Message: e.target.value, MessageErr: true, MessagehelperText: 'Message is required.' })
      }
      else {
        this.setState({ Message: e.target.value, MessageErr: false, MessagehelperText: '' })
      }
    }
  };

  codeLatLng = (lat, lng) => {
    const google = window.google;
    var geocoder = new google.maps.Geocoder();
    var latlng = new google.maps.LatLng(lat, lng);
    var city = "";
    geocoder.geocode({ latLng: latlng }, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        //   // // console.log(results)
        if (results[1]) {
          //formatted address

          city = results[results.length - 4].formatted_address;
          this.handleSelect(city);
          this.setState({ placeName: city });
          this.getSearchListing("", city);
          //find country name
          //      for (var i=0; i<results[0].address_components.length; i++) {
          //     for (var b=0;b<results[0].address_components[i].types.length;b++) {

          //     //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
          //         if (results[0].address_components[i].types[b] == "administrative_area_level_1") {
          //             //this is the object you are looking for
          //             city= results[0].address_components[i];
          //             break;
          //         }
          //         if (results[0].address_components[i].types[b] == "country") {
          //             //this is the object you are looking for
          //             city= results[0].address_components[i];
          //             break;
          //         }
          //     }
          // }
          // city data
          // alert(city.short_name + " " + city.long_name)
        } else {
          //   alert("No results found");
        }
      } else {
        // alert("Geocoder failed due to: " + status);
      }
    });
  };

  handleChangePlaces = (address) => {
    this.setState({ placeName: address });
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState({ latitude: latLng.lat, longitude: latLng.lng });
        // // // console.log('Success', latLng)
      })
      .catch((error) => {
        /*console.error('Error', error)*/
      });
  };

  handleSelect = (address) => {
    this.setState({ placeName: address });
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState({ latitude: latLng.lat, longitude: latLng.lng });
        // // // console.log('Success', latLng)
      })
      .catch((error) => {
        /*console.error('Error', error)*/
      });
  };

  distanceToMouse = (pt, mp) => {
    if (pt && mp) {
      // return distance between the marker and mouse pointer
      return Math.sqrt(
        (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
      );
    }
  };

  handleFavModal = (e, item, type) => {
    if (CommonConfig.loginData().PersonID) {
      this.setState({ items: item });
      if (type === "Favorite") {
        this.setState({ showFavModal: true });
      } else {
        this.favUnFavPod(e, "UnFavorite", item);
      }
    } else {
      // this.props.location.state["showLoginModal" ] = true
      // this.props.history.push('/admin/listing');
      this.setState({ showLoginModal: true });
      // // // console.log("list",this.props)
    }
  };

  favUnFavPod = (event, type, items) => {
    event.preventDefault();
    if (type == "Favorite") {
      const { WishListName } = this.state;
      if (CommonConfig.isEmpty(WishListName)) {
        this.setState({
          WishListNameErr: true,
          WishListNamehelperText: "Please enter name",
        });
      } else {
        let data = {
          WishListName: WishListName,
          EntityID: items.PropertyID,
          UserID: CommonConfig.loginData().PersonID,
        };
        try {
          api
            .post("user/favunfav", data)
            .then((res) => {
              if (res.success) {
                this.setState({
                  showFavModal: false,
                  items: [],
                  WishListName: "",
                  WishListNameErr: false,
                  WishListNamehelperText: "",
                });
                cogoToast.success(`Saved to ` + WishListName + `.`);
                this.getSearchListing();
              } else {
                this.setState({
                  WishListNameErr: true,
                  WishListNamehelperText: "Please enter valid name.",
                });
                cogoToast.error(`Maximum name length 50 character is allowed`);
              }
            })

            .catch((error) => {
              // // console.log("error", error);
            });
        } catch (err) {
          // // console.log("error", err);
        }
      }
    } else {
      let data = {
        WishListName: items.WishListName,
        EntityID: items.PropertyID,
        UserID: CommonConfig.loginData().PersonID,
      };
      try {
        api
          .post("user/favunfav", data)
          .then((res) => {
            if (res.success) {
              cogoToast.success(
                `Removed from favourite ` + items.WishListName + `.`
              );
              this.setState({
                showFavModal: false,
                items: [],
                WishListName: "",
                WishListNameErr: false,
                WishListNamehelperText: "",
              });
              this.getSearchListing();
            } else {
              // this.setState({ WishListNameErr: true, WishListNamehelperText: 'Please enter valid name.' })
              // cogoToast.error(`Maximum name length 50 character is allowed`)
            }
          })

          .catch((error) => {
            // // console.log("error", error);
          });
      } catch (err) {
        // // console.log("error", err);
      }
    }
  };

  findPosition = (obj) => {
    var currentTop = 0;
    if (obj.offsetParent) {
      do {
        currentTop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return [currentTop];
    }
  };

  validate = (event) => {
    event.preventDefault();
    const { Name, EmailAddress, Message } = this.state;
    let IsValid = true;

    if (CommonConfig.isEmpty(Name)) {
      IsValid = false;
      this.setState({ NameErr: true, NamehelperText: 'Name is required.' })
    }
    else {
      this.setState({ NameErr: false, NamehelperText: '' })
    }
    if (CommonConfig.isEmpty(EmailAddress)) {
      IsValid = false;
      this.setState({ EmailAddressErr: true, EmailAddresshelperText: 'Email address is required.' })
    }
    else if (!EmailAddress.match(CommonConfig.RegExp.email)) {
      IsValid = false;
      this.setState({ EmailAddressErr: true, EmailAddresshelperText: 'Please enter valid email.' })
    }
    else {
      this.setState({ EmailAddressErr: false, EmailAddresshelperText: '' })
    }
    if (CommonConfig.isEmpty(Message)) {
      IsValid = false;
      this.setState({ MessageErr: true, MessagehelperText: 'Message is required.' })
    }
    else {
      this.setState({ MessageErr: false, MessagehelperText: '' })
    }
    return IsValid;
  }

  submitReport = (e, PropertyID) => {
    e.preventDefault();
    if (this.validate(e)) {
      let data = {
        Name: this.state.Name,
        Email: this.state.EmailAddress,
        Message: this.state.Message,
        PropertyID: PropertyID,
        PersonID: CommonConfig.loginData().PersonID ? CommonConfig.loginData().PersonID : '',
      }
      // console.log("data...", data);
      try {
        api.post("property/reportPod", data).then(res => {
          if (res.success) {
            cogoToast.success('Request sent to host. We will respond to your message soon!')
            this.setState({
              showReportModal: false,
              Name: '',
              NameErr: false,
              NamehelperText: '',

              EmailAddress: '',
              EmailAddressErr: false,
              EmailAddresshelperText: '',

              Message: '',
              MessageErr: false,
              MessagehelperText: '',
            });
          }
          else {
            cogoToast.error('Request failed. Please try again!')
            this.setState({
              showReportModal: false,
              Name: '',
              NameErr: false,
              NamehelperText: '',

              EmailAddress: '',
              EmailAddressErr: false,
              EmailAddresshelperText: '',

              Message: '',
              MessageErr: false,
              MessagehelperText: '',
            });
          }
        }).catch(err => {
          // // console.log(err)
        })
      } catch (err) {
      }
    }
  }

  render() {
    const { ...rest } = this.props;
    const { t } = this.props
    const {
      podList,
      dayTypeList,
      amenitiesList,
      timeSlotList,
      noOfUsersList,
      IsGridViewSelected,
      latitude,
      longitude,
      podListMap,
      showFavModal,
    } = this.state;
    const timeSlotListArray = timeSlotList.map((content) => {
      return { value: content.StringMapID, label: content.StringMapName };
    });

    return (
      <div className="relative">
        <a
          href="javascript:;"
          className="mobile-filter-toggle"
          onClick={() => this.setState({ showToggle: !this.state.showToggle })}
        >
          Filters
        </a>
        <section
          className={
            this.state.showToggle
              ? "innerpage-filter filter-mobile"
              : "innerpage-filter"
          }
        >
          <div className="container-fluid">
            {this.state.day === "Single Day" ? (
              <div className="innerpage-filter-wrap">
                <div className="place">
                  <div className="ms-input">
                    <i className="fas fa-search"></i>
                    <PlacesAutocomplete
                      value={this.state.placeName}
                      onChange={this.handleChangePlaces}
                      onSelect={this.handleSelect}
                      searchOptions={{ componentRestrictions: { country: [CommonConfig.userCountryData().country_code] } }}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: "Find your workpod?",
                              className: "location-search-input",
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                                : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>
                                    <i className="fas fa-map-marker-alt"></i>
                                    {suggestion.description}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>
                <div className="place-info">
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom-end"
                    rootClose={true}
                    overlay={
                      <Popover>
                        <div className="date-selection">
                          <div className="date-select-box">
                            {dayTypeList.map((x) => {
                              return (
                                <button
                                  className={
                                    this.state.day === x.StringMapName
                                      ? "active"
                                      : null
                                  }
                                  onClick={() =>
                                    this.handleChange(x.StringMapName, "day")
                                  }
                                >
                                  {x.StringMapName}
                                </button>
                              );
                            })}
                          </div>
                        </div>
                        <Calendar
                          onChange={(item) =>
                            this.handleChange(item, "singleDate")
                          }
                          date={this.state.singleDate}
                          minDate={new Date()}
                        />
                      </Popover>
                    }
                  >
                    <div className="ms-input">
                      <i className="far fa-calendar-alt"></i>
                      <input
                        ref={datepick => this.datepick = datepick}
                        type="text"
                        placeholder="Date"
                        readOnly
                        onClick={() => this.handleClickOpenCalendar()}
                        value={
                          this.state.singleDate
                            ? moment(this.state.singleDate).format("DD/MM/YYYY")
                            : ""
                        }
                      />
                    </div>
                  </OverlayTrigger>
                </div>
                <div className="place-info multi">
                  <div className="ms-input select-icn">
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      placeholder={"Time Slot"}
                      value={this.state.Time}
                      isMulti
                      onChange={(e) => this.handleChange(e, "Time")}
                      options={timeSlotListArray}
                    />
                  </div>
                </div>
                <div className="place-info">
                  <div className="ms-input select-icn">
                    <i className="far fa-user"></i>
                    <select
                      onChange={(e) => this.handleChange(e, "Users")}
                      value={this.state.Users}
                    >
                      {/* <option selected disabled hidden value=''>Users</option> */}
                      {noOfUsersList.map((x, idx) => {
                        return (
                          <option value={x.StringMapName} key={idx}>
                            {x.StringMapName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="button-tooltip">More Filters</Tooltip>}
                >
                  {({ ref, ...triggerHandler }) => (
                    <div className="more-filter-toggle" {...triggerHandler}>
                      <a
                        href="javascript:;"
                        ref={ref}
                        className="mf-click"
                        onClick={() => {
                          this.setState({ toggleEvent: Date.now() });
                        }}
                      >
                        <i className="fas fa-sliders-h"></i>
                      </a>
                    </div>
                  )}
                </OverlayTrigger>
                <div className="filter-search">
                  <button
                    className="btn-fill"
                    onClick={() => this.getSearchListing()}
                  >
                    Available Pods
                  </button>
                </div>
              </div>
            ) : (
              <div className="innerpage-filter-wrap">
                <div className="place">
                  <div className="ms-input">
                    <i className="fas fa-search"></i>
                    <PlacesAutocomplete
                      value={this.state.placeName}
                      onChange={this.handleChangePlaces}
                      onSelect={this.handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: "Where to go?",
                              className: "location-search-input",
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                                : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>

                <div className="place-info">
                  <div className="ms-input">
                    <i className="far fa-calendar-alt"></i>
                    <OverlayTrigger
                      trigger="click"
                      placement="bottom-end"
                      rootClose={true}
                      overlay={
                        <Popover>
                          <div className="date-selection">
                            <div className="date-select-box">
                              {dayTypeList.map((x) => {
                                return (
                                  <button
                                    className={
                                      this.state.day === x.StringMapName
                                        ? "active"
                                        : null
                                    }
                                    onClick={() =>
                                      this.handleChange(x.StringMapName, "day")
                                    }
                                  >
                                    {x.StringMapName}
                                  </button>
                                );
                              })}
                            </div>
                          </div>
                          <DateRangePicker

                            onChange={(item) =>
                              this.handleChange([item.selection], "date")
                            }
                            showSelectionPreview={true}
                            showMonthAndYearPickers={false}
                            showDateDisplay={false}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={this.state.selectedDateRange}
                            minDate={new Date()}
                            direction="horizontal"
                          />
                        </Popover>
                      }
                    >
                      <input
                        ref={datepickrange => this.datepickrange = datepickrange}
                        type="text"
                        placeholder="Date"
                        readOnly
                        onClick={() => this.handleClickOpenCalendar()}
                        value={
                          this.state.selectedDateRange.length > 0 &&
                            Date.parse(
                              this.state.selectedDateRange[0].startDate
                            ) !=
                            Date.parse(this.state.selectedDateRange[0].endDate)
                            ? moment(
                              this.state.selectedDateRange[0].startDate
                            ).format("DD/MM/YYYY") +
                            "-" +
                            moment(
                              this.state.selectedDateRange[0].endDate
                            ).format("DD/MM/YYYY")
                            : ""
                        }
                      />
                    </OverlayTrigger>
                  </div>
                </div>
                <div className="place-info multi-date-sel">
                  <div className="ms-input select-icn">
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      placeholder={"Arrival Time"}
                      value={this.state.ArriveTime}
                      isMulti
                      onChange={(e) => this.handleChange(e, "ArriveTime")}
                      options={timeSlotListArray}
                    />
                  </div>
                </div>
                <div className="place-info multi-date-sel">
                  <div className="ms-input select-icn">
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      placeholder={"Departure Time"}
                      value={this.state.DepartTime}
                      isMulti
                      onChange={(e) => this.handleChange(e, "DepartTime")}
                      options={timeSlotListArray}
                    />
                  </div>
                </div>
                <div className="place-info">
                  <div className="ms-input select-icn">
                    <i className="far fa-user"></i>
                    <select
                      onChange={(e) => this.handleChange(e, "Users")}
                      value={this.state.Users}
                    >
                      <option selected disabled hidden value="">
                        Users
                      </option>
                      {noOfUsersList.map((x, idx) => {
                        return (
                          <option value={x.StringMapName} key={idx}>
                            {x.StringMapName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="more-filter-toggle">
                  <a
                    href="javascript:;"
                    className="mf-click"
                    data-toggle="tooltip"
                    title="More Filters"
                    onClick={() => this.setState({ toggleEvent: Date.now() })}
                  >
                    <i className="fas fa-sliders-h"></i>
                  </a>
                </div>
                <div className="filter-search">
                  <button
                    className="btn-fill"
                    onClick={() => this.getSearchListing()}
                  >
                    Available Pods
                  </button>
                </div>
              </div>
            )}
            <SlideToggle toggleEvent={this.state.toggleEvent} collapsed>
              {({ setCollapsibleElement }) => (
                <div
                  className="innerpage-advance-filter"
                  ref={setCollapsibleElement}
                >
                  <div className="advance-filter-wrap">
                    <div className="filter-wrap">
                      <div className="row align-items-center">
                        <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                          <div className="advance-filter-inner">
                            <strong>Amenities</strong>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                          <div className="advance-filter-checkbox">
                            {/* <div className="filter-check">
                                                            <label><input type="checkbox" />
                                                                <span>
                                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style={{ enableBackground: "new 0 0 512 512" }} space="preserve"> <g> <g> <path d="M329.956,399.834H182.044c-9.425,0-17.067,7.641-17.067,17.067s7.641,17.067,17.067,17.067h147.911 c9.425,0,17.067-7.641,17.067-17.067S339.381,399.834,329.956,399.834z" /> </g> </g> <g> <g> <path d="M329.956,346.006H182.044c-9.425,0-17.067,7.641-17.067,17.067s7.641,17.067,17.067,17.067h147.911 c9.425,0,17.067-7.641,17.067-17.067S339.381,346.006,329.956,346.006z" /> </g> </g> <g> <g> <path d="M472.178,133.907h-54.303V35.132c0-9.425-7.641-17.067-17.067-17.067H111.192c-9.425,0-17.067,7.641-17.067,17.067v98.775 H39.822C17.864,133.907,0,151.772,0,173.73v171.702c0,21.958,17.864,39.822,39.822,39.822h54.306v91.614 c0,9.425,7.641,17.067,17.067,17.067h289.61c9.425,0,17.067-7.641,17.067-17.067v-91.614h54.306 c21.958,0,39.822-17.864,39.822-39.822V173.73C512,151.773,494.136,133.907,472.178,133.907z M128.258,52.199h255.483v81.708 H128.258V52.199z M383.738,459.801H128.262c0-3.335,0-135.503,0-139.628h255.477C383.738,324.402,383.738,456.594,383.738,459.801 z M400.808,234.122h-43.443c-9.425,0-17.067-7.641-17.067-17.067s7.641-17.067,17.067-17.067h43.443 c9.425,0,17.067,7.641,17.067,17.067S410.234,234.122,400.808,234.122z" /> </g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>
                                                                    Printer
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="filter-check">
                                                            <label><input type="checkbox" />
                                                                <span>
                                                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="548.172px" height="548.172px" viewBox="0 0 548.172 548.172" style={{ enableBackground: "new 0 0 548.172 548.172" }} space="preserve"> <g> <path d="M534.75,49.965c-8.945-8.945-19.694-13.422-32.261-13.422H45.681c-12.562,0-23.313,4.477-32.264,13.422 C4.471,58.913,0,69.663,0,82.226v310.633c0,12.566,4.471,23.315,13.417,32.265c8.951,8.945,19.702,13.414,32.264,13.414h155.318 c0,7.231-1.524,14.661-4.57,22.269c-3.044,7.614-6.09,14.273-9.136,19.981c-3.042,5.715-4.565,9.897-4.565,12.56 c0,4.948,1.807,9.24,5.424,12.847c3.615,3.621,7.898,5.435,12.847,5.435h146.179c4.949,0,9.233-1.813,12.848-5.435 c3.62-3.606,5.427-7.898,5.427-12.847c0-2.468-1.526-6.611-4.571-12.415c-3.046-5.801-6.092-12.566-9.134-20.267 c-3.046-7.71-4.569-15.085-4.569-22.128h155.318c12.56,0,23.309-4.469,32.254-13.414c8.949-8.949,13.422-19.698,13.422-32.265 V82.226C548.176,69.663,543.699,58.913,534.75,49.965z M511.627,319.768c0,2.475-0.903,4.613-2.711,6.424 c-1.81,1.804-3.952,2.707-6.427,2.707H45.681c-2.473,0-4.615-0.903-6.423-2.707c-1.807-1.817-2.712-3.949-2.712-6.424V82.226 c0-2.475,0.902-4.615,2.712-6.423c1.809-1.805,3.951-2.712,6.423-2.712h456.815c2.471,0,4.617,0.904,6.42,2.712 c1.808,1.809,2.711,3.949,2.711,6.423V319.768L511.627,319.768z" /> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>
                                                                    Desktop
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="filter-check">
                                                            <label><input type="checkbox" />
                                                                <span>
                                                                    <svg id="Capa_1" enable-background="new 0 0 458.706 458.706" height="512" viewBox="0 0 458.706 458.706" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m229.353 163.824h-32.765v65.529h32.765c18.062 0 32.765-14.703 32.765-32.765s-14.703-32.764-32.765-32.764z" /><path d="m425.941 0h-393.176c-18.094 0-32.765 14.671-32.765 32.765v393.176c0 18.094 14.671 32.765 32.765 32.765h393.176c18.094 0 32.765-14.671 32.765-32.765v-393.176c0-18.094-14.671-32.765-32.765-32.765zm-196.588 294.882h-32.765v65.529h-65.529v-262.117h98.294c54.203 0 98.294 44.091 98.294 98.294s-44.091 98.294-98.294 98.294z" /></svg>
                                                                    Free Parking
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="filter-check">
                                                            <label><input type="checkbox" />
                                                                <span>
                                                                    <svg height="512" viewBox="0 0 192 192" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m16 184a8 8 0 0 0 8 8h144a8 8 0 0 0 8-8v-16h-160z" /><path d="m168 0h-144a8 8 0 0 0 -8 8v152h160v-152a8 8 0 0 0 -8-8zm-88 16a8 8 0 1 1 -8 8 8 8 0 0 1 8-8zm-40 8a8 8 0 1 1 8 8 8 8 0 0 1 -8-8zm56 120a48 48 0 1 1 48-48 48.054 48.054 0 0 1 -48 48zm56-112h-32v-16h32z" /><path d="m121.6 76.821a32 32 0 1 0 0 38.358 23.96 23.96 0 0 1 0-38.358z" /></svg>
                                                            Laundry
                                                        </span>
                                                            </label>
                                                        </div>
                                                        <div className="filter-check">
                                                            <label><input type="checkbox" />
                                                                <span>
                                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style={{ enableBackground: "new 0 0 512 512" }} space="preserve"> <g> <g> <path d="M447.117,66.034H64.883C29.106,66.034,0,95.141,0,130.917v212.327c0,35.776,29.106,64.883,64.883,64.883h2.395v20.733 c0,9.447,7.658,17.105,17.105,17.105s17.105-7.658,17.105-17.105v-20.734h309.025v20.733c0,9.447,7.658,17.105,17.105,17.105 s17.105-7.658,17.105-17.105v-20.733h2.395c35.776,0,64.883-29.107,64.883-64.883V130.917 C512,95.141,482.893,66.034,447.117,66.034z M388.276,310.087c0,16.75-13.579,30.328-30.328,30.328H97.089 c-16.75,0-30.328-13.579-30.328-30.328V164.075c0-16.75,13.579-30.328,30.328-30.328h260.858c16.75,0,30.328,13.579,30.328,30.328 V310.087z M451.474,310.648c-14.107,0-25.544-11.437-25.544-25.544c0-14.107,11.437-25.544,25.544-25.544 s25.544,11.437,25.544,25.544C477.018,299.213,465.581,310.648,451.474,310.648z M451.474,214.602 c-14.107,0-25.544-11.437-25.544-25.544c0-14.108,11.437-25.544,25.544-25.544s25.544,11.437,25.544,25.544 C477.018,203.165,465.581,214.602,451.474,214.602z" /> </g> </g> <g> <g> <path d="M330.121,171.953c-9.447,0-17.105,7.658-17.105,17.105v96.046c0,9.447,7.658,17.105,17.105,17.105 c9.447,0,17.105-7.658,17.105-17.105v-96.046C347.225,179.611,339.568,171.953,330.121,171.953z" /> </g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>
                                                            Microwave
                                                        </span>
                                                            </label>
                                                        </div>
                                                        <div className="filter-check">
                                                            <label><input type="checkbox" />
                                                                <span>
                                                                    <svg height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm129.75 201.75-138.667969 138.664062c-4.160156 4.160157-9.621093 6.253907-15.082031 6.253907s-10.921875-2.09375-15.082031-6.253907l-69.332031-69.332031c-8.34375-8.339843-8.34375-21.824219 0-30.164062 8.339843-8.34375 21.820312-8.34375 30.164062 0l54.25 54.25 123.585938-123.582031c8.339843-8.34375 21.820312-8.34375 30.164062 0 8.339844 8.339843 8.339844 21.820312 0 30.164062zm0 0" /></svg>
                                                                        Verified Pods</span></label>
                                                        </div>
                                                        <div className="filter-check">
                                                            <label><input type="checkbox" />
                                                                <span>
                                                                    <svg id="Capa_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m467 46h-422c-24.814 0-45 20.186-45 45v150c0 24.814 20.186 45 45 45h422c24.814 0 45-20.186 45-45v-150c0-24.814-20.186-45-45-45zm-90 90c8.284 0 15 6.716 15 15s-6.716 15-15 15-15-6.716-15-15 6.716-15 15-15zm60 90h-362c-8.291 0-15-6.709-15-15s6.709-15 15-15h362c8.291 0 15 6.709 15 15s-6.709 15-15 15zm0-60c-8.284 0-15-6.716-15-15s6.716-15 15-15 15 6.716 15 15-6.716 15-15 15z" /><path d="m136 316c-8.291 0-15 6.709-15 15v22.925c0 35.654-19.819 67.705-51.709 83.657-7.412 3.706-10.415 12.715-6.709 20.127 3.688 7.354 12.64 10.426 20.127 6.709 42.129-21.064 68.291-63.398 68.291-110.493v-22.925c0-8.291-6.709-15-15-15z" /><path d="m376 316c-8.291 0-15 6.709-15 15v22.925c0 47.095 26.162 89.429 68.291 110.493 7.48 3.713 16.436.652 20.127-6.709 3.706-7.412.703-16.421-6.709-20.127-31.89-15.952-51.709-48.003-51.709-83.657v-22.925c0-8.291-6.709-15-15-15z" /><path d="m271 451v-120c0-8.291-6.709-15-15-15s-15 6.709-15 15v120c0 8.291 6.709 15 15 15s15-6.709 15-15z" /><path d="m331 451v-120c0-8.291-6.709-15-15-15s-15 6.709-15 15v120c0 8.291 6.709 15 15 15s15-6.709 15-15z" /><path d="m211 451v-120c0-8.291-6.709-15-15-15s-15 6.709-15 15v120c0 8.291 6.709 15 15 15s15-6.709 15-15z" /></g></svg>
                                                        Air Conditioning
                                                        </span>
                                                            </label>
                                                        </div>
                                                         */}
                            {amenitiesList
                              .slice(0, amenitiesList.length - 3)
                              .map((content, idx) => {
                                return (
                                  <div className="filter-check" key={idx}>
                                    <label>
                                      <input
                                        type="checkbox"
                                        onClick={(e) =>
                                          this.handleChange(
                                            content,
                                            "Amenities"
                                          )
                                        }
                                        checked={
                                          this.state.Amenities.filter(
                                            (x) => x == content.FeatureID
                                          ).length > 0
                                            ? true
                                            : false
                                        }
                                      />
                                      <span>
                                        {content.FeatureIconURL
                                          ? parse(content.FeatureIconURL)
                                          : ""}
                                        {content.FeatureName}
                                      </span>
                                    </label>
                                  </div>
                                );
                              })}

                            <a
                              href="javascript:;"
                              className="btn btn-primary advance-filter-btn"
                              onClick={() =>
                                this.setState({
                                  toggleAdvanceEvent: Date.now(),
                                })
                              }
                            >
                              <i className="fas fa-plus"></i>
                            </a>
                          </div>
                          <SlideToggle
                            toggleEvent={this.state.toggleAdvanceEvent}
                            collapsed
                          >
                            {({ setCollapsibleElement }) => (
                              <div
                                className="filter-wrap advance-filter-wrap-hidden"
                                style={{ display: "none" }}
                                ref={setCollapsibleElement}
                              >
                                <div className="row align-items-center">
                                  {/* <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                                                            <div className="advance-filter-inner">
                                                                <strong>Amenities</strong>
                                                            </div>
                                                        </div> */}
                                  <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                                    <div className="advance-filter-checkbox">
                                      {/* <div className="filter-check">
                                                                                <label><input type="checkbox" />
                                                                                    <span>
                                                                                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style={{ enableBackground: "new 0 0 512 512" }} space="preserve"> <g> <g> <path d="M489.771,234.328H344.304l-26.261-41.989h101.139c12.257,0,22.384-10.564,22.384-22.822v-34.779 c0-12.258-10.129-22.314-22.384-22.314H92.818c-12.255,0-22.384,10.056-22.384,22.314v34.781 c0,12.257,10.129,22.821,22.384,22.821h101.139l-26.261,41.989H22.229C9.972,234.328,0,244.045,0,256.303v34.781 c0,12.257,9.972,21.805,22.229,21.805h2.152v66.37c0,11.221,9.097,20.317,20.317,20.317s20.317-9.097,20.317-20.317v-66.37h53.52 l-31.065,50.283c-5.916,9.536-2.981,22.166,6.553,28.081c3.335,2.07,7.037,3.109,10.692,3.109c6.797,0,13.437-3.594,17.283-9.793 l44.357-71.68h179.288l44.357,71.706c3.847,6.2,10.487,9.714,17.283,9.714c3.656,0,7.358-0.933,10.692-3.003 c9.534-5.915,12.469-18.626,6.553-28.161l-31.067-50.256h53.52v66.37c0,11.221,9.097,20.317,20.317,20.317 s20.317-9.097,20.317-20.317v-66.37h2.152c12.257,0,22.229-9.548,22.229-21.806v-34.781 C512,244.045,502.028,234.328,489.771,234.328z M215.518,234.328l26.26-41.989h28.444l26.261,41.989H215.518z" /> </g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>
                                                                    Barbecue Area
                                                                </span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="filter-check">
                                                                                <label><input type="checkbox" />
                                                                                    <span>
                                                                                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 491.975 491.975" style={{ enableBackground: "new 0 0 491.975 491.975" }} > <g id="XMLID_28_"> <path id="XMLID_31_" d="M225.315,96.963c26.748,0,48.479-21.706,48.479-48.481C273.794,21.699,252.063,0,225.315,0 c-26.779,0-48.492,21.699-48.492,48.482C176.823,75.257,198.536,96.963,225.315,96.963z" /> <path id="XMLID_30_" d="M300.233,371.688c-12.883,44.732-54.121,77.583-102.946,77.583c-59.126,0-107.209-48.085-107.209-107.193 c0-43.754,26.396-81.413,64.066-98.054V198.58c-61.69,18.581-106.764,75.847-106.764,143.498 c0,82.649,67.247,149.897,149.906,149.897c60.238,0,112.159-35.801,135.966-87.169l-16.926-33.255 C311.575,371.59,306.071,371.64,300.233,371.688z" /> <path id="XMLID_29_" d="M441.48,429.237l-64.939-127.672c-4.847-9.553-14.645-15.566-25.363-15.566h-83.173v-18.966h71.582 c7.148,0,13.156-3.736,17.037-9.118c2.522-3.506,4.316-7.579,4.316-12.236c0-11.789-9.549-21.351-21.353-21.351h-43.125h-28.457 V160c0-17.692-13.118-42.704-42.689-42.704c-23.584,0-42.703,19.122-42.703,42.704v139.372c0,24.058,19.503,43.561,43.562,43.561 h78.265h29.284l57.024,112.117c5.011,9.875,15.011,15.573,25.389,15.573c4.35,0,8.761-0.994,12.883-3.104 C443.054,460.401,448.628,443.251,441.48,429.237z" /> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>
                                                                        Disability
                                                                    </span></label>
                                                                            </div>
                                                                            <div className="filter-check">
                                                                                <label><input type="checkbox" />
                                                                                    <span>
                                                                                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 62.141 62.141" style={{ enableBackground: "new 0 0 62.141 62.141" }}> <g> <g> <circle style={{ fill: '#010002' }} cx="25.496" cy="5.667" r="5.667" /> <path d="M46.002,30.756c-0.042-0.786-0.941-2.689-1.143-3.081c-2.305-4.489-4.717-8.929-7.265-13.287 c-3.857-6.601-14.113-0.606-10.239,6.016c0.113,0.193,0.222,0.39,0.335,0.586c-2.353,3.001-5.896,4.117-10.311,3.655 c-2.712-0.284-3.355,2.835-1.984,4.549v32.947h1.985V30.254c5.359,0.561,9.869-0.983,13.177-4.194 c1.348,2.448,2.662,4.915,3.94,7.396l-8.385,8.023c-1.104,1.056-1.549,2.627-1.168,4.104c1.026,3.974,3.021,11.708,3.563,13.852 c1.097,4.346,7.97,3.104,6.869-1.263c-1.028-4.079-2.504-8.777-3.536-12.854c-0.013-0.049-0.029-0.091-0.043-0.138 c0.043,0.138,7.621-6.645,7.621-6.645c0.352,6.797,0.697,14.014,1.045,20.304c0.253,4.495,7.226,4.145,6.974-0.362 C46.959,49.816,46.481,39.412,46.002,30.756z" /> </g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>
                                                                        Mobility Needs
                                                                    </span></label>
                                                                            </div>
                                                                             */}
                                      {/* <div className="filter-check">
                                                                    <label><input type="checkbox" /> <span>Ratings</span></label>
                                                                </div> */}
                                      {amenitiesList
                                        .slice(
                                          Math.max(amenitiesList.length - 3, 1)
                                        )
                                        .map((content, idx) => {
                                          return (
                                            <div
                                              className="filter-check"
                                              key={idx}
                                            >
                                              <label>
                                                <input
                                                  type="checkbox"
                                                  onClick={(e) =>
                                                    this.handleChange(
                                                      content,
                                                      "Amenities"
                                                    )
                                                  }
                                                  checked={
                                                    this.state.Amenities.filter(
                                                      (x) =>
                                                        x == content.FeatureID
                                                    ).length > 0
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                <span>
                                                  {content.FeatureIconURL
                                                    ? parse(
                                                      content.FeatureIconURL
                                                    )
                                                    : ""}
                                                  {content.FeatureName}
                                                </span>
                                              </label>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </SlideToggle>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </SlideToggle>
          </div>
        </section>

        {!IsGridViewSelected ? (
          <section className="main-listing-outer bg-light-gray">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-7 col-md-7 listing-scroll">
                  <div className="listing-header">
                    <p>{podList.length} Pods</p>
                    <div className="listing-sort">
                      <label>Sort By:</label>
                      <select
                        className="form-control"
                        onChange={(e) => this.handleChange(e, "SortBy")}
                        value={this.state.sortBy}
                      >
                        <option value="High">Price: High to low</option>
                        <option value="Low">Price: Low to high</option>
                        <option value="Popularity">Popularity</option>
                        <option value="Rating">Rating</option>
                        <option value="Nearby">Near by</option>
                      </select>
                    </div>
                  </div>
                  <div className="listing-main">
                    <div className="covid-info">
                      <p>
                        {parse(t('PODRegistration:Step0.CovidAlert'))}
                      </p>
                    </div>
                    {podList.length > 0 ? podList.map((x, idx) => {
                      return (
                        <div
                          className="listing-main-wrap"
                          key={idx}
                          id={idx}
                          onMouseEnter={() => {
                            var podListMap = this.state.podListMap;
                            var podListMapIndex = podListMap.findIndex(
                              (content) => content.PropertyID == x.PropertyID
                            );
                            podListMap[podListMapIndex][
                              "IsHoveredAddress"
                            ] = true;
                            this.setState({ podListMap: podListMap });
                          }}
                          onMouseLeave={() => {
                            var podListMap = this.state.podListMap;
                            var podListMapIndex = podListMap.findIndex(
                              (content) => content.PropertyID == x.PropertyID
                            );
                            podListMap[podListMapIndex][
                              "IsHoveredAddress"
                            ] = false;
                            this.setState({ podListMap: podListMap });
                          }}
                        >
                          <div className="listing-media">
                            <div className="lm-slider">
                              <Swiper
                                navigation
                                pagination={{
                                  clickable: true,
                                  dynamicBullets: true,
                                }}
                              >
                                {x.PropertyMedia.filter(item => item.MediaType.split("/")[0] === 'image').map((content) => {
                                  return (
                                    <SwiperSlide>
                                        <img src={apiBase + content.MediaURL} alt="" height="200px"
                                          width="310px" /> 
                                         {/* <video controls height="200px" width="310px"><source src={apiBase + content.MediaURL} type={content.MediaType} /></video>  */}
                                    </SwiperSlide>
                                  );
                                })}
                              </Swiper>
                            </div>
                            {this.state.podList[idx].StudentDiscount ? (
                              <span className="student-discount-text">
                                Student Discount
                              </span>
                            ) : null}
                          </div>
                          <div className="listing-body">
                            <a
                              href="javascript:;"
                              className={
                                x.UserPropertyFavoriteID == null
                                  ? "item-favorite"
                                  : "item-favorite active"
                              }
                              onClick={(e) =>
                                this.handleFavModal(
                                  e,
                                  x,
                                  x.UserPropertyFavoriteID == null
                                    ? "Favorite"
                                    : "UnFavorite"
                                )
                              }
                            >
                              <i className="fas fa-heart"></i>
                            </a>
                            <a href="javascript:;" className="item-report"
                              onClick={() => {
                                this.setState({
                                  showReportModal: true
                                })
                              }}
                            >
                              <i class="fas fa-flag"></i>
                            </a>
                            <div
                              className="listing-item-title"
                              onClick={() => this.detailspage(x.PropertyID)}
                            >
                              <a href="javascript:;">{x.PropertyName}</a>
                              <span className="listing-item-address">
                                {x.PropertyAddress}
                              </span>
                            </div>
                            <div
                              className="fpb-amenities listing-item-amenities"
                              onClick={() => this.detailspage(x.PropertyID)}
                            >
                              <ul>
                                {x.Features.map((content) => {
                                  return (
                                    <li>
                                      {/* {content.FeatureIconURL ? parse(content.FeatureIconURL) : ''} */}
                                      {content.FeatureName}
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                            <div className="fpb-slots">
                              <p>Available Slots</p>
                              <div className="slots-inner">
                                {x.Slot
                                  ? x.Slot.split(",").map((content, indx) => {
                                    var SlotPrice = x.SlotPrice.split(",");
                                    return (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip id="button-tooltip-price">
                                            {x.CurrencySymbol ? parse(x.CurrencySymbol) : ''} {SlotPrice[indx]}
                                          </Tooltip>
                                        }
                                      >
                                        {({ ref, ...triggerHandler }) => (
                                          <a
                                            href="javascript:;"
                                            onClick={() => {
                                              this.props.history.push({
                                                pathname: "/admin/detailpage",
                                                state: {
                                                  PropertyID: x.PropertyID,
                                                  selectedDateRange: this
                                                    .state.selectedDateRange,
                                                  day: this.state.day,
                                                  ArriveTime:
                                                    this.state.day ==
                                                      "Multi Day"
                                                      ? timeSlotListArray.filter(
                                                        (a) =>
                                                          a.label ===
                                                          content
                                                      )
                                                      : this.state.ArriveTime,
                                                  DepartTime: this.state
                                                    .DepartTime,
                                                  Time:
                                                    this.state.day ==
                                                      "Single Day"
                                                      ? timeSlotListArray.filter(
                                                        (a) =>
                                                          a.label ===
                                                          content
                                                      )
                                                      : this.state.Time,
                                                  singleDate: this.state
                                                    .singleDate,
                                                  Users: this.state.Users,
                                                  latitude: this.state
                                                    .latitude,
                                                  longitude: this.state
                                                    .longitude,
                                                },
                                              });
                                            }}
                                            {...triggerHandler}
                                            ref={ref}
                                            className="slot-text"
                                            key={indx}
                                          >
                                            {content}
                                          </a>

                                          // <div className="more-filter-toggle" >
                                          //     <a href="javascript:;" ref={ref} className="mf-click" onClick={() => {
                                          //         this.setState({ toggleEvent: Date.now() })
                                          //     }
                                          //     }><i className="fas fa-sliders-h"></i></a>
                                          // </div>
                                        )}
                                      </OverlayTrigger>
                                    );
                                  })
                                  : null}
                              </div>
                            </div>
                            <div>
                              {/* <Button
                                  variant="outline-danger"
                                  onClick={() => {
                                    this.setState({
                                        showReportModal: true
                                    })
                                  }}
                                >
                                  Report
                                </Button> */}
                            </div>
                            <Modal
                              show={this.state.showReportModal}
                              onHide={() =>
                                this.setState({
                                  showReportModal: false,
                                  Name: "",
                                  NameErr: false,
                                  NamehelperText: "",
                                  EmailAddress: "",
                                  EmailAddressErr: false,
                                  EmailAddresshelperText: "",
                                  Message: "",
                                  MessageErr: false,
                                  MessagehelperText: "",
                                })
                              }
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Report this Pod</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                {/* <p className="contact-text">
                                    The Work Pod’s customer services are happy
                                    to help if you have any website feedback,
                                    testimonials or complaints about the
                                    booking/payment process. Please allow us
                                    some time to get back to you.
                                  </p> */}
                                <div className="form-group">
                                  <label>Name *</label>
                                  <input
                                    type="text"
                                    className={
                                      this.state.NameErr
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    placeholder="E.g. Clara Smith"
                                    value={this.state.Name}
                                    onChange={(e) =>
                                      this.handleChange(e, "Name")
                                    }
                                  />
                                  <div className="invalid-feedback">
                                    {this.state.NamehelperText}
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label>Email address *</label>
                                  <input
                                    type="text"
                                    className={
                                      this.state.EmailAddressErr
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    placeholder="E.g. abac@gmail.com"
                                    value={this.state.EmailAddress}
                                    onChange={(e) =>
                                      this.handleChange(e, "EmailAddress")
                                    }
                                  />
                                  <div className="invalid-feedback">
                                    {this.state.EmailAddresshelperText}
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label>Report Message *</label>
                                  <textarea
                                    placeholder="Your message"
                                    className={
                                      this.state.MessageErr
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    value={this.state.Message}
                                    onChange={(e) =>
                                      this.handleChange(e, "Message")
                                    }
                                  ></textarea>
                                  <div className="invalid-feedback">
                                    {this.state.MessagehelperText}
                                  </div>
                                </div>
                                <div className="pr-text">
                                  <p>
                                    The information you provided is only used
                                    to respond to your request. The data
                                    controller is The WorkPod who acts in
                                    compliance with applicable laws. You can
                                      read our entire{" "}
                                    <a
                                      href="/admin/privacypolicy"
                                      target="_blank"
                                    >
                                      Privacy Policy
                                      </a>
                                      .
                                    </p>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={() =>
                                    this.setState({
                                      showReportModal: false,
                                      Name: "",
                                      NameErr: false,
                                      NamehelperText: "",
                                      EmailAddress: "",
                                      EmailAddressErr: false,
                                      EmailAddresshelperText: "",
                                      Message: "",
                                      MessageErr: false,
                                      MessagehelperText: "",
                                    })
                                  }
                                >
                                  Cancel
                                  </Button>
                                <Button
                                  variant="primary"
                                  onClick={(e) => this.submitReport(e, x.PropertyID)}
                                >
                                  Submit
                                  </Button>
                              </Modal.Footer>
                            </Modal>


                            <div
                              className="listing-item-footer"
                              onClick={() => this.detailspage(x.PropertyID)}
                            >
                              {/* <div className="listing-item-rating">
                                                                <div className="fpb-star">
                                                                    <i className="fas fa-star"></i>
                                                                    {" " + x.Rate + " "}
                                                                    <span>{x.ReviewRatingType}</span>
                                                                </div>
                                                            </div> */}

                              <div className="listing-price">
                                <span className="fpb-price">
                                  <small>Starting from</small> <sup>
                                    {x.CurrencySymbol ? parse(x.CurrencySymbol) : ''}
                                    {/* {parse(x.CurrencySymbol)} */}
                                  </sup>
                                  {x.Price}
                                  <sub></sub>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                      :
                      <div className="no-pod-available">
                        <p>Unfortunately, there are no pods available within your parameters; would you like to try a <a href="/admin/homepage">different date or location</a>?</p>
                      </div>
                    }
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 map-fixed">
                  <StickyBox offsetTop={20} offsetBottom={20}>
                    <div className="map-outer">
                      {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d317718.69319292053!2d-0.38177650508630834!3d51.528307984912544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2sLondon%2C%20UK!5e0!3m2!1sen!2sin!4v1614246979234!5m2!1sen!2sin" style={{ border: '0' }} allowfullscreen="" loading="lazy"></iframe> */}

                      <GoogleMapReact
                        ref={(mapk) => (this.mapk = mapk)}
                        bootstrapURLKeys={{
                          key: "AIzaSyDiKc4HxX5G7EfneIZBN_Hlk2_luoT_yvo",
                          language: "en",
                          region: "US",
                        }}

                        center={{ lat: latitude, lng: longitude }}
                        // center={{ lat: '50.736129', lng: '-1.988229' }}
                        // center={{ lat:'23.63936', lng : '68.14712'}}
                        zoom={this.state.zoom}
                        options={{
                          gestureHandling: "greedy",
                          loading: "lazy"
                        }}
                        distanceToMouse={() => this.distanceToMouse()}
                        onZoomAnimationStart={(e) => this.setState({ zoom: e })}
                      >
                        {podListMap.map((x, idx) => {
                          return (

                            <OverlayTrigger
                              key={idx}
                              lat={x.Latitude}
                              lng={x.Longitude}
                              trigger="click"
                              placement="top"
                              rootClose={true}
                              overlay={
                                <Popover>
                                  <div className="featured-pod-box with-map">
                                    <div className="fpb-image">
                                      <div className="lm-slider">
                                        <Swiper
                                          navigation
                                          pagination={{
                                            clickable: true,
                                            dynamicBullets: true,
                                          }}
                                        >
                                          {x.PropertyMedia.filter(item => item.MediaType.split("/")[0] === 'image').map((content) => {
                                            return (
                                              <SwiperSlide>
                                                <img
                                                  src={apiBase + content.MediaURL}
                                                  alt=""
                                                  height="200px"
                                                  width="310px"
                                                />
                                              </SwiperSlide>
                                            );
                                          })}

                                        </Swiper>
                                      </div>
                                    </div>
                                    <div
                                      className="fpb-content"
                                      onClick={() => this.detailspage(x.PropertyID)}
                                    >
                                      <a href="javacript:;">{x.PropertyName}</a>
                                      <p>{x.PropertyAddress}</p>
                                      <div className="fpb-amenities">
                                        <ul>
                                          {x.Features.map((content) => {
                                            return (
                                              <li>
                                                {content.FeatureName}
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </div>

                                      <div className="pod-grid-footer">
                                        <div className="listing-price">
                                          <span className="fpb-price">
                                            <sup>{x.CurrencySymbol ? parse(x.CurrencySymbol) : ''}</sup>
                                            {x.Price}
                                            <sub></sub>
                                          </span>
                                        </div>

                                      </div>
                                    </div>
                                  </div>

                                </Popover>
                              }
                            >
                              <div
                                className="listing-price-tag"
                                style={
                                  x.IsHoveredAddress &&
                                    x.IsHoveredAddress == true
                                    ? {
                                      backgroundColor: "black",
                                      color: "white",
                                      zIndex: "2",
                                    }
                                    : null
                                }
                                onClick={() => {
                                  window.scrollTo(
                                    0,
                                    this.findPosition(
                                      document.getElementById(idx)
                                    ) - 110
                                  );
                                  this.setState({
                                    showingInfoWindow: true,
                                    latitude: x.latitude,
                                    longitude: x.longitude,
                                    zoom: 15,
                                  });
                                }}
                              >
                                <span
                                  className="listing-price-tag-text"
                                  title={x.PropertyName}
                                >
                                  {x.CurrencySymbol ? parse(x.CurrencySymbol) : ''}{x.Price}
                                </span>
                              </div>
                            </OverlayTrigger>

                          );
                        })}
                      </GoogleMapReact>

                      {/* <Map google={this.props.google}
                                                gestureHandling='greedy'
                                                loading='lazy'
                                                center={{ lat: latitude, lng: longitude }} zoom={this.state.zoom}>

                                                {podListMap.map((x, idx) => {
                                                    return (
                                                        <Marker onClick={(props, marker, e) => {
                                                            this.setState({
                                                                selectedPlace: props,
                                                                activeMarker: marker,
                                                                showingInfoWindow: true
                                                            })
                                                        }}
                                                            name={'Current location'} position={{ lat: x.Latitude, lng: x.Longitude }}>
                                                            <div className="circle" style={x.IsHoveredAddress && x.IsHoveredAddress == true ? { backgroundColor: "black", color: "white", zIndex: "2" } : null} onClick={() => this.setState({ latitude: x.latitude, longitude: x.longitude, zoom: 15 })}>
                                                                <span className="circleText" title={x.PropertyName}>
                                                                    &#163;{x.Price}
                                                                </span>
                                                            </div>
                                                        </Marker>
                                                    );
                                                })}
                                                <InfoWindow
                                                    marker={this.state.activeMarker}
                                                    visible={this.state.showingInfoWindow}>
                                                    <div className="featured-pod-box with-map">
                                                        <div className="fpb-image">
                                                            <div className="lm-slider">
                                                                <Swiper
                                                                    navigation
                                                                    pagination={{ clickable: true, dynamicBullets: true }}
                                                                >
                                                                    <SwiperSlide><img src={listingimage} alt="" /></SwiperSlide>
                                                                    <SwiperSlide><img src={listingimage} alt="" /></SwiperSlide>
                                                                    <SwiperSlide><img src={listingimage} alt="" /></SwiperSlide>
                                                                    <SwiperSlide><img src={listingimage} alt="" /></SwiperSlide>
                                                                </Swiper>
                                                            </div>
                                                            <a href="javascript:;" className="item-favorite"><i className="fas fa-heart" aria-hidden="true"></i></a>
                                                        </div>
                                                        <div className="fpb-content" onClick={() => this.detailspage(x.PropertyID)}>
                                                            <a href="javacript:;">{x.PropertyName}</a>
                                                            <p>{x.PropertyAddress}</p>
                                                            <div className="fpb-amenities">
                                                                <ul>
                                                                    {x.Features.map(content => {
                                                                        return (<li>{content.FeatureIconURL ? parse(content.FeatureIconURL) : ''} {content.FeatureName}</li>
                                                                        )
                                                                    }
                                                                    )}
                                                                </ul>
                                                            </div>
                                                            <div className="fpb-star">
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star-half"></i>
                                                                <span>Excellent</span>
                                                            </div>
                                                            <div className="pod-grid-footer">
                                                                <div className="listing-price">
                                                                    <span className="fpb-price"><sup>&#163;</sup>{x.Price}<sub></sub></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </InfoWindow>

                                            </Map> */}

                      <a
                        href="javascript:;"
                        onClick={() =>
                          this.setState({ IsGridViewSelected: true })
                        }
                        className="close-map-btn"
                      >
                        <i className="fas fa-times"></i>
                      </a>
                    </div>
                  </StickyBox>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="pod-grid-outer">
            <div className="container-fluid">
              <div className="pod-grid-inner">
                <div className="listing-header">
                  <p>{podList.length} Rentals</p>
                  <div className="right-part">
                    <div className="listing-sort">
                      <label>Sort By:</label>
                      <select
                        className="form-control"
                        onChange={(e) => this.handleChange(e, "SortBy")}
                        value={this.state.sortBy}
                      >
                        <option value="High">Price: High to low</option>
                        <option value="Low">Price: Low to high</option>
                        <option value="Popularity">Popularity</option>
                        <option value="Rating">Rating</option>
                        <option value="Nearby">Near by</option>
                      </select>
                    </div>
                    <div className="map-view-btn">
                      <a
                        href="javascript:;"
                        onClick={() =>
                          this.setState({ IsGridViewSelected: false })
                        }
                      >
                        <i className="far fa-map"></i> Show Map
                      </a>
                    </div>
                  </div>
                </div>
                <div className="covid-info">
                  <p>
                    {parse(t('PODRegistration:Step0.CovidAlert'))}
                  </p>
                </div>
                <div className="pod-grid-main">
                  {podList
                    .map((x, i) => {
                      return i % 4 === 0 ? podList.slice(i, i + 4) : null;
                    })
                    .filter((x) => x != null)
                    .map((item, idx) => {
                      return (
                        <div className="row" key={idx}>
                          {item.map((x) => (
                            <div className="col-lg-3 col-md-6">
                              <div className="featured-pod-box">
                                <div className="fpb-image">
                                  <div className="lm-slider">
                                    <Swiper
                                      navigation
                                      pagination={{
                                        clickable: true,
                                        dynamicBullets: true,
                                      }}
                                    >
                                      {x.PropertyMedia.filter(item => item.MediaType.split("/")[0] === 'image').map((content) => {
                                        return (
                                          <SwiperSlide>
                                            <img
                                              src={apiBase + content.MediaURL}
                                              alt=""
                                              height="200px"
                                              width="310px"
                                            />
                                          </SwiperSlide>
                                        );
                                      })}

                                    </Swiper>
                                  </div>
                                  <a
                                    href="javascript:;"
                                    className={
                                      x.UserPropertyFavoriteID == null
                                        ? "item-favorite"
                                        : "item-favorite active"
                                    }
                                    onClick={(e) =>
                                      this.handleFavModal(
                                        e,
                                        x,
                                        x.UserPropertyFavoriteID == null
                                          ? "Favorite"
                                          : "UnFavorite"
                                      )
                                    }
                                  >
                                    <i className="fas fa-heart"></i>
                                  </a>
                                </div>
                                <div
                                  className="fpb-content"
                                  onClick={() => this.detailspage(x.PropertyID)}
                                >
                                  <a href="javacript:;">{x.PropertyName}</a>
                                  <p>{x.PropertyAddress}</p>
                                  <div className="fpb-amenities">
                                    <ul>
                                      {x.Features.map((content) => {
                                        return (
                                          <li>
                                            {/* {content.FeatureIconURL ? parse(content.FeatureIconURL) : ''}  */}
                                            {content.FeatureName}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>

                                  <div className="pod-grid-footer">
                                    <div className="listing-price">
                                      <span className="fpb-price">
                                        <sup>{x.CurrencySymbol ? parse(x.CurrencySymbol) : ''}</sup>
                                        {x.Price}
                                        <sub></sub>
                                      </span>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </section>
        )}

        <Modal
          show={showFavModal}
          onHide={() =>
            this.setState({
              showFavModal: false,
              items: [],
            })
          }
          centered
        >
          <div className="custom-modal-login-inner">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Name this wishlist</h4>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={() =>
                    this.setState({
                      showFavModal: false,
                      item: [],
                    })
                  }
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="modal-login-form register">
                  <Form
                    noValidate
                    onSubmit={(e) =>
                      this.favUnFavPod(e, "Favorite", this.state.items)
                    }
                  >
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="name"
                        className="mb-15"
                      >
                        <Form.Control
                          required
                          type="text"
                          className={
                            this.state.WishListNameErr ? "is-invalid" : null
                          }
                          placeholder="Name"
                          value={this.state.WishListName}
                          autoFocus
                          onChange={(e) => this.handleChange(e, "WishListName")}
                        />
                        <Form.Control.Feedback type="invalid">
                          {this.state.WishListNamehelperText}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Button
                      type="submit"
                      className="homey_login_button btn btn-primary btn-full-width"
                    >
                      Create
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {this.state.showLoginModal ? (
          <Header
            showLoginModal={this.state.showLoginModal}
            hideLoginModal={() => this.setState({ showLoginModal: false })}
            {...rest}
          />
        ) : (
          false
        )}
      </div>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyAww6TxPSzQbg74ljJahxvuLHmRfICHbf4",
})(withNamespaces()(Listing));
