import React, { Component } from "react";
import cogoToast from "cogo-toast";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import api from "../../utils/apiClient";
import { CommonConfig } from "utils/constant";
import { Rating } from "primereact/rating";
import Loader from '../../../src/utils/general';

class Review extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ratingType: "",
      ratingTypeErr: false,
      ratingTypeHelperText: "",
      ratingDescription: "",
      ratingDescriptionErr: false,
      ratingDescriptionHelperText: "",
      rate: 0,
      rateErr: false,
      rateHelperText: "",
      PersonID: "",
      IsLinkExpired: true,
      ratingDone: false,
      RatingType: [],
      Status: "",
      Loading: true
    };
  }

  componentDidMount() {
    this.showLoader();
    this.validateLink();
    this.getRatingType();
    this.getRatingCategory();
  }

  validateLink = () => {
    var url = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
    let data = {
      // UUID: this.props.match.params.id
      UUID: url,
      type: "review",
    };
    this.setState({
      UUID: url,
    });
    try {
      api
        .post("userauthentication/verifyLink", data)
        .then((res) => {
          if (res.success && res.Data.length > 0) {
            this.setState({
              Status: res.Data[0].Status,
              PropertyName: res.Data[0].PropertyName,
              PropertyID: res.Data[0].PropertyID,
              BookedBy: res.Data[0].BookedBy,
            });
            // console.log("data...", this.state);
          } else {
            cogoToast.error("Oops!! The link has expired");
            this.props.history.push("/admin/homepage");
          }
        })
        .catch((err) => {
          // cogoToast.error("Something went wrong");
          // // console.log("error....", err);
        });
    } catch (err) {
      // // console.log("err.....", err);
    }
  };

  getRatingType = () => {
    let data = {
      StringMapType: "RATINGTYPE",
    };
    try {
      api
        .post("utility/getStringMap", data)
        .then((res) => {
          if (res.success) {
            res.data.map((OBJ) => {
              OBJ.IsChecked = false;
            });
            this.setState({ RatingType: res.data });
          } else {
          }
        })
        .catch((err) => {});
    } catch (err) {}
  };

  getRatingCategory = () => {
    let data = {
      StringMapType: "RATINGCATEGORY",
    };
    try {
      api
        .post("utility/getStringMap", data)
        .then((res) => {
          if (res.success) {
            res.data.map((OBJ) => {
              OBJ.value = 0;
            });
            this.setState({ RatingCategory: res.data });
            this.hideLoader();
          } else {
          }
        })
        .catch((err) => {});
    } catch (err) {}
  };

  handleChange = (e, idx, type) => {
    if (type === "ratingType") {
      let RatingTypeNew = this.state.RatingType;
      RatingTypeNew.map((x) => {
        x.IsChecked = false;
      });
      RatingTypeNew[idx]["IsChecked"] = e.target.checked;
      this.setState({
        RatingType: RatingTypeNew,
      });
    } else if (type === "description") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          ratingDescription: e.target.value,
          ratingDescriptionErr: true,
          ratingDescriptionHelperText: "Please enter description",
        });
      } else if(e.target.value.length > 40 || e.target.value.length < 10 ) {
        this.setState({
          ratingDescription: e.target.value,
          ratingDescriptionErr: true,
          ratingDescriptionHelperText: "Description should be minimum of 10 and maximum of 40 characters.",
        });
      } else {
        this.setState({
          ratingDescription: e.target.value,
          ratingDescriptionErr: false,
          ratingDescriptionHelperText: "",
        });
      }
    } else if (type === "Ratingcategory") {
      let RatingDescriptionNew = this.state.RatingCategory;
      RatingDescriptionNew[idx]["value"] = e.value;
      this.setState({
        RatingCategory: RatingDescriptionNew,
      });
    }
  };

  showLoader = () => {
    this.setState({ Loading: true });
  };

  hideLoader = () => {
    this.setState({ Loading: false });
  };

  validate = () => {
    let isValid = true;
    // if (
    //   CommonConfig.isEmpty(this.state.RatingType.filter((x) => x.IsChecked)[0])
    // ) {
    //   cogoToast.error("Please select Rating Type");
    //   isValid = false;
    // }
    this.state.RatingCategory.map((item) => {
      if (item.value === 0) {
        isValid = false;
        cogoToast.error("Please rate us");
      }
    });
    if (CommonConfig.isEmpty(this.state.ratingDescription)) {
      cogoToast.error("Please enter the description");
      isValid = false;
    } else if(this.state.ratingDescription.length < 10 || this.state.ratingDescription.length > 40 ) {
      cogoToast.error("Description should be minimum of 10 and maximum of 40 characters.");
      isValid = false;
    }
    return isValid;
  };

  onHide = () => {
    this.props.history.push('/admin/homepage')
  };

  review = (event) => {
    event.preventDefault();

    if (this.validate()) {
      try {
        let Name = this.state.RatingType.filter((x) => x.IsChecked === true);
        let reviewList = []
        this.state.RatingCategory.map((item) => {
          reviewList.push({ 
            EntityType:item.StringMapName, 
            EntityValue: item.value
          });
        });
        let data = {
          UUID: this.state.UUID,
          // RatingType: Name[0].StringMapName,
          Description: this.state.ratingDescription,
          PropertyID: this.state.PropertyID,
          ReviewList: reviewList,
          BookedBy: this.state.BookedBy
        };

        // console.log("dataa", data);
        api.post("property/addReview", data).then((res) => {
          if (res.success) {
            this.setState({
              Status: 'Inactive'
            })
            cogoToast.success("Your response has been submitted");
            // this.props.history.push("/admin/homepage");
          } else {
            cogoToast.error("Something went wrong");
            // this.hideLoader();
          }
        });
        //   .catch((err) => {
        //     // cogoToast.error("Something Went Wrong");
        //     //   this.hideLoader();
        //   });
      } catch (error) {
        // cogoToast.error("Something Went Wrong");
        //   this.hideLoader();
      }
    } else {
      // cogoToast.error("Password Not Match");
    }
  };

  render() {
    return this.state.Loading ? (<Loader />) : this.state.Status === "Active" ? (
      <Modal
        show={true}
        onHide={() => this.onHide()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Help us improve</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
                  {/* <h6 className="font-weight-normal"></h6> */}
            <Form.Row>
            <Form.Group className="give-feedback">
              <p>Workpod: {this.state.PropertyName}</p>
              </Form.Group>
              {/* {this.state.RatingType
                ? this.state.RatingType.map((type, idx) => (
                    <div key={`default-radio-${idx}`} className="mb-3">
                      <Form.Check
                        type="radio"
                        inline={true}
                        onClick={(e) => {
                          this.handleChange(e, idx, "ratingType");
                        }}
                        checked={type.IsChecked}
                        id={`default-radio-${idx}`}
                        label={type.StringMapName}
                      />
                    </div>
                  ))
                : null} */}
              {/* </Form.Group> */}
            </Form.Row>
            <Form.Row>
            {this.state.RatingCategory
              ? this.state.RatingCategory.map((type, idx) => {
                  return (
                    
                      <Form.Group
                        as={Col}
                        md="3"
                        controlId="RatingCategory"
                        className="mb-15"
                      >
                        <span className="mb-5px">{type.StringMapName}</span>
                        <Rating
                          value={type.value}
                          cancel={false}
                          onChange={(e) =>
                            this.handleChange(e, idx, "Ratingcategory")
                          }
                        />
                      </Form.Group>
                    
                  );
                })
              : null}
              </Form.Row>

            <Form.Row>
              {/* <Form.Group controlId="exampleForm.ControlTextarea1"> */}
              <Form.Label>Write your experience</Form.Label>
              <Form.Control
                value={this.state.ratingDescription}
                onChange={(e) => this.handleChange(e, "", "description")}
                as="textarea"
                rows={5}
                className={
                  this.state.ratingDescriptionErr
                    ? "form-control mb-10 is-invalid"
                    : "form-control mb-10"
                }
              />
              {/* </Form.Group> */}
              <span className="text-danger">
                {this.state.ratingDescriptionErr
                  ? this.state.ratingDescriptionHelperText
                  : null}
              </span>
              {/* </Form.Group> */}
            </Form.Row>
          </Form>
          {/* </div>
               </div>
             </div>
         </div>
       </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.review}>Submit</Button>
        </Modal.Footer>
      </Modal>
    ) : (
      <Modal
        show={true}
        onHide={() => this.onHide()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <h3 className="font-weight-normal">
              Thanks!! Your response has been submitted
            </h3>
          </p>
        </Modal.Body>
      </Modal>
    );
  }
}

export default Review;
