import React, { Component } from "react";
import api from "../../utils/apiClient";
import { apiBase } from "../../../src/utils/config";
import logo from "../../assets/img/logo.svg";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import cogoToast from "cogo-toast";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import moment from "moment";
import parse from "html-react-parser";
import DatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { CommonConfig } from "utils/constant";
import Dropzone from "react-dropzone";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import { withNamespaces } from 'react-i18next';
import Loader from '../../../src/utils/general';

const animatedComponents = makeAnimated();

class ViewEditPropertyByAdmin extends Component {
  constructor(props) {
    super(props);
    const {t} = props;
    this.state = {
      Heading: [
       t('ViewEditPod:Step0.Heading'),
       t('ViewEditPod:Step1.Heading'),
       t('ViewEditPod:Step2.Heading'),
       t('ViewEditPod:Step3.Heading'),
       t('ViewEditPod:Step4.Heading'),
       t('ViewEditPod:Step5.Heading'),
       t('ViewEditPod:Step6.Heading'),
       t('ViewEditPod:Step7.Heading'),
       t('ViewEditPod:Step8.Heading'),
      ],
      Step: 0,
      FirstName: "",
      PersonID: "",
      PropertyAddress: "",
      PropertyName: "",
      Latitude: 0.0,
      Longitude: 0.0,
      Flat: "",
      Price: 0,
      PodType: "",
      podTypeList: [],
      noOfPodList: [],
      amenitiesList: [],
      standardAmenitiesList: [],
      amenitiesRulesList: [],
      City: "",
      Country: "",
      Postcode: "",
      Users: 1,
      WashRoom: 1,
      Amenities: [],
      Rules: [],
      IsEmailAccesibility: false,
      dynamicProgress: 14.2857,
      IsAdjust: false,
      Time: [],
      timeSlotList: [],
      internetTypeList: [],
      PropertyMedia: [],
      Media: "",
      PreviewMedia: [],
      selectedDateRange: [
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ],
      dayTypeList: [],
      singleDate: [],
      day: "Single Day",
      WorkingDays: [],
      workingDaysList: [],
      titleSuggestion: [],
      titleSuggestionList: [],
      files: [],
      mainDateArray: [],
      rangeDate: [],
      InternetType: "",
      StudentDiscount: "",
      SeasonalDiscount: "",

      IsPaypalSelected: false,
      IsAccountSelected: false,

      StepBank: 1,
      StepPaypal: 1,
      BankFlat: "",

      AccountHolder: "",
      AccountHolderErr: false,
      AccountHolderhelperText: "",

      Bankname: "",
      BanknameErr: false,
      BanknamehelperText: "",

      Sortcode: "",
      SortcodeErr: false,
      SortcodehelperText: "",

      Accountnumber: "",
      AccountnumberErr: false,
      AccountnumberhelperText: "",

      Confirmaccountnumber: "",
      ConfirmaccountnumberErr: false,
      ConfirmaccountnumberhelperText: "",

      BankStreet: "",
      BankStreetErr: false,
      BankStreethelperText: "",

      BankCity: "",
      BankCityErr: false,
      BankCityhelperText: "",

      BankCountry: "",
      BankCountryErr: false,
      BankCountryhelperText: "",

      BankPostcode: "",
      BankPostcodeErr: false,
      BankPostcodehelperText: "",

      AccountType: "Savings",
      IsPayoutSubmit: false,

      PaypalPayerID: "",
      PaypalEmail: "",
      PaypalEmailErr: false,
      PaypalEmailhelperText: "",
      PaypalConfirmEmail: "",
      PaypalConfirmEmailErr: false,
      PaypalConfirmEmailhelperText: "",
      PayPalFirstName: "",
      PayPalPhoneNumber: "",
      disabled: true,
      Reason: "",
      ReasonHelperText: "",
      ReasonErr: false,
      isApproved: false,
      isReject: false,
      isRejectButtonNotClicked: true,
      photoIndex: 0,
      isOpen: false,
      fileLength: 0,
      FullDayStringMapName: "09:00 - 17:00",
      CurrencyCode: 'GBP',
      CurrencySymbol: '',
      loading: true,

      PropertyDateSelectErr:[],
      PropertyDateSelecthelperText:[]
    };
  }

  componentDidMount() {
    if (this.props.location.state) {
      this.setState({
        FirstName: this.props.location.state.FirstName,
        PersonID: this.props.location.state.PersonID,
      });
    } else {
      this.props.history.push("/admin/dashboard");
    }

    this.getCurrencyDetails()
    this.getPaypalResponse();
    this.getPodTypeList();
    this.getDayTypeList();
    this.getInternetTypeList();
    this.getNoOfPodList();
    this.getWorkingDaysList();
    this.getAmenitiesList();
    this.getStandardAmenitiesList();
    this.getAmenitiesRulesList();
    this.getTimeSlotList();
    this.getNoOfUsersList();
    this.getNoOfWashRoomsList();
    this.titleSuggestionList("");
    this.getPropertyDetails();
  }

  getCurrencyDetails = () => {
    let data = {
      CountryISOCode: CommonConfig.userCountryData().country_code
    }
    try {
      api.post("utility/getCurrencyDetails", data).then(res => {
        if (res.success) {
          this.setState({ CurrencyCode: res.data[0].CurrencyCode, CurrencySymbol: res.data[0].CurrencySymbol });
        }
        else {
        }
      }).catch(err => {
        // // console.log(err)
      })
    } catch (err) {
    }
  }

  getPaypalResponse = () => {
    var url_string = window.location.href; //window.location.href
    var url = new URL(url_string);
    var c = url.searchParams.get("code");

    var details = {
      grant_type: "authorization_code",
      code: c,
    };
    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    api
      .post("https://api-m.sandbox.paypal.com/v1/oauth2/token", formBody)
      .then(async (res) => {
        if (res.refresh_token) {
          var details1 = {
            grant_type: "refresh_token",
            refresh_token: res.refresh_token,
          };
          var formBody1 = [];
          for (var property in details1) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details1[property]);
            formBody1.push(encodedKey + "=" + encodedValue);
          }
          formBody1 = formBody1.join("&");
          await api
            .post("https://api-m.sandbox.paypal.com/v1/oauth2/token", formBody1)
            .then(async (resnew) => {
              if (resnew) {
                await api
                  .get(
                    "https://api-m.sandbox.paypal.com/v1/identity/oauth2/userinfo?schema=paypalv1.1"
                  )
                  .then((finalres) => {
                    if (finalres) {
                      var historyState = JSON.parse(
                        localStorage.getItem("PaypalHistoryData")
                      );
                      // // console.log("historyState", historyState)
                      historyState.PaypalEmail = finalres.emails[0].value;
                      historyState.PaypalPayerID = finalres.payer_id;
                      historyState.PayPalFirstName = finalres.name;
                      historyState.IsPayoutSubmit = true;
                      historyState.IsPayoutSelected = false;
                      historyState.IsPaypalSelected = true;
                      historyState.IsAccountSelected = false;
                      historyState.StepBank = 1;
                      historyState.StepPaypal = 1;
                      this.setState(historyState);
                      localStorage.removeItem("PaypalHistoryData");
                      // window.location.href = '/admin/registration'

                      // this.setState({
                      //     PaypalEmail: finalres.emails[0].value,
                      //     PaypalPayerID: finalres.payer_id,
                      //     PayPalFirstName: finalres.name,
                      //     IsPayoutSubmit: true, IsPayoutSelected: false,
                      //     IsPaypalSelected: true,
                      //     IsAccountSelected: false,
                      //     StepBank: 1,
                      //     StepPaypal: 1
                      // })
                    } else {
                      cogoToast.error(
                        "Unable to add details.Please try again!"
                      );
                    }
                  });
              } else {
                cogoToast.error("Unable to add details.Please try again!");
              }
            });
        } else {
          cogoToast.error("Unable to add details.Please try again!");
        }
      });
  };

  getPropertyDetails = () => {
    try {
      api
        .post("property/viewpropertybyadmin", this.props.location.state)
        .then((res) => {
          // console.log("res.....", res);
          if (res.success) {
            this.setState({
              PropertyID: res.data.PropertyID,
              PropertyAddress: res.data.PropertyAddress,
              PropertyNumber: res.data.PropertyNumber,
              PodType: res.data.Amenities[0].EntityType,
              PropertyName: res.data.PropertyName,
              PropertyDescription: res.data.PropertyDescription,
              Street: res.data.Street,
              City: res.data.City,
              County: res.data.State,
              Country: res.data.Country,
              Postcode:res.data.PostalCode,
              Latitude: res.data.Latitude,
              Longitude: res.data.Longitude,
              GuestCount: res.data.GuestCount,
              InternetType: res.data.Amenities[0].InternetType,
              WashRoom: res.data.BathroomCount,
              StudentDiscount: res.data.StudentDiscount,
              SeasonalDiscount: res.data.Amenities[0].SeasonalDiscount
                ? res.data.Amenities[0].SeasonalDiscount
                : null,
              Amenities: res.data.Amenities.map((x) => {
                return {
                  FeatureID: x.FeatureID,
                  PropertyFeatureID: x.PropertyFeatureID,
                };
              }),
              Rules: res.data.PropertyRules,
              files: res.data.PropertyMedia.map((x, idx) => {
                return {
                  PropertyMediaID: x.PropertyMediaID,
                  preview: apiBase + x.MediaURL,
                  Index: idx,
                  size: 1024,
                  name: x.MediaURL,
                  type: x.MediaType,
                  isAlreadyThere: true,
                };
              }),
              PropertyStatus: res.data.PropertyStatus,
              HostEmail: res.data.HostEmailId,
              PersonID: res.data.PersonID,
              AdminEmail: res.data.AdminEmailID,
              HostName: res.data.HostName,
              mainDateArray: res.data.PropertySchedule,
              IsPaypalSelected: res.data.IsPaypalSelected.data[0],
              Accountnumber: res.data.AccountNo,
              SortCode: res.data.SortCode,
              AccountHolder: res.data.AccountHolder,
              PayPalFirstName: res.data.FirstName,
              PaypalEmail: res.data.Email,
              PayPalPhoneNumber: res.data.PhoneNumber,
            });

            this.hideLoader();
            this.setState({
              listOfTitles: this.state.titleSuggestionList.map((x) => x.label),
            });
            if (!this.state.listOfTitles.includes(this.state.PropertyName)) {
              this.setState({
                titleSuggestion: {
                  value: "Customize your title",
                },
              });
            }
            this.codeLatLng(this.state.Latitude, this.state.Longitude);

            this.setState({
              IsAccountSelected: CommonConfig.isEmpty(this.state.Accountnumber) ? false : true,
              IsPayoutSubmit: CommonConfig.isEmpty(this.state.Accountnumber) && this.state.IsPaypalSelected === 0 ? false : true
            })   

            if (this.state.GuestCount > 3) {
              this.setState({
                Users: "Multi",
                UsersCount: this.state.GuestCount,
              });
            }
            else {
              this.setState({
                Users: this.state.GuestCount
              })
            }
          }
        })
        .catch((err) => { });
    } catch (err) { }
  };

  getNoOfPodList = () => {
    let data = {
      StringMapType: "NOOFPODS",
    };
    try {
      api
        .post("utility/getStringMap", data)
        .then((res) => {
          if (res.success) {
            this.setState({ noOfPodList: res.data });
          } else {
          }
        })
        .catch((err) => { });
    } catch (err) { }
  };

  getDayTypeList = () => {
    let data = {
      StringMapType: "DAYTYPE",
    };
    try {
      api
        .post("utility/getStringMap", data)
        .then((res) => {
          if (res.success) {
            this.setState({ dayTypeList: res.data });
          } else {
          }
        })
        .catch((err) => { });
    } catch (err) { }
  };

  getNoOfUsersList = () => {
    let data = {
      StringMapType: "NOOFUSERS",
    };
    try {
      api
        .post("utility/getStringMap", data)
        .then((res) => {
          if (res.success) {
            this.setState({ noOfUsersList: res.data });
          } else {
          }
        })
        .catch((err) => { });
    } catch (err) { }
  };

  getInternetTypeList = () => {
    let data = {
      StringMapType: "INTERNETTYPE",
    };
    try {
      api
        .post("utility/getStringMap", data)
        .then((res) => {
          if (res.success) {
            this.setState({ internetTypeList: res.data });
          } else {
          }
        })
        .catch((err) => { });
    } catch (err) { }
  };

  getWorkingDaysList = () => {
    let data = {
      StringMapType: "WEEKDAY",
    };
    try {
      api
        .post("utility/getStringMap", data)
        .then((res) => {
          if (res.success) {
            this.setState({ workingDaysList: res.data });
          } else {
          }
        })
        .catch((err) => { });
    } catch (err) { }
  };

  getNoOfWashRoomsList = () => {
    let data = {
      StringMapType: "NOOFWASHROOMS",
    };
    try {
      api
        .post("utility/getStringMap", data)
        .then((res) => {
          if (res.success) {
            this.setState({ noOfWashRoomsList: res.data });
          } else {
          }
        })
        .catch((err) => { });
    } catch (err) { }
  };

  getPodTypeList = () => {
    let data = {
      StringMapType: "PODTYPE",
    };
    try {
      api
        .post("utility/getStringMap", data)
        .then((res) => {
          if (res.success) {
            this.setState({ podTypeList: res.data });
          } else {
          }
        })
        .catch((err) => { });
    } catch (err) { }
  };

  getTimeSlotList = () => {
    let data = {
      StringMapType: "TIMESLOT"
    };
    try {
      api
        .post("utility/getStringMap", data)
        .then((res) => {
          if (res.success) {
            this.setState({ timeSlotList: res.data });
          } else {
          }
        })
        .catch((err) => { });
    } catch (err) { }
  };

  getAmenitiesList = () => {
    let data = {
      FeatureType: "AMENITIES",
    };
    try {
      api
        .post("property/getAmenitiesList", data)
        .then((res) => {
          if (res.success) {
            this.setState({ amenitiesList: res.data });
          } else {
          }
        })
        .catch((err) => { });
    } catch (err) { }
  };

  getAmenitiesRulesList = () => {
    let data = {
      FeatureType: "RULES",
    };
    try {
      api
        .post("property/getAmenitiesList", data)
        .then((res) => {
          if (res.success) {
            this.setState({ amenitiesRulesList: res.data });
          } else {
          }
        })
        .catch((err) => { });
    } catch (err) { }
  };

  getStandardAmenitiesList = () => {
    let data = {
      FeatureType: "STANDARDAMENITIES",
    };
    try {
      api
        .post("property/getAmenitiesList", data)
        .then((res) => {
          if (res.success) {
            this.setState({ standardAmenitiesList: res.data });
          } else {
          }
        })
        .catch((err) => { });
    } catch (err) { }
  };

  handleChangePlaces = (address, type) => {
    if (type == "Street") {
      if (CommonConfig.isEmpty(address)) {
        this.setState({
          Street: address,
          StreetErr: true,
          StreethelperText: "Please select street for your space.",
        });
      } else {
        this.setState({
          Street: address,
          StreetErr: false,
          StreethelperText: "",
        });
      }
    } else if (type == "BankStreet") {
      if (CommonConfig.isEmpty(address)) {
        this.setState({ BankStreet: address, BankStreetErr: true, BankStreethelperText: 'Please select street for your space.' })
      } else {
        this.setState({ BankStreet: address, BankStreetErr: false, BankStreethelperText: '' })
      }
    } else {
      if (CommonConfig.isEmpty(address)) {
        this.setState({
          PropertyAddress: address,
          PropertyAddressErr: true,
          PropertyAddresshelperText: "Please select location for your space.",
        });
      } else {
        this.setState({
          PropertyAddress: address,
          PropertyAddressErr: false,
          PropertyAddresshelperText: "",
        });
      }
    }
  };

  handleSelect = (address, type) => {
    if (type == "Street") {
      if (CommonConfig.isEmpty(address)) {
        this.setState({
          Street: address,
          StreetErr: true,
          StreethelperText: "Please select street for your space.",
        });
      } else {
        this.setState({
          Street: address,
          StreetErr: false,
          StreethelperText: "",
        });
      }
      geocodeByAddress(address)
        .then((results) => {
          // find country name
          for (var i = 0; i < results[0].address_components.length; i++) {
            for (
              var b = 0;
              b < results[0].address_components[i].types.length;
              b++
            ) {
              //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
              if (results[0].address_components[i].types[b] == "route") {
                //this is the object you are looking for
                this.setState({
                  Street: results[0].address_components[i].long_name,
                });
                break;
              }
              if (
                results[0].address_components[i].types[b] == "postal_town" ||
                results[0].address_components[i].types[b] ==
                "administrative_area_level_2"
              ) {
                //this is the object you are looking for
                this.setState({
                  City: results[0].address_components[i].long_name,
                });
                break;
              }
              if (
                results[0].address_components[i].types[b] ==
                "administrative_area_level_1"
              ) {
                //this is the object you are looking for
                this.setState({
                  County: results[0].address_components[i].long_name,
                });
                break;
              }
              if (results[0].address_components[i].types[b] == "postal_code") {
                //this is the object you are looking for
                this.setState({
                  Postcode: results[0].address_components[i].long_name,
                });
                break;
              }
            }
          }
          return getLatLng(results[0]);
        })
        .then((latLng) => {
          this.setState({ Latitude: latLng.lat, Longitude: latLng.lng });
        })
        .catch((error) => { });
    } else {
      if (CommonConfig.isEmpty(address)) {
        this.setState({
          PropertyAddress: address,
          PropertyAddressErr: true,
          PropertyAddresshelperText: "Please select location for your space.",
        });
      } else {
        this.setState({
          PropertyAddress: address,
          PropertyAddressErr: false,
          PropertyAddresshelperText: "",
        });
      }
      geocodeByAddress(address)
        .then((results) => {
          // find country name
          for (var i = 0; i < results[0].address_components.length; i++) {
            for (
              var b = 0;
              b < results[0].address_components[i].types.length;
              b++
            ) {
              //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
              if (results[0].address_components[i].types[b] == "route") {
                //this is the object you are looking for
                this.setState({
                  Street: results[0].address_components[i].long_name,
                });
                break;
              }
              if (
                results[0].address_components[i].types[b] == "postal_town" ||
                results[0].address_components[i].types[b] ==
                "administrative_area_level_2"
              ) {
                //this is the object you are looking for
                this.setState({
                  City: results[0].address_components[i].long_name,
                });
                break;
              }
              if (
                results[0].address_components[i].types[b] ==
                "administrative_area_level_1"
              ) {
                //this is the object you are looking for
                this.setState({
                  County: results[0].address_components[i].long_name,
                });
                break;
              }
              if (results[0].address_components[i].types[b] == "postal_code") {
                //this is the object you are looking for
                this.setState({
                  Postcode: results[0].address_components[i].long_name,
                });
                break;
              }
            }
          }
          return getLatLng(results[0]);
        })
        .then((latLng) => {
          this.setState({ Latitude: latLng.lat, Longitude: latLng.lng });
        })
        .catch((error) => { });
    }
  };

  codeLatLng = (lat, lng) => {
    const google = window.google;
    var geocoder = new google.maps.Geocoder();
    var latlng = new google.maps.LatLng(lat, lng);
    this.setState({ Latitude: lat, Longitude: lng });
    geocoder.geocode({ latLng: latlng }, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        //formatted address

        for (var i = 0; i < results[0].address_components.length; i++) {
          for (
            var b = 0;
            b < results[0].address_components[i].types.length;
            b++
          ) {
            //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
            if (results[0].address_components[i].types[b] == "route") {
              //this is the object you are looking for
              this.setState({
                Street: results[0].address_components[i].long_name,
              });
              break;
            }
            if (
              results[0].address_components[i].types[b] == "postal_town" ||
              results[0].address_components[i].types[b] ==
              "administrative_area_level_2"
            ) {
              //this is the object you are looking for
              this.setState({
                City: results[0].address_components[i].long_name,
              });
              break;
            }
            if (
              results[0].address_components[i].types[b] ==
              "administrative_area_level_1"
            ) {
              //this is the object you are looking for
              this.setState({
                County: results[0].address_components[i].long_name,
              });
              break;
            }
            if (results[0].address_components[i].types[b] == "postal_code") {
              //this is the object you are looking for
              this.setState({
                Postcode: results[0].address_components[i].long_name,
              });
              break;
            }
          }
        }
      } else {
        // alert("Geocoder failed due to: " + status);
      }
    });
  };

  handleChange = (e, type, idx) => {

    if (type == "PODTYPE") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          PodType: e.target.value,
          PodTypeErr: true,
          PodTypehelperText: "Please select pod type.",
        });
      } else {
        this.setState({
          PodType: e.target.value,
          PodTypeErr: false,
          PodTypehelperText: "",
        });
      }
    }
    if (type == "Reason") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          Reason: e.target.value,
          ReasonErr: true,
          ReasonHelperText: "Please enter the reason to decline",
        });
      } else {
        this.setState({
          Reason: e.target.value,
          ReasonErr: false,
          ReasonHelperText: "",
        });
      }
    }
    if (type == "NOOFPODS") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          NoOfPods: e.target.value,
          NoOfPodsErr: true,
          NoOfPodshelperText: "Please select number of pod.",
        });
      } else {
        this.setState({
          NoOfPods: e.target.value,
          NoOfPodsErr: false,
          NoOfPodshelperText: "",
        });
      }
    }
    if (type == "UsersCount") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          UsersCount: e.target.value,
          UsersErr: true,
          UsershelperText: "Please enter user count.",
        });
      } else if (!e.target.value.match(CommonConfig.RegExp.number)) {
        this.setState({
          UsersCount: this.state.UsersCount
        })
      } else {
        this.setState({
          UsersCount: e.target.value,
          UsersErr: false,
          UsershelperText: "",
        });
      }
    }
    if (type == "NOOFUSERS") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          Users: e.target.value,
          UsersErr: true,
          UsershelperText: "Please select no of users.",
        });
      } else {
        this.setState({
          Users: e.target.value,
          UsersErr: false,
          UsershelperText: "",
        });
      }
    }
    if (type == "NOOFWASHROOMS") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          WashRoom: e.target.value,
          WashRoomErr: true,
          WashRoomhelperText: "Please select no of washroom.",
        });
      } else {
        this.setState({
          WashRoom: e.target.value,
          WashRoomErr: false,
          WashRoomhelperText: "",
        });
      }
    }
    if (type == "INTERNETTYPE") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          InternetType: e.target.value,
          InternetTypeErr: true,
          InternetTypehelperText: "Please select Internet type.",
        });
      } else {
        this.setState({
          InternetType: e.target.value,
          InternetTypeErr: false,
          InternetTypehelperText: "",
        });
      }
    }
    if (type == "StudentDiscount") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          StudentDiscount: e.target.value,
          StudentDiscountErr: true,
          StudentDiscounthelperText: "Please enter pod type.",
        });
      } else {
        this.setState({
          StudentDiscount: e.target.value,
          StudentDiscountErr: false,
          StudentDiscounthelperText: "",
        });
      }
    }
    if (type == "SeasonalDiscount") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          SeasonalDiscount: e.target.value,
          SeasonalDiscountErr: true,
          SeasonalDiscounthelperText: "Please enter seasonal discount.",
        });
      } else {
        this.setState({
          SeasonalDiscount: e.target.value,
          SeasonalDiscountErr: false,
          SeasonalDiscounthelperText: "",
        });
      }
    }
    if (type == "FLAT") {
      this.setState({ PropertyNumber: e.target.value });
    }
    if (type == "CITY") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          City: e.target.value,
          CityErr: true,
          CityhelperText: "Please enter city.",
        });
      } else {
        this.setState({
          City: e.target.value,
          CityErr: false,
          CityhelperText: "",
        });
      }
    }
    if (type == "COUNTY") {
      if (CommonConfig.isEmpty(e.target.value)) {
          this.setState({ County: e.target.value, CountyErr: true, CountyhelperText: 'Please enter county.' })
      } else {
          this.setState({ County: e.target.value, CountyErr: false, CountyhelperText: '' })
      }
    }
    if (type == "COUNTRY") {
      if (CommonConfig.isEmpty(e.target.value)) {
          this.setState({ Country: e.target.value, CountryErr: true, CountryhelperText: 'Please select country.' })
      } else {
          this.setState({ Country: e.target.value, CountryErr: false, CountryhelperText: '' })
      }
    }
    if (type == "POSTCODE") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          Postcode: e.target.value,
          PostcodeErr: true,
          PostcodehelperText: "Please enter postcode.",
        });
      } else {
        this.setState({
          Postcode: e.target.value,
          PostcodeErr: false,
          PostcodehelperText: "",
        });
      }
    }
    if (type === "Amenities") {
      let AmenitiesNew = this.state.Amenities.map((x) => x.FeatureID);
      if (AmenitiesNew.includes(e.FeatureID)) {
        var array = [...this.state.Amenities];
        var index = array.findIndex((x) => x.FeatureID == e.FeatureID);
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ Amenities: array });
        }
      } else {
        this.setState({
          Amenities: [
            ...this.state.Amenities,
            { PropertyFeatureID: "", FeatureID: e.FeatureID },
          ],
        });
      }
    }
    if (type === "Rules") {
      if (
        this.state.Rules.filter((x) => x.FeatureID == e.FeatureID).length > 0
      ) {
        var array = [...this.state.Rules];
        var index = array.findIndex((x) => x.FeatureID == e.FeatureID);
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ Rules: array });
        }
      } else {
        var data = {
          PropertyRulesID: "",
          Rule: e.FeatureName,
          FeatureID: e.FeatureID,
        };
        this.setState({ Rules: [...this.state.Rules, data] });
      }
    }
    if (type === "AdditionalRules") {
      if (!CommonConfig.isEmpty(this.state.AdditionalRule)) {
        var data = {
          PropertyRulesID: "",
          Rule: this.state.AdditionalRule,
          FeatureID: "",
        };
        this.setState({
          Rules: [...this.state.Rules, data],
          AdditionalRule: "",
        });
      }
    }
    if (type == "IsEmailAccessibility") {
      this.setState({ IsEmailAccesibility: e.target.checked });
    }
    if (type == "Price") {
      const mainDateArray = [...this.state.mainDateArray];

      mainDateArray[idx.mainidx].Time[idx.childidx]["Price"] = e.target.value;
      mainDateArray[idx.mainidx].Time[idx.childidx]["priceErr"] = false;
      mainDateArray[idx.mainidx].Time[idx.childidx]["priceHelperText"] = "";
      this.setState({ mainDateArray: mainDateArray });
    }
    if (type == "FullDayPrice") {
      this.setState({ Price: e.target.value });
    }
    if (type == "PROPERTYNAME") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          PropertyName: e.target.value,
          PropertyNameErr: true,
          PropertyNamehelperText: "Please enter title for your pod.",
        });
      } else {
        this.setState({
          PropertyName: e.target.value,
          PropertyNameErr: false,
          PropertyNamehelperText: "",
        });
      }
    }
    if (type == "PROPERTYDESCRIPTION") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          PropertyDescription: e.target.value,
          PropertyDescriptionErr: true,
          PropertyDescriptionhelperText:
            "Please enter description for your space.",
        });
      } else if(e.target.value.length < 25) {
        this.setState({ PropertyDescription: e.target.value, PropertyDescriptionErr: true, PropertyDescriptionhelperText: 'Minimum 25 characters required' })
        }else {
        this.setState({
          PropertyDescription: e.target.value,
          PropertyDescriptionErr: false,
          PropertyDescriptionhelperText: "",
        });
      }
    }
    if (type == "TIMESLOT") {
      this.setState({ Time: e });
    }
    if (type == "PropertyMedia") {
      var file = e.target.files;
      var imgSrc = [];
      var media = [];
      for (let i = 0; i < e.target.files.length; i++) {
        media.push({
          FileName:
            moment().format("DD_MM_YYYY") + "_" + e.target.files[i].name,
          MediaType: e.target.files[i].type,
          DateTime: moment().format("DD-MM-YYYY"),
          PropertyMediaID: "",
        });
        var reader = new FileReader();
        var url = reader.readAsDataURL(e.target.files[i]);
        reader.onloadend = function (e) {
          imgSrc.push({
            imgSrc: [reader.result],
          });
        }.bind(this);
      }
      this.setState({
        PropertyMedia: media,
        Media: file,
        PreviewMedia: imgSrc,
      });
    }
    if (type === "date") {
      this.setState({
        selectedDateRange: e,
      });
    }
    if (type == "newDate") {
      this.setState({ singleDate: e });
    }
    if (type == "newRangeDate") {
      this.setState({ rangeDate: e });
    }
    if (type === "singleDate") {
      this.setState({ singleDate: e });
    }
    if (type === "day") {
      this.setState({ day: e });
    }
    if (type === "WorkingDays") {
      if (this.state.WorkingDays.includes(e.StringMapID)) {
        var array = [...this.state.WorkingDays];
        var index = array.indexOf(e.StringMapID);
        if (index !== -1) {
          array.splice(index, 1);
          this.setState({ WorkingDays: array });
        }
      } else {
        this.setState({
          WorkingDays: [...this.state.WorkingDays, e.StringMapID],
        });
      }
    }
    if (type === "MainDateTime") {
      if (
        this.state.mainDateArray[idx].Time.filter(
          (x) => x.StringMapID === e.StringMapID
        ).length > 0
      ) {
        var array = [...this.state.mainDateArray];
        var index = array[idx].Time.findIndex(
          (x) => x.StringMapID === e.StringMapID
        );
        let timeIndex = this.state.timeSlotList.findIndex(
          (x) => x.StringMapName === this.state.FullDayStringMapName
        );
        if (timeIndex !== -1) {
          array[idx].Time.splice(timeIndex, 1);
        }
        if (index !== -1) {
          array[idx].Time.splice(index, 1);
          this.setState({ mainDateArray: array });
        }
      } else {
        var array = [...this.state.mainDateArray];
        array[idx].Time.push({
          StringMapID: e.StringMapID,
          Price: "",
          StringMapName: e.StringMapName,
          priceErr: false,
          priceHelperText: "",
        });
        if (this.state.timeSlotList.length - 1 === array[idx].Time.length) {
          let timeArr = this.state.timeSlotList.filter(
            (x) => x.StringMapName === this.state.FullDayStringMapName
          );
          if (timeArr[0]) {
            array[idx].Time.push({
              StringMapID: timeArr[0].StringMapID,
              Price: "",
              StringMapName: timeArr[0].StringMapName,
              priceErr: false,
              priceHelperText: "",
            });
          }
          // // console.log("tes......",array[idx].Time.length);
        }
        this.setState({ mainDateArray: [...array] });
      }
    }
    if (type == "IsPayoutSelected") {
      this.setState({ IsPayoutSelected: e.target.checked });
    }
    if (type == "IsPaypalSelected") {
      this.setState({
        IsPaypalSelected: e.target.checked,
        IsAccountSelected: false,
      });
    }
    if (type == "IsAccountSelected") {
      this.setState({
        IsAccountSelected: e.target.checked,
        IsPaypalSelected: false,
      });
    }
    if (type == "AccountHolder") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          AccountHolder: e.target.value,
          AccountHolderErr: true,
          AccountHolderhelperText: "Please enter account holder name.",
        });
      } else {
        this.setState({
          AccountHolder: e.target.value,
          AccountHolderErr: false,
          AccountHolderhelperText: "",
        });
      }
    }
    if (type == "Bankname") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          Bankname: e.target.value,
          BanknameErr: true,
          BanknamehelperText: "Please enter bank name.",
        });
      } else {
        this.setState({
          Bankname: e.target.value,
          BanknameErr: false,
          BanknamehelperText: "",
        });
      }
    }
    if (type == "Sortcode") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          Sortcode: e.target.value,
          SortcodeErr: true,
          SortcodehelperText: "Please enter sort code.",
        });
      } else if (
        !e.target.value.split("-").join("").match(CommonConfig.RegExp.number)
      ) {
        this.setState({ Sortcode: this.state.Sortcode });
      } else {
        
        var foo = e.target.value.split("-").join(""); // remove hyphens
        if (foo.length > 0) {
          foo = foo.match(new RegExp(".{1,2}", "g")).join("-");
        }
        // $(this).val(foo);
        this.setState({
          Sortcode: foo,
          SortcodeErr: false,
          SortcodehelperText: "",
        });
      }
    }
    if (type == "Accountnumber") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          Accountnumber: e.target.value,
          AccountnumberErr: true,
          AccountnumberhelperText: "Please enter account number.",
        });
      } else if (
        !e.target.value.match(CommonConfig.RegExp.number) ||
        e.target.value.length > 8
      ) {
        this.setState({ Accountnumber: this.state.Accountnumber });
      } else {
        this.setState({
          Accountnumber: e.target.value,
          AccountnumberErr: false,
          AccountnumberhelperText: "",
        });
      }
    }
    if (type == "Confirmaccountnumber") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          Confirmaccountnumber: e.target.value,
          ConfirmaccountnumberErr: true,
          ConfirmaccountnumberhelperText:
            "Please enter confirm account number.",
        });
      } else if (
        !e.target.value.match(CommonConfig.RegExp.number) ||
        e.target.value.length > 8
      ) {
        this.setState({
          Confirmaccountnumber: this.state.Confirmaccountnumber,
        });
      } else if (e.target.value != this.state.Accountnumber) {
        this.setState({
          Confirmaccountnumber: e.target.value,
          ConfirmaccountnumberErr: true,
          ConfirmaccountnumberhelperText:
            "Account number and confirm account number must be same.",
        });
      } else {
        this.setState({
          Confirmaccountnumber: e.target.value,
          ConfirmaccountnumberErr: false,
          ConfirmaccountnumberhelperText: "",
        });
      }
    }
    if (type == "PaypalEmail") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          PaypalEmail: e.target.value,
          PaypalEmailErr: true,
          PaypalEmailhelperText: "Please enter email address.",
        });
      } else if (!e.target.value.match(CommonConfig.RegExp.email)) {
        this.setState({
          PaypalEmail: e.target.value,
          PaypalEmailErr: true,
          PaypalEmailhelperText: "Please enter valid email address.",
        });
      } else {
        this.setState({
          PaypalEmail: e.target.value,
          PaypalEmailErr: false,
          PaypalEmailhelperText: "",
        });
      }
    }
    if (type == "PaypalConfirmEmail") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          PaypalConfirmEmail: e.target.value,
          PaypalConfirmEmailErr: true,
          PaypalConfirmEmailhelperText: "Please enter confirm email address.",
        });
      } else if (!e.target.value.match(CommonConfig.RegExp.email)) {
        this.setState({
          PaypalConfirmEmail: e.target.value,
          PaypalConfirmEmailErr: true,
          PaypalConfirmEmailhelperText: "Please enter valid email address.",
        });
      } else if (e.target.value != this.state.PaypalEmail) {
        this.setState({
          PaypalConfirmEmail: e.target.value,
          PaypalConfirmEmailErr: true,
          PaypalConfirmEmailhelperText:
            "Email and confirm email address must be same.",
        });
      } else {
        this.setState({
          PaypalConfirmEmail: e.target.value,
          PaypalConfirmEmailErr: false,
          PaypalConfirmEmailhelperText: "",
        });
      }
    }
    if (type == "BANKFLAT") {
      this.setState({ BankFlat: e.target.value })
    }
    if (type == "BANKCITY") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ BankCity: e.target.value, BankCityErr: true, BankCityhelperText: 'Please enter city.' })
      } else {
        this.setState({ BankCity: e.target.value, BankCityErr: false, BankCityhelperText: '' })
      }
    }
    if (type == "BANKCOUNTRY") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ BankCountry: e.target.value, BankCountryErr: true, BankCountryhelperText: 'Please enter country.' })
      } else {
        this.setState({ BankCountry: e.target.value, BankCountryErr: false, BankCountryhelperText: '' })
      }
    }
    if (type == "BANKPOSTCODE") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({ BankPostcode: e.target.value, BankPostcodeErr: true, BankPostcodehelperText: 'Please enter postcode.' })
      } else {
        this.setState({ BankPostcode: e.target.value, BankPostcodeErr: false, BankPostcodehelperText: '' })
      }
    }
  };

  addDates = () => {
    var singleDay = [];
    var multiDay = [];

    if (this.state.singleDate.length > 0 || this.state.rangeDate.length > 0) {
      if (this.state.singleDate.length > 0) {
        singleDay = this.state.singleDate.map((x, idx) => {
          return {
            day: "Single Day",
            StartDate: moment(new Date(this.state.singleDate[idx]))
              .format("YYYY-MM-DD HH:mm:ss")
              .toString(),
            EndDate: moment(new Date(this.state.singleDate[idx]))
              .format("YYYY-MM-DD HH:mm:ss")
              .toString(),
            Time: [],
          };
        });
        // // console.log('filt', singleDay.map(x => this.state.mainDateArray.map(i => Date.parse(i.StartDate) == Date.parse(x.StartDate))))
        var temp = [];
        for (let i = 0; i < singleDay.length; i++) {
          for (let j = 0; j < this.state.mainDateArray.length; j++) {
            if (
              moment(this.state.mainDateArray[j].StartDate).format(
                "DD-MM-YYYY"
              ) == moment(singleDay[i].StartDate).format("DD-MM-YYYY")
            ) {
              temp.push(
                moment(this.state.mainDateArray[j].StartDate).format(
                  "DD-MM-YYYY"
                )
              );
            }
            // // console.log("temp",temp)
          }
        }
        if (temp.length > 0) {
          // this.setState({ PropertyDateErr: true, PropertyDatehelperText: temp.map(x => { return moment(x.StartDate).format('DD-MM-YYYY') }) + `is already added.Please try again with different dates` })
          cogoToast.error(
            "Some dates are already added please try with different dates"
          );
        } else {
          this.setState({
            mainDateArray: this.state.mainDateArray.concat(singleDay),
            singleDate: [],
          });
        }
      }
      if (this.state.rangeDate.length > 0) {
        var numberOfDatesArray = [];
        if (
          new Date(this.state.rangeDate[0]) > new Date(this.state.rangeDate[1])
        ) {
          let rangeDateNew = this.state.rangeDate;
          [rangeDateNew[0], rangeDateNew[1]] = [
            this.state.rangeDate[1],
            this.state.rangeDate[0],
          ];
          this.setState({
            rangeDate: rangeDateNew,
          });
          // console.log("objecttt", this.state.rangeDate[0]);
        }
        numberOfDatesArray.push(new Date(this.state.rangeDate[0]));
        CommonConfig.getDatesBetweenDates(
          new Date(this.state.rangeDate[0]),
          new Date(this.state.rangeDate[1])
        ).map((x) => {
          numberOfDatesArray.push(x);
        });
        numberOfDatesArray.push(new Date(this.state.rangeDate[1]));

        numberOfDatesArray.map((x) => {
          multiDay.push({
            day: "Single Day",
            StartDate: moment(new Date(x))
              .format("YYYY-MM-DD HH:mm:ss")
              .toString(),
            EndDate: moment(new Date(x))
              .format("YYYY-MM-DD HH:mm:ss")
              .toString(),
            Time: [],
          });
        });
        // multiDay = { "day": 'Multi Day', "StartDate": moment(new Date(this.state.rangeDate[0])).format('YYYY-MM-DD HH:mm:ss').toString(), "EndDate": moment(new Date(this.state.rangeDate[1])).format('YYYY-MM-DD HH:mm:ss').toString(), "Time": [] }
        var temp = [];
        for (let i = 0; i < multiDay.length; i++) {
          for (let j = 0; j < this.state.mainDateArray.length; j++) {
            if (
              moment(this.state.mainDateArray[j].StartDate).format(
                "DD-MM-YYYY"
              ) == moment(multiDay[i].StartDate).format("DD-MM-YYYY")
            ) {
              temp.push(
                moment(this.state.mainDateArray[j].StartDate).format(
                  "DD-MM-YYYY"
                )
              );
            } else if (
              moment(this.state.mainDateArray[j].EndDate).format(
                "DD-MM-YYYY"
              ) == moment(multiDay[i].EndDate).format("DD-MM-YYYY")
            ) {
              temp.push(
                moment(this.state.mainDateArray[j].EndDate).format("DD-MM-YYYY")
              );
            }
            // // console.log("temp",temp)
          }
        }
        if (temp.length > 0) {
          // this.setState({ PropertyDateErr: true, PropertyDatehelperText: temp.map(x => { return moment(x.StartDate).format('DD-MM-YYYY') }) + `is already added.Please try again with different dates.` })
          cogoToast.error(
            "Some dates are already added please try with different dates"
          );
        } else {
          this.setState({
            mainDateArray: this.state.mainDateArray.concat(multiDay),
            rangeDate: [],
          });
        }
      }
      this.setState({ PropertyDateErr: false, PropertyDatehelperText: "" });
    } else {
      this.setState({
        PropertyDateErr: true,
        PropertyDatehelperText: "Please add date for your listing.",
      });
    }

    // var array = {
    //     "day": this.state.day,
    //     "StartDate": this.state.day == 'Single Day' ? this.state.singleDate : this.state.selectedDateRange[0].startDate,
    //     "EndDate": this.state.day == 'Single Day' ? this.state.singleDate : this.state.selectedDateRange[0].endDate
    // }
    // new Date().add(4, "days")
    // this.setState({
    //     mainDateArray: singleDay.concat(multiDay)
    // })
  };

  addSubtractCount = (type) => {
    if (type == "subtract") {
      this.setState({ Users: this.state.Users - 1 });
    } else if (type == "subtractWashRoom") {
      this.setState({ WashRoom: this.state.WashRoom - 1 });
    } else if (type == "addWashRoom") {
      this.setState({ WashRoom: this.state.WashRoom + 1 });
    } else {
      this.setState({ Users: this.state.Users + 1 });
    }
  };

  distanceToMouse = (pt, mp) => {
    if (pt && mp) {
      // return distance between the marker and mouse pointer
      return Math.sqrt(
        (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
      );
    }
  };

  loadMap = (map, maps, IsDraggable) => {
    const google = window.google;

    let marker = new maps.Marker({
      position: { lat: this.state.Latitude, lng: this.state.Longitude },
      map,
      draggable: IsDraggable,
    });
  };

  handleDisable = () => {
    this.setState({
      disabled: !this.state.disabled,
    });
  };

  goBack = () => {
    this.props.history.push("/admin/podlisting");
  };

  moveMarker = (props, marker, e) => {
    this.codeLatLng(e.latLng.lat(), e.latLng.lng());
  };

  getMaxDate = () => {
    var d = new Date();
    d.setDate(d.getDate() + 30);
    return d;
  };

  titleSuggestionList = (inputValue) => {
    let data = {
      SearchString: inputValue,
    };
    try {
      api
        .post("property/getTitleSuggestionList", data)
        .then((res) => {
          if (res.success) {
            this.setState({ titleSuggestionList: res.data });
          } else {
          }
        })
        .catch((err) => { });
    } catch (err) { }
  };

  validatePayout = (event, type) => {
    event.preventDefault();
    const {
      StepBank,
      StepPaypal,
      PaypalEmail,
      PaypalConfirmEmail,
      BankStreet,
      BankCity,
      BankCountry,
      BankPostcode,
      Bankname,
      AccountHolder,
      Sortcode,
      Accountnumber,
      Confirmaccountnumber,
    } = this.state;
    let IsValid = true;
    if (type == "Bank") {
      if (StepBank == 2) {
        if (CommonConfig.isEmpty(AccountHolder)) {
          IsValid = false;
          this.setState({
            AccountHolderErr: true,
            AccountHolderhelperText: "Please enter account holder name.",
          });
        } else {
          this.setState({
            AccountHolderErr: false,
            AccountHolderhelperText: "",
          });
        }
        if (CommonConfig.isEmpty(Bankname)) {
          IsValid = false;
          this.setState({
            BanknameErr: true,
            BanknamehelperText: "Please enter bank name.",
          });
        } else {
          this.setState({ BanknameErr: false, BanknamehelperText: "" });
        }
        if (CommonConfig.isEmpty(Sortcode)) {
          IsValid = false;
          this.setState({
            SortcodeErr: true,
            SortcodehelperText: "Please enter sort code.",
          });
        } else {
          this.setState({ SortcodeErr: false, SortcodehelperText: "" });
        }
        if (CommonConfig.isEmpty(Accountnumber)) {
          IsValid = false;
          this.setState({
            AccountnumberErr: true,
            AccountnumberhelperText: "Please enter account number.",
          });
        } else {
          this.setState({
            AccountnumberErr: false,
            AccountnumberhelperText: "",
          });
        }
        if (CommonConfig.isEmpty(Confirmaccountnumber)) {
          IsValid = false;
          this.setState({
            ConfirmaccountnumberErr: true,
            ConfirmaccountnumberhelperText:
              "Please enter confirm account number.",
          });
        } else if (Confirmaccountnumber != Accountnumber) {
          IsValid = false;
          this.setState({
            ConfirmaccountnumberErr: true,
            ConfirmaccountnumberhelperText:
              "Account number and confirm account number must be same.",
          });
        } else {
          this.setState({
            ConfirmaccountnumberErr: false,
            ConfirmaccountnumberhelperText: "",
          });
        }
      } else if (StepBank == 3) {
        if (CommonConfig.isEmpty(BankCity)) {
          IsValid = false;
          this.setState({
            BankCityErr: true,
            BankCityhelperText: "Please enter city.",
          });
        } else {
          this.setState({ BankCityErr: false, BankCityhelperText: "" });
        }
        if (CommonConfig.isEmpty(BankCountry)) {
          IsValid = false;
          this.setState({
            BankCountryErr: true,
            BankCountryhelperText: "Please enter country.",
          });
        } else {
          this.setState({ BankCountryErr: false, BankCountryhelperText: "" });
        }
        if (CommonConfig.isEmpty(BankStreet)) {
          IsValid = false;
          this.setState({
            BankStreetErr: true,
            BankStreethelperText: "Please enter street.",
          });
        } else {
          this.setState({ BankStreetErr: false, BankStreethelperText: "" });
        }
        if (CommonConfig.isEmpty(BankPostcode)) {
          IsValid = false;
          this.setState({
            BankPostcodeErr: true,
            BankPostcodehelperText: "Please enter postcode.",
          });
        } else {
          this.setState({ BankPostcodeErr: false, BankPostcodehelperText: "" });
        }
      }
      if (IsValid) {
        this.setState({ StepBank: this.state.StepBank + 1 });
      }
    } else {
      if (CommonConfig.isEmpty(PaypalEmail)) {
        IsValid = false;
        this.setState({
          PaypalEmailErr: true,
          PaypalEmailhelperText: "Please enter email address.",
        });
      } else if (!PaypalEmail.match(CommonConfig.RegExp.email)) {
        IsValid = false;
        this.setState({
          EmailErr: true,
          emailhelperText: "Please enter valid email address.",
        });
      } else {
        this.setState({ PaypalEmailErr: false, PaypalEmailhelperText: "" });
      }
      if (CommonConfig.isEmpty(PaypalConfirmEmail)) {
        IsValid = false;
        this.setState({
          PaypalConfirmEmailErr: true,
          PaypalConfirmEmailhelperText: "Please enter confirm email address.",
        });
      } else if (!PaypalConfirmEmail.match(CommonConfig.RegExp.email)) {
        IsValid = false;
        this.setState({
          EmailErr: true,
          emailhelperText: "Please enter valid email address.",
        });
      } else if (PaypalConfirmEmail != PaypalEmail) {
        IsValid = false;
        this.setState({
          PaypalConfirmEmailErr: true,
          PaypalConfirmEmailhelperText:
            "Email and confirm email address must be same.",
        });
      } else {
        this.setState({
          PaypalConfirmEmailErr: false,
          PaypalConfirmEmailhelperText: "",
        });
      }
      if (IsValid) {
        this.setState({
          IsPayoutSubmit: true,
          IsPayoutSelected: true,
          IsPaypalSelected: true,
          IsAccountSelected: false,
          StepBank: 1,
          StepPaypal: 1,
        });
      }
    }
  };

  validate = () => {
    const {
      Step,
      PropertyAddress,
      PodType,
      PropertyDescription,
      PropertyName,
      WorkingDays,
      Time,
      singleDate,
      selectedDateRange,
      Street,
      City,
      County,
      Country,
      Postcode,
      Users,
      UsersCount,
      WashRoom,
      Amenities,
      Rules,
      files,
      InternetType,
      mainDateArray,
    } = this.state;
    this.setState({
      disabled: true,
    });
    let IsValid = true;
    if (Step == 0) {
      if (CommonConfig.isEmpty(PropertyAddress)) {
        IsValid = false;
        this.setState({
          PropertyAddressErr: true,
          PropertyAddresshelperText: "Please select location for your space.",
        });
      } else {
        this.setState({
          PropertyAddressErr: false,
          PropertyAddresshelperText: "",
        });
      }
    } else if (Step == 1) {
      
      if (CommonConfig.isEmpty(PropertyAddress)) {
        IsValid = false;
        this.setState({
          PropertyAddressErr: true,
          PropertyAddresshelperText: "Please select location for your space.",
        });
      } else {
        this.setState({
          PropertyAddressErr: false,
          PropertyAddresshelperText: "",
        });
      }
      if (CommonConfig.isEmpty(PodType)) {
        IsValid = false;
        this.setState({
          PodTypeErr: true,
          PodTypehelperText: "Please select pod type.",
        });
      } else {
        this.setState({ PodTypeErr: false, PodTypehelperText: "" });
      }
      if (CommonConfig.isEmpty(PropertyName)) {
        IsValid = false;
        this.setState({
          PropertyNameErr: true,
          PropertyNamehelperText: "Please enter title for your pod.",
        });
      } else {
        this.setState({ PropertyNameErr: false, PropertyNamehelperText: "" });
      }
      if (CommonConfig.isEmpty(PropertyDescription)) {
        IsValid = false;
        this.setState({
          PropertyDescriptionErr: true,
          PropertyDescriptionhelperText:
            "Please enter description for your space.",
        });
      } else if(PropertyDescription.length < 25){
        IsValid = false;
        this.setState({ PropertyDescriptionErr: true, PropertyDescriptionhelperText: 'Minimum 25 characters required.' })
        }else {
        this.setState({
          PropertyDescriptionErr: false,
          PropertyDescriptionhelperText: "",
        });
      }

      if (!mainDateArray.length > 0) {
        IsValid = false;
        this.setState({
          PropertyDateErr: true,
          PropertyDatehelperText: "Please add date for your listing.",
        });
      } else {
        this.setState({ PropertyDateErr: false, PropertyDatehelperText: "" });
      }

      mainDateArray.map((x,idx) => {
        if (!x.Time.length > 0) {
          IsValid = false;
          let propertyNew = this.state.PropertyDateSelectErr;
          propertyNew[idx] = true;
          let propertyNewHelper = this.state.PropertyDateSelecthelperText;
          propertyNewHelper[idx] = 'Please select timeslot for your listing.';
          this.setState({ PropertyDateSelectErr: propertyNew, PropertyDateSelecthelperText: propertyNewHelper })
        } else {
          let propertyNew = this.state.PropertyDateSelectErr;
          propertyNew[idx] = false;
          let propertyNewHelper = this.state.PropertyDateSelecthelperText;
          propertyNewHelper[idx] = '';
          this.setState({ PropertyDateSelectErr: propertyNew, PropertyDateSelecthelperText: propertyNewHelper })
        }
      });
    } else if (Step == 2) {
      if (CommonConfig.isEmpty(City)) {
        IsValid = false;
        this.setState({ CityErr: true, CityhelperText: "Please enter city." });
      } else {
        this.setState({ CityErr: false, CityhelperText: "" });
      }
      if (CommonConfig.isEmpty(Country)) {
        IsValid = false;
        this.setState({
          CountryErr: true,
          CountryhelperText: "Please enter country.",
        });
      } else {
        this.setState({ CountryErr: false, CountryhelperText: "" });
      }
      if (CommonConfig.isEmpty(County)) {
        IsValid = false;
        this.setState({
          CountyErr: true,
          CountyhelperText: "Please enter county.",
        });
      } else {
        this.setState({ CountyErr: false, CountyhelperText: "" });
      }
      if (CommonConfig.isEmpty(Street)) {
        IsValid = false;
        this.setState({
          StreetErr: true,
          StreethelperText: "Please enter street.",
        });
      } else {
        this.setState({ StreetErr: false, StreethelperText: "" });
      }
      if (CommonConfig.isEmpty(Postcode)) {
        IsValid = false;
        this.setState({
          PostcodeErr: true,
          PostcodehelperText: "Please enter postcode.",
        });
      } else {
        this.setState({ PostcodeErr: false, PostcodehelperText: "" });
      }
    } else if (Step == 3) {
      if (CommonConfig.isEmpty(Users)) {
        IsValid = false;
        this.setState({
          UsersErr: true,
          UsershelperText: "Please select no of users.",
        });
      } else {
        if (Users == "Multi" && CommonConfig.isEmpty(UsersCount)) {
          this.setState({
            UsersErr: true,
            UsershelperText: "Please enter user count.",
          });
        } else {
          this.setState({ UsersErr: false, UsershelperText: "" });
        }
      }
      if (CommonConfig.isEmpty(InternetType)) {
        IsValid = false;
        this.setState({
          InternetTypeErr: true,
          InternetTypehelperText: "Please select your internet type.",
        });
      } else {
        this.setState({ InternetTypeErr: false, InternetTypehelperText: "" });
      }
      if (CommonConfig.isEmpty(WashRoom)) {
        IsValid = false;
        this.setState({
          WashRoomErr: true,
          WashRoomhelperText: "Please enter no of washrooms.",
        });
      } else {
        this.setState({ WashRoomErr: false, WashRoomhelperText: "" });
      }
      if (mainDateArray.length > 0) {
        let mainDateArr = mainDateArray.map((x, indx) => {
          // mainDateArray[idx.mainidx].Time[idx.childidx]['Price'] = e.target.value
          x.Time.map((i, idx) => {
            if (CommonConfig.isEmpty(i.Price)) {
              mainDateArray[indx].Time[idx]["priceErr"] = true;
              mainDateArray[indx].Time[idx]["priceHelperText"] =
                "Please enter price";
              IsValid = false;
            } else {
              mainDateArray[indx].Time[idx]["priceErr"] = false;
              mainDateArray[indx].Time[idx]["priceHelperText"] = "";
            }
            return i;
          });
          return x;
        });
        this.setState({
          mainDateArray: mainDateArr,
        });
      }
    } else if (Step == 5) {
      if (!Amenities.length > 0) {
        IsValid = false;
        cogoToast.error("Please select atleast one amenity to continue.");
      }
    } else if (Step == 7) {
      if (files.length < 4) {
        IsValid = false;
        cogoToast.error("Please upload minimum 4 photos to continue.");
      }
    }
    if (IsValid) {
      this.setState({
        Step: this.state.Step + 1,
        dynamicProgress: this.state.dynamicProgress + 14.2857123,
      });
    }
  };

  onDrop = (files) => {
    let countVideo = 0;
    let countImage = 0;
    let isValid = true;
    let message = ""
    files.forEach((file) => {
      let typeArr = file.type.split("/");
      if (typeArr.length) {
        if (typeArr[0] === "video") {
          countVideo = countVideo + 1
          if (countVideo > 1) {
            isValid = false;
            message = "Maximum 1 video is allowed"
          }
          else if (Number(file.size) > 3145728) {
            isValid = false;
            message = "Size limit exceeded"
          }
        }
      }
    });

    this.state.files.forEach((file) => {
      let typeArr = file.type.split("/");
      if (typeArr.length) {
        if (typeArr[0] === "video") {
          countVideo = countVideo + 1
          if (countVideo > 1) {
            isValid = false;
            message = "Maximum 1 video is allowed"
          }
          else if (Number(file.size) > 3145728) {
            isValid = false;
            message = "Size limit exceeded"
          }
        }
      }
    });

    files.forEach((file) => {
      let typeArr = file.type.split("/");
      if (typeArr.length) {
        if (typeArr[0] === "image") {
          countImage = countImage + 1
          const image = new Image();
          image.addEventListener('load', () => {
          // console.log(`${image.width}x${image.height}`)
            if(image.height < 500 || image.width < 750){
              isValid = false;
              message = 'Minimum 750 x 500 resolution required'
            }
          });
        image.src = URL.createObjectURL(file)
        }
      }
    });

    this.state.files.forEach((file) => {
      let typeArr = file.type.split("/");
      if (typeArr.length) {
        if (typeArr[0] === "image") {
          countImage = countImage + 1
        }
      }
    });
    if (countImage > 8) {
      isValid = false;
      message = "Maximum 8 photos are allowed"
    }

    setTimeout( () => {
      if (isValid) {
        var newfiles = files.map(file => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
            Index: this.state.files.length + 1
          })
        })
        var allFiles = this.state.files.concat(newfiles)
        this.setState({
          files: allFiles
        })
      }
      else cogoToast.error(message)},1000)
  };

  removeImage = (Index) => {
    var array = [...this.state.files];
    var index = Index;
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  };

  approve = () => {
    this.showLoader();
    const {t} = this.props;
    var media = [];
    for (let i = 0; i < this.state.files.length; i++) {
      media.push({
        PropertyMediaID: this.state.files[i].PropertyMediaID,
        Index: this.state.files[i].Index,
        FileName: !this.state.files[i].isAlreadyThere
          ? moment().format("DD_MM_YYYY") + "_" + this.state.files[i].name
          : this.state.files[i].name,
        MediaType: this.state.files[i].type,
        DateTime: moment().format("DD-MM-YYYY"),
      });
    }

    var formData = new FormData();
    formData.append("PropertyID", this.state.PropertyID);
    formData.append("PropertySchemeCategory", "");
    formData.append("PropertySchemeType", "");
    formData.append("PropertySchemeName", "name");
    formData.append("IsFeatured", 0);
    formData.append("IsRent", 0);
    formData.append("isForSale", 0);
    formData.append("isNew", 0);
    formData.append("CreatedBy", this.state.PersonID);
    formData.append("PersonID", this.state.PersonID);
    formData.append("PropertyName", this.state.PropertyName);
    formData.append('IsPaypalSelected', this.state.IsPaypalSelected ? 1 : this.state.IsAccountSelected ? 0 : 0)
    formData.append('AccountNo', this.state.Accountnumber);
    formData.append('BankName', this.state.Bankname);
    formData.append('SortCode', this.state.Sortcode.split("-").join(""));
    formData.append('AccountHolder', this.state.AccountHolder);
    formData.append('PaypalPayerID', this.state.PaypalPayerID);
    formData.append('FirstName', this.state.PayPalFirstName);
    formData.append('Email', this.state.PaypalEmail);
    formData.append('PhoneNumber', this.state.PayPalPhoneNumber);
    formData.append(
      "PropertyFeature",
      JSON.stringify(
        this.state.Amenities.map((x) => {
          return { FeatureID: x.FeatureID };
        })
      )
    );
    formData.append(
      "PropertyRules",
      JSON.stringify(
        this.state.Rules.map((x) => {
          return { Rule: x.Rule, FeatureID: x.FeatureID };
        })
      )
    );
    formData.append(
      "PropertySchedule",
      JSON.stringify(this.state.mainDateArray)
    );
    formData.append(
      "PropertyAvailability",
      JSON.stringify(this.state.workingDaysList)
    );
    formData.append("Price", this.state.Price);
    formData.append('CurrencyCode', this.state.CurrencyCode);
    formData.append("StudentDiscount", this.state.StudentDiscount);
    formData.append("SeasonalDiscount", this.state.SeasonalDiscount);
    formData.append("InternetType", this.state.InternetType);
    formData.append("SlotType", "");
    formData.append("AgentName", "");
    formData.append("Date", "");
    formData.append("TimeStart", "");
    formData.append("TimeEnd", "");
    formData.append("PropertyAddress", this.state.PropertyAddress);
    formData.append("PropertyNumber", this.state.PropertyNumber);
    formData.append("Street", this.state.Street);
    formData.append("City", this.state.City);
    formData.append('County', this.state.County);
    formData.append('Country', this.state.Country);
    formData.append('CountryCode', t("ViewEditPod:CountryISOCode"));
    formData.append('PostalCode', this.state.Postcode);
    formData.append("Latitude", this.state.Latitude);
    formData.append("Longitude", this.state.Longitude);
    formData.append(
      "GuestCount",
      this.state.Users === "Multi" ? this.state.UsersCount : this.state.Users
    );
    formData.append("BedroomCount", 0);
    formData.append("BathroomCount", this.state.WashRoom);
    formData.append("PropertyDescription", this.state.PropertyDescription);
    formData.append("IsDedicatedGuestSpace", 0);
    formData.append("IsVerifiedPod", 0);
    formData.append("PropertyListingFrom", "");
    formData.append("PropertyListingTo", "");
    formData.append("EntityType", this.state.PodType);
    formData.append("PropertyMedia", JSON.stringify(media));
    formData.append("OldMedia", JSON.stringify(media.filter(x => x.PropertyMediaID != undefined)));
    for (let i = 0; i < this.state.files.length; i++) {
      formData.append("Media", this.state.files[i]);
    }

    try {
      api
        .post("property/addoreditordeleteorinactiveproperty", formData)
        .then((res) => {
          if (res.success) {
            let data = {
              Status: "Active",
              Reason: "",
              PropertyID: this.state.PropertyID,
              Type: ["Admin Activated Pod", "Host Activated Pod"],
            };
            try {
              api
                .post("property/updatePropertyStatus", data)
                .then((res) => {
                  if (res.success) {
                    this.setState({ statusRes: res.data });
                    let details = {
                      PropertyID: this.state.PropertyID,
                      Status: "activated",
                      Reason: ""
                    }
                    this.SetNotificationAdmin(details);
                    this.SetNotificationHost(details);
                    this.hideLoader();
                    this.props.history.push("/admin/podlisting");
                  } else {
                  }
                })
                .catch((err) => { });
            } catch (err) { }
          } else {
          }
        })
        .catch((err) => { });
    } catch (err) { }
  };

  reject = () => {
    this.setState({
      isReject: true,
      isRejectButtonNotClicked: false,
    });
  };

  rejectPod = () => {
    if (CommonConfig.isEmpty(this.state.Reason)) {
      this.setState({
        ReasonErr: true,
        ReasonHelperText: "Please enter reason to decline.",
      });
    }

    this.showLoader();

    let data = {
      PropertyID: this.state.PropertyID,
      Status: "Declined",
      Reason: this.state.Reason,
      Type: ["Admin Declined Pod", "Host Declined Pod"],
    };

    // console.log("dataaaaaaa..", data);
    if (!CommonConfig.isEmpty(this.state.Reason)) {
      try {
        api
          .post("property/updatePropertyStatus", data)
          .then((res) => {
            if (res.success) {
              this.setState({ statusRes: res.data });
              let details = {
                PropertyID: this.state.PropertyID,
                Status: "declined",
                Reason: this.state.Reason
              }
              this.SetNotificationAdmin(details);
              this.SetNotificationHost(details);
              this.hideLoader();
              this.props.history.push("/admin/podlisting");
            } else {
            }
          })
          .catch((err) => { });
      } catch (err) { }
    }
  };

  SetNotificationAdmin = (details) => {
    try {
      let Reason = !CommonConfig.isEmpty(details.Reason) ? "due to  " + details.Reason : "";
      let NotificationData = {
        NotificationCategory: details.Status === "activated" ? "Pod Activation":"POD Declined",
        NotificationType: 'Notification',
        EntityType: 'Property',
        EntityId: details.PropertyID,
        UserFrom: 'System',
        UserTo: 'Admin',
        GroupTo: null,
        NotificationText: `A property named ${this.state.PropertyName} has been ${details.Status} ${Reason} by you`,
        IsImportant: 1
      }
      // console.log("NotificationData", NotificationData)
      
      api.post("user/AddNotification", NotificationData).then(res => {
        if (res.success) {
        }
        else {
        }
      }).catch(err => {
        // console.log(err)
      })
    }
    catch (err) {
    }
  }

  SetNotificationHost = (details) => {
    try {
      let Reason = !CommonConfig.isEmpty(details.Reason) ? "due to " + details.Reason : "";
      let NotificationData = {
        NotificationCategory: details.Status === "activated" ? "Pod Activation":"POD Declined",
        NotificationType: 'Notification',
        EntityType: 'Property',
        EntityId: details.PropertyID,
        UserFrom: 'System',
        UserTo: this.state.PersonID,
        GroupTo: null,
        NotificationText: `Your pod named ${this.state.PropertyName} has been ${details.Status} ${Reason} by Admin`,
        IsImportant: 1
      }
      api.post("user/AddNotification", NotificationData).then(res => {
        if (res.success) {
        }
        else {
        }
      }).catch(err => {
        // console.log(err)
      })
    }
    catch (err) {
    }
  }

  showLoader = () => {
    this.setState({
        loading: true
    })
  }

  hideLoader = () => {
    this.setState({
        loading: false
    })
  }

  getSteps = () => {

    const { t } = this.props;

    // console.log("date", this.state.mainDateArray);
    var maxDate = this.getMaxDate();

    const country = t("ViewEditPod:CountryCode");

    const searchOptions = {
      componentRestrictions: { country: [country] },
    }

    const timeSlotListArray = this.state.timeSlotList.map((content) => {
      return { value: content.StringMapID, label: content.StringMapName };
    });

    const {
      Step,
      FirstName,
      podTypeList,
      noOfUsersList,
      dayTypeList,
      noOfWashRoomsList,
      amenitiesList,
      workingDaysList,
      amenitiesRulesList,
      internetTypeList,
      standardAmenitiesList,
    } = this.state;

    if (Step == 0) {
      return (
        <div className="add-place-wrap">
          <div className="container">
            <div className="add-place-content">
              <div className="apc-heading">
                <h2>
                  {t('ViewEditPod:Step0.HeadingStep')}
                  <span className = "edit-icon"><i
                    className="fa fa-pencil"
                    onClick={() => this.handleDisable()}
                    aria-hidden="true"
                  ></i></span>
                </h2>
              </div>
              <div className="row">
                <div className="col-lg-5 col-md-5">
                  <div className="apc-inner-content">
                    <div className="form-group">
                      <label>{parse(t('ViewEditPod:Step0.Address'))}</label>
                      <PlacesAutocomplete
                        searchOptions = {searchOptions}
                        value={this.state.PropertyAddress}
                        onChange={(e) =>
                          this.handleChangePlaces(e, "PropertyAddress")
                        }
                        onSelect={(e) =>
                          this.handleSelect(e, "PropertyAddress")
                        }
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className="landing-main-search">
                            <input
                              {...getInputProps({
                                placeholder: "Pod Address*",
                                disabled: this.state.disabled,
                                className: [
                                  this.state.PropertyAddressErr
                                    ? "form-control location-search-input is-invalid"
                                    : "form-control location-search-input",
                                ],
                              })}
                            />
                            <div className="invalid-feedback">
                              {this.state.PropertyAddresshelperText}
                            </div>
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                  : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };

                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>
                                      <i className="fas fa-map-marker-alt"></i>
                                      {suggestion.description}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                    <h4>Mandatory Amenities</h4>
                    <div className="checkbox-list-tag">
                      <div className="row">
                        {standardAmenitiesList.map((x) => {
                          return (
                            <div className="col-lg-6 col-md-6">
                              <div class="filter-check">
                                <label>
                                  <input
                                    type="checkbox"
                                    checked="checked"
                                    disabled
                                  ></input>
                                  <span>{x.FeatureName}</span>
                                </label>
                              </div>
                            </div>
                          );
                        })}
                        {/* <div className="col-lg-6 col-md-6">
                                                    <div class="filter-check">
                                                        <label>
                                                            <input type="checkbox" checked="checked" disabled></input>
                                                            <span>
                                                                Wifi
                                                        </span>
                                                        </label>
                                                    </div>
                                                </div> */}
                      </div>
                      {/* <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                    <div class="filter-check">
                                                        <label>
                                                            <input type="checkbox" checked="checked" disabled></input>
                                                            <span>
                                                                Internet Connection
                                                        </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6">
                                                    <div class="filter-check">
                                                        <label>
                                                            <input type="checkbox" checked="checked" disabled></input>
                                                            <span>
                                                                Available 2+ sockets
                                                        </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                         */}
                    </div>
                    <div class="covid-info mt-20">
                      <p>
                      {parse(t('ViewEditPod:Step0.CovidAlert'))}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="apc-footer">
                <a
                  href="javascript:;"
                  onClick={() => this.validate()}
                  className="btn btn-primary"
                >
                  Next
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (Step == 1) {
      return (
        <div className="add-place-wrap">
          <div className="container">
            <div className="add-place-inner">
              <div className="add-place-content">
                <div className="apc-heading">
                  <h2>
                    Hi, {FirstName} Let's get started viewing this space.
                    <span className = "edit-icon"><i
                      className="fa fa-pencil"
                      onClick={() => this.handleDisable()}
                      aria-hidden="true"
                    ></i>
                    </span>
                  </h2>
                </div>
                <div className="apc-inner-content">
                  <div className="row">
                    <div className="col-lg-5 col-md-5">
                      <span>STEP 1</span>
                      <h4>{parse(t('ViewEditPod:Step1.PodLocation'))}</h4>
                      <div className="form-group">
                        <PlacesAutocomplete
                        searchOptions = {searchOptions}
                          value={this.state.PropertyAddress}
                          onChange={(e) =>
                            this.handleChangePlaces(e, "PropertyAddress")
                          }
                          onSelect={(e) =>
                            this.handleSelect(e, "PropertyAddress")
                          }
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div className="landing-main-search">
                              <input
                                {...getInputProps({
                                  placeholder: "Pod Address*",
                                  disabled: this.state.disabled,
                                  className: [
                                    this.state.PropertyAddressErr
                                      ? "form-control mb-10 location-search-input is-invalid"
                                      : "form-control mb-10 location-search-input",
                                  ],
                                })}
                              />
                              <div className="invalid-feedback">
                                {this.state.PropertyAddresshelperText}
                              </div>
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                    : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };

                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>
                                        <i className="fas fa-map-marker-alt"></i>
                                        {suggestion.description}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>

                        {/* <input type="text" className="form-control mb-10" placeholder="" value="211 Station Road, Harrow" name="" /> */}
                        {this.state.PropertyAddress ? (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Flat, Suite (optional)"
                            disabled={this.state.disabled}
                            value={this.state.PropertyNumber}
                            onChange={(e) => this.handleChange(e, "FLAT")}
                          />
                        ) : null}
                      </div>
                      <div className="apc-heading">
                        <h3>{parse(t('ViewEditPod:Step1.PodType'))}</h3>
                      </div>
                      <div className="form-group">
                        <label>{parse(t('ViewEditPod:Step1.PodSelection'))}</label>
                        <select
                          className={[
                            this.state.PodTypeErr
                              ? "form-control is-invalid"
                              : "form-control",
                          ]}
                          value={this.state.PodType}
                          disabled={this.state.disabled}
                          onChange={(e) => this.handleChange(e, "PODTYPE")}
                        >
                          <option value="" selected disabled hidden>
                            Select one
                          </option>
                          {podTypeList.map((x) => {
                            return (
                              <option value={x.StringMapName}>
                                {x.StringMapName}
                              </option>
                            );
                          })}
                        </select>
                        <div className="invalid-feedback">
                          {this.state.PodTypehelperText}
                        </div>
                      </div>
                      <div className="apc-heading">
                        <h3>{parse(t('ViewEditPod:Step1.PodTitle'))}</h3>
                        <span>
                        {parse(t('ViewEditPod:Step1.PodTitleText'))}
                        </span>
                      </div>
                      <div className="form-group">
                        <Select
                          className={[
                            !this.state.titleSuggestion.value ==
                              "Customize your title" &&
                              this.state.PropertyNameErr
                              ? "form-control mb-10 is-invalid"
                              : "mb-10",
                          ]}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          placeholder={
                            this.state.listOfTitles.includes(
                              this.state.PropertyName
                            )
                              ? this.state.PropertyName
                              : "Customize your title"
                          }
                          value={this.state.PropertyName}
                          isDisabled={this.state.disabled}
                          options={this.state.titleSuggestionList}
                          onChange={(e) => {
                            if (e.value == "Customize your title") {
                              this.setState({
                                titleSuggestion: e,
                                PropertyName: "",
                              });
                            } else {
                              this.setState({
                                titleSuggestion: e,
                                PropertyName: e.label,
                                PropertyNameErr: false,
                                PropertyNamehelperText: "",
                              });
                            }
                          }}
                        />
                        {this.state.titleSuggestion &&
                          this.state.titleSuggestion.value ==
                          "Customize your title" ? (
                          <input
                            type="text"
                            className={[
                              this.state.titleSuggestion.value ==
                                "Customize your title" &&
                                this.state.PropertyNameErr
                                ? "form-control mb-10 is-invalid"
                                : "form-control mb-10",
                            ]}
                            placeholder="Pod Title"
                            maxLength={100}
                            value={this.state.PropertyName}
                            disabled={this.state.disabled}
                            onChange={(e) =>
                              this.handleChange(e, "PROPERTYNAME")
                            }
                          />
                        ) : null}
                        <div className="invalid-feedback d-block">
                          {this.state.PropertyNamehelperText}
                        </div>
                      </div>
                      <div className="apc-heading">
                        <h3>{parse(t('ViewEditPod:Step1.PodDescription'))}</h3>
                        <span>
                        {parse(t('ViewEditPod:Step1.PodDescriptionText'))}
                        </span>
                      </div>
                      <div className="form-group">
                        {/* <input type="text" className="form-control mb-10" placeholder="Pod Description" value={this.state.PropertyDescription} onChange={(e) => this.handleChange(e, "PROPERTYDESCRIPTION")} /> */}
                        <textarea
                          className={[
                            this.state.PropertyDescriptionErr
                              ? "form-control mb-10 is-invalid"
                              : "form-control mb-10",
                          ]}
                          rows="3"
                          maxLength="250"
                          placeholder="e.g. Comfortable pod located in a safe area with all basic amenities located within walkable distance"
                          disabled={this.state.disabled}
                          value={this.state.PropertyDescription}
                          onChange={(e) =>
                            this.handleChange(e, "PROPERTYDESCRIPTION")
                          }
                        ></textarea>
                        <div className="invalid-feedback">
                          {this.state.PropertyDescriptionhelperText}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-5 col-md-5">
                      <div className="apc-heading">
                        <h3>{parse(t('ViewEditPod:Step1.AvailableDate'))}</h3>
                      </div>
                      {!this.state.disabled ||
                        this.state.mainDateArray.length === 0 ? (
                        <div className="form-group">
                          <label>{parse(t('ViewEditPod:Step1.AvailableDateText'))}</label>
                          <div className="row">
                            <div className="col-lg-8 col-md-8">
                              <DatePicker
                                inputClass={[
                                  this.state.PropertyDateErr
                                    ? "form-control is-invalid"
                                    : "form-control",
                                ]}
                                value={
                                  this.state.day === "Single Day"
                                    ? this.state.singleDate
                                    : this.state.rangeDate
                                }
                                onChange={(e) =>
                                  this.state.day === "Single Day"
                                    ? this.handleChange(e, "newDate")
                                    : this.handleChange(e, "newRangeDate")
                                }
                                format="DD-MM-YYYY"
                                formattingIgnoreList={["Date", "Time"]}
                                multiple
                                minDate={new DateObject()}
                                maxDate={new DateObject().add(30, "days")}
                                range={
                                  this.state.day === "Multi Day" ? true : false
                                }
                                numberOfMonths={2}
                                plugins={[
                                  <DatePanel sort="date" />,
                                  <div className="date-selection register-date-selection" position="top">
                                    <div className="date-select-box" >{dayTypeList.map(x => {
                                      return (
                                        <button className={this.state.day === x.StringMapName ? "active" : null} onClick={() => this.handleChange(x.StringMapName, "day")}>{x.StringMapName}</button>
                                      )
                                    })}</div>
                                  </div>
                                  // {dayTypeList.map((x) => {
                                  //   return (
                                  //     <div
                                  //       onClick={() =>
                                  //         this.handleChange(
                                  //           x.StringMapName,
                                  //           "day"
                                  //         )
                                  //       }
                                  //     >
                                  //       {x.StringMapName}
                                  //     </div>
                                  //   );
                                  // })}
                                  // </div>,
                                ]}
                              />
                              <div className="invalid-feedback d-block">
                                {this.state.PropertyDatehelperText}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                              <a
                                style={{
                                  pointerEvents: this.state.disabled
                                    ? "none"
                                    : null,
                                }}
                                className="btn btn-primary"
                                href="javascript:;"
                                onClick={() => this.addDates()}
                              >
                                Add
                              </a>
                            </div>
                          </div>
                          <span className="input-tagline">
                          {parse(t('ViewEditPod:Step1.AvailableDateSmallText'))}
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        {this.state.mainDateArray.length > 0
                          ? this.state.mainDateArray.map((x, indx) => {
                            return (
                              <div className="row mt-3" key={indx}>
                                <div className="col-lg-3 col-md-3 border-0">
                                  <div className="selected-date">
                                    <a
                                      href="javacript:;"
                                      onClick={() => {
                                        if (!this.state.disabled) {
                                          var array = [
                                            ...this.state.mainDateArray,
                                          ];
                                          var index = indx;
                                          if (index !== -1) {
                                            array.splice(index, 1);
                                            this.setState({
                                              mainDateArray: array,
                                            });
                                          }
                                        }
                                      }}
                                    >
                                      <i className="fas fa-times"></i>
                                    </a>
                                    {x.day !== "Single Day" ? (
                                      <h6>
                                        {moment(x.StartDate).format(
                                          "ddd DD MMMM,YYYY"
                                        )}{" "}
                                          to{" "}
                                        {moment(x.EndDate).format(
                                          "ddd DD MMMM,YYYY"
                                        )}
                                      </h6>
                                    ) : (
                                      <h6>
                                        {moment(x.StartDate).format(
                                          "ddd DD MMMM,YYYY"
                                        )}
                                      </h6>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-9 col-md-9">
                                  <div className="row">
                                    {this.state.timeSlotList.map(
                                      (content, idx) => {
                                        return (
                                          <div className="col-lg-3 col-md-3">
                                            <div className="filter-check">
                                              <label>
                                                <input
                                                  disabled={
                                                    this.state.disabled
                                                  }
                                                  type="checkbox"
                                                  disabled={
                                                    content.StringMapName ===
                                                      this.state
                                                        .FullDayStringMapName
                                                      ? true
                                                      : false
                                                  }
                                                  onClick={(e) =>
                                                    this.handleChange(
                                                      content,
                                                      "MainDateTime",
                                                      indx
                                                    )
                                                  }
                                                  checked={
                                                    x.Time.filter(
                                                      (i) =>
                                                        i.StringMapID ==
                                                        content.StringMapID
                                                    ).length > 0
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                <span>
                                                  {content.StringMapName}
                                                </span>
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                    {this.state.PropertyDateSelectErr[indx]?<div className = "text-danger">{this.state.PropertyDateSelecthelperText[indx]}</div>:null}
                                  </div>
                                </div>
                              </div>
                            );
                          })
                          : null}
                      </div>
                    </div>
                  </div>

                  {/* <div className="apc-heading">
                                        <h3>Choose a working days for your listing</h3>
                                    </div>
                                    <div className="checkbox-list-tag d-inline-block">
                                        {workingDaysList.map((content, idx) => {
                                            return (
                                                <div className="filter-check">
                                                    <label><input type="checkbox" onClick={(e) => this.handleChange(content, "WorkingDays")} checked={this.state.WorkingDays.filter(x => x == content.StringMapID).length > 0 ? true : false} />
                                                        <span>
                                                            {content.StringMapName}
                                                        </span>
                                                    </label>
                                                </div>
                                            )
                                        })}
                                    </div> */}

                  {/* <div className="apc-heading">
                                        <h3>Add a timeslot for your listing</h3>
                                    </div>
                                    <div className="form-group">
                                        <label>Choose a time slot</label> */}
                  {/* <select className="form-control" value={this.state.PodType} onChange={(e) => this.handleChange(e, "TIMESLOT")}>
                                            <option value="" selected disabled hidden>Select one</option>
                                            {podTypeList.map(x => {
                                                return (
                                                    <option value={x.StringMapName}>{x.StringMapName}</option>
                                                )
                                            })}
                                        </select> */}
                  {/* <Select
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            placeholder={'Time slot'}
                                            value={this.state.Time}
                                            isMulti
                                            onChange={(e) => this.handleChange(e, "TIMESLOT")}
                                            options={timeSlotListArray}
                                        />
                                    </div> */}
                  <div className="apc-footer">
                    <a
                      href="javascript:;"
                      onClick={() =>
                        this.setState({
                          Step: this.state.Step - 1,
                          dynamicProgress:
                            this.state.dynamicProgress - 14.2857123,
                        })
                      }
                      className="btn"
                    >
                      <i className="fas fa-angle-left"></i> Back
                    </a>
                    <a
                      className="btn btn-primary"
                      href="javascript:;"
                      onClick={() => this.validate()}
                    >
                      Continue
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (Step == 2) {
      return (
        <div className="add-place-wrap">
          <div className="container">
            <div className="add-place-inner">
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-5">
                  <div className="add-place-content">
                    <div className="apc-heading">
                      <h3>
                      {t('ViewEditPod:Step2.HeadingStep')}
                      <span className = "edit-icon">
                        <i
                          className="fa fa-pencil"
                          onClick={() => this.handleDisable()}
                          aria-hidden="true"
                        ></i>
                        </span>
                      </h3>
                      <p>
                      {parse(t('ViewEditPod:Step2.HeadPara'))}
                      </p>
                    </div>
                    <div className="apc-inner-content">
                      <button
                        disabled={this.state.disabled}
                        className="btn btn-outline-primary btn-icon use-loc-btn"
                        onClick={() => {
                          navigator.geolocation.getCurrentPosition(
                            (position) => {
                              this.setState({
                                Latitude: parseFloat(
                                  position.coords.latitude
                                ).toFixed(4),
                                Longitude: parseFloat(
                                  position.coords.longitude
                                ).toFixed(4),
                              });
                              this.codeLatLng(
                                parseFloat(position.coords.latitude).toFixed(4),
                                parseFloat(position.coords.longitude).toFixed(4)
                              );
                            }
                          );
                        }}
                      >
                        <i className="fas fa-map-marker-alt"></i> Use current
                        location
                      </button>
                      <div className="form-group">
                        <label>{parse(t('ViewEditPod:Step2.Country'))}</label>
                        <select disabled={this.state.disabled} className={[this.state.CountryErr ? "form-control is-invalid" : "form-control"]} value={this.state.Country} onChange = {(e) => this.handleChange(e,'COUNTRY')}>                  
                          <option value={this.state.Country}>{t('ViewEditPod:Step2.CountryName')}</option>
                        </select>
                        <div className="invalid-feedback">{this.state.CountryhelperText}</div>
                      </div>
                      <div className="form-group">
                        <label>{parse(t('ViewEditPod:Step2.Flat'))}</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Flat"
                          disabled={this.state.disabled}
                          value={this.state.PropertyNumber}
                          onChange={(e) => this.handleChange(e, "FLAT")}
                        />
                        <span className="input-tagline">{parse(t('ViewEditPod:Step2.FlatExampleText'))}</span>
                      </div>
                      <div className="form-group">
                        <label>{parse(t('ViewEditPod:Step2.Street'))}</label>
                        <PlacesAutocomplete
                          searchOptions = {searchOptions}
                          value={this.state.Street}
                          onChange={(e) => this.handleChangePlaces(e, "Street")}
                          onSelect={(e) => this.handleSelect(e, "Street")}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div className="landing-main-search">
                              <input
                                {...getInputProps({
                                  placeholder: "Street",
                                  disabled: this.state.disabled,
                                  className: [
                                    this.state.StreetErr
                                      ? "form-control mb-10 location-search-input is-invalid"
                                      : "form-control mb-10 location-search-input",
                                  ],
                                })}
                              />
                              <div className="invalid-feedback">
                                {this.state.StreethelperText}
                              </div>
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                    : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>
                                        <i className="fas fa-map-marker-alt"></i>
                                        {suggestion.description}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>

                        <span className="input-tagline">{parse(t('ViewEditPod:Step2.StreetExampleText'))}</span>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>{parse(t('ViewEditPod:Step2.City'))}</label>
                            <input
                              type="text"
                              className={[
                                this.state.CityErr
                                  ? "form-control is-invalid"
                                  : "form-control",
                              ]}
                              value={this.state.City}
                              disabled={this.state.disabled}
                              onChange={(e) => this.handleChange(e, "CITY")}
                            />
                            <div className="invalid-feedback">
                              {this.state.CityhelperText}
                            </div>
                            <span className="input-tagline">{parse(t('ViewEditPod:Step2.CityExampleText'))}</span>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>{parse(t('ViewEditPod:Step2.County'))}</label>
                            <input
                              type="text"
                              value={this.state.County}
                              disabled={this.state.disabled}
                              onChange={(e) => this.handleChange(e, "COUNTY")}
                              className={[
                                this.state.CountyErr
                                  ? "form-control is-invalid"
                                  : "form-control",
                              ]}
                            />
                            <div className="invalid-feedback">
                              {this.state.CountyhelperText}
                            </div>
                            <span className="input-tagline">
                            {parse(t('ViewEditPod:Step2.CountyExampleText'))}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>{parse(t('ViewEditPod:Step2.PostCode'))}</label>
                            <input
                              type="text"
                              value={this.state.Postcode}
                              disabled={this.state.disabled}
                              onChange={(e) => this.handleChange(e, "POSTCODE")}
                              className={[
                                this.state.PostcodeErr
                                  ? "form-control is-invalid"
                                  : "form-control",
                              ]}
                            />
                            <div className="invalid-feedback">
                              {this.state.PostcodehelperText}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="apc-heading">
                        <h3>{parse(t('ViewEditPod:Step2.Pin'))}</h3>
                        <p>
                          {parse(t('ViewEditPod:Step2.PinText'))}
                        </p>
                      </div>
                      <div className="apc-inner-content">
                        <div className="place-map-outer">
                          <p>{this.state.PropertyAddress}</p>
                          <div
                            className="place-map"
                            style={{ height: "400px", width: "100%" }}
                          >
                            <Map
                              google={this.props.google}
                              gestureHandling="greedy"
                              loading="lazy"
                              initialCenter={{
                                lat: this.state.Latitude,
                                lng: this.state.Longitude,
                              }}
                              center={{
                                lat: this.state.Latitude,
                                lng: this.state.Longitude,
                              }}
                              zoom={14}
                            >
                              <Marker
                                onClick={this.onMarkerClick}
                                name={"Current location"}
                                position={{
                                  lat: this.state.Latitude,
                                  lng: this.state.Longitude,
                                }}
                                draggable={
                                  this.state.disabled
                                    ? false
                                    : this.state.IsAdjust
                                }
                                onDragend={this.moveMarker.bind(this)}
                              />
                            </Map>
                            <a
                              href="javascript:;"
                              onClick={() =>
                                this.setState({
                                  IsAdjust: !this.state.IsAdjust,
                                })
                              }
                            >
                              {!this.state.IsAdjust ? "Adjust" : "Save"}
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="apc-footer">
                        <a
                          href="javascript:;"
                          onClick={() =>
                            this.setState({
                              Step: this.state.Step - 1,
                              dynamicProgress:
                                this.state.dynamicProgress - 14.2857123,
                            })
                          }
                          className="btn"
                        >
                          <i className="fas fa-angle-left"></i> Back
                        </a>
                        <a
                          href="javascript:;"
                          onClick={() => this.validate()}
                          className="btn btn-primary"
                        >
                          Yes, that's right
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (Step == 3) {
      return (
        <div className="add-place-wrap">
          <div className="container">
            <div className="add-place-inner">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-8 col-md-12">
                  <div className="add-place-content">
                    <div className="apc-heading">
                      <h3>
                      {parse(t('ViewEditPod:Step3.HowManyUsers'))}
                      <span className = "edit-icon">
                        <i
                          className="fa fa-pencil"
                          onClick={() => this.handleDisable()}
                          aria-hidden="true"
                        ></i></span>
                      </h3>
                      <p>
                      {parse(t('ViewEditPod:Step3.UsersText'))}
                      </p>
                    </div>
                    <div className="apc-inner-content">
                      <div className="form-group">
                        <label>{parse(t('ViewEditPod:Step3.Users'))}</label>
                        {this.state.Users !== "Multi" ? (
                          <select
                            disabled={this.state.disabled}
                            className={[
                              this.state.UsersErr
                                ? "form-control is-invalid"
                                : "form-control",
                            ]}
                            value={this.state.Users}
                            onChange={(e) => this.handleChange(e, "NOOFUSERS")}
                          >
                            {noOfUsersList.map((x) => {
                              return (
                                <option value={x.StringMapName}>
                                  {x.StringMapName}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <div className="row">
                            <div className="col-lg-4 col-md-4">
                              <select
                                className={[
                                  this.state.UsersErr
                                    ? "form-control is-invalid"
                                    : "form-control",
                                ]}
                                value={this.state.Users}
                                disabled={this.state.disabled}
                                onChange={(e) =>
                                  this.handleChange(e, "NOOFUSERS")
                                }
                              >
                                {noOfUsersList.map((x) => {
                                  return (
                                    <option value={x.StringMapName}>
                                      {x.StringMapName}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="col-lg-8 col-md-8">
                              <input
                                disabled={this.state.disabled}
                                type="text"
                                className="form-control"
                                value={this.state.UsersCount}
                                onChange={(e) =>
                                  this.handleChange(e, "UsersCount")
                                }
                              />
                            </div>
                          </div>
                        )}
                        <div className="invalid-feedback">
                          {this.state.UsershelperText}
                        </div>
                      </div>
                      <div className="apc-heading mt-20">
                        <h3>{parse(t('ViewEditPod:Step3.InternetType'))}</h3>
                      </div>
                      <div className="form-group">
                        <label>{parse(t('ViewEditPod:Step3.InternetTypeLabel'))}</label>
                        <select
                          className={[
                            this.state.InternetTypeErr
                              ? "form-control is-invalid"
                              : "form-control",
                          ]}
                          value={this.state.InternetType}
                          disabled={this.state.disabled}
                          onChange={(e) => this.handleChange(e, "INTERNETTYPE")}
                        >
                          <option value="" disabled selected hidden>
                            Select
                          </option>
                          {internetTypeList.map((x) => {
                            return (
                              <option value={x.StringMapName}>
                                {x.StringMapName}
                              </option>
                            );
                          })}
                        </select>
                        <div className="invalid-feedback">
                          {this.state.InternetTypehelperText}
                        </div>
                      </div>
                      <div className="apc-heading mt-20">
                        <h3>{parse(t('ViewEditPod:Step3.Washroom'))}</h3>
                      </div>
                      <div className="form-group">
                        <label>{parse(t('ViewEditPod:Step3.WashroomLabel'))}</label>
                        <select
                          className={[
                            this.state.WashRoomErr
                              ? "form-control is-invalid"
                              : "form-control",
                          ]}
                          value={this.state.WashRoom}
                          disabled={this.state.disabled}
                          onChange={(e) =>
                            this.handleChange(e, "NOOFWASHROOMS")
                          }
                        >
                          {noOfWashRoomsList.map((x) => {
                            return (
                              <option value={x.StringMapName}>
                                {x.StringMapName}
                              </option>
                            );
                          })}
                        </select>
                        <div className="invalid-feedback">
                          {this.state.WashRoomhelperText}
                        </div>
                      </div>
                      <div className="apc-heading mt-20">
                        <h3>{parse(t('ViewEditPod:Step3.UserPayment'))}</h3>
                          <span className="input-tagline">
                            {parse(t('ViewEditPod:Step3.UserPaymentText'))}
                          </span>
                      </div>
                      <div className="form-group">
                        {this.state.mainDateArray.length > 0
                          ? this.state.mainDateArray.map((x, indx) => {
                            return (
                              <div>
                                <div
                                  className="form-control col-lg-12 col-md-12 border-0 text-center mt-4"
                                  key={indx}
                                >
                                  {x.day !== "Single Day" ? (
                                    <h6>
                                      {moment(x.StartDate).format(
                                        "ddd DD MMMM,YYYY"
                                      )}{" "}
                                        to{" "}
                                      {moment(x.EndDate).format(
                                        "ddd DD MMMM,YYYY"
                                      )}
                                    </h6>
                                  ) : (
                                    <h6>
                                      {moment(x.StartDate).format(
                                        "ddd DD MMMM,YYYY"
                                      )}
                                    </h6>
                                  )}
                                </div>
                                <div className="row mt-1">{x.Time.length > 0
                                  ? x.Time.map((i, idx) => {
                                    return (
                                        <div className=" col-lg-3 col-md-3 border-0" key={idx}>
                                          <div className="form-group">
                                          <label>
                                            {
                                              this.state.timeSlotList.filter(
                                                (t) =>
                                                  t.StringMapID ===
                                                  i.StringMapID
                                              )[0].StringMapName
                                            }
                                          </label>
                                          
                                          <div className="input-group">
                                            <div class="input-group-prepend">
                                              <span class="input-group-text">{this.state.CurrencySymbol ? parse(this.state.CurrencySymbol) : ''}</span>
                                            </div>
                                            <input
                                              type="number"
                                              disabled={this.state.disabled}
                                              className={
                                                i.priceErr
                                                  ? "form-control is-invalid"
                                                  : "form-control"
                                              }
                                              value={i.Price ? i.Price : ""}
                                              onChange={(e) =>
                                                this.handleChange(
                                                  e,
                                                  "Price",
                                                  {
                                                    mainidx: indx,
                                                    childidx: idx,
                                                  }
                                                )
                                              }
                                              />
                                              <div className="invalid-feedback">
                                                {i.priceHelperText}
                                              </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                  : null}
                                  </div>
                              </div>
                            );
                          })
                          : null}
                      </div>
                      <div className="apc-heading mt-20">
                        <h5>
                          {parse(t('ViewEditPod:Step3.Discount'))}
                        </h5>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>{parse(t('ViewEditPod:Step3.StudentDiscount'))}</label>
                            <input
                              type="number"
                              className="form-control"
                              disabled={this.state.disabled}
                              value={this.state.StudentDiscount}
                              onChange={(e) =>
                                this.handleChange(e, "StudentDiscount")
                              }
                            />
                            <div className="invalid-feedback">
                              {this.state.StudentDiscounthelperText}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>{parse(t('ViewEditPod:Step3.SeasonalDiscount'))}</label>
                            <input
                              type="number"
                              className="form-control"
                              disabled={this.state.disabled}
                              value={this.state.SeasonalDiscount}
                              onChange={(e) =>
                                this.handleChange(e, "SeasonalDiscount")
                              }
                            />
                            <div className="invalid-feedback">
                              {this.state.SeasonalDiscounthelperText}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="apc-heading mt-20">
                                                <h3>Do you want to provide seasonal discount?</h3>
                                            </div> */}

                      <div className="apc-footer">
                        <a
                          href="javascript:;"
                          onClick={() =>
                            this.setState({
                              Step: this.state.Step - 1,
                              dynamicProgress:
                                this.state.dynamicProgress - 14.2857123,
                            })
                          }
                          className="btn"
                        >
                          <i className="fas fa-angle-left"></i> Back
                        </a>
                        <a
                          href="javascript:;"
                          onClick={() => this.validate()}
                          className="btn btn-primary"
                        >
                          Next
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (Step == 5) {
      return (
        <div className="add-place-wrap">
          <div className="container">
            <div className="add-place-inner">
              <div className="row justify-content-center">
                <div className="col-lg-8 col-md-8">
                  <div className="add-place-content">
                    <div className="apc-heading">
                      <h3>
                      {parse(t('ViewEditPod:Step5.Amenities'))}
                      <span className = "edit-icon">
                        <i
                          className="fa fa-pencil"
                          onClick={() => this.handleDisable()}
                          aria-hidden="true"
                        ></i></span>
                      </h3>
                      <p>
                      {parse(t('ViewEditPod:Step5.AmenitiesText'))}
                      </p>
                    </div>
                    <div className="checkbox-list-tag">
                      {amenitiesList
                        .map((x, i) => {
                          return i % 3 === 0
                            ? amenitiesList.slice(i, i + 3)
                            : null;
                        })
                        .filter((x) => x != null)
                        .map((item, idx) => {
                          return (
                            <div className="row" key={idx}>
                              {item.map((content) => (
                                <div className="col-lg-4 col-md-4">
                                  {/* <div className="custom-control custom-checkbox" key={idx}>
                                                                <input type="checkbox" checked={this.state.Amenities.filter(x => x == content.FeatureID).length > 0 ? true : false} onChange={() => this.handleChange(content, "Amenities")} className="custom-control-input" id={content.FeatureName}></input>
                                                                <label className="custom-control-label" for={content.FeatureName}>{content.FeatureName}</label>
                                                            </div> */}
                                  <div className="filter-check" key={idx}>
                                    <label>
                                      <input
                                        type="checkbox"
                                        disabled={this.state.disabled}
                                        onClick={(e) =>
                                          this.handleChange(
                                            content,
                                            "Amenities"
                                          )
                                        }
                                        checked={
                                          this.state.Amenities.filter(
                                            (x) =>
                                              x.FeatureID == content.FeatureID
                                          ).length > 0
                                            ? true
                                            : false
                                        }
                                      />
                                      <span>
                                        {content.FeatureIconURL
                                          ? parse(content.FeatureIconURL)
                                          : ""}
                                        {content.FeatureName}
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="apc-footer">
                    <a
                      href="javascript:;"
                      onClick={() =>
                        this.setState({
                          Step: this.state.Step - 1,
                          dynamicProgress:
                            this.state.dynamicProgress - 14.2857123,
                        })
                      }
                      className="btn"
                    >
                      <i className="fas fa-angle-left"></i> Back
                    </a>
                    <a
                      href="javascript:;"
                      onClick={() => this.validate()}
                      className="btn btn-primary"
                    >
                      Next
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (Step == 6) {
      return (
        <div className="add-place-wrap">
          <div className="container">
            <div className="add-place-inner">
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-5">
                  <div className="add-place-content">
                    <div className="apc-heading">
                      <h3>
                      {parse(t('ViewEditPod:Step6.AdditionalRules'))}
                      <span className = "edit-icon">
                        <i
                          className="fa fa-pencil"
                          onClick={() => this.handleDisable()}
                          aria-hidden="true"
                        ></i></span>
                      </h3>
                    </div>
                    <div className="apc-inner-content">
                      <div className="additional-rules">
                        <div className="input-btn-group">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              onChange={(e) =>
                                this.setState({
                                  AdditionalRule: e.target.value,
                                })
                              }
                              value={this.state.AdditionalRule}
                            />
                            <div className="input-group-append">
                              <button
                                className="btn btn-outline-primary"
                                type="button"
                                disabled={this.state.disabled}
                                onClick={(e) =>
                                  this.handleChange(e, "AdditionalRules")
                                }
                              >
                                Add
                              </button>
                            </div>
                          </div>
                          <div className="addition-rules-option mt-2">
                            {this.state.Rules.filter(
                              (item) => item.FeatureID == ""
                            ).map((x, idx) => {
                              return (
                                <div
                                  className="custom-control custom-checkbox"
                                  key={idx}
                                >
                                  <label className="mr-2">{x.Rule}</label>
                                  <a
                                    href="javacript:;"
                                    onClick={() => {
                                      if (!this.state.disabled) {
                                        var array = [...this.state.Rules];
                                        var index = idx;
                                        if (index !== -1) {
                                          array.splice(index, 1);
                                          this.setState({ Rules: array });
                                        }
                                      }
                                    }}
                                  >
                                    <i className="fas fa-times"></i>
                                  </a>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="addition-rules-option">
                          <h6>{parse(t('ViewEditPod:Step6.RulesText'))}</h6>

                          {amenitiesRulesList.map((content, idx) => {
                            return (
                              <div
                                className="custom-control custom-checkbox"
                                key={idx}
                              >
                                <input
                                  disabled={this.state.disabled}
                                  type="checkbox"
                                  checked={
                                    this.state.Rules.filter(
                                      (x) => x.FeatureID == content.FeatureID
                                    ).length > 0
                                      ? true
                                      : false
                                  }
                                  onChange={() =>
                                    this.handleChange(content, "Rules")
                                  }
                                  className="custom-control-input"
                                  id={content.FeatureName}
                                ></input>
                                <label
                                  className="custom-control-label"
                                  for={content.FeatureName}
                                >
                                  {content.FeatureName}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="apc-footer">
                        <a
                          href="javascript:;"
                          onClick={() =>
                            this.setState({
                              Step: this.state.Step - 1,
                              dynamicProgress:
                                this.state.dynamicProgress - 14.2857123,
                            })
                          }
                          className="btn"
                        >
                          <i className="fas fa-angle-left"></i> Back
                        </a>
                        <a
                          href="javascript:;"
                          onClick={() => this.validate()}
                          className="btn btn-primary"
                        >
                          Next
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (Step == 7) {
      return (
        <div className="add-place-wrap">
          <div className="container">
            <div className="add-place-inner">
              <div className="row justify-content-center">
                <div className="col-lg-10 col-md-10">
                  <div className="add-place-content">
                    {/* <div className="apc-heading">
                                            <h3>Add your photo</h3>
                                        </div>
                                        <div className="apc-inner-content"> */}
                    {/* <div className="add-photo-wrap">
                                                <div className="image-holder">
                                                    <img src={avatar} align="" />
                                                </div>
                                                <div className="image-upload-btn">
                                                    <a href="#" className="btn btn-fb btn-icon"><i className="fab fa-facebook-f"></i> Use Facebook photo</a>
                                                    <a href="#" className="btn btn-primary btn-icon"><i className="fas fa-cloud-upload-alt"></i> Upload a photo</a>
                                                </div>
                                            </div> */}

                    <div className="apc-heading">
                      <h3>
                      {parse(t('ViewEditPod:Step7.PropertyPhoto'))}
                      <span className = "edit-icon">
                        <i
                          className="fa fa-pencil"
                          onClick={() => this.handleDisable()}
                          aria-hidden="true"
                        ></i></span>
                      </h3>
                    </div>

                    <Alert variant="primary">
                    {parse(t('ViewEditPod:Step7.SupportedType'))}
                    </Alert>

                    <div className="property-photo-add">
                      {/* <button onClick={this.handleSetVisible}>Show Me</button> */}
                      {/* <RMIUploader
                                                    ref={upload => this.upload = upload}
                                                    isOpen={this.state.visible}
                                                    hideModal={this.hideModal}
                                                    onSelect={this.onSelect}
                                                    onUpload={this.onUpload}
                                                    onRemove={this.onRemove}
                                                    dataSources={this.state.PreviewMedia}
                                                /> */}
                      {/* <input type="file" className="form-control" value={this.state.file} onChange={(e) => this.handleChange(e, "PropertyMedia")} multiple></input>

                                            <div className="added-pic">
                                                <div className="row">
                                                    {this.state.PreviewMedia.map(x => {
                                                        return (
                                                            <div className="col-lg-3 col-md-3">
                                                                <img src={x.imgSrc[0]} align="" />
                                                            </div>
                                                        )
                                                    })}
                                                    {/* <div className="col-lg-3 col-md-3">
                                                            <img src={avatar} align="" />
                                                        </div>
                                                        <div className="col-lg-3 col-md-3">
                                                            <img src={avatar} align="" />
                                                        </div>
                                                        <div className="col-lg-3 col-md-3">
                                                            <img src={avatar} align="" />
                                                        </div>
                                                        <div className="col-lg-3 col-md-3">
                                                            <img src={avatar} align="" />
                                                        </div> 
                                                </div>
                                            </div> */}

                      <Dropzone
                        accept="image/*,video/mp4,video/mp4,video/ogg,video/webm"
                        onDrop={this.state.disabled ? null : this.onDrop}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section className="dropzone-container">
                            <div {...getRootProps({ className: "dropzone" })}>
                              <input {...getInputProps()} />
                              <button
                                disabled={this.state.disabled}
                                type="button"
                                className="btn btn-primary btn-icon"
                              >
                                <i className="fas fa-cloud-upload-alt"></i>
                                {parse(t('ViewEditPod:Step7.UploadButton'))}
                              </button>
                              <p>or drag them in</p>
                            </div>
                            {this.state.files.length > 0 ? (
                              <aside>
                                <h6>Files</h6>
                                <ul className="thumb-container">
                                  {this.state.files.map((file, idx) => {
                                    return (
                                      <div className="thumb" key={file.name} onClick={() => this.setState({ isOpen: true })}>
                                        <div className="thumb-inner">
                                          {file.type.split("/")[0] === "video" ? <video controls>
                                            <source src={file.preview} type={file.type} />
                                          </video> : <img
                                            src={file.preview}
                                            className="img-preview"
                                          />}
                                          <a
                                            href="javascript:;"
                                            onClick={
                                              !this.state.disabled
                                                ? () => this.removeImage(idx)
                                                : null
                                            }
                                          >
                                            <i className="fas fa-trash-alt"></i>
                                          </a>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </ul>
                              </aside>
                            ) : null}
                          </section>
                        )}
                      </Dropzone>
                    </div>
                    <div className="apc-footer">
                      <a
                        href="javascript:;"
                        onClick={() =>
                          this.setState({
                            Step: this.state.Step - 1,
                            dynamicProgress:
                              this.state.dynamicProgress - 14.2857123,
                          })
                        }
                        className="btn"
                      >
                        <i className="fas fa-angle-left"></i> Back
                      </a>
                      <a
                        href="javascript:;"
                        onClick={() => this.validate()}
                        className="btn btn-primary"
                      >
                        Next
                      </a>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (Step == 4) {
      return (
        <div className="add-place-wrap">
          <div className="container">
            <div className="add-place-inner">
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-5">
                  <div className="add-place-content">
                    <div className="apc-inner-content">
                      <div className="apc-heading">
                        <h4>{parse(t('ViewEditPod:Step4.PayoutMethod'))}
                        <span className = "edit-icon">
                        <i
                            className="fa fa-pencil"
                            onClick={() => this.handleDisable()}
                            aria-hidden="true"
                          ></i></span>
                        </h4>

                      </div>
                      <p>
                      {parse(t('ViewEditPod:Step4.PayoutMethodText'))}
                      </p>
                      {(this.state.IsAccountSelected &&
                        this.state.IsPayoutSubmit) ||
                        (this.state.IsPaypalSelected &&
                          this.state.IsPayoutSubmit) ? null : (
                        <div className="acc-text">
                          <div className="form-check form-check-inline">
                            <input
                              disabled={this.state.disabled}
                              type="radio"
                              className="form-check-input"
                              id="feature-later"
                              checked={this.state.IsPayoutSelected}
                              onChange={(e) =>
                                this.handleChange(e, "IsPayoutSelected")
                              }
                            ></input>
                            <label
                              className="form-check-label"
                              for="feature-later"
                            >
                              {parse(t('ViewEditPod:Step4.PayoutRadio'))}
                            </label>
                          </div>
                          {/* <div className="custom-control custom-radio">
                                                  <input type="radio" className="custom-control-input" id="feature-later" checked={this.state.IsPayoutSelected} onChange={(e) => this.handleChange(e, "IsPayoutSelected")}></input>
                                                  <label className="custom-control-label" for="feature-later">Add payout method</label>
                                              </div> */}
                        </div>
                      )}
                      {this.state.IsAccountSelected &&
                        this.state.IsPayoutSubmit ? (
                        <div className="acc-text">
                          <div className="added-acc-box">
                            <i className="fas fa-university"></i>
                            <p>{parse(t('ViewEditPod:Step4.BankAccount'))}</p>
                            <span>
                              {this.state.AccountHolder +
                                ", " +
                                this.state.Accountnumber + parse(t('ViewEditPod:Step4.CurrencyCode'))}
                            </span>
                            <a
                              href="javacript:;"
                              style={{ pointerEvents: this.state.disabled ? "none" : null }}
                              className="remove-acc"
                              onClick={() =>
                                this.setState({
                                  IsPayoutSubmit: false,
                                  IsPayoutSelected: false,
                                  IsPaypalSelected: false,
                                  IsAccountSelected: false,
                                  StepBank: 1,
                                  StepPaypal: 1,
                                  BankFlat: "",

                                  AccountHolder: "",
                                  AccountHolderErr: false,
                                  AccountHolderhelperText: "",

                                  Bankname: "",
                                  BanknameErr: false,
                                  BanknamehelperText: "",

                                  Sortcode: "",
                                  SortcodeErr: false,
                                  SortcodehelperText: "",

                                  Accountnumber: "",
                                  AccountnumberErr: false,
                                  AccountnumberhelperText: "",

                                  Confirmaccountnumber: "",
                                  ConfirmaccountnumberErr: false,
                                  ConfirmaccountnumberhelperText: "",

                                  BankStreet: "",
                                  BankStreetErr: false,
                                  BankStreethelperText: "",

                                  BankCity: "",
                                  BankCityErr: false,
                                  BankCityhelperText: "",

                                  BankCountry: "",
                                  BankCountryErr: false,
                                  BankCountryhelperText: "",

                                  BankPostcode: "",
                                  BankPostcodeErr: false,
                                  BankPostcodehelperText: "",
                                  PaypalPayerID: "",
                                  PaypalEmail: "",
                                  PaypalEmailErr: false,
                                  PaypalEmailhelperText: "",
                                  PaypalConfirmEmail: "",
                                  PaypalConfirmEmailErr: false,
                                  PaypalConfirmEmailhelperText: "",
                                  PayPalFirstName: "",
                                  PayPalPhoneNumber: "",
                                  AccountType: "Savings",
                                })
                              }
                            >
                              <i className="fas fa-times"></i>
                            </a>
                          </div>
                        </div>
                      ) : this.state.IsPaypalSelected &&
                        this.state.IsPayoutSubmit ? (
                        <div className="acc-text">
                          <div className="added-acc-box">
                            <i class="fab fa-paypal"></i>
                            <p>{this.state.PaypalEmail + parse(t('ViewEditPod:Step4.CurrencyCode'))}</p>

                            <a
                              href="javacript:;"
                              style={{ pointerEvents: this.state.disabled ? "none" : null }}
                              className="remove-paypal-acc"
                              onClick={() =>
                                this.setState({
                                  IsPayoutSubmit: false,
                                  IsPayoutSelected: false,
                                  IsPaypalSelected: false,
                                  IsAccountSelected: false,
                                  StepBank: 1,
                                  StepPaypal: 1,
                                  BankFlat: "",

                                  AccountHolder: "",
                                  AccountHolderErr: false,
                                  AccountHolderhelperText: "",

                                  Bankname: "",
                                  BanknameErr: false,
                                  BanknamehelperText: "",

                                  Sortcode: "",
                                  SortcodeErr: false,
                                  SortcodehelperText: "",

                                  Accountnumber: "",
                                  AccountnumberErr: false,
                                  AccountnumberhelperText: "",

                                  Confirmaccountnumber: "",
                                  ConfirmaccountnumberErr: false,
                                  ConfirmaccountnumberhelperText: "",

                                  BankStreet: "",
                                  BankStreetErr: false,
                                  BankStreethelperText: "",

                                  BankCity: "",
                                  BankCityErr: false,
                                  BankCityhelperText: "",

                                  BankCountry: "",
                                  BankCountryErr: false,
                                  BankCountryhelperText: "",

                                  BankPostcode: "",
                                  BankPostcodeErr: false,
                                  BankPostcodehelperText: "",
                                  PaypalPayerID: "",
                                  PaypalEmail: "",
                                  PaypalEmailErr: false,
                                  PaypalEmailhelperText: "",
                                  PaypalConfirmEmail: "",
                                  PaypalConfirmEmailErr: false,
                                  PaypalConfirmEmailhelperText: "",
                                  PayPalFirstName: "",
                                  PayPalPhoneNumber: "",

                                  AccountType: "Savings",
                                })
                              }
                            >
                              <i className="fas fa-times"></i>
                            </a>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="apc-footer">
                    <a
                      href="javascript:;"
                      onClick={() =>
                        this.setState({
                          Step: this.state.Step - 1,
                          dynamicProgress: this.state.dynamicProgress - 11.11,
                        })
                      }
                      className="btn"
                    >
                      <i className="fas fa-angle-left"></i> Back
                    </a>
                    <button
                      onClick={() => this.validate()}
                      className="btn btn-primary"
                    >
                      {(this.state.IsAccountSelected &&
                        this.state.IsPayoutSubmit) ||
                        (this.state.IsPaypalSelected && this.state.IsPayoutSubmit)
                        ? "Next"
                        : "Skip for Now"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4"></div>
            </div>
          </div>
        </div>
      );
    } else if (Step == 8) {
      return (
        <div className="reg-summary-outer">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="reg-summary">
                  <h2>{parse(t('ViewEditPod:Step8.FinishText'))}</h2>
                  {/* <p>You can always edit your listing after you publish it.</p> */}
                  <ul>
                    {this.state.Heading.map((x, idx) => {
                      return (
                        <li
                          className="completed"
                          key={idx}
                          onClick={() => this.setState({ Step: idx })}
                        >
                          <i class="fas fa-check"></i>
                          <p>{x}</p>
                        </li>
                      );
                    })}

                    {/* <li className="active">
                                            <i class="fas fa-check"></i>
                                            <p>Location</p>
                                            <a href="#" className="btn btn-primary">Continue</a>
                                        </li>
                                        <li className="pending">
                                            <i class="fas fa-check"></i>
                                            <p>Amenities</p>
                                        </li>
                                        <li className="pending">
                                            <i class="fas fa-check"></i>
                                            <p>Photos</p>
                                        </li>
                                        <li className="pending">
                                            <i class="fas fa-check"></i>
                                            <p>Description and title</p>
                                        </li>
                                        <li className="pending">
                                            <i class="fas fa-check"></i>
                                            <p>Booking setting</p>
                                        </li>
                                        <li className="pending">
                                            <i class="fas fa-check"></i>
                                            <p>Calendar and availability</p>
                                        </li>
                                        <li className="pending">
                                            <i class="fas fa-check"></i>
                                            <p>Pricing</p>
                                        </li>
                                        <li className="pending">
                                            <i class="fas fa-check"></i>
                                            <p>Review</p>
                                        </li> */}
                  </ul>
                </div>
              </div>
              {this.state.PropertyStatus === "Under Review" ||
                this.state.PropertyStatus === "Declined" ? <p>
                <i class="fas fa-info-circle"></i> {parse(t('ViewEditPod:Step8.SubmitText'))}
              </p> : null}
            </div>
            {this.state.isRejectButtonNotClicked &&
              (this.state.PropertyStatus === "Under Review" ||
                this.state.PropertyStatus === "Declined") ? (
              <div className="apc-footer">
                <div className="">
                  <a
                    href="javascript:;"
                    onClick={() => this.reject()}
                    className="btn btn-secondary"
                  >
                    Reject
                  </a>
                  <a
                    href="javascript:;"
                    onClick={() => this.approve()}
                    className="btn btn-primary ml-5"
                  >
                    Approve
                  </a>
                </div>
              </div>
            ) : this.state.isReject ? (
              <>
                <div className="form-group">
                  <textarea
                    className={[
                      this.state.ReasonErr
                        ? "form-control mb-10 is-invalid"
                        : "form-control mb-10",
                    ]}
                    rows="3"
                    maxLength="250"
                    placeholder="Reason to decline this pod.."
                    value={this.state.Reason}
                    onChange={(e) => this.handleChange(e, "Reason")}
                  ></textarea>
                  <div className="invalid-feedback">
                    {this.state.ReasonHelperText}
                  </div>
                </div>
                <div className="apc-footer">
                  <a
                    href="javascript:;"
                    onClick={() => this.rejectPod()}
                    className="btn btn-secondary"
                  >
                    Reject
                  </a>
                </div>
              </>
            ) : (
              <div className="apc-footer">
                <a
                  href="javascript:;"
                  onClick={() => this.goBack()}
                  className="btn btn-primary"
                >
                  Go to Listing
                </a>
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  render() {
    const { photoIndex, isOpen, IsPayoutSelected, IsPayoutSubmit, loading } = this.state;
    const {t} = this.props;

    const country = t("ViewEditPod:CountryCode");

    const searchOptions = {
        componentRestrictions: { country: [country] },
    }

    return (
      loading ? (<Loader />) : 
      <div>
        <div className="add-place-header">
          <div className="container-fluid">
            <div className="aph-inner">
              <div className="logo">
                <a href="/admin/homepage">
                  <img src={logo} alt="" />
                </a>
              </div>
              <span>{this.state.Heading[this.state.Step]}</span>
            </div>
          </div>
        </div>

        <div className="add-place-progressbar">
          <div
            className="fill"
            style={{ width: this.state.dynamicProgress + "%" }}
          ></div>
        </div>
        <div className="wizard-step mt-5 hosting-wizard">
                    <ul>
                        {this.state.Heading.map((x, idx) => {
                            return (
                                <li>
                                    <a href="javascript:;" className={this.state.Step == idx ? "active" : this.state.Step > idx ? "completed" : "pending"}>
                                        <span>{idx + 1}</span>
                                        <p>{x}</p>
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                </div>


        {this.getSteps()}
        <Modal
          show={IsPayoutSelected && !IsPayoutSubmit}
          onHide={() =>
            this.setState({
              IsPayoutSelected: false,
              IsPaypalSelected: false,
              IsAccountSelected: false,
              StepBank: 1,
              StepPaypal: 1,
            })
          }
          centered
        >
          <div className="custom-modal-login-inner">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={() =>
                    this.setState({
                      IsPayoutSelected: false,
                      IsPaypalSelected: false,
                      IsAccountSelected: false,
                      StepBank: 1,
                      StepPaypal: 1,
                    })
                  }
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="add-bank-outer">
                  {this.state.IsPayoutSelected &&
                    this.state.IsAccountSelected &&
                    this.state.StepBank == 2 ? (
                    <form>
                      <div className="add-bank-info">
                        <h4>{parse(t('ViewEditPod:Step4.AccountInfo'))}</h4>
                        {/* <div className="apc-heading">
                                                    <h6>Bank account type</h6>
                                                </div>
                                                <div className="bank-info-radio">
                                                    <div className="form-check form-check-inline">
                                                        <input type="radio" className="form-check-input" id="checking-radio" checked={this.state.AccountType == "Checking" ? true : false} onChange={(e) => this.setState({ AccountType: "Checking" })}></input>
                                                        <label className="form-check-label" for="checking-radio">
                                                            Checking
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input type="radio" className="form-check-input" id="savings-radio" checked={this.state.AccountType == "Checking" ? false : true} onChange={(e) => this.setState({ AccountType: "Savings" })}></input>
                                                        <label className="form-check-label" for="savings-radio">
                                                            Savings
                                                        </label>
                                                    </div>
                                                    <div className="invalid-feedback">{this.state.AccountTypehelperText}</div>
                                                </div> */}

                        <div className="form-group">
                          <label>{parse(t('ViewEditPod:Step4.AccountHolderName'))}</label>
                          <input
                            type="text"
                            className={
                              this.state.AccountHolderErr
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            placeholder="E.g. Clara Smith"
                            value={this.state.AccountHolder}
                            onChange={(e) =>
                              this.handleChange(e, "AccountHolder")
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.AccountHolderhelperText}
                          </div>
                        </div>

                        <div className="form-group">
                          <label>{parse(t('ViewEditPod:Step4.BankName'))}</label>
                          <input
                            type="text"
                            className={
                              this.state.BanknameErr
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            value={this.state.Bankname}
                            onChange={(e) => this.handleChange(e, "Bankname")}
                          />
                          <div className="invalid-feedback">
                            {this.state.BanknamehelperText}
                          </div>
                        </div>

                        <div className="form-group">
                          <label>{parse(t('ViewEditPod:Step4.SortCode'))}</label>
                          <input
                            type="text"
                            maxLength="8"
                            className={
                              this.state.SortcodeErr
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            placeholder="Ex. 11-22-33"
                            value={this.state.Sortcode}
                            onChange={(e) => this.handleChange(e, "Sortcode")}
                          />
                          <div className="invalid-feedback">
                            {this.state.SortcodehelperText}
                          </div>
                        </div>

                        <div className="form-group">
                          <label>{parse(t('ViewEditPod:Step4.AccountNo'))}</label>
                          <input
                            type="text"
                            className={
                              this.state.AccountnumberErr
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            placeholder="Ex. 1234567890"
                            value={this.state.Accountnumber}
                            onChange={(e) =>
                              this.handleChange(e, "Accountnumber")
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.AccountnumberhelperText}
                          </div>
                        </div>

                        <div className="form-group">
                          <label>{parse(t('ViewEditPod:Step4.ConfirmAccountNo'))}</label>
                          <input
                            type="text"
                            className={
                              this.state.ConfirmaccountnumberErr
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            placeholder=""
                            value={this.state.Confirmaccountnumber}
                            onChange={(e) =>
                              this.handleChange(e, "Confirmaccountnumber")
                            }
                          />
                          <div className="invalid-feedback">
                            {this.state.ConfirmaccountnumberhelperText}
                          </div>
                        </div>
                        {/* </div>
                                                                </div> */}
                        <div className="apc-footer">
                          <a
                            href="javascript:;"
                            onClick={() =>
                              this.setState({
                                StepBank: this.state.StepBank - 1,
                              })
                            }
                            className="btn"
                          >
                            <i className="fas fa-angle-left"></i> Back
                          </a>
                          <button
                            className="btn btn-primary"
                            onClick={(event) =>
                              this.validatePayout(event, "Bank")
                            }
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </form>
                  ) : this.state.IsPayoutSelected &&
                    this.state.IsAccountSelected &&
                    this.state.StepBank == 3 ? (
                    <form>
                      <div className="add-bank-info">
                        <h4>{parse(t('ViewEditPod:Step4.PayoutAddress'))}</h4>
                        <span>
                        {parse(t('ViewEditPod:Step4.PayoutAddressText'))}
                        </span>

                        <div className="form-group">
                          <label>{parse(t('ViewEditPod:Step4.Country'))}</label>
                          <select className="form-control">
                            <option>{parse(t('ViewEditPod:Step4.CountryName'))}</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label>{parse(t('ViewEditPod:Step4.Flat'))}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Flat"
                            value={this.state.BankFlat}
                            onChange={(e) => this.handleChange(e, "BANKFLAT")}
                          />
                          <span className="input-tagline">{parse(t('ViewEditPod:Step4.FlatExampleText'))}</span>
                        </div>
                        <div className="form-group">
                          <label>{parse(t('ViewEditPod:Step4.Street'))}</label>
                          <PlacesAutocomplete
                            searchOptions = {searchOptions}
                            value={this.state.BankStreet}
                            onChange={(e) =>
                              this.handleChangePlaces(e, "BankStreet")
                            }
                            onSelect={(e) => this.handleSelect(e, "BankStreet")}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div className="landing-main-search">
                                <input
                                  {...getInputProps({
                                    placeholder: "Street",
                                    // className: 'form-control mb-10 location-search-input',
                                    className: [
                                      this.state.BankStreetErr
                                        ? "form-control mb-10 location-search-input is-invalid"
                                        : "form-control mb-10 location-search-input",
                                    ],
                                    // className="form-control mb-10"
                                  })}
                                />
                                <div className="invalid-feedback">
                                  {this.state.BankStreethelperText}
                                </div>
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                      : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>
                                          <i className="fas fa-map-marker-alt"></i>
                                          {suggestion.description}
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>

                          <span className="input-tagline">
                          {parse(t('ViewEditPod:Step4.StreetExampleText'))}
                          </span>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label>{parse(t('ViewEditPod:Step4.City'))}</label>
                              <input
                                type="text"
                                className={[
                                  this.state.BankCityErr
                                    ? "form-control is-invalid"
                                    : "form-control",
                                ]}
                                value={this.state.BankCity}
                                onChange={(e) =>
                                  this.handleChange(e, "BANKCITY")
                                }
                              />
                              <div className="invalid-feedback">
                                {this.state.BankCityhelperText}
                              </div>
                              <span className="input-tagline">{parse(t('ViewEditPod:Step4.CityExampleText'))}</span>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label>{parse(t('ViewEditPod:Step4.County'))}</label>
                              <input
                                type="text"
                                value={this.state.BankCountry}
                                onChange={(e) =>
                                  this.handleChange(e, "BANKCOUNTRY")
                                }
                                className={[
                                  this.state.BankCountryErr
                                    ? "form-control is-invalid"
                                    : "form-control",
                                ]}
                              />
                              <div className="invalid-feedback">
                                {this.state.BankCountryhelperText}
                              </div>
                              <span className="input-tagline">
                              {parse(t('ViewEditPod:Step4.CountyExampleText'))}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label>{parse(t('ViewEditPod:Step4.PostCode'))}</label>
                              <input
                                type="text"
                                value={this.state.BankPostcode}
                                onChange={(e) =>
                                  this.handleChange(e, "BANKPOSTCODE")
                                }
                                className={[
                                  this.state.BankPostcodeErr
                                    ? "form-control is-invalid"
                                    : "form-control",
                                ]}
                              />
                              <div className="invalid-feedback">
                                {this.state.BankPostcodehelperText}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="apc-footer">
                          <a
                            href="javascript:;"
                            onClick={() =>
                              this.setState({
                                StepBank: this.state.StepBank - 1,
                              })
                            }
                            className="btn"
                          >
                            <i className="fas fa-angle-left"></i> Back
                          </a>
                          <button
                            className="btn btn-primary"
                            onClick={(event) =>
                              this.validatePayout(event, "Bank")
                            }
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </form>
                  ) : this.state.IsPayoutSelected &&
                    this.state.IsAccountSelected &&
                    this.state.StepBank == 4 ? (
                    <div className="confirm-bank-info">
                      <h4>{parse(t('ViewEditPod:Step4.ConfirmAccountInfo'))}</h4>
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="added-info-box">
                            <h6>{parse(t('ViewEditPod:Step4.PayoutMethodLabel'))}</h6>
                            <p>{parse(t('ViewEditPod:Step4.PayoutMethodType'))}</p>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="added-info-box">
                            <h6>{parse(t('ViewEditPod:Step4.BankAccountType'))}</h6>
                            <p>{this.state.AccountType}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="added-info-box">
                            <h6>{parse(t('ViewEditPod:Step4.AccountHolderName'))}</h6>
                            <p>{this.state.AccountHolder}</p>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="added-info-box">
                            <h6>{parse(t('ViewEditPod:Step4.BankName'))}</h6>
                            <p>{this.state.Bankname}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="added-info-box">
                            <h6>{parse(t('ViewEditPod:Step4.SortCode'))}</h6>
                            <p>{this.state.Sortcode}</p>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="added-info-box">
                            <h6>{parse(t('ViewEditPod:Step4.AccountNo'))}</h6>
                            <p>{this.state.Accountnumber}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="added-info-box">
                            <h6>{parse(t('ViewEditPod:Step4.AccountAddress'))}</h6>
                            <p>{this.state.BankStreet}</p>
                          </div>
                        </div>
                      </div>
                      <div className="apc-footer">
                        <button
                          onClick={() =>
                            this.setState({ StepBank: this.state.StepBank - 1 })
                          }
                          className="btn"
                        >
                          <i className="fas fa-angle-left"></i> Back
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            this.setState({
                              IsPayoutSubmit: true,
                              IsPayoutSelected: true,
                              IsPaypalSelected: false,
                              IsAccountSelected: true,
                              StepBank: 1,
                              StepPaypal: 1,
                            })
                          }
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  ) : this.state.IsPayoutSelected &&
                    this.state.IsPaypalSelected &&
                    this.state.StepPaypal == 2 ? (
                    <div className="container">
                      <div className="payment-info-text">
                        {/* <h4>PayPal</h4> */}
                        <h4>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            version="1.1"
                            id="Layer_1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 122.88 29.77"
                            style={{ enableBackground: "new 0 0 122.88 29.77" }}
                            space="preserve"
                          >
                            <g>
                              <path
                                style={{
                                  fillRule: "evenodd",
                                  clipRule: "evenodd",
                                  fill: "#009EE3",
                                }}
                                d="M10.72,7.01h8.4c4.51,0,6.21,2.28,5.94,5.64c-0.43,5.54-3.78,8.6-8.22,8.6H14.6c-0.61,0-1.02,0.4-1.18,1.5 l-0.95,6.35c-0.06,0.41-0.28,0.65-0.6,0.68H6.59c-0.5,0-0.67-0.38-0.54-1.2L9.26,8.21C9.39,7.4,9.83,7.01,10.72,7.01L10.72,7.01z"
                              />
                              <path
                                style={{
                                  fillRule: "evenodd",
                                  clipRule: "evenodd",
                                  fill: "#113984",
                                }}
                                d="M47.18,6.63c2.83,0,5.45,1.54,5.09,5.37c-0.43,4.55-2.87,7.07-6.72,7.08h-3.36c-0.48,0-0.72,0.39-0.84,1.2 l-0.65,4.13c-0.1,0.62-0.42,0.93-0.89,0.93h-3.13c-0.5,0-0.67-0.32-0.56-1.03l2.58-16.57c0.13-0.82,0.43-1.12,0.99-1.12H47.18 L47.18,6.63L47.18,6.63z M42.08,15.5h2.55c1.59-0.06,2.65-1.16,2.76-3.15c0.06-1.23-0.77-2.11-2.09-2.1l-2.4,0.01L42.08,15.5 L42.08,15.5L42.08,15.5z M60.77,24.08c0.29-0.26,0.58-0.39,0.54-0.07l-0.1,0.77c-0.05,0.4,0.11,0.61,0.48,0.61h2.78 c0.47,0,0.7-0.19,0.81-0.91l1.71-10.75c0.09-0.54-0.05-0.8-0.46-0.8h-3.06c-0.28,0-0.41,0.15-0.48,0.57l-0.11,0.66 c-0.06,0.34-0.22,0.41-0.36,0.06c-0.52-1.23-1.84-1.78-3.69-1.73c-4.29,0.09-7.17,3.34-7.48,7.51c-0.24,3.23,2.07,5.76,5.12,5.76 C58.68,25.75,59.67,25.1,60.77,24.08L60.77,24.08L60.77,24.08L60.77,24.08z M58.44,22.43c-1.84,0-3.13-1.47-2.86-3.28 c0.27-1.8,1.99-3.28,3.84-3.28s3.13,1.47,2.86,3.28C62.01,20.95,60.29,22.43,58.44,22.43L58.44,22.43L58.44,22.43z M72.42,12.89 h-2.82c-0.58,0-0.82,0.43-0.63,0.97l3.5,10.24l-3.43,4.87c-0.29,0.41-0.06,0.78,0.34,0.78h3.17c0.04,0,0.07,0.01,0.11,0.01 c0.34,0,0.65-0.18,0.83-0.47l10.76-15.43c0.33-0.47,0.18-0.97-0.37-0.97h-3c-0.51,0-0.72,0.2-1.01,0.63l-4.49,6.5l-2-6.52 C73.26,13.1,72.97,12.89,72.42,12.89L72.42,12.89L72.42,12.89z"
                              />
                              <path
                                style={{
                                  fillRule: "evenodd",
                                  clipRule: "evenodd",
                                  fill: "#009EE3",
                                }}
                                d="M95.44,6.63c2.83,0,5.45,1.54,5.09,5.37c-0.43,4.55-2.87,7.07-6.72,7.08h-3.36c-0.48,0-0.72,0.39-0.84,1.2 l-0.65,4.13c-0.1,0.62-0.42,0.93-0.89,0.93h-3.13c-0.5,0-0.67-0.32-0.56-1.03l2.59-16.57c0.13-0.81,0.43-1.12,0.99-1.12L95.44,6.63 L95.44,6.63L95.44,6.63z M90.35,15.5h2.55c1.59-0.06,2.65-1.16,2.76-3.15c0.06-1.23-0.77-2.11-2.09-2.1l-2.4,0.01L90.35,15.5 L90.35,15.5L90.35,15.5z M109.03,24.08c0.29-0.26,0.58-0.39,0.54-0.07l-0.1,0.77c-0.05,0.4,0.11,0.61,0.48,0.61h2.78 c0.47,0,0.7-0.19,0.81-0.91l1.71-10.75c0.09-0.54-0.05-0.8-0.46-0.8h-3.05c-0.28,0-0.41,0.15-0.48,0.57l-0.11,0.66 c-0.06,0.34-0.22,0.41-0.36,0.06c-0.52-1.23-1.84-1.78-3.68-1.73c-4.29,0.09-7.17,3.34-7.48,7.51c-0.24,3.23,2.07,5.76,5.12,5.76 c2.21,0,3.2-0.65,4.31-1.67l0,0L109.03,24.08L109.03,24.08z M106.71,22.43c-1.84,0-3.13-1.47-2.86-3.28 c0.27-1.8,1.99-3.28,3.84-3.28c1.84,0,3.13,1.47,2.86,3.28C110.28,20.95,108.55,22.43,106.71,22.43L106.71,22.43L106.71,22.43z M119.52,25.4h-3.21c-0.01,0-0.01,0-0.02,0c-0.21,0-0.38-0.17-0.38-0.38c0-0.02,0-0.04,0-0.05l2.82-17.85 c0.06-0.25,0.28-0.43,0.53-0.43h3.21c0.01,0,0.01,0,0.02,0c0.21,0,0.38,0.17,0.38,0.38c0,0.02,0,0.04,0,0.05l-2.82,17.85 C120,25.22,119.78,25.4,119.52,25.4L119.52,25.4L119.52,25.4z"
                              />
                              <path
                                style={{
                                  fillRule: "evenodd",
                                  clipRule: "evenodd",
                                  fill: "#113984",
                                }}
                                d="M5.47,0h8.41c2.37,0,5.18,0.08,7.05,1.73c1.25,1.11,1.91,2.87,1.76,4.77c-0.52,6.42-4.35,10.01-9.5,10.01H9.04 c-0.71,0-1.17,0.47-1.37,1.73l-1.16,7.37c-0.08,0.48-0.28,0.76-0.65,0.79H0.68c-0.57,0-0.78-0.43-0.63-1.39L3.78,1.4 C3.93,0.45,4.45,0,5.47,0L5.47,0z"
                              />
                              <path
                                style={{
                                  fillRule: "evenodd",
                                  clipRule: "evenodd",
                                  fill: "#172C70",
                                }}
                                d="M7.79,17.5l1.47-9.29c0.13-0.81,0.57-1.21,1.46-1.21h8.4c1.39,0,2.51,0.22,3.39,0.62 c-0.84,5.71-4.54,8.89-9.38,8.89H9C8.44,16.51,8.04,16.79,7.79,17.5L7.79,17.5z"
                              />
                            </g>
                          </svg>
                        </h4>
                        <h6>{parse(t('ViewEditPod:Step4.PaypalHeader'))}</h6>
                        {/* <h6>Get paid in 3-4 hours</h6>
                                                            <p>
                                                                When you click next, you will be redirected to another website where you may securely enter your banking details. After completing the requested information you will be redirected back to WorkPod.
                                                            </p> */}
                      </div>
                      {/* <div className="apc-footer">
                                                            <a href="javascript:;" onClick={() => this.setState({ StepBank: this.state.StepBank - 1 })} className="btn"><i className="fas fa-angle-left"></i> Back</a>
                                                            <a href="https://www.sandbox.paypal.com/connect?flowEntry=static&client_id=AUcFwMbRADq56AaIHl2wDrPyPpsFN-d4UQULCcCzvjNSdQ6SJcUtGkwkf2xNgzzTwKyoRVRYQIhz4yyo&response_type=code&scope=openid profile email address https://uri.paypal.com/services/paypalattributes&redirect_uri=https://uat.theworkpod.co.uk/admin/registration" className="btn btn-primary" onClick={() => {
                                                                // 
                                                                localStorage.setItem('PaypalHistoryData', JSON.stringify(this.state))
                                                            }}>Login with PayPal</a>
                                                        </div> */}
                      <div className="form-group">
                        <label>{parse(t('ViewEditPod:Step4.PaypalEmail'))}</label>
                        <input
                          type="text"
                          className={
                            this.state.PaypalEmailErr
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          placeholder="E.g. abc@gmail.com"
                          value={this.state.PaypalEmail}
                          onChange={(e) => this.handleChange(e, "PaypalEmail")}
                        />
                        <div className="invalid-feedback">
                          {this.state.PaypalEmailhelperText}
                        </div>
                      </div>
                      <div className="form-group">
                        <label>{parse(t('ViewEditPod:Step4.ConfirmPaypalEmail'))}</label>
                        <input
                          type="text"
                          className={
                            this.state.PaypalConfirmEmailErr
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          placeholder="E.g. abc@gmail.com"
                          value={this.state.PaypalConfirmEmail}
                          onChange={(e) =>
                            this.handleChange(e, "PaypalConfirmEmail")
                          }
                        />
                        <div className="invalid-feedback">
                          {this.state.PaypalConfirmEmailhelperText}
                        </div>
                      </div>
                      <div className="apc-footer">
                        <button
                          onClick={() =>
                            this.setState({
                              StepPaypal: this.state.StepPaypal - 1,
                            })
                          }
                          className="btn"
                        >
                          <i className="fas fa-angle-left"></i> Back
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={(e) => this.validatePayout(e, "PayPal")}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="container">
                      <div className="add-place-inner">
                        <div className="add-place-content">
                          <div className="apc-inner-content">
                            <div className="row">
                              <div className="col-lg-12 col-md-12">
                                <div className="apc-heading">
                                  <h4>{parse(t('ViewEditPod:Step4.CountryLabel'))}</h4>
                                </div>
                                <div className="form-group">
                                  <select
                                    className={[
                                      this.state.CountryDropDownErr
                                        ? "form-control is-invalid"
                                        : "form-control",
                                    ]}
                                    value={this.state.CountryDropDown}
                                    onChange={(e) =>
                                      this.handleChange(e, "COUNTRY")
                                    }
                                  >
                                    {/* <option value="" selected disabled hidden>Select one</option> */}
                                    <option>{parse(t('ViewEditPod:Step4.CountryName'))}</option>
                                  </select>
                                  <div className="invalid-feedback">
                                    {this.state.CountryDropDownhelperText}
                                  </div>
                                </div>
                                <div className="payout-method">
                                  <h4>{parse(t('ViewEditPod:Step4.PayoutCountryText'))}</h4>
                                  <ul>
                                    {t('ViewEditPod:CountryISOCode') === "GB" ?
                                      <li>
                                        <label>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xlink="http://www.w3.org/1999/xlink"
                                            version="1.1"
                                            id="Layer_1"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 350 29.77"
                                            style={{
                                              enableBackground:
                                                "new 0 0 122.88 29.77",
                                            }}
                                            space="preserve"
                                          >
                                            <g>
                                              <path
                                                style={{
                                                  fillRule: "evenodd",
                                                  clipRule: "evenodd",
                                                  fill: "#009EE3",
                                                }}
                                                d="M10.72,7.01h8.4c4.51,0,6.21,2.28,5.94,5.64c-0.43,5.54-3.78,8.6-8.22,8.6H14.6c-0.61,0-1.02,0.4-1.18,1.5 l-0.95,6.35c-0.06,0.41-0.28,0.65-0.6,0.68H6.59c-0.5,0-0.67-0.38-0.54-1.2L9.26,8.21C9.39,7.4,9.83,7.01,10.72,7.01L10.72,7.01z"
                                              />
                                              <path
                                                style={{
                                                  fillRule: "evenodd",
                                                  clipRule: "evenodd",
                                                  fill: "#113984",
                                                }}
                                                d="M47.18,6.63c2.83,0,5.45,1.54,5.09,5.37c-0.43,4.55-2.87,7.07-6.72,7.08h-3.36c-0.48,0-0.72,0.39-0.84,1.2 l-0.65,4.13c-0.1,0.62-0.42,0.93-0.89,0.93h-3.13c-0.5,0-0.67-0.32-0.56-1.03l2.58-16.57c0.13-0.82,0.43-1.12,0.99-1.12H47.18 L47.18,6.63L47.18,6.63z M42.08,15.5h2.55c1.59-0.06,2.65-1.16,2.76-3.15c0.06-1.23-0.77-2.11-2.09-2.1l-2.4,0.01L42.08,15.5 L42.08,15.5L42.08,15.5z M60.77,24.08c0.29-0.26,0.58-0.39,0.54-0.07l-0.1,0.77c-0.05,0.4,0.11,0.61,0.48,0.61h2.78 c0.47,0,0.7-0.19,0.81-0.91l1.71-10.75c0.09-0.54-0.05-0.8-0.46-0.8h-3.06c-0.28,0-0.41,0.15-0.48,0.57l-0.11,0.66 c-0.06,0.34-0.22,0.41-0.36,0.06c-0.52-1.23-1.84-1.78-3.69-1.73c-4.29,0.09-7.17,3.34-7.48,7.51c-0.24,3.23,2.07,5.76,5.12,5.76 C58.68,25.75,59.67,25.1,60.77,24.08L60.77,24.08L60.77,24.08L60.77,24.08z M58.44,22.43c-1.84,0-3.13-1.47-2.86-3.28 c0.27-1.8,1.99-3.28,3.84-3.28s3.13,1.47,2.86,3.28C62.01,20.95,60.29,22.43,58.44,22.43L58.44,22.43L58.44,22.43z M72.42,12.89 h-2.82c-0.58,0-0.82,0.43-0.63,0.97l3.5,10.24l-3.43,4.87c-0.29,0.41-0.06,0.78,0.34,0.78h3.17c0.04,0,0.07,0.01,0.11,0.01 c0.34,0,0.65-0.18,0.83-0.47l10.76-15.43c0.33-0.47,0.18-0.97-0.37-0.97h-3c-0.51,0-0.72,0.2-1.01,0.63l-4.49,6.5l-2-6.52 C73.26,13.1,72.97,12.89,72.42,12.89L72.42,12.89L72.42,12.89z"
                                              />
                                              <path
                                                style={{
                                                  fillRule: "evenodd",
                                                  clipRule: "evenodd",
                                                  fill: "#009EE3",
                                                }}
                                                d="M95.44,6.63c2.83,0,5.45,1.54,5.09,5.37c-0.43,4.55-2.87,7.07-6.72,7.08h-3.36c-0.48,0-0.72,0.39-0.84,1.2 l-0.65,4.13c-0.1,0.62-0.42,0.93-0.89,0.93h-3.13c-0.5,0-0.67-0.32-0.56-1.03l2.59-16.57c0.13-0.81,0.43-1.12,0.99-1.12L95.44,6.63 L95.44,6.63L95.44,6.63z M90.35,15.5h2.55c1.59-0.06,2.65-1.16,2.76-3.15c0.06-1.23-0.77-2.11-2.09-2.1l-2.4,0.01L90.35,15.5 L90.35,15.5L90.35,15.5z M109.03,24.08c0.29-0.26,0.58-0.39,0.54-0.07l-0.1,0.77c-0.05,0.4,0.11,0.61,0.48,0.61h2.78 c0.47,0,0.7-0.19,0.81-0.91l1.71-10.75c0.09-0.54-0.05-0.8-0.46-0.8h-3.05c-0.28,0-0.41,0.15-0.48,0.57l-0.11,0.66 c-0.06,0.34-0.22,0.41-0.36,0.06c-0.52-1.23-1.84-1.78-3.68-1.73c-4.29,0.09-7.17,3.34-7.48,7.51c-0.24,3.23,2.07,5.76,5.12,5.76 c2.21,0,3.2-0.65,4.31-1.67l0,0L109.03,24.08L109.03,24.08z M106.71,22.43c-1.84,0-3.13-1.47-2.86-3.28 c0.27-1.8,1.99-3.28,3.84-3.28c1.84,0,3.13,1.47,2.86,3.28C110.28,20.95,108.55,22.43,106.71,22.43L106.71,22.43L106.71,22.43z M119.52,25.4h-3.21c-0.01,0-0.01,0-0.02,0c-0.21,0-0.38-0.17-0.38-0.38c0-0.02,0-0.04,0-0.05l2.82-17.85 c0.06-0.25,0.28-0.43,0.53-0.43h3.21c0.01,0,0.01,0,0.02,0c0.21,0,0.38,0.17,0.38,0.38c0,0.02,0,0.04,0,0.05l-2.82,17.85 C120,25.22,119.78,25.4,119.52,25.4L119.52,25.4L119.52,25.4z"
                                              />
                                              <path
                                                style={{
                                                  fillRule: "evenodd",
                                                  clipRule: "evenodd",
                                                  fill: "#113984",
                                                }}
                                                d="M5.47,0h8.41c2.37,0,5.18,0.08,7.05,1.73c1.25,1.11,1.91,2.87,1.76,4.77c-0.52,6.42-4.35,10.01-9.5,10.01H9.04 c-0.71,0-1.17,0.47-1.37,1.73l-1.16,7.37c-0.08,0.48-0.28,0.76-0.65,0.79H0.68c-0.57,0-0.78-0.43-0.63-1.39L3.78,1.4 C3.93,0.45,4.45,0,5.47,0L5.47,0z"
                                              />
                                              <path
                                                style={{
                                                  fillRule: "evenodd",
                                                  clipRule: "evenodd",
                                                  fill: "#172C70",
                                                }}
                                                d="M7.79,17.5l1.47-9.29c0.13-0.81,0.57-1.21,1.46-1.21h8.4c1.39,0,2.51,0.22,3.39,0.62 c-0.84,5.71-4.54,8.89-9.38,8.89H9C8.44,16.51,8.04,16.79,7.79,17.5L7.79,17.5z"
                                              />
                                            </g>
                                          </svg>
                                          <input
                                            type="radio"
                                            className="input-control"
                                            checked={this.state.IsPaypalSelected}
                                            onChange={(e) =>
                                              this.handleChange(
                                                e,
                                                "IsPaypalSelected"
                                              )
                                            }
                                          ></input>
                                        </label>
                                        <ul className="payout-tags">
                                          <li>{parse(t('ViewEditPod:Step4.PaypalRadio1'))}</li>
                                          <li>
                                          {parse(t('ViewEditPod:Step4.PaypalRadio2'))}
                                        </li>
                                          <li>{parse(t('ViewEditPod:Step4.PaypalRadio3'))}</li>
                                        </ul>
                                      </li>
                                      : null}
                                    <li>
                                      <label>
                                        <span>{parse(t('ViewEditPod:Step4.BankTransfer'))}</span>
                                        <input
                                          type="radio"
                                          className="input-control"
                                          checked={this.state.IsAccountSelected}
                                          onChange={(e) =>
                                            this.handleChange(
                                              e,
                                              "IsAccountSelected"
                                            )
                                          }
                                        ></input>
                                      </label>
                                      <ul className="payout-tags">
                                        <li>{parse(t('ViewEditPod:Step4.BankTransferRadio1'))}</li>
                                        <li>
                                        {parse(t('ViewEditPod:Step4.BankTransferRadio2'))}
                                        </li>
                                        <li>{parse(t('ViewEditPod:Step4.BankTransferRadio3'))}</li>
                                      </ul>
                                    </li>

                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="apc-footer">
                              <a
                                href="javascript:;"
                                onClick={() =>
                                  this.setState({
                                    IsPayoutSelected: false,
                                    IsPaypalSelected: false,
                                    IsAccountSelected: false,
                                    StepBank: 1,
                                    StepPaypal: 1,
                                  })
                                }
                                className="btn"
                              >
                                <i className="fas fa-angle-left"></i> Back
                              </a>
                              <button
                                className="btn btn-primary"
                                disabled={
                                  this.state.IsPaypalSelected == true ||
                                    this.state.IsAccountSelected == true
                                    ? false
                                    : true
                                }
                                onClick={() => {
                                  if (this.state.IsPaypalSelected) {
                                    this.setState({
                                      StepPaypal: this.state.StepPaypal + 1,
                                    });
                                  } else if (this.state.IsAccountSelected) {
                                    this.setState({
                                      StepBank: this.state.StepBank + 1,
                                    });
                                  }
                                }}
                              >
                                Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </Modal>

        {isOpen && (
          <Lightbox
            mainSrc={apiBase + this.state.files[photoIndex]["name"]}
            nextSrc={
              this.state.files[(photoIndex + 1) % this.state.files.length]
            }
            prevSrc={
              this.state.files[
              (photoIndex + this.state.files.length - 1) %
              this.state.files.length
              ]
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex + this.state.files.length - 1) %
                  this.state.files.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % this.state.files.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAww6TxPSzQbg74ljJahxvuLHmRfICHbf4",
})(withNamespaces()(ViewEditPropertyByAdmin));