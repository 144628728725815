import React, { Component } from 'react'
import api from '../../../src/utils/apiClient';
import { apiBase } from '../../../src/utils/config';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import parse from 'html-react-parser';
import { CommonConfig } from 'utils/constant';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import listingimage from "../../assets/img/listing-image.jpg";
import moment from 'moment';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

class WishList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PersonID: CommonConfig.loginData().PersonID,
            PersonType: CommonConfig.loginData().PersonType,
            wishList: []
        }
    }
    componentDidMount() {
        this.getMyWishList()
    }

    getMyWishList = () => {
        let data = {
            "UserID": this.state.PersonID
        }
        try {
            api.post("user/getMyFavoritesList", data).then(res => {
                if (res.success) {
                    this.setState({ wishList: res.data });
                }
                else {
                }
            }).catch(err => {
                // // console.log(err)
            })
        } catch (err) {
        }
    }

    detailspage = (id) => {
        this.props.history.push({
            pathname: "/admin/detailpage",
            state: {
                PropertyID: id
            }
        })
    }

    render() {
        const { wishList } = this.state;
        return (
            <div className="host-dashboard-outer">
                <div className="container">
                    <div className="host-page-heading">
                        <h2>Favourite Pod</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            {wishList.length > 0 ?
                                <div className="user-booked-listing">
                                    {wishList.map((x, i) => {
                                        return i % 4 === 0 ? wishList.slice(i, i + 4) : null;
                                    }).filter(x => x != null).map((item, idx) => {
                                        return (
                                            <div className="row" key={idx}>
                                                {item.map(x => <div className="col-lg-3 col-md-3">
                                                    <div className="featured-pod-box">
                                                        <div className="fpb-image">
                                                            <div className="lm-slider">
                                                                <Swiper
                                                                    navigation
                                                                    pagination={{ clickable: true, dynamicBullets: true }}
                                                                >
                                                                    {x.PropertyMedia.filter(item => item.MediaType.split("/")[0] === 'image').map(content => {
                                                                        return (
                                                                            <SwiperSlide>
                                                    <img src={apiBase + content.MediaURL} alt="" height="200px"
                                                    width="310px" />
                                                     {/* <video controls height="200px"width="310px"><source src={apiBase + content.MediaURL} type={content.MediaType} /></video> */}
                                    </SwiperSlide>
                                                                        )
                                                                    }
                                                                    )}
                                                                </Swiper>
                                                            </div>
                                                            <a href="javascript:;" className="item-favorite"><i className="fas fa-heart" aria-hidden="true"></i></a>
                                                        </div>
                                                        <div className="fpb-content" onClick={() => this.detailspage(x.PropertyID)}>
                                                            <a href="javacript:;">{x.PropertyName}</a>
                                                            <p>{x.PropertyAddress}</p>
                                                            <div className="fpb-amenities">
                                                                <ul>
                                                                    {x.Features.map(content => {
                                                                        return (<li>{content.FeatureIconURL ? parse(content.FeatureIconURL) : ''} {content.FeatureName}</li>
                                                                        )
                                                                    }
                                                                    )}
                                                                </ul>
                                                            </div>
                                                            <div className="fpb-star">
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star-half"></i>
                                                                <span>Excellent</span>
                                                            </div>
                                                            <div className="pod-grid-footer">
                                                                <div className="listing-price">
                                                                    <span className="fpb-price"><sup>{parse(x.CurrencySymbol)}</sup>{x.Price}<sub></sub></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                )}
                                            </div>
                                        )
                                    }
                                    )}

                                </div>
                                :
                                <span>
                                    No record found
                                </span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default WishList;