import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import api from "../../../src/utils/apiClient";
import { ProgressBar } from "primereact/progressbar";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { Button, ButtonGroup } from "react-bootstrap";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { CommonConfig } from "utils/constant";
import {InputSwitch} from 'primereact/inputswitch';
import moment from "moment";
import cogoToast from "cogo-toast";
import { Checkbox } from "primereact/checkbox";

class FeedbackListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dt: null,
      podList: [],
      personType: "",
      toggleSwitch:[],
    };
  }

  componentDidMount() {
    // this.getMyPodList();
    this.getFeedbackList();
  }

  getFeedbackList = () => {
    try {
        api
          .get("user/getFeedbackList")
          .then((res) => {
            if (res.success) {
                this.setState({
                    FeedbackList: res.data
                })
            } else {
            }
          })
          .catch((err) => {

          });
    } catch (err) {}
  }

  filterDate = (value, filter) => {
    if (
      filter === undefined ||
      filter === null ||
      (typeof filter === "string" && filter.trim() === "")
    ) {
      return true;
    }

    if (value === undefined || value === null) {
      return false;
    }

    return value === this.formatDate(filter);
  };

  formatDate = (date) => {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = "0" + month;
    }

    if (day < 10) {
      day = "0" + day;
    }

    return date.getFullYear() + "-" + month + "-" + day;
  };

  onRepresentativesChange = (e) => {
    this.state.dt.current.filter(e.value, "representative.name", "in");
    this.setState({ selectedRepresentative: e.value });
  };

  onDateChange = (e) => {
    this.state.dt.current.filter(e.value, "date", "custom");
    this.setState({ selectedDate: e.value });
  };

  onStatusChange = (e) => {
    this.state.dt.current.filter(e.value, "status", "equals");
    this.setState({ selectedStatus: e.value });
  };

//   PropertyNameTemplate = (rowData) => {
//     return (
//       <React.Fragment>
//         <span className="p-column-title">Name</span>
//         {rowData.PropertyName}
//       </React.Fragment>
//     );
//   };

  FeedbackTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Feedback</span>
        {rowData.Topic}
      </React.Fragment>
    );
  };

  DescriptionTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Description</span>
        {rowData.Description}
      </React.Fragment>
    );
  };

  isBugTemplate = (rowData) => {
    const { personType } = this.state;
    return (
      <React.Fragment>
        <span className="p-column-title">Is Bug</span>
        {rowData.IsBug.data[0]?"Yes":"No"}
      </React.Fragment>
    )
  };

  FeedbackStatusTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Status</span>
        {rowData.FeedbackStatus}
      </React.Fragment>
    )
  };

  handleFeedbackStatus = (rowData, Status) => {
    try {
        let data = {
            EntityID: rowData.FeedbackID,
            Status: Status,
            Type: 'Feedback'
        }
        api
          .post("user/updateReviewFeedbackStatus", data)
          .then((res) => {
            if (res.success) {
                cogoToast.success("Status updated")
                this.getFeedbackList();
            } else {
            }
          })
          .catch((err) => {

          });
    } catch (err) {}
  }

  ActionTemplate = (rowData) => {
    return (
        <React.Fragment>
          <span className="p-column-title">Action</span>
          {
              <>
              <i className="fas fa-check text-success" onClick={()=>this.handleFeedbackStatus(rowData,"Approved") }></i> {' '}
              <i className="fas fa-times text-danger" onClick={()=>this.handleFeedbackStatus(rowData,"Rejected") }></i>
              </>
          }
        </React.Fragment>
      )
  }

  LastModifiedTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">CreatedOn</span>
        {moment(rowData.CreatedOn).format("DD-MM-YYYY HH:mm A")}
      </React.Fragment>
    );
  };

  header = () => {
    return (
      <div className="table-header">
        List of Pods
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder="Global Search"
          />
        </span>
      </div>
    );
  };

  render() {
    const { dt, FeedbackList, header, globalFilter } = this.state;
    return (
      <div className="datatable-filter-demo">
        <div className="podlisting-table">
          <DataTable
            ref={dt}
            value={FeedbackList}
            paginator
            rows={10}
            header={header}
            className="p-datatable-customers"
            globalFilter={globalFilter}
            emptyMessage="No Feedbacks found."
          >
            {/* <Column
              field="PropertyName"
              header="Listing"
              body={this.PropertyTemplate}
              filter
              filterPlaceholder="Search by Pod name"
            /> */}

            <Column
              field="Topic"
              // filterField="status"
              header={"Feedback"}
              body={this.FeedbackTemplate}
            />

            <Column
              field="Description"
              // filterField="status"
              header={"Description"}
              body={this.DescriptionTemplate}
            />

            <Column
              field="IsBug"
              // filterField="status"
              header={"Is Bug"}
              body={this.isBugTemplate}
            />

            <Column
              field="CreatedOn"
              header="Last Modified"
              body={this.LastModifiedTemplate}
              sortable
            />
           
            <Column
              field="FeedbackStatus"
              header="Status"
              body={this.FeedbackStatusTemplate}
            />

            {/* <Column
              field="Created"
              header="Location"
              body={this.LocationTemplate}
              sortable
              filterMatchMode="contains"
            /> */}

            <Column
              field="status"
              filterField="status"
              header={"Action"}
              body={this.ActionTemplate}
            />
           
            {/* <Column
              field="GuestCount"
              header="Users"
              body={this.UsersTemplate}
              sortable = {true}
              
              filterMatchMode="gte"
            />
            <Column
              field="BathroomCount"
              header="WashRooms"
              body={this.WashRoomsTemplate}
              sortable
              filterMatchMode="gte"
            /> */}

          </DataTable>
        </div>
      </div>
    );
  }
}

export default FeedbackListing;
