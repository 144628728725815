import React from 'react';
import ReactDOM from 'react-dom';
import '../src/assets/css/bootstrap.min.css';
import './index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../src/assets/css/style.css";
import "../src/assets/css/table.css";
import "../src/assets/css/normalize.css";
import reportWebVitals from './reportWebVitals';
import { createBrowserHistory } from "history";
import '../src/utils/i18n';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "./layouts/Admin.js";
const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <Switch>
      <Route path="/admin" component={AdminLayout} />
      <Redirect from="/" to="/admin/homepage" />
    </Switch>
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// // console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
