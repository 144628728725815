import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import api from "../../../src/utils/apiClient";
import { ProgressBar } from "primereact/progressbar";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { Button, ButtonGroup } from "react-bootstrap";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { CommonConfig } from "utils/constant";
import {InputSwitch} from 'primereact/inputswitch';
import moment from "moment";
import cogoToast from "cogo-toast";
import { Checkbox } from "primereact/checkbox";
import Modal from 'react-bootstrap/Modal';

class ReviewListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dt: null,
      podList: [],
      personType: "",
      toggleSwitch:[],
      EntityList:[],
      hostList :[],
      isOpen1: false,
      isOpen2: false,
      reason: '',
      reasonErr: false,
      reasonHelperText: '',
      rowPersonID: '',
    };
  }

  componentDidMount() {
    // this.getMyPodList();
    this.getHostList();
  }

  getHostList = () => {
    try {
        api
          .post("user/getHostList")
          .then((res) => {
            if (res.success && res.data.length > 0) {
                this.setState({
                    hostList: res.data
                })
            } else {
            }
          })
          .catch((err) => {

          });
    } catch (err) {}
  }

  filterDate = (value, filter) => {
    if (
      filter === undefined ||
      filter === null ||
      (typeof filter === "string" && filter.trim() === "")
    ) {
      return true;
    }

    if (value === undefined || value === null) {
      return false;
    }

    return value === this.formatDate(filter);
  };

  formatDate = (date) => {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = "0" + month;
    }

    if (day < 10) {
      day = "0" + day;
    }

    return date.getFullYear() + "-" + month + "-" + day;
  };

  onRepresentativesChange = (e) => {
    this.state.dt.current.filter(e.value, "representative.name", "in");
    this.setState({ selectedRepresentative: e.value });
  };

  onDateChange = (e) => {
    this.state.dt.current.filter(e.value, "date", "custom");
    this.setState({ selectedDate: e.value });
  };

  onStatusChange = (e) => {
    this.state.dt.current.filter(e.value, "status", "equals");
    this.setState({ selectedStatus: e.value });
  };

  RateTemplate = (rowData,idx, StringMapName) => {
    // // console.log("rowdataaaaaaaaaa",rowData)
    let i = rowData.EntityList.indexOf(StringMapName);
    // // console.log("i..........................",rowData,rowData.Rate,rowData.EntityList[i],StringMapName,i);
    return (
      <React.Fragment>
        <span className="p-column-title">Rate</span>
        {rowData.Rate[i]?rowData.Rate[i]:null}
      </React.Fragment>
    )
  }

  NameTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Name</span>
        {rowData.Name}
      </React.Fragment>
    );
  };

  EmailTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Email</span>
        {rowData.Email}
      </React.Fragment>
    );
  };

  PropertyHostedTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Property Hosted</span>
        {rowData.TotalPropertyHosted}
      </React.Fragment>
    );
  };

  HostVerificationStatusTemplate = (rowData) => {
    let className = rowData.HostVerificationStatus === 'Verified' ? 'text-success' : 'text-danger'
    return (
      <React.Fragment>
        <span className="p-column-title">Status</span>
        <span className={className}>{rowData.HostVerificationStatus}</span>
      </React.Fragment>
    )
  };

  handleHostStatus = (rowData, Status) => {
    try {
        let data = {
            PersonID: rowData.PersonID,
            Status: Status,
        }
        api
          .post("user/updateHostVerificationStatus", data)
          .then((res) => {
            if (res.success) {
                cogoToast.success("Host verified")
                this.getHostList();
            } else {
            }
          })
          .catch((err) => {

          });
    } catch (err) {}
  }

  enableDisableModal = (e,rowData) => {
    if(!e.value){
      this.setState({
        isOpen1: true,
        rowPersonID: rowData.PersonID
      })
    }
    else{
      this.setState({
        isOpen2: true,
        rowPersonID: rowData.PersonID
      })
    }
  }

  enableDisableHost = (e,Status) => {
    const {rowPersonID, reason} = this.state;
    if(this.validate(Status)){
      let data = {
        PersonID: rowPersonID,
        Status: Status,
        Reason: reason
      }
      try {
        api.post("user/updateHostStatus", data)
            .then((res) => {
                if (res.success) {
                    this.hideModal();
                    this.getHostList();
                    cogoToast.success("Status updated to "+Status);
                } else {
                }
            })
            .catch((err) => {
                // // console.log(err)
            });
      } catch (err) { }
    }
  }

  hideModal = () => {
    this.setState({
      isOpen1: false,
      isOpen2: false,
      reason: '',
      reasonHelperText: '',
      reasonErr: false
    })
  }

  handleChange = (e,type) => {
    if(type === 'Reason'){
        if (CommonConfig.isEmpty(e.target.value)) {
            this.setState({ reason: e.target.value, reasonErr: true, reasonHelperText: 'Please enter the reason.' })
        } else if(e.target.value.length < 20) {
          this.setState({ reason: e.target.value, reasonErr: true, reasonHelperText: 'minimum 20 characters required' })
        }        
        else {
            this.setState({ reason: e.target.value, reasonErr: false, reasonHelperText: '' })
        }
    }
  }

  validate = (type) => {
    const {reason} = this.state
    let isValid = true
    if(type === 'Inactive') {
      if (CommonConfig.isEmpty(reason)) {
        isValid = false;
        this.setState({ reasonErr: true, reasonHelperText: 'Reason cannot be empty.' })
    }
    else if(reason.length < 20)  {
      isValid = false;
      this.setState({
        reasonErr: true,
        reasonHelperText: "Minimum 20 characters required.",
      });
    }
    else {
        this.setState({ reasonErr: false, reasonHelperText: '' })
    }
  }
    return isValid;
  }

  ActionTemplate = (rowData) => {
    return (
        <React.Fragment>
          <span className="p-column-title">Action</span>
          {/* <i className="fas fa-check text-success" onClick={()=>this.handleHostStatus(rowData,"Verified") }></i> */}
          {
          rowData.HostVerificationStatus === 'Not Verified'?<Button onClick={()=>this.handleHostStatus(rowData,"Verified")}>Verify</Button>:
          <InputSwitch checked={rowData.HostStatus === 'Active'?true:false} onChange = {(e)=> this.enableDisableModal(e,rowData)} />
          }
        </React.Fragment>
      )
  }

  LastModifiedTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">CreatedOn</span>
        {moment(rowData.CreatedOn).format("DD-MM-YYYY HH:mm A")}
      </React.Fragment>
    );
  };

  ReasonTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Reason</span>
        {rowData.Reason}
      </React.Fragment>
    );
  }

  header = () => {
    return (
      <div className="table-header">
        List of Pods
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder="Global Search"
          />
        </span>
      </div>
    );
  };

  render() {
    // // console.log("review...",this.state.EntityList);
    const { dt, hostList, header, globalFilter } = this.state;
    return (
      <div className="datatable-filter-demo">
        <div className="podlisting-table">
          <DataTable
            ref={dt}
            value={hostList}
            paginator
            rows={10}
            header={header}
            className="p-datatable-customers"
            globalFilter={globalFilter}
            emptyMessage="No records available."
          >
            <Column
              field="Name"
              header="Name"
              body={this.NameTemplate}
            />

            <Column
              field="Email"
              header={"Email"}
              body={this.EmailTemplate}
            />

            <Column
              field="TotalPropertyHosted"
              header={"POD Hosted"}
              body={this.PropertyHostedTemplate}
            />

            <Column
              field="CreatedOn"
              header="Last Modified"
              body={this.LastModifiedTemplate}
              sortable
            />

            <Column
              field="HostStatus"
              header="Host Verification Status"
              body={this.HostVerificationStatusTemplate}
            />

            <Column
              field="Reason"
              header="Reason"
              body={this.ReasonTemplate}
            />

            <Column
              field="status"
              filterField="status"
              header={"Action"}
              body={this.ActionTemplate}
            />

          </DataTable>
        </div>

        <Modal show={this.state.isOpen1} onHide={() => this.hideModal()}>
          <Modal.Header closeButton>
            <Modal.Title>Disable Host</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Please enter a reason to disable this host
            <div className="form-group">
              <textarea className={[this.state.reasonErr ? "form-control mb-10 is-invalid" : "form-control mb-10"]} rows="3" value={this.state.reason} onChange={(e) => this.handleChange(e, "Reason")}></textarea>
              <div className="invalid-feedback" >{this.state.reasonHelperText}</div>
              </div>
          </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick = {() => this.hideModal()}>
                Cancel
              </Button>
              <Button variant="primary" onClick={(e) => this.enableDisableHost(e, "Inactive")}>
                Disable Host
              </Button>
            </Modal.Footer>
          </Modal>
                                
        <Modal show={this.state.isOpen2} onHide={() => this.hideModal()}>
          <Modal.Header closeButton>
            <Modal.Title>Enable Host</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to enable this host?
          </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick = {() => this.hideModal()}>
                No
              </Button>
              <Button variant="primary" onClick={(e) => this.enableDisableHost(e, "Active")}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
                                
      </div>
    );
  }
}

export default ReviewListing;