import React, { Component } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import api from "../../../src/utils/apiClient";
import { CommonConfig } from "utils/constant";
import moment from "moment";
import cogoToast from "cogo-toast";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Modal from 'react-bootstrap/Modal';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { Button } from "react-bootstrap";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { InputSwitch } from 'primereact/inputswitch';

const initialState = {
    dt: null,
    podList: [],

    showDelete: false,
    deleteItems: [],

    key: 'CreateBlog',
    PersonID: CommonConfig.loginData().PersonID,
    BlogBody: '',

    Heading: '',
    HeadingErr: false,
    HeadinghelperText: '',

    ShortDescription: '',
    ShortDescriptionErr: false,
    ShortDescriptionhelperText: '',

    Category: '',
    CategoryErr: false,
    CategoryhelperText: '',
};

const editorConfiguration = {
    toolbar: ['heading', '|', 'undo', 'redo', '|', 'bold', 'italic', 'link','bulletedList', 'numberedList', 'blockQuote', 'image']
};

class ManageBlog extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.getBlogList()
    }

    getBlogList = () => {
        let data = {
            PersonID: CommonConfig.loginData().PersonID,
            PersonType: CommonConfig.loginData().PersonType,
        };
        try {
            api
                .post("utility/getBlogList", data)
                .then((res) => {
                    if (res.success) {
                        this.setState({ blogList: res.data });
                    } else {
                    }
                })
                .catch((err) => {
                    // // console.log(err)
                });
        } catch (err) { }
    };

    handleChange = (event, editor) => {
        const data = editor.getData();
        this.setState({ BlogBody: data })
    }

    handleChangeBlog = (e, type) => {
        if (type === "Heading") {
            this.setState({ Heading: e.target.value })
        }
        else if (type === "ShortDescription") {
            if (e.target.value.length > 250) {
                this.setState({ ShortDescriptionErr: true, ShortDescriptionhelperText: "Maximum 250 characters are allowed." })
            } else {
                this.setState({ ShortDescription: e.target.value, ShortDescriptionErr: false, ShortDescriptionhelperText: "" })
            }
        }
        else if (type === "Category") {
            this.setState({ Category: e.target.value })
        }
        else if (type === "Image") {
            // 
            this.setState({ Image: e.target.files[0] })
        }
    }

    validate = () => {
        let IsValid = true;
        if (CommonConfig.isEmpty(this.state.Heading)) {
            this.setState({ HeadingErr: true, HeadinghelperText: "Heading is required." })
            IsValid = false;
        }
        else {
            this.setState({ HeadingErr: false, HeadinghelperText: '' })
        }
        if (CommonConfig.isEmpty(this.state.Image)) {
            this.setState({ ImageErr: true, ImagehelperText: "Image is required." })
            IsValid = false;
        }
        else {
            this.setState({ ImageErr: false, ImagehelperText: '' })
        }
        if (CommonConfig.isEmpty(this.state.ShortDescription)) {
            this.setState({ ShortDescriptionErr: true, ShortDescriptionhelperText: "Short description is required." })
            IsValid = false;
        } else if (this.state.ShortDescription.length > 250) {
            this.setState({ ShortDescriptionErr: true, ShortDescriptionhelperText: "Maximum 250 characters are allowed." })
            IsValid = false;
        }
        else {
            this.setState({ ShortDescriptionErr: false, ShortDescriptionhelperText: '' })
        }
        if (CommonConfig.isEmpty(this.state.Category)) {
            this.setState({ CategoryErr: true, CategoryhelperText: "Category is required." })
            IsValid = false;
        }
        else {
            this.setState({ CategoryErr: false, CategoryhelperText: '' })
        }
        return IsValid;
    }

    submit = (e) => {
        e.preventDefault();
        if (this.validate()) {
            // let data = {
            //     CreatedBy: CommonConfig.loginData().PersonID,
            //     BlogBody: this.state.BlogBody.replaceAll('"', "'"),
            //     Heading : this.state.Heading,
            //     ShortDescription : this.state.ShortDescription,
            //     Category : this.state.Category,
            //     Type: "Insert"
            // }
            var formData = new FormData();
            formData.append('CreatedBy', CommonConfig.loginData().PersonID);
            formData.append('BlogBody', this.state.BlogBody.replaceAll('"', "'"));
            formData.append('Heading', this.state.Heading);
            formData.append('ShortDescription', this.state.ShortDescription);
            formData.append('Category', this.state.Category);
            formData.append('Media', this.state.Image);
            formData.append('Type', 'Insert');
            try {
                api.post("utility/addUpdateBlog", formData).then(res => {
                    if (res.success) {
                        window.scrollTo(0, 0)
                        this.setState(initialState);
                        this.getBlogList();
                        cogoToast.success('Blog added successfully.')
                    }
                    else {
                    }
                }).catch(err => {
                    // console.log(err)
                })
            } catch (err) {
            }
        }
    }

    HeadingTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Heading</span>
                {rowData.Heading}
            </React.Fragment>
        );
    };

    CategoryTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Category</span>
                {rowData.Category}
            </React.Fragment>
        );
    };

    StatusTemplate = (rowData) => {
        return (<React.Fragment>
            <span className="p-column-title">Status</span>
            {rowData.Status}
        </React.Fragment>)
    };

    ActionTemplate = (rowData) => {
        return (<React.Fragment>
            <div className="tbl-actions">
                <InputSwitch checked={rowData.Status === "Active" ? true : false} onChange={(e) => {
                    this.update(e, rowData, "Inactive")
                }} />
                <a href="javascript:;" className="view-btn"><i className="pi pi-trash" onClick={(e) => this.setState({
                    deleteItems: rowData,
                    showDelete: true
                })}></i></a>
            </div>
        </React.Fragment>
        );
    };

    LastModifiedTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">UpdatedOn</span>
                {moment(rowData.UpdatedOn).format("DD-MM-YYYY HH:mm A")}
            </React.Fragment>
        );
    };

    header = () => {
        return (
            <div className="table-header">
                List of Blogs
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search"
                        onInput={(e) => this.setState({ globalFilter: e.target.value })}
                        placeholder="Global Search"
                    />
                </span>
            </div>
        );
    };

    update = (event, rowData, type) => {
        event.preventDefault();
        let data = {};
        if (type === "Inactive") {
            data = {
                BlogID: rowData.BlogID,
                BlogStatus: event.target.value === true ? "Active" : "Inactive",
                UpdatedBy: this.state.PersonID,
                Type: "Inactive"
            }
        } else {
            data = {
                BlogID: rowData.BlogID,
                BlogStatus: 'Inactive',
                UpdatedBy: this.state.PersonID,
                Type: "Delete"
            }
        }

        try {
            api.post("utility/updateBlogStatus", data).then(res => {
                if (res.success) {
                    if (type === "Inactive") {
                        cogoToast.success(`Blog ${rowData.Heading} is now ${event.target.value == true ? "activated" : "deactivated"}.`)
                    } else {
                        cogoToast.success(`Blog ${rowData.Heading} deleted successsfully.`)
                    }
                    this.setState(initialState);
                    this.setState({ key: "ViewBlog" })
                    this.getBlogList();
                }
                else {
                }
            }).catch(err => {
                // console.log(err)
            })
        } catch (err) {
        }
    }

    render() {
        const { dt, header, globalFilter, blogList } = this.state;
        return (
            <div className="datatable-filter-demo">
                <div className="host-dashboard-outer">
                    <div className="container">
                        <div className="host-page-heading">
                            <h2>Manage Blogs</h2>
                        </div>
                        <Tabs
                            id="controlled-tab-coupon-example"
                            className="requests-tab-nav"
                            defaultActiveKey="CreateBlog"
                            activeKey={this.state.key}
                            onSelect={(k) => this.setState({ key: k })}
                        >
                            <Tab eventKey="CreateBlog" title="Create Blog">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label>Heading</label>
                                            <input type="text" className={this.state.HeadingErr ? "form-control is-invalid" : "form-control"} placeholder="Heading" value={this.state.Heading} onChange={(e) => this.handleChangeBlog(e, "Heading")} />
                                            <div className="invalid-feedback" >{this.state.HeadinghelperText}</div>
                                        </div>
                                        <div className="form-group">
                                            <label>Image</label>
                                            <input type="file" className={this.state.ImageErr ? "form-control is-invalid h-100" : "form-control h-100"} onChange={(e) => this.handleChangeBlog(e, "Image")} />
                                            <div className="invalid-feedback d-block" >{this.state.ImagehelperText}</div>
                                        </div>
                                        <div className="form-group">
                                            <label>Category</label>
                                            <input type="text" className={this.state.CategoryErr ? "form-control is-invalid" : "form-control"} placeholder="Category" value={this.state.Category} onChange={(e) => this.handleChangeBlog(e, "Category")} />
                                            <div className="invalid-feedback" >{this.state.CategoryhelperText}</div>
                                        </div>
                                        <div className="form-group">
                                            <label>Short Description</label>
                                            <textarea rows="3" className={this.state.ShortDescriptionErr ? "form-control is-invalid" : "form-control"} placeholder="Short Description" value={this.state.ShortDescription} onChange={(e) => this.handleChangeBlog(e, "ShortDescription")} />
                                            <div className="invalid-feedback" >{this.state.ShortDescriptionhelperText}</div>
                                        </div>
                                        <div className="apc-footer">
                                            <button onClick={() => this.setState(initialState)} className="btn"><i className="fas fa-times"></i> Reset</button>
                                            <button className="btn btn-primary" onClick={(event) => this.submit(event)}>Submit</button>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-8">
                                        <div className="form-group" style={{ height: "420px", overflow: "auto" }}>
                                            <CKEditor
                                                data={this.state.BlogBody}
                                                editor={ClassicEditor}
                                                onInit={editor => {
                                                }}
                                                config={editorConfiguration}
                                                onChange={this.handleChange}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="ViewBlog" title="View Blogs">

                                <DataTable
                                    ref={dt}
                                    value={blogList}
                                    paginator
                                    rows={10}
                                    header={header}
                                    className="p-datatable-customers"
                                    globalFilter={globalFilter}
                                    emptyMessage="No blogs found."
                                >
                                    <Column
                                        field="Heading"
                                        header="Heading"
                                        body={this.HeadingTemplate}
                                        filter
                                        filterPlaceholder="Search by Heading"
                                    />
                                    <Column
                                        field="Category"
                                        header="Category"
                                        sortable
                                        body={this.CategoryTemplate}
                                        filter
                                        filterPlaceholder="Search by Category"
                                    />
                                    <Column
                                        field="Status"
                                        filterField="status"
                                        header={"Status"}
                                        body={this.StatusTemplate}
                                    />
                                    <Column
                                        header="Action"
                                        body={this.ActionTemplate}
                                    />
                                    <Column
                                        field="UpdatedOn"
                                        header="Last Modified"
                                        body={this.LastModifiedTemplate}
                                    // sortable
                                    />
                                </DataTable>
                                <Modal show={this.state.showDelete} onHide={() => this.setState({
                                    deleteItems: [],
                                    showDelete: false
                                })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Delete Blog</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>Are you sure you want to delete blog?</Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={() => this.setState({
                                            deleteItems: [],
                                            showDelete: false
                                        })}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" onClick={(e) => this.update(e, this.state.deleteItems, "Delete")}>
                                            Delete
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }
}

export default ManageBlog;
