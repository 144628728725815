import React, { Component } from "react";
import contactImage from "../../assets/img/contact-img.svg";
import { CommonConfig } from "../../utils/constant";
import cogoToast from 'cogo-toast';
import api from '../../../src/utils/apiClient';

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (e, type) => {
    if (type === "Name") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          Name: e.target.value,
          NameErr: true,
          NamehelperText: "Name is required.",
        });
      } else {
        this.setState({
          Name: e.target.value,
          NameErr: false,
          NamehelperText: "",
        });
      }
    } else if (type === "EmailAddress") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          EmailAddress: e.target.value,
          EmailAddressErr: true,
          EmailAddresshelperText: "Email address is required.",
        });
      } else if (!e.target.value.match(CommonConfig.RegExp.email)) {
        this.setState({
          EmailAddress: e.target.value,
          EmailAddressErr: true,
          EmailAddresshelperText: "Please enter valid email.",
        });
      } else {
        this.setState({
          EmailAddress: e.target.value,
          EmailAddressErr: false,
          EmailAddresshelperText: "",
        });
      }
    } else if (type === "Message") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          Message: e.target.value,
          MessageErr: true,
          MessagehelperText: "Message is required.",
        });
      }
      else if (e.target.value.length < 20) {
        this.setState({
          Message: e.target.value,
          MessageErr: true,
          MessagehelperText: "Minimum 20 characters required.",
        })
      } else {
        this.setState({
          Message: e.target.value,
          MessageErr: false,
          MessagehelperText: "",
        });
      }
    }
  };

  validate = (event) => {
    event.preventDefault();
    const { Name, EmailAddress, Message } = this.state;
    let IsValid = true;

    if (CommonConfig.isEmpty(Name)) {
      IsValid = false;
      this.setState({ NameErr: true, NamehelperText: 'Name is required.' })
    }
    else {
      this.setState({ NameErr: false, NamehelperText: '' })
    }
    if (CommonConfig.isEmpty(EmailAddress)) {
      IsValid = false;
      this.setState({ EmailAddressErr: true, EmailAddresshelperText: 'Email address is required.' })
    }
    else if (!EmailAddress.match(CommonConfig.RegExp.email)) {
      IsValid = false;
      this.setState({ EmailAddressErr: true, EmailAddresshelperText: 'Please enter valid email.' })
    }
    else {
      this.setState({ EmailAddressErr: false, EmailAddresshelperText: '' })
    }
    if (CommonConfig.isEmpty(Message)) {
      IsValid = false;
      this.setState({ MessageErr: true, MessagehelperText: 'Message is required.' })
    }
    else if (Message.length < 20) {
      IsValid = false;
      this.setState({
        MessageErr: true,
        MessagehelperText: "Minimum 20 characters required.",
      });
    }
    else {
      this.setState({ MessageErr: false, MessagehelperText: '' })
    }
    return IsValid;
  }

  submitContact = (e) => {
    e.preventDefault();
    if (this.validate(e)) {
      let data = {
        Name: this.state.Name,
        Email: this.state.EmailAddress,
        Message: this.state.Message,
        PersonID: CommonConfig.loginData().PersonID ? CommonConfig.loginData().PersonID : '',
        PersonType: 'Admin'
      }
      try {
        api.post("property/contactUs", data).then(res => {
          
          if (res.success) {
            cogoToast.success('Request sent to the system. We will respond to your message soon!')
            this.setState({
              Name: '',
              NameErr: false,
              NamehelperText: '',

              EmailAddress: '',
              EmailAddressErr: false,
              EmailAddresshelperText: '',

              Message: '',
              MessageErr: false,
              MessagehelperText: '',
            });
          }
          else {
            cogoToast.error('Request failed. Please try again!')
            this.setState({
              Name: '',
              NameErr: false,
              NamehelperText: '',

              EmailAddress: '',
              EmailAddressErr: false,
              EmailAddresshelperText: '',

              Message: '',
              MessageErr: false,
              MessagehelperText: '',
            });
          }
        }).catch(err => {
          // // console.log(err)
        })
      } catch (err) {
      }
    }
  }

  render() {
    return (
      <div className="contact-outer">
        <ol className="breadcrumb ml-4">
              <li>
                <a
                  href="javascript:;"
                  onClick={() =>
                    this.props.history.push("/admin/homepage")
                  }
                >
                  <span>Home</span>
                </a>
              </li>
              <li className="active">
                <span>Contact Us</span>
              </li>
            </ol>
            
        <div className="contact-heading">
          
          <div className="container">
            <h2 className="h2">Contact Us</h2>
          </div>
        </div>
        <div className="container">
          <div className="contact-form">
            <div className="row mobile-column-reverce">
              <div className="col-lg-6 col-md-6">
                <p className="contact-text">
                  The Work Pod’s customer services are happy to help if you have
                  any website feedback, testimonials or complaints about the
                  booking/payment process. Please allow us some time to get back
                  to you.
                </p>
                {/* <div className="inline-radio">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input"></input>
                                        <label class="custom-control-label" for="customRadio1">User</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input"></input>
                                        <label class="custom-control-label" for="customRadio2">Host</label>
                                    </div>
                                </div> */}
                <div className="form-group">
                  {/* <input type="text" placeholder="Name *" className="form-control"></input> */}
                  <label>Name *</label>
                  <input
                    type="text"
                    className={
                      this.state.NameErr
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    placeholder="E.g. Clara Smith"
                    value={this.state.Name}
                    onChange={(e) => this.handleChange(e, "Name")}
                  />
                  <div className="invalid-feedback">
                    {this.state.NamehelperText}
                  </div>
                </div>
                <div className="form-group">
                  <label>Email address *</label>
                  <input
                    type="text"
                    className={
                      this.state.EmailAddressErr
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    placeholder="E.g. abac@gmail.com"
                    value={this.state.EmailAddress}
                    onChange={(e) => this.handleChange(e, "EmailAddress")}
                  />
                  <div className="invalid-feedback">
                    {this.state.EmailAddresshelperText}
                  </div>
                </div>
                <div className="form-group">
                  <label>Message *</label>
                  <textarea
                    placeholder="Your message"
                    className={
                      this.state.MessageErr
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    value={this.state.Message}
                    onChange={(e) => this.handleChange(e, "Message")}
                  ></textarea>
                  <div className="invalid-feedback">
                    {this.state.MessagehelperText}
                  </div>
                </div>
                {/* <div className="form-group">
                                    <input type="text" placeholder="Email address *" className="form-control"></input>
                                </div>
                                <div className="form-group">
                                    <textarea placeholder="Your message" className="form-control"></textarea>
                                </div> */}
                <div className="pr-text">
                  <p>
                    The information you provided is only used to respond to your
                    request. The data controller is The WorkPod who acts in
                    compliance with applicable laws. You can read our entire{" "}
                    <a href="/admin/privacypolicy">Privacy Policy</a>.
                  </p>
                  {/* <p>
                                        This site is protected by reCAPTCHA and the Google <a href="/admin/privacypolicy">Privacy Policy</a> and <a href="/admin/termsofservice">Terms of Service</a> apply.
                                    </p> */}
                </div>
                <div className="ct-submit">
                  <button className="btn btn-primary" onClick={(e) => { this.submitContact(e) }}>Submit</button>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="contact-image">
                  <img src={contactImage} alt="Contact Us"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUs;
