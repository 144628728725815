import React, { Component } from 'react'
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import api from '../../../src/utils/apiClient';
import { CommonConfig } from "../../utils/constant";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import moment from 'moment';
import cogoToast from 'cogo-toast';
import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReactExport from "react-export-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import parse from "html-react-parser";
import logo from "../../assets/img/logo-white.svg";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

const initialState = {
    dt: null,
    globalFilter: '',
    prePaidAccountList: [],
    prePaidTableList: [],
    PrepaidAccountName: '',
    IsViewModeSelected: false,
    IsLedgerViewModeSelected: false,
    ISTopUpSelected: false,
    PrepaidAccountID: false,
    TopUp: '',
    CurrentBalance: '',
    prepaidItems: {},
    ClientID: '',
    Currency: '',
    addItems: {},
    prepaidTableItems: {},
    showAddMemberModal: false,
    FullName: '',
    FullNameErr: false,
    FullNamehelperText: '',
    PhoneNumber: '',
    PhoneNumberErr: false,
    PhoneNumberhelperText: '',
    EmailAddress: '',
    EmailAddressErr: false,
    EmailAddresshelperText: '',
    editItems: {},
    PrepaidAccountName: CommonConfig.loginData().FirstName,
    PrepaidAccountNameErr: false,
    PrepaidAccountNamehelperText: '',

    Purpose: '',
    PurposeErr: false,
    PurposehelperText: '',

    IsSetupPrepaidSelected: false,
    purposeList: [],
    ledgerTableList: [],
    PrepaidAccountBalance: 0,
    ledgerItemList: {},
    StartDate: '',
    EndDate: '',
    exportledgerList: [],
    KeyID: '',
    PrepaidAccountCurrencySymbol: ''
}

class PrepaidAccount extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getMyPrePaidAccount();
        this.getPaypalDetails();
        this.getRazorPayDetails();
        this.getPurposeList();
    }

    getPurposeList = () => {
        let data = {
            StringMapType: "PURPOSE"
        }
        try {
            api.post("utility/getStringMap", data).then(res => {
                if (res.success) {
                    this.setState({ purposeList: res.data });
                }
                else {
                }
            }).catch(err => {
                // // console.log(err)
            })
        } catch (err) {
        }
    }

    getPaypalDetails = () => {
        try {
            api
                .post("utility/getPaypalDetail")
                .then((res) => {
                    if (res.success) {
                        this.setState({ ClientID: res.data[0].ClientID, Currency: res.data[0].Currency });
                    } else {
                    }
                })
                .catch((err) => {
                    // console.log(err)
                });
        } catch (err) { }
    };

    getRazorPayDetails = () => {
        try {
            api
                .post("utility/getRazorPayDetail")
                .then((res) => {
                    if (res.success) {
                        this.setState({ KeyID: res.data[0].KeyID });
                    } else {
                    }
                })
                .catch((err) => {
                    // console.log(err)
                });
        } catch (err) { }
    };

    getMyPrePaidAccount = (item) => {
        let data = {
            PersonID: CommonConfig.loginData().PersonID
        }
        try {
            api
                .post("user/getPrepaidAccountList", data)
                .then((res) => {
                    if (res.success) {
                        this.setState({ prePaidAccountList: res.data });
                        if (item && !CommonConfig.isObjectEmpty(item)) {
                            this.handleView(res.data.filter(x => x.PrepaidAccountID == item.PrepaidAccountID)[0])
                        }
                    } else {
                    }
                })
                .catch((err) => {
                    // console.log(err)
                });
        } catch (err) { }
    };

    getLedgerDetails = (item) => {
        this.setState({ ledgerItemList: item })
        let data = {
            PrepaidAccountID: item.PrepaidAccountID,
            StartDate: this.state.StartDate,
            EndDate: this.state.EndDate
        }
        try {
            api
                .post("user/getLedgerDetails", data)
                .then((res) => {
                    if (res.success) {
                        // res.data[0].map(x=>{

                        // })
                        this.setState({ ledgerTableList: res.data, IsLedgerViewModeSelected: true, PrepaidAccountName: res.data[0].PrepaidAccountName, PrepaidAccountBalance: res.data[0].ClosingBalance,PrepaidAccountCurrencySymbol: res.data[0].CurrencySymbol });
                        // if (item && !CommonConfig.isObjectEmpty(item)) {
                        //     this.handleView(res.data.filter(x => x.PrepaidAccountID == item.PrepaidAccountID)[0])
                        // }
                    } else {
                    }
                })
                .catch((err) => {
                    // console.log(err)
                });
        } catch (err) { }
    };

    createTopUpbalance = (response, paymentMode) => {
        let data = {
            PrepaidAccountID: '',
            PaymentMode: paymentMode,
            PrepaidAccountName: this.state.PrepaidAccountName,
            Balance: this.state.TopUp,
            Purpose: this.state.Purpose,
            PersonID: CommonConfig.loginData().PersonID,
            PaypalRecordID: response.data[0][0].PayPalRecordID
        }
        try {
            api.post("user/addUpdatePrepaidAccount", data).then(res => {
                if (res.success) {
                    cogoToast.success('Prepaid account created successfully.')
                    this.setState(initialState);
                    window.scrollTo(0, 0);
                    this.getMyPrePaidAccount();
                    this.getPaypalDetails();
                    this.getRazorPayDetails();
                    this.getPurposeList();
                }
                else {
                    cogoToast.error('Unable to create Prepaid account. Please try again !')
                }
            }).catch(err => {
                // // console.log(err)
            })
        } catch (err) {
        }
    }

    updateTopUpbalance = (response, paymentMode) => {
        let data = {
            PrepaidAccountID: this.state.prepaidItems.PrepaidAccountID,
            PaymentMode: paymentMode,
            PrepaidAccountName: this.state.prepaidItems.PrepaidAccountName,
            Balance: this.state.TopUp,
            Purpose: this.state.prepaidItems.Purpose,
            PersonID: CommonConfig.loginData().PersonID,
            PaypalRecordID: response.data[0][0].PayPalRecordID
        }
        try {
            api.post("user/addUpdatePrepaidAccount", data).then(res => {
                if (res.success) {
                    cogoToast.success('Your balance updated successfully.')
                    this.setState(initialState);
                    window.scrollTo(0, 0);
                    this.getMyPrePaidAccount();
                    this.getPaypalDetails();
                    this.getRazorPayDetails();
                    this.getPurposeList();
                }
                else {
                    cogoToast.error('Unable to update your balance. Please try again !')
                }
            }).catch(err => {
                // // console.log(err)
            })
        } catch (err) {
        }
    }

    validate = (e) => {
        e.preventDefault();
        const { EmailAddress, PhoneNumber, FullName } = this.state;
        let IsValid = true;

        if (CommonConfig.isEmpty(FullName)) {
            IsValid = false;
            this.setState({ FullNameErr: true, FullNamehelperText: 'Full name is required.' })
        }
        else {
            this.setState({ FullNameErr: false, FullNamehelperText: '' })
        }

        if (CommonConfig.isEmpty(EmailAddress)) {
            IsValid = false;
            this.setState({ EmailAddressErr: true, EmailAddresshelperText: 'Email address is required.' })
        }
        else {
            this.setState({ EmailAddressErr: false, EmailAddresshelperText: '' })
        }

        if (CommonConfig.isEmpty(PhoneNumber)) {
            IsValid = false;
            this.setState({ PhoneNumberErr: true, PhoneNumberhelperText: 'Phone number is required.' })
        }
        else {
            this.setState({ PhoneNumberErr: false, PhoneNumberhelperText: '' })
        }

        return IsValid;
    }

    addMember = (e, items) => {
        e.preventDefault();
        if (this.validate(e)) {
            var prepaidTableItems = this.state.prepaidTableItems;
            let data = {
                PrepaidAccountID: this.state.PrepaidAccountID,
                PrepaidAccountUserID: CommonConfig.isObjectEmpty(this.state.editItems) ? '' : this.state.editItems.PrepaidAccountUserID,
                Name: this.state.FullName,
                Email: this.state.EmailAddress,
                Phone: this.state.PhoneNumber,
                PersonID: CommonConfig.loginData().PersonID
            }
            try {
                api.post("user/addUpdatePrepaidAccountMember", data).then(res => {
                    // console.log('res', res)
                    if (res.success) {
                        if (res.data.ReturnStatus == 'Member already exists') {
                            cogoToast.info(res.data.ReturnStatus);
                        } else {
                            cogoToast.success(res.data.ReturnStatus);
                        }
                        this.setState(initialState);
                        window.scrollTo(0, 0);
                        this.getMyPrePaidAccount(prepaidTableItems);
                        this.getPaypalDetails();
                    }
                    else {
                        cogoToast.error('Unable to add member. Please try again !')
                    }
                }).catch(err => {
                    // // console.log(err)
                })
            } catch (err) {
            }
        }
    }

    deleteMember = (e, rowData) => {
        e.preventDefault();
        var prepaidTableItems = this.state.prepaidTableItems;
        let data = {
            PrepaidAccountID: this.state.PrepaidAccountID,
            PrepaidAccountUserID: rowData.PrepaidAccountUserID,
            PersonID: CommonConfig.loginData().PersonID
        }
        try {
            api.post("user/deletePrepaidAccountMember", data).then(res => {
                // console.log('res', res)
                if (res.success) {
                    cogoToast.success('Member removed successfully.');
                    this.setState(initialState);
                    window.scrollTo(0, 0);
                    this.getMyPrePaidAccount(prepaidTableItems);
                    this.getPaypalDetails();
                }
                else {
                    cogoToast.error('Unable to remove member. Please try again !')
                }
            }).catch(err => {
                // // console.log(err)
            })
        } catch (err) {
        }
    }

    ActionTemplate = (rowData) => {
        return (<React.Fragment>
            <div className="tbl-actions">
                <a href="javascript:;" className="view-btn" onClick={(e) =>
                    this.setState({ showAddMemberModal: true, editItems: rowData, FullName: rowData.Name, EmailAddress: rowData.Email, PhoneNumber: rowData.Phone })
                }><i className="pi pi-pencil"></i></a>
                <a href="javascript:;" className="view-btn" onClick={(e) => this.deleteMember(e, rowData)}><i className="pi pi-trash"></i></a>
            </div>
        </React.Fragment>
        );
    };

    TransactionDateTemplate = (rowData) => {
        return (<React.Fragment>
            <span>{rowData.TransactionDate ? moment(rowData.TransactionDate).format('DD-MM-YYYY HH:mm') : ''}</span>
        </React.Fragment>
        );
    };

    NameTemplate = (rowData) => {
        return (<React.Fragment>
            <span>{rowData.Name}</span>
        </React.Fragment>
        );
    };

    EmailTemplate = (rowData) => {
        return (<React.Fragment>
            <span>{rowData.Email}</span>
        </React.Fragment>
        );
    };

    PhoneTemplate = (rowData) => {
        return (<React.Fragment>
            <span>{rowData.Phone}</span>
        </React.Fragment>
        );
    };

    handleView = (item) => {
        
        // var data = []
        // var Phone = item.Phone.split(',');
        // var Email = item.Email.split(',');
        // item.Name.split(',').map((x, idx) => {
        //     data.push({
        //         "Name": x,
        //         "Email": Email[idx],
        //         "Phone": Phone[idx]
        //     })
        // })

        // this.setState({ IsViewModeSelected: true, prepaidTableItems: item, prePaidTableList: data, PrepaidAccountName: item.PrepaidAccountName, PrepaidAccountID: item.PrepaidAccountID })


        let data = {
            PrepaidAccountID: item.PrepaidAccountID
        }
        try {
            api
                .post("user/getPrepaidAccountListByID", data)
                .then((res) => {
                    if (res.success) {
                        this.setState({ IsViewModeSelected: true, prepaidTableItems: item, prePaidTableList: res.data, PrepaidAccountName: item.PrepaidAccountName, PrepaidAccountID: item.PrepaidAccountID });
                    } else {
                    }
                })
                .catch((err) => {
                    // console.log(err)
                });
        } catch (err) { }

    };

    handleChange = (e, type) => {
        if (type == "TopUp") {
            if (CommonConfig.isEmpty(e)) {
                this.setState({ TopUp: e, TopUpErr: true, TopUphelperText: 'Please enter amount' })
            } else {
                this.setState({ TopUp: e, TopUpErr: false, TopUphelperText: '' })
            }
        }
        if (type === "FullName") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ FullName: e.target.value, FullNameErr: true, FullNamehelperText: 'Full name is required..' })
            } else {
                this.setState({ FullName: e.target.value, FullNameErr: false, FullNamehelperText: '' })
            }
        }
        if (type == "EmailAddress") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ EmailAddress: e.target.value, EmailAddressErr: true, EmailAddresshelperText: 'Email address is required.' })
            } else if (!e.target.value.match(CommonConfig.RegExp.email)) {
                this.setState({ EmailAddress: e.target.value, EmailAddressErr: true, EmailAddresshelperText: 'Please enter valid email.' })
            } else {
                this.setState({ EmailAddress: e.target.value, EmailAddressErr: false, EmailAddresshelperText: '' })
            }
        }
        if (type === "PhoneNumber") {
            if (CommonConfig.isEmpty(e)) {
                this.setState({ PhoneNumber: e, PhoneNumberErr: true, PhoneNumberhelperText: 'Phone number is required..' })
            } else {
                this.setState({ PhoneNumber: e, PhoneNumberErr: false, PhoneNumberhelperText: '' })
            }
        }
        if (type == "PrepaidAccountName") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ PrepaidAccountName: e.target.value, PrepaidAccountNameErr: true, PrepaidAccountNamehelperText: 'Please enter prepaid account name' })
            } else {
                this.setState({ PrepaidAccountName: e.target.value, PrepaidAccountNameErr: false, PrepaidAccountNamehelperText: '' })
            }
        }

        if (type == "Purpose") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ Purpose: e.target.value, PurposeErr: true, PurposehelperText: 'Please select purpose' })
            } else {
                this.setState({ Purpose: e.target.value, PurposeErr: false, PurposehelperText: '' })
            }
        }
    }

    header = () => {
        return (
            <div className="table-header">
                List of Users
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search"
                        onInput={(e) => this.setState({ globalFilter: e.target.value })}
                        placeholder="Global Search"
                    />
                </span>
            </div>
        );
    };

    async RazorPaySetupPrepaid(Amount, items) {
        var data = {
            Amount: Amount,
            Currency: items.Currency
        }
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        // creating a new order
        const result = await api.post("utility/razorPayCheckOut", data)


        if (!result) {
            alert("Server error. Are you online?");
            return;
        }

        // Getting the order details back
        const { amount, id: order_id, currency } = result.data;

        const options = {
            key: this.state.KeyID, // Enter the Key ID generated from the Dashboard
            amount: amount.toString(),
            currency: currency,
            name: "The WorkPod",
            description: "Test Transaction",
            image: logo,
            order_id: order_id,
            handler: async (response) => {
                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                    PurchaseAmount: amount,
                    currency: currency
                };

                const result = await api.post("property/RazorPayTxnSave", data);
                if (result.success) {
                    this.setState({ loading: true })
                    this.updateTopUpbalance(result, 'RazorPay');
                }
                // alert(result.data.msg);
            },
            prefill: {
                name: "The WorkPod",
                email: "info@theworkpod.co.uk",
                contact: "0208 427 8787",
            },
            notes: {
                address: "85 Great Portland Street First Floor, London, United Kingdom, W1W 7LT",
            },
            theme: {
                // color: "#61dafb",
                color: "#1da1f2"
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    async createRazorPaySetupPrepaid(Amount) {
        var data = {
            Amount: Amount,
            Currency: CommonConfig.userCountryData() ? CommonConfig.userCountryData().CurrencyCode : ''
        }
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        // creating a new order
        const result = await api.post("utility/razorPayCheckOut", data)


        if (!result) {
            alert("Server error. Are you online?");
            return;
        }

        // Getting the order details back
        const { amount, id: order_id, currency } = result.data;

        const options = {
            key: this.state.KeyID, // Enter the Key ID generated from the Dashboard
            amount: amount.toString(),
            currency: currency,
            name: "The WorkPod",
            description: "Test Transaction",
            image: logo,
            order_id: order_id,
            handler: async (response) => {
                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                    PurchaseAmount: amount,
                    currency: currency
                };

                const result = await api.post("property/RazorPayTxnSave", data);
                if (result.success) {
                    this.setState({ loading: true })
                    this.createTopUpbalance(result, 'RazorPay');
                }
                // alert(result.data.msg);
            },
            prefill: {
                name: "The WorkPod",
                email: "info@theworkpod.co.uk",
                contact: "0208 427 8787",
            },
            notes: {
                address: "85 Great Portland Street First Floor, London, United Kingdom, W1W 7LT",
            },
            theme: {
                // color: "#61dafb",
                color: "#1da1f2"
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    render() {
        const { prePaidAccountList, dt, globalFilter } = this.state;
        return (
            <div /*className="prepaid-acc-outer"*/ className="host-dashboard-outer">
                <div className="container">
                <div className="host-page-heading">
                <h2>Manage Prepaid Account</h2>
              </div>
                    <div className="pre-ac-info">
                    
                        {/* <h2>Manage Prepaid Account</h2> */}
                        {this.state.prePaidAccountList.length > 0 && !this.state.IsViewModeSelected && !this.state.ISTopUpSelected && !this.state.IsSetupPrepaidSelected && !this.state.IsLedgerViewModeSelected ?
                            <div className="row">
                                {this.state.prePaidAccountList.map(x => {

                                    return (
                                        <div className="col-lg-4 col-md-4">
                                            <div className="pre-dashboard-info">
                                                <span>{x.PrepaidAccountName}</span>
                                                <div className="pre-account-balence">
                                                    <h3>Balance: {x.CurrencySymbol ? parse(x.CurrencySymbol) : ""} {x.Balance}</h3>
                                                    <a href="javascript:;" onClick={() => this.setState({ ISTopUpSelected: true, PrepaidAccountID: x.PrepaidAccountID, prepaidItems: x })} className="btn btn-primary">Top Up</a>
                                                </div>
                                                <a href="javascript:;" onClick={() => this.handleView(x)}><p>{x.PersonID.split(',').length} Members are configured</p></a>
                                                <div className="payment-history">
                                                    <a href="javascript:;" className="btn history-btn" onClick={() => this.getLedgerDetails(x)} >Payment History</a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            : this.state.IsLedgerViewModeSelected ?
                                <div className="payment-history-outer">
                                    <button onClick={() => this.setState({ IsLedgerViewModeSelected: false, ledgerTableList: [], PrepaidAccountName: '', PrepaidAccountID: '', PrepaidAccountBalance: 0,PrepaidAccountCurrencySymbol : '', ledgerItemList: {}, StartDate: '', EndDate: '' })} className="btn back-btn"><i className="fas fa-chevron-left"></i> Back</button>
                                    <div className="history-table-head">
                                        <h3 className="h-accname">{this.state.PrepaidAccountName}</h3>
                                        <div className="h-date-filter">
                                            <InputText
                                                type="date"
                                                onInput={(e) => this.setState({ StartDate: e.target.value })}
                                                placeholder="Start Date"
                                                className="ml-2"
                                            />
                                            <InputText
                                                type="date"
                                                onInput={(e) => this.setState({ EndDate: e.target.value })}
                                                placeholder="End Date"
                                                className="ml-2"
                                            />
                                            <button onClick={() => this.getLedgerDetails(this.state.ledgerItemList)} className="btn btn-primary ml-2">Submit</button>
                                        </div>
                                        <ExcelFile element={<img src="https://img.icons8.com/color/48/000000/ms-excel.png" height="35px" className="export-excel" onClick={() => cogoToast.success('File exported successfully')} />} filename={this.state.PrepaidAccountName + ' ' + 'Transaction Statement'}>
                                            <ExcelSheet data={this.state.ledgerTableList} name="Transaction Statement">
                                                <ExcelColumn label="Transaction Date" value="TransactionDate" />
                                                <ExcelColumn label="Transaction Type" value="TransactionType" />
                                                <ExcelColumn label="Description" value="Description" />
                                                <ExcelColumn label="Amount" value="Amount" />
                                                <ExcelColumn label="Balance" value="Balance" />
                                            </ExcelSheet>
                                        </ExcelFile>
                                        <h6>Current Balance : {this.state.PrepaidAccountCurrencySymbol ? parse(this.state.PrepaidAccountCurrencySymbol) : null}&nbsp;{this.state.PrepaidAccountBalance}</h6>
                                    </div>
                                    <div className="table-common">
                                        <div className="table-column-main">
                                            <DataTable
                                                ref={dt}
                                                value={this.state.ledgerTableList}
                                                paginator
                                                rows={10}
                                                // header={this.header}
                                                className="p-datatable-customers"
                                                globalFilter={globalFilter}
                                                emptyMessage="No history found."
                                            >
                                                {/* <Column
                                                field="Name"
                                                header="Name"
                                                body={this.NameTemplate}
                                                filter
                                            /> */}
                                                <Column
                                                    field="TransactionDate"
                                                    header="Transaction Date"
                                                    body={this.TransactionDateTemplate}
                                                    // filter
                                                    sortable
                                                />
                                                <Column
                                                    field="TransactionType"
                                                    header="Transaction Type"
                                                    // body={this.PhoneTemplate}
                                                    filter
                                                />
                                                <Column
                                                    field="Description"
                                                    header="Description"
                                                    filter
                                                />
                                                <Column
                                                    field="Amount"
                                                    header="Amount"
                                                    // body={this.ActionTemplate}
                                                    sortable
                                                />
                                                <Column
                                                    field="Balance"
                                                    header="Balance"
                                                    sortable
                                                // filter
                                                />
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                                : this.state.IsViewModeSelected ?
                                    <div /*className="pre-ac-info"*/>
                                        <button onClick={() => this.setState({ IsViewModeSelected: false, prepaidTableItems: {}, prePaidTableList: [], PrepaidAccountName: '', PrepaidAccountID: '' })} className="btn back-btn"><i className="fas fa-chevron-left"></i> Back</button>
                                        <h2>Who will be using this Prepaid Account {this.state.PrepaidAccountName}</h2>
                                        <div className="table-common">
                                            <div className="table-header">
                                                <h3>{this.state.prePaidTableList.length} Members</h3>
                                                <div className="th-action">
                                                    <a href="javascript:;" onClick={() => this.setState({ showAddMemberModal: true })}><i className="fa fa-plus"></i></a>
                                                </div>
                                            </div>
                                            <div className="table-column-main">
                                                <DataTable
                                                    ref={dt}
                                                    value={this.state.prePaidTableList}
                                                    paginator
                                                    rows={10}
                                                    // header={this.header}
                                                    className="p-datatable-customers"
                                                    globalFilter={globalFilter}
                                                    emptyMessage="No user found."
                                                >
                                                    <Column
                                                        field="Name"
                                                        header="Name"
                                                        body={this.NameTemplate}
                                                        filter
                                                    />
                                                    <Column
                                                        field="Email"
                                                        header="Email"
                                                        body={this.EmailTemplate}
                                                        filter
                                                    />
                                                    <Column
                                                        field="Phone"
                                                        header="Phone"
                                                        body={this.PhoneTemplate}
                                                        filter
                                                    />
                                                    <Column
                                                        field="UserStatus"
                                                        header="Status"
                                                        filter
                                                    />
                                                    <Column
                                                        header="Action"
                                                        body={this.ActionTemplate}
                                                    />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                    : this.state.ISTopUpSelected ?

                                        <div /*className="pre-ac-info"*/>
                                            <button onClick={() => this.setState({ ISTopUpSelected: false, PrepaidAccountID: '', prepaidItems: {} })} className="btn back-btn"><i className="fas fa-chevron-left"></i> Back</button>

                                            <h2>Top Up Prepaid Account {this.state.prepaidItems.PrepaidAccountName}</h2>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6">
                                                            <div className="form-group">
                                                                <label>Current Balance ({this.state.prepaidItems.Currency})</label>
                                                                <input className="form-control" value={this.state.prepaidItems.Balance} disabled></input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="filter-check-outer">
                                                        <label className="topup-label">Initial Amount to Top Up({this.state.prepaidItems.Currency})</label>
                                                        <div className="filter-check">
                                                            <label>
                                                                <input type="radio" name="topup-amount" checked={this.state.TopUp == 100 ? true : false} onChange={(e) => this.handleChange(100, "TopUp")}></input>
                                                                <span>100</span>
                                                            </label>
                                                        </div>
                                                        <div className="filter-check">
                                                            <label>
                                                                <input type="radio" name="topup-amount" checked={this.state.TopUp == 200 ? true : false} onChange={(e) => this.handleChange(200, "TopUp")}></input>
                                                                <span>200</span>
                                                            </label>
                                                        </div>
                                                        <div className="filter-check">
                                                            <label>
                                                                <input type="radio" name="topup-amount" checked={this.state.TopUp == 500 ? true : false} onChange={(e) => this.handleChange(500, "TopUp")}></input>
                                                                <span>500</span>
                                                            </label>
                                                        </div>
                                                        <div className="filter-check">
                                                            <label>
                                                                <input type="radio" name="topup-amount" checked={this.state.TopUp == 1000 ? true : false} onChange={(e) => this.handleChange(1000, "TopUp")}></input>
                                                                <span>1000</span>
                                                            </label>
                                                        </div>
                                                        <div className="filter-check">
                                                            <label>
                                                                <input type="radio" name="topup-amount" checked={this.state.TopUp !== 100 && this.state.TopUp !== 200 && this.state.TopUp !== 500 && this.state.TopUp !== 1000 ? true : false} onChange={(e) => this.handleChange('', "TopUp")}></input>
                                                                <span>Custom</span>
                                                            </label>
                                                        </div>
                                                        {this.state.TopUp !== 100 && this.state.TopUp !== 200 && this.state.TopUp !== 500 && this.state.TopUp !== 1000 ?
                                                            <div className="custom-amount">
                                                                <div className="row">
                                                                    <div className="col-lg-6 col-md-6">
                                                                        <div className="form-group">
                                                                            <label>Enter Amount</label>
                                                                            <input type="text" className={this.state.TopUpErr ? "form-control is-invalid" : "form-control"} value={this.state.TopUp} onChange={(e) => this.handleChange(e.target.value, "TopUp")}></input>
                                                                            <div className="invalid-feedback">{this.state.TopUphelperText}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6">
                                                            <div className="form-group">
                                                                <label>Amount Payable ({this.state.prepaidItems.Currency})</label>
                                                                <input className="form-control" value={this.state.TopUp} disabled></input>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6">
                                                            <div className="form-group">
                                                                <label>Projected Balance after Top Up ({this.state.prepaidItems.Currency})</label>
                                                                <input className="form-control" value={parseFloat(Number(this.state.prepaidItems.Balance) + Number(this.state.TopUp)).toFixed(2)} disabled></input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6">
                                                    <div className="submit-btn">
                                                        {this.state.prepaidItems.Currency === 'INR' ? <button className="btn btn-primary" onClick={() => this.RazorPaySetupPrepaid(this.state.TopUp, this.state.prepaidItems)}>
                                                            Pay {this.state.prepaidItems.CurrencySymbol ? parse(this.state.prepaidItems.CurrencySymbol) : ""} {this.state.TopUp}
                                                        </button>
                                                            :
                                                            <PayPalScriptProvider options={{ "client-id": this.state.ClientID, "currency": this.state.Currency }}>
                                                                <PayPalButtons forceReRender={[this.state.TopUp]} style={{ color: "blue", shape: "pill", label: "pay", height: 45 }} /*style={{ layout: "vertical" }}*/ createOrder={(data, actions) => {
                                                                    return actions.order.create({
                                                                        purchase_units: [{
                                                                            amount: {
                                                                                currency_code: this.state.Currency,
                                                                                value: this.state.TopUp
                                                                            }
                                                                        }]
                                                                    });
                                                                }}
                                                                    onApprove={(data, actions) => {

                                                                        return actions.order.capture().then((details) => {
                                                                            const resData = {
                                                                                OrderId: details.id,
                                                                                Status: details.status,
                                                                                intent: details.intent,
                                                                                ApiLink: details.links[0].href,
                                                                                APIMethod: details.links[0].method,
                                                                                PayerId: details.payer.payer_id,
                                                                                PayerCountryCode: details.payer.address.country_code,
                                                                                PayerEmailId: details.payer.email_address,
                                                                                PayerName: details.purchase_units[0].shipping.name.full_name,
                                                                                PurchaseAmount: details.purchase_units[0].amount.value,
                                                                                CurrencyCode: details.purchase_units[0].amount.currency_code,
                                                                                CreatedOn: moment(details.create_time).format('YYYY-MM-DD HH:mm:ss').toString()
                                                                            }
                                                                            try {
                                                                                api.post("property/PayPalTxnSave", resData).then(res => {
                                                                                    this.updateTopUpbalance(res, 'PayPal');
                                                                                }).catch(err => {
                                                                                    // console.log(err)
                                                                                })
                                                                            }
                                                                            catch (err) {
                                                                                // console.log(err)
                                                                            }


                                                                        });
                                                                    }}
                                                                    onError={(err) => {
                                                                        console.error('error from the onError callback', err);
                                                                        cogoToast.error(err)
                                                                    }
                                                                    }
                                                                />
                                                            </PayPalScriptProvider>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        : !this.state.IsSetupPrepaidSelected ?
                                            <div className="create-prepaid-acc">
                                                <p>It appears that you do not have any Prepaid Account setup</p>
                                                <a href="javascript:;" onClick={() => this.setState({ IsSetupPrepaidSelected: true })} className="btn btn-success">Setup My Prepaid Account</a>
                                            </div>

                                            :

                                            <div /*className="pre-ac-info"*/>
                                                <button onClick={() => this.setState({ IsSetupPrepaidSelected: false })} className="btn back-btn"><i className="fas fa-chevron-left"></i> Back</button>
                                                <h2>New Prepaid Account Information</h2>
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label>Name of Prepaid Account</label>
                                                            <input type="text" className={this.state.PrepaidAccountNameErr ? "form-control is-invalid" : "form-control"} value={this.state.PrepaidAccountName} onChange={(e) => this.handleChange(e, "PrepaidAccountName")}></input>
                                                            <div className="invalid-feedback">{this.state.PrepaidAccountNamehelperText}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label>Type of account</label>
                                                            <select className={this.state.PurposeErr ? "form-control is-invalid" : "form-control"} value={this.state.Purpose} onChange={(e) => this.handleChange(e, "Purpose")}>
                                                                <option value=''>Select</option>
                                                                {this.state.purposeList.map(x => {
                                                                    return (
                                                                        <option value={x.StringMapName}>{x.StringMapName}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            <div className="invalid-feedback" >{this.state.PurposehelperText}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="filter-check-outer">
                                                            <label className="topup-label">Initial Amount to Top Up({CommonConfig.userCountryData() ? CommonConfig.userCountryData().CurrencyCode : ''})</label>
                                                            <div className="filter-check">
                                                                <label>
                                                                    <input type="radio" name="topup-amount" checked={this.state.TopUp == 100 ? true : false} onChange={(e) => this.handleChange(100, "TopUp")}></input>
                                                                    <span>100</span>
                                                                </label>
                                                            </div>
                                                            <div className="filter-check">
                                                                <label>
                                                                    <input type="radio" name="topup-amount" checked={this.state.TopUp == 200 ? true : false} onChange={(e) => this.handleChange(200, "TopUp")}></input>
                                                                    <span>200</span>
                                                                </label>
                                                            </div>
                                                            <div className="filter-check">
                                                                <label>
                                                                    <input type="radio" name="topup-amount" checked={this.state.TopUp == 500 ? true : false} onChange={(e) => this.handleChange(500, "TopUp")}></input>
                                                                    <span>500</span>
                                                                </label>
                                                            </div>
                                                            <div className="filter-check">
                                                                <label>
                                                                    <input type="radio" name="topup-amount" checked={this.state.TopUp == 1000 ? true : false} onChange={(e) => this.handleChange(1000, "TopUp")}></input>
                                                                    <span>1000</span>
                                                                </label>
                                                            </div>
                                                            <div className="filter-check">
                                                                <label>
                                                                    <input type="radio" name="topup-amount" checked={this.state.TopUp !== 100 && this.state.TopUp !== 200 && this.state.TopUp !== 500 && this.state.TopUp !== 1000 ? true : false} onChange={(e) => this.handleChange('', "TopUp")}></input>
                                                                    <span>Custom</span>
                                                                </label>
                                                            </div>
                                                            {this.state.TopUp !== 100 && this.state.TopUp !== 200 && this.state.TopUp !== 500 && this.state.TopUp !== 1000 ?
                                                                <div className="custom-amount">
                                                                    <div className="row">
                                                                        <div className="col-lg-6 col-md-6">
                                                                            <div className="form-group">
                                                                                <label>Enter Amount</label>
                                                                                <input type="text" className={this.state.TopUpErr ? "form-control is-invalid" : "form-control"} value={this.state.TopUp} onChange={(e) => this.handleChange(e.target.value, "TopUp")}></input>
                                                                                <div className="invalid-feedback">{this.state.TopUphelperText}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="form-group">
                                                                    <label>Amount Payable ({CommonConfig.userCountryData() ? CommonConfig.userCountryData().CurrencyCode : ''})</label>
                                                                    <input className="form-control" value={this.state.TopUp} disabled></input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="submit-btn">
                                                            {CommonConfig.userCountryData() ? CommonConfig.userCountryData().CurrencyCode === 'INR' ? <button className="btn btn-primary" onClick={() => this.createRazorPaySetupPrepaid(this.state.TopUp)}>
                                                                Pay {CommonConfig.userCountryData().CurrencySymbol ? parse(CommonConfig.userCountryData().CurrencySymbol) : ""} {this.state.TopUp}
                                                            </button>
                                                                :

                                                                <PayPalScriptProvider options={{ "client-id": this.state.ClientID, "currency": this.state.Currency }}>
                                                                    <PayPalButtons forceReRender={[this.state.TopUp]} style={{ color: "blue", shape: "pill", label: "pay", height: 45 }} /*style={{ layout: "vertical" }}*/ createOrder={(data, actions) => {
                                                                        return actions.order.create({
                                                                            purchase_units: [{
                                                                                amount: {
                                                                                    currency_code: this.state.Currency,
                                                                                    value: this.state.TopUp
                                                                                }
                                                                            }]
                                                                        });
                                                                    }}
                                                                        onApprove={(data, actions) => {

                                                                            return actions.order.capture().then((details) => {
                                                                                const resData = {
                                                                                    OrderId: details.id,
                                                                                    Status: details.status,
                                                                                    intent: details.intent,
                                                                                    ApiLink: details.links[0].href,
                                                                                    APIMethod: details.links[0].method,
                                                                                    PayerId: details.payer.payer_id,
                                                                                    PayerCountryCode: details.payer.address.country_code,
                                                                                    PayerEmailId: details.payer.email_address,
                                                                                    PayerName: details.purchase_units[0].shipping.name.full_name,
                                                                                    PurchaseAmount: details.purchase_units[0].amount.value,
                                                                                    CurrencyCode: details.purchase_units[0].amount.currency_code,
                                                                                    CreatedOn: moment(details.create_time).format('YYYY-MM-DD HH:mm:ss').toString()
                                                                                }
                                                                                try {
                                                                                    api.post("property/PayPalTxnSave", resData).then(res => {
                                                                                        this.createTopUpbalance(res, 'PayPal');
                                                                                    }).catch(err => {
                                                                                        // console.log(err)
                                                                                    })
                                                                                }
                                                                                catch (err) {
                                                                                    // console.log(err)
                                                                                }


                                                                            });
                                                                        }}
                                                                        onError={(err) => {
                                                                            console.error('error from the onError callback', err);
                                                                            cogoToast.error(err)
                                                                        }
                                                                        }
                                                                    />
                                                                </PayPalScriptProvider>
                                                                : null
                                                            }
                                                            {/* <button type="submit" className="btn btn-primary">PayPal</button>
                                                                    <button type="submit" className="btn btn-success">Debit / Credit Card</button> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                        }
                    </div>
                </div>
                <Modal show={this.state.showAddMemberModal} onHide={() => this.setState({
                    showAddMemberModal: false,
                    Phone: '', PhoneErr: false, PhonehelperText: '',
                    EmailAddress: '', EmailAddressErr: false, EmailAddresshelperText: '',
                })}
                    backdrop="static"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add Member to {this.state.PrepaidAccountName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label>Full Name *</label>
                            <input type="text" className={this.state.FullNameErr ? "form-control is-invalid" : "form-control"} placeholder="E.g. John Doe" value={this.state.FullName} onChange={(e) => this.handleChange(e, "FullName")} />
                            <div className="invalid-feedback" >{this.state.FullNamehelperText}</div>
                        </div>
                        <div className="form-group">
                            <label>Email address *</label>
                            <input type="text" className={this.state.EmailAddressErr ? "form-control is-invalid" : "form-control"} placeholder="E.g. abac@gmail.com" value={this.state.EmailAddress} onChange={(e) => this.handleChange(e, "EmailAddress")} />
                            <div className="invalid-feedback" >{this.state.EmailAddresshelperText}</div>
                        </div>
                        <div className="form-group">
                            <label>Phone number *</label>
                            <PhoneInput
                                country={'gb'}
                                value={this.state.PhoneNumber}
                                onChange={(e) => this.handleChange(e, "PhoneNumber")}
                            />
                            {/* <input type="text" className={this.state.PhoneNumberErr ? "form-control is-invalid" : "form-control"} placeholder="E.g. 01234567890" value={this.state.PhoneNumber} onChange={(e) => this.handleChange(e, "PhoneNumber")} /> */}
                            <div className="invalid-feedback d-block" >{this.state.PhoneNumberhelperText}</div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({
                            showAddMemberModal: false,
                            Phone: '', PhoneErr: false, PhonehelperText: '',
                            EmailAddress: '', EmailAddressErr: false, EmailAddresshelperText: '',
                        })}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={(e) => this.addMember(e)}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>
        )
    }
}

export default PrepaidAccount
