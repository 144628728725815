import React, { Component } from 'react'
import image1 from "../../assets/img/workspace.svg";
import image2 from "../../assets/img/find-pod.svg";
import image3 from "../../assets/img/confirm.svg";
import image4 from "../../assets/img/enjoy-pod.svg";

class HowDoesItWork extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <div className="how-we-work-outer">
                <div className="container-fluid">
                    <div className="how-work-box">
                        <div className="row mobile-column-reverce">
                            <div className="col-lg-6 col-md-6">
                                <div className="hw-content">
                                    <h2>Office rentals, revolutionised</h2>
                                    <p>
                                        With the Work Pod, you can finish your to-do list anywhere you choose – or, if you’re looking to make some extra cash, rent out your empty workspace by the hour. We provide a platform to advertise (or book!) temporary offices, so wherever in the world you may be, you can find a peaceful sanctuary to get things done.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="hw-img">
                                    <img src={image1} alt=""></img>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 className="hw-head">How does it work?</h2>
                    <div className="how-work-box">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="hw-img">
                                    <img src={image2} alt=""></img>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="hw-content">
                                    <h2>Register to book...</h2>
                                    <p>
                                        From the homepage, users can either browse or create listings. After inputting a desired date and location, you’ll find comprehensive descriptions of every workspace, complete with photos and listed amenities. All you need to do (unless you wish to message the host with enquiries) is pick a timeslot and confirm via PayPal, then your booking will appear on your dashboard.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="how-work-box">
                        <div className="row mobile-column-reverce">
                            <div className="col-lg-6 col-md-6">
                                <div className="hw-content">
                                    <h2>...or register to host</h2>
                                    <p>
                                        Creating a listing is simple: answer the essential questions about your office, upload some photos and it will show up in future searches. Any bookings or enquiries from potential users will appear in your dashboard, so keep your eyes open for notifications. 
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="hw-img">
                                    <img src={image3} alt=""></img>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="how-work-box">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="hw-img">
                                    <img src={image4} alt=""></img>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="hw-content">
                                    <h2>Enjoy the respite, in your pod.</h2>
                                    <p>
                                        With the amenities you chose at your fingertips, bunker down for some solid work, so that later, you can relax in the knowledge that your jobs are done!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        )
    }
}

export default HowDoesItWork;