import React, { Component } from 'react'
import blogimage1 from "../../assets/img/10-signs.jpg";
import blogimg2 from "../../assets/img/image_2021_04_13T12_30_46_583Z.png";
import blog3 from "../../assets/img/blog3.png";
import { CommonConfig } from 'utils/constant';
import parse from 'html-react-parser';
import api from "../../../src/utils/apiClient";
import { apiBase } from '../../../src/utils/config';

class BlogDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.location.state && this.props.location.state.id ? this.props.location.state.id : '',
            blogDetail: {}
        }
    }

    componentDidMount() {
        if (this.props.location.state && this.props.location.state.id) {
            this.getBlogDetailByID(this.props.location.state.id);
        }
    }

    getBlogDetailByID = (BlogID) => {
        let data = {
            PersonID: CommonConfig.loginData().PersonID,
            BlogID: BlogID,
        };
        try {
            api
                .post("utility/getBlogDetailByID", data)
                .then((res) => {
                    if (res.success) {
                        this.setState({ blogDetail: res.data[0] });
                    } else {
                    }
                })
                .catch((err) => {
                    // // console.log(err)
                });
        } catch (err) { }
    }

    render() {
        const { blogDetail } = this.state;
        return (
            <div className="blog-detail-outer">
                <div className="container">
                    <div className="blog-detail-inner">
                        <div className="row">
                            <div className="col-lg-8 col-md-8">
                                <div className="blog-single">
                                    {CommonConfig.isObjectEmpty(blogDetail) ? '' :parse(blogDetail.BlogTemplate)}
                                </div>

                                {/* <div className={this.state.id == 1 ? "blog-single" : "blog-single d-none"}>
                                    <h2>10 SIGNS YOU COULD USE A WORK POD</h2>
                                    <p>
                                        The Work Pod expands to more than just home workers: creatives, office managers and their employees can all find respite in our temporary workspaces. We, of course, would be thrilled to help you. But maybe you’re skeptical, or yet to be convinced our services are the right place to be looking. If any of these situations sound familiar, we’re a better fit for each other than you may expect…
                                    </p>
                                    <div className="blog-single-img">
                                        <img src={blogimage1} alt=""></img>
                                    </div>
                                    <p>
                                        <b>1) You’re thinking of redesigning your workspace.</b> You haven’t quite committed to the idea, but Pinterest and recent articles have been sources of temptation: houseplants, for instance, allegedly boost staff wellbeing by up to 47%. Why not draw some inspiration from reality, book some local spaces and take a proper look around? One day in a different office might make you see marble tiles in a whole new light…
                                    </p>
                                    <p>
                                        <b>2) You consider yourself to be ‘ahead of the times’.</b> You’re the type of person who knows all the latest details on innovation labs, or can define a ‘think tank’ without having to look it up, and that’s one-in-a-million. Every company needs a powerhouse like you… especially considering you need a venue to test out your newest concept. Look down at your blueprint. Then back up at us. We already know you’re joining the dots.
                                    </p>
                                    <p>
                                        <b>3) Maybe you’re starting your own business.</b> Organising the necessities is an endless process, and before you even consider the prospect of office locations, you’re bound to a laptop, lamenting the amount of paperwork still to come. While you’re sorting all the equipment for your full-time headquarters, the ideal solution for now is to work somewhere that promises every amenity you could wish for. We at the Work Pod know you’re going to do great things, and if our office listings can provide you peace of mind, count us in.
                                    </p>
                                    <p>
                                        <b>4) You’re plagued by noise.</b> When it’s not constant drilling sounds, it’s schoolkids burning off their energy outside, and when it’s not that, it’s… something. There’s always something, and you (and/or your employees) are exhausted. Earplugs are out of the question, especially if phone calls are a factor – so if you can’t change the volume level, change your environment. Find the deep focus you’re dreaming about. Don’t fret about commute times; pick a workspace close to the usual headquarters, and realise you’re suddenly able to hear yourself think. This is what taking back control looks like.
                                    </p>
                                    <p>
                                        <b>5) Your productivity’s slumped and you don’t know why.</b> You’ve tried everything – adjusting your diet, making endless to-do lists and drinking enough coffee that the speed of your heartbeat alone could power a small village – but something’s changed from the day you started. Switch up your environment, book a workspace elsewhere (the distance is up to you) and give yourself a taste of that New Job Energy.
                                    </p>
                                    <p>
                                        <b>6) You’re on a modest budget.</b> You’re working on it, but fixed monthly contracts and utility bills would require money you don’t yet have. Paying for one-off office slots – cheap as they come – would be the definition of taking things a day at a time. We understand that a solid portion of our audience sort listings from the lowest to highest prices, and as hosts continue to upload office slots at competitive rates, we can aid you on the hunt for the best possible deal.
                                    </p>
                                    <p>
                                        <b>7) You need a backdrop for your Zoom calls.</b> Boxes. Boxes everywhere. We’d be wrong not to acknowledge the home-workers who use our services for a peaceful getaway, and their biggest struggle is finding the right space for a video call. As much as you’d like to introduce your cat to the contractors, he crawls invasively into view and denies you any choice. Before you know it, your beloved pet sits atop your keyboard and now you’re spamming the letter ‘G’. The bad news is we’ve been there – the good news is we’ve got an office for that.
                                    </p>
                                    <p>
                                        <b>8) You’re sick of the same old scenery.</b> Even the wall painting you’d bought and adored has begun to look a little dull... If you’re not outwardly admitting how much you want a different window view (just once!) then it’s at least starting to show in your work. Mental and creative blocks are completely understandable when routine grows tiring: when there’s nothing new in your life to pay attention to, the well of inspiration can and will run dry. Maybe, for a change, you want to look out and see trees as opposed to skyscrapers. Why not try an hour in a new office, and see if you notice any shift in thought patterns?
                                    </p>
                                    <p>
                                        <b>9) Your flight’s been delayed (or worse, cancelled).</b> Nobody’s ever thrilled to be stuck in the middle of absolutely nowhere – there’s so much on your plate that this now feels like the Universe is out to get you. Perhaps it’s more of a sign to trust it. If you want anything done today, you’ll have to improvise, and we’re certain we can find you a well-priced workspace for these next few slow hours.
                                    </p>
                                    <p>
                                        <b>10) You’ve always wanted to visit (nearby place name).</b> Yes, that place – the cultural epicentre you’ve not yet given a chance – we know you’ve been planning itineraries and secretly googling its hotspots between meetings. But how could you go, with how busy work has been lately? Let’s call it a compromise: book yourself an office for one, get your 9-5 out of the way and spend the meantime exploring. Perhaps even call it a ‘business trip’. With your work-life balance one step closer to equal, you can rest assured it would be your most exciting lunch break that week.
                                    </p>
                                </div>

                                <div className={this.state.id == 2 ? "blog-single" : "blog-single d-none"}>
                                    <h2>Hear yourself think: noise reduction tips for employers and home workers</h2>
                                    <p>
                                        Gravely-impacted productivity, increased stress, lowered job satisfaction and even lower employee morale: these are only some of the impacts of noise upon employees’ working lives. Office equipment manufacturer Steelcase reported that 49% of workers struggle to concentrate in noisy environments, losing an average of 86 minutes per day due to auditory distractions. It’s also worth mentioning that noise is classified as a safety hazard: according to the designated government website, an estimated 17,000 people in the UK suffer either deafness, ringing in the ears (tinnitus) or other conditions caused by excessive volumes at work.
                                    </p>
                                    <div className="blog-single-img">
                                        <img src={blogimg2} alt=""></img>
                                    </div>
                                    <p>
                                        You’ve come to expect it in certain environments - concerts, construction sites, motorbike conventions – but in office scenarios, it’s harder to simply grin and bear. ‘Best-case’ scenario, employees make a few mistakes; in the very worst, they leave their position with serious hearing impairment. Even heavy metal enthusiasts have their limits. Luckily, we at the Work Pod have devised a handy list of secrets to making your environment a more peaceful (and productive!) space:
                                    </p>
                                    <ul>
                                        <li>
                                            This may sound counterproductive, but among employees that don’t have call-handling duties, you may benefit from promoting the use of noise-cancelling headphones for white noise or instrumental music (especially for colleagues who struggle with ADHD or other mental health conditions). This is backed not only by popular experience but also recent studies: scientists concluded that white noise can aid the brain’s auditory centres, thereby improving concentration and memory.
                                        </li>
                                        <li>
                                            A similar but separate alternative to headphones is the concept of sound masking. Through the use of ambient noise (tailored to match the frequency of human speech), faraway conversations deliberately fade into the background, balancing the acoustics to more comfortable levels – but still quiet enough that you can hold a discussion with somebody in your immediate vicinity.
                                        </li>
                                        <li>
                                            You might have noticed a recent influx in designated quiet rooms. Created to restore mental and physical energy levels, momentary peace inside a soundproof, minimally-decorated space will raise employees to their cognitive and creative best, raising the standard for their colleagues. With soft lighting, an accessible central location and strict rules regarding distractions (i.e. no mobile phones or talking above a whisper), the whole workforce will benefit from a tenfold increase in focus. If your budget or location doesn’t allow for this, you may want to improvise by renting offices with meeting rooms (repurposing the meeting room accordingly) once a week.
                                        </li>
                                        <li>
                                            While open-plan offices have obvious appeal, cubicles and wall partitions – even low-level ones – are a tried-and-true means of improving room-wide sound levels. If you’re worried about the prospect of it looking dated and unwelcoming, there are plenty of modernised design ideas available online.
                                        </li>
                                        <li>
                                            Printers and photocopiers don’t just generate their own noise; they become an unintended gathering point for employees. If space allows, try relocating the machines in a separate location to your call-handling colleagues – a separate room would be ideal. On the topic of machinery, PC boxes on the desk are a shortcut to fan and machinery noise pollution: relocate them underneath, for peace’s sake.
                                        </li>
                                        <li>
                                            May we gently suggest you re-evaluate your flooring? Hard materials – such as wood, porcelain and ceramic – generate a surprisingly large amount of noise pollution, due to their lack of sound absorption. If you’re thinking of redesigning your office block (or cheaper yet, renting a temporary workspace), carpet or vinyl/linoleum are perfect for sound absorption. Similarly, suspended ceilings, acoustic walls and slatted window blinds work wonders for acoustics. You might even want to be adventurous and indulge in a ‘green wall’: the bigger the houseplant, the bigger the soundproofing impact.
                                        </li>
                                    </ul>
                                    <p>
                                        It seems like there are a million ways to solve it, all of which are either costly, a major hassle or both. Want something both budget-friendly and easy? Save yourself a world of stress and rent hourly workspaces with The Work Pod.
                                    </p>
                                </div>

                                <div className={this.state.id == 3 ? "blog-single" : "blog-single d-none"}>
                                    <h2>Workplace Happiness: It’s a DIY Job</h2>
                                    <p>
                                        Does anyone else find it a tad weird that ‘workplace happiness’ has become a buzzword? The approaches to it have become almost clinical and robotic; a concrete, measurable objective as opposed to a complex and flighty state of mind – heavily disconnected from, well... regular happiness (i.e. the emotion).
                                    </p>
                                    <div className="blog-single-img">
                                        <img src={blog3} alt=""></img>
                                    </div>
                                    <p>
                                        2020 and 2021 saw an insurgence of the phrase “romanticise your life”: referring to adjusting those rose-tinted glasses; buying yourself flowers; embracing the little things like sunny days, cups of coffee and commute journeys that feel like a film scene. But how can we apply that philosophy in the workplace? It’s one responsibility after another: you spend years of your life under UV lights and stacks of paperwork, then you come home and try to decide what you’re having for lunch the next day. Then there’s the matter of reviewing your bank account and putting away some savings whenever you get the chance. Maybe you even plan tomorrow’s work outfit the next day, if you’re super on-the-ball. You start to build life around work, focusing on the free spaces and “just X more days until Friday”, rather than entertain the prospect that office life could be fulfilling too. Does that not exhaust you?
                                    </p>
                                    <p>
                                        We’ve compiled a roadmap (tried and tested by the Creative Team themselves) for keeping your work routine an interesting place to be. It’s large, but ultimately non-exhaustive – everybody’s definition of happiness is unique. Take this as a starting point and adjust accordingly!
                                    </p>
                                    <ul>
                                        <li>
                                        	<b>Wake up and live your morning on purpose.</b> Emotions rule us, as much as we’d like them not to: a ‘good’ or ‘bad’ morning experience can set the tone for the entire day. Try to live in the present and give every morning activity your full mindful attention – all too easily, our mind jumps hours ahead, thinking “oh dear, another week...” There’s a reason you’re urged to take things a step at a time: the alternative can be overwhelming. Embrace the warmth of your chosen beverage in your hands, and appreciate the smell of it before you take a sip. Analyse your routine, down to the small details – from the amount of sleep you get and what you choose for breakfast (‘nutritious’ and ‘tasty’ aren’t mutually exclusive!) right down to bedsheets and kitchenware. Maybe you get dressed as the radio plays, or play a song while you’re brushing your teeth. We won’t pretend you’re made of money, or that this is an immediate fix, but there can always be something small to do to make the first hour worthwhile. (This also encompasses staring out the window during a commute! Is there a house with pretty details you’ve never noticed? Is there a bakery you’ve never visited? Can you make up backstories for the strangers you pass on the street?)
                                        </li>
                                        <li>
                                        	<b>Revitalise your to-do list.</b> Have you heard of the Eisenhower matrix? Whether you prioritise like a pro or it’s an area you need to develop, this makes the process effortless, which saves time wanting to tear your hair out over a task that isn’t even time-sensitive (and turning it into an excuse to feel bad about yourself).
                                        </li>
                                        <li>
                                        	<b>Pretend you’re in a movie montage during busy days.</b> Soundtracks are a huge help, if your role allows you to wear earphones (don’t spend too much time picking an album!); otherwise, just pick a song to get stuck in your brain. Maybe even narrate the scene internally. The movie is your life, and this is your chance to remember you’re the protagonist!
                                        </li>
                                        <li>
                                        	<b>During quieter, routine tasks, notice how quiet your head gets.</b> Do you jump straight to the conclusion that you’re bored, or realise that this is one of the few moments your head isn’t full-to-bursting and overloaded? Why deliberately dissatisfy yourself by fantasising about being somewhere else? This is a chance for cognitive reframing: repetitive tasks like scanning and photocopying are a secret mindfulness opportunity.
                                        </li>
                                        <li>
                                        	<b>Try something new every chance you get.</b> Humans are a tricky breed: we crave stability but routine-induced boredom is lethal to our mental health. Novelty can be found everywhere: switching the colour pen you write in, the font of your Word document (within reason...), the flavour of beverage you drink, the recipes you make for lunch or the places you go to buy it, the route you walk during your breaks, and even initiating conversation with a colleague you don’t particularly talk to. It’s a hidden learning experience; an opportunity to satisfy your natural curiosity: maybe you’ll like what you find, or maybe you’ll realise how much you appreciate your usual choice.
                                        </li>
                                        <li>
                                        	<b>Go out for fresh air whenever the opportunity allows.</b> Yes, even if it’s cold (wrap up accordingly). You can enjoy the peace of walking in solitude, or make it a social experience by inviting someone to keep you company.
                                        </li>
                                        <li>
                                        	<b>To the maximum extent you’re allowed, personalise your cubicle/desk.</b> Go full-on Pinterest with it! Mugs from home, throw cushions, Blu-Tac wall posters (even motivational slogans if they float your boat), stationery... maybe put some flowers in a vase. Life’s too short not to love your surroundings, and remember: nothing that makes you smile is ever a waste of time.
                                        </li>
                                        <li>
                                        	<b>Dopamine dressing.</b> A recent name for an age-old concept: wearing what makes you feel good! In this context, make the effort to reconcile uniform regulations with your sense of personal style. Fashion may not be everyone’s thing, but it doesn’t have to be a case of following trends – simply dressing closer to the ideal version of your head, letting your outer vessel reflect the colour and light inside you.
                                        </li>
                                        <li>
                                        	<b>Do something nice for your colleagues.</b> It doesn’t have to be a grand gesture, but it’s a truth universally acknowledged that making others feel good does the same for us. There’s a bonding opportunity anywhere you look: sharing your home-cooking with the office, or asking with genuine interest about their life and background. Every human being carries a story inside them, and it can be humbling and intriguing to learn what your colleagues have to say.
                                        </li>
                                        <li>
                                        	<b>When you get a spare moment, discreetly write down amusing or happy moments after they happen.</b> Those are memories, and they’re yours to keep. Don’t let them get away.
                                        </li>
                                        <li>
                                        	<b>See if you can play a role in organising team events.</b> Similar principle to the above. Workplace relationships can make or break a job, and where there’s a chance to get to know each other, there’s a chance to bring more pleasure to your day-to-day activities.
                                        </li>
                                        <li>
                                        	<b>Appreciate that, in some way or another, you’re directly helping somebody.</b> That refers to both those higher up in the organisational structure, to external stakeholders like customers/service users whose days are made a little easier through you completing your to-do list. Of course you’d rather people directly show their appreciation – maybe you feel invisible, and that’s understandable –  but sometimes it helps to give yourself the reassurance you crave from other people. If your role had no purpose and served nobody, you wouldn’t be around!
                                        </li>
                                    </ul>
                                </div> */}

                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="blog-sidebar">
                                    <div className="blog-latest-post">
                                        <h3>Latest Post</h3>
                                        <div className="lp-box">
                                            <div className="lp-img">
                                                <img src={blogimg2}></img>
                                            </div>
                                            <div className="lp-details">
                                                <h4>Hear yourself think: noise reduction</h4>
                                                <p>
                                                    Gravely-impacted productivity, increased stress...<a href="#">Read more</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BlogDetail
