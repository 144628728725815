import React, { Component } from 'react'
import blogimg from "../../assets/img/10-signs.jpg";
import blogimg1 from "../../assets/img/image_2021_04_13T12_30_46_583Z.png";
import blogimg2 from "../../assets/img/image_2021_04_13T12_30_46_583Z.png";
import { CommonConfig } from "utils/constant";
import api from "../../../src/utils/apiClient";
import { apiBase } from '../../../src/utils/config';
import blog3 from "../../assets/img/blog3.png";

class BlogPress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogList: []
    }
  }

  componentDidMount = () => {
    this.getBlogList()
  }

  getBlogList = () => {
    let data = {
      PersonID: CommonConfig.loginData().PersonID,
      PersonType: CommonConfig.loginData().PersonType,
    };
    try {
      api
        .post("utility/getBlogList", data)
        .then((res) => {
          if (res.success) {
            this.setState({ blogList: res.data });
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  };

  render() {
    const { blogList } = this.state;
    return (
      <div className="blog-outer">
        <div className="container">
          <div className="common-heading">
            <ol className="breadcrumb">
              <li>
                <a
                  href="javascript:;"
                  onClick={() =>
                    this.props.history.push("/admin/homepage")
                  }
                >
                  <span>Home</span>
                </a>
              </li>
              <li className="active">
                <span>Blog & Press</span>
              </li>
            </ol>

            <h2>From Our Blog</h2>
            <p>Stay updated on the latest discussions</p>
          </div>
          <div className="blog-inner">
            {blogList
              .map((x, i) => {
                return i % 4 === 0 ? blogList.slice(i, i + 4) : null;
              })
              .filter((x) => x != null)
              .map((i, idx) => {
                return (<div className="row" key={idx}>
                  {i.map((item) => (
                    <div className="col-lg-4 col-md-4" onClick={() => this.props.history.push({
                      pathname: '/admin/blogdetail',
                      state: { id: item.BlogID }
                    })}>
                      <div className="blog-box">
                        <div className="blog-img">
                          <a href="javascript:;" >
                            <img src={apiBase + item.Image} alt="" />
                            <div className="gr-overlay"></div>
                          </a>
                        </div>
                        <div className="blog-content">
                          <a href="javascript:;" className="blog-title">{item.Heading}</a>
                          <a href="javascript:;" className="blog-category"><i className="fa fa-bookmark-o"></i> {item.Category}</a>
                          <p>
                            {item.ShortDescription + "..."}
                          </p>
                          <div className="blog-author">
                            {/* <span><i className="fa fa-calendar-o"></i> 2 years ago</span> */}
                            <span><i className="fa fa-user"></i> by <a href="javascript:;">WorkPod Creative Team</a></span>
                          </div>
                        </div>
                      </div>
                    </div>

                  ))}
                </div>
                );
              })}
            {/* <div className="col-lg-4 col-md-4" onClick={() => this.props.history.push({
                pathname: '/admin/blogdetail',
                state: { id: 1 }
              })}>
                <div className="blog-box">
                  <div className="blog-img">
                    <a href="javascript:;" >
                      <img src={blogimg} alt="" />
                      <div className="gr-overlay"></div>
                    </a>
                  </div>
                  <div className="blog-content">
                    <a href="javascript:;" className="blog-title">10 Signs You could use a <br></br>Work Pod</a>
                    <a href="javascript:;" className="blog-category"><i className="fa fa-bookmark-o"></i> Articles</a>
                    <p>
                      The Work Pod expands to more than just home workers: creatives, office managers and their employees can all find...
                    </p>
                    <div className="blog-author">
                      <span><i className="fa fa-user"></i> by <a href="javascript:;">WorkPod Creative Team</a></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4" onClick={() => this.props.history.push({
                pathname: '/admin/blogdetail',
                state: { id: 2 }
              })}>
                <div className="blog-box">
                  <div className="blog-img">
                    <a href="javascript:;">
                      <img src={blogimg1} alt="" />
                      <div className="gr-overlay"></div>
                    </a>
                  </div>
                  <div className="blog-content">
                    <a href="javascript:;" className="blog-title">Hear yourself think: noise reduction tips for...</a>
                    <a href="javascript:;" className="blog-category"><i className="fa fa-bookmark-o"></i> Articles</a>
                    <p>
                      Gravely-impacted productivity, increased stress, lowered job satisfaction and even lower employee...
                    </p>
                    <div className="blog-author">
                      <span><i className="fa fa-user"></i> by <a href="javascript:;">WorkPod Creative Team</a></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4" onClick={() => this.props.history.push({
                pathname: '/admin/blogdetail',
                state: { id: 3 }
              })}>
                <div className="blog-box">
                  <div className="blog-img">
                    <a href="javascript:;">
                      <img src={blog3} alt="" />
                      <div className="gr-overlay"></div>
                    </a>
                  </div>
                  <div className="blog-content">
                    <a href="javascript:;" className="blog-title">Workplace Happiness: It’s a <br></br>DIY Job</a>
                    <a href="javascript:;" className="blog-category"><i className="fa fa-bookmark-o"></i> Articles</a>
                    <p>
                      Does anyone else find it a tad weird that ‘workplace happiness’ has become a buzzword? The approaches...
                    </p>
                    <div className="blog-author">
                      <span><i className="fa fa-user"></i> by <a href="javascript:;">WorkPod Creative Team</a></span>
                    </div>
                  </div>
                </div>
              </div> */}

            {/* <div className="col-lg-4 col-md-4">
                        <div className="blog-box">
                          <div className="blog-img">
                            <a href="javascript:;">
                              <img src={blogimg} alt="" />
                              <div className="gr-overlay"></div>
                            </a>
                          </div>
                          <div className="blog-content">
                            <a href="javascript:;" className="blog-title">Here’s What People Are Saying About...</a>
                            <a href="javascript:;" className="blog-category"><i className="fa fa-bookmark-o"></i> Rentals</a>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis et sem sed sollicitudin. Donec non odio…
                            </p>
                            <div className="blog-author">
                              <span><i className="fa fa-calendar-o"></i> 2 years ago</span>
                              <span><i className="fa fa-user"></i> by <a href="javascript:;">Mike Parker</a></span>
                            </div>
                          </div>
                        </div>
                      </div> */}

          </div>
        </div>
      </div>
    )
  }
}

export default BlogPress
