import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import api from "../../../src/utils/apiClient";
import { ProgressBar } from "primereact/progressbar";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { Button, ButtonGroup } from "react-bootstrap";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { CommonConfig } from "utils/constant";
import {InputSwitch} from 'primereact/inputswitch';
import moment from "moment";
import cogoToast from "cogo-toast";
import { Checkbox } from "primereact/checkbox";

class ReviewListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dt: null,
      podList: [],
      personType: "",
      toggleSwitch:[],
      EntityList:[],
      reviewList :[],
    };
  }

  componentDidMount() {
    // this.getMyPodList();
    this.getReviewList();
    this.getReviewCategory();
  }

  getReviewList = () => {
    try {
        api
          .get("property/getReviewList")
          .then((res) => {
            if (res.success && res.data.length > 0) {
              // // console.log("entityList",res.data)
                // res.data[0].EntityList = String(res.data[0].EntityList).split(',')
                res.data.map((data,idx) => {
                  res.data[idx].Rate = String(res.data[idx].Rate).split(',')
                  res.data[idx].EntityList = String(res.data[idx].EntityList).split(',')
                })
                this.setState({
                    reviewList: res.data
                })
            } else {
            }
          })
          .catch((err) => {

          });
    } catch (err) {}
  }

  getReviewCategory = () => {
    let data = {
        StringMapType: "RATINGCATEGORY"
    }
    try {
        api.post("utility/getStringMap", data).then(res => {
            if (res.success) {
                this.setState({ EntityList: res.data });
            }
            else {
            }
        }).catch(err => {
            // // console.log(err)
        })
    } catch (err) {
    }
}

  filterDate = (value, filter) => {
    if (
      filter === undefined ||
      filter === null ||
      (typeof filter === "string" && filter.trim() === "")
    ) {
      return true;
    }

    if (value === undefined || value === null) {
      return false;
    }

    return value === this.formatDate(filter);
  };

  formatDate = (date) => {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = "0" + month;
    }

    if (day < 10) {
      day = "0" + day;
    }

    return date.getFullYear() + "-" + month + "-" + day;
  };

  onRepresentativesChange = (e) => {
    this.state.dt.current.filter(e.value, "representative.name", "in");
    this.setState({ selectedRepresentative: e.value });
  };

  onDateChange = (e) => {
    this.state.dt.current.filter(e.value, "date", "custom");
    this.setState({ selectedDate: e.value });
  };

  onStatusChange = (e) => {
    this.state.dt.current.filter(e.value, "status", "equals");
    this.setState({ selectedStatus: e.value });
  };

  RateTemplate = (rowData,idx, StringMapName) => {
    // // console.log("rowdataaaaaaaaaa",rowData)
    let i = rowData.EntityList.indexOf(StringMapName);
    // // console.log("i..........................",rowData,rowData.Rate,rowData.EntityList[i],StringMapName,i);
    return (
      <React.Fragment>
        <span className="p-column-title">Rate</span>
        {rowData.Rate[i]?rowData.Rate[i]:null}
      </React.Fragment>
    )
  }

  PropertyNameTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Name</span>
        {rowData.PropertyName}
      </React.Fragment>
    );
  };

  DescriptionTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Description</span>
        {rowData.Description}
      </React.Fragment>
    );
  };

  ReviewStatusTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Status</span>
        {rowData.ReviewStatus}
      </React.Fragment>
    )
  };

  handleReviewStatus = (rowData, Status) => {
    try {
        let data = {
            EntityID: rowData.UserReviewRatingID,
            Status: Status,
            Type: 'Review'
        }
        api
          .post("user/updateReviewFeedbackStatus", data)
          .then((res) => {
            if (res.success) {
                cogoToast.success("Status updated")
                this.getReviewList();
            } else {
            }
          })
          .catch((err) => {

          });
    } catch (err) {}
  }

  ActionTemplate = (rowData) => {
    return (
        <React.Fragment>
          <span className="p-column-title">Action</span>
          {
             rowData.ReviewStatus === 'Pending'? <>
              <i className="fas fa-check text-success" onClick={()=>this.handleReviewStatus(rowData,"Approved") }></i> {' '}
              <i className="fas fa-times text-danger" onClick={()=>this.handleReviewStatus(rowData,"Rejected") }></i> </>:
              <i className="fas fa-times text-danger" onClick={()=>this.handleReviewStatus(rowData,"Rejected") }></i> 
          }
        </React.Fragment>
      )
  }

  LastModifiedTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">CreatedOn</span>
        {moment(rowData.CreatedOn).format("DD-MM-YYYY HH:mm A")}
      </React.Fragment>
    );
  };

  header = () => {
    return (
      <div className="table-header">
        List of Pods
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder="Global Search"
          />
        </span>
      </div>
    );
  };

  render() {
    // // console.log("review...",this.state.EntityList);
    const { dt, reviewList, header, globalFilter } = this.state;
    return (
      <div className="datatable-filter-demo">
        <div className="podlisting-table">
          <DataTable
            ref={dt}
            value={reviewList}
            paginator
            rows={10}
            header={header}
            className="p-datatable-customers"
            globalFilter={globalFilter}
            emptyMessage="No Reviews found."
          >
            <Column
              field="PropertyName"
              header="Listing"
              body={this.PropertyNameTemplate}
              filter
              filterPlaceholder="Search by Pod name"
            />

            <Column
              field="Description"
              // filterField="status"
              header={"Review"}
              body={this.DescriptionTemplate}
            />

            <Column
              field="CreatedOn"
              header="Last Modified"
              body={this.LastModifiedTemplate}
              sortable
            />

            {this.state.reviewList.length ?this.state.EntityList.map((data,idx)=>{
               return ( 
                <Column
                  field="EntityList"
                  header={data.StringMapName}
                  body={(rowData) => this.RateTemplate(rowData,idx,data.StringMapName)}
                />
              )
            }):null}
           
            <Column
              field="ReviewStatus"
              header="Status"
              body={this.ReviewStatusTemplate}
            />

            <Column
              field="status"
              filterField="status"
              header={"Action"}
              body={this.ActionTemplate}
            />

          </DataTable>
        </div>
      </div>
    );
  }
}

export default ReviewListing;
;