import React, { Component } from "react";
import cogoToast from 'cogo-toast';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import api from '../../utils/apiClient';
import { CommonConfig } from 'utils/constant';
const passwordText = `One capital (upperCaseLetters) required <br />
                    One small (lowerCaseLetters) required<br /> 
                    One special character required<br />
                    One number required<br />
                    Minimum 8 characters required`

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            confirmPassword: "",
            CurrentPassword: "",
            passwordErr: false,
            confirmPasswordErr: false,
            currentPasswordErr: false,
            passwordHelperText: "",
            confirmPasswordHelperText: "",
            currentPasswordHelperText: "",
            Loading: false,
            PersonID: '',
            IsLinkExpired: true,
            checkLetter: false,
            checkUpperCase: false,
            checkLowerCase: false,
            checkNumber: false,
            checkSpecialCharacter: false,
            checkPassword: false,
            ResetPasswordValidated: false
        };

    }

    componentDidMount() {
        this.showLoader();
        this.validateLink();
    }

    validateLink = () => {
        var url = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
        url = url.slice(0, url.indexOf('?'))
        let data = {
            // UUID: this.props.match.params.id
            UUID: url,
            type: "resetpassword"
        }
        try {
            api.post("userauthentication/verifyLink", data).then(res => {
                this.hideLoader();

                if (res.success && res.Data.length > 0) {
                    this.setState({
                        PersonID: res.Data[0].PersonID,
                        IsLinkExpired: false
                    })
                }
                else {
                    cogoToast.error("Your password reset request has already expired. Please try again.");
                    this.props.history.push('/admin/homepage')
                }
            }).catch(err => {
                // cogoToast.error("Something went wrong");
                // // console.log("error....", err);
            })
        }
        catch (err) {
            // // console.log("err.....", err);
        }
    }

    validate = (event) => {
        let IsFormValid = true;
        if (CommonConfig.isEmpty(this.state.password)) {
            IsFormValid = false;
            this.setState({ passwordErr: true, passwordHelperText: "Please enter password" });
        }
        if (CommonConfig.isEmpty(this.state.confirmPassword)) {
            IsFormValid = false;
            this.setState({ confirmPasswordErr: true, confirmPasswordHelperText: "Please enter confirm password" });
        }
        if (this.state.confirmPasswordErr) {
            IsFormValid = false;
            this.setState({ confirmPasswordErr: true, confirmPasswordHelperText: "Please enter valid confirm password" });
        }
        if (this.state.passwordErr) {
            IsFormValid = false;
            this.setState({ passwordErr: true, passwordHelperText: "Please enter valid password" });
        }
        if (this.state.password !== this.state.confirmPassword) {
            IsFormValid = false;
            this.setState({ confirmPasswordErr: true, confirmPasswordHelperText: "Password and Confirm Password does not match" });
        }
        this.setState({ ResetPasswordValidated: true })
        return IsFormValid
    }

    handleChange = (event, type) => {
        if (type === "password") {
            this.setState({ password: event.target.value })
        }
        else if (type === "confirmpassword") {
            this.setState({ confirmPassword: event.target.value })
        }
    }

    showLoader = () => {
        this.setState({ Loading: true });
    }

    hideLoader = () => {
        this.setState({ Loading: false });
    }

    reset = (event) => {
        event.preventDefault()
        // const form = event.currentTarget;
        // if (form.checkValidity() === false) {
        //     event.preventDefault();
        //     event.stopPropagation();
        //   }

        //   this.setState({ResetPasswordValidated:true})
        if (this.validate(event)) {
            this.setState({ ResetPasswordValidated: true })
            let isMatch = this.state.password.localeCompare(this.state.confirmPassword);

            if (isMatch === 0) {
                this.showLoader();

                try {
                    let data = {
                        UserID: this.state.PersonID,
                        Password: this.state.password,
                        // CurrentPassword : this.state.CurrentPassword
                    }
                    api.post('userauthentication/resetPassword', data).then(res => {
                        if (res.success) {
                            this.hideLoader();
                            cogoToast.success("Password updated successfully");
                            this.props.history.push("/admin/homepage");
                        }
                        else {
                            cogoToast.error("Something went wrong");
                            this.hideLoader();
                        }
                    }).catch(err => {
                        // cogoToast.error("Something Went Wrong");
                        this.hideLoader();
                    });

                } catch (error) {
                    // cogoToast.error("Something Went Wrong");
                    this.hideLoader();
                }

            }
            else {
                // cogoToast.error("Password Not Match");
            }
        } else {
            //    const form = event.currentTarget;
            // if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            //   }
            this.setState({ ResetPasswordValidated: true })
            // event.preventDefault();
            // event.stopPropagation();
        }

    }

    handleBlur = (event, type) => {

        if (type === "password") {
            this.setState({ checkPassword: true });
            let passwordVal = event.target.value;
            if (CommonConfig.isEmpty(passwordVal)) {
                this.setState({
                    passwordErr: true,
                    checkNumber: false,
                    checkLetter: false,
                    checkLowerCase: false,
                    checkSpecialCharacter: false,
                    checkUpperCase: false,
                    password: passwordVal,
                    passwordHelperText: "Please enter Password"
                });
            } else if (passwordVal) {
                let isError = false;
                if (passwordVal.match(CommonConfig.RegExp.regExpNumber)) {
                    this.setState({ password: passwordVal, checkNumber: true, passwordErr: false, passwordHelperText: "" });
                }
                else {
                    isError = true;
                    this.setState({ password: passwordVal, checkNumber: false, passwordErr: true, passwordHelperText: "" });
                }
                if (passwordVal.match(CommonConfig.RegExp.regExpUpperCase)) {
                    this.setState({ password: passwordVal, checkUpperCase: true, passwordErr: false, passwordHelperText: "" });
                }
                else {
                    isError = true;
                    this.setState({ password: passwordVal, checkUpperCase: false, passwordErr: true, passwordHelperText: "" });
                }
                if (passwordVal.match(CommonConfig.RegExp.regExpLowerCase)) {
                    this.setState({ password: passwordVal, checkLowerCase: true, passwordErr: false, passwordHelperText: "" });
                }
                else {
                    isError = true;
                    this.setState({ password: passwordVal, checkLowerCase: false, passwordErr: true, passwordHelperText: "" });
                }
                if (passwordVal.length >= 8) {
                    this.setState({ password: passwordVal, checkLetter: true, passwordErr: false, passwordHelperText: "" });
                }
                else {
                    isError = true;
                    this.setState({ password: passwordVal, checkLetter: false, passwordErr: true, passwordHelperText: "" });
                }
                if (passwordVal.match(CommonConfig.RegExp.regExpSpecialCharacter)) {
                    this.setState({ password: passwordVal, checkSpecialCharacter: true, passwordErr: false, passwordHelperText: "" });
                }
                else {
                    isError = true;
                    this.setState({ password: passwordVal, checkSpecialCharacter: false, passwordErr: true, passwordHelperText: "" });
                }
                if (isError) {
                    this.setState({ passwordErr: true });
                }
            }
            else {
                this.setState({ passwordErr: false, passwordHelperText: "", password: passwordVal });
            }
        }

        else if (type === "confirmpassword") {
            let confirmPasswordVal = event.target.value;
            if (confirmPasswordVal === "" || confirmPasswordVal === null) {
                this.setState({ confirmPasswordErr: true, open: true, confirmPasswordHelperText: "Please enter Confirm Password" });
            }
            else if (confirmPasswordVal !== this.state.password) {
                // cogoToast.error("Password Not Match");
            }
            else {
                this.setState({ confirmPassword: confirmPasswordVal, confirmPasswordErr: false, confirmPasswordHelperText: "" });
            }
        }

    };


    render() {
        return (
            <div className="custom-modal-login-inner mt-4">
                <div className="modal-body-right pull-right">
                    {
                        this.state.IsLinkExpired ?
                            null
                            :
                            <div className="modal-content reset-password">
                                <div className="modal-header">
                                    <h4 className="modal-title">Update password</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="modal-login-form register">
                                        <Form noValidate validated={this.state.ResetPasswordValidated} onSubmit={(event) => this.reset(event)}>
                                            <Form.Row>
                                                <Form.Group className="mb-15">
                                                    <span><h6 className="font-weight-normal">Must include at least one symbol or number and have at least 8 characters.</h6></span>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} md="12" controlId="password" className="mb-15">
                                                    {/* <Form.Label>Email</Form.Label> */}
                                                    <Form.Control
                                                        required
                                                        type="password"
                                                        placeholder="Password"
                                                        value={this.state.password}
                                                        autoFocus
                                                        onChange={(event) => this.handleBlur(event, "password")}
                                                        onBlur={(event) => this.handleBlur(event, "password")}
                                                        onFocus={() => this.setState({ passwordErr: false, passwordHelperText: "", checkPassword: true })}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{this.state.passwordHelperText}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} md="12" controlId="confirmpassword" className="mb-15">
                                                    {/* <Form.Label>Email</Form.Label> */}
                                                    <Form.Control
                                                        required
                                                        type="password"
                                                        placeholder="Re-enter your password"
                                                        value={this.state.confirmPassword}
                                                        onChange={event => this.handleChange(event, "confirmpassword")}
                                                        onBlur={(event) => this.handleBlur(event, "confirmpassword")}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{this.state.confirmPasswordHelperText}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Button type="submit" className="homey_login_button btn btn-primary btn-full-width">Update</Button>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        )
    }
}

export default ResetPassword;