import moment from 'moment';
import React, { Component } from 'react'
import logo from "../../assets/img/logo.svg";
import { withNamespaces } from 'react-i18next';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        const { t } = this.props;
        return (
            <footer>
                <div className="container">
                    <div className="footer-inner">
                        <div className="row">
                            <div className="col-lg-3 col-md-12">
                                <div className="f-info">
                                    <a href="javascript:;" className="f-logo"><img src={logo} alt="" /></a>
                                    {/* <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.
                                    </p>
                                    <a href="javascript:;" className="f-readmore-link" onClick={()=>{window.location.href = '/admin/aboutus'}}>Read More <i className="fa fa-angle-right"></i></a> */}
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-6">
                                <div className="f-nav">
                                    <h2 className="footer-heading">Explore</h2>
                                    <ul>
                                        {/* <li>
                                            <a href="javascript:;">Home</a>
                                        </li> */}
                                        <li>
                                            <a href="javascript:;" onClick={()=>{window.location.href = '/admin/homepage'}}>Search</a>
                                        </li>
                                            {/* <li>
                                                <a href="javascript:;">Rental</a>
                                            </li> */}
                                        {/* <li>
                                            <a href="javascript:;" onClick={()=>{window.location.href = '/admin/requesttobook'}}>Request to book</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;" onClick={()=>{window.location.href = '/admin/places'}}>Places</a>
                                        </li> */}
                                        <li>
                                            <a href="/admin/aboutus">About</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-6">
                                <div className="f-nav">
                                    <h2 className="footer-heading">Company</h2>
                                    <ul>
                                        <li>
                                            <a href="javascript:;" onClick={()=>{window.location.href = '/admin/blogpress'}}>Blog and Press</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;" onClick={()=>{window.location.href = '/admin/faq'}}>FAQ</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;" onClick={()=>{window.location.href = '/admin/termsofservice'}}>Terms Of Service</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;" onClick={()=>{window.location.href = '/admin/privacypolicy'}}>Privacy Policy</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;" onClick={()=>{window.location.href = '/admin/paymenttermsofservice'}}>Payment Terms</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="f-contact">
                                    <h2 className="footer-heading">Contact Us</h2>
                                    <ul>
                                        <li>
                                            <a href="javascript:;"><i className="fas fa-map-marker-alt"></i> {t('Footer:Address')}</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;"><i className="fas fa-phone-square-alt"></i> {t('Footer:ContactNo')}</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;"><i className="far fa-envelope"></i> {t('Footer:Email')}</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;" onClick={()=>{window.location.href = '/admin/contactus'}}><i className="far fa-arrow-alt-circle-right"></i> Contact us</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright">
                        <div className="row">
                            <div className="col-lg-9 col-md-9">
                                <div className="copyright-text">
                                    <p>The Workpod - All rights reserved - Designed and Developed by <a href="javascript:;">Cognisun</a> | <strong>Version </strong>: 0.1 {moment().format('YYYY-MM-DD-HH:mm')}</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <div className="f-social">
                                    <ul>
                                        {/* <li>
                                            <a href="javascript:;" className="fb"><i className="fab fa-facebook-f"></i></a>
                                        </li> */}
                                        <li>
                                            <a href="https://twitter.com/theworkpoduk" target="_blank" className="twt"><i className="fab fa-twitter"></i></a>
                                        </li>
                                        {/* <li>
                                            <a href="javascript:;" className="linkd"><i className="fab fa-linkedin-in"></i></a>
                                        </li> */}
                                        <li>
                                            <a href="https://instagram.com/theworkpoduk" target="_blank" className="insta"><i className="fab fa-instagram"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default withNamespaces()(Footer);