import React, { Component } from 'react'
import api from '../../../src/utils/apiClient';
import { CommonConfig } from "../../utils/constant";
import StickyBox from "react-sticky-box";
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import { apiBase } from '../../../src/utils/config';
import Col from 'react-bootstrap/Col';
import cogoToast from 'cogo-toast';
import Header from '../Header/Header.js'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import tick from "../../assets/img/booking-confirm.svg";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import logo from "../../assets/img/logo-white.svg";
import { withNamespaces } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import SlideToggle from "react-slide-toggle";
import parse from "html-react-parser";
import Loader from '../../../src/utils/general';
import {Modal,Button} from 'react-bootstrap';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

class Checkout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            EntityID: this.props.location.state.data.EntityID,
            AdultCount: this.props.location.state.data.AdultCount,
            day: this.props.location.state.data.day,
            CheckInDate: this.props.location.state.data.CheckInDate,
            CheckOutDate: this.props.location.state.data.CheckOutDate,
            filteredSlotArray: this.props.location.state.data.filteredSlotArray ? this.props.location.state.data.filteredSlotArray : [],
            ArriveTime: this.props.location.state.data.ArriveTime ? this.props.location.state.data.ArriveTime : [],
            DepartTime: this.props.location.state.data.DepartTime ? this.props.location.state.data.DepartTime : [],
            BookedBy: CommonConfig.loginData().PersonID,
            TotalAmount: this.props.location.state.data.TotalAmount,
            propertyDetail: this.props.location.state.data.propertyDetail,
            IsArriveFullDaySelected: this.props.location.state.data.IsArriveFullDaySelected,
            IsDepartFullDaySelected: this.props.location.state.data.IsDepartFullDaySelected,
            Step: 0,
            IsBookWithoutAccount: false,

            FirstName: '',
            FirstNameErr: false,
            FirstNamehelperText: '',

            LastName: '',
            LastNameErr: false,
            LastNamehelperText: '',

            Email: '',
            EmailErr: false,
            EmailhelperText: '',

            PhoneNumber: '',
            PhoneNumberErr: false,
            PhoneNumberhelperText: '',

            BookingNumber: '',
            GuestUserEmail: '',
            showLoginModal: false,
            TotalBookingDays: CommonConfig.getDatesBetweenDates(this.props.location.state.data.CheckInDate, this.props.location.state.data.CheckOutDate),
            IsSameSlotSelected: this.props.location.state.data.IsSameSlotSelected,
            Password: '',
            IsOKToSendOffer: false,
            PasswordErr: false,
            PasswordhelperText: '',
            ConfirmPassword: '',
            ConfirmPasswordErr: false,
            ConfirmPasswordhelperText: '',

            StudentId: '',
            StudentIdErr: false,
            StudentIdhelperText: '',

            CouponCode: '',
            CouponCodeErr: false,
            CouponCodehelperText: '',

            IsStudentDiscountApplied: false,
            IsCouponDiscountApplied: false,
            StudentDiscount: 0,
            CouponDiscount: 0,
            DiscountPercentage: 0,
            KeyID: '',
            ClientID: '',
            Currency: '',
            IsBookSmsSent: false,
            IsPrepaidAccountSelected: false,
            prePaidAccountList: [],
            PrepaidAccountID: '',
            IsSetupPrepaidSelected: false,
            purposeList: [],

            PrepaidAccountName: CommonConfig.loginData().FirstName,
            PrepaidAccountNameErr: false,
            PrepaidAccountNamehelperText: '',

            Purpose: '',
            PurposeErr: false,
            PurposehelperText: '',

            TopUp: '',
            CheckOutDetails: [],
            toggleEvent: 0,
            IsUp: false,
            CurrencyCode: '',
            CurrencySymbol: '',
            loading: false,
            openCancelCheckoutModal: false
        }
        // this.reloadPage();
    }

    componentDidMount = () => {
        this.getPaypalDetails();
        this.getRazorPayDetails();
        this.getPurposeList();
        this.getCheckOutDetails();
    }

    getCheckOutDetails = () => {
        let data;
        if (this.state.day == 'Single Day') {
            data = {
                Date: moment(this.props.location.state.data.CheckInDate).format('YYYY-MM-DD').toString(),
                // TimeSlots : this.state.ArriveTime.map(x=>{ return x.value}).join(),
                TimeSlots: this.state.filteredSlotArray[0].Time.map(x => { return x.value }).join(),
                PropertyID: this.state.propertyDetail.PropertyID,
                Users: this.state.AdultCount,
                Day: this.state.day,
                filteredSlotArray: {
                    filteredSlotArray: this.state.filteredSlotArray.map(x => {
                        return {
                            "Dates": moment(x.Dates).format('YYYY-MM-DD').toString,
                            "Time": x.Time,
                        }
                    })
                }
            }
        } else {
            data = {
                Date: '',
                // TimeSlots : this.state.ArriveTime.map(x=>{ return x.value}).join(),
                TimeSlots: '',
                PropertyID: this.state.propertyDetail.PropertyID,
                Users: this.state.AdultCount,
                Day: this.state.day,
                filteredSlotArray: {
                    filteredSlotArray: this.state.filteredSlotArray.map(x => {
                        return {
                            "Dates": x.Dates,
                            "Time": x.Time,
                        }
                    })
                }
            }
        }

        try {
            api.post("property/getCheckOutDetails", data).then(res => {
                
                if (res.success) {
                    this.setState({ CheckOutDetails: res.data[0], CurrencyCode: res.data[0].length > 0 ? res.data[0][0].CurrencyCode : "", CurrencySymbol: res.data[0].length > 0 ? res.data[0][0].CurrencySymbol : "" })
                }
                else {
                }
            }).catch(err => {
                // // console.log(err)
            })
        } catch (err) {
        }
    }

    getPaypalDetails = () => {
        try {
            api
                .post("utility/getPaypalDetail")
                .then((res) => {
                    if (res.success) {
                        this.setState({ ClientID: res.data[0].ClientID, Currency: res.data[0].Currency });
                        if (CommonConfig.loginData()) {
                            this.getPrepaidAccountDetails()
                            this.setState({ Step: 1 })
                        }
                    } else {
                    }
                })
                .catch((err) => {
                    // console.log(err)
                });
        } catch (err) { }
    };

    getRazorPayDetails = () => {
        try {
            api
                .post("utility/getRazorPayDetail")
                .then((res) => {
                    if (res.success) {
                        this.setState({ KeyID: res.data[0].KeyID });
                        if (CommonConfig.loginData()) {
                            this.getPrepaidAccountDetails()
                            this.setState({ Step: 1 })
                        }
                    } else {
                    }
                })
                .catch((err) => {
                    // console.log(err)
                });
        } catch (err) { }
    };

    getPrepaidAccountDetails = () => {
        let data = {
            PersonID: CommonConfig.loginData().PersonID
        }
        try {
            api
                .post("user/getPrepaidAccountDetail", data)
                .then((res) => {
                    if (res.success) {
                        this.setState({ prePaidAccountList: res.data });
                    } else {
                    }
                })
                .catch((err) => {
                    // console.log(err)
                });
        } catch (err) { }
    };

    getPurposeList = () => {
        let data = {
            StringMapType: "PURPOSE"
        }
        try {
            api.post("utility/getStringMap", data).then(res => {
                if (res.success) {
                    this.setState({ purposeList: res.data });
                }
                else {
                }
            }).catch(err => {
                // // console.log(err)
            })
        } catch (err) {
        }
    }

    updateTopUpbalance = (response, paymentMode) => {
        let data = {
            PrepaidAccountID: '',
            PaymentMode: paymentMode,
            PrepaidAccountName: this.state.PrepaidAccountName,
            Balance: this.state.TopUp,
            Purpose: this.state.Purpose,
            PersonID: CommonConfig.loginData().PersonID,
            PaypalRecordID: response.data[0][0].PayPalRecordID
        }
        try {
            api.post("user/addUpdatePrepaidAccount", data).then(res => {
                if (res.success) {
                    cogoToast.success('Prepaid account created successfully.')
                    this.getPaypalDetails();
                    this.getRazorPayDetails();
                    this.getPurposeList();
                    this.getCheckOutDetails();
                    this.setState({ IsPrepaidAccountSelected: false, IsSetupPrepaidSelected: false, loading: false });
                }
                else {
                    cogoToast.error('Unable to create Prepaid account. Please try again !')
                }
            }).catch(err => {
                // // console.log(err)
            })
        } catch (err) {
        }
    }

    bookpod = (response, details, TotalCost, TimeSlot, PriceSlot, PaymentMethod) => {
        // 
        // var FinalSlots = []
        // TimeSlot.map((x, idx) => {
        //     FinalSlots.push({
        //         SlotDate: moment(x.Date).format('YYYY-MM-DD HH:mm:ss').toString(),
        //         SlotTime: x.Slot,
        //         SlotPrice: PriceSlot[idx].Price
        //     })
        // })

        if (localStorage.getItem('loginData')) {
            let data;
            if (this.state.day == "Single Day") {
                data = {
                    EntityID: this.state.EntityID,
                    AdultCount: this.state.AdultCount,
                    day: this.state.day,
                    CheckInDate: moment(this.state.CheckInDate).format('YYYY-MM-DD HH:mm:ss').toString(),
                    CheckOutDate: moment(this.state.CheckOutDate).format('YYYY-MM-DD HH:mm:ss').toString(),
                    ArriveTime: this.state.ArriveTime,
                    BookedBy: CommonConfig.loginData().PersonID,
                    PaypalRecordID: CommonConfig.isObjectEmpty(response) ? '' : response.data[0][0].PayPalRecordID,
                    IsBookWithoutAccount: this.state.IsBookWithoutAccount ? 1 : 0,
                    Price: TotalCost,
                    PrepaidAccountID: this.state.PrepaidAccountID,
                    PaymentMode: PaymentMethod,
                    PropertyScheduleID: this.props.location.state.data.propertyDetail.PropertyScheduleID,
                    FinalSlots: this.state.CheckOutDetails,
                    CouponCode: this.state.IsCouponDiscountApplied ? this.state.CouponCode : '',
                    DiscountType: this.state.IsStudentDiscountApplied ? "Student" : this.state.IsCouponDiscountApplied ? "Coupon" : '',
                    DiscountPercentage: this.state.IsStudentDiscountApplied ? this.state.propertyDetail.StudentDiscount : this.state.IsCouponDiscountApplied ? this.state.DiscountPercentage : 0,
                    DiscountAmount: this.state.IsStudentDiscountApplied ? this.state.StudentDiscount : this.state.IsCouponDiscountApplied ? this.state.CouponDiscount : 0,
                }
            } else {
                data = {
                    EntityID: this.state.EntityID,
                    AdultCount: this.state.AdultCount,
                    day: this.state.day,
                    CheckInDate: moment(this.state.CheckInDate).format('YYYY-MM-DD HH:mm:ss').toString(),
                    CheckOutDate: moment(this.state.CheckOutDate).format('YYYY-MM-DD HH:mm:ss').toString(),
                    ArriveTime: this.state.ArriveTime,
                    DepartTime: this.state.DepartTime,
                    BookedBy: CommonConfig.loginData().PersonID,
                    PaypalRecordID: CommonConfig.isObjectEmpty(response) ? '' : response.data[0][0].PayPalRecordID,
                    IsBookWithoutAccount: this.state.IsBookWithoutAccount ? 1 : 0,
                    Price: TotalCost,
                    PaymentMode: PaymentMethod,
                    PrepaidAccountID: this.state.PrepaidAccountID,
                    PropertyScheduleID: this.props.location.state.data.propertyDetail.PropertyScheduleID,
                    FinalSlots: this.state.CheckOutDetails,
                    CouponCode: this.state.IsCouponDiscountApplied ? this.state.CouponCode : '',
                    DiscountType: this.state.IsStudentDiscountApplied ? "Student" : this.state.IsCouponDiscountApplied ? "Coupon" : '',
                    DiscountPercentage: this.state.IsStudentDiscountApplied ? this.state.propertyDetail.StudentDiscount : this.state.IsCouponDiscountApplied ? this.state.DiscountPercentage : 0,
                    DiscountAmount: this.state.IsStudentDiscountApplied ? this.state.StudentDiscount : this.state.IsCouponDiscountApplied ? this.state.CouponDiscount : 0,
                }
            }
            try {
                api.post("property/bookingPod", data).then(res => {
                    if (res.success) {
                        window.scrollTo(0, 0)
                        // this.setState({ dayTypeList: res.data });
                        // alert('Booking has been done successfully...!!!');
                        // cogoToast.success("Booking has been done successfully...!!!");
                        this.setState({ Step: 2, BookingNumber: res.data.BookingNumber, GuestUserEmail: res.data.GuestUserEmail, loading: false })
                        // this.sendBookingMail(res);
                        // this.sendMailtoGuest(details, res);
                        // this.sendMailtoHost(details);
                        // this.props.history.push("/admin/dashboard");
                    }
                    else {
                    }
                }).catch(err => {
                    // console.log(err)
                })
            } catch (err) {
            }




        } else if (this.state.IsBookWithoutAccount) {
            let data;
            if (this.state.day == "Single Day") {
                data = {
                    EntityID: this.state.EntityID,
                    AdultCount: this.state.AdultCount,
                    day: this.state.day,
                    CheckInDate: moment(this.state.CheckInDate).format('YYYY-MM-DD HH:mm:ss').toString(),
                    CheckOutDate: moment(this.state.CheckOutDate).format('YYYY-MM-DD HH:mm:ss').toString(),
                    ArriveTime: this.state.ArriveTime,
                    BookedBy: '',
                    FirstName: this.state.FirstName,
                    LastName: this.state.LastName,
                    Email: this.state.Email,
                    PhoneNumber: this.state.PhoneNumber,
                    PaypalRecordID: response.data[0][0].PayPalRecordID,
                    IsBookWithoutAccount: this.state.IsBookWithoutAccount ? 1 : 0,
                    Price: TotalCost,
                    PaymentMode: PaymentMethod,
                    PropertyScheduleID: this.props.location.state.data.propertyDetail.PropertyScheduleID,
                    FinalSlots: this.state.CheckOutDetails,
                    CouponCode: this.state.IsCouponDiscountApplied ? this.state.CouponCode : '',
                    DiscountType: this.state.IsStudentDiscountApplied ? "Student" : this.state.IsCouponDiscountApplied ? "Coupon" : '',
                    DiscountPercentage: this.state.IsStudentDiscountApplied ? this.state.propertyDetail.StudentDiscount : this.state.IsCouponDiscountApplied ? this.state.DiscountPercentage : 0,
                    DiscountAmount: this.state.IsStudentDiscountApplied ? this.state.StudentDiscount : this.state.IsCouponDiscountApplied ? this.state.CouponDiscount : 0,
                }
            } else {
                data = {
                    EntityID: this.state.EntityID,
                    AdultCount: this.state.AdultCount,
                    day: this.state.day,
                    CheckInDate: moment(this.state.CheckInDate).format('YYYY-MM-DD HH:mm:ss').toString(),
                    CheckOutDate: moment(this.state.CheckOutDate).format('YYYY-MM-DD HH:mm:ss').toString(),
                    ArriveTime: this.state.ArriveTime,
                    DepartTime: this.state.DepartTime,
                    BookedBy: '',
                    FirstName: this.state.FirstName,
                    LastName: this.state.LastName,
                    Email: this.state.Email,
                    PhoneNumber: this.state.PhoneNumber,
                    PaypalRecordID: response.data[0][0].PayPalRecordID,
                    IsBookWithoutAccount: this.state.IsBookWithoutAccount ? 1 : 0,
                    Price: TotalCost,
                    PaymentMode: PaymentMethod,
                    PropertyScheduleID: this.props.location.state.data.propertyDetail.PropertyScheduleID,
                    FinalSlots: this.state.CheckOutDetails,
                    CouponCode: this.state.IsCouponDiscountApplied ? this.state.CouponCode : '',
                    DiscountType: this.state.IsStudentDiscountApplied ? "Student" : this.state.IsCouponDiscountApplied ? "Coupon" : '',
                    DiscountPercentage: this.state.IsStudentDiscountApplied ? this.state.propertyDetail.StudentDiscount : this.state.IsCouponDiscountApplied ? this.state.DiscountPercentage : 0,
                    DiscountAmount: this.state.IsStudentDiscountApplied ? this.state.StudentDiscount : this.state.IsCouponDiscountApplied ? this.state.CouponDiscount : 0,
                }
            }
            try {
                api.post("property/bookingPod", data).then(res => {
                    if (res.success) {
                        window.scrollTo(0, 0)
                        // this.setState({ dayTypeList: res.data });
                        // alert('Booking has been done successfully...!!!');
                        // cogoToast.success("Booking has been done successfully...!!!");
                        this.setState({ Step: 2, BookingNumber: res.data.BookingNumber, GuestUserEmail: res.data.GuestUserEmail, loading: false })
                        // this.sendBookingMail(res);
                        // this.sendMailtoGuest(details, res);
                        // this.sendMailtoHost(details);
                        // this.props.history.push("/admin/dashboard");
                    }
                    else {
                    }
                }).catch(err => {
                    // console.log(err)
                })
            } catch (err) {
            }
        }


    }

    SetNotificationAdmin = (details) => {
        try {
            let NotificationData = {
                NotificationCategory: 'Booking Confirmation',
                NotificationType: 'Notification',
                EntityType: 'Property',
                EntityId: this.state.EntityID,
                UserFrom: 'System',
                UserTo: 'Admin',
                GroupTo: null,
                NotificationText: details !== "prepaid" ? 'A property named ' + this.state.propertyDetail.PropertyName + ' is booked by ' + details.purchase_units[0].shipping.name.full_name : CommonConfig.loginData().PersonID ? 'A property named ' + this.state.propertyDetail.PropertyName + ' is booked by ' + CommonConfig.loginData().FirstName : '',
                IsImportant: 1
            }
            api.post("user/AddNotification", NotificationData).then(res => {
                if (res.success) {
                }
                else {
                }
            }).catch(err => {
                // console.log(err)
            })
        }
        catch (err) {
        }
    }

    SetNotificationHost = (details) => {
        // notification Generated for Property Host account Start

        try {
            let NotificationData = {
                NotificationCategory: 'Booking Confirmation',
                NotificationType: 'Notification',
                EntityType: 'Property',
                EntityId: this.state.EntityID,
                UserFrom: 'System',
                UserTo: this.state.propertyDetail.PersonID,
                GroupTo: null,
                NotificationText: details !== "prepaid" ? 'your Property is booked by ' + details.purchase_units[0].shipping.name.full_name : CommonConfig.loginData().PersonID ? 'your Property is booked by ' + CommonConfig.loginData().FirstName : '',
                IsImportant: 1
            }
            api.post("user/AddNotification", NotificationData).then(res => {
                if (res.success) {
                }
                else {
                }
            }).catch(err => {
                // console.log(err)
            })
        }
        catch (err) {
        }
        // notification Generated for Property Host account End

    }

    SetNotificationGuest = () => {
        // notification Generated for Property Host account Start

        try {
            let NotificationData = {
                NotificationCategory: 'Booking Confirmation',
                NotificationType: 'Notification',
                EntityType: 'Property',
                EntityId: this.state.EntityID,
                UserFrom: 'System',
                UserTo: CommonConfig.loginData().PersonID,
                GroupTo: null,
                NotificationText: 'you have booked property ' + this.state.propertyDetail.PropertyName,
                IsImportant: 1
            }
            api.post("user/AddNotification", NotificationData).then(res => {
                if (res.success) {
                }
                else {
                }
            }).catch(err => {
                // console.log(err)
            })
        }
        catch (err) {
        }
        // notification Generated for Property Host account End

    }

    sendBookingMail = (res) => {
        let data = {
            TransactionID: res.data.TransactionID
        }
        try {
            api.post("property/sendBookingMail", data).then(res => {
                
                if (res.success) {
                }
                else {
                }
            }).catch(err => {
                // console.log(err)
            })
        }
        catch (err) {
        }
    }

    sendMailtoGuest = (details, res) => {
        // Email send to Guest who is booking
        try {
            let BookingDetails = {
                Name: details ? details.purchase_units[0].shipping.name.full_name : CommonConfig.loginData().FirstName,
                PropertyName: this.state.propertyDetail.PropertyName,
                CheckInDate: this.state.CheckInDate,
                CheckOutDate: this.state.CheckOutDate,
                UserEmailId: res.data.GuestUserEmail
            }
            api.post("property/EmailBookingDetailsGuest", BookingDetails).then(res => {
                
                if (res.success) {
                }
                else {
                }
            }).catch(err => {
                // console.log(err)
            })
        }
        catch (err) {
        }
        // Email send to Guest who is booking End
    }

    sendMailtoHost = (details) => {

        
        // Email send to Host whose property is booked start(after completion of this process will insert entry to table)
        try {
            let BookingDetails = {
                Name: details ? details.purchase_units[0].shipping.name.full_name : CommonConfig.loginData().FirstName,
                PropertyName: this.state.propertyDetail.PropertyName,
                CheckInDate: this.state.CheckInDate,
                CheckOutDate: this.state.CheckOutDate,
                UserEmailId: this.state.propertyDetail.HostEmailId,
                HostName: this.state.propertyDetail.HostName
            }
            
            api.post("property/EmailBookingDetailsHost", BookingDetails).then(res => {
                if (res.success) {
                }
                else {
                }
            }).catch(err => {
                // console.log(err)
            })
        }
        catch (err) {
        }
        // Email send to Host whose property is booked End

    }

    reloadPage = () => {
        // The last "domLoading" Time //
        var currentDocumentTimestamp =
            new Date(performance.timing.domLoading).getTime();
        // Current Time //
        var now = Date.now();
        // Ten Seconds //
        var Sec = 1000;
        // Plus Ten Seconds //
        var plusTenSec = currentDocumentTimestamp + Sec;
        if (now > plusTenSec) {
            window.location.reload();
        } else { }
    }

    handleChange = (e, type) => {
        if (type == "FirstName") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ FirstName: e.target.value, FirstNameErr: true, FirstNamehelperText: 'Please enter first name.' })
            } else {
                this.setState({ FirstName: e.target.value, FirstNameErr: false, FirstNamehelperText: '' })
            }
        }
        if (type == "LastName") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ LastName: e.target.value, LastNameErr: true, LastNamehelperText: 'Please enter last name.' })
            } else {
                this.setState({ LastName: e.target.value, LastNameErr: false, LastNamehelperText: '' })
            }
        }
        if (type == "Email") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ Email: e.target.value, EmailErr: true, EmailhelperText: 'Please enter email.' })
            } else if (!e.target.value.match(CommonConfig.RegExp.email)) {
                this.setState({ Email: e.target.value, EmailErr: true, EmailhelperText: 'Please enter valid email.' })
            } else {
                this.setState({ Email: e.target.value, EmailErr: false, EmailhelperText: '' })
            }
        }
        if (type == "Password") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ Password: e.target.value, PasswordErr: true, PasswordhelperText: 'Please enter password.' })
            } else {
                this.setState({ Password: e.target.value, PasswordErr: false, PasswordhelperText: '' })
            }
        }
        if (type == "ConfirmPassword") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ ConfirmPassword: e.target.value, ConfirmPasswordErr: true, ConfirmPasswordhelperText: 'Please enter confirm password.' })
            } else if (e.target.value !== this.state.Password) {
                this.setState({ ConfirmPassword: e.target.value, ConfirmPasswordErr: true, ConfirmPasswordhelperText: 'Password and confirm password must be same.' })
            } else {
                this.setState({ ConfirmPassword: e.target.value, ConfirmPasswordErr: false, ConfirmPasswordhelperText: '' })
            }
        }
        if (type == "IsOKToSendOffer") {
            this.setState({ IsOKToSendOffer: e.target.checked })
        }
        if (type == "StudentId") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ StudentId: e.target.value, StudentIdErr: true, StudentIdhelperText: 'Please enter student id.' })
            } else if (!e.target.value.match(CommonConfig.RegExp.number)) {
                this.setState({ StudentId: this.state.StudentId })
            }
            else if (e.target.value.length > 8) {
                this.setState({ StudentId: this.state.StudentId })
            }
            else {
                this.setState({ StudentId: e.target.value, StudentIdErr: false, StudentIdhelperText: '' })
            }
        }
        if (type == "CouponCode") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ CouponCode: e.target.value, CouponCodeErr: true, CouponCodehelperText: 'Please enter coupon code.' })
            } else if (e.target.value.length > 8) {
                this.setState({ CouponCode: this.state.CouponCode })
            } else {
                this.setState({ CouponCode: e.target.value, CouponCodeErr: false, CouponCodehelperText: '' })
            }
        }
        if (type == "PrepaidAccount") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ PrepaidAccountID: e.target.value, PrepaidAccountIDErr: true, PrepaidAccountIDhelperText: 'Please select an account' })
            } else {
                this.setState({ PrepaidAccountID: e.target.value, PrepaidAccountIDErr: false, PrepaidAccountIDhelperText: '' })
            }
        }

        if (type == "PrepaidAccountName") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ PrepaidAccountName: e.target.value, PrepaidAccountNameErr: true, PrepaidAccountNamehelperText: 'Please enter prepaid account name' })
            } else {
                this.setState({ PrepaidAccountName: e.target.value, PrepaidAccountNameErr: false, PrepaidAccountNamehelperText: '' })
            }
        }

        if (type == "Purpose") {
            if (CommonConfig.isEmpty(e.target.value)) {
                this.setState({ Purpose: e.target.value, PurposeErr: true, PurposehelperText: 'Please select purpose' })
            } else {
                this.setState({ Purpose: e.target.value, PurposeErr: false, PurposehelperText: '' })
            }
        }

        if (type == "TopUp") {
            if (CommonConfig.isEmpty(e)) {
                this.setState({ TopUp: e, TopUpErr: true, TopUphelperText: 'Please enter amount' })
            } else {
                this.setState({ TopUp: e, TopUpErr: false, TopUphelperText: '' })
            }
        }
        if (type === "PhoneNumber") {
            if (CommonConfig.isEmpty(e)) {
                this.setState({ PhoneNumber: e, PhoneNumberErr: true, PhoneNumberhelperText: 'Please enter phone number.' })
            } else {
                this.setState({ PhoneNumber: e, PhoneNumberErr: false, PhoneNumberhelperText: '' })
            }
        }
    }

    validate = (event) => {
        event.preventDefault();
        const { FirstName, LastName, Email, PhoneNumber } = this.state;
        let IsValid = true;

        if (CommonConfig.isEmpty(FirstName)) {
            IsValid = false;
            this.setState({ FirstNameErr: true, FirstNamehelperText: 'Please enter first name.' })
        }
        else {
            this.setState({ FirstNameErr: false, FirstNamehelperText: '' })
        }
        if (CommonConfig.isEmpty(LastName)) {
            IsValid = false;
            this.setState({ LastNameErr: true, LastNamehelperText: 'Please enter last name.' })
        }
        else {
            this.setState({ LastNameErr: false, LastNamehelperText: '' })
        }
        if (CommonConfig.isEmpty(Email)) {
            IsValid = false;
            this.setState({ EmailErr: true, EmailhelperText: 'Please enter email.' })
        }
        else if (!Email.match(CommonConfig.RegExp.email)) {
            IsValid = false;
            this.setState({ EmailErr: true, EmailhelperText: 'Please enter valid email.' })
        }
        else {
            this.setState({ EmailErr: false, EmailhelperText: '' })
        }
        if (CommonConfig.isEmpty(PhoneNumber)) {
            IsValid = false;
            this.setState({ PhoneNumberErr: true, PhoneNumberhelperText: 'Please enter phone number.' })
        }
        else {
            this.setState({ PhoneNumberErr: false, PhoneNumberhelperText: '' })
        }
        return IsValid;
    }

    BookWithoutAccount = (event) => {
        event.preventDefault();
        if (this.validate(event)) {
            this.setState({ Step: 1 })
        }
    }

    validateRegister = (event) => {
        event.preventDefault();
        const { FirstName, LastName, Email, Password, ConfirmPassword } = this.state;
        let IsValid = true;

        if (CommonConfig.isEmpty(FirstName)) {
            IsValid = false;
            this.setState({ FirstNameErr: true, FirstNamehelperText: 'Please enter first name.' })
        }
        else {
            this.setState({ FirstNameErr: false, FirstNamehelperText: '' })
        }
        if (CommonConfig.isEmpty(LastName)) {
            IsValid = false;
            this.setState({ LastNameErr: true, LastNamehelperText: 'Please enter last name.' })
        }
        else {
            this.setState({ LastNameErr: false, LastNamehelperText: '' })
        }
        if (CommonConfig.isEmpty(Email)) {
            IsValid = false;
            this.setState({ EmailErr: true, emailhelperText: 'Please enter email.' })
        }
        else if (!Email.match(CommonConfig.RegExp.email)) {
            IsValid = false;
            this.setState({ EmailErr: true, emailhelperText: 'Please enter valid email.' })
        }
        else {
            this.setState({ EmailErr: false, emailhelperText: '' })
        }
        if (CommonConfig.isEmpty(Password)) {
            IsValid = false;
            this.setState({ PasswordErr: true, PasswordhelperText: 'Please enter password.' })
        }
        else {
            this.setState({ PasswordeErr: false, PasswordehelperText: '' })
        }
        if (CommonConfig.isEmpty(ConfirmPassword)) {
            IsValid = false;
            this.setState({ ConfirmPasswordErr: true, ConfirmPasswordhelperText: 'Please enter confirm password' })
        }
        else if (ConfirmPassword !== Password) {
            IsValid = false;
            this.setState({ ConfirmPasswordErr: true, ConfirmPasswordhelperText: 'Password and confirm password must be same' })
        }
        else {
            this.setState({ ConfirmPasswordErr: false, ConfirmPasswordhelperText: '' })
        }
        return IsValid;
    }

    handleRegister = (event) => {
        event.preventDefault();
        if (this.validateRegister(event)) {
            let data = {
                FirstName: this.state.FirstName,
                LastName: this.state.LastName,
                DOB: this.state.DOB,
                Email: this.state.Email,
                Password: this.state.Password,
                IsOKToSendOffer: this.state.IsOKToSendOffer ? 0 : 1,
                EntityType: "User",
                EmailType: "Personal",
                PhoneType: "Personal",
                Type: "Insert",
                RegisterType: "email"
            }
            try {
                api.post("user/addoreditordeleteorinactiveuser", data).then(res => {
                    if (!res.data[0].IsAlreadyExist && res.success) {
                        cogoToast.success('You have been registered successfully ! Verification Link sent to your registered email address.')
                        this.setState({
                            LoggedInUsername: CommonConfig.loginData().PersonID ? CommonConfig.loginData().FirstName : '',
                            PersonType: '',
                            Username: '',
                            UsernameErr: false,
                            UsernamehelperText: '',
                            PasswordErr: false,
                            PasswordhelperText: '',
                            PhoneNumber: '',
                            FirstName: '',
                            LastName: '',
                            DOB: '',
                            Email: '',
                            EmailErr: false,
                            emailhelperText: '',
                            Password: '',
                            IsOKToSendOffer: false,
                            IsRegistered: false,
                            IsRegisteredWithEmail: false,
                            IsAgreed: false,
                            Step1: false,
                            Step2: false,
                            Step3: false,
                            Step4: false,
                            IsDecline: false,
                            Password: '',
                            forgotPasswordValidated: false,
                            LoginValidated: false,
                            RegisterValidated: false,
                            Password: '',
                            showLoginModal: false,
                            showRegisterModal: false,
                            showHostRegisterModal: false,
                            showForgotPasswordModal: false,
                            notificationList: [],
                            notificationCount: Number,
                            notificationView: false,
                            selectAllIconClass: true,
                            showToggle: false,

                        })
                        localStorage.setItem('loginData', JSON.stringify(res.data[0]));
                        localStorage.setItem('isAuthorized', res.success);
                        this.setState({ Step: 1 })
                    }
                    else {
                        this.setState({ IsRegistered: false })
                        cogoToast.error('User already exist.')
                    }
                }).catch(err => {
                    // // console.log(err)
                })
            } catch (err) {
            }
        }
    }

    verifyCoupon = (e, TotalCost) => {
        e.preventDefault();

        if (CommonConfig.isEmpty(this.state.CouponCode)) {
            this.setState({ CouponCodeErr: true, CouponCodehelperText: 'Coupon code is required.' })
        }
        else {
            this.setState({ CouponCodeErr: false, CouponCodehelperText: '' })
            let data = {
                CouponCode: this.state.CouponCode,
            }
            try {
                api.post("utility/verifyCouponCode", data).then(res => {
                    
                    if (res.success) {
                        this.setState({ CouponDiscount: parseFloat(Number(TotalCost) * Number(res.data.DiscountPercentage) / 100).toFixed(2), IsCouponDiscountApplied: true, DiscountPercentage: res.data.DiscountPercentage })
                    }
                    else {
                        this.setState({ CouponCodeErr: true, CouponCodehelperText: 'Coupon code is not valid or expired.' })
                    }
                }).catch(err => {
                    // console.log(err)
                })
            } catch (err) {
            }
        }
    }

    handleTogglePassword = (type) => {
        if (type === "Password") {
            this.setState(state => ({ showPassword: !state.showPassword }));
        } else {
            this.setState(state => ({ showConfirmPassword: !state.showConfirmPassword }));
        }
    };

    sendBookSMS = (e) => {
        
        // console.log("dsdsd", CommonConfig.loginData().PhoneNumber)
        if (CommonConfig.loginData().PersonID && !CommonConfig.loginData().PhoneNumber) {
            cogoToast.error(`Unable to find your Phone number . May be you haven't signed up using phone number.`)
        } else {
            let data = {
                PhoneNumber: CommonConfig.loginData().PersonID && CommonConfig.loginData().PhoneNumber ? CommonConfig.loginData().PhoneNumber : this.state.PhoneNumber,
                BookingNumber: this.state.BookingNumber,
                CheckInDate: this.state.CheckInDate,
                PropertyName: this.state.propertyDetail.PropertyName,
                PropertyAddress: this.state.propertyDetail.PropertyAddress
            }

            try {
                api.post("utility/sendBookingSMS", data).then(res => {
                    // console.log("data", data)
                    if (res.success) {
                        this.setState({ IsBookSmsSent: true })
                    }
                    else {
                    }
                }).catch(err => {
                    // // console.log(err)
                })
            } catch (err) {
            }
        }
    }

    async RazorPaySetupPrepaid(Amount) {
        var data = {
            Amount: Amount,
            Currency: this.state.CurrencyCode
        }
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        // creating a new order
        const result = await api.post("utility/razorPayCheckOut", data)


        if (!result) {
            alert("Server error. Are you online?");
            return;
        }

        // Getting the order details back
        const { amount, id: order_id, currency } = result.data;

        const options = {
            key: this.state.KeyID, // Enter the Key ID generated from the Dashboard
            amount: amount.toString(),
            currency: currency,
            name: "The WorkPod",
            description: "Test Transaction",
            image: logo,
            order_id: order_id,
            handler: async (response) => {
                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                    PurchaseAmount: amount,
                    currency: currency
                };

                const result = await api.post("property/RazorPayTxnSave", data);
                if (result.success) {
                    this.setState({ loading: true })
                    this.updateTopUpbalance(result, 'RazorPay');
                }
                // alert(result.data.msg);
            },
            prefill: {
                name: "The WorkPod",
                email: "info@theworkpod.co.uk",
                contact: "0208 427 8787",
            },
            notes: {
                address: "85 Great Portland Street First Floor, London, United Kingdom, W1W 7LT",
            },
            theme: {
                // color: "#61dafb",
                color: "#1da1f2"
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    async displayRazorpay(Amount) {

        var data = {
            Amount: Amount,
            Currency: this.state.CurrencyCode
        }
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        // creating a new order
        const result = await api.post("utility/razorPayCheckOut", data)


        if (!result) {
            alert("Server error. Are you online?");
            return;
        }

        // Getting the order details back
        const { amount, id: order_id, currency } = result.data;

        const options = {
            key: this.state.KeyID, // Enter the Key ID generated from the Dashboard
            amount: amount.toString(),
            currency: currency,
            name: "The WorkPod",
            description: "Test Transaction",
            image: logo,
            order_id: order_id,
            handler: async (response) => {
                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                    PurchaseAmount: amount,
                    currency: currency
                };

                const result = await api.post("property/RazorPayTxnSave", data);
                if (result.success) {
                    this.setState({ loading: true })
                    this.SetNotificationHost("prepaid");
                    this.SetNotificationGuest();
                    this.SetNotificationAdmin("prepaid");
                    this.bookpod(result, {}, Amount, [], [], "RazorPay");
                }
                // alert(result.data.msg);
            },
            prefill: {
                name: "The WorkPod",
                email: "info@theworkpod.co.uk",
                contact: "0208 427 8787",
            },
            notes: {
                address: "85 Great Portland Street First Floor, London, United Kingdom, W1W 7LT",
            },
            theme: {
                // color: "#61dafb",
                color: "#1da1f2"
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    render() {
        const { t } = this.props;
        const { FirstName, LastName, Email, PhoneNumber, loading , openCancelCheckoutModal } = this.state;
        const { ...rest } = this.props;
        // // console.log("chec",this.state.CheckOutDetails)

        // window.paypal.Buttons({
        //     createOrder: (data, actions) => {
        //         return actions.order.create({
        //             purchase_units: [{
        //                 amount: {
        //                     value: this.state.TotalAmount
        //                 }
        //             }]
        //         });
        //     },
        //     onApprove: (data, actions) => {

        //         return actions.order.capture().then((details) => {
        //             const resData = {
        //                 OrderId: details.id,
        //                 Status: details.status,
        //                 intent: details.intent,
        //                 ApiLink: details.links[0].href,
        //                 APIMethod: details.links[0].method,
        //                 PayerId: details.payer.payer_id,
        //                 PayerCountryCode: details.payer.address.country_code,
        //                 PayerEmailId: details.payer.email_address,
        //                 PayerName: details.purchase_units[0].shipping.name.full_name,
        //                 PurchaseAmount: details.purchase_units[0].amount.value,
        //                 CurrencyCode: details.purchase_units[0].amount.currency_code,
        //                 CreatedOn: details.create_time
        //             }
        //             try {
        //                 api.post("property/PayPalTxnSave", resData).then(res => {
        //                 }).catch(err => {
        //                     // console.log(err)
        //                 })
        //             }
        //             catch (err) {
        //                 // console.log(err)
        //             }

        //             this.SetNotificationHost(details);
        //             this.SetNotificationGuest();
        //             this.sendMailtoGuest(details);
        //             this.sendMailtoHost(details);
        //             this.bookpod();
        //         });
        //     }
        // }).render('#paypal-button-container');

        const PriceData = [];
        var TmSlot = this.state.propertyDetail.TimeSlot.split(',');
        var TimeSlotPrice = this.state.propertyDetail.TimeSlotPrice.split(",")
        var ArriveT = []
        var DepartT = []
        this.state.ArriveTime.map(x => { ArriveT.push(x.label) })
        this.state.DepartTime.map(x => { DepartT.push(x.label) })
        var early = this.state.ArriveTime.filter(x => x.label == "09:00 - 13:00").length
        var noon = !ArriveT.includes("09:00 - 13:00") && ArriveT.includes("13:00 - 17:00") == true ? 1 : 0
        var evening = !ArriveT.includes("09:00 - 13:00") && !ArriveT.includes("13:00 - 17:00") && ArriveT.includes("17:00 - 21:00") == true ? 1 : 0

        var earlydepart = DepartT.includes("09:00 - 13:00") && !DepartT.includes("13:00 - 17:00") && !DepartT.includes("17:00 - 21:00") == true ? 1 : 0
        var noondepart = !DepartT.includes("17:00 - 21:00") && DepartT.includes("13:00 - 17:00") == true ? 1 : 0
        var eveningdepart = this.state.DepartTime.filter(x => x.label == "17:00 - 21:00").length

        if (this.state.day == "Multi Day" && !this.state.IsSameSlotSelected) {
            if (this.state.IsArriveFullDaySelected) {
                this.state.ArriveTime.map((x) => {
                    var Index = TmSlot.findIndex(item => item === x.label)
                    PriceData.push({ "Pdata": TimeSlotPrice[Index] })
                })
                this.state.ArriveTime.map((x) => {
                    var Index = TmSlot.findIndex(item => item === x.label)
                    this.state.TotalBookingDays.map(i => {
                        PriceData.push({ "Pdata": TimeSlotPrice[Index] })
                    })
                })
            } else {
                if (early > 0) {
                    TmSlot.filter(i => i !== "Full Day").map((x, idx) => {
                        var Index = TmSlot.findIndex(item => item === x)
                        PriceData.push({ "Pdata": TimeSlotPrice[Index] })
                    })
                }
                if (noon > 0) {
                    TmSlot.filter(i => i !== "09:00 - 13:00" && i !== "Full Day").map((x, idx) => {
                        var Index = TmSlot.findIndex(item => item === x)
                        PriceData.push({ "Pdata": TimeSlotPrice[Index] })
                    })
                }
                if (evening > 0) {
                    TmSlot.filter(i => i !== "09:00 - 13:00" && i !== "13:00 - 17:00" && i !== "Full Day").map((x, idx) => {
                        var Index = TmSlot.findIndex(item => item === x)
                        PriceData.push({ "Pdata": TimeSlotPrice[Index] })
                    })
                }
                this.state.TotalBookingDays.map(i => {
                    TmSlot.filter(i => i !== "Full Day").map((x, idx) => {
                        PriceData.push({ "Pdata": TimeSlotPrice[idx] })
                    })
                })
            }
        } else if (this.state.day == "Multi Day" && this.state.IsSameSlotSelected) {

            this.state.ArriveTime.map((x) => {
                var Index = TmSlot.findIndex(item => item === x.label)
                PriceData.push({ "Pdata": TimeSlotPrice[Index] })
            })
            this.state.ArriveTime.map((x) => {
                var Index = TmSlot.findIndex(item => item === x.label)
                this.state.TotalBookingDays.map(i => {
                    PriceData.push({ "Pdata": TimeSlotPrice[Index] })
                })
            })
        } else {
            this.state.ArriveTime.map((x) => {
                var Index = TmSlot.findIndex(item => item === x.label)
                PriceData.push({ "Pdata": TimeSlotPrice[Index] })
            })
        }


        if (this.state.day == "Multi Day" && !this.state.IsSameSlotSelected) {
            if (this.state.IsDepartFullDaySelected) {
                var Index = TmSlot.findIndex(item => item === "Full Day")
                PriceData.push({ "Pdata": TimeSlotPrice[Index] })
            } else {
                if (eveningdepart > 0) {
                    TmSlot.filter(i => i !== "Full Day").map((x, idx) => {
                        var Index = TmSlot.findIndex(item => item === x)
                        PriceData.push({ "Pdata": TimeSlotPrice[Index] })
                    })
                }
                if (noondepart > 0) {
                    TmSlot.filter(i => i !== "17:00 - 21:00" && i !== "Full Day").map((x, idx) => {
                        var Index = TmSlot.findIndex(item => item === x)
                        PriceData.push({ "Pdata": TimeSlotPrice[Index] })
                    })
                }
                if (earlydepart > 0) {
                    TmSlot.filter(i => i !== "17:00 - 21:00" && i !== "13:00 - 17:00" && i !== "Full Day").map((x, idx) => {
                        var Index = TmSlot.findIndex(item => item === x)
                        PriceData.push({ "Pdata": TimeSlotPrice[Index] })
                    })
                }
            }
        } else if (this.state.day == "Multi Day" && this.state.IsSameSlotSelected) {
            this.state.ArriveTime.map((x) => {
                var Index = TmSlot.findIndex(item => item === x.label)
                PriceData.push({ "Pdata": TimeSlotPrice[Index] })
            })
        }

        var PriceLst = []

        if (this.state.day == "Multi Day" && !this.state.IsSameSlotSelected) {
            if (this.state.IsArriveFullDaySelected) {
                this.state.ArriveTime.map((x) => {
                    var Index = TmSlot.findIndex(item => item === x.label)
                    PriceLst.push({ "Date": this.state.CheckInDate, "Price": TimeSlotPrice[Index] })
                })
                this.state.ArriveTime.map((x) => {
                    var Index = TmSlot.findIndex(item => item === x.label)
                    this.state.TotalBookingDays.map(i => {
                        PriceLst.push({ "Date": i, "Price": TimeSlotPrice[Index] })
                    })
                })
            } else {
                if (early > 0) {
                    TmSlot.filter(i => i !== "Full Day").map((x, idx) => {
                        var Index = TmSlot.findIndex(item => item === x)
                        PriceLst.push({ "Date": this.state.CheckInDate, "Price": TimeSlotPrice[Index] })
                    })
                }
                if (noon > 0) {
                    TmSlot.filter(i => i !== "09:00 - 13:00" && i !== "Full Day").map((x, idx) => {
                        var Index = TmSlot.findIndex(item => item === x)
                        PriceLst.push({ "Date": this.state.CheckInDate, "Price": TimeSlotPrice[Index] })
                    })
                }
                if (evening > 0) {
                    TmSlot.filter(i => i !== "09:00 - 13:00" && i !== "13:00 - 17:00" && i !== "Full Day").map((x, idx) => {
                        var Index = TmSlot.findIndex(item => item === x)
                        PriceLst.push({ "Date": this.state.CheckInDate, "Price": TimeSlotPrice[Index] })
                    })
                }
                this.state.TotalBookingDays.map(i => {

                    TmSlot.filter(i => i !== "Full Day").map((x, idx) => {
                        var Index = TmSlot.findIndex(item => item === x)
                        PriceLst.push({ "Date": i, "Price": TimeSlotPrice[Index] })
                    })
                })
            }
        } else if (this.state.day == "Multi Day" && this.state.IsSameSlotSelected) {
            this.state.ArriveTime.map((x) => {
                var Index = TmSlot.findIndex(item => item === x.label)
                PriceLst.push({ "Date": this.state.CheckInDate, "Price": TimeSlotPrice[Index] })
            })
            this.state.ArriveTime.map((x) => {
                var Index = TmSlot.findIndex(item => item === x.label)
                this.state.TotalBookingDays.map(i => {
                    PriceLst.push({ "Date": i, "Price": TimeSlotPrice[Index] })
                })
            })
        } else {
            this.state.ArriveTime.map((x) => {
                var Index = TmSlot.findIndex(item => item === x.label)
                PriceLst.push({ "Date": this.state.singleDate, "Price": TimeSlotPrice[Index] })
            })
        }

        if (this.state.day == "Multi Day" && !this.state.IsSameSlotSelected) {
            if (this.state.IsDepartFullDaySelected) {
                var Index = TmSlot.findIndex(item => item === "Full Day")
                PriceLst.push({ "Date": this.state.CheckOutDate, "Price": TimeSlotPrice[Index] })
            } else {
                if (eveningdepart > 0) {
                    TmSlot.filter(i => i !== "Full Day").map((x, idx) => {
                        var Index = TmSlot.findIndex(item => item === x)
                        PriceLst.push({ "Date": this.state.CheckOutDate, "Price": TimeSlotPrice[Index] })
                    })
                }
                if (noondepart > 0) {
                    TmSlot.filter(i => i !== "17:00 - 21:00" && i !== "Full Day").map((x, idx) => {
                        var Index = TmSlot.findIndex(item => item === x)
                        PriceLst.push({ "Date": this.state.CheckOutDate, "Price": TimeSlotPrice[Index] })
                    })
                }
                if (earlydepart > 0) {
                    TmSlot.filter(i => i !== "17:00 - 21:00" && i !== "13:00 - 17:00" && i !== "Full Day").map((x, idx) => {
                        var Index = TmSlot.findIndex(item => item === x)
                        PriceLst.push({ "Date": this.state.CheckOutDate, "Price": TimeSlotPrice[Index] })
                    })
                }
            }
        } else if (this.state.day == "Multi Day" && this.state.IsSameSlotSelected) {
            PriceLst = PriceLst.concat(this.state.ArriveTime.map((x) => {
                var Index = TmSlot.findIndex(item => item === x.label)
                // return this.state.TotalBookingDays.map(i => {
                return { "Date": this.state.CheckOutDate, "Price": TimeSlotPrice[Index] }
                // })
            }))
        }

        // PriceLst = PriceLst.sort(function (a, b) {
        //     // Turn your strings into dates, and then subtract them
        //     // to get a value that is either negative, positive, or zero.
        //     return new Date(a.Date) - new Date(b.Date);
        // });

        var TmSlotLst = []

        if (this.state.day == "Multi Day" && !this.state.IsSameSlotSelected) {
            if (this.state.IsArriveFullDaySelected) {
                this.state.ArriveTime.map((x) => {
                    var Index = TmSlot.findIndex(item => item === x.label)
                    TmSlotLst.push({ "Date": this.state.CheckInDate, "Slot": TmSlot[Index] })
                })
                this.state.ArriveTime.map((x) => {
                    var Index = TmSlot.findIndex(item => item === x.label)
                    this.state.TotalBookingDays.map(i => {
                        TmSlotLst.push({ "Date": i, "Slot": TmSlot[Index] })
                    })
                })
            } else {
                if (early > 0) {
                    TmSlot.filter(i => i !== "Full Day").map(x => {
                        TmSlotLst.push({ "Date": this.state.CheckInDate, "Slot": x })
                    })
                }
                if (noon > 0) {
                    TmSlot.filter(i => i !== "09:00 - 13:00" && i !== "Full Day").map(x => {
                        TmSlotLst.push({ "Date": this.state.CheckInDate, "Slot": x })
                    })
                }
                if (evening > 0) {
                    TmSlotLst.push({ "Date": this.state.CheckInDate, "Slot": "17:00 - 21:00" })
                }

                this.state.TotalBookingDays.map(i => {
                    TmSlot.filter(i => i !== "Full Day").map(x => {
                        TmSlotLst.push({ "Date": i, "Slot": x })
                    })
                })
            }
        } else if (this.state.day == "Multi Day" && this.state.IsSameSlotSelected) {
            this.state.ArriveTime.map((x) => {
                var Index = TmSlot.findIndex(item => item === x.label)
                TmSlotLst.push({ "Date": this.state.CheckInDate, "Slot": TmSlot[Index] })
            })
            this.state.ArriveTime.map((x) => {
                var Index = TmSlot.findIndex(item => item === x.label)
                this.state.TotalBookingDays.map(i => {
                    TmSlotLst.push({ "Date": i, "Slot": TmSlot[Index] })
                })
            })
        } else {
            this.state.ArriveTime.map((x) => {
                var Index = TmSlot.findIndex(item => item === x.label)
                TmSlotLst.push({ "Date": this.state.singleDate, "Slot": TmSlot[Index] })
            })
        }

        if (this.state.day == "Multi Day" && !this.state.IsSameSlotSelected) {
            // this.state.TotalBookingDays.map(i => {
            //     TmSlotLst.push({ "Date": i, "Slot": TmSlot[Index] })
            // })
            if (this.state.IsDepartFullDaySelected) {
                TmSlotLst.push({ "Date": this.state.CheckOutDate, "Slot": "Full Day" })
            } else {
                if (eveningdepart > 0) {
                    TmSlot.filter(i => i !== "Full Day").map(x => {
                        TmSlotLst.push({ "Date": this.state.CheckOutDate, "Slot": x })
                    })
                }
                if (noondepart > 0) {
                    TmSlot.filter(i => i !== "17:00 - 21:00" && i !== "Full Day").map(x => {
                        TmSlotLst.push({ "Date": this.state.CheckOutDate, "Slot": x })
                    })
                }
                if (earlydepart > 0) {
                    TmSlotLst.push({ "Date": this.state.CheckOutDate, "Slot": "09:00 - 13:00" })
                }
            }
        } else if (this.state.day == "Multi Day" && this.state.IsSameSlotSelected) {
            TmSlotLst = TmSlotLst.concat(this.state.ArriveTime.map((x) => {
                var Index = TmSlot.findIndex(item => item === x.label)
                return { "Date": this.state.CheckOutDate, "Slot": TmSlot[Index] }
            }))
        }

        // TmSlotLst = TmSlotLst.sort(function (a, b) {
        //     // Turn your strings into dates, and then subtract them
        //     // to get a value that is either negative, positive, or zero.
        //     return new Date(a.Date) - new Date(b.Date);
        // });

        // var TotalCost = parseFloat(Number((PriceData.reduce((a, v) => a = a + parseFloat(v.Pdata), 0))) - Number(this.state.StudentDiscount) - Number(this.state.CouponDiscount)).toFixed(2);

        var TotalCost = parseFloat((this.state.CheckOutDetails.length > 0 ? Number(this.state.CheckOutDetails[0].TotalAmount) : Number(0)) - Number(this.state.StudentDiscount) - Number(this.state.CouponDiscount)).toFixed(2)

        return (
            loading ? (<Loader />) :
                (
                    <div className="checkout-outer">
                        <div className="container">
                            <div className="wizard-step">
                                <ul>
                                    <li>
                                        <a href="javascript:;" className={this.state.Step == 0 ? "active" : "completed"}>
                                            <span>1</span>
                                            <p>User Details</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:;" className={this.state.Step == 1 ? "active" : this.state.Step == 2 ? "completed" : "pending"}>
                                            <span>2</span>
                                            <p>Payment</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:;" className={this.state.Step == 2 ? "completed" : "pending"}>
                                            <span>3</span>
                                            <p>Confirmation</p>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row mobile-column-reverce tablet-column-reverce">
                                <div className="col-lg-7 col-md-12">
                                    {this.state.Step == 0 && !this.state.IsBookWithoutAccount ?
                                        <div className="ck-login-outer">
                                            <div className="ck-btn-login">
                                                <button className="btn btn-outline-primary btn-full" onClick={() => this.setState({ IsBookWithoutAccount: true })}>Book as a guest</button>
                                                <button className="btn btn-primary btn-full" onClick={() => this.setState({ showLoginModal: true })}>Log in</button>
                                            </div>

                                            <div className="or">
                                                <span>or</span>
                                            </div>
                                            <div className="ck-create-acc">
                                                <h2>Create your account</h2>
                                                <p>
                                                    And save time managing your bookings (tag your favorite pods, faster cancellation...)
                                    </p>
                                                <div className="row justify-content-center">
                                                    <div className="col-lg-8 col-md-8">
                                                        <Form noValidate onSubmit={(event) => this.handleRegister(event)
                                                        }>
                                                            <Form.Row>
                                                                <Form.Group as={Col} md="12" controlId="firstname" className="mb-3">
                                                                    <Form.Control
                                                                        required
                                                                        type="text"
                                                                        className={this.state.FirstNameErr ? "is-invalid" : null}
                                                                        value={FirstName}
                                                                        placeholder="First Name" onChange={(e) => this.handleChange(e, "FirstName")}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">{this.state.FirstNamehelperText}</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Form.Group as={Col} md="12" controlId="lastname" className="mb-3">
                                                                    <Form.Control
                                                                        required
                                                                        type="text"
                                                                        className={this.state.LastNameErr ? "is-invalid" : null}
                                                                        value={LastName}
                                                                        placeholder="Last Name" onChange={(e) => this.handleChange(e, "LastName")}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">{this.state.LastNamehelperText}</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Form.Group as={Col} md="12" controlId="email" className="mb-3">
                                                                    <Form.Control
                                                                        required
                                                                        type="email"
                                                                        className={this.state.EmailErr ? "is-invalid" : null}
                                                                        value={Email}
                                                                        placeholder="Email" onChange={(e) => this.handleChange(e, "Email")}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">{this.state.emailhelperText}</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Form.Group as={Col} md="12" controlId="password" className="mb-3">
                                                                    <Form.Control
                                                                        required
                                                                        type={this.state.showPassword ? "text" : "password"}
                                                                        className={this.state.PasswordErr ? "is-invalid" : null}
                                                                        value={this.state.Password}
                                                                        placeholder="Password" onChange={(e) => this.handleChange(e, "Password")}
                                                                    />
                                                                    <a href="javascript:;">
                                                                        {!this.state.showPassword ?
                                                                            <i className="fas fa-eye-slash" onClick={() => this.handleTogglePassword("Password")}></i>
                                                                            :
                                                                            <i className="fas fa-eye" onClick={() => this.handleTogglePassword("Password")}></i>

                                                                        }
                                                                    </a>
                                                                    <Form.Control.Feedback type="invalid">{this.state.PasswordhelperText}</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Form.Group as={Col} md="12" controlId="confirmpassword" className="mb-3">
                                                                    <Form.Control
                                                                        required
                                                                        type={this.state.showConfirmPassword ? "text" : "password"}
                                                                        className={this.state.ConfirmPasswordErr ? "is-invalid" : null}
                                                                        value={this.state.ConfirmPassword}
                                                                        placeholder="Confirm Password" onChange={(e) => this.handleChange(e, "ConfirmPassword")}
                                                                    />
                                                                    <a href="javascript:;">
                                                                        {!this.state.showConfirmPassword ?
                                                                            <i className="fas fa-eye-slash" onClick={() => this.handleTogglePassword("ConfirmPassword")}></i>
                                                                            :
                                                                            <i className="fas fa-eye" onClick={() => this.handleTogglePassword("ConfirmPassword")}></i>

                                                                        }
                                                                    </a>
                                                                    <Form.Control.Feedback type="invalid">{this.state.ConfirmPasswordhelperText}</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Form.Row>
                                                            <button type="submit" className="homey_login_button btn btn-primary outlined btn-full-width">Create your account</button>
                                                            <span>By selecting <strong>Create your account</strong> below, I agree to Workpod’s <a href="/admin/termsofservice">Terms of Service</a>, <a href="/admin/paymenttermsofservice">Payments Terms of Service</a>, <a href="/admin/privacypolicy">Privacy Policy</a>, and <a href="/admin/nondiscriminationpolicy">Nondiscrimination Policy</a>.</span>

                                                            <div className="login-opt">
                                                                <div className="checkbox pull-left">
                                                                    <label>
                                                                        <input type="checkbox" value={this.state.IsOKToSendOffer} onChange={(e) => this.handleChange(e, 'IsOKToSendOffer')} /><span>I don’t want to receive marketing messages from Workpod</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Form>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        : this.state.Step == 0 && this.state.IsBookWithoutAccount ?
                                            <div className="ck-book-without-acc-outer">
                                                <div className="ck-create-acc">
                                                    <h2>Book without an account</h2>
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-8 col-md-8">
                                                            <Form noValidate onSubmit={(event) => this.BookWithoutAccount(event)
                                                            }>
                                                                <Form.Group as={Col} md="12" controlId="firstname">
                                                                    <Form.Control
                                                                        required
                                                                        type="text"
                                                                        className={this.state.FirstNameErr ? "is-invalid" : null}
                                                                        value={FirstName}
                                                                        placeholder="First Name" onChange={(e) => this.handleChange(e, "FirstName")}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">{this.state.FirstNamehelperText}</Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="12" controlId="lastname">
                                                                    <Form.Control
                                                                        required
                                                                        type="text"
                                                                        className={this.state.LastNameErr ? "is-invalid" : null}
                                                                        value={LastName}
                                                                        placeholder="Last Name" onChange={(e) => this.handleChange(e, "LastName")}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">{this.state.LastNamehelperText}</Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="12" controlId="email">
                                                                    <Form.Control
                                                                        required
                                                                        type="text"
                                                                        className={this.state.EmailErr ? "is-invalid" : null}
                                                                        value={Email}
                                                                        placeholder="Email" onChange={(e) => this.handleChange(e, "Email")}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">{this.state.EmailhelperText}</Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="12" controlId="phoneno">
                                                                    <PhoneInput
                                                                        country={'gb'}
                                                                        value={PhoneNumber}
                                                                        onChange={(e) => this.handleChange(e, "PhoneNumber")}
                                                                    />
                                                                    {/* <Form.Control
                                                                required
                                                                type="text"
                                                                className={this.state.PhoneNumberErr ? "is-invalid" : null}
                                                                value={PhoneNumber}
                                                                placeholder="Phone no." onChange={(e) => this.handleChange(e, "PhoneNumber")}
                                                            /> */}
                                                                    <Form.Control.Feedback type="invalid">{this.state.PhoneNumberhelperText}</Form.Control.Feedback>
                                                                </Form.Group>

                                                                {/* <div className="form-group">
                                                        <input type="text" className="form-control" placeholder="First name" value={this.state.FirstName} onChange={(e) => this.handleChange(e, "FirstName")}></input>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" placeholder="Last name" value={this.state.LastName} onChange={(e) => this.handleChange(e, "LastName")}></input>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" placeholder="Email address" value={this.state.Email} onChange={(e) => this.handleChange(e, "Email")}></input>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="number" className="form-control" placeholder="Phone no." value={this.state.PhoneNumber} onChange={(e) => this.handleChange(e, "PhoneNumber")}></input>
                                                    </div>
                                                     */}
                                                                <div className="ckc-submit">
                                                                    <button type="submit" className="btn btn-primary">Continue</button>
                                                                    <div className="privacy-text">
                                                                        <p>
                                                                            By booking on our site. you agree to our <a href="/admin/termsofservice" target="_blank">Terms of Sales</a> and our <a href="/admin/privacypolicy" target="_blank">Data Privacy Policy</a>
                                                                        </p>
                                                                    </div>
                                                                    <button className="btn btn-default btn-icon btn-back" onClick={() => this.setState({ IsBookWithoutAccount: false })}><i className="fas fa-angle-left"></i>Back</button>
                                                                </div>
                                                            </Form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            : this.state.Step == 1 ?

                                                !this.state.IsPrepaidAccountSelected ?
                                                    <div className="ck-login-outer">
                                                        <div className="ck-heading">
                                                            <h2>Request to book</h2>
                                                        </div>
                                                        <div className="ck-trip">
                                                            <h3>Your Booking</h3>
                                                            {this.state.day == "Single Day" ?
                                                                <div className="ck-trip-row">
                                                                    <div className="crt-info">
                                                                        <p>Dates</p>
                                                                        <span>{moment(this.state.CheckInDate).format("DD/MM/YYYY").toString()} </span>
                                                                    </div>
                                                                    {/* <div className="crt-edit">
                                                            <a href="javascript:;">Edit</a>
                                                        </div> */}
                                                                </div> :
                                                                <div className="ck-trip-row">
                                                                    <div className="crt-info">
                                                                        <p>Dates</p>
                                                                        <span>{moment(this.state.CheckInDate).format("DD/MM/YYYY").toString()} - {moment(this.state.CheckOutDate).format("DD/MM/YYYY").toString()}</span>
                                                                    </div>
                                                                    {/* <div className="crt-edit">
                                                            <a href="javascript:;">Edit</a>
                                                        </div> */}
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="ck-cancellation-policy">
                                                            <h3>Cancellation policy</h3>
                                                            <p>
                                                                Our policy is fair and straightforward: cancellation is free until 3 days before the check-in date (shown in your confirmation email), giving your host enough time for new potential bookings. If you cancel later than this, you’ll only receive a 50% refund.
                                                    {/* <a href="javascript:;">Learn more</a> */}
                                                            </p>
                                                            {/* <p>
                                                    <b>Make sure this host’s cancellation policy works for you.</b> Our Extenuating Circumstances policy may not cover travel disruptions caused by known events, such as COVID-19, or foreseeable events, such as common severe weather. <a href="/admin/freecancellation" target="_blank">Learn more</a>
                                                </p> */}
                                                        </div>
                                                        <div className="reserve-warning">
                                                            {/* <i className="far fa-calendar-alt"></i>
                                                            <h4>Your reservation won’t be confirmed until the host accepts your request (within 24 hours).</h4>
                                                            <p>You won’t be charged until then.</p> */}
                                                        </div>
                                                        <div className="ck-policy">
                                                            <p>
                                                                By selecting the button below, I agree to the <a href="/admin/howdoesitwork" target="_blank">House Rules</a>, <a href="/admin/freecancellation" target="_blank">Cancellation Policy</a>, <a href="https://www.gov.uk/guidance/travel-advice-novel-coronavirus" target="_blank">Workpod’s social distancing and other COVID-19-related guidelines</a>, and the <a href="/admin/userrefund" target="_blank">User Refund Policy</a>. I also agree to pay the total amount shown, which includes Service Fees. Payment Terms between you and Workpod Payments UK Ltd.
                                                            </p>
                                                        </div>
                                                        {this.state.CurrencyCode === 'INR' ?
                                                            <div className="ck-book">
                                                                <button className="btn btn-primary pre-checkout mb-2" onClick={() => this.displayRazorpay(TotalCost)}>
                                                                    Pay {this.state.CurrencySymbol ? parse(this.state.CurrencySymbol) : ""} {TotalCost}
                                                                </button>
                                                                {CommonConfig.loginData().PersonID ?
                                                                    <button className="btn btn-primary pre-checkout" onClick={() => {
                                                                        window.scrollTo(0, 0)
                                                                        this.setState({ IsPrepaidAccountSelected: true })
                                                                    }}>
                                                                        Prepaid Account
                                                                    </button>
                                                                    : null
                                                                }
                                                            </div>
                                                            :
                                                            <div className="ck-book">
                                                                <PayPalScriptProvider options={{ "client-id": this.state.ClientID, "currency": this.state.Currency }}>
                                                                    <PayPalButtons forceReRender={[TotalCost]} style={{ color: "blue", shape: "pill", label: "pay", height: 45 }} /*style={{ layout: "vertical" }}*/ createOrder={(data, actions) => {
                                                                        return actions.order.create({
                                                                            purchase_units: [{
                                                                                amount: {
                                                                                    currency_code: this.state.Currency,
                                                                                    value: TotalCost
                                                                                }
                                                                            }]
                                                                        });
                                                                    }}
                                                                        onApprove={(data, actions) => {

                                                                            return actions.order.capture().then((details) => {
                                                                                const resData = {
                                                                                    OrderId: details.id,
                                                                                    Status: details.status,
                                                                                    intent: details.intent,
                                                                                    ApiLink: details.links[0].href,
                                                                                    APIMethod: details.links[0].method,
                                                                                    PayerId: details.payer.payer_id,
                                                                                    PayerCountryCode: details.payer.address.country_code,
                                                                                    PayerEmailId: details.payer.email_address,
                                                                                    PayerName: details.purchase_units[0].shipping.name.full_name,
                                                                                    PurchaseAmount: details.purchase_units[0].amount.value,
                                                                                    CurrencyCode: details.purchase_units[0].amount.currency_code,
                                                                                    CreatedOn: moment(details.create_time).format('YYYY-MM-DD HH:mm:ss').toString()
                                                                                }
                                                                                try {
                                                                                    api.post("property/PayPalTxnSave", resData).then(res => {
                                                                                        this.setState({ loading: true })
                                                                                        this.SetNotificationHost(details);
                                                                                        this.SetNotificationGuest();
                                                                                        this.SetNotificationAdmin(details);
                                                                                        this.bookpod(res, details, TotalCost, TmSlotLst, PriceLst, "PayPal");
                                                                                    }).catch(err => {
                                                                                        // console.log(err)
                                                                                    })
                                                                                }
                                                                                catch (err) {
                                                                                    // console.log(err)
                                                                                }


                                                                            });
                                                                        }}
                                                                        onError={(err) => {
                                                                            console.error('error from the onError callback', err);
                                                                            cogoToast.error(err)
                                                                        }
                                                                        }
                                                                    />
                                                                    {CommonConfig.loginData().PersonID ?
                                                                        <button className="btn btn-primary pre-checkout" onClick={() => {
                                                                            window.scrollTo(0, 0)
                                                                            this.setState({ IsPrepaidAccountSelected: true })
                                                                        }}>
                                                                            Prepaid Account
                                                                        </button>
                                                                        : null
                                                                    }
                                                                </PayPalScriptProvider>

                                                            </div>
                                                        }
                                                    </div>

                                                    : this.state.prePaidAccountList.length > 0 ?
                                                        <div className="pre-ac-info">
                                                            <h2>Specify Your Prepaid Account</h2>
                                                            <div className="row">
                                                                <div className="col-lg-8 col-md-8">
                                                                    <div className="form-group">
                                                                        <label>Select an account</label>
                                                                        <select className={this.state.PrepaidAccountIDErr ? "form-control is-invalid" : "form-control"} value={this.state.PrepaidAccountID} onChange={(e) => this.handleChange(e, "PrepaidAccount")}>
                                                                            <option value=''>Select</option>
                                                                            {this.state.prePaidAccountList.map(x => {
                                                                                return (
                                                                                    <option value={x.PrepaidAccountID}>{x.PrepaidAccountName}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                        <div className="invalid-feedback" >{this.state.PrepaidAccountIDhelperText}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-8 col-md-8">
                                                                    <div className="form-group">
                                                                        <label>Amount Payable for Current Booking ({this.state.CurrencyCode})</label>
                                                                        <input className="form-control" value={TotalCost} disabled></input>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-8 col-md-8">
                                                                    <div className="form-group">
                                                                        <label>Prepaid Account Balance</label>
                                                                        <input className="form-control" value={this.state.PrepaidAccountID ? this.state.prePaidAccountList.filter(x => x.PrepaidAccountID === this.state.PrepaidAccountID)[0].Balance : ''} disabled></input>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-8 col-md-8">
                                                                    <div className="submit-btn">
                                                                        <button className="btn btn-default btn-icon btn-back" onClick={() => this.setState({ IsPrepaidAccountSelected: false, PrepaidAccountID: '' })}><i className="fas fa-angle-left"></i>Back</button>
                                                                        <button type="submit" className="btn btn-primary" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            if (CommonConfig.isEmpty(this.state.PrepaidAccountID)) {
                                                                                this.setState({ PrepaidAccountIDErr: true, PrepaidAccountIDhelperText: 'Please select account.' })
                                                                            } else if (Number(TotalCost) > Number(this.state.prePaidAccountList.filter(x => x.PrepaidAccountID === this.state.PrepaidAccountID)[0].Balance)) {
                                                                                cogoToast.error("Insufficient balance. Please try using different payment methods.")
                                                                            }
                                                                            else {
                                                                                this.setState({ PrepaidAccountIDErr: false, PrepaidAccountIDhelperText: '', loading: true })
                                                                                this.SetNotificationGuest();
                                                                                this.SetNotificationHost("prepaid");
                                                                                this.SetNotificationAdmin("prepaid");
                                                                                this.bookpod({}, '', TotalCost, TmSlotLst, PriceLst, "Prepaid Account")
                                                                            }
                                                                        }
                                                                        }>Charge Prepaid Account</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        : !this.state.IsSetupPrepaidSelected ?
                                                            <div className="create-prepaid-acc">
                                                                <p>It appears that you do not have any Prepaid Account setup</p>
                                                                <a href="javascript:;" onClick={() => this.setState({ IsSetupPrepaidSelected: true })} className="btn btn-success">Setup My Prepaid Account</a>
                                                            </div>
                                                            :
                                                            <div className="pre-ac-info">
                                                                <h2>New Prepaid Account Information</h2>
                                                                <div className="row">
                                                                    <div className="col-lg-8 col-md-8">
                                                                        <div className="form-group">
                                                                            <label>Name of Prepaid Account</label>
                                                                            <input type="text" className={this.state.PrepaidAccountNameErr ? "form-control is-invalid" : "form-control"} value={this.state.PrepaidAccountName} onChange={(e) => this.handleChange(e, "PrepaidAccountName")}></input>
                                                                            <div className="invalid-feedback">{this.state.PrepaidAccountNamehelperText}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-8 col-md-8">
                                                                        <div className="form-group">
                                                                            <label>Type of account</label>
                                                                            <select className={this.state.PurposeErr ? "form-control is-invalid" : "form-control"} value={this.state.Purpose} onChange={(e) => this.handleChange(e, "Purpose")}>
                                                                                <option value=''>Select</option>
                                                                                {this.state.purposeList.map(x => {
                                                                                    return (
                                                                                        <option value={x.StringMapName}>{x.StringMapName}</option>
                                                                                    )
                                                                                })}
                                                                            </select>
                                                                            <div className="invalid-feedback" >{this.state.PurposehelperText}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-2">
                                                                    <div className="col-lg-8 col-md-8">
                                                                        <div className="filter-check-outer">
                                                                            <label className="topup-label">Initial Amount to Top Up({this.state.CurrencyCode})</label>
                                                                            <div className="filter-check">
                                                                                <label>
                                                                                    <input type="radio" name="topup-amount" checked={this.state.TopUp == 100 ? true : false} onChange={(e) => this.handleChange(100, "TopUp")}></input>
                                                                                    <span>100</span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="filter-check">
                                                                                <label>
                                                                                    <input type="radio" name="topup-amount" checked={this.state.TopUp == 200 ? true : false} onChange={(e) => this.handleChange(200, "TopUp")}></input>
                                                                                    <span>200</span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="filter-check">
                                                                                <label>
                                                                                    <input type="radio" name="topup-amount" checked={this.state.TopUp == 500 ? true : false} onChange={(e) => this.handleChange(500, "TopUp")}></input>
                                                                                    <span>500</span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="filter-check">
                                                                                <label>
                                                                                    <input type="radio" name="topup-amount" checked={this.state.TopUp == 1000 ? true : false} onChange={(e) => this.handleChange(1000, "TopUp")}></input>
                                                                                    <span>1000</span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="filter-check">
                                                                                <label>
                                                                                    <input type="radio" name="topup-amount" checked={this.state.TopUp !== 100 && this.state.TopUp !== 200 && this.state.TopUp !== 500 && this.state.TopUp !== 1000 ? true : false} onChange={(e) => this.handleChange('', "TopUp")}></input>
                                                                                    <span>Custom</span>
                                                                                </label>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {this.state.TopUp !== 100 && this.state.TopUp !== 200 && this.state.TopUp !== 500 && this.state.TopUp !== 1000 ?
                                                                    <div className="row">
                                                                        <div className="col-lg-8 col-md-8">
                                                                            <div className="form-group">
                                                                                <label>Enter Amount</label>
                                                                                <input type="text" className={this.state.TopUpErr ? "form-control is-invalid" : "form-control"} value={this.state.TopUp} onChange={(e) => this.handleChange(e.target.value, "TopUp")}></input>
                                                                                <div className="invalid-feedback">{this.state.TopUphelperText}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    null}
                                                                <div className="row">
                                                                    <div className="col-lg-8 col-md-8">
                                                                        <div className="form-group">
                                                                            <label>Amount Payable ({this.state.CurrencyCode})</label>
                                                                            <input className="form-control" value={this.state.TopUp} disabled></input>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-8 col-md-8">
                                                                        <div className="submit-btn">
                                                                            {this.state.CurrencyCode === 'INR' ? <button className="btn btn-primary" onClick={() => this.RazorPaySetupPrepaid(this.state.TopUp)}>
                                                                                Pay {this.state.CurrencySymbol ? parse(this.state.CurrencySymbol) : ""} {this.state.TopUp}
                                                                            </button>
                                                                                :
                                                                                <PayPalScriptProvider options={{ "client-id": this.state.ClientID, "currency": this.state.Currency }}>
                                                                                    <PayPalButtons forceReRender={[this.state.TopUp]} style={{ color: "blue", shape: "pill", label: "pay", height: 45 }} /*style={{ layout: "vertical" }}*/ createOrder={(data, actions) => {
                                                                                        return actions.order.create({
                                                                                            purchase_units: [{
                                                                                                amount: {
                                                                                                    currency_code: this.state.Currency,
                                                                                                    value: this.state.TopUp
                                                                                                }
                                                                                            }]
                                                                                        });
                                                                                    }}
                                                                                        onApprove={(data, actions) => {

                                                                                            return actions.order.capture().then((details) => {
                                                                                                const resData = {
                                                                                                    OrderId: details.id,
                                                                                                    Status: details.status,
                                                                                                    intent: details.intent,
                                                                                                    ApiLink: details.links[0].href,
                                                                                                    APIMethod: details.links[0].method,
                                                                                                    PayerId: details.payer.payer_id,
                                                                                                    PayerCountryCode: details.payer.address.country_code,
                                                                                                    PayerEmailId: details.payer.email_address,
                                                                                                    PayerName: details.purchase_units[0].shipping.name.full_name,
                                                                                                    PurchaseAmount: details.purchase_units[0].amount.value,
                                                                                                    CurrencyCode: details.purchase_units[0].amount.currency_code,
                                                                                                    CreatedOn: moment(details.create_time).format('YYYY-MM-DD HH:mm:ss').toString()
                                                                                                }
                                                                                                try {
                                                                                                    api.post("property/PayPalTxnSave", resData).then(res => {
                                                                                                        this.updateTopUpbalance(res, 'PayPal');
                                                                                                    }).catch(err => {
                                                                                                        // console.log(err)
                                                                                                    })
                                                                                                }
                                                                                                catch (err) {
                                                                                                    // console.log(err)
                                                                                                }


                                                                                            });
                                                                                        }}
                                                                                        onError={(err) => {
                                                                                            console.error('error from the onError callback', err);
                                                                                            cogoToast.error(err)
                                                                                        }
                                                                                        }
                                                                                    />
                                                                                </PayPalScriptProvider>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                :
                                                <div className="ck-booking-confirm-outer">
                                                    <div className="ckb-box">
                                                        <img src={tick} alt=""></img>
                                                        <h2>Your booking is confirmed</h2>
                                                        <span>Booking number: {this.state.BookingNumber}</span>
                                                        <p>
                                                            We have just sent you a booking confirmation at {this.state.GuestUserEmail} The host has been informed of your arrival.
                                                </p>
                                                        {!this.state.IsBookSmsSent ?
                                                            <button className="btn btn-outline-primary" onClick={(e) => this.sendBookSMS(e)}>Send me the confirmation by text message</button>
                                                            :
                                                            <button className="btn btn-outline-primary">The SMS confirmation has been sent!</button>
                                                        }
                                                    </div>
                                                    <div className="ckb-cancel-warning">
                                                        <h4><i class="far fa-thumbs-up"></i> Changed your mind?</h4>
                                                        <p>
                                                            You can cancel your booking until the last minute via the website (if you have created an account) or via your confirmation email(if not). Please do not forget to do this as this will allow our host to better organize their remaining bookings.
                                                </p>
                                                    </div>
                                                    <div className="privacy-text">
                                                        <p>
                                                            Need help? <a href="/admin/faq" target="_blank">See Frequently Asked Questions</a><br></br>
                                                    You can contact our Customer Service by phone at {t('Checkout : CustomerServiceNo')}
                                                        </p>
                                                        <a href="/admin/homepage" className="btn btn-outline-primary">Make another booking</a>
                                                    </div>
                                                </div>


                                    }
                                </div>
                                <div className="col-lg-5 col-md-12">
                                    <StickyBox offsetTop={110} offsetBottom={20}>
                                        <div className="ck-book-box-wrap">
                                            <div className="ck-bookinfo">
                                                <div className="ck-bookinfo-image">
                                                    {/* <img src="https://dummyimage.com/124x106"></img> */}
                                                    <Swiper
                                                        navigation={false}
                                                        pagination={{ clickable: true, dynamicBullets: true }}
                                                    >
                                                        {this.state.propertyDetail && this.state.propertyDetail.PropertyMedia ? this.state.propertyDetail.PropertyMedia.filter(item => item.MediaType.split("/")[0] === 'image').map(content => {
                                                            return (

                                                                <SwiperSlide><img src={apiBase + content.MediaURL} alt="" height="106" width="124" /></SwiperSlide>
                                                            )
                                                        }
                                                        ) : null}
                                                    </Swiper>

                                                </div>
                                                <div className="ck-bookinfo-details">
                                                    <p>{this.state.propertyDetail.PropertyName}</p>
                                                    <span>{this.state.propertyDetail.PropertyAddress}</span>
                                                    {/* <small>2 pods</small> */}
                                                    {/* <div className="star">
                                                <i class="fas fa-star"></i>
                                                <p>{this.state.propertyDetail.Rate} &nbsp; </p><br />
                                            </div>
                                            <p>Rating Type :  ({this.state.propertyDetail.ReviewRatingType})</p> */}
                                                </div>
                                            </div>

                                            <div className="offer-outer">

                                                <a
                                                    href="javascript:;"
                                                    onClick={() => this.setState({ toggleEvent: Date.now(), IsUp: !this.state.IsUp })}
                                                >
                                                    <h3>Discount / Promotion Code <i className={!this.state.IsUp ? `fa fa-chevron-down` : 'fa fa-chevron-up'}></i></h3>
                                                </a>
                                                <SlideToggle toggleEvent={this.state.toggleEvent} collapsed>
                                                    {({ setCollapsibleElement }) => (
                                                        <div
                                                            ref={setCollapsibleElement}
                                                        >
                                                            {this.state.propertyDetail && this.state.propertyDetail.StudentDiscount && !this.state.IsStudentDiscountApplied && !this.state.IsCouponDiscountApplied ?
                                                                <div className="form-group">
                                                                    <label>Are you a Student ? ({this.state.propertyDetail.StudentDiscount}% discount applicable)</label>
                                                                    <div className="with-apply-btn">
                                                                        <input type="text" className={this.state.StudentIdErr ? "form-control is-invalid" : "form-control"} placeholder="E.g. 12345678" value={this.state.StudentId} onChange={(e) => this.handleChange(e, "StudentId")} />
                                                                        <button className="btn btn-primary" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            if (CommonConfig.isEmpty(this.state.StudentId)) {
                                                                                this.setState({ StudentIdErr: true, StudentIdhelperText: 'Student Id is required.' })
                                                                            } else if (this.state.StudentId.length < 8) {
                                                                                this.setState({ StudentIdErr: true, StudentIdhelperText: 'Student Id cannot be less than 8 character.' })
                                                                            } else if (this.state.StudentId.length > 8) {
                                                                                this.setState({ StudentIdErr: true, StudentIdhelperText: 'Student Id cannot be more than 8 character.' })
                                                                            } else {
                                                                                this.setState({ StudentIdErr: false, StudentIdhelperText: '' })
                                                                                this.setState({ StudentDiscount: parseFloat(Number(TotalCost) * Number(this.state.propertyDetail.StudentDiscount) / 100).toFixed(2), IsStudentDiscountApplied: true });
                                                                            }
                                                                        }}>Apply
                                                                        </button>
                                                                    </div>
                                                                    <div className="invalid-feedback d-block" >{this.state.StudentIdhelperText}</div>
                                                                </div>
                                                                : this.state.propertyDetail && this.state.propertyDetail.StudentDiscount && this.state.IsStudentDiscountApplied && !this.state.IsCouponDiscountApplied ?

                                                                    // <div className="form-group">
                                                                    //     <label>Are you a Student ? ({this.state.propertyDetail.StudentDiscount}% discount applicable)</label>
                                                                    //     <button className="btn btn-success" disabled>Applied</button>
                                                                    //     <a href="javacript:;" className="student-discount" onClick={() => this.setState({
                                                                    //         IsStudentDiscountApplied: false,
                                                                    //         StudentId: '',
                                                                    //         StudentIdErr: false,
                                                                    //         StudentIdhelperText: '',
                                                                    //         IsCouponDiscountApplied: false,
                                                                    //         CouponCode: '',
                                                                    //         CouponCodeErr: false,
                                                                    //         CouponCodehelperText: '',
                                                                    //         CouponDiscount: 0
                                                                    //     })}><i className="fas fa-times"></i></a>

                                                                    // </div>
                                                                    <div className="dis-applied">
                                                                        <p>{this.state.propertyDetail.StudentDiscount}% Student Discount Applied</p>
                                                                        <a href="javascript:;" onClick={() => this.setState({
                                                                            StudentDiscount: 0,
                                                                            IsStudentDiscountApplied: false,
                                                                            StudentId: '',
                                                                            StudentIdErr: false,
                                                                            StudentIdhelperText: '',
                                                                            IsCouponDiscountApplied: false,
                                                                            CouponCode: '',
                                                                            CouponCodeErr: false,
                                                                            CouponCodehelperText: '',
                                                                            CouponDiscount: 0
                                                                        })}
                                                                        ><i className="fas fa-times"></i></a>
                                                                    </div>
                                                                    : null}

                                                            {!this.state.IsCouponDiscountApplied && !this.state.IsStudentDiscountApplied ?

                                                                <div className="form-group">
                                                                    <label>Enter Coupon Code</label>
                                                                    <div className="with-apply-btn">
                                                                        <input type="text" className={this.state.CouponCodeErr ? "form-control is-invalid" : "form-control"} placeholder="E.g. WINTER20" value={this.state.CouponCode} onChange={(e) => this.handleChange(e, "CouponCode")} />

                                                                        <button className="btn btn-primary" onClick={(e) => this.verifyCoupon(e, TotalCost)}>Apply</button>
                                                                    </div>
                                                                    <div className="invalid-feedback d-block" >{this.state.CouponCodehelperText}</div>
                                                                </div>
                                                                :
                                                                this.state.IsCouponDiscountApplied && !this.state.IsStudentDiscountApplied ?
                                                                    // <div className="form-group">
                                                                    //     <label> Coupon Code - </label>
                                                                    //     <span> {this.state.CouponCode}</span>

                                                                    //     <button className="btn btn-success" disabled>Applied</button>
                                                                    //     <a href="javacript:;" className="student-discount" onClick={() => this.setState({
                                                                    //         IsStudentDiscountApplied: false,
                                                                    //         StudentId: '',
                                                                    //         StudentIdErr: false,
                                                                    //         StudentIdhelperText: '',
                                                                    //         IsCouponDiscountApplied: false,
                                                                    //         CouponCode: '',
                                                                    //         CouponCodeErr: false,
                                                                    //         CouponCodehelperText: '',
                                                                    //         CouponDiscount: 0
                                                                    //     })}><i className="fas fa-times"></i></a>
                                                                    // </div>

                                                                    <div className="dis-applied">
                                                                        <p>{this.state.CouponCode} is Applied</p>
                                                                        <a href="javascript:;" onClick={() => {

                                                                            this.setState({
                                                                                IsStudentDiscountApplied: false,
                                                                                StudentId: '',
                                                                                StudentDiscount: 0,
                                                                                StudentIdErr: false,
                                                                                StudentIdhelperText: '',
                                                                                IsCouponDiscountApplied: false,
                                                                                CouponCode: '',
                                                                                CouponCodeErr: false,
                                                                                CouponCodehelperText: '',
                                                                                CouponDiscount: 0
                                                                            })
                                                                        }}
                                                                        ><i className="fas fa-times"></i></a>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    )}
                                                </SlideToggle>

                                            </div>

                                            <div className="ck-price">
                                                <h3>Booking Summary</h3>
                                                <div className="slot-prices">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Time Slot</th>
                                                                {/* <th className="right">Price</th>
                                                                <th className="right">Users</th> */}
                                                                <th></th>
                                                                <th></th>
                                                                <th className="right">Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.CheckOutDetails.map(x => {
                                                                return (
                                                                    <tr>
                                                                        <td>{x.DOW + " ," + moment(x.BookingDate).format('DD MMM YYYY')}</td>
                                                                        <td>{x.Slot}</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        {/* <td className="right">{x.CurrencySymbol ? parse(x.CurrencySymbol) : ""} {x.Price}</td>
                                                                        <td className="right">{x.UserCount}</td> */}
                                                                        <td className="right">{x.CurrencySymbol ? parse(x.CurrencySymbol) : ""} {x.Amount}</td>
                                                                    </tr>
                                                                )
                                                            })}

                                                            {/* <tr>
                                                        <td>01-05-2021</td>
                                                        <td>09:00 - 13:00</td>
                                                             <td className="right">£ 7.00</td>
                                                    </tr> */}
                                                        </tbody>
                                                        <tfoot>
                                                            {this.state.IsStudentDiscountApplied ?
                                                                <tr>
                                                                    <th colSpan="4">Student Discount {this.state.propertyDetail.StudentDiscount}% applied</th>
                                                                    <th className="right"> - {this.state.CurrencySymbol ? parse(this.state.CurrencySymbol) : ""}{this.state.StudentDiscount}</th>
                                                                </tr>
                                                                : null}
                                                            {this.state.IsCouponDiscountApplied ?
                                                                <tr>
                                                                    <th colSpan="4">Promo code discount {this.state.DiscountPercentage}% applied</th>
                                                                    <th className="right"> - {this.state.CurrencySymbol ? parse(this.state.CurrencySymbol) : ""}{this.state.CouponDiscount}</th>
                                                                </tr>
                                                                : null}

                                                            <tr>
                                                                <th colSpan="4">Total</th>
                                                                <th className="right">{this.state.CurrencySymbol ? parse(this.state.CurrencySymbol) : ""} {TotalCost}</th>
                                                            </tr>
                                                        </tfoot>

                                                    </table>
                                                </div>
                                                {/* <div className="ck-price-row">
                                            <p>{TmSlotLst.map(x => {
                                                return (
                                                    <>
                                                        <span className="mr-5">{moment(x.Date).format('DD-MM-YYYY')}</span>
                                                        {x.Slot} <br /></>
                                                )
                                            })}
                                            </p>
                                            <span>{PriceLst.map(x => { return <>£ {x.Price}<br /></> })}</span>
                                        </div> */}
                                                {/* {this.state.IsStudentDiscountApplied ?
                                            <div className="ck-price-row">
                                                <a href="javascript:;">Student Discount {this.state.propertyDetail.StudentDiscount}% applied</a>
                                                <span> - £{this.state.StudentDiscount}</span>
                                            </div>
                                            : null}
                                        {this.state.IsCouponDiscountApplied ?
                                            <div className="ck-price-row">
                                                <a href="javascript:;">Promo code discount {this.state.DiscountPercentage}% applied</a>
                                                <span> - £{this.state.CouponDiscount}</span>
                                            </div>
                                            : null} */}
                                                {/* <div className="ck-price-row">
                                            <a href="javascript:;">Total</a>
                                            <span>£{TotalCost}</span>
                                        </div> */}
                                            </div>

                                        </div>
                                    </StickyBox>
                                </div>
                            </div>
                        </div>

                        <Modal
                            show={openCancelCheckoutModal}
                            onHide={() => this.setState({
                                openCancelCheckoutModal: false
                            })}
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Are you sure you want to cancel transaction ?</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>By clicking continue your checkout process will be cancelled . Are you sure you want to continue?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={() => {
                                    this.setState({
                                        openCancelCheckoutModal: false
                                    })
                                }}>
                                    Take me to {t('Header:Country') === "gb" ? "India" : "UK"} Site
                                </Button>
                                <Button className="country-change-continue" onClick={() =>
                                    this.props.history.push('/admin/detailpage')
                                }>
                                    Continue
                                </Button>
                            </Modal.Footer>
                        </Modal>



                        {this.state.showLoginModal ? <Header showLoginModal={this.state.showLoginModal} hideLoginModal={() => this.setState({ showLoginModal: false })} {...rest} /> : false}
                    </div>
                )
        )

    }

}

export default withNamespaces()(Checkout);