import React, { Component } from 'react'
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

class FAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div className="faq-outer">
                <ol className="breadcrumb ml-4">
                    <li>
                        <a
                            href="javascript:;"
                            onClick={() =>
                                this.props.history.push("/admin/homepage")
                            }
                        >
                            <span>Home</span>
                        </a>
                    </li>
                    <li className="active">
                        <span>FAQ</span>
                    </li>
                </ol>

                <div className="contact-heading">
                    <div className="container">
                        <h2 className="h2">Frequently Asked Questions</h2>
                    </div>
                </div>
                <div className="container">
                    <div className="faq-inner">
                        <h3>Popular Topics</h3>
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    Having internet is crucial to getting my work done. How can I be sure there’s a connection?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        We understand this, and class wi-fi connection as an office necessity, as opposed to an amenity. Hosts may wish to specify whether there are usable computers connected to the Internet and/or users would have to bring their own laptop and use the given wi-fi code, but ultimately, reliable Internet connection should be part of what you’re paying for.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                    How can I ensure that any electronic equipment I need will be available?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        The listing’s details section should cover any and all available amenities (including but not limited to photocopiers, scanners, printers and coffee machines), so this should inform your decision on making a booking – when in doubt, you can easily contact hosts with any questions you may have.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="2">
                                    Do I need to prove my identity upon arrival? If so, how can I do this?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        Yes, and it’s super simple! Just bring a printed (or digital) copy of your booking confirmation, and the host will double-check.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="3">
                                    How do I know if a host/listing is legitimate?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        All users have the power to rate and review a listing after their stay, and are encouraged by The Work Pod to do so. Any breach of the terms of service should be reported to The Work Pod so we can immediately take action.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="4">
                                    How do the time slots work?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="4">
                                    <Card.Body>
                                        Available dates and times will vary according to the host’s choice of offerings, and are likely non-negotiable.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="5">
                                    How do hosts earn money with the Work Pod? Does the website take a portion of your money?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="5">
                                    <Card.Body>
                                        The majority of the booking commission will of course go to the host, but like any other e-commerce platform, the Work Pod earns a small fee for use of its services. We add an extra 3% charge onto the user’s bill, so hosts can earn the amount they initially intended without any deductions/losses.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            {/* <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="6">
                                    How can I find my booking number?
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="6">
                                    <Card.Body>
                                    Your booking number can be found in the booking confirmation sent by email. If you have a customer account, you can also find this number in the tab "My bookings".<br></br>
                                    If you do not have an account yet, please contact our customer service by phone and they will provide you with this information.
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card> */}
                        </Accordion>
                    </div>
                </div>
            </div>
        )
    }
}

export default FAQ
