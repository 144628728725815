import React, { Component } from 'react'
import london from "../../assets/img/london.jpg";
import edinburgh from "../../assets/img/edinburgh.jpg";
import manchester from "../../assets/img/manchester.jpg";
import birmingham from "../../assets/img/birmingham.jpg";
import { withNamespaces } from 'react-i18next';

class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }

    searchPod = (placeName) => {
        this.props.history.push({
            pathname: "/admin/listing",
            state: { placeName: placeName }
        })
    }

    render() {
        const { t } = this.props;
        return (
            <div className="aboutus-outer">
                <div className="container">
                    <ol className="breadcrumb">
                        <li>
                            <a
                                href="javascript:;"
                                onClick={() =>
                                    this.props.history.push("/admin/homepage")
                                }
                            >
                                <span>Home</span>
                            </a>
                        </li>
                        <li className="active">
                            <span>About Us</span>
                        </li>
                    </ol>

                    <div className="about-inner">
                        <h2>About Us</h2>
                        <div className="about-text">
                            <p>
                                At The WorkPod, our objective is to provide private, dedicated spaces for people to work when they don’t have their own. We believe that everyone has the right to a comfortable, convenient working environment, where they can produce their best work possible in the best surroundings possible, and so The WorkPod was born. The WorkPod helps people to find peaceful, affordably-priced workspaces at the click of a few buttons, no matter the area or short notice; without any contractual/financial obligations.
                            </p>
                            <p>
                                We hope that The WorkPod will become something people can use regularly, whether you need a quiet and professional meeting room or simply a place to focus. We want to be there for the people that have struggled to gain some calm away from home during lockdowns and need some space to think. For the students that just need some space from their siblings, and the people that need a change in their surroundings. The stifled ideas and developing ideas - we want to help them all grow and be nurtured into something greater.
                            </p>
                        </div>

                    </div>
                </div>
                <div className="about-contact-box">
                    <div className="container">
                        <div className="ac-inner">
                            <h2>Any other questions?</h2>
                            <a href="/admin/contactus">Contact Us</a>
                            <p>
                                A The WorkPod advisor will answer all your questions on <span>0208 427 8787</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="about-places">
                    <div className="container">
                        <h2 className="h2">Your WorkPod, wherever you want it</h2>
                        <div className="trending-pods-inner">
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <a href="javascript:;" onClick={() => this.searchPod(t('AboutUs:PlaceName1Value'))}>
                                        <div className="tp-box">
                                            <img src={london} alt="" />
                                            <div className="gr-overlay"></div>
                                            <h3 className="tp-box-caption">{t('AboutUs:PlaceName1')}</h3>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <a href="javascript:;" onClick={() => this.searchPod(t('AboutUs:PlaceName2Value'))}>
                                        <div className="tp-box">
                                            <img src={edinburgh} alt="" />
                                            <div className="gr-overlay"></div>
                                            <h3 className="tp-box-caption">{t('AboutUs:PlaceName2')}</h3>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <a href="javascript:;" onClick={() => this.searchPod(t('AboutUs:PlaceName3Value'))}>
                                        <div className="tp-box">
                                            <img src={manchester} alt="" />
                                            <div className="gr-overlay"></div>
                                            <h3 className="tp-box-caption">{t('AboutUs:PlaceName3')}</h3>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <a href="javascript:;" onClick={() => this.searchPod(t('AboutUs:PlaceName4Value'))}>
                                        <div className="tp-box">
                                            <img src={birmingham} alt="" />
                                            <div className="gr-overlay"></div>
                                            <h3 className="tp-box-caption">{t('AboutUs:PlaceName4')}</h3>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="workpod-team">
                    <div className="container">
                        <h2 className="h2">Our Team</h2>
                        <div className="row">
                            <div className="col-lg-3 col-md-3">
                                <div className="team-box">
                                    <img src="https://picsum.photos/200"></img>
                                    <h4>Member Name</h4>
                                    <span>Designation</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <div className="team-box">
                                    <img src="https://picsum.photos/200"></img>
                                    <h4>Member Name</h4>
                                    <span>Designation</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <div className="team-box">
                                    <img src="https://picsum.photos/200"></img>
                                    <h4>Member Name</h4>
                                    <span>Designation</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <div className="team-box">
                                    <img src="https://picsum.photos/200"></img>
                                    <h4>Member Name</h4>
                                    <span>Designation</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

// export default AboutUs

export default withNamespaces()(AboutUs);
