import React, { Component } from "react";
import api from "../../utils/apiClient";
import { apiBase } from '../../../src/utils/config';
import logo from "../../assets/img/logo.svg";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
// import { Calendar } from 'react-date-range';
// import { DateRangePicker } from 'react-date-range';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
// import Popover from 'react-bootstrap/Popover'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import cogoToast from "cogo-toast";
import Select from "react-select";
// import AsyncSelect from 'react-select/async';
import makeAnimated from "react-select/animated";
import moment from "moment";
import parse from "html-react-parser";
import DatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
// import Toolbar from "react-multi-date-picker/plugins/toolbar"
// import { RMIUploader } from "react-multiple-image-uploader";
import { CommonConfig } from "utils/constant";
import Dropzone from "react-dropzone";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
const animatedComponents = makeAnimated();

class ViewPropertyByAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Heading: [
        "Qualify for a host",
        "Pods and guests",
        "Location",
        "Pricing and guests",
        "Amenities",
        "Rules",
        "Add Photos",
        "Booking settings",
      ],
      Step: 0,
      FirstName: "",
      PersonID: "",
      PropertyAddress: "",
      PropertyName: "",
      Latitude: 0.0,
      Longitude: 0.0,
      Flat: "",
      PodType: "",
      podTypeList: [],
      noOfPodList: [],
      amenitiesList: [],
      standardAmenitiesList: [],
      amenitiesRulesList: [],
      City: "",
      Country: "",
      Postcode: "",
      Users: 1,

      WashRoom: 1,
      Amenities: [],
      Rules: [],
      IsEmailAccesibility: false,
      dynamicProgress: 14.2857,
      IsAdjust: false,
      Time: [],
      timeSlotList: [],
      internetTypeList: [],
      PropertyMedia: [],
      Media: "",
      PreviewMedia: [],
      selectedDateRange: [
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ],
      dayTypeList: [],
      singleDate: [],
      day: "Single Day",
      WorkingDays: [],
      workingDaysList: [],
      titleSuggestion: [],
      titleSuggestionList: [],
      files: [],
      mainDateArray: [],
      rangeDate: [],
      InternetType: "",
      StudentDiscount: "",
      SeasonalDiscount: "",
      List: [],
      Reason: "",
      ReasonHelperText: "",
      ReasonErr: false,
      isApproved: false,
      isReject: false,
      isRejectButtonNotClicked: true,
      photoIndex: 0,
      isOpen: false,
    };
  }

  componentDidMount() {
    if (this.props.location.state) {
      this.setState({
        FirstName: this.props.location.state.FirstName,
        PersonID: this.props.location.state.PersonID,
      });
    } else {
      this.props.history.push("/admin/dashboard");
    }
    this.getPodTypeList();
    // this.getDayTypeList();
    this.getInternetTypeList();
    // this.getNoOfPodList();
    // this.getWorkingDaysList();
    this.getAmenitiesList();
    this.getStandardAmenitiesList();
    this.getAmenitiesRulesList();
    this.getTimeSlotList();
    this.getNoOfUsersList();
    this.getNoOfWashRoomsList();
    this.titleSuggestionList("");
    this.getPropertyDetails();
  }

  getPropertyDetails = () => {
    // console.log("object", this.props.location.state)
    try {
      api
        .post("property/viewpropertybyadmin", this.props.location.state)
        .then((res) => {
          if (res.success) {
            this.setState({ List: res.data  });
            // console.log("stateeee", this.state.List);
            this.setState({
              PropertyName: this.state.List.PropertyName
            })
            this.setState({
              listOfTitles: this.state.titleSuggestionList.map(x => x.label)
            })
            if (!this.state.listOfTitles.includes(this.state.PropertyName)) {
              this.setState({
                titleSuggestion: {
                  value: "Customize your title"
                }
              })
            }
            // console.log("propertyname", this.state.PropertyName)
            // console.log("propertyname", this.state.PropertyName)
            this.codeLatLng(this.state.List.Latitude, this.state.List.Longitude)
            this.setState({ amenitiesOfPod: this.state.List.Amenities.map(x => x.FeatureName) })
            // console.log("amenitiesss", this.state.amenitiesOfPod)
            this.setState({ amenitiesRules: this.state.List.PropertyRules.map(x => x.Rule) })
            // console.log("amenitiesssRuless", this.state.amenitiesRules)

            this.setState({
              allRules: this.state.amenitiesRulesList.map(x => x.FeatureName)
            })
            this.setState({
              additionalRules: this.state.amenitiesRules.filter(x => !this.state.allRules.includes(x))
            })
            // console.log("additional", this.state.additionalRules)
          }

        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  };

  //   getNoOfPodList = () => {
  //     let data = {
  //       StringMapType: "NOOFPODS",
  //     };
  //     try {
  //       api
  //         .post("utility/getStringMap", data)
  //         .then((res) => {
  //           if (res.success) {
  //             this.setState({ noOfPodList: res.data });
  //           } else {
  //           }
  //         })
  //         .catch((err) => {
  //           // // console.log(err)
  //         });
  //     } catch (err) {}
  //   };

  //   getDayTypeList = () => {
  //     let data = {
  //       StringMapType: "DAYTYPE",
  //     };
  //     try {
  //       api
  //         .post("utility/getStringMap", data)
  //         .then((res) => {
  //           if (res.success) {
  //             this.setState({ dayTypeList: res.data });
  //           } else {
  //           }
  //         })
  //         .catch((err) => {
  //           // // console.log(err)
  //         });
  //     } catch (err) {}
  //   };

  getNoOfUsersList = () => {
    let data = {
      StringMapType: "NOOFUSERS",
    };
    try {
      api
        .post("utility/getStringMap", data)
        .then((res) => {
          if (res.success) {
            this.setState({ noOfUsersList: res.data });
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  };

  getInternetTypeList = () => {
    let data = {
      StringMapType: "INTERNETTYPE",
    };
    try {
      api
        .post("utility/getStringMap", data)
        .then((res) => {
          if (res.success) {
            this.setState({ internetTypeList: res.data });
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  };

  //   getWorkingDaysList = () => {
  //     let data = {
  //       StringMapType: "WEEKDAY",
  //     };
  //     try {
  //       api
  //         .post("utility/getStringMap", data)
  //         .then((res) => {
  //           if (res.success) {
  //             this.setState({ workingDaysList: res.data });
  //           } else {
  //           }
  //         })
  //         .catch((err) => {
  //           // // console.log(err)
  //         });
  //     } catch (err) {}
  //   };

  getNoOfWashRoomsList = () => {
    let data = {
      StringMapType: "NOOFWASHROOMS",
    };
    try {
      api
        .post("utility/getStringMap", data)
        .then((res) => {
          if (res.success) {
            this.setState({ noOfWashRoomsList: res.data });
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  };

  getPodTypeList = () => {
    let data = {
      StringMapType: "PODTYPE",
    };
    try {
      api
        .post("utility/getStringMap", data)
        .then((res) => {
          if (res.success) {
            this.setState({ podTypeList: res.data });
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  };

  getTimeSlotList = () => {
    let data = {
      StringMapType: "TIMESLOT",
    };
    try {
      api
        .post("utility/getStringMap", data)
        .then((res) => {
          if (res.success) {
            this.setState({ timeSlotList: res.data });
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  };

  getAmenitiesList = () => {
    let data = {
      FeatureType: "AMENITIES",
    };
    try {
      api
        .post("property/getAmenitiesList", data)
        .then((res) => {
          if (res.success) {
            this.setState({ amenitiesList: res.data });
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  };

  getAmenitiesRulesList = () => {
    let data = {
      FeatureType: "RULES",
    };
    try {
      api
        .post("property/getAmenitiesList", data)
        .then((res) => {
          if (res.success) {
            this.setState({ amenitiesRulesList: res.data });
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  };

  getStandardAmenitiesList = () => {
    let data = {
      FeatureType: "STANDARDAMENITIES",
    };
    try {
      api
        .post("property/getAmenitiesList", data)
        .then((res) => {
          if (res.success) {
            this.setState({ standardAmenitiesList: res.data });
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  };

  //   handleChangePlaces = (address, type) => {
  //     if (type == "Street") {
  //       if (CommonConfig.isEmpty(address)) {
  //         this.setState({
  //           Street: address,
  //           StreetErr: true,
  //           StreethelperText: "Please select street for your space.",
  //         });
  //       } else {
  //         this.setState({
  //           Street: address,
  //           StreetErr: false,
  //           StreethelperText: "",
  //         });
  //       }
  //     } else {
  //       if (CommonConfig.isEmpty(address)) {
  //         this.setState({
  //           PropertyAddress: address,
  //           PropertyAddressErr: true,
  //           PropertyAddresshelperText: "Please select location for your space.",
  //         });
  //       } else {
  //         this.setState({
  //           PropertyAddress: address,
  //           PropertyAddressErr: false,
  //           PropertyAddresshelperText: "",
  //         });
  //       }
  //     }
  //   };

  //   handleSelect = (address, type) => {
  //     if (type == "Street") {
  //       if (CommonConfig.isEmpty(address)) {
  //         this.setState({
  //           Street: address,
  //           StreetErr: true,
  //           StreethelperText: "Please select street for your space.",
  //         });
  //       } else {
  //         this.setState({
  //           Street: address,
  //           StreetErr: false,
  //           StreethelperText: "",
  //         });
  //       }
  //       geocodeByAddress(address)
  //         .then((results) => {
  //           // find country name
  //           for (var i = 0; i < results[0].address_components.length; i++) {
  //             for (
  //               var b = 0;
  //               b < results[0].address_components[i].types.length;
  //               b++
  //             ) {
  //               //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
  //               if (results[0].address_components[i].types[b] == "route") {
  //                 //this is the object you are looking for
  //                 this.setState({
  //                   Street: results[0].address_components[i].long_name,
  //                 });
  //                 break;
  //               }
  //               if (
  //                 results[0].address_components[i].types[b] == "postal_town" ||
  //                 results[0].address_components[i].types[b] ==
  //                   "administrative_area_level_2"
  //               ) {
  //                 //this is the object you are looking for
  //                 this.setState({
  //                   City: results[0].address_components[i].long_name,
  //                 });
  //                 break;
  //               }
  //               if (
  //                 results[0].address_components[i].types[b] ==
  //                 "administrative_area_level_1"
  //               ) {
  //                 //this is the object you are looking for
  //                 this.setState({
  //                   Country: results[0].address_components[i].long_name,
  //                 });
  //                 break;
  //               }
  //               if (results[0].address_components[i].types[b] == "postal_code") {
  //                 //this is the object you are looking for
  //                 this.setState({
  //                   Postcode: results[0].address_components[i].long_name,
  //                 });
  //                 break;
  //               }
  //             }
  //           }
  //           return getLatLng(results[0]);
  //         })
  //         .then((latLng) => {
  //           this.setState({ Latitude: latLng.lat, Longitude: latLng.lng });
  //         })
  //         .catch((error) => {
  //           /*console.error('Error', error)*/
  //         });
  //     } else {
  //       if (CommonConfig.isEmpty(address)) {
  //         this.setState({
  //           PropertyAddress: address,
  //           PropertyAddressErr: true,
  //           PropertyAddresshelperText: "Please select location for your space.",
  //         });
  //       } else {
  //         this.setState({
  //           PropertyAddress: address,
  //           PropertyAddressErr: false,
  //           PropertyAddresshelperText: "",
  //         });
  //       }
  //       geocodeByAddress(address)
  //         .then((results) => {
  //           // find country name
  //           for (var i = 0; i < results[0].address_components.length; i++) {
  //             for (
  //               var b = 0;
  //               b < results[0].address_components[i].types.length;
  //               b++
  //             ) {
  //               //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
  //               if (results[0].address_components[i].types[b] == "route") {
  //                 //this is the object you are looking for
  //                 this.setState({
  //                   Street: results[0].address_components[i].long_name,
  //                 });
  //                 break;
  //               }
  //               if (
  //                 results[0].address_components[i].types[b] == "postal_town" ||
  //                 results[0].address_components[i].types[b] ==
  //                   "administrative_area_level_2"
  //               ) {
  //                 //this is the object you are looking for
  //                 this.setState({
  //                   City: results[0].address_components[i].long_name,
  //                 });
  //                 break;
  //               }
  //               if (
  //                 results[0].address_components[i].types[b] ==
  //                 "administrative_area_level_1"
  //               ) {
  //                 //this is the object you are looking for
  //                 this.setState({
  //                   Country: results[0].address_components[i].long_name,
  //                 });
  //                 break;
  //               }
  //               if (results[0].address_components[i].types[b] == "postal_code") {
  //                 //this is the object you are looking for
  //                 this.setState({
  //                   Postcode: results[0].address_components[i].long_name,
  //                 });
  //                 break;
  //               }
  //             }
  //           }
  //           return getLatLng(results[0]);
  //         })
  //         .then((latLng) => {
  //           this.setState({ Latitude: latLng.lat, Longitude: latLng.lng });
  //         })
  //         .catch((error) => {
  //           /*console.error('Error', error)*/
  //         });
  //     }
  //   };

  codeLatLng = (lat, lng) => {
    const google = window.google;
    var geocoder = new google.maps.Geocoder();
    var latlng = new google.maps.LatLng(lat, lng);
    this.setState({ Latitude: lat, Longitude: lng });
    geocoder.geocode({ latLng: latlng }, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        //formatted address

        for (var i = 0; i < results[0].address_components.length; i++) {
          for (
            var b = 0;
            b < results[0].address_components[i].types.length;
            b++
          ) {
            //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
            if (results[0].address_components[i].types[b] == "route") {
              //this is the object you are looking for
              this.setState({
                Street: results[0].address_components[i].long_name,
              });
              break;
            }
            if (
              results[0].address_components[i].types[b] == "postal_town" ||
              results[0].address_components[i].types[b] ==
              "administrative_area_level_2"
            ) {
              //this is the object you are looking for
              this.setState({
                City: results[0].address_components[i].long_name,
              });
              break;
            }
            if (
              results[0].address_components[i].types[b] ==
              "administrative_area_level_1"
            ) {
              //this is the object you are looking for
              this.setState({
                Country: results[0].address_components[i].long_name,
              });
              break;
            }
            if (results[0].address_components[i].types[b] == "postal_code") {
              //this is the object you are looking for
              this.setState({
                Postcode: results[0].address_components[i].long_name,
              });
              break;
            }
          }
        }
      } else {
        // alert("Geocoder failed due to: " + status);
      }
    });
  };

  handleChange = (e, type, idx) => {
    //     if (type == "PODTYPE") {
    //       if (CommonConfig.isEmpty(e.target.value)) {
    //         this.setState({
    //           PodType: e.target.value,
    //           PodTypeErr: true,
    //           PodTypehelperText: "Please select pod type.",
    //         });
    //       } else {
    //         this.setState({
    //           PodType: e.target.value,
    //           PodTypeErr: false,
    //           PodTypehelperText: "",
    //         });
    //       }
    //     }
    //     if (type == "NOOFPODS") {
    //       if (CommonConfig.isEmpty(e.target.value)) {
    //         this.setState({
    //           NoOfPods: e.target.value,
    //           NoOfPodsErr: true,
    //           NoOfPodshelperText: "Please select number of pod.",
    //         });
    //       } else {
    //         this.setState({
    //           NoOfPods: e.target.value,
    //           NoOfPodsErr: false,
    //           NoOfPodshelperText: "",
    //         });
    //       }
    //     }
    //     if (type == "UsersCount") {
    //       if (CommonConfig.isEmpty(e.target.value)) {
    //         this.setState({
    //           UsersCount: e.target.value,
    //           UsersErr: true,
    //           UsershelperText: "Please enter user count.",
    //         });
    //       } else {
    //         this.setState({
    //           UsersCount: e.target.value,
    //           UsersErr: false,
    //           UsershelperText: "",
    //         });
    //       }
    //     }
    //     if (type == "NOOFUSERS") {
    //       if (CommonConfig.isEmpty(e.target.value)) {
    //         this.setState({
    //           Users: e.target.value,
    //           UsersErr: true,
    //           UsershelperText: "Please select no of users.",
    //         });
    //       } else {
    //         this.setState({
    //           Users: e.target.value,
    //           UsersErr: false,
    //           UsershelperText: "",
    //         });
    //       }
    //     }
    //     if (type == "NOOFWASHROOMS") {
    //       if (CommonConfig.isEmpty(e.target.value)) {
    //         this.setState({
    //           WashRoom: e.target.value,
    //           WashRoomErr: true,
    //           WashRoomhelperText: "Please select no of washroom.",
    //         });
    //       } else {
    //         this.setState({
    //           WashRoom: e.target.value,
    //           WashRoomErr: false,
    //           WashRoomhelperText: "",
    //         });
    //       }
    //     }
    //     if (type == "INTERNETTYPE") {
    //       if (CommonConfig.isEmpty(e.target.value)) {
    //         this.setState({
    //           InternetType: e.target.value,
    //           InternetTypeErr: true,
    //           InternetTypehelperText: "Please select Internet type.",
    //         });
    //       } else {
    //         this.setState({
    //           InternetType: e.target.value,
    //           InternetTypeErr: false,
    //           InternetTypehelperText: "",
    //         });
    //       }
    //     }
    //     if (type == "StudentDiscount") {
    //       if (CommonConfig.isEmpty(e.target.value)) {
    //         this.setState({
    //           StudentDiscount: e.target.value,
    //           StudentDiscountErr: true,
    //           StudentDiscounthelperText: "Please enter pod type.",
    //         });
    //       } else {
    //         this.setState({
    //           StudentDiscount: e.target.value,
    //           StudentDiscountErr: false,
    //           StudentDiscounthelperText: "",
    //         });
    //       }
    //     }
    //     if (type == "SeasonalDiscount") {
    //       if (CommonConfig.isEmpty(e.target.value)) {
    //         this.setState({
    //           SeasonalDiscount: e.target.value,
    //           SeasonalDiscountErr: true,
    //           SeasonalDiscounthelperText: "Please enter seasonal discount.",
    //         });
    //       } else {
    //         this.setState({
    //           SeasonalDiscount: e.target.value,
    //           SeasonalDiscountErr: false,
    //           SeasonalDiscounthelperText: "",
    //         });
    //       }
    //     }
    //     if (type == "FLAT") {
    //       this.setState({ Flat: e.target.value });
    //     }
    //     if (type == "CITY") {
    //       if (CommonConfig.isEmpty(e.target.value)) {
    //         this.setState({
    //           City: e.target.value,
    //           CityErr: true,
    //           CityhelperText: "Please enter city.",
    //         });
    //       } else {
    //         this.setState({
    //           City: e.target.value,
    //           CityErr: false,
    //           CityhelperText: "",
    //         });
    //       }
    //     }
    //     if (type == "COUNTRY") {
    //       if (CommonConfig.isEmpty(e.target.value)) {
    //         this.setState({
    //           Country: e.target.value,
    //           CountryErr: true,
    //           CountryhelperText: "Please enter country.",
    //         });
    //       } else {
    //         this.setState({
    //           Country: e.target.value,
    //           CountryErr: false,
    //           CountryhelperText: "",
    //         });
    //       }
    //     }
    //     if (type == "POSTCODE") {
    //       if (CommonConfig.isEmpty(e.target.value)) {
    //         this.setState({
    //           Postcode: e.target.value,
    //           PostcodeErr: true,
    //           PostcodehelperText: "Please enter postcode.",
    //         });
    //       } else {
    //         this.setState({
    //           Postcode: e.target.value,
    //           PostcodeErr: false,
    //           PostcodehelperText: "",
    //         });
    //       }
    //     }
    //     if (type === "Amenities") {
    //       if (this.state.Amenities.includes(e.FeatureID)) {
    //         var array = [...this.state.Amenities];
    //         var index = array.indexOf(e.FeatureID);
    //         if (index !== -1) {
    //           array.splice(index, 1);
    //           this.setState({ Amenities: array });
    //         }
    //       } else {
    //         this.setState({ Amenities: [...this.state.Amenities, e.FeatureID] });
    //       }
    //     }
    //     if (type === "Rules") {
    //       if (
    //         this.state.Rules.filter((x) => x.FeatureID == e.FeatureID).length > 0
    //       ) {
    //         var array = [...this.state.Rules];
    //         var index = array.findIndex((x) => x.FeatureID == e.FeatureID);
    //         if (index !== -1) {
    //           array.splice(index, 1);
    //           this.setState({ Rules: array });
    //         }
    //       } else {
    //         var data = {
    //           Rule: e.FeatureName,
    //           FeatureID: e.FeatureID,
    //         };
    //         this.setState({ Rules: [...this.state.Rules, data] });
    //       }
    //     }
    //     if (type === "AdditionalRules") {
    //       var data = {
    //         Rule: this.state.AdditionalRule,
    //         FeatureID: "",
    //       };
    //       this.setState({ Rules: [...this.state.Rules, data], AdditionalRule: "" });
    //       // }
    //     }
    //     if (type == "IsEmailAccessibility") {
    //       this.setState({ IsEmailAccesibility: e.target.checked });
    //     }
    //     if (type == "Price") {
    //       const mainDateArray = [...this.state.mainDateArray];
    //       mainDateArray[idx.mainidx].Time[idx.childidx]["Price"] = e.target.value;
    //       this.setState({ mainDateArray: mainDateArray });
    //     }
    //     if (type == "FullDayPrice") {
    //       this.setState({ Price: e.target.value });
    //     }
    //     if (type == "PROPERTYNAME") {
    //       if (CommonConfig.isEmpty(e.target.value)) {
    //         this.setState({
    //           PropertyName: e.target.value,
    //           PropertyNameErr: true,
    //           PropertyNamehelperText: "Please enter title for your pod.",
    //         });
    //       } else {
    //         this.setState({
    //           PropertyName: e.target.value,
    //           PropertyNameErr: false,
    //           PropertyNamehelperText: "",
    //         });
    //       }
    //     }
    //     if (type == "PROPERTYDESCRIPTION") {
    //       if (CommonConfig.isEmpty(e.target.value)) {
    //         this.setState({
    //           PropertyDescription: e.target.value,
    //           PropertyDescriptionErr: true,
    //           PropertyDescriptionhelperText:
    //             "Please enter description for your space.",
    //         });
    //       } else {
    //         this.setState({
    //           PropertyDescription: e.target.value,
    //           PropertyDescriptionErr: false,
    //           PropertyDescriptionhelperText: "",
    //         });
    //       }
    //     }
    if (type == "Reason") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          Reason: e.target.value,
          ReasonErr: true,
          ReasonHelperText: "Please enter the reason to decline",
        });
      } else {
        this.setState({
          Reason: e.target.value,
          ReasonErr: false,
          ReasonHelperText: "",
        });
      }
    }
    //     if (type == "TIMESLOT") {
    //       this.setState({ Time: e });
    //     }
    //     if (type == "PropertyMedia") {
    //       var file = e.target.files;
    //       var imgSrc = [];
    //       var media = [];
    //       for (let i = 0; i < e.target.files.length; i++) {
    //         media.push({
    //           FileName:
    //             moment().format("DD_MM_YYYY") + "_" + e.target.files[i].name,
    //           MediaType: e.target.files[i].type,
    //           DateTime: moment().format("DD-MM-YYYY"),
    //         });
    //         var reader = new FileReader();
    //         var url = reader.readAsDataURL(e.target.files[i]);
    //         reader.onloadend = function (e) {
    //           imgSrc.push({
    //             imgSrc: [reader.result],
    //           });
    //         }.bind(this);
    //       }
    //       this.setState({
    //         PropertyMedia: media,
    //         Media: file,
    //         PreviewMedia: imgSrc,
    //       });
    //     }
    //     if (type === "date") {
    //       this.setState({
    //         selectedDateRange: e,
    //       });
    //     }
    //     if (type == "newDate") {
    //       this.setState({ singleDate: e });
    //     }
    //     if (type == "newRangeDate") {
    //       this.setState({ rangeDate: e });
    //     }
    //     if (type === "singleDate") {
    //       this.setState({ singleDate: e });
    //     }
    //     if (type === "day") {
    //       this.setState({ day: e });
    //     }
    //     if (type === "WorkingDays") {
    //       if (this.state.WorkingDays.includes(e.StringMapID)) {
    //         var array = [...this.state.WorkingDays];
    //         var index = array.indexOf(e.StringMapID);
    //         if (index !== -1) {
    //           array.splice(index, 1);
    //           this.setState({ WorkingDays: array });
    //         }
    //       } else {
    //         this.setState({
    //           WorkingDays: [...this.state.WorkingDays, e.StringMapID],
    //         });
    //       }
    //     }
    //     if (type === "MainDateTime") {
    //       if (
    //         this.state.mainDateArray[idx].Time.filter(
    //           (x) => x.StringMapID === e.StringMapID
    //         ).length > 0
    //       ) {
    //         var array = [...this.state.mainDateArray];
    //         var index = array[idx].Time.findIndex(
    //           (x) => x.StringMapID === e.StringMapID
    //         );
    //         if (index !== -1) {
    //           array[idx].Time.splice(index, 1);
    //           this.setState({ mainDateArray: array });
    //         }
    //       } else {
    //         var array = [...this.state.mainDateArray];
    //         array[idx].Time.push({
    //           StringMapID: e.StringMapID,
    //           Price: "",
    //         });
    //         this.setState({ mainDateArray: [...array] });
    //       }
    //     }
  };

  addDates = () => {
    var singleDay = [];
    var multiDay = [];
    
    if (this.state.singleDate.length > 0 || this.state.rangeDate.length > 0) {
      if (this.state.singleDate.length > 0) {
        singleDay = this.state.singleDate.map((x, idx) => {
          return {
            day: "Single Day",
            StartDate: new Date(this.state.singleDate[idx]),
            EndDate: new Date(this.state.singleDate[idx]),
            Time: [],
          };
        });
        var temp = [];
        for (let i = 0; i < singleDay.length; i++) {
          for (let j = 0; j < this.state.mainDateArray.length; j++) {
            if (
              moment(this.state.mainDateArray[j].StartDate).format(
                "DD-MM-YYYY"
              ) == moment(singleDay[i].StartDate).format("DD-MM-YYYY")
            ) {
              temp.push(
                moment(this.state.mainDateArray[j].StartDate).format(
                  "DD-MM-YYYY"
                )
              );
            }
          }
        }
        if (temp.length > 0) {
          cogoToast.error(
            "Some dates are already added please try with different dates"
          );
        } else {
          this.setState({
            mainDateArray: this.state.mainDateArray.concat(singleDay),
            singleDate: [],
          });
        }
      }
      if (this.state.rangeDate.length > 0) {
        multiDay = {
          day: "Multi Day",
          StartDate: new Date(this.state.rangeDate[0]),
          EndDate: new Date(this.state.rangeDate[1]),
          Time: [],
        };
        var temp = [];
        for (let i = 0; i < singleDay.length; i++) {
          for (let j = 0; j < this.state.mainDateArray.length; j++) {
            if (
              moment(this.state.mainDateArray[j].StartDate).format(
                "DD-MM-YYYY"
              ) == moment(singleDay[i].StartDate).format("DD-MM-YYYY")
            ) {
              temp.push(
                moment(this.state.mainDateArray[j].StartDate).format(
                  "DD-MM-YYYY"
                )
              );
            } else if (
              moment(this.state.mainDateArray[j].EndDate).format(
                "DD-MM-YYYY"
              ) == moment(singleDay[i].EndDate).format("DD-MM-YYYY")
            ) {
              temp.push(
                moment(this.state.mainDateArray[j].EndDate).format("DD-MM-YYYY")
              );
            }
          }
        }
        if (temp.length > 0) {
          cogoToast.error(
            "Some dates are already added please try with different dates"
          );
        } else {
          this.setState({
            mainDateArray: this.state.mainDateArray.concat(multiDay),
            rangeDate: [],
          });
        }
      }
      this.setState({ PropertyDateErr: false, PropertyDatehelperText: "" });
    } else {
      this.setState({
        PropertyDateErr: true,
        PropertyDatehelperText: "Please add date for your listing.",
      });
    }
  };

  addSubtractCount = (type) => {
    if (type == "subtract") {
      this.setState({ Users: this.state.Users - 1 });
    } else if (type == "subtractWashRoom") {
      this.setState({ WashRoom: this.state.WashRoom - 1 });
    } else if (type == "addWashRoom") {
      this.setState({ WashRoom: this.state.WashRoom + 1 });
    } else {
      this.setState({ Users: this.state.Users + 1 });
    }
  };

  distanceToMouse = (pt, mp) => {
    if (pt && mp) {
      return Math.sqrt(
        (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
      );
    }
  };

  loadMap = (map, maps, IsDraggable) => {
    const google = window.google;
    let marker = new maps.Marker({
      position: { lat: this.state.Latitude, lng: this.state.Longitude },
      map,
      draggable: IsDraggable,
    });
  };

  //   submit = () => {
  //     var media = [];
  //     for (let i = 0; i < this.state.files.length; i++) {
  //       media.push({
  //         Index: this.state.files[i].Index,
  //         FileName:
  //           moment().format("DD_MM_YYYY") + "_" + this.state.files[i].name,
  //         MediaType: this.state.files[i].type,
  //         DateTime: moment().format("DD-MM-YYYY"),
  //       });
  //     }

  //     var formData = new FormData();
  //     formData.append("PropertySchemeCategory", "");
  //     formData.append("PropertySchemeType", "");
  //     formData.append("IsFeatured", 0);
  //     formData.append("IsRent", 0);
  //     formData.append("isForSale", 0);
  //     formData.append("isNew", 0);
  //     formData.append("CreatedBy", this.state.PersonID);
  //     formData.append("PersonID", this.state.PersonID);
  //     formData.append("PropertyName", this.state.PropertyName);
  //     formData.append(
  //       "PropertyFeature",
  //       JSON.stringify(
  //         this.state.Amenities.map((x) => {
  //           return { FeatureID: x };
  //         })
  //       )
  //     );
  //     formData.append(
  //       "PropertyRules",
  //       JSON.stringify(
  //         this.state.Rules.map((x) => {
  //           return { Rule: x.Rule, FeatureID: x.FeatureID };
  //         })
  //       )
  //     );
  //     formData.append(
  //       "PropertySchedule",
  //       JSON.stringify(this.state.mainDateArray)
  //     );
  //     formData.append(
  //       "PropertyAvailability",
  //       JSON.stringify(this.state.workingDaysList)
  //     );
  //     formData.append("Price", this.state.Price);
  //     formData.append("StudentDiscount", this.state.StudentDiscount);
  //     formData.append("SeasonalDiscount", this.state.SeasonalDiscount);
  //     formData.append("InternetType", this.state.InternetType);
  //     formData.append("SlotType", "");
  //     formData.append("AgentName", "");
  //     formData.append("Date", "");
  //     formData.append("TimeStart", "");
  //     formData.append("TimeEnd", "");
  //     formData.append("PropertyAddress", this.state.PropertyAddress);
  //     formData.append("PropertyIndex", this.state.Postcode);
  //     formData.append("PropertyNumber", this.state.Flat);
  //     formData.append("Latitude", this.state.Latitude);
  //     formData.append("Longitude", this.state.Longitude);
  //     formData.append(
  //       "GuestCount",
  //       this.state.Users === "Multi" ? this.state.UsersCount : this.state.Users
  //     );
  //     formData.append("BedroomCount", 0);
  //     formData.append("BathroomCount", this.state.WashRoom);
  //     formData.append("PropertyDescription", this.state.PropertyDescription);
  //     formData.append("IsDedicatedGuestSpace", 0);
  //     formData.append("IsVerifiedPod", 0);
  //     formData.append("PropertyListingFrom", "");
  //     formData.append("PropertyListingTo", "");
  //     formData.append("EntityType", this.state.PodType);
  //     formData.append("PropertyMedia", JSON.stringify(media));
  //     for (let i = 0; i < this.state.files.length; i++) {
  //       formData.append("Media", this.state.files[i]);
  //     }

  //     try {
  //       api
  //         .post("property/addoreditordeleteorinactiveproperty", formData)
  //         .then((res) => {
  //           if (res.success) {
  //             cogoToast.success("Pod added successfully");
  //             this.props.history.push("/admin/podlisting");
  //           } else {
  //           }
  //         })
  //         .catch((err) => {
  //           // // console.log(err)
  //         });
  //     } catch (err) {}
  //   };

  approve = () => {
    let data = {
      Status: "Active",
      Reason: "",
      PropertyID: this.state.List.PropertyID,
      Type: ["Admin Activated Pod","Host Activated Pod"]
      // FirstName: this.state.List.HostName,
      // PropertyName: this.state.List.PropertyName,
      // HostEmail: this.state.List.HostEmailId,
      // AdminEmail: this.state.List.AdminEmail
    };

    try {
      api
        .post("property/updatePropertyStatus", data)
        .then((res) => {
          if (res.success) {
            this.setState({ statusRes: res.data });
            this.props.history.push('/admin/podlisting')
            cogoToast.success("Pod approved")
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  };

  reject = () => {
    this.setState({
      isReject: true,
      isRejectButtonNotClicked: false
    });

  }

  rejectPod = () => {

    if (CommonConfig.isEmpty(this.state.Reason)) {
      // IsValid = false;
      this.setState({
        ReasonErr: true,
        ReasonHelperText: "Please enter reason to decline.",
      });
    }

    let data = {

      PropertyID: this.state.List.PropertyID,
      Status: "Declined",
      Reason: this.state.Reason,
      Type: ["Admin Declined Pod","Host Declined Pod"]
      // FirstName: this.state.List.HostName,
      // PropertyName: this.state.List.PropertyName,
      // HostEmail: this.state.List.HostEmailId,
      // AdminEmail: this.state.List.AdminEmail
    };

    // console.log("dataaaaaaa..", data)
    if (!CommonConfig.isEmpty(this.state.Reason)) {
      try {
        api
          .post("property/updatePropertyStatus", data)
          .then((res) => {
            if (res.success) {
              this.setState({ statusRes: res.data });
              this.props.history.push('/admin/podlisting')
              cogoToast.success("Pod Declined");
            } else {
            }
          })
          .catch((err) => {
            // // console.log(err)
          });
      } catch (err) { }
    }
  };

  moveMarker = (props, marker, e) => {
    this.codeLatLng(e.latLng.lat(), e.latLng.lng());
  };

  getMaxDate = () => {
    var d = new Date();
    d.setDate(d.getDate() + 30);
    return d;
  };

  titleSuggestionList = (inputValue) => {
    // e.preventDefault();

    let data = {
      SearchString: inputValue,
    };
    try {
      api
        .post("property/getTitleSuggestionList", data)
        .then((res) => {
          if (res.success) {
            this.setState({ titleSuggestionList: res.data });
          } else {
          }
        })
        .catch((err) => {
          // // console.log(err)
        });
    } catch (err) { }
  };

  goBack = () => {
    this.props.history.push('/admin/podlisting')
  }

  validate = () => {
    //     const {
    //       Step,
    //       PropertyAddress,
    //       PodType,
    //       PropertyDescription,
    //       PropertyName,
    //       WorkingDays,
    //       Time,
    //       singleDate,
    //       selectedDateRange,
    //       Street,
    //       City,
    //       Country,
    //       Postcode,
    //       Users,
    //       UsersCount,
    //       WashRoom,
    //       Amenities,
    //       Rules,
    //       files,
    //       InternetType,
    //       mainDateArray,
    //     } = this.state.List;
    //     let IsValid = true;
    //     if (Step == 0) {
    //       if (CommonConfig.isEmpty(PropertyAddress)) {
    //         IsValid = false;
    //         this.setState({
    //           PropertyAddressErr: true,
    //           PropertyAddresshelperText: "Please select location for your space.",
    //         });
    //       } else {
    //         this.setState({
    //           PropertyAddressErr: false,
    //           PropertyAddresshelperText: "",
    //         });
    //       }
    //     } else if (Step == 1) {
    //       if (CommonConfig.isEmpty(PropertyAddress)) {
    //         IsValid = false;
    //         this.setState({
    //           PropertyAddressErr: true,
    //           PropertyAddresshelperText: "Please select location for your space.",
    //         });
    //       } else {
    //         this.setState({
    //           PropertyAddressErr: false,
    //           PropertyAddresshelperText: "",
    //         });
    //       }
    //       if (CommonConfig.isEmpty(PodType)) {
    //         IsValid = false;
    //         this.setState({
    //           PodTypeErr: true,
    //           PodTypehelperText: "Please select pod type.",
    //         });
    //       } else {
    //         this.setState({ PodTypeErr: false, PodTypehelperText: "" });
    //       }
    //       if (CommonConfig.isEmpty(PropertyName)) {
    //         IsValid = false;
    //         this.setState({
    //           PropertyNameErr: true,
    //           PropertyNamehelperText: "Please enter title for your pod.",
    //         });
    //       } else {
    //         this.setState({ PropertyNameErr: false, PropertyNamehelperText: "" });
    //       }
    //       if (CommonConfig.isEmpty(PropertyDescription)) {
    //         IsValid = false;
    //         this.setState({
    //           PropertyDescriptionErr: true,
    //           PropertyDescriptionhelperText:
    //             "Please enter description for your space.",
    //         });
    //       } else {
    //         this.setState({
    //           PropertyDescriptionErr: false,
    //           PropertyDescriptionhelperText: "",
    //         });
    //       }

    //       if (!mainDateArray.length > 0) {
    //         IsValid = false;
    //         this.setState({
    //           PropertyDateErr: true,
    //           PropertyDatehelperText: "Please add date for your listing.",
    //         });
    //       } else {
    //         this.setState({ PropertyDateErr: false, PropertyDatehelperText: "" });
    //       }
    //     } else if (Step == 2) {
    //       if (CommonConfig.isEmpty(City)) {
    //         IsValid = false;
    //         this.setState({ CityErr: true, CityhelperText: "Please enter city." });
    //       } else {
    //         this.setState({ CityErr: false, CityhelperText: "" });
    //       }
    //       if (CommonConfig.isEmpty(Country)) {
    //         IsValid = false;
    //         this.setState({
    //           CountryErr: true,
    //           CountryhelperText: "Please enter country.",
    //         });
    //       } else {
    //         this.setState({ CountryErr: false, CountryhelperText: "" });
    //       }
    //       if (CommonConfig.isEmpty(Street)) {
    //         IsValid = false;
    //         this.setState({
    //           StreetErr: true,
    //           StreethelperText: "Please enter street.",
    //         });
    //       } else {
    //         this.setState({ StreetErr: false, StreethelperText: "" });
    //       }
    //       if (CommonConfig.isEmpty(Postcode)) {
    //         IsValid = false;
    //         this.setState({
    //           PostcodeErr: true,
    //           PostcodehelperText: "Please enter postcode.",
    //         });
    //       } else {
    //         this.setState({ PostcodeErr: false, PostcodehelperText: "" });
    //       }
    //     } else if (Step == 3) {
    //       if (CommonConfig.isEmpty(Users)) {
    //         IsValid = false;
    //         this.setState({
    //           UsersErr: true,
    //           UsershelperText: "Please select no of users.",
    //         });
    //       } else {
    //         if (Users == "Multi" && CommonConfig.isEmpty(UsersCount)) {
    //           this.setState({
    //             UsersErr: true,
    //             UsershelperText: "Please enter user count.",
    //           });
    //         } else {
    //           this.setState({ UsersErr: false, UsershelperText: "" });
    //         }
    //       }
    //       if (CommonConfig.isEmpty(InternetType)) {
    //         IsValid = false;
    //         this.setState({
    //           InternetTypeErr: true,
    //           InternetTypehelperText: "Please select your internet type.",
    //         });
    //       } else {
    //         this.setState({ InternetTypeErr: false, InternetTypehelperText: "" });
    //       }
    //       if (CommonConfig.isEmpty(WashRoom)) {
    //         IsValid = false;
    //         this.setState({
    //           WashRoomErr: true,
    //           WashRoomhelperText: "Please enter no of washrooms.",
    //         });
    //       } else {
    //         this.setState({ WashRoomErr: false, WashRoomhelperText: "" });
    //       }
    //     } else if (Step == 4) {
    //       if (!Amenities.length > 0) {
    //         IsValid = false;
    //         cogoToast.error("Please select atleast one amenity to continue.");
    //       }
    //     } else if (Step == 5) {
    //       if (!Rules.length > 0) {
    //         IsValid = false;
    //         cogoToast.error("Please select atleast one rule to continue.");
    //       }
    //     } else if (Step == 6) {
    //       if (!files.length > 0) {
    //         IsValid = false;
    //         cogoToast.error("Please upload atleast one photo to continue.");
    //       }
    //     }
    // if (IsValid) {
    this.setState({
      Step: this.state.Step + 1,
      dynamicProgress: this.state.dynamicProgress + 14.2857123,
    });
    // }
  };

  onDrop = (files) => {
    var newfiles = files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        Index: this.state.files.length + 1,
      })
    );
    var allFiles = this.state.files.concat(newfiles);

    this.setState({
      files: allFiles,
    });
  };

  removeImage = (Index) => {
    var array = [...this.state.files];
    var index = Index;
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  };

  getSteps = () => {
    // console.log("date", this.state.mainDateArray);
    var maxDate = this.getMaxDate();

    const timeSlotListArray = this.state.timeSlotList.map((content) => {
      return { value: content.StringMapID, label: content.StringMapName };
    });

    const {
      Step,
      FirstName,
      podTypeList,
      noOfUsersList,
      dayTypeList,
      noOfWashRoomsList,
      amenitiesList,
      workingDaysList,
      amenitiesRulesList,
      internetTypeList,
      standardAmenitiesList,
    } = this.state;

    if (Step == 0) {
      return (
        <div className="add-place-wrap">
          <div className="container">
            <div className="add-place-content">
              <div className="apc-heading">
                <h2>Qualify for a host?</h2>
              </div>
              <div className="row">
                <div className="col-lg-5 col-md-5">
                  <div className="apc-inner-content">
                    <div className="form-group">
                      <label>Address</label>
                      <PlacesAutocomplete
                        value={this.state.List.PropertyAddress}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className="landing-main-search">
                            <input
                              {...getInputProps({
                                placeholder: "Pod Address*",
                                disabled: true,
                                className: [
                                  //   this.state.PropertyAddressErr
                                  // ?
                                  //  "form-control location-search-input is-invalid"
                                  // :
                                  "form-control location-search-input",
                                ],
                              })}
                            />
                            {/* <div className="invalid-feedback">
                              {this.state.PropertyAddresshelperText}
                            </div> */}
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";

                                const style = suggestion.active
                                  ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                  : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };

                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>
                                      <i className="fas fa-map-marker-alt"></i>
                                      {suggestion.description}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                    <h4>Mandatory Amenities</h4>
                    <div className="checkbox-list-tag">
                      <div className="row">
                        {standardAmenitiesList.map((x) => {
                          return (
                            <div className="col-lg-6 col-md-6">
                              <div class="filter-check">
                                <label>
                                  <input
                                    type="checkbox"
                                    checked="checked"
                                    disabled
                                  ></input>
                                  <span>{x.FeatureName}</span>
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div class="covid-info mt-20">
                      <p>
                        Due to COVID-19, there are lockdowns in place across the
                        UK. Travel is not permitted other than in limited
                        circumstances.<a href="javascript:;">Learn more</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="apc-footer">
                <a
                  href="javascript:;"
                  onClick={() => this.validate()}
                  className="btn btn-primary"
                >
                  Next
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (Step == 1) {
      return (
        <div className="add-place-wrap">
          <div className="container">
            <div className="add-place-inner">
              <div className="add-place-content">
                <div className="apc-heading">
                  <h2>Hi, Admin Let's view the space.</h2>
                </div>
                <div className="apc-inner-content">
                  <div className="row">
                    <div className="col-lg-5 col-md-5">
                      <span>STEP 1</span>
                      <h4>Where's your space located?</h4>
                      <div className="form-group">
                        <PlacesAutocomplete
                          value={this.state.List.PropertyAddress}
                        //   onChange={(e) =>
                        //     this.handleChangePlaces(e, "PropertyAddress")
                        //   }
                        //   onSelect={(e) =>
                        //     this.handleSelect(e, "PropertyAddress")
                        //   }
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div className="landing-main-search">
                              <input
                                {...getInputProps({
                                  placeholder: "Pod Address*",
                                  disabled: true,
                                  className: [
                                    // this.state.PropertyAddressErr
                                    //   ? "form-control mb-10 location-search-input is-invalid"
                                    //   : 
                                    "form-control mb-10 location-search-input",
                                  ],
                                })}
                              />
                              {/* <div className="invalid-feedback">
                                {this.state.PropertyAddresshelperText}
                              </div> */}
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                    : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };

                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>
                                        <i className="fas fa-map-marker-alt"></i>
                                        {suggestion.description}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>

                        {this.state.List.PropertyAddress ? (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Flat, Suite (optional)"
                            value={this.state.List.PropertyNumber}
                            disabled={true}
                          // onChange={(e) => this.handleChange(e, "FLAT")}
                          />
                        ) : null}
                      </div>
                      <div className="apc-heading">
                        <h3>What kind of pod are you listing?</h3>
                      </div>
                      <div className="form-group">
                        <label>Choose a pod type</label>
                        <select
                          disabled={true}
                          className={[
                            // this.state.PodTypeErr
                            //   ? "form-control is-invalid"
                            //   :
                            "form-control",
                          ]}
                          value={this.state.List.PropertyMedia[0].EntityType}
                        //   onChange={(e) => this.handleChange(e, "PODTYPE")}
                        >
                          {/* <option value="" selected disabled hidden>
                            Select one
                          </option> */}
                          {podTypeList.map((x) => {
                            return (
                              <option value={x.StringMapName}>
                                {x.StringMapName}
                              </option>
                            );
                          })}
                        </select>
                        {/* <div className="invalid-feedback">
                          {this.state.PodTypehelperText}
                        </div> */}
                      </div>
                      <div className="apc-heading">
                        <h3>Create a title for your listing</h3>
                        <span>
                          Catch guests’ attention with a listing title that
                          highlights what makes your pod special.
                        </span>
                      </div>
                      <div className="form-group">
                        <Select
                          disabled={true}
                          className={[
                            !this.state.titleSuggestion.value ==
                              "Customize your title" &&
                              this.state.PropertyNameErr
                              ? "form-control mb-10 is-invalid"
                              : "mb-10",
                          ]}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          //   placeholder={!this.state.titleSuggestion.value ==
                          //     "Customize your title"?this.state.List.PropertyName:"Customize your title"}
                          value={this.state.List.PropertyName}
                          placeholder={this.state.listOfTitles.includes(this.state.PropertyName) ? this.state.List.PropertyName : "Customize your title"}
                          options={this.state.titleSuggestionList}
                        // onChange={(e) => {
                        //     if (e.value == "Customize your title") {
                        //       this.setState({
                        //         titleSuggestion: e,
                        //         PropertyName: "",
                        //       });
                        //     } else {
                        //       this.setState({
                        //         titleSuggestion: e,
                        //         PropertyName: e.label,
                        //         PropertyNameErr: false,
                        //         PropertyNamehelperText: "",
                        //       });
                        //     }
                        //   }}
                        />
                        {this.state.titleSuggestion &&
                          this.state.titleSuggestion.value ==
                          "Customize your title" ? (
                          <input
                            disabled={true}
                            type="text"
                            className={[
                              this.state.titleSuggestion.value ==
                              "Customize your title" &&
                              //   this.state.PropertyNameErr
                              //     ? "form-control mb-10 is-invalid"
                              // : 
                              "form-control mb-10",
                            ]}
                            placeholder="Pod Title"
                            value={this.state.List.PropertyName}
                          // onChange={(e) =>
                          //   this.handleChange(e, "PROPERTYNAME")
                          // }
                          />
                        ) : null}
                        {/* <div className="invalid-feedback d-block">
                          {this.state.PropertyNamehelperText}
                        </div> */}
                      </div>
                      <div className="apc-heading">
                        <h3>Describe your pod to guests</h3>
                        <span>
                          Mention the best features of your space, any special
                          amenities like fast wifi or parking, and what you love
                          about the neighbourhood.
                        </span>
                      </div>
                      <div className="form-group">
                        <textarea
                          className={[
                            // this.state.PropertyDescriptionErr
                            //   ? "form-control mb-10 is-invalid"
                            //   : 
                            "form-control mb-10",
                          ]}
                          rows="3"
                          disabled={true}
                          maxLength="250"
                          placeholder="e.g. Comfortable pod located in a safe area with all basic amenities located within walkable distance"
                          value={this.state.List.PropertyDescription}
                        //   onChange={(e) =>
                        //     this.handleChange(e, "PROPERTYDESCRIPTION")
                        //   }
                        ></textarea>
                        {/* <div className="invalid-feedback">
                          {this.state.PropertyDescriptionhelperText}
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-5 col-md-5">
                      <div className="apc-heading">
                        <h3>Choose a available date for your listing</h3>
                      </div>
                      <div className="form-group">
                        <label>Choose a date</label>
                        <div className="row">
                          <div className="col-lg-8 col-md-8">
                            <DatePicker
                              inputClass={[
                                this.state.PropertyDateErr
                                  ? "form-control is-invalid"
                                  : "form-control",
                              ]}
                              value={
                                this.state.day === "Single Day"
                                  ? this.state.singleDate
                                  : this.state.rangeDate
                              }
                              onChange={(e) =>
                                this.state.day === "Single Day"
                                  ? this.handleChange(e, "newDate")
                                  : this.handleChange(e, "newRangeDate")
                              }
                              multiple
                              range={
                                this.state.day === "Multi Day" ? true : false
                              }
                              numberOfMonths={
                                this.state.day === "Multi Day" ? 2 : 1
                              }
                              plugins={[
                                <DatePanel sort="date" />,
                                <div className="rmdp-toolbar" position="bottom">
                                  {dayTypeList.map((x) => {
                                    return (
                                      <div
                                        onClick={() =>
                                          this.handleChange(
                                            x.StringMapName,
                                            "day"
                                          )
                                        }
                                      >
                                        {x.StringMapName}
                                      </div>
                                    );
                                  })}
                                </div>,
                              ]}
                            />
                            <div className="invalid-feedback d-block">
                              {this.state.PropertyDatehelperText}
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4">
                            <a
                              className="btn btn-primary"
                              href="javascript:;"
                              onClick={() => this.addDates()}
                            >
                              Add
                            </a>
                          </div>
                        </div>

                        <span className="input-tagline">
                          We only provide 30 days listing in advance.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-8 col-md-8">
                      <div className="form-group">
                        <div>
                          {this.state.List.WorkingDays.map((item, indx) => {
                            return (
                              // <>
                              <div className="row mt-3" key={indx}>
                                <div className="col-lg-4 col-md-4 border-0">
                                  <span>{item.WorkingDays}</span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        {this.state.mainDateArray.length > 0
                          ? this.state.mainDateArray.map((x, indx) => {
                            return (
                              <div className="row mt-3" key={indx}>
                                <div className="col-lg-4 col-md-4 border-0">
                                  {x.day !== "Single Day" ? (
                                    <h6>
                                      {moment(x.StartDate).format(
                                        "ddd DD MMMM,YYYY"
                                      )}{" "}
                                        to{" "}
                                      {moment(x.EndDate).format(
                                        "ddd DD MMMM,YYYY"
                                      )}
                                    </h6>
                                  ) : (
                                    <h6>
                                      {moment(x.StartDate).format(
                                        "ddd DD MMMM,YYYY"
                                      )}
                                    </h6>
                                  )}
                                </div>
                                <div className="col-lg-8 col-md-8">
                                  <div className="row">
                                    {this.state.timeSlotList.map(
                                      (content, idx) => {
                                        return (
                                          <div className="col-lg-4 col-md-4">
                                            <div className="filter-check">
                                              <label>
                                                <input
                                                  type="checkbox"
                                                  onClick={(e) =>
                                                    this.handleChange(
                                                      content,
                                                      "MainDateTime",
                                                      indx
                                                    )
                                                  }
                                                  checked={
                                                    x.Time.filter(
                                                      (i) =>
                                                        i.StringMapID ==
                                                        content.StringMapID
                                                    ).length > 0
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                <span>
                                                  {content.StringMapName}
                                                </span>
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                    <a
                                      href="javacript:;"
                                      onClick={() => {
                                        var array = [
                                          ...this.state.mainDateArray,
                                        ];
                                        var index = indx;
                                        if (index !== -1) {
                                          array.splice(index, 1);
                                          this.setState({
                                            mainDateArray: array,
                                          });
                                        }
                                      }}
                                    >
                                      <i className="fas fa-times"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                          : null}
                      </div>
                    </div>
                  </div>

                  <div className="apc-footer">
                    <a
                      href="javascript:;"
                      onClick={() =>
                        this.setState({
                          Step: this.state.Step - 1,
                          dynamicProgress:
                            this.state.dynamicProgress - 14.2857123,
                        })
                      }
                      className="btn"
                    >
                      <i className="fas fa-angle-left"></i> Back
                    </a>
                    <a
                      className="btn btn-primary"
                      href="javascript:;"
                      onClick={() => this.validate()}
                    >
                      Continue
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (Step == 2) {
      return (
        <div className="add-place-wrap">
          <div className="container">
            <div className="add-place-inner">
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-5">
                  <div className="add-place-content">
                    <div className="apc-heading">
                      <h3>Double-check the full address of your space</h3>
                      <p>
                        Users will only get exact address once they've booked a
                        reservation.
                      </p>
                    </div>
                    <div className="apc-inner-content">
                      {/* <button
                        disabled={true}
                        className="btn btn-outline-primary btn-icon use-loc-btn"
                        onClick={() => {
                          navigator.geolocation.getCurrentPosition(
                            (position) => {
                              this.setState({
                                Latitude: parseFloat(
                                  position.coords.latitude
                                ).toFixed(4),
                                Longitude: parseFloat(
                                  position.coords.longitude
                                ).toFixed(4),
                              });
                              this.codeLatLng(
                                parseFloat(position.coords.latitude).toFixed(4),
                                parseFloat(position.coords.longitude).toFixed(4)
                              );
                            }
                          );
                        }}
                      >
                        <i className="fas fa-map-marker-alt"></i> Use current
                        location
                      </button>
                       */}
                      <div className="form-group">
                        <label>Country / Region</label>
                        <select className="form-control">
                          <option>United Kingdom</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label>Street</label>
                        <PlacesAutocomplete
                          value={this.state.List.Street}
                        //   onChange={(e) => this.handleChangePlaces(e, "Street")}
                        //   onSelect={(e) => this.handleSelect(e, "Street")}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div className="landing-main-search">
                              <input
                                {...getInputProps({
                                  placeholder: "Street",
                                  disabled: true,
                                  // className: 'form-control mb-10 location-search-input',
                                  className: [
                                    // this.state.StreetErr
                                    //   ? "form-control mb-10 location-search-input is-invalid"
                                    //   : 
                                    "form-control mb-10 location-search-input",
                                  ],
                                  // className="form-control mb-10"
                                })}
                              />
                              {/* <div className="invalid-feedback">
                                {this.state.StreethelperText}
                              </div> */}
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                    : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>
                                        <i className="fas fa-map-marker-alt"></i>
                                        {suggestion.description}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>

                        <span className="input-tagline">e.g. 20 Deans Yd</span>
                      </div>
                      <div className="form-group">
                        <label>Flat (optional)</label>
                        <input
                          type="text"
                          disabled={true}
                          className="form-control"
                          placeholder="Flat"
                          value={this.state.List.PropertyNumber}
                        //   onChange={(e) => this.handleChange(e, "FLAT")}
                        />
                        <span className="input-tagline">e.g. Apart. 2</span>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>City</label>
                            <input
                              type="text"
                              disabled={true}
                              className={[
                                // this.state.CityErr
                                //   ? "form-control is-invalid"
                                //   : 
                                "form-control",
                              ]}
                              value={this.state.City}
                            //   onChange={(e) => this.handleChange(e, "CITY")}
                            />
                            {/* <div className="invalid-feedback">
                              {this.state.CityhelperText}
                            </div> */}
                            <span className="input-tagline">e.g. London</span>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>Country</label>
                            <input
                              type="text"
                              disabled={true}
                              value={this.state.Country}
                              onChange={(e) => this.handleChange(e, "COUNTRY")}
                              className={[
                                // this.state.CountryErr
                                //   ? "form-control is-invalid"
                                //   : 
                                "form-control",
                              ]}
                            />
                            {/* <div className="invalid-feedback">
                              {this.state.CountryhelperText}
                            </div> */}
                            <span className="input-tagline">
                              e.g. Greater London
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>Postcode</label>
                            <input
                              type="text"
                              disabled={true}
                              value={this.state.Postcode}
                              //   onChange={(e) => this.handleChange(e, "POSTCODE")}
                              className={[
                                // this.state.PostcodeErr
                                //   ? "form-control is-invalid"
                                //   : 
                                "form-control",
                              ]}
                            />
                            {/* <div className="invalid-feedback">
                              {this.state.PostcodehelperText}
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="apc-heading">
                        <h3>Is the pin in the right place?</h3>
                        <p>
                          If needed, you can adjust the map so the pin is in the
                          right location. Only confirmed guests will see this so
                          that they know how to get to your place.
                        </p>
                      </div>
                      <div className="apc-inner-content">
                        <div className="place-map-outer">
                          <p>{this.state.PropertyAddress}</p>
                          <div
                            className="place-map"
                            style={{ height: "400px", width: "100%" }}
                          >
                            <Map
                              google={this.props.google}
                              gestureHandling="greedy"
                              loading="lazy"
                              initialCenter={{
                                lat: this.state.Latitude,
                                lng: this.state.Longitude,
                              }}
                              center={{
                                lat: this.state.Latitude,
                                lng: this.state.Longitude,
                              }}
                              zoom={14}
                            >
                              <Marker
                                disabled={true}
                                onClick={this.onMarkerClick}
                                name={"Current location"}
                                position={{
                                  lat: this.state.List.Latitude,
                                  lng: this.state.List.Longitude,
                                }}
                                draggable={false}
                                onDragend={this.moveMarker.bind(this)}
                              />
                            </Map>
                            <a
                              href="javascript:;"
                              onClick={() =>
                                this.setState({
                                  IsAdjust: !this.state.IsAdjust,
                                })
                              }
                            >
                              {"Save"}
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="apc-footer">
                        <a
                          href="javascript:;"
                          onClick={() =>
                            this.setState({
                              Step: this.state.Step - 1,
                              dynamicProgress:
                                this.state.dynamicProgress - 14.2857123,
                            })
                          }
                          className="btn"
                        >
                          <i className="fas fa-angle-left"></i> Back
                        </a>
                        <a
                          href="javascript:;"
                          onClick={() => this.validate()}
                          className="btn btn-primary"
                        >
                          Yes, that's right
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (Step == 3) {
      return (
        <div className="add-place-wrap">
          <div className="container">
            <div className="add-place-inner">
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-5">
                  <div className="add-place-content">
                    <div className="apc-heading">
                      <h3>How many users can the space accommodate?</h3>
                      <p>
                        Please check there is a desk and chair for each
                        potential user.
                      </p>
                    </div>
                    <div className="apc-inner-content">
                      <div className="form-group">
                        <label>Users</label>
                        {this.state.List.GuestCount <= 3 ? (
                          <select
                            disabled={true}
                            className={[
                              //   this.state.UsersErr
                              //     ? "form-control is-invalid"
                              // : 
                              "form-control",
                            ]}
                            value={this.state.List.GuestCount}
                          // onChange={(e) => this.handleChange(e, "NOOFUSERS")}
                          >
                            {noOfUsersList.map((x) => {
                              return (
                                <option value={x.StringMapName}>
                                  {x.StringMapName}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <div className="row">
                            <div className="col-lg-4 col-md-4">
                              <select
                                disabled={true}
                                className={[
                                  //   this.state.UsersErr
                                  //     ? "form-control is-invalid"
                                  // : 
                                  "form-control",
                                ]}
                                value="Multi"
                              // onChange={(e) =>
                              //   this.handleChange(e, "NOOFUSERS")
                              // }
                              >
                                {noOfUsersList.map((x) => {
                                  return (
                                    <option value={x.StringMapName}>
                                      {x.StringMapName}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="col-lg-8 col-md-8">
                              <input
                                disabled={true}
                                type="number"
                                className="form-control"
                                value={this.state.List.GuestCount}
                              // onChange={(e) =>
                              //   this.handleChange(e, "UsersCount")
                              // }
                              />
                            </div>
                          </div>
                        )}
                        <div className="invalid-feedback">
                          {this.state.UsershelperText}
                        </div>
                      </div>
                      <div className="apc-heading mt-20">
                        <h3>What is your Internet type?</h3>
                      </div>
                      <div className="form-group">
                        <label>Internet Type</label>
                        <select
                          disabled={true}
                          className={[
                            // this.state.InternetTypeErr
                            //   ? "form-control is-invalid"
                            //   : 
                            "form-control",
                          ]}
                          value={this.state.List.Amenities[0].InternetType}
                        //   onChange={(e) => this.handleChange(e, "INTERNETTYPE")}
                        >
                          {/* <option value="" disabled selected hidden>
                            Select
                          </option> */}
                          {internetTypeList.map((x) => {
                            return (
                              <option value={this.state.List.Amenities[0].InternetType}>
                                {this.state.List.Amenities[0].InternetType}
                              </option>
                            );
                          })}
                        </select>
                        {/* <div className="invalid-feedback">
                          {this.state.InternetTypehelperText}
                        </div> */}
                      </div>
                      <div className="apc-heading mt-20">
                        <h3>How many washrooms?</h3>
                      </div>
                      <div className="form-group">
                        <label>Washrooms</label>
                        <select
                          disabled={true}
                          className={[
                            // this.state.WashRoomErr
                            //   ? "form-control is-invalid"
                            //   : 
                            "form-control",
                          ]}
                          value={this.state.List.BathroomCount}
                        //   onChange={(e) =>
                        //     this.handleChange(e, "NOOFWASHROOMS")
                        //   }
                        >
                          {noOfWashRoomsList.map((x) => {
                            return (
                              <option value={x.StringMapName}>
                                {x.StringMapName}
                              </option>
                            );
                          })}
                        </select>
                        {/* <div className="invalid-feedback">
                          {this.state.WashRoomhelperText}
                        </div> */}
                      </div>

                      <div className="apc-heading mt-20">
                        <h3>How much will users pay?</h3>
                        <span className="input-tagline">
                          Pricing starting from &#163;15
                        </span>
                      </div>
                      <div className="form-group">
                        {this.state.mainDateArray.length > 0
                          ? this.state.mainDateArray.map((x, indx) => {
                            return (
                              <div>
                                <div
                                  className="form-control col-lg-12 col-md-12 border-0 text-center mt-4"
                                  key={indx}
                                >
                                  {x.day !== "Single Day" ? (
                                    <h6>
                                      {moment(x.StartDate).format(
                                        "ddd DD MMMM,YYYY"
                                      )}{" "}
                                        to{" "}
                                      {moment(x.EndDate).format(
                                        "ddd DD MMMM,YYYY"
                                      )}
                                    </h6>
                                  ) : (
                                    <h6>
                                      {moment(x.StartDate).format(
                                        "ddd DD MMMM,YYYY"
                                      )}
                                    </h6>
                                  )}
                                </div>
                                {x.Time.length > 0
                                  ? x.Time.map((i, idx) => {
                                    return (
                                      <div className="row mt-1" key={idx}>
                                        <div className="form-control col-lg-4 col-md-4 border-0">
                                          <h6>
                                            {
                                              this.state.timeSlotList.filter(
                                                (t) =>
                                                  t.StringMapID ===
                                                  i.StringMapID
                                              )[0].StringMapName
                                            }
                                          </h6>
                                        </div>
                                        <div className="col-lg-8 col-md-8">
                                          <div className="input-group">
                                            <input
                                              type="number"
                                              className="form-control"
                                              value={i.Price ? i.Price : ""}
                                              onChange={(e) =>
                                                this.handleChange(
                                                  e,
                                                  "Price",
                                                  {
                                                    mainidx: indx,
                                                    childidx: idx,
                                                  }
                                                )
                                              }
                                            />
                                            <div class="input-group-append">
                                              <span class="input-group-text">
                                                £
                                                  </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                  : null}
                              </div>
                            );
                          })
                          : null}
                      </div>
                      <div className="apc-heading mt-20">
                        <h5>
                          Do you want to provide student and seasonal discount?
                        </h5>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>Student Discount</label>
                            <input
                              disabled={true}
                              type="number"
                              className="form-control"
                              value={
                                this.state.List.Amenities[0].StudentDiscount
                              }
                            //   onChange={(e) =>
                            //     this.handleChange(e, "StudentDiscount")
                            //   }
                            />
                            {/* <div className="invalid-feedback">
                              {this.state.StudentDiscounthelperText}
                            </div> */}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>Seasonal Discount</label>
                            <input
                              type="number"
                              disabled={true}
                              className="form-control"
                              value={
                                this.state.List.Amenities[0].SeasonalDiscount
                              }
                            //   onChange={(e) =>
                            //     this.handleChange(e, "SeasonalDiscount")
                            //   }
                            />
                            {/* <div className="invalid-feedback">
                              {this.state.SeasonalDiscounthelperText}
                            </div> */}
                          </div>
                        </div>
                      </div>

                      <div className="apc-footer">
                        <a
                          href="javascript:;"
                          onClick={() =>
                            this.setState({
                              Step: this.state.Step - 1,
                              dynamicProgress:
                                this.state.dynamicProgress - 14.2857123,
                            })
                          }
                          className="btn"
                        >
                          <i className="fas fa-angle-left"></i> Back
                        </a>
                        <a
                          href="javascript:;"
                          onClick={() => this.validate()}
                          className="btn btn-primary"
                        >
                          Next
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (Step == 4) {
      return (
        <div className="add-place-wrap">
          <div className="container">
            <div className="add-place-inner">
              <div className="row justify-content-center">
                <div className="col-lg-8 col-md-8">
                  <div className="add-place-content">
                    <div className="apc-heading">
                      <h3>What amenities do you offer?</h3>
                      <p>
                        These are just the amenities users usually expect, but
                        you can add even more after you publish.
                      </p>
                    </div>
                    <div className="checkbox-list-tag">
                      {amenitiesList
                        .map((x, i) => {
                          return i % 3 === 0
                            ? amenitiesList.slice(i, i + 3)
                            : null;
                        })
                        .filter((x) => x != null)
                        .map((item, idx) => {
                          // console.log("item", item);
                          return (
                            <div className="row" key={idx}>
                              {item.map((content) => (
                                <div className="col-lg-4 col-md-4">
                                  <div className="filter-check" key={idx}>
                                    <label>
                                      <input
                                        type="checkbox"
                                        // onClick={(e) =>
                                        //   this.handleChange(
                                        //     content,
                                        //     "Amenities"
                                        //   )
                                        // }
                                        checked={
                                          this.state.amenitiesOfPod.includes(content.FeatureName) ? true : false
                                        }
                                      />
                                      <span>
                                        {content.FeatureIconURL
                                          ? parse(content.FeatureIconURL)
                                          : ""}
                                        {content.FeatureName}
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="apc-footer">
                    <a
                      href="javascript:;"
                      onClick={() =>
                        this.setState({
                          Step: this.state.Step - 1,
                          dynamicProgress:
                            this.state.dynamicProgress - 14.2857123,
                        })
                      }
                      className="btn"
                    >
                      <i className="fas fa-angle-left"></i> Back
                    </a>
                    <a
                      href="javascript:;"
                      onClick={() => this.validate()}
                      className="btn btn-primary"
                    >
                      Next
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (Step == 5) {
      return (
        <div className="add-place-wrap">
          <div className="container">
            <div className="add-place-inner">
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-5">
                  <div className="add-place-content">
                    <div className="apc-heading">
                      <h3>Additional rules</h3>
                    </div>
                    <div className="apc-inner-content">
                      <div className="additional-rules">
                        <div className="input-btn-group">
                          <div className="input-group">
                            <input
                              disabled={true}
                              type="text"
                              className="form-control"
                              placeholder=""
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              //   onChange={(e) =>
                              //     this.setState({
                              //       AdditionalRule: e.target.value,
                              //     })
                              //   }
                              value={this.state.AdditionalRule}
                            />
                            <div className="input-group-append">
                              <button
                                className="btn btn-outline-primary"
                                type="button"
                              // onClick={(e) =>
                              //   this.handleChange(e, "AdditionalRules")
                              // }
                              >
                                Add
                              </button>
                            </div>
                          </div>
                          <div className="addition-rules-option mt-2">
                            {this.state.additionalRules.map((x, idx) => {
                              return (
                                <div
                                  className="custom-control custom-checkbox"
                                  key={idx}
                                >
                                  <label className="mr-2">{x}</label>
                                  <a
                                    href="javacript:;"
                                  // onClick={() => {
                                  //   var array = [...this.state.Rules];
                                  //   var index = idx;
                                  //   if (index !== -1) {
                                  //     array.splice(index, 1);
                                  //     this.setState({ Rules: array });
                                  //   }
                                  // }}
                                  >
                                    <i className="fas fa-times"></i>
                                  </a>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="addition-rules-option">
                          <h6>Details users must know about your home</h6>

                          {amenitiesRulesList.map((content, idx) => {
                            // console.log("contentt", content)
                            return (
                              <div
                                className="custom-control custom-checkbox"
                                key={idx}
                              >
                                <input
                                  type="checkbox"
                                  checked={
                                    this.state.amenitiesRules.includes(content.FeatureName) ? true : false
                                  }
                                  //   onChange={() =>
                                  //     this.handleChange(content, "Rules")
                                  //   }
                                  className="custom-control-input"
                                  id={content.FeatureName}
                                ></input>
                                <label
                                  className="custom-control-label"
                                  for={content.FeatureName}
                                >
                                  {content.FeatureName}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="apc-footer">
                        <a
                          href="javascript:;"
                          onClick={() =>
                            this.setState({
                              Step: this.state.Step - 1,
                              dynamicProgress:
                                this.state.dynamicProgress - 14.2857123,
                            })
                          }
                          className="btn"
                        >
                          <i className="fas fa-angle-left"></i> Back
                        </a>
                        <a
                          href="javascript:;"
                          onClick={() => this.validate()}
                          className="btn btn-primary"
                        >
                          Next
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (Step == 6) {
      return (
        <div className="add-place-wrap">
          <div className="container">
            <div className="add-place-inner">
              <div className="row justify-content-center">
                <div className="col-lg-10 col-md-10">
                  <div className="add-place-content">
                    <div className="apc-heading">
                      <h3>Add Property Photo</h3>
                    </div>

                    <div className="property-photo-add">
                      <Dropzone onDrop={this.onDrop}>
                        {({ getRootProps, getInputProps }) => (
                          <section className="dropzone-container">
                            {/* <div {...getRootProps({ className: "dropzone" })}>
                              <input {...getInputProps()} />
                              <button
                                type="button"
                                className="btn btn-primary btn-icon"
                              >
                                <i className="fas fa-cloud-upload-alt"></i>
                                Upload Photos
                              </button>
                              <p>or drag them in</p>
                            </div> */}
                            {/* {this.state.files.length > 0 ? ( */}
                            {/* <aside> */}
                            <h6>Files</h6>
                            <ul className="thumb-container">
                              {/* {this.state.files.map((file, idx) => { */}
                              {/* return ( */}
                              <div className="thumb">
                                <div className="thumb-inner">
                                  {this.state.List.PropertyMedia.map(item => {
                                    return <>
                                      <img
                                        src={apiBase + item.MediaURL}
                                        className="img-preview"
                                        onClick={() => this.setState({ isOpen: true })}
                                      />
                                      {/* <a
                                            href="javascript:;"
                                            onClick={() =>
                                              this.removeImage(idx)
                                            }
                                          >
                                            <i className="fas fa-trash-alt"></i>
                                          </a> */}
                                    </>
                                  }
                                  )}
                                </div>
                              </div>
                              {/* ); */}
                              {/* })} */}
                            </ul>
                            {/* </aside> */}
                            {/* ) : null} */}
                          </section>
                        )}
                      </Dropzone>
                    </div>
                    <div className="apc-footer">
                      <a
                        href="javascript:;"
                        onClick={() =>
                          this.setState({
                            Step: this.state.Step - 1,
                            dynamicProgress:
                              this.state.dynamicProgress - 14.2857123,
                          })
                        }
                        className="btn"
                      >
                        <i className="fas fa-angle-left"></i> Back
                      </a>
                      <a
                        href="javascript:;"
                        onClick={() => this.validate()}
                        className="btn btn-primary"
                      >
                        Next
                      </a>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (Step == 7) {
      return (
        <div className="reg-summary-outer">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="reg-summary">
                  <h2>Finish your listing to start earning</h2>
                  <p>You can always edit your listing after you publish it.</p>
                  <ul>
                    {this.state.Heading.map((x, idx) => {
                      return (
                        <li
                          className="completed"
                          key={idx}
                          onClick={() => this.setState({ Step: idx })}
                        >
                          <i class="fas fa-check"></i>
                          <p>{x}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <p>
                <i class="fas fa-info-circle"></i> Approve or Reject the pod
              </p>
            </div>
            {this.state.isRejectButtonNotClicked && (this.state.List.PropertyStatus === 'Under Review' || this.state.List.PropertyStatus === 'Declined') ?
              <div className="apc-footer">
                <div className="">
                  <a
                    href="javascript:;"
                    onClick={() => this.reject()}
                    className="btn btn-secondary"
                  >
                    Reject
              </a>
                  <a
                    href="javascript:;"
                    onClick={() => this.approve()}
                    className="btn btn-primary ml-5"
                  >
                    Approve
              </a>

                </div>
              </div> :
              this.state.isReject ? (
                <>
                  <div className="form-group">
                    <textarea
                      className={[
                        this.state.ReasonErr
                          ? "form-control mb-10 is-invalid"
                          : "form-control mb-10",
                      ]}
                      rows="3"
                      maxLength="250"
                      placeholder="Reason to decline this pod.."
                      value={this.state.List.Reason}
                      onChange={(e) => this.handleChange(e, "Reason")}
                    ></textarea>
                    <div className="invalid-feedback">
                      {this.state.ReasonHelperText}
                    </div>
                  </div>
                  <div className="apc-footer">
                    <a
                      href="javascript:;"
                      onClick={() => this.rejectPod()}
                      className="btn btn-secondary"
                    >
                      Reject
            </a>
                  </div>
                </>
                //   </div>
              ) : <div className="apc-footer">
                <a
                  href="javascript:;"
                  onClick={() => this.goBack()}
                  className="btn btn-primary"
                >
                  Go to Listing
               </a></div>}

          </div>
        </div>
      );
    }
  };

  render() {
    const  { photoIndex, isOpen } = this.state
    return (
      <div>
        <div className="add-place-header d-none">
          <div className="container-fluid">
            <div className="aph-inner">
              <div className="logo">
                <a href="/admin/homepage">
                  <img src={logo} alt="" />
                </a>
              </div>
              <span>{this.state.Heading[this.state.Step]}</span>
            </div>
          </div>
        </div>

        <div className="add-place-progressbar">
          <div
            className="fill"
            style={{ width: this.state.dynamicProgress + "%" }}
          ></div>
        </div>
        {this.getSteps()}
        {isOpen && (
          <Lightbox
            mainSrc={apiBase + this.state.List.PropertyMedia[photoIndex]["MediaURL"]}
            nextSrc={this.state.List.PropertyMedia[(photoIndex + 1) % this.state.List.PropertyMedia.length]}
            prevSrc={this.state.List.PropertyMedia[(photoIndex + this.state.List.PropertyMedia.length - 1) % this.state.List.PropertyMedia.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + this.state.List.PropertyMedia.length - 1) % this.state.List.PropertyMedia.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % this.state.List.PropertyMedia.length
              })
            }
          />
        )}
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAww6TxPSzQbg74ljJahxvuLHmRfICHbf4",
})(ViewPropertyByAdmin);
