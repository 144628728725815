import React from 'react';
import logo from "../assets/img/logo.svg";

const Loader = () => {

  return (
    <div className="loader-outer">
      <div className="loader-inner">
        <div className="loader-logo">
          <img className="logo-color" src={logo} alt="" />
        </div>
        <div className="spinner">
          <div className="spinnerContainer active common">
            <div className="spinner-layer layer-1 common">
              <div className="circle-clipper left common">
                <div className="circle common" style={{ borderWidth: "3px" }}></div>
              </div>
              <div className="gap-patch common">
                <div className="circle common" style={{ borderWidth: "3px" }}></div>
              </div>
              <div className="circle-clipper right common">
                <div className="circle common" style={{ borderWidth: "3px" }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default Loader;