import React, { Component } from "react";
import cogoToast from 'cogo-toast';
import api from '../../utils/apiClient';
import { CommonConfig } from 'utils/constant';

class verification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };

    }

    componentDidMount() {
        this.showLoader();
        this.validateLink();
    }

    validateLink = () => {
        var url = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
        url = url.slice(0, url.indexOf('?'))
        let data = {
            // UUID: this.props.match.params.id
            UUID: url,
            type:"email"
        }
        try {
            api.post("userauthentication/verifyLink", data).then(res => {
                this.hideLoader();
                // // console.log('email',res);
                if (res.success) {
                    this.setState({
                        PersonID: res.Data[0].PersonID,
                        Email : res.Data[0].Email,
                        IsLinkExpired: false
                    })
                    // console.log("res...",res)
                    // this.props.history.push('/admin/homepage');

                    // this.props.history.push({ 
                    //     pathname: '/admin/homepage',
                    //     state: {
                    //         email : res.Data[0].Email
                    //     }
                    // });
                    window.location.href = `/admin/homepage?verifyID=${window.btoa(res.Data[0].Email)}`
                    // salesLeadID+`/`+msg.data[index].ServiceType+`/`+msg.data[index].MainServiceName+`/`+msg.data[index].ServiceDisplayName+``)
                    cogoToast.success('Email verified successfully.')
                    
                }
                else {

                    const options = {
                        hideAfter: 5
                    };
                    cogoToast.error("Email Verification Link Expired", options);
                }
            }).catch(err => {
                // cogoToast.error("Something went wrong");
                // // console.log("error....", err);
            })
        }
        catch (err) {
            // // console.log("err.....", err);
        }
    }

    showLoader = () => {
        this.setState({ Loading: true });
    }

    hideLoader = () => {
        this.setState({ Loading: false });
    }

    render() {
        return (
            <div>
            </div>
        )
    }
}

export default verification;