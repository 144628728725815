import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import api from '../utils/apiClient';
// creates a beautiful scrollbar
// import PerfectScrollbar from "perfect-scrollbar";
// import "perfect-scrollbar/css/perfect-scrollbar.css";
import Header from "../components/Header/Header.js";
import Footer from "../components/Footer/Footer.js";
import routes from "../routes.js";
import i18n from '../utils/i18n';

// var ps;

export default function Dashboard(props) {
  const { ...rest } = props;
  const [showLoginModal, setshowLoginModal] = React.useState(false)
  // const ref = React.createRef()
  const mainPanel = React.createRef();

  React.useEffect(() => {
    getUserCountryDetails();
    // unmount
    return function () {
      localStorage.removeItem("UserCountryDetails")
    }
  }, [])

  const getUserCountryDetails = () => {
    let data = {
      CountryISOCode: '',
      WebURL: window.location.origin
    }
    try {
      api.post("utility/getCurrencyDetails", data)
        .then((res) => {
          i18n.changeLanguage(res.data[0].country_code);
          localStorage.setItem("UserCountryDetails", JSON.stringify(res.data[0]))
        })
        .catch((err) => {

        })
    } catch (err) {
      // // console.log("error", err);
    }
  }

  const getActiveRoute = routes => {

    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const isAuthorized = localStorage.getItem("isAuthorized");

  const getRoutes = routes => {
    window.scrollTo(0, 0);
    if (window.location.pathname == "/admin/homepage") {
      document.body.classList.remove("add-place-page");
      document.body.classList.add("homepage");

    } else if (window.location.pathname == "/admin/registration" || window.location.pathname == "/admin/viewProperty") {
      document.body.classList.remove("homepage");
      document.body.classList.add("add-place-page");
    } else {
      document.body.classList.remove("homepage");
      document.body.classList.remove("add-place-page");
    }
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if ((prop.layout === "/admin" || prop.layout === "/") && isAuthorized && prop.isAccessRequired) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            showLoginModal={showLoginModal}
            render={props => (
              <prop.component {...props} />
            )}
          />
        );
      } else if ((prop.layout === "/admin" || prop.layout === "/") && !prop.isAccessRequired) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            showLoginModal={showLoginModal}
            render={props => (
              <prop.component {...props} />
            )}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    // window.location.pathname !== "/admin/registration" || window.location.pathname !== "/admin/viewProperty"?
    <div ref={mainPanel}>
      <div style={(window.location.pathname == "/admin/registration" || window.location.pathname == "/admin/viewProperty") ? { "display": "none" } : null}><Header showLoginModal={showLoginModal} hideLoginModal={() => setshowLoginModal(false)} {...rest} /></div>
      <Switch>
        {getRoutes(routes)}
        <Redirect from="/" to="/admin/homepage" />
      </Switch>
      <div style={(window.location.pathname == "/admin/registration" || window.location.pathname == "/admin/viewProperty") ? { "display": "none" } : null}><Footer {...rest} /></div>
    </div>
    // :
    // <div ref={mainPanel}>
    //   <Switch>
    //     {getRoutes(routes)}
    //     <Redirect from="/" to="/admin/homepage" />
    //   </Switch>
    // </div>
  );
}
