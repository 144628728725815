import React, { Component } from 'react'
import london from "../../assets/img/london.jpg";
import birmingham from "../../assets/img/birmingham.jpg";
import manchester from "../../assets/img/manchester.jpg";
import edinburgh from "../../assets/img/edinburgh.jpg";

class Places extends Component {
    constructor(props){
        super(props);
        this.state = {
            
        }
    }

    render(){
        return (
            <div className="places-outer">
                <div className="container">
                    <div className="common-heading">
                        <h2>Places</h2>
                        <p>Explore our best selection of the best places around the world</p>
                    </div>
                    <div className="trending-pods-inner">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                            <a href="javascript:;">
                                <div className="tp-box">
                                <img src={london} alt="" />
                                <div className="gr-overlay"></div>
                                <h3 className="tp-box-caption">London</h3>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <a href="javascript:;">
                                <div className="tp-box">
                                <img src={edinburgh} alt="" />
                                <div className="gr-overlay"></div>
                                <h3 className="tp-box-caption">Edinburgh</h3>
                                </div>
                            </a>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                            <a href="javascript:;">
                                <div className="tp-box">
                                <img src={manchester} alt="" />
                                <div className="gr-overlay"></div>
                                <h3 className="tp-box-caption">Manchester</h3>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <a href="javascript:;">
                                <div className="tp-box">
                                <img src={birmingham} alt="" />
                                <div className="gr-overlay"></div>
                                <h3 className="tp-box-caption">Birmingham</h3>
                                </div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Places
