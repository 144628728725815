import React, { Component } from 'react'

class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div className="container">
                <div className="privacy-text">
                    <ol className="breadcrumb">
                        <li>
                            <a
                                href="javascript:;"
                                onClick={() =>
                                    this.props.history.push("/admin/homepage")
                                }
                            >
                                <span>Home</span>
                            </a>
                        </li>
                        <li className="active">
                            <span>Privacy Policy</span>
                        </li>
                    </ol>

                    <h2>Privacy Policy</h2>
                    <p>
                        <b>Last Updated:</b> 26th March, 2021
                    </p>
                    <p>
                        The Work Pod is a platform built on mutual trust. A fundamental part of earning your trust means providing clarity about how we use your information and protect your human right to privacy.
                    </p>
                    <p>
                        This Privacy Policy describes how The Work Pod LTD and its affiliates (“<b>we</b>,” “<b>us</b>,” or “<b>the Work Pod</b>”), process personal information that we collect through the Work Pod Platform. Please review the supplemental information provided:
                    </p>
                    <h3>1. DEFINITIONS</h3>
                    <p>
                        Undefined terms in this Privacy Policy have the same definition as in our Terms of Service (“<b>Terms</b>”).
                    </p>
                    <h3>2. PERSONAL INFORMATION WE COLLECT</h3>
                    <p>
                        <b>2.1 Information needed to use the Work Pod Platform</b>
                    </p>
                    <p>
                        We collect personal information about you when you use the Work Pod Platform. Without it, we may not be able to provide you with all services requested. This information includes:
                    </p>
                    <ul>
                        <li>
                            <b>Contact Information, Account, Profile Information.</b> Such as your first name, surname, phone number, postal address, email address, date of birth, and profile photo; some of which will depend on the features you use.
                        </li>
                        <li>
                            <b>Identity Verification and Payment Information.</b> Such as images of your government-issued ID (as permitted by applicable laws), your ID number or other verification information; bank account or payment account information.
                        </li>
                        <li>
                            <b>Contact Verification.</b> Such as you will receive phone calls and SMS messages from WorkPod to provide update on your order and/or for marketing purposes. Message frequency depends on your activity.Message and data rates may apply.
                        </li>
                    </ul>
                    <p><b>2.2 Information you choose to give us</b></p>
                    <p>
                        You can choose to provide us with additional personal information. This information may include:
                    </p>
                    <p>
                        <b>Additional Profile Information.</b> Such as gender, preferred language(s), city, and personal description. Some of this information as indicated in your account settings is part of your public profile page and will be publicly visible.
                    </p>
                    <p>
                        <b>Other Information.</b> Such as when you fill in a form, add information to your account, respond to surveys, post to community forums, participate in promotions, communicate with our customer care team and other Members, or share your experience with us. This may include health information if you choose to share it with us.
                    </p>
                    <p><b>2.3 Information Automatically Collected by Using the Work Pod Platform and our Payment Services</b></p>
                    <p>
                        When you use the Work Pod Platform and Payment Services, we automatically collect personal information. This information may include:
                    </p>
                    <ul>
                        <li>
                            <b>Geo-location Information.</b> Such as precise or approximate location determined from your IP address. We may also collect this information when you’re not using the app if you enable this through your settings or device permissions.
                        </li>
                        <li>
                            <b>Usage Information.</b> Such as the pages or content you view, searches for Listings, bookings you have made, and other actions on the Work Pod Platform.
                        </li>
                        <li>
                            <b>Log Data and Device Information.</b> Such as IP address, access dates and times, hardware and software information, device information, device event information, unique identifiers, crash data, cookie data, and the pages you’ve viewed or engaged with before or after using the Work Pod Platform. We may collect this information even if you haven’t created a Work Pod account or logged in.
                        </li>
                        <li>
                            <b>Payment Transaction Information.</b> Such as payment instrument used, date and time, payment amount, payment instrument expiration date and billing postcode, PayPal email address, IBAN information, your address and other related transaction details.
                        </li>
                    </ul>
                    <p>
                        <b>2.4 Personal Information We Collect from Third Parties</b>
                    </p>
                    <p>
                        We collect personal information from other sources, such as:
                    </p>
                    <ul>
                        <li>
                            <b>Third-Party Services.</b> If you link, connect or log into the Work Pod Platform with a third-party service (e.g. Google, Facebook), you direct the service to send us information such as your registration profile information as controlled by that service or as authorised by you via your privacy settings at that service.
                        </li>
                        <li>
                            <b>Other Sources.</b> To the extent permitted by applicable law, we may receive additional information about you, such as references, demographic data or information to help detect fraud and safety issues from third-party service providers and/or partners, and combine it with information we have about you. For example, we may receive background check results or fraud warnings from identity verification service providers for use in our fraud prevention and risk assessment efforts. We may receive information about you and your activities on and off the Work Pod Platform, or about your experiences and interactions from our partners. We may receive health information, including but not limited to health information related to contagious diseases.
                        </li>
                    </ul>
                    <h3>3. HOW WE USE INFORMATION WE COLLECT</h3>
                    <p>
                        <b>3.1 Provide, Improve, and Develop the Work Pod Platform.</b> We use personal information to:
                    </p>
                    <ul>
                        <li>
                            enable you to access the Work Pod Platform and make and receive payments,
                        </li>
                        <li>
                            enable you to communicate with other Members,
                        </li>
                        <li>
                            perform analytics, debug and conduct research,
                        </li>
                        <li>
                            provide customer service,
                        </li>
                        <li>
                            send you messages, updates, security alerts, and account notifications,
                        </li>
                        <li>
                            personalise and customise your experience based on your interactions with the Work Pod Platform, your search and booking history, your profile information and preferences, and other content you submit, and
                        </li>
                        <li>
                            enable your use of our enterprise products.
                        </li>
                    </ul>
                    <p>
                        <b>3.2 Create and Maintain a Trusted and Safer Environment.</b> We use personal information to:
                    </p>
                    <ul>
                        <li>
                            detect and prevent fraud, spam, abuse, security and safety incidents, and other harmful activity,
                        </li>
                        <li>
                            study and combat discrimination consistent with our Nondiscrimination Policy,
                        </li>
                        <li>
                            conduct security investigations and risk assessments,
                        </li>
                        <li>
                            verify or authenticate information provided by you,
                        </li>
                        <li>
                            conduct checks against databases and other information sources, including background or police checks,
                        </li>
                        <li>
                            comply with our legal obligations, protect the health and wellbeing of our Users, Hosts and members of the public,
                        </li>
                        <li>
                            resolve disputes with our Members,
                        </li>
                        <li>
                            enforce our agreements with third parties,
                        </li>
                        <li>
                            comply with law, respond to legal requests, prevent harm and protect our rights,
                        </li>
                        <li>
                            enforce our Terms and other policies (e.g. Nondiscrimination Policy), and
                        </li>
                        <li>
                            in connection with the activities above, we may conduct profiling based on your interactions with the Work Pod Platform, your profile information and other content you submit to The Work Pod, and information obtained from third parties. In limited cases, automated processes could restrict or suspend access to the Work Pod Platform if such processes detect activity that we think poses a safety or other risk to The Work Pod, our community, or third parties. If you would like to challenge the decisions based on the automated process, please contact us via Contact Information section below.
                        </li>
                    </ul>
                    <p><b>3.3 Provide, Personalise, Measure and Improve our Advertising and Marketing.</b> We may use personal information to:</p>
                    <ul>
                        <li>
                            send you promotional messages, marketing, advertising, and other information based on your preferences and social media advertising through social media platforms,
                        </li>
                        <li>
                            personalise, measure, and improve our advertising,
                        </li>
                        <li>
                            administer referral programs, rewards, surveys, sweepstakes, contests, or other promotional activities or events sponsored or managed by The Work Pod or its third-party partners, and
                        </li>
                        <li>
                            analyse characteristics and preferences to send you promotional messages, marketing, advertising and other information that we think might be of interest to you.
                        </li>
                    </ul>
                    <p>
                        <b>3.4 Provide Payment services.</b> Personal information is used to enable or authorise third parties to use Payment Services:
                    </p>
                    <ul>
                        <li>
                            Detect and prevent money laundering, fraud, abuse, security incidents.
                        </li>
                        <li>
                            Conduct security investigations and risk assessments.
                        </li>
                        <li>
                            Comply with legal obligations (such as anti-money laundering regulations).
                        </li>
                        <li>
                            Enforce the Payment Terms and other payment policies.
                        </li>
                        <li>
                            With your consent, send you promotional messages, marketing, advertising, and other information that may be of interest to you based on your preferences.
                        </li>
                        <li>
                            Provide and improve the Payment Services.
                        </li>
                    </ul>
                    <h3>4. SHARING &amp; DISCLOSURE</h3>
                    <p><b>4.1 Sharing With Your Consent or at Your Direction</b></p>
                    <p>
                        Where you provide consent, we share your information as described at the time of consent, such as when authorising a third-party application or website to access your Work Pod account or participating in promotional activities by The Work Pod partners or third parties.
                    </p>
                    <p>
                        Where permissible with applicable law, we may use certain information about you, such as your email address, de-identify it, and share it with social media platforms, to generate leads, drive traffic to The Work Pod or otherwise promote our products and services.
                    </p>
                    <p>
                        <b>4.2 Sharing Between Members</b>
                    </p>
                    <p>
                        To help facilitate bookings or other interactions between Members, we may need to share certain information such as:
                    </p>
                    <ul>
                        <li>
                            When a booking request is made or dispute is submitted, certain information may be shared between User(s) and Host(s), including profile, name, names of any additional Users, cancellation history, review information, dispute outcome (when applicable) and other information you choose to share and submit. When a booking is confirmed, additional information is shared to assist with coordinating the trip, like profile photo and phone number. When you as a Host have a confirmed booking, certain information is shared with the User (and the additional Users they invite, if applicable) to coordinate the booking, such as your profile, full name, phone number, and Listing address.
                        </li>
                    </ul>
                    <p><b>4.3 Information You Publish in Profiles, Listings, and other Public Information</b></p>
                    <p>
                        You can make certain information publicly visible to others, such as:
                    </p>
                    <ul>
                        <li>
                            Your public profile page, which includes your profile photo, first name, description, and city.
                        </li>
                        <li>
                            Listing pages that include information such as the Workspace’s approximate or precise location description, calendar availability, profile photo, aggregated demand information (like page views over a period of time), and additional information you choose to share.
                        </li>
                        <li>
                            Reviews, ratings and other public feedback.
                        </li>
                    </ul>
                    <p>
                        We may display parts of your public profile and other Content you make available to the public, e.g. Listing details on third-party sites, platforms and apps.
                    </p>
                    <p>
                        Information you share publicly on the Work Pod Platform may be indexed through third-party search engines. In some cases, you may opt-out of this feature in your account settings.
                    </p>
                    <p><b>4.4 Host Service Providers</b></p>
                    <p>
                        Hosts may use third-party services to help manage or deliver their services, such as cleaning services or lock providers. Hosts may use features on the Work Pod Platform to share information about the User (like their check-in and check-out dates, full name and/or phone number) with such third-party service providers.
                    </p>
                    <p>
                        <b>4.5 Complying with Law, Responding to Legal Requests, Preventing Harm and Protecting our Rights</b>
                    </p>
                    <p>
                        We may disclose your information to courts, law enforcement, governmental or public authorities, tax authorities, or authorised third parties, if and to the extent we are required or permitted to do so by law or where disclosure is reasonably necessary: (i) to comply with our legal obligations, (ii) to comply with a valid legal request or to respond to claims asserted against The Work Pod, (iii) to respond to a valid legal request relating to a criminal investigation to address alleged or suspected illegal activity, or to respond to or address any other activity that may expose us, you, or any other of our users to legal or regulatory liability, (iv) to enforce and administer our agreements with Members, or (v) to protect the rights, property or personal safety of The Work Pod, its employees, its Members, or members of the public. For example, if permitted due to the forgoing circumstances, Host tax information may be shared with tax authorities or other governmental agencies.
                    </p>
                    <p>
                        Where appropriate, we may notify Members about legal requests unless: (i) providing notice is prohibited by the legal process itself, by court order we receive, or by applicable law, or (ii) we believe that providing notice would be futile, ineffective, create a risk of injury or bodily harm to an individual or group, or create or increase a risk of fraud upon or harm to The Work Pod, our Members, or expose The Work Pod to a claim of obstruction of justice.
                    </p>
                    <p>
                        For jurisdictions where The Work Pod facilitates the collection and remittance of Taxes where legally permissible according to applicable law, we may disclose Hosts’ and Users’ information about transactions, bookings, Workspaces and occupancy Taxes to the applicable tax authority, such as Host and User names, Listing addresses, transaction dates and amounts, tax identification number(s), the amount of taxes received (or due) by Hosts from Users, and contact information.
                    </p>
                    <p>
                        In jurisdictions where The Work Pod facilitates or requires a registration, notification, permit, or licence application of a Host with a local governmental authority through The Work Pod in accordance with local law, we may share information of participating Hosts with the relevant authority, both during the application process and, periodically thereafter, such as the Host’s full name and contact details, Workspace address, tax identification number, Listing details, and timeslot booked.
                    </p>
                    <p><b>4.6 Programs with Managers and Owners</b></p>
                    <p>
                        We may share personal information of Hosts and Users such as booking information, and information related to compliance with applicable laws such as short-term rental laws with landlords, management companies, and/or property owners (the “<b>Building Management</b>”), in order to facilitate programs with Building Management. For example, user booking and personal information, including user contact information, may be shared with the Building Management of the building, complex, or community where a host lives and/or the listing is located, to facilitate hosting services, compliance with applicable laws, security, billing, and other services.
                    </p>
                    <p><b>4.7 Service Providers</b></p>
                    <p>
                        We may share personal information with affiliated and unaffiliated service providers to help us run our business, including service providers that help us: (i) verify your identity or authenticate your identification documents, (ii) check information against public databases, (iii) conduct background or police checks, fraud prevention, and risk assessment, (iv) perform product development, maintenance and debugging, (v) allow the provision of the Work Pod Services through third-party platforms and software tools (e.g. through the integration with our APIs), or (vi) provide customer service, advertising, or payments services, (vii) process, handle or assess insurance claims or similar claims. These providers are contractually bound to protect your personal information and have access to your personal information to perform these tasks.
                    </p>
                    <p><b>4.8 Business Transfers</b></p>
                    <p>
                        If The Work Pod undertakes or is involved in any merger, acquisition, reorganisation, sale of assets, bankruptcy, or insolvency event, then we may sell, transfer or share some or all of our assets, including your information in connection with such transaction or in contemplation of such transaction (e.g., due diligence). In this event, we will notify you before your personal information is transferred and becomes subject to a different privacy policy.
                    </p>
                    <h3>5. OTHER IMPORTANT INFORMATION</h3>
                    <p><b>5.1 Analysing your Communications</b></p>
                    <p>
                        We may review, scan, or analyse your communications on the Work Pod Platform for reasons outlined in the “How We Use Information We Collect” section of this policy, including fraud prevention, risk assessment, regulatory compliance, investigation, product development, research, analytics, enforcing our Terms of Service, and customer support purposes. For example, as part of our fraud prevention efforts, we may scan and analyse messages to mask contact information and references to other sites. In some cases, we may also scan, review, or analyse messages to debug, improve, and expand product offerings. We use automated methods where reasonably possible. Occasionally we may need to manually review communications, such as for fraud investigations and customer support, or to assess and improve the functionality of these automated tools. We will not review, scan, or analyse your messaging communications to send third-party marketing messages to you and we will not sell reviews or analyses of these communications.
                    </p>
                    <p><b>5.2 Linking Third-Party Accounts</b></p>
                    <p>
                        You can link your Work Pod account with certain third-party services like social networks. When you direct the data sharing by creating this link:
                    </p>
                    <ul>
                        <li>
                            some of the information provided to us from linking accounts may be published on your public profile,
                        </li>
                        <li>
                            information you provide to us from the linking of your accounts may be stored, processed and transmitted for fraud prevention and risk assessment purposes, and
                        </li>
                        <li>
                            publication and display of information that you provide to the Work Pod Platform through this linkage is subject to your settings and authorisations on the Work Pod Platform and the third-party service.
                        </li>
                    </ul>
                    <p>
                        <b>5.3 Third-Party Partners &amp; Integrations</b>
                    </p>
                    <p>
                        Parts of The Work Pod may link to third-party services, not owned or controlled by The Work Pod, such as Google Maps/Earth. Use of these services is subject to the privacy policies of those providers, such as Google Maps/Earth Additional Terms of Use, and Google Privacy Policy. The Work Pod does not own or control these third parties and when you interact with them you are providing your information to them.
                    </p>
                    <h3>6. YOUR RIGHTS</h3>
                    <p>
                        You can exercise any of the rights described in this section consistent with applicable law. To submit a data subject rights request, you can contact us directly. Please note that we may ask you to verify your identity and request before taking further action on your request.
                    </p>
                    <p><b>6.1 Managing Your Information</b></p>
                    <p>
                        You can access and update some of your personal information through your Account settings. If you connected your Work Pod Account to a third-party service, like Facebook or Google, you can change your settings and unlink from that service in your Account settings. You are responsible for keeping your personal information up to date.
                    </p>
                    <p><b>6.2 Data Access and Portability</b></p>
                    <p>
                        In some jurisdictions, applicable law may entitle you to request certain copies of your personal information or information about how we handle your personal information, request copies of personal information that you have provided to us in a structured, commonly used, and machine-readable format, and/or request that we transmit this information to another service provider (where technically feasible).
                    </p>
                    <p><b>6.3 Data Erasure</b></p>
                    <p>
                        In certain jurisdictions, you can request that your personal information be deleted. Please note that if you request the erasure of your personal information:
                    </p>
                    <ul>
                        <li>
                            We may retain your personal information as necessary for our legitimate business interests, such as prevention of money laundering, fraud detection and prevention, and enhancing safety. For example, if we suspend a Work Pod Account for fraud or safety reasons, we may retain information from that The Work Pod Account to prevent that Member from opening a new The Work Pod Account in the future.
                        </li>
                        <li>
                            We may retain and use your personal information to the extent necessary to comply with our legal obligations. For example, The Work Pod and The Work Pod Payments may keep information for tax, legal reporting and auditing obligations.
                        </li>
                        <li>
                            Information you have shared with others (e.g., Reviews, forum postings) will continue to be publicly visible on The Work Pod, even after your Work Pod Account is cancelled. However, attribution of such information to you will be removed. Some copies of your information (e.g. log records) will remain in our database, but are disassociated from personal identifiers.
                        </li>
                    </ul>
                    <h3>7. SECURITY</h3>
                    <p>
                        While no organisation can guarantee perfect security, we are continuously implementing and updating administrative,     technical, and physical security measures to help protect your information against unauthorised access, loss, destruction, or alteration.
                    </p>
                    <h3>8. CHANGES TO THIS PRIVACY POLICY</h3>
                    <p>
                        We reserve the right to modify this Privacy Policy at any time in accordance with applicable law. If we do so, we will post the revised Privacy Policy and update the “Last Updated” date at the top. In case of material changes, we will also provide you with notice of the modification by email at least thirty (30) days before the effective date. If you disagree with the revised Privacy Policy, you can cancel your Account. If you do not cancel your Account before the revised Privacy Policy becomes effective, your continued access to or use of the Work Pod Platform will be subject to the revised Privacy Policy.
                    </p>

                </div>
            </div>
        )
    }
}

export default PrivacyPolicy
